import React from "react";

import { Badge } from "@shopify/polaris";

interface SelectedIndicatorMarkupProps {
  selected: boolean;
}

const SelectedIndicator = (props: SelectedIndicatorMarkupProps) => {
  const { selected } = props;

  return (
    selected && (
      // <InlineStack blockAlign="center">
      //   <Icon source={CircleTickOutlineIcon} tone="success" />
      //   <Text as="p" tone="success">
      //     Selected
      //   </Text>
      // </InlineStack>
      <Badge tone="success">Selected</Badge>
    )
  );
};

export default SelectedIndicator;
