import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  Link as ReactRouterLink,
  Redirect,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { usePostHog } from "posthog-js/react";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";

import AppProviderWrapper from "./App/AppProviderWrapper";
import ErrorBoundary from "./ErrorBoundary";
import ProtectedRoute from "./ProtectedRoute";
import { ToastProvider } from "../contexts/Toast";

import Navbar from "./Nav/Navbar";

import { CurrentContactContext } from "../contexts/Contact";

// USER AUTH
import Auth0Callback from "../features/User/Auth0Callback";

// ACCOUNTS
import AccountsIndex from "../features/Account/Index";
import AccountsShow from "../features/Account/Show";
import Profile from "../features/Profile";

// ADMIN
import Admin from "../features/Admin";

// VENDOR ADMIN
import VendorAdmin from "../features/VendorAdmin";

// OPPORTUNITIES
import OpportunitiesShow from "../features/Opportunity/Show";
import CreateOpportunity from "../features/Opportunity/Create";
import OpportunitiesEmbeddedSendingSent from "../features/Opportunity/EmbeddedSendingSent";

// TEAMS
import Teams from "../features/Team/Index";
import TeamShow from "../features/Team/Show";

// TRANSACTIONS
import TransactionIndex from "../features/Transaction/Index";

// CREDIT SUBMISSIONS
import CreditSubmissionIndex from "../features/CreditSubmission/Index";

// FINANCING OPTIONS
import FinancingOptionsNew from "../features/FinancingOption/New";
import FinancingOptionsShow from "../features/FinancingOption/Show";
import FinancingOptionsShare from "../features/FinancingOption/Share/v1.5/Share";

//  VENDORS
import VendorsNew from "../features/Vendor/New";

// VENDOR PAYMENTS
import VendorPaymentsNew from "../features/VendorPayment/New";
import VendorPaymentsShow from "../features/VendorPayment/Show";
import InvoiceConfirm from "../features/Invoice/Confirm";

// CREDIT SUBMISSIONS
import CreditSubmissionNew from "../features/CreditSubmission/New";
import CreditSubmissionShow from "../features/CreditSubmission/Show";

//  VENDOR SETTINGS
import VendorAdminSettingsIndex from "../features/VendorAdmin/Settings/Index";
import VendorAdminSettingsShow from "../features/VendorAdmin/Settings/Show";

// HOME
import HomeIndex from "../features/Home/Index";

// OTHER PAGES
import Customer from "../features/Customer";
import PublicPaymentCalculator from "../features/PublicPaymentCalculator";
import Section179Calculator from "../features/Section179Calculator";
import FourZeroFour from "./404";
import LoadingScreen from "./Auth/Loading";
import RequestAccess from "../features/Opportunity/RequestAccess";
import Sandbox from "./Sandbox";
import FrameWrapper from "./App/FrameWrapper";

const App = (props) => {
  // Auth0 setup
  const {
    logout,
    user,
    error,
    isAuthenticated,
    isLoading: isLoadingAuth,
  } = useAuth0();

  const {
    currentContact,
    currentContactId,
    currentContactHasPermission,
    isOrganizationAdmin,
    isVendorAdmin,
    isTeamAdmin,
    isTeamMember,
    isCustomer,
    isLoadingPermissions,
    permissions,
  } = useContext(CurrentContactContext);

  // Pendo setup
  useEffect(() => {
    try {
      if (
        isAuthenticated &&
        currentContact.attributes &&
        pendo &&
        !isLoadingPermissions
      ) {
        pendo.initialize({
          visitor: {
            id: currentContactId, // Required if user is logged in
            email: currentContact.attributes.email, // Recommended if using Pendo Feedback, or NPS Email
            full_name: currentContact.attributes.name, // Recommended if using Pendo Feedback
            role: currentContact.attributes.is_organization_admin, // Optional
            is_organization_admin: isOrganizationAdmin,
            is_vendor_admin: isVendorAdmin,
            is_team_admin: isTeamAdmin,
            is_team_member: isTeamMember,
            is_customer: isCustomer,
            permissions: permissions,
          },
          // disableGuides: true,
        });
      }
    } catch (error) {
      console.warn(error);
    }
  }, [
    currentContact,
    currentContactId,
    isAuthenticated,
    isCustomer,
    isLoadingPermissions,
    isOrganizationAdmin,
    isTeamAdmin,
    isTeamMember,
    isVendorAdmin,
    permissions,
  ]);

  // Posthog setup
  const posthog = usePostHog();
  useEffect(() => {
    try {
      if (currentContact) {
        posthog?.identify(currentContact.id, {
          email: currentContact.attributes.email,
          name: currentContact.attributes.full_name,
          organization_admin: isOrganizationAdmin,
          customer: isCustomer,
        });
      }
    } catch (error) {
      console.warn(error);
    }
  }, [currentContact, isCustomer, isOrganizationAdmin, posthog, user]);

  const financingOptionsShare = () => <FinancingOptionsShare />;

  if (isLoadingAuth || isLoadingPermissions) return <LoadingScreen />;

  const sandbox = () => <Sandbox foo="foo" bar="bar" baz="baz" />;

  const customerRoutes = (
    <Switch>
      <ProtectedRoute exact path={"/"} component={Customer} />
      <ProtectedRoute exact path="/profile" component={Profile} />

      <Route exact path="/calculator" component={PublicPaymentCalculator} />
      <Route
        exact
        path="/section-179-calculator"
        component={Section179Calculator}
      />

      <ProtectedRoute
        exact
        path="/opportunities/:opportunityId/share"
        component={financingOptionsShare}
      />

      <ProtectedRoute
        exact
        path="/opportunities/:opportunityId/share/:pageStepId"
        component={financingOptionsShare}
      />

      {/* <ProtectedRoute
        exact
        path="/opportunities/:opportunityId/share/:contactId"
        component={financingOptionsShare}
      /> */}

      <Route
        exact
        path="/clients/:clientId/opportunities/:id"
        component={({
          match: {
            params: { id },
          },
        }) => <Redirect to={`/opportunities/${id}/share`} />}
      />

      <ProtectedRoute
        exact
        path="/request_access/clients/:clientId/opportunities/:opportunityId"
        component={RequestAccess}
      />

      <ProtectedRoute component={FourZeroFour} />
    </Switch>
  );

  const vendorRoutes = (
    <Switch>
      <ProtectedRoute
        exact
        path={"/"}
        component={
          isTeamMember || isVendorAdmin || isOrganizationAdmin
            ? HomeIndex
            : () => <LoadingScreen />
        }
      />

      <Route exact path="/calculator" component={PublicPaymentCalculator} />

      <Route
        exact
        path="/section-179-calculator"
        component={Section179Calculator}
      />

      <ProtectedRoute exact path="/sandbox" component={sandbox} />
      <ProtectedRoute exact path="/profile" component={Profile} />

      <ProtectedRoute
        exact
        path="/reset-password-callback"
        component={Auth0Callback}
      />

      <ProtectedRoute
        exact
        path="/opportunities/:opportunityId/share"
        component={financingOptionsShare}
      />

      <ProtectedRoute
        exact
        path="/opportunities/:opportunityId/share/:pageStepId"
        component={financingOptionsShare}
      />

      {/* <ProtectedRoute
        exact
        path="/opportunities/:opportunityId/share/:contactId"
        component={financingOptionsShare}
      /> */}

      <ProtectedRoute
        path="/admin"
        component={
          currentContact
            ? isOrganizationAdmin
              ? () => <Admin />
              : () => <FourZeroFour />
            : () => <LoadingScreen />
        }
      />

      <ProtectedRoute
        path="/vendor_admin"
        component={
          currentContact
            ? isVendorAdmin
              ? () => <VendorAdmin />
              : () => <FourZeroFour />
            : () => <LoadingScreen />
        }
      />

      <ProtectedRoute
        path="/vendor_admin"
        component={
          currentContact
            ? isVendorAdmin
              ? () => <VendorAdmin />
              : () => <FourZeroFour />
            : () => <LoadingScreen />
        }
      />

      <ProtectedRoute
        exact
        path="/opportunities/:opportunityId/purchase_options/:purchaseOptionType/new"
        component={FinancingOptionsNew}
      />
      <ProtectedRoute
        exact
        path="/opportunities/:opportunityId/purchase_options/:financingOptionId"
        component={FinancingOptionsShow}
      />

      <ProtectedRoute
        exact
        path="/opportunities/:id"
        component={OpportunitiesShow}
      />

      <ProtectedRoute
        exact
        path="/opportunities/:opportunityId/vendor_payments/new"
        component={VendorPaymentsNew}
      />
      <ProtectedRoute
        exact
        path="/opportunities/:opportunityId/vendor_payments/:id"
        component={VendorPaymentsShow}
      />

      <ProtectedRoute
        exact
        path="/opportunities/:opportunityId/final_invoice/new"
        component={VendorPaymentsNew}
      />

      <ProtectedRoute
        exact
        path="/opportunities/:opportunityId/final_invoice/:id"
        component={VendorPaymentsShow}
      />

      <ProtectedRoute
        exact
        path="/opportunities/:opportunityId/credit_submissions/new"
        component={CreditSubmissionNew}
      />

      <ProtectedRoute
        exact
        path="/opportunities/:opportunityId/credit_submissions/:id"
        component={CreditSubmissionShow}
      />

      <ProtectedRoute exact path="/clients/:id" component={AccountsShow} />

      <ProtectedRoute
        exact
        path="/clients/:clientId/opportunities/:id"
        component={({ match }) => (
          <ProtectedOpportunityShowComponent match={match} />
        )}
      />
      <ProtectedRoute
        exact
        path="/request_access/clients/:clientId/opportunities/:opportunityId"
        component={RequestAccess}
      />

      <ProtectedRoute
        exact
        path="/sending_success"
        component={OpportunitiesEmbeddedSendingSent}
      />

      <ProtectedRoute exact path="/vendors/new" component={VendorsNew} />

      <ProtectedRoute
        exact
        path="/invoices/:id/confirm"
        component={InvoiceConfirm}
      />

      <ProtectedRoute
        exact
        path="/opportunity/new"
        component={CreateOpportunity}
      />

      <Route exact path="/transactions" component={TransactionIndex} />

      <ProtectedRoute
        exact
        path="/credit_submissions"
        component={
          currentContact
            ? isOrganizationAdmin
              ? () => <CreditSubmissionIndex />
              : () => <FourZeroFour />
            : () => <LoadingScreen />
          }
        />

      <ProtectedRoute exact path="/teams" component={Teams} />

      <ProtectedRoute exact path="/teams/:id" component={TeamShow} />

      <ProtectedRoute path="/accounts" component={AccountsIndex} />

      <ProtectedRoute
        exact
        path="/vendor_settings"
        component={VendorAdminSettingsIndex}
      />
      <ProtectedRoute
        exact
        path="/vendor_settings/:id"
        component={VendorAdminSettingsShow}
      />

      <Route component={FourZeroFour} />
    </Switch>
  );

  const publicRoutes = (
    <Switch>
      <Route
        exact
        path="/opportunities/:opportunityId/share"
        component={financingOptionsShare}
      />

      <Route
        exact
        path="/opportunities/:opportunityId/share/:pageStepId"
        component={financingOptionsShare}
      />

      <Route
        exact
        path="/section-179-calculator"
        component={Section179Calculator}
      />

      <Route exact path="/calculator" component={PublicPaymentCalculator} />
    </Switch>
  );

  return (
    <AppProviderWrapper>
      <FrameWrapper>
        <ToastProvider>
          <ErrorBoundary>
            {isLoadingPermissions ? (
              <Route component={LoadingScreen} />
            ) : currentContact ? (
              isCustomer ? (
                customerRoutes
              ) : (
                vendorRoutes
              )
            ) : (
              publicRoutes
            )}
          </ErrorBoundary>
        </ToastProvider>
      </FrameWrapper>
    </AppProviderWrapper>
  );
};

const ProtectedOpportunityShowComponent = ({ match }) => {
  const { currentContact, isLoadingPermissions } = useContext(
    CurrentContactContext
  );

  if (!currentContact || isLoadingPermissions) return <LoadingScreen />;

  return <OpportunitiesShow />;
};
ProtectedOpportunityShowComponent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default App;
