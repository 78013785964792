import React from "react";
import { Notifier } from "@airbrake/browser";
import FatalError from "./FatalError";
import PropTypes from "prop-types";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };

    if (process.env.AIRBRAKE_PROJECT_ID && process.env.AIRBRAKE_PROJECT_KEY) {
      this.airbrake = new Notifier({
        projectId: process.env.AIRBRAKE_PROJECT_ID,
        projectKey: process.env.AIRBRAKE_PROJECT_KEY,
      });
    }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Send error to Airbrake
    this.airbrake &&
      this.airbrake.notify({
        error: error,
        params: { info: info },
      });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <FatalError />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
};

export default ErrorBoundary;
