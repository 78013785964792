import React from "react";
import PropTypes from "prop-types";
import { Badge } from "@shopify/polaris";
import { capitalizeFirstLetter } from "../../utilities";

const TransactionBadge = (props) => {
  const { status = "pending" } = props;

  const formatBadgeStatus = (status) => {
    switch (status) {
      case "pending":
        return "default";
      case "scheduled":
        return "info";
      case "completed":
        return "success";
      case "failed":
        return "critical";
      default:
        return "default";
    }
  };

  return (
    (<Badge tone={formatBadgeStatus(status)}>
      {capitalizeFirstLetter(status)}
    </Badge>)
  );
};

TransactionBadge.propTypes = {
  status: PropTypes.string.isRequired,
};

export default TransactionBadge;
