import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  Page,
  Layout,
  FormLayout,
  InlineStack,
  Button,
  TextField,
  Select,
  BlockStack,
  Labelled,
  Tabs,
  List,
  Link,
  Text,
  Box,
  RangeSlider,
  Divider,
  Bleed,
  RadioButton,
  Tooltip,
} from "@shopify/polaris";
import {
  useField,
  useForm,
  useReset,
  useChoiceField,
  asChoiceField,
  getValues,
} from "@shopify/react-form";
import { ResetMinor } from "@shopify/polaris-icons";
import PropTypes from "prop-types";
import { ResponsivePie } from "@nivo/pie";

import { formatCurrency } from "../utilities";

import PaymentCalculator from "../components/Calculator/PaymentCalculator";

const PublicPaymentCalculator = () => {

  const handleCalculatorChange = (values) => {
    console.log(values);
  };
  
  const pageMarkup = (
    <PaymentCalculator 
      onCalculatorChange={handleCalculatorChange}
    />
  );

  return pageMarkup;
};

export default PublicPaymentCalculator;
