import React, {useEffect} from "react";
import PropTypes from "prop-types";

// a react component that renders a Hubspot form based on a formId
const HubspotForm = ({formId}) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";

    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: process.env.HUBSPOT_PORTAL_ID,
          formId: formId,
          target: `#hubspotForm-${formId}`,
        });
      }
    });

    // Clean up the script element when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, [formId]);

  return <div id={`hubspotForm-${formId}`} />;
}

HubspotForm.propTypes = {
  formId: PropTypes.string.isRequired,
};

export default HubspotForm;
