import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import {
  Card,
  Page,
  Layout,
  Modal,
  Select,
  TextField,
  Toast,
} from "@shopify/polaris";

import FadeIn from "../../../components/FadeIn";
import TeamIndexTable from "../../../components/Team/IndexTable";

import {
  useGetVendorsQuery,
  useCreateVendorAdminTeamMutation,
} from "../../../services/api";

const VendorAdminTeams = () => {
  const history = useHistory();

  const [showAddTeamModal, setShowAddTeamModal] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [selectedVendorId, setSelectedVendorId] = useState("");
  const [selectedVendorStr, setSelectedVendorStr] = useState("");
  const [vendorItems, setVendorItems] = useState([]);
  const handleSelectChange = useCallback((value) => {
    setSelectedVendorStr(value);
  }, []);

  const [createVendorAdminTeam, { isLoading: isCreatingTeam }] =
    useCreateVendorAdminTeamMutation();

  const navigateToVendorAdminTeamShow = (team) => {
    history.push({
      pathname: `/vendor_admin/teams/${team.id}`,
      state: { team: { ...team } },
    });
  };

  const handleTeamNameChange = (name) => {
    setTeamName(name);
  };

  const { data: vendorData = [], isLoading: isLoadingVendors } =
    useGetVendorsQuery({filter: "vendor_admin"});

  const handleCreateTeam = () => {
    createVendorAdminTeam({ vendor_id: selectedVendorId, team_name: teamName })
      .then(() => {
        setShowToast(true);
        setToastMessage("Team created.");
        setShowAddTeamModal(false);

        return { status: "success" };
      })
      .catch((error) => console.log(error));
    setShowAddTeamModal(false);
  };

  useEffect(() => {
    if (vendorData.length > 0) {
      let vendorSet = [];
      setVendorItems(
        vendorData.map((item) => {
          return item?.attributes?.name;
        })
      );
      setSelectedVendorId(vendorData[0].attributes.id);
      setSelectedVendorStr(vendorData[0].attributes.name);
    }
  }, [vendorData]);

  useEffect(() => {
    let vendor = null;

    if (selectedVendorStr != "") {
      vendor = vendorData.find((vendor) => {
        return vendor?.attributes?.name == selectedVendorStr;
      });
    }
  }, [selectedVendorStr, vendorData]);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState();
  const toggleShowToast = useCallback(
    () => setShowToast((active) => !active),
    []
  );
  const handleDismissToast = () => {
    toggleShowToast();
    setToastMessage();
  };
  const toastMarkup = showToast ? (
    <Toast content={toastMessage} onDismiss={handleDismissToast} />
  ) : undefined;

  const addTeamModal = (
    <Modal
      open={showAddTeamModal}
      onClose={() => setShowAddTeamModal(false)}
      title={"New team"}
      loading={isCreatingTeam}
      primaryAction={{
        content: "Save",
        onAction: () => {
          handleCreateTeam();
        },
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => setShowAddTeamModal(false),
        },
      ]}
    >
      <Modal.Section>
        <Select
          label="Vendor"
          options={vendorItems}
          onChange={handleSelectChange}
          value={selectedVendorStr}
          requiredIndicator
        />
        <div style={{ paddingTop: "0.5rem" }}>
          <TextField
            label={"Team name"}
            type={"text"}
            autoComplete={"off"}
            value={teamName}
            onChange={handleTeamNameChange}
            requiredIndicator
          />
        </div>
      </Modal.Section>
    </Modal>
  );

  const pageIndexMarkup = (
    <FadeIn fadeIn>
      <Page
        fullWidth
        title="Teams"
        secondaryActions={[
          {
            content: "Add team",
            onAction: () => setShowAddTeamModal(true),
          },
        ]}
      >
        <Layout>
          <Layout.Section primary>
            <Card padding="0">
              <TeamIndexTable
                onTeamClick={navigateToVendorAdminTeamShow}
                itemLimit={10}
                isVendorAdmin={true}
              />
            </Card>
          </Layout.Section>
        </Layout>

        {addTeamModal}
        {toastMarkup}
      </Page>
    </FadeIn>
  );

  return pageIndexMarkup;
};

export default VendorAdminTeams;
