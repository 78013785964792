import React from "react";
import PropTypes from "prop-types";

import { Card, Badge, LegacyStack, Text, Icon, Link } from "@shopify/polaris";
import { CheckCircleIcon } from "@shopify/polaris-icons";

import { formatCurrency } from "../../../../../utilities";

const TermCard = (props) => {
  const {
    financingOption,
    optionIndex,
    onSelectOption,
    selectedOption,
    handleClickShowTerms,
    totalFinanceAmount,
  } = props;

  const isSelectedOption = selectedOption
    ? selectedOption.id == financingOption.id
    : false;
  const cardClass = isSelectedOption
    ? "FortFinancingOption__PublicCardUpdated FortFinancingOption__PublicCardUpdated--selected"
    : financingOption.attributes.selected
    ? "FortFinancingOption__PublicCardUpdated FortFinancingOption__PublicCardUpdated--selected"
    : "FortFinancingOption__PublicCardUpdated";

  const { financing_type: financingType } = financingOption.attributes;


  return <>
    <Card sectioned key={`option-${optionIndex}`}>
      <div
        className={cardClass}
        onClick={() => onSelectOption(financingOption)}
      >
        <LegacyStack alignment="center" wrap={false}>
          <LegacyStack.Item fill>
            <LegacyStack>
              <LegacyStack.Item>
                <Text variant="bodyMd" fontWeight="bold" as="h3">
                  {financingOption.attributes.title}
                </Text>
              </LegacyStack.Item>

              <LegacyStack.Item>
                {financingOption.attributes.selected && (
                  <LegacyStack alignment={"center"} spacing={"tight"}>
                    <Icon source={CheckCircleIcon} tone={"success"} />
                    <Text as="span" tone="success">
                      <span data-test="accepted-indicator">Accepted</span>
                    </Text>
                  </LegacyStack>
                )}
              </LegacyStack.Item>
            </LegacyStack>
          </LegacyStack.Item>

          <LegacyStack.Item>
            <Link
              removeUnderline={true}
              onClick={(e) => {
                e.stopPropagation();
                handleClickShowTerms(financingOption);
              }}
              external
            >
              Term Details
            </Link>
          </LegacyStack.Item>
        </LegacyStack>

        <Text variant="bodyMd" as="span" tone="subdued">
          {financingOption.attributes.details}
          {financingType == "Standard financing" &&
            " • Debtor owns the equipment free of liens at end of term"}
          {financingType === "Fair market value" &&
            " • At the end of the term lessee may: 1.) Return the equipment, 2.) Continue to rent for a defined term, or 3.) Buy the equipment for the fair market value."}
          {financingType !== "Net terms" &&
            ` • ${financingOption.attributes.term_length} months`}
          {financingOption.attributes.approved && (
            <>
              &nbsp; • <Badge tone="success">Approved</Badge>
            </>
          )}
        </Text>
      </div>
    </Card>
  </>;
};

TermCard.propTypes = {
  financingOption: PropTypes.object,
  optionIndex: PropTypes.number,
  onSelectOption: PropTypes.func,
  selectedOption: PropTypes.object,
  handleClickShowTerms: PropTypes.func,
  totalFinanceAmount: PropTypes.number,
};

export default TermCard;
