import React, { useState, useCallback } from "react";

import {
  InlineStack,
  Text,
  ProgressBar,
  Box,
  Button,
  Popover,
  Card,
  Bleed,
} from "@shopify/polaris";
import { CaretDownIcon } from "@shopify/polaris-icons";

import PropTypes from "prop-types";
import TaskList from "./TaskList";

const TaskListProgressBar = (props) => {
  const {
    completedTasksCount = 0,
    totalTasksCount = 0,
    opportunityId,
    hasPopover = true,
  } = props;

  const progress = ((completedTasksCount || 0) / (totalTasksCount || 1)) * 100;

  const [popoverActive, setPopoverActive] = useState(false);
  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );

  const progressBarContent = (
    <InlineStack
      wrap={false}
      gap={"200"}
      blockAlign={"center"}
      align={"space-evenly"}
    >
      <ProgressBar size={"small"} tone={"primary"} progress={progress} />
      <Box minWidth="32px">
        <InlineStack wrap={false} gap={"100"}>
          <Text variant={"bodySm"}>
            {completedTasksCount || "-"}
          </Text>
          <Text variant={"bodySm"}>
            /
          </Text>
          <Text variant={"bodySm"}>
            {totalTasksCount || "-"}
          </Text>
        </InlineStack>
      </Box>

      {hasPopover && (
        <Bleed marginInlineStart={"100"}>
          <Box paddingBlockStart={"100"}>
            <Button
              variant="plain"
              icon={CaretDownIcon}
              accessibilityLabel={"Toggle task list"}
            />
          </Box>
        </Bleed>
      )}
    </InlineStack>
  );

  const popoverActivator = (
    <div onClick={togglePopoverActive} style={{ cursor: "pointer" }}>
      {progressBarContent}
    </div>
  );

  const popover = (
    <Popover
      active={popoverActive}
      activator={popoverActivator}
      autofocusTarget="first-node"
      onClose={togglePopoverActive}
    >
      <Box padding={"200"}>
        <TaskList opportunityId={opportunityId} />
      </Box>
    </Popover>
  );

  return hasPopover ? popover : progressBarContent;
};

TaskListProgressBar.propTypes = {
  completedTasksCount: PropTypes.number,
  totalTasksCount: PropTypes.number,
  hasPopover: PropTypes.bool,
  opportunityId: PropTypes.string,
};

export default TaskListProgressBar;
