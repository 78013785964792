import React, { useState, useCallback, useRef, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  Card,
  Page,
  Layout,
  Modal,
  Button,
  Toast,
} from "@shopify/polaris";

import VendorProgramForm from "../../../components/VendorProgram/Form";
import FadeIn from "../../../components/FadeIn";
import LoadingScreen from "../../../components/Auth/Loading";
import VendorProgramIndexTable from "../../../components/VendorProgram/IndexTable";

import { CurrentContactContext } from "../../../contexts/Contact";

const VendorPrograms = () => {

  const { currentContact } = useContext(CurrentContactContext)

  const history = useHistory();
  const location = useLocation();

  const [showAddVendorProgramModal, setShowAddVendorProgramModal] = useState(false);
  const handleCloseModal = () => {
    setShowAddVendorProgramModal(false);
  };

  const [isCreatingVendorProgram, setIsCreatingVendorProgram] = useState(false);

  const [vendorProgramFormDirty, setVendorProgramFormDirty] = useState(false);
  const handleAddVendorProgram = (isDirty) => {
    setVendorProgramFormDirty(isDirty);
  };
  const handleCreateVendorProgram = () => {
    handleCloseModal();
    setShowToast(true);
    setToastMessage("Vendor Program added.");
  };

  const vendorProgramFormRef = useRef();

  const addVendorProgramModal = (
    <Modal
      open={showAddVendorProgramModal}
      onClose={handleCloseModal}
      title={"Add vendor program"}
      loading={isCreatingVendorProgram}
      primaryAction={{
        content: "Save",
        onAction: () => {
          setIsCreatingVendorProgram(true);
          vendorProgramFormRef.current.handleSubmitForm();
        },
        disabled: !vendorProgramFormDirty,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleCloseModal,
        },
      ]}
    >
      <VendorProgramForm
        history={history}
        ref={vendorProgramFormRef}
        vendorProgramId={""}
        vendorProgram={undefined}
        onCreateOrUpdate={handleCreateVendorProgram}
        onEdit={handleAddVendorProgram}
        isAdminContext={true}
        setIsCreatingVendorProgram={setIsCreatingVendorProgram}
      />
    </Modal>
  );

  const [showToast, setShowToast] = useState(location?.state?.showToast);
  const [toastMessage, setToastMessage] = useState(
    location?.state?.toastMessage
  );
  const toggleShowToast = useCallback(
    () => setShowToast((active) => !active),
    []
  );
  const handleDismissToast = () => {
    const { state: currentState } = location;
    if (currentState?.toastMessage) {
      delete currentState.toastMessage;
    }
    if (currentState?.showToast) {
      delete currentState.showToast;
    }
    window.history.replaceState({ ...currentState }, "");
    toggleShowToast();
    setToastMessage();
  };

  const toastMarkup = showToast ? (
    <Toast content={toastMessage} onDismiss={handleDismissToast} />
  ) : undefined;

  const PageMarkup = (
    <FadeIn fadeIn>
      <Page
        fullWidth
        title="Vendor Programs"
        primaryAction={
          <Button  onClick={()=>setShowAddVendorProgramModal(true)} variant="primary">
            Add vendor program
          </Button>
        }
      >
        <Layout>
          <Layout.Section>
            <Card padding="0">
              <VendorProgramIndexTable/>
            </Card>
          </Layout.Section>
        </Layout>
        {toastMarkup}
        {addVendorProgramModal}
      </Page>
    </FadeIn>
  );

  return currentContact ? PageMarkup : <LoadingScreen />;
};

export default VendorPrograms;
