import React, {
  useImperativeHandle,
  forwardRef,
  useEffect,
  useState,
} from "react";
import {
  FormLayout,
  TextField,
  Form,
  Banner,
  Button,
} from "@shopify/polaris";
import { useField, useForm, notEmpty } from "@shopify/react-form";
import PropTypes from "prop-types";

import {
  useUpdateTeamMutation,
} from "../../../services/api";

const AdminTeamForm = forwardRef((props, ref) => {
  const { teamId, team, onCreateOrUpdate, onEdit } = props;

  const [updateTeam, { isLoading: isUpdatingTeam }] = useUpdateTeamMutation();

  const {
    fields,
    submit,
    submitting,
    // reset,
    submitErrors,
    makeClean,
    dirty: teamFormDirty,
  } = useForm({
    fields: {
      name: useField({
        value: team ? team.attributes.name : "",
        validates: [notEmpty("Team name is required")],
      }),
    },
    async onSubmit(form) {
      if (teamId) {
        return updateTeam({ team_id: teamId, team_name: form.name, ...form })
          .unwrap()
          .then((response) => {
            makeClean();
            onCreateOrUpdate(response);
            return { status: "success" };
          })
          .catch((error) => {
            console.log(error);
            setShowErrorBanner(true);
            return {
              status: "fail",
              errors: [{ message: "Could not update team" }],
            };
          });
      }
    },
  });

  useEffect(() => {
    onEdit(teamFormDirty);
  }, [onEdit, teamFormDirty]);

  useImperativeHandle(ref, () => ({
    handleSubmitForm() {
      submit();
    },
  }));

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const errorBanner = submitErrors.length > 0 && showErrorBanner && (
    <Banner tone="critical" title="Something went wrong">
      {submitErrors.map((error, index) => {
        return <p key={index}>{error.message}</p>;
      })}
    </Banner>
  );

  return (
    <Form onSubmit={submit}>
      <FormLayout>
        {errorBanner}
        <TextField label="Name" autoComplete="off" {...fields.name} />
        {teamFormDirty && (
          <Button  submit loading={submitting || isUpdatingTeam} variant="primary">
            Save
          </Button>
        )}
      </FormLayout>
    </Form>
  );
});
AdminTeamForm.displayName = "AdminTeamForm";

AdminTeamForm.propTypes = {
  teamId: PropTypes.string,
  team: PropTypes.object,
  onCreateOrUpdate: PropTypes.func,
  onEdit: PropTypes.func,
};

export default AdminTeamForm;
