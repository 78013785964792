import React, { useEffect, useState, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Page, Layout, Tabs, Modal, Text, TextField } from "@shopify/polaris";

import {
  useGetVendorsQuery,
  useGetTagsQuery,
  useCreateTagMutation,
  useDeleteTagsMutation,
  // useGetVendorAdminsQuery,
} from "../../../services/api";

import FadeIn from "../../../components/FadeIn";
import LoadingScreen from "../../../components/Auth/Loading";
import VendorAdminSettingsAccountDetailsTab from "./Tabs/AccountDetails";
import VendorAdminListTab from "./Tabs/VendorAdminList";
import VendorAdminIntegratedPaymentsSettings from "./Tabs/IntegratedPaymentsSettings";
import VendorAdminSettingsTagsTab from "./Tabs/VendorTags";

import { CurrentContactContext } from "../../../contexts/Contact";

const VendorAdminSettingsShow = (props) => {
  const { id: vendorId } = useParams();

  const { currentContact } = useContext(CurrentContactContext);

  const { data: vendors = [], isLoading: isLoadingVendors } =
    useGetVendorsQuery();

  const { data: tagsData = null, isLoading: fetchingTags } = useGetTagsQuery();

  const [createTag, { isLoading: isCreatingTag }] = useCreateTagMutation();

  const [deleteTags, { isLoading: isDeletingTags }] = useDeleteTagsMutation();

  // const { data: admins = [], isLoading: queryIsLoading } =
  //   useGetVendorAdminsQuery({ vendorId: vendorId });

  const [currentVendor, setCurrentVendor] = useState();

  const [tagName, setTagName] = useState("");
  const [tags, setTags] = useState([]);
  const [allTagsSelected, setAllTagsSelected] = useState(false);
  const [selectedTagIds, setSelectedTagIds] = useState([]);
  const [createTagModalActive, setCreateTagModalActive] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const handleTagSelectionChange = useCallback(
    (selectedTagIds) => setSelectedTagIds(selectedTagIds),
    [selectedTagIds]
  );

  const handleAllTagsSelectionChange = useCallback(() => {
    () => setAllTagsSelected(!allTagsSelected);
  }, [allTagsSelected]);

  const handleCreateTagModalActiveChange = useCallback(
    () => setCreateTagModalActive(!createTagModalActive),
    [createTagModalActive]
  );

  const handleSetTagName = useCallback((value) => setTagName(value), []);

  const handleCreateTag = () => {
    return createTag({ tag: tagName, vendor_tags: true })
      .unwrap()
      .then(() => {
        setTagName("");
        handleCreateTagModalActiveChange();
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteTags = () => {
    setOpenConfirmationModal(false);

    return deleteTags({ tag_ids: selectedTagIds.join(",") })
      .unwrap()
      .then(() => {
        handleTagSelectionChange([]);
        handleAllTagsSelectionChange(false);
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (tagsData) {
      setTags(tagsData);
    }
  }, [tagsData]);

  useEffect(() => {
    if (vendors.length > 0) {
      for (let i = 0; i < vendors.length; i++) {
        if (vendors[i].id === vendorId) {
          setCurrentVendor(vendors[i]);
          break;
        }
      }
    }
  }, [vendors]);

  const [selected, setSelected] = useState(0);
  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );

  const tabs = [
    {
      id: "vendor-admins",
      content: "Vendor Admins",
      accessibilityLabel: "Vendor Admins",
      panelID: "vendor-admins",
      tab: <VendorAdminListTab vendorId={vendorId} />,
    },
    {
      id: "account-details",
      content: "Account Details",
      accessibilityLabel: "Account Details",
      panelID: "account-details",
      tab: <VendorAdminSettingsAccountDetailsTab vendor={currentVendor} />,
    },
    {
      id: "integrated-payments-settings",
      content: "Integrated payments settings",
      accessibilityLabel: "Integrated payments settings",
      panelID: "integrated-payments-settings",
      tab: <VendorAdminIntegratedPaymentsSettings vendor={currentVendor} />,
    },
    {
      id: "vendor-tag-settings",
      content: "Vendor Tags",
      accessibilityLabel: "Vendor Tags",
      panelID: "vendor-tag-settings",
      tab: (
        <VendorAdminSettingsTagsTab
          vendor={currentVendor}
          tags={tags}
          fetchingTags={fetchingTags}
          isCreatingTag={isCreatingTag}
          isDeletingTags={isDeletingTags}
          selectedResources={selectedTagIds}
          setSelectedResources={handleTagSelectionChange}
          allResourcesSelected={allTagsSelected}
          setAllResourcesSelected={handleAllTagsSelectionChange}
          setOpenConfirmationModal={setOpenConfirmationModal}
          handleCreateTagModalActiveChange={handleCreateTagModalActiveChange}
        />
      ),
    },
    //{
    //  id: 'program-details',
    //  content: 'Program Details',
    //  accessibilityLabel: 'Program Details',
    //  panelID: 'program-details',
    //  tab: <p>Program Details</p>
    //},
    //{
    //  id: 'payments-setup',
    //  content: 'Payments Setup',
    //  accessibilityLabel: 'Payments Setup',
    //  panelID: 'payments-setup',
    //  tab: <p>Payments Setup</p>
    //},
  ];

  const areYouSureModal = (
    <Modal
      open={openConfirmationModal}
      onClose={() => setOpenConfirmationModal(false)}
      title="Are you sure?"
      primaryAction={{
        content: "Confirm",
        onAction: handleDeleteTags,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => setOpenConfirmationModal(false),
        },
      ]}
    >
      <Modal.Section>
        <Text variant="bodyMd" as="p">
          {`You are about to delete ${selectedTagIds.length} tags. This action cannot be undone.`}
        </Text>
        <Text variant="bodyMd" as="p">
          {`Deleting tags will remove them from all opportunities.`}
        </Text>
      </Modal.Section>
    </Modal>
  );

  const createTagModal = (
    <Modal
      open={createTagModalActive}
      onClose={handleCreateTagModalActiveChange}
      title="New tag"
      loading={isCreatingTag}
      primaryAction={{
        content: "Save",
        onAction: handleCreateTag,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleCreateTagModalActiveChange,
        },
      ]}
    >
      <Modal.Section>
        <TextField
          label="Tag name"
          value={tagName}
          onChange={handleSetTagName}
          helpText="Can create multiple tags by separating with commas. Ex: 'Tag 1, Tag 2'"
          autoComplete="off"
        />
      </Modal.Section>
    </Modal>
  );

  const pageIndexMarkup = (
    <>
      <FadeIn fadeIn>
        <Page
          fullWidth
          title={
            currentVendor ? currentVendor.attributes.name : "Vendor Settings"
          }
          backAction={{ content: "Vendor Settings", url: "/vendor_settings" }}
        >
          <Layout>
            <Layout.Section>
              <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                {tabs[selected].tab}
              </Tabs>
            </Layout.Section>
          </Layout>
        </Page>
        {createTagModal}
        {areYouSureModal}
      </FadeIn>
    </>
  );

  return currentContact ? pageIndexMarkup : <LoadingScreen />;
};

export default VendorAdminSettingsShow;
