import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import queryString from "query-string";

const Auth0ProviderWithHistory = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const query = queryString.parse(search);

  const domain = process.env.AUTH0_DOMAIN;
  const clientId = process.env.AUTH0_CLIENT_ID;
  const redirectUri = process.env.AUTH0_CALLBACK_URL;
  const audience = process.env.AUTH0_AUDIENCE;

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && audience)) {
    return null;
  }

  const { login_hint, screen_hint } = query;

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        redirect_uri: redirectUri,
        screen_hint,
        login_hint,
      }}
      useRefreshTokens={true}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

Auth0ProviderWithHistory.propTypes = {
  children: PropTypes.any,
};

export default Auth0ProviderWithHistory;
