import React from "react";
import PropTypes from "prop-types";
import { Box } from "@shopify/polaris";

type Props = {
  children: string | JSX.Element | JSX.Element[];
}

const CardBoxTitle = ({ children } : Props) => {

  return (
    <Box
      as="section"
      paddingBlockEnd="0"
      paddingInlineEnd="400"
      paddingBlockStart="400"
      paddingInlineStart="400"
    >
      {children}
    </Box>
  );

};

CardBoxTitle.propTypes = {
  children: PropTypes.node.isRequired,
  background: PropTypes.string
};

export default CardBoxTitle;

