const AsyncLocalStorage = {
  getItem: (key) => new Promise((resolve, reject) => {
    try {
      const value = window.localStorage.getItem(key)
      resolve(value)
    } catch (error) {
      reject(error)
    }
  }),

  setItem: (key, value) => new Promise((resolve, reject) => {
    try {
      window.localStorage.setItem(key, value)
      resolve(value)
    } catch (error) {
      reject(error)
    }
  }),

  removeItem: (key) => new Promise((resolve, reject) => {
    try {
      const value = window.localStorage.getItem(key)
      window.localStorage.removeItem(key)
      resolve(value)
    } catch (error) {
      reject(error)
    }
  }),

  clear: () => new Promise((resolve, reject) => {
    try {
      window.localStorage.clear()
      resolve(true)
    } catch (error) {
      reject(error)
    }
  }),

  getAllKeys: () => new Promise((resolve, reject) => {
    try {
      const allKeys = Object.keys(window.localStorage)
      resolve(allKeys)
    } catch (error) {
      reject(error)
    }
  }),

  multiGet: (keys) => new Promise((resolve, reject) => {
    try {
      const values = keys.map(key => [ key, window.localStorage.getItem(key) ])
      resolve(values)
    } catch (error) {
      reject(error)
    }
  }),

  multiSet: (keyValuePairs) => new Promise((resolve, reject) => {
    try {
      keyValuePairs.forEach(keyValuePair => window.localStorage.setItem(keyValuePair[0], keyValuePair[1]))
      const newKeyValuePairs = keyValuePairs.map(keyValuePair => [ keyValuePair[0], window.localStorage.getItem(keyValuePair[0]) ])
      resolve(newKeyValuePairs)
    } catch (error) {
      reject(error)
    }
  }),
}

export default AsyncLocalStorage
