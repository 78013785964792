import React, {
  useImperativeHandle,
  forwardRef,
  useEffect,
  useState,
} from "react";
import {
  FormLayout,
  TextField,
  Select,
  Form,
  Banner,
} from "@shopify/polaris";
import { useField, useForm, notEmpty } from "@shopify/react-form";
import PropTypes from "prop-types";

import {
  useCreateVendorMutation,
} from "../../services/api";
import { stateOptions } from "../../utilities";

const VendorForm = forwardRef((props, ref) => {
  const {
    vendor = { attributes: {} },
    onCreateOrUpdate = () => {},
    onEdit = () => {},
  } = props;


  const [createVendor, { isLoading: isCreating }] = useCreateVendorMutation();

  const {
    fields,
    submit,
    submitErrors,
    makeClean,
    dirty: vendorFormDirty,
  } = useForm({
    fields: {
      name: useField({
        value: vendor ? vendor.attributes.name : "",
        validates: [notEmpty("Account name is required")],
      }),
      dba: useField({
        value: vendor ? vendor.attributes.dba : "",
        validates: [],
      }),
      billing_street: useField({
        value: vendor ? vendor.attributes.billing_street : "",
        validates: [],
      }),
      billing_country: useField({
        value: vendor ? vendor.attributes.billing_country : "",
        validates: [],
      }),
      billing_city: useField({
        value: vendor ? vendor.attributes.billing_city : "",
        validates: [],
      }),
      billing_state: useField({
        value: vendor ? vendor.attributes.billing_state || undefined : "",
        validates: [],
      }),
      billing_postal_code: useField({
        value: vendor ? vendor.attributes.billing_postal_code : "",
        validates: [],
      }),
    },
    async onSubmit(form) {
      const newVendorFormPayload = { ...vendor.attributes, ...form };

      return createVendor({ ...newVendorFormPayload })
        .unwrap()
        .then((response) => {
          makeClean();
          onCreateOrUpdate(response);
          return { status: "success" };
        })
        .catch((error) => {
          console.log(error);
          setShowErrorBanner(true);
          return {
            status: "fail",
            errors: [{ message: "Could not create vendor." }],
          };
        });
    },
  });

  useEffect(() => {
    onEdit(vendorFormDirty);
  }, [onEdit, vendorFormDirty]);

  useImperativeHandle(ref, () => ({
    handleSubmitForm() {
      submit();
    },
  }));

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const errorBanner = submitErrors.length > 0 && showErrorBanner && (
    <Banner tone="critical" title="Something went wrong">
      {submitErrors.map((error, index) => {
        return <p key={index}>{error.message}</p>;
      })}
    </Banner>
  );

  return (
    <Form onSubmit={submit}>
      <FormLayout>
        {errorBanner}
        <FormLayout.Group>
          <TextField
            label="Legal name"
            {...fields.name}
            requiredIndicator
            disabled={vendor.attributes.name}
            placeholder={"Search for company name above"}
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField label="DBA" {...fields.dba} />
        </FormLayout.Group>
        <TextField label="Street address" {...fields.billing_street} />
        <FormLayout.Group>
          <TextField label="City" {...fields.billing_city} />
          <Select
            label="State"
            placeholder={"Select state"}
            options={stateOptions}
            {...fields.billing_state}
          />
          <TextField label="Zip code" {...fields.billing_postal_code} />
        </FormLayout.Group>
      </FormLayout>
    </Form>
  );
});
VendorForm.displayName = "VendorForm";

VendorForm.propTypes = {
  vendorId: PropTypes.number,
  vendor: PropTypes.object,
  onCreateOrUpdate: PropTypes.func,
  onEdit: PropTypes.func,
};

export default VendorForm;
