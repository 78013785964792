import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";

import {
  Card,
  Box,
  Page,
  PageActions,
  Layout,
  Modal,
  Banner,
  BlockStack,
} from "@shopify/polaris";
import Pluralize from "pluralize";

import {
  useGetOpportunityQuery,
  useGetVendorPaymentQuery,
  useGetVendorPaymentAttachmentQuery,
} from "../../services/api";

import VendorPaymentForm from "../../components/VendorPayment/Form";
import AccountForm from "../../components/Account/Form";
import AttachmentUpload from "../../components/VendorPayment/AttachmentUpload";
import InvoiceEmbed from "../Invoice/Embed";
import FadeIn from "../../components/FadeIn";
import LoadingScreen from "../../components/Auth/Loading";

import { CurrentContactContext } from "../../contexts/Contact";

const VendorPaymentsShow = () => {
  const history = useHistory();
  const location = useLocation();

  const { opportunityId, id: vendorPaymentId } = useParams();

  const { currentContact } = useContext(CurrentContactContext);
  const [finalInvoice, setFinalInvoice] = useState(false);

  const { opportunity: opportunityObject } = location.state?.opportunity
    ? location?.state
    : {
        opportunity: { attributes: {} },
      };
  const {
    data: opportunity = opportunityObject,
    isLoading: isLoadingOpportunity,
  } = useGetOpportunityQuery(opportunityId);

  const navigateToOpportunitiesShow = useCallback(
    (state = {}) => {
      history.push({
        pathname: `/clients/${opportunity.attributes.client_id}/opportunities/${opportunityId}`,
        search: finalInvoice ? "?tab=payments-n-review" : "",
        state: state,
      });
    },
    [history, opportunity.attributes.client_id, opportunityId, finalInvoice]
  );

  const {
    data: vendorPayment = { attributes: {} },
    isLoading: isLoadingVendorPayment,
  } = useGetVendorPaymentQuery(
    { id: vendorPaymentId, opportunityId: opportunityId },
    { skip: !vendorPaymentId }
  );

  const { data: vendorPaymentAttachment = { attributes: {} } } =
    useGetVendorPaymentAttachmentQuery(
      { vendorPaymentId: vendorPaymentId },
      { skip: !vendorPaymentId }
    );

  const vendorPaymentFormRef = useRef();

  const handleUpdateVendorPayment = () => {
    navigateToOpportunitiesShow({
      showToast: true,
      toastMessage: "Vendor quote updated.",
    });
  };

  const vendorFormRef = useRef();
  const [showAddVendorModal, setShowAddVendorModal] = useState(false);
  const handleCloseModal = () => {
    setShowAddVendorModal(false);
  };

  const [vendorFormDirty, setVendorFormDirty] = useState(false);
  const handleVendorEdit = (isDirty) => {
    setVendorFormDirty(isDirty);
  };

  const handleCreateVendor = (vendor) => {
    setSelectedVendorId(vendor.id);
    handleCloseModal();
  };

  const [vendorFormQuery, setVenorFormQuery] = useState();
  const handleAddVendor = (searchQuery) => {
    setVenorFormQuery(searchQuery);
    setShowAddVendorModal(true);
  };

  const [selectedVendorId, setSelectedVendorId] = useState();

  useEffect(() => {
    if (opportunity?.attributes?.final_invoice_ids) {
      setFinalInvoice(
        opportunity.attributes.final_invoice_ids?.includes(vendorPaymentId)
      );
    }
  }, [opportunity, vendorPayment]);

  const addVendorModal = (
    <Modal
      open={showAddVendorModal}
      onClose={handleCloseModal}
      title={"Add vendor"}
      primaryAction={{
        content: "Save",
        onAction: () => vendorFormRef.current.handleSubmitForm(),
        disabled: !vendorFormDirty,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleCloseModal,
        },
      ]}
    >
      <AccountForm
        ref={vendorFormRef}
        onEdit={handleVendorEdit}
        onCreateOrUpdate={handleCreateVendor}
        resourceName={"vendor"}
        searchQuery={vendorFormQuery}
        isVendor={true}
      />
    </Modal>
  );

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [fieldErrors, setFieldErrors] = useState([]);
  const handleSubmitForm = useCallback(() => {
    if (vendorPaymentFormRef.current.hasFieldErrors()) {
      setFieldErrors(vendorPaymentFormRef.current.fieldErrors());
      setShowErrorBanner(true);
    } else {
      vendorPaymentFormRef.current.handleSubmitForm();
    }
  }, [vendorPaymentFormRef]);

  const pagePrimaryAction = {
    content: "Save",
    onAction: handleSubmitForm,
  };

  const pageMarkup = (
    <FadeIn fadeIn>
      <Page
        title={opportunity.attributes.name}
        subtitle={"Vendor quote"}
        primaryAction={pagePrimaryAction}
        fullWidth
      >
        {showErrorBanner && (
          <Layout>
            <Layout.Section variant="fullWidth">
              <BlockStack>
                <Banner
                  tone="critical"
                  title={`To save this vendor quote, ${
                    fieldErrors.length
                  } ${Pluralize(
                    "error",
                    fieldErrors.length
                  )} need to be corrected.`}
                >
                  {fieldErrors.map((error, index) => {
                    return <p key={index}>{error}</p>;
                  })}
                </Banner>
              </BlockStack>
            </Layout.Section>
            <Layout.Section></Layout.Section>
          </Layout>
        )}

        <Layout>
          <Layout.Section variant="oneHalf">
            <Card>
              <BlockStack gap="400">
                {vendorPayment.attributes.invoice_url ? (
                  <InvoiceEmbed
                    invoiceContentType={vendorPayment.attributes.content_type}
                    invoiceUrl={vendorPayment.attributes.invoice_url}
                    size="small"
                  />
                ) : (
                  <AttachmentUpload
                    opportunityId={opportunityId}
                    vendorPaymentId={vendorPaymentId}
                    isWithinCard={true}
                    onUploadSuccess={() => {}}
                  />
                )}
              </BlockStack>
            </Card>
          </Layout.Section>

          <Layout.Section variant="oneHalf">
            <Card>
              <VendorPaymentForm
                ref={vendorPaymentFormRef}
                clientId={opportunity.attributes.client_id}
                opportunityId={opportunityId}
                vendorPayment={vendorPayment}
                vendorId={selectedVendorId}
                onCreateOrUpdate={handleUpdateVendorPayment}
                onAddVendor={handleAddVendor}
                finalInvoice={finalInvoice}
              />
            </Card>
          </Layout.Section>
        </Layout>

        <Layout>
          <Layout.Section></Layout.Section>

          <Layout.Section>
            <PageActions primaryAction={pagePrimaryAction} />
          </Layout.Section>
        </Layout>

        {addVendorModal}
      </Page>
    </FadeIn>
  );

  return currentContact ? pageMarkup : <LoadingScreen />;
};

VendorPaymentsShow.propTypes = {};

export default VendorPaymentsShow;
