import React from "react";
import PropTypes from "prop-types";

import { Card, InlineStack, Text, Icon } from "@shopify/polaris";
import { StoreIcon } from "@shopify/polaris-icons";

const ClientCard = (props) => {
  const { clientAccount } = props;

  const cardClass =
    "FortSigner__PublicCardUpdated FortSigner__PublicCardUpdated--selected";

  return (
    <>
      <Card sectioned>
        <div className={cardClass}>
          <InlineStack gap="400" align="start" blockAlign="center" wrap={false}>
            <Text>
              <Icon source={StoreIcon} />
            </Text>

            <div>
              <Text variant="bodyMd" fontWeight="bold" as="h3">
                {clientAccount?.attributes?.name || ""}
              </Text>

              <Text variant="bodyMd" as="span" tone="subdued">
                {clientAccount?.attributes?.billing_address || ""}
              </Text>
            </div>
          </InlineStack>
        </div>
      </Card>
    </>
  );
};

ClientCard.propTypes = {
  clientAccount: PropTypes.object,
};

export default ClientCard;
