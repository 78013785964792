import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";

import {
  Card,
  Box,
  Text,
  InlineStack,
  BlockStack,
  InlineGrid,
  Divider,
  Button,
  Page,
  Layout,
  Modal,
  Toast,
  Banner,
  IndexFilters,
  ChoiceList,
  Pagination,
  useSetIndexFiltersMode,
} from "@shopify/polaris";

import {
  useGetAdminAccountQuery,
  useGetLendersQuery,
  useCreateAdminAccountReviewMutation,
} from "../../../services/api";

import AccountForm from "../../../components/Account/Form";
import FadeIn from "../../../components/FadeIn";
import AdminClientOpportunityList from "../../../components/Admin/Client/OpportunityList";
import CreditPreview from "../../../components/Opportunity/CreditPreview";
import LoadingScreen from "../../../components/Auth/Loading";
import CreditSubmissionTable from "../../../components/Admin/CreditSubmission/CreditSubmissionTable";

import { CurrentContactContext } from "../../../contexts/Contact";

const AdminClientShow = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [Ids, setIds] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedLender, setSelectedLender] = useState([]);
  const [lenderChoiceList, setLenderChoiceList] = useState([]);
  const [queryValue, setQueryValue] = useState("");
  const [sortSelected, setSortSelected] = useState(["created_date asc"]);
  const [selected, setSelected] = useState(0);
  const limit = 25;

  const { mode, setMode } = useSetIndexFiltersMode();

  const { id: clientId } = useParams();
  const { currentContact, isOrganizationAdmin, isFinanceSpecialist } =
    useContext(CurrentContactContext);

  useEffect(() => {
    if (!clientId) {
      navigateToAdminAccountsIndex();
    }
  }, [clientId, navigateToAdminAccountsIndex]);

  const { client: clientObject } = location?.state?.client
    ? location.state
    : {
        client: { attributes: {} },
      };

  const { data: client = clientObject } = useGetAdminAccountQuery(clientId);
  const [createAccountReview, { isLoading: isCreatingAccountReview }] =
    useCreateAdminAccountReviewMutation();

  const { data: lenders = [] } = useGetLendersQuery();

  const onCreateNewView = async (value) => {
    await sleep(500);
    setItemStrings([...itemStrings, value]);
    setSelected(itemStrings.length);
    return true;
  };

  const navigateToAdminAccountsIndex = useCallback(() => {
    history.push("/admin");
  }, [history]);

  const navigateToOpportunityShow = useCallback(
    (clientId, opportunityId) => {
      history.push(`/clients/${clientId}/opportunities/${opportunityId}`);
    },
    [history]
  );

  const handleCreateOpportunity = () => {
    history.push({
      pathname: "/opportunity/new",
      state: { clientId: clientId },
    });
  };

  useEffect(() => {
    if (lenders.length > 0) {
      setLenderChoiceList(
        lenders.map((lender) => ({
          label: lender.attributes.name,
          value: lender.id,
        }))
      );
    }
  }, [lenders]);

  const handleFiltersQueryChange = useCallback((value) => {
    setQueryValue(value);
    setPage(1);
  }, []);

  const handleOnSelect = (viewIndex) => {
    setSelected(viewIndex);
    setQueryValue("");
    setPage(1);
  };

  const handleChangePage = (mode) => {
    setIsLoading(true);
    setPage((state) => {
      if (mode == "next" && state == Math.ceil(count / limit)) {
        return Math.ceil(count / limit);
      } else if (mode == "previous" && state == 1) {
        return 1;
      }

      if (mode == "next") {
        return state + 1;
      } else if (mode == "previous") {
        return state - 1;
      }
    });
  };

  const handleSelectLender = (lender) => {
    setSelectedLender(lender);
  };

  const handleOpportunityClick = (opportunity) => {
    navigateToOpportunityShow(opportunity.attributes.client_id, opportunity.id);
  };

  const filters = [
    {
      key: "lender",
      label: "Lender",
      filter: (
        <ChoiceList
          title="Lender"
          titleHidden
          choices={lenderChoiceList}
          selected={selectedLender || []}
          onChange={handleSelectLender}
          allowMultiple
        />
      ),
      shortcut: true,
    },
  ];

  const tablePagination = (
    <Pagination
      hasPrevious={page != 1}
      hasNext={page < Math.ceil(count / limit)}
      onPrevious={() => {
        handleChangePage("previous");
      }}
      onNext={() => {
        handleChangePage("next");
      }}
      label={
        <Text>
          Page {page} of {Math.ceil(count / limit)}
        </Text>
      }
    />
  );

  const opportunitiesResourceList = (
    <AdminClientOpportunityList
      onCreateOpportunity={handleCreateOpportunity}
      onOpportunityClick={handleOpportunityClick}
      clientId={clientId}
    />
  );

  const [showEditAccountDetailsModal, setShowAccountDetailsModal] =
    useState(false);
  const handleEditAccountDetails = () => {
    setShowAccountDetailsModal(true);
  };
  const handleCloseModal = () => {
    setShowAccountDetailsModal(false);
    setShowReviewAccountModal(false);
  };

  const [clientFormDirty, setAccountFormDirty] = useState(false);
  const handleAccountEdit = (isDirty) => {
    setAccountFormDirty(isDirty);
  };
  const handleAccountUpdate = (data) => {
    handleCloseModal();
    setShowToast(true);
    setToastMessage("Account updated.");
  };

  const clientFormRef = useRef();

  const editAccountModal = (
    <Modal
      open={showEditAccountDetailsModal}
      onClose={handleCloseModal}
      title="Edit client"
      primaryAction={{
        content: "Save",
        onAction: () => clientFormRef.current.handleSubmitForm(),
        disabled: !clientFormDirty,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleCloseModal,
        },
      ]}
    >
      <AccountForm
        ref={clientFormRef}
        account={client}
        onCreateOrUpdate={handleAccountUpdate}
        onEdit={handleAccountEdit}
        isAdminContext={true}
      />
    </Modal>
  );

  const [showReviewAccountModal, setShowReviewAccountModal] = useState(false);
  const handleReviewAccount = () => {
    return createAccountReview({
      accountId: clientId,
    })
      .unwrap()
      .then(({ data }) => {
        handleCloseModal();
        setShowToast(true);
        setToastMessage("Account reviewed.");
        return { status: "success" };
      })
      .catch((error) => {
        alert("Failed to add review.");
        console.log(error);
      });
  };

  const reviewAccountModal = (
    <Modal
      open={showReviewAccountModal}
      onClose={handleCloseModal}
      title="Review client"
      primaryAction={{
        content: "Add review",
        onAction: handleReviewAccount,
        loading: isCreatingAccountReview,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleCloseModal,
        },
      ]}
      sectioned
    >
      Please check the legal business name and all other client details before
      adding your review.
    </Modal>
  );

  const [showToast, setShowToast] = useState(location?.state?.showToast);
  const [toastMessage, setToastMessage] = useState(
    location?.state?.toastMessage
  );
  const toggleShowToast = useCallback(
    () => setShowToast((active) => !active),
    []
  );
  const handleDismissToast = () => {
    const { state: currentState } = location;
    if (currentState?.toastMessage) {
      delete currentState.toastMessage;
    }
    if (currentState?.showToast) {
      delete currentState.showToast;
    }
    window.history.replaceState({ ...currentState }, "");
    toggleShowToast();
    setToastMessage();
  };
  const toastMarkup = showToast ? (
    <Toast content={toastMessage} onDismiss={handleDismissToast} />
  ) : undefined;

  const pageMarkup = (
    <FadeIn fadeIn>
      <Page
        title={client.attributes.name}
        subtitle={"Client Account"}
        secondaryActions={[
          {
            content: "Review",
            onAction: () => setShowReviewAccountModal(true),
          },
        ]}
        fullWidth
      >
        <Layout>
          {!client.attributes.reviewed && (
            <Layout.Section>
              <Banner
                title="This client has not been reviewed by a Fortify admin."
                action={{
                  content: "Review",
                  onAction: () => setShowReviewAccountModal(true),
                }}
                tone="warning"
              ></Banner>
            </Layout.Section>
          )}

          {/* Account details */}
          <Layout.Section primary>
            <Card padding="0">
              <Box padding="400">
                <InlineStack align="space-between">
                  <Text variant="headingMd" as="h2">
                    Account information
                  </Text>
                  <Button
                    onClick={() => handleEditAccountDetails()}
                    variant="plain"
                  >
                    Edit client
                  </Button>
                </InlineStack>
              </Box>
              <Box padding="400" paddingBlockStart="0">
                <InlineGrid columns={4} gap="2">
                  <BlockStack gap="100">
                    <Text as="label">Legal name</Text>
                    <Text as="p">{client.attributes.name}</Text>
                  </BlockStack>
                  <BlockStack gap="100">
                    <Text as="label">DBA</Text>
                    <Text as="p">{client.attributes.dba || "-"}</Text>
                  </BlockStack>

                  <BlockStack gap="100">
                    <Text as="label">Email</Text>
                    <Text as="p">{client.attributes.email || "-"}</Text>
                  </BlockStack>
                  <BlockStack gap="100">
                    <Text as="label">Phone number</Text>
                    <Text as="p">{client.attributes.phone || "-"}</Text>
                  </BlockStack>
                </InlineGrid>
              </Box>
              <Divider borderColor="border" />
              <Box padding="400">
                <BlockStack gap="100">
                  <Text as="label">Street address</Text>
                  <Text as="p">{client.attributes.billing_street || "-"}</Text>
                  <InlineGrid columns={3} gap="2">
                    <BlockStack gap="100">
                      <Text as="label">City</Text>
                      <Text as="p">
                        {client.attributes.billing_city || "-"}
                      </Text>
                    </BlockStack>
                    <BlockStack gap="100">
                      <Text as="label">State</Text>
                      <Text as="p">
                        {client.attributes.billing_state || "-"}
                      </Text>
                    </BlockStack>
                    <BlockStack gap="100">
                      <Text as="label">Zip code</Text>
                      <Text as="p">
                        {client.attributes.billing_postal_code || "-"}
                      </Text>
                    </BlockStack>
                  </InlineGrid>
                </BlockStack>
              </Box>
            </Card>
            {(isOrganizationAdmin || isFinanceSpecialist) && (
              <>
                <br />
                <CreditPreview
                  creditPreviewId={client.attributes.latest_credit_preview_id}
                  viewOnly={true}
                />
              </>
            )}
            <br />
            {clientId && client.attributes.type != "Lender" && (
              <Card padding="0">
                <Box padding="400">
                  <InlineStack align="space-between">
                    <Text variant="headingMd" as="h2">
                      Opportunities
                    </Text>
                    <Button
                      onClick={() => handleCreateOpportunity()}
                      variant="plain"
                    >
                      Create opportunity
                    </Button>
                  </InlineStack>
                </Box>
                <Box>{opportunitiesResourceList}</Box>
              </Card>
            )}
            <br />
            <Card padding="0">
              <Box padding="400" paddingBlockEnd={"0"}>
                <InlineStack align="space-between">
                  <Text variant="headingMd" as="h2">
                    Credit submissions
                  </Text>
                </InlineStack>
              </Box>
              <IndexFilters
                sortSelected={sortSelected}
                onSort={setSortSelected}
                queryValue={queryValue}
                queryPlaceholder={""}
                onQueryChange={handleFiltersQueryChange}
                onQueryClear={() => setQueryValue("")}
                cancelAction={{
                  onAction: () => {},
                  disabled: false,
                  loading: false,
                }}
                tabs={[]}
                selected={selected}
                onSelect={handleOnSelect}
                canCreateNewView={true}
                onCreateNewView={onCreateNewView}
                filters={filters}
                appliedFilters={[]}
                onClearAll={() => {}}
                mode={mode}
                setMode={setMode}
                filteringAccessibilityTooltip="Search (F)"
              />
              <CreditSubmissionTable
                setCount={setCount}
                page={page}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                limit={limit}
                setIds={setIds}
                queryValue={queryValue}
                clientIdFilter={clientId}
                clientShowPage={true}
                lender={selectedLender.join(",")}
              />
              <InlineStack align="center">
                <div style={{ padding: "1.3rem" }}>
                  {count > 0 ? tablePagination : <></>}
                </div>
              </InlineStack>
            </Card>
          </Layout.Section>
        </Layout>

        {editAccountModal}
        {reviewAccountModal}
        {toastMarkup}
      </Page>
    </FadeIn>
  );

  return currentContact ? pageMarkup : <LoadingScreen />;
};

export default AdminClientShow;
