import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import {
  FormLayout,
  Autocomplete,
  Icon,
  BlockStack,
  TextField,
  Collapsible,
  Button,
} from "@shopify/polaris";
import { LocationIcon, SearchIcon } from "@shopify/polaris-icons";
import { useDebounce } from "use-debounce";
import { useGetCompaniesSearchQuery } from "../../services/api";

const AccountSearchForm = (props) => {
  const { selectCompany, deselectCompany, query, resourceName = "account" } = props;

  const [queryValue, setQueryValue] = useState("");
  const [address, setAddress] = useState("");
  const [searchQuery] = useDebounce(queryValue, 500);
  const [addressQuery] = useDebounce(address, 500);
  // const [activeAddressField, setActiveAddressField] = useState(false);
  const [skipQuery, setSkipQuery] = useState(false);
  const [hasSearchFocus, setHasSearchFocus] = useState(false);

  const handleQueryChange = (queryValue) => {
    setSkipQuery(false);
    setQueryValue(queryValue);
    setOptions([]);
  };

  useEffect(() => {
    if (query) {
      handleQueryChange(query);
      setHasSearchFocus(true);
    }
  }, [query]);

  const {
    data: { companies = [] } = [],
    isLoading,
    isFetching,
  } = useGetCompaniesSearchQuery(
    { query: searchQuery, address: addressQuery },
    {
      skip: searchQuery.length < 3 || skipQuery,
      refetchOnMountOrArgChange: true,
    }
  );

  // const handleAddressChange = (value) => {
  //   setAddress(value);
  // };

  // const handleAddAddress = () => {
  //   setActiveAddressField((state) => {
  //     if (state) {
  //       setAddress();
  //     }
  //     return !state;
  //   });
  // };

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState([]);

  const updateSelection = useCallback(
    (selected) => {
      const selectedValue = selected.find((selectedItem) => {
        const matchedOption = options.find((option) => {
          return option.value.match(selectedItem);
        });

        return matchedOption && matchedOption.label;
      });

      const { company: selectedCompany } = companies.find(({ company }) => {
        return company.company_number === selectedValue;
      });

      setSelectedOptions(selected);
      setSkipQuery(true);
      setQueryValue(selectedCompany?.name);
      selectCompany(selectedCompany);
    },
    [options]
  );

  const handleManuallyAddAccount = () => {
    setQueryValue("");
    setSelectedOptions([]);
    deselectCompany();
  };

  useEffect(() => {
    if (companies.length) {
      const formattedCompaniesArray = companies.map(({ company }) => {
        return {
          label: `${company?.name} ${
            company?.registered_address_in_full
              ? "- " + company?.registered_address_in_full
              : ""
          }`,
          value: company?.company_number,
        };
      });
      setOptions(formattedCompaniesArray);
    }
  }, [companies]);

  const emptyState = (
    <React.Fragment>
      <Icon source={SearchIcon} />
      <div style={{ textAlign: "center", padding: "12px" }}>
        <BlockStack>
          <p>Could not find any results for "{queryValue}"</p>
          <Button onClick={() => handleManuallyAddAccount()}>
            Add {resourceName} manually
          </Button>
        </BlockStack>
      </div>
    </React.Fragment>
  );

  const textField = (
    <Autocomplete.TextField
      onChange={handleQueryChange}
      value={queryValue}
      prefix={<Icon source={SearchIcon} tone="base" />}
      placeholder="Search legal entity name..."
      // labelAction={{
      //   content: `${activeAddressField ? "Remove" : "Add"} registered address`,
      //   onAction: handleAddAddress,
      // }}
      focused={hasSearchFocus}
    />
  );

  return (
    <FormLayout>
      <Autocomplete
        options={options}
        selected={selectedOptions}
        onSelect={updateSelection}
        textField={textField}
        emptyState={searchQuery.length > 2 && emptyState}
        loading={isLoading || isFetching}
        listTitle="Legal Company Name - Registered Address"
      />

      {/* <Collapsible
        open={activeAddressField}
        id="address-field"
        transition={{
          duration: "500ms",
          timingFunction: "ease-in-out",
        }}
      >
        <TextField
          value={address}
          placeholder="Registered address"
          onChange={handleAddressChange}
          prefix={<Icon source={LocationsIcon} color="base" />}
        />
      </Collapsible> */}
    </FormLayout>
  );
};

AccountSearchForm.propTypes = {
  selectCompany: PropTypes.func,
  deselectCompany: PropTypes.func,
  query: PropTypes.string,
  resourceName: PropTypes.string,
};

export default AccountSearchForm;
