import React, { useState, useCallback } from "react";
import {
  useGetOpportunityQuery,
  useDeleteOpportunityContactsMutation,
  useGetOpportunityContactsQuery,
  useUpdateOpportunityContactMutation,
} from "../../services/api";

import {
  Avatar,
  Badge,
  Text,
  ResourceItem,
  ResourceList,
  LegacyStack,
  BlockStack,
} from "@shopify/polaris";
import PropTypes from "prop-types";
import pluralize from "../../pluralizeConfig";

const OpportunityContactList = (props) => {
  const { opportunityId, onRemoveContacts = () => {}, removeable = false } = props;

  const resourceName = {
    singular: "contact",
    plural: "contacts",
  };

  const {
    data: opportunity = { attributes: {} },
  } = useGetOpportunityQuery(opportunityId);

  const {
    data: opportunityContacts = [],
    isLoading,
    isFetching,
  } = useGetOpportunityContactsQuery(opportunityId, { skip: !opportunityId });

  const [
    updateOpportunityContact,
    { isLoading: isUpdatingOpportunityContact },
  ] = useUpdateOpportunityContactMutation();

  const [
    deleteOpportunityContacts,
    { isLoading: isDeletingOpportunityContacts },
  ] = useDeleteOpportunityContactsMutation();

  const [selectedContacts, setSelectedContacts] = useState([]);

  const handleRemoveSelectedContacts = useCallback(async () => {
    try {
      await deleteOpportunityContacts({
        opportunityId: opportunityId,
        opportunity_contact_ids: selectedContacts,
      });
      setSelectedContacts([]);
      onRemoveContacts(selectedContacts.length);
    } catch (error) {
      console.log(error);
    }
  }, [
    deleteOpportunityContacts,
    onRemoveContacts,
    opportunityId,
    selectedContacts,
  ]);

  const handleMarkContactSigner = useCallback(async () => {
    const opportunityContactId = selectedContacts[0];

    if (opportunity.attributes.has_opportunity_envelopes) {
      const innerHTML = document.getElementById("signerLockError")
        .innerHTML.replaceAll("inlineErrorMsg", "Designated signer locked: Opportunity envelop already generated");
      document.getElementById("signerLockError").innerHTML = innerHTML;
      document.getElementById("signerLockErrorDiv").setAttribute("style", "padding: 20px 20px 0");
    }

    try {
      await updateOpportunityContact({
        opportunityId: opportunityId,
        id: opportunityContactId,
        signer: true,
      });

      setSelectedContacts([]);
    } catch (error) {
      console.log(error);
    }
  }, [opportunityId, selectedContacts, updateOpportunityContact]);

  const hasSignerContact = opportunityContacts.some(
    (item) => item?.attributes?.signer
  );
  const selectedContactsHasSigner = selectedContacts.some(
    (selectedContactId) => {
      const selectedContact = opportunityContacts.find((contact) => {
        return contact.id == selectedContactId;
      });
      return selectedContact.attributes.signer;
    }
  );

  const promotedBulkActions = [
    ...(removeable ? [{
      content: `Remove ${pluralize("contact", selectedContacts.length)}`,
      onAction: handleRemoveSelectedContacts,
    }] : []),
    {
      content: "Mark as signer",
      onAction: handleMarkContactSigner,
      disabled: selectedContacts.length != 1 ||
        selectedContactsHasSigner || opportunity.attributes.has_opportunity_envelopes,
      indicator: selectedContacts.length == 1 && !hasSignerContact,
    },
  ];

  const contactsResourceList = (
    <ResourceList
      resourceName={resourceName}
      items={opportunityContacts}
      renderItem={renderItem}
      emptyState={
        <div style={{ padding: "20px" }}>No contacts shared with</div>
      }
      loading={isFetching || isLoading || isUpdatingOpportunityContact}
      promotedBulkActions={promotedBulkActions}
      selectedItems={selectedContacts}
      onSelectionChange={setSelectedContacts}
      selectable
    />
  );

  return contactsResourceList;
};

const renderItem = (contact) => {
  const {
    id,
    attributes: { email, full_name, initials, signer },
  } = contact;

  const DESIGNATED_SIGNER_EMOJI = "✍️"

  const avatar = <Avatar size="sm" name={full_name} initials={initials} />;

  return (
    <ResourceItem
      id={id}
      name={full_name}
      accessibilityLabel={`Opportunity shared with ${full_name}`}
      media={avatar}
      verticalAlignment="center"
    >
      <LegacyStack alignment="center">
        <BlockStack spacing="tight">
          {full_name}
          <Text variant="bodySm" as="p" tone="subdued">
            {email}
          </Text>
        </BlockStack>

        {signer && <Badge>{DESIGNATED_SIGNER_EMOJI} Designated signer</Badge>}
      </LegacyStack>
    </ResourceItem>
  );
};

OpportunityContactList.propTypes = {
  onRemoveContacts: PropTypes.func,
  opportunityId: PropTypes.string,
  removeable: PropTypes.bool,
};

export default OpportunityContactList;
