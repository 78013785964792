import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  Page,
  Layout,
  InlineStack,
  Button,
  TextField,
  Text,
  Popover,
  ActionList,
  Spinner,
  DataTable,
  Banner,
  Link,
  Toast
} from "@shopify/polaris";

import { CurrentContactContext } from "../../../contexts/Contact";

import FadeIn from "../../../components/FadeIn";
import LoadingScreen from "../../../components/Auth/Loading";
import EditDocumentModal from "./EditDocumentModal";
import EditDocumentAssociationModal from "./EditDocumentAssociationModal";

import { Editor } from "@tinymce/tinymce-react";

import { editorSnippets, htmlSnippets } from "./SnippetItems";

import {
  useGetDocumentQuery,
  useUpdateDocumentMutation,
  usePreviewDocumentMutation,
} from "../../../services/api";

import { toHumanReadableFinanceType } from "../../../utilities";

const AdminDocumentShow = () => {
  const { id: documentId } = useParams();

  const { currentContact } = useContext(CurrentContactContext);

  const {
    data: document = { attributes: {} },
    isLoading: isLoadingDocument,
    refetch: refetchDocument,
  } = useGetDocumentQuery({ id: documentId });

  const [updateDocument, { isLoading: isUpdatingDocument }] =
    useUpdateDocumentMutation();

  const [previewDocument, { isLoading: isPreviewingDocument }] =
    usePreviewDocumentMutation();

  const [templateContents, setTemplateContents] = useState("");
  const [htmlContents, setHtmlContents] = useState("");
  const [showHtmlContents, setShowHtmlContents] = useState(false);
  const toggleShowHtmlContents = () => {
    setShowHtmlContents(!showHtmlContents);
  };

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const toggleShowToast = useCallback(
    () => setShowToast((active) => !active),
    []
  );
  const handleDismissToast = () => {
    toggleShowToast();
    setToastMessage("");
  };
  const toastMarkup = showToast ? (
    <Toast content={toastMessage} onDismiss={handleDismissToast} />
  ) : undefined;

  const editorRef = useRef(null);

  const handleShowHtmlEditorButton = () => {
    if (!showHtmlContents) {
      const editorContents = editorRef.current.getContent();
      setHtmlContents(editorContents);
    } else {
      setShowSnippetsPopover(false);
      setTemplateContents(htmlContents);
    }
    toggleShowHtmlContents();
  };

  const wysiwygEditorBlock = !showHtmlContents && (
    <Editor
      apiKey={process.env.TINY_MCE_KEY}
      onInit={(evt, editor) => (editorRef.current = editor)}
      value={templateContents}
      onEditorChange={(newValue, editor) => setTemplateContents(newValue)}
      init={{
        height: 500,
        menubar: false,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount",
          "table",
        ],
        toolbar:
          "bold italic underline |" +
          " alignleft alignright aligncenter alignjustify |" +
          " bullist numlist |" +
          "table |" +
          " snippets |",
        table_default_attributes: { border: 0 },
        setup: function (editor) {
          var toggleState = false;

          editor.ui.registry.addMenuButton("snippets", {
            text: "Snippets",
            fetch: function (callback) {
              var items = editorSnippets(editor);
              callback(items);
            },
          });
        },
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
      }}
    />
  );

  function typeInTextarea(newText) {
    let el = window.document.getElementById("PolarisHtmlEditor");
    const [start, end] = [el.selectionStart, el.selectionEnd];
    setHtmlContents(
      [htmlContents.slice(0, start), newText, htmlContents.slice(start)].join(
        ""
      )
    );
  }

  const insertLiquidSnippet = (snippet) => {
    typeInTextarea(snippet);
  };

  const htmlEditorBlock = showHtmlContents && (
    <TextField
      id={"PolarisHtmlEditor"}
      multiline={20}
      value={htmlContents}
      onChange={setHtmlContents}
    />
  );

  const saveDocumentTemplate = () => {
    return updateDocument({
      id: documentId,
      name: document.attributes.name,
      template: templateContents,
      body: templateContents,
    })
      .unwrap()
      .then(() => {
        console.log("success");

        setToastMessage("Document saved")
        setShowToast(true)

        // Refetch document to prevent old document from loading
        refetchDocument();

        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [showSnippetsPopover, setShowSnippetsPopover] = useState(false);
  const toggleShowSnippetsPopover = () => {
    setShowSnippetsPopover(!showSnippetsPopover);
  };
  const showSnippetsButton = (
    <Button onClick={toggleShowSnippetsPopover}>Snippets</Button>
  );
  const [htmlEditorSnippets, setHtmlEditorSnippets] = useState([]);

  useEffect(() => {
    let snippets = htmlSnippets(insertLiquidSnippet);
    setHtmlEditorSnippets(snippets);
  }, []);

  const [openEditDocumentModal, setOpenEditDocumentModal] = useState(false);
  const toggleEditDocumentModal = () => {
    setOpenEditDocumentModal(!openEditDocumentModal);
  };
  const editDocumentModal = (
    <EditDocumentModal
      documentId={documentId}
      openEditDocumentModal={openEditDocumentModal}
      toggleEditDocumentModal={toggleEditDocumentModal}
      refetchDocument={refetchDocument}
    />
  );

  const [
    openEditDocumentAssociationModal,
    setOpenEditDocumentAssociationModal,
  ] = useState(false);
  const toggleEditDocumentAssociationModal = () => {
    setOpenEditDocumentAssociationModal(!openEditDocumentAssociationModal);
  };
  const editDocumentAssociationModal = (
    <EditDocumentAssociationModal
      documentId={documentId}
      document={document}
      openEditDocumentAssociationModal={openEditDocumentAssociationModal}
      toggleEditDocumentAssociationModal={toggleEditDocumentAssociationModal}
      refetchDocument={refetchDocument}
    />
  );

  const [sortedFinanceOptionTypeRows, setSortedFinanceOptionTypeRows] =
    useState([]);

  function sortAlphabetically(rows, index, direction) {
    return [...rows].sort((rowA, rowB) => {
      const itemA = rowA[index] || 0;
      const itemB = rowB[index] || 0;

      return direction === "descending"
        ? itemB > itemA
          ? 1
          : -1
        : itemA > itemB
        ? 1
        : -1;
    });
  }

  const handleFinanceOptionSort = useCallback(
    (index, direction) => {
      let sortedRows = sortAlphabetically(
        sortedFinanceOptionTypeRows,
        index,
        direction
      );
      setSortedFinanceOptionTypeRows(sortedRows);
    },
    [sortedFinanceOptionTypeRows]
  );

  const [sortedVendorProgramRows, setSortedVendorProgramRows] = useState([]);

  const handleVendorProgramSort = useCallback(
    (index, direction) => {
      let sortedRows = sortAlphabetically(
        sortedVendorProgramRows,
        index,
        direction
      );
      setSortedVendorProgramRows(sortedRows);
    },
    [sortedVendorProgramRows]
  );

  useEffect(() => {
    if (document.attributes.template) {
      setTemplateContents(document.attributes.template);
    }

    if (document.attributes.associated_vendor_programs?.length > 0) {
      let vendorProgramRows = [];
      for (
        let i = 0;
        i < document.attributes.associated_vendor_programs.length;
        i++
      ) {
        vendorProgramRows.push([
          document.attributes.associated_vendor_programs[i].name,
        ]);
      }

      setSortedVendorProgramRows(vendorProgramRows);
    }

    if (document.attributes.financing_option_type?.length > 0) {
      let financeTypeRows = [];
      for (
        let i = 0;
        i < document.attributes.financing_option_type.length;
        i++
      ) {
        financeTypeRows.push([
          toHumanReadableFinanceType[
            document.attributes.financing_option_type[i].name
          ],
        ]);
      }

      setSortedFinanceOptionTypeRows(financeTypeRows);
    }
  }, [document]);

  const callPreviewDocument = () => {
    console.log("call preview document");

    return previewDocument({
      id: documentId,
      body: {},
    })
      .unwrap()
      .then((response) => {
        console.log("response");
        console.log(response);

        var newWindow = window.open();
        newWindow.document.write(response);

        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const PageMarkup = (
    <FadeIn fadeIn>
      {isLoadingDocument ? (
        <div style={{ margin: "auto", width: "10%" }}>
          <Spinner />
        </div>
      ) : (
        <Page
          fullWidth
          backAction={{ content: "Documents", url: "/admin/documents" }}
          title={document.attributes.name}
          secondaryActions={[
            {
              content: "Preview",
              accessibilityLabel: "Preview",
              onAction: callPreviewDocument,
              disabled: true
            },
          ]}
        >
          <Layout>
            <Layout.Section>
              <Banner
                title="Document editor is a work in progress."
                tone="warning"
              >
                <p>
                  This feature is very fresh and needs to be better documented
                  and tested. If you are unsure about something,{" "}
                  <Link
                    url="https://fort-internal.slack.com/team/U0211GNAXLN"
                    external
                  >
                    ask Tim
                  </Link>
                  .
                </p>
              </Banner>
            </Layout.Section>
            <Layout.Section>
              <Card>
                <div style={{ paddingBottom: "16px"}}>
                  <InlineStack gap="200" align="space-between">
                    <Text variant="headingMd" as="h6">
                      Document name
                    </Text>

                    <Button  onClick={() => { setOpenEditDocumentModal(true) }} variant="plain">
                      Edit
                    </Button>
                  </InlineStack>
                </div>

                <div>
                  <Text as="p" tone="subdued">
                    {document.attributes.name}
                  </Text>
                </div>
              </Card>
            </Layout.Section>

            <Layout.Section primary>
              <Card>
                <div style={{ paddingBottom: "16px"}}>
                  <InlineStack gap="200" align="space-between">
                    <Text variant="headingMd" as="h6">
                      Template
                    </Text>

                    <Button onClick={saveDocumentTemplate} loading={isUpdatingDocument} variant="primary">
                      Save
                    </Button>
                  </InlineStack>
                </div>

                <div style={{ paddingBottom: "16px"}}>
                  <InlineStack gap="200" align="end">
                      {showHtmlContents && (
                          <Popover
                            active={showSnippetsPopover}
                            activator={showSnippetsButton}
                            onClose={toggleShowSnippetsPopover}
                          >
                            <ActionList
                              actionRole="menuitem"
                              sections={htmlEditorSnippets}
                            />
                          </Popover>
                      )}

                    {!showHtmlContents && (
                      <Button onClick={handleShowHtmlEditorButton}>
                        {"{ / }"}
                      </Button>
                    )}

                    {showHtmlContents && (
                        <span className="html-editor-switcher-button">
                          <Button onClick={handleShowHtmlEditorButton}>
                            {"{ / }"}
                          </Button>
                        </span>
                    )}
                  </InlineStack>
                </div>

                <div>
                  {wysiwygEditorBlock}
                  {htmlEditorBlock}
                </div>
              </Card>

              <br />
              <br />
            </Layout.Section>

            <Layout.Section variant="oneThird">
              <Card padding="0">
                <div style={{ padding: "16px"}}>
                  <InlineStack gap="200" align="space-between">
                    <Text variant="headingMd" as="h6">
                      Associated with
                    </Text>

                    <Button onClick={() => { setOpenEditDocumentAssociationModal(true) }} variant="plain">
                      Edit
                    </Button>
                  </InlineStack>
                </div>

                <div flush>
                  <DataTable
                    columnContentTypes={["text"]}
                    headings={["Finance option types"]}
                    rows={sortedFinanceOptionTypeRows}
                    sortable={[true]}
                    defaultSortDirection="descending"
                    initialSortColumnIndex={0}
                    onSort={handleFinanceOptionSort}
                    hasZebraStripingOnData
                  />
                </div>
              </Card>

              <br />
              <br />

              <Card padding="0">
                <div style={{ padding: "16px"}}>
                  <InlineStack gap="200" align="space-between">
                    <Text variant="headingMd" as="h6">
                      Document available to
                    </Text>
                  </InlineStack>
                </div>

                <div style={{ padding: "16px"}}>
                  <Text as="p" tone="subdued">
                    Changes to this document will be available to the following
                    vendor programs
                  </Text>
                </div>

                <div flush>
                  <DataTable
                    columnContentTypes={["text"]}
                    headings={["Vendor programs"]}
                    rows={sortedVendorProgramRows}
                    sortable={[true]}
                    defaultSortDirection="descending"
                    initialSortColumnIndex={0}
                    onSort={handleVendorProgramSort}
                    hasZebraStripingOnData
                  />
                </div>
              </Card>
            </Layout.Section>
          </Layout>

          {toastMarkup}
          {editDocumentModal}
          {editDocumentAssociationModal}
        </Page>
      )}
    </FadeIn>
  );

  return currentContact ? PageMarkup : <LoadingScreen />;
};

export default AdminDocumentShow;
