import React from "react";
import PropTypes from "prop-types";
import { AppProvider } from "@shopify/polaris";
import { Link as ReactRouterLink } from "react-router-dom";
import enTranslations from "@shopify/polaris/locales/en.json";

export const AppProviderWrapper = ({ children }) => {
  const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;
  const AppLink = ({ children, url = "", external, ref, ...rest }) => {
    // react-router only supports links to pages it can handle itself. It does not
    // support arbirary links, so anything that is not a path-based link should
    // use a reglar old `a` tag
    if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
      rest.target = "_blank";
      rest.rel = "noopener noreferrer";
      return (
        <a href={url} {...rest}>
          {children}
        </a>
      );
    }

    return (
      <ReactRouterLink to={url} {...rest}>
        {children}
      </ReactRouterLink>
    );
  };

  return (
    <AppProvider i18n={enTranslations} linkComponent={AppLink} theme="light">
      {children}
    </AppProvider>
  );
};
AppProviderWrapper.propTypes = {
  children: PropTypes.any,
};

export default AppProviderWrapper;
