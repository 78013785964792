import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  Spinner,
  Card,
} from "@shopify/polaris";

import {
  useCreateDocusignEmbeddedSendingMutation
} from "../../services/api";

import emptySearchCompanies from "../../assets/emptySearchCompanies.svg";

const OpportunitiesEmbeddedSending = (props) => {

  const { id: opportunityId } = useParams();

  const [
    createDocusignEmbeddedSending,
    { isLoading: isCreatingDocusignEmbeddedSending },
  ] = useCreateDocusignEmbeddedSendingMutation();

  const [urlLoading, setUrlLoading] = useState(false);
  const [docusignRedirectUrl, setDocusignRedirectUrl] = useState();

  let componentMounted = true;

  // Generate on arrival of page
  useEffect(async () => {
    setUrlLoading(true);

    const body = {
      opportunityId: opportunityId,
    };

    let response = await createDocusignEmbeddedSending({
      opportunityId: opportunityId,
      ...body,
    })
    .then((response) => {
      if(response.data) {
        return response.data.redirect_url
      }
    })

    if (componentMounted){
      setDocusignRedirectUrl(response);
      setUrlLoading(false);
    }

    return () => {
      componentMounted = false;
    }
}, []);

  const embeddedSendingLayout = (
    <Card padding="400">
      { docusignRedirectUrl ?
        <iframe
          src={docusignRedirectUrl}
          style={{ width: "100%", height: "1000px" }}
          /> :
        <div style={{ paddingTop: "450px", textAlign: "center", width: "100%", height: "1000px" }}>
          <Spinner accessibilityLabel="Spinner example" size="large" />
        </div>
      }
    </Card>
  )

  return embeddedSendingLayout;
};

export default OpportunitiesEmbeddedSending;
