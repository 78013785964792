import React, { useState, useEffect, useCallback, useContext } from "react";
import PropTypes from "prop-types";
// import { useCookies } from "react-cookie";

import {
  Icon,
  Button,
  Text,
  BlockStack,
  InlineStack,
  InlineGrid,
  Modal,
  Banner,
} from "@shopify/polaris";
import { NoteIcon } from "@shopify/polaris-icons";

import { formatCurrency } from "../../../../../utilities";

import { CurrentContactContext } from "../../../../../contexts/Contact";

import {
  useGetOpportunityQuery,
  useGetFinancingOptionsQuery,
  useGetVendorPaymentsQuery,
  useCreateOpportunityEventMutation,
  useUpdateFinancingOptionMutation,
  useCreateFinancingOptionEventMutation,
} from "../../../../../services/api";

import FadeIn from "../../../../../components/FadeIn";
import InvoiceModal from "../../components/InvoiceModal";
import TermCard from "./TermCard";
import DisplayTermsModal from "../../components/DisplayTermsModal";
import Section179Ad from "./Section179Ad";

const SelectTerms = (props) => {
  const {
    opportunityId,
    hasFinalInvoice,
    setCanClickNext,
    setShowNextStepSpinner,
    hasStandardOptions = false,
    hasDollarOutOptions = false,
  } = props;

  const { currentContact } = useContext(CurrentContactContext);

  const {
    data: opportunity = { attributes: {} },
    isLoading: isLoadingOpportunity,
    error: opportunityError,
  } = useGetOpportunityQuery(opportunityId);

  const { data: financingOptions = [], isLoading: isLoadingFinancingOptions } =
    useGetFinancingOptionsQuery(opportunityId);

  const { data: vendorPayments = [], isLoading: isLoadingVendorPayments } =
    useGetVendorPaymentsQuery(
      {
        opportunityId: opportunityId,
        finalInvoice: hasFinalInvoice,
      },
      { skip: !opportunity }
    );

  const [createOpportunityEvent] = useCreateOpportunityEventMutation();
  const [createFinancingOptionEvent] = useCreateFinancingOptionEventMutation();

  const [updateFinancingOption, { isLoading: isUpdatingFinancingOption }] =
    useUpdateFinancingOptionMutation();

  const renderAddresss = (firstLine, secondLine) => {
    return (
      <div>
        <p>{firstLine}</p>
        <p>{secondLine}</p>
      </div>
    );
  };

  const customerBillToAddress = (opportunity) => {
    let address, firstLine, secondLine;
    if (
      opportunity.attributes.client_address &&
      opportunity.attributes.client_address.length > 0
    ) {
      address = opportunity.attributes.client_address.split(",");

      firstLine = address[0];
      secondLine = "";

      for (let i = 1; i < address.length; i++) {
        secondLine = secondLine + address[i];
        if (i != address.length - 1) {
          secondLine = secondLine + ",";
        }
      }
    }

    return renderAddresss(firstLine, secondLine);
  };

  const vendorShipToAddress = (opportunity) => {
    if (vendorPayments && vendorPayments.length > 0) {
      let vendorPayment = vendorPayments[0];
      let address = vendorPayment.attributes.product_address.split(",");

      let firstLine = address[0];
      let secondLine = "";

      for (let i = 1; i < address.length; i++) {
        secondLine = secondLine + address[i];
        if (i != address.length - 1) {
          secondLine = secondLine + ",";
        }
      }

      return renderAddresss(firstLine, secondLine);
    } else {
      return customerBillToAddress(opportunity);
    }
  };

  const handleCreateOpportunityEvent = useCallback(
    (type, contactId) => {
      return createOpportunityEvent({
        opportunityId: opportunityId,
        type: type,
        contact_id: contactId,
      })
        .unwrap()
        .then()
        .catch((error) => {
          console.log(error);
        });
    },
    [createOpportunityEvent, opportunityId]
  );

  const handleCreateFinancingOptionEvent = useCallback(
    (financingOptionId, type, contactId) => {
      return createFinancingOptionEvent({
        financingOptionId: financingOptionId,
        type: type,
        contact_id: contactId,
      })
        .unwrap()
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [createFinancingOptionEvent]
  );

  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
  const toggleOpenInvoiceModal = () => {
    if (!openInvoiceModal) {
      handleCreateOpportunityEvent(
        "VendorPaymentAttachmentViewed",
        currentContact?.id
      );
    }
    setOpenInvoiceModal(!openInvoiceModal);
  };
  const invoiceModal = openInvoiceModal && (
    <InvoiceModal
      finalInvoiceId={opportunity.attributes.final_invoice_ids?.length > 0}
      openInvoiceModal={openInvoiceModal}
      toggleOpenInvoiceModal={toggleOpenInvoiceModal}
      vendorPayments={vendorPayments}
    />
  );

  // The persisted selected option from the list of financing options
  const [selectedOption, setSelectedOption] = useState();
  // The current option that the user has selected
  const [optionSelection, setOptionSelection] = useState();

  useEffect(() => {
    const selectedOption = financingOptions.find(
      (financingOption) => financingOption.attributes.selected
    );
    selectedOption && setSelectedOption(selectedOption);
    if (selectedOption) setCanClickNext(true)
  }, [financingOptions]);

  const [
    showTermSelectionConfirmationModal,
    setShowTermSelectionConfirmationModal,
  ] = useState(false);

  const handleSelectOption = useCallback(
    (option) => {
      if (!selectedOption) {
        handleConfirmOptionSelection(option);
      } else {
        setOptionSelection(option);
        setShowTermSelectionConfirmationModal(true);
      }
    },
    [
      handleConfirmOptionSelection,
      selectedOption,
      setShowTermSelectionConfirmationModal,
    ]
  );

  const termSelectionConfirmationModal = (
    <Modal
      title={
        selectedOption != null && optionSelection == selectedOption
          ? "Undo choice?"
          : "Choose option?"
      }
      open={showTermSelectionConfirmationModal}
      onClose={() => setShowTermSelectionConfirmationModal(false)}
      primaryAction={{
        content: "Confirm",
        onAction: () => handleConfirmOptionSelection(optionSelection),
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => setShowTermSelectionConfirmationModal(false),
        },
      ]}
    >
      {selectedOption &&
        (optionSelection == selectedOption ? (
          <Modal.Section>
            <Text>
              Confirm that you would like to undo this choice and select a
              different option.
            </Text>
          </Modal.Section>
        ) : (
          <Modal.Section>
            <BlockStack gap={400}>
              {opportunity.attributes.has_opportunity_envelopes && (
                <Banner
                  title={"There are open documents for this opportunity"}
                  tone="warning"
                >
                  <p>
                    Changing your purchase option will void these documents and
                    new documents will be generated in their place if applicable
                    to the new purchase option.
                  </p>
                </Banner>
              )}
              <BlockStack gap={200}>
                <Text>Are you sure you want to choose:</Text>
                <Text fontWeight={"bold"} variant={"bodyLg"}>
                  {optionSelection?.attributes?.title}
                </Text>
                <Text>Please confirm your choice to continue.</Text>
              </BlockStack>
            </BlockStack>
          </Modal.Section>
        ))}
    </Modal>
  );

  const handleConfirmOptionSelection = useCallback(
    (option) => {
      // Select option normally
      if (selectedOption && selectedOption?.id == option.id) {
        setSelectedOption(null);
        handleNextSelectOption(option, false);
      } else {
        setSelectedOption(option);
        handleNextSelectOption(option, true);
      }

      setShowTermSelectionConfirmationModal(false);
    },
    [handleNextSelectOption, selectedOption]
  );

  const handleNextSelectOption = useCallback(
    (option, selected = true) => {
      const selectedFinancingOptionId = option.id;

      setCanClickNext(false);
      setShowNextStepSpinner(true);

      return updateFinancingOption({
        id: selectedFinancingOptionId,
        opportunityId: opportunityId,
        selected: selected,
      })
        .then((response) => {
          if (response?.data?.attributes?.selected) {
            handleCreateOpportunityEvent(
              "FinancingOptionSelected",
              currentContact?.id
            );

            switch (response?.data?.attributes?.financing_type) {
              case "Net terms":
                handleCreateOpportunityEvent(
                  "OpportunityNetTermsSelected",
                  currentContact.id
                );
                break;
              case "Standard financing":
                handleCreateOpportunityEvent(
                  "OpportunityStandardFinancingSelected",
                  currentContact.id
                );
                break;
              case "Fair market value":
                handleCreateOpportunityEvent(
                  "OpportunityFairMarketValueSelected",
                  currentContact.id
                );
                break;
              case "Dollar out lease":
                handleCreateOpportunityEvent(
                  "OpportunityDollarOutLeaseSelected",
                  currentContact.id
                );
                break;
              case "Rental":
                handleCreateOpportunityEvent(
                  "OpportunityRentalSelected",
                  currentContact.id
                );
                break;
              case "Software as a service":
                handleCreateOpportunityEvent(
                  "OpportunitySoftwareAsAServiceSelected",
                  currentContact.id
                );
                break;
              default:
                break;
            }
          }

          setShowNextStepSpinner(false);

          if (selected) setCanClickNext(true);

          return { status: "success" };
        })
        .catch((error) => {
          console.log(error);
          setShowNextStepSpinner(false);
        });
    },
    [
      setCanClickNext,
      setShowNextStepSpinner,
      updateFinancingOption,
      opportunityId,
      handleCreateOpportunityEvent,
      currentContact,
    ]
  );

  const [listOfFinancingOptions, setListOfFinancingOptions] = useState([]);
  const [hasRentalOption, setHasRentalOption] = useState(false);
  const [hasSaasOption, setHasSaasOption] = useState(false);
  const [hasSection179Ad, setHasSection179Ad] = useState(false);

  useEffect(() => {
    if (financingOptions.length > 0) {
      for (let i = 0; i < financingOptions.length; i++) {
        if (financingOptions[i].attributes.selected) {
          setCanClickNext(true);
          break;
        }
      }

      const netTermsOptions = financingOptions.filter(
        (option) => option.attributes.financing_type === "Net terms"
      );
      const standardFinancingOptions = financingOptions.filter(
        (option) => option.attributes.financing_type === "Standard financing"
      );
      const subscriptionOptions = financingOptions.filter(
        (option) =>
          option.attributes.financing_type === "Subscription license agreement"
      );
      const saasOptions = financingOptions.filter(
        (option) => option.attributes.financing_type === "Software as a service"
      );
      const fairMarketValueOptions = financingOptions.filter(
        (option) => option.attributes.financing_type === "Fair market value"
      );
      const dollarOutOptions = financingOptions.filter(
        (option) => option.attributes.financing_type === "Dollar out lease"
      );
      const rentalOptions = financingOptions.filter(
        (option) => option.attributes.financing_type === "Rental"
      );

      if (rentalOptions.length > 0) setHasRentalOption(true);
      if (saasOptions.length > 0) setHasSaasOption(true);
      if (
        netTermsOptions.length > 0 ||
        standardFinancingOptions.length > 0 ||
        fairMarketValueOptions.length > 0 ||
        dollarOutOptions.length > 0
      )
        setHasSection179Ad(true);

      const sortedOptions = [
        ...netTermsOptions,
        ...standardFinancingOptions,
        ...saasOptions,
        ...subscriptionOptions,
        ...fairMarketValueOptions,
        ...dollarOutOptions,
        ...rentalOptions,
      ];

      setListOfFinancingOptions(sortedOptions);
    }
  }, [financingOptions, setCanClickNext]);

  const [showDisplayTermsModal, setShowDisplayTermsModal] = useState(false);
  const toggleShowDisplayTermsModal = () => {
    setShowDisplayTermsModal(!showDisplayTermsModal);
  };

  const [currentFinancingOption, setCurrentFinancingOption] =
    useState(undefined);
  const handleClickShowTerms = (financingOption) => {
    setCurrentFinancingOption(financingOption);
    toggleShowDisplayTermsModal();

    handleCreateFinancingOptionEvent(
      financingOption.id,
      "ViewFinancingOption",
      currentContact?.id
    );
  };

  const displayTermsModal = showDisplayTermsModal && (
    <DisplayTermsModal
      showDisplayTermsModal={showDisplayTermsModal}
      toggleShowDisplayTermsModal={toggleShowDisplayTermsModal}
      financingOption={currentFinancingOption}
    />
  );

  return (
    <FadeIn fadeIn>
      <BlockStack gap={{ sm: "4", xs: "16" }}>
        {vendorPayments.map((vendorPayment, index) => (
          <InlineStack gap="400" key={index}>
            {vendorPayment.attributes.thumbnail_filename ? (
              <div className="pdf-thumbnail" onClick={toggleOpenInvoiceModal}>
                <div className="pdf-overlay">
                  <InlineStack>
                    <Icon source={NoteIcon} tone={"inherit"} />
                    <span>{vendorPayment.attributes.thumbnail_filename}</span>
                  </InlineStack>

                  <InlineStack align="center">
                    <div className="pdf-overlay-button">
                      <Button>View file</Button>
                    </div>
                  </InlineStack>
                </div>
                <img src={vendorPayment.attributes.thumbnail_url} />
              </div>
            ) : vendorPayment.attributes.invoice_url ? (
              <>
                <div className="pdf-thumbnail" onClick={toggleOpenInvoiceModal}>
                  <div className="pdf-overlay">
                    <InlineStack>
                      <Icon source={NoteIcon} tone={"inherit"} />
                      <span>Quote</span>
                    </InlineStack>

                    <InlineStack align="center">
                      <div className="pdf-overlay-button">
                        <Button>View file</Button>
                      </div>
                    </InlineStack>
                  </div>
                  <div style={{ maxHeight: "220px", maxWidth: "170px" }}>
                    <svg
                      width="170"
                      height="220"
                      viewBox="0 0 136 192"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="10"
                        y="10"
                        width="49"
                        height="6"
                        rx="3"
                        fill="#F1F2F3"
                      />
                      <rect
                        x="10"
                        y="20"
                        width="60"
                        height="6"
                        rx="3"
                        fill="#F1F2F3"
                      />
                      <rect
                        x="9"
                        y="56"
                        width="116"
                        height="7"
                        rx="3.5"
                        fill="#F1F2F3"
                      />
                      <rect
                        x="8"
                        y="67"
                        width="116"
                        height="7"
                        rx="3.5"
                        fill="#F1F2F3"
                      />
                      <rect
                        x="8"
                        y="78"
                        width="116"
                        height="7"
                        rx="3.5"
                        fill="#F1F2F3"
                      />
                      <rect
                        x="9"
                        y="89"
                        width="116"
                        height="7"
                        rx="3.5"
                        fill="#F1F2F3"
                      />
                      <rect
                        x="88"
                        y="100"
                        width="37"
                        height="7"
                        rx="3.5"
                        fill="#F1F2F3"
                      />
                      <rect
                        x="88"
                        y="111"
                        width="37"
                        height="7"
                        rx="3.5"
                        fill="#F1F2F3"
                      />
                      <circle cx="116.5" cy="17.5" r="8.5" fill="#F1F2F3" />
                      <rect
                        x="0.5"
                        y="0.5"
                        width="135"
                        height="191"
                        rx="3.5"
                        stroke="#C9CCCF"
                      />
                    </svg>
                  </div>
                </div>
              </>
            ) : (
              <div style={{ maxHeight: "220px", maxWidth: "170px" }}>
                <svg
                  width="170"
                  height="220"
                  viewBox="0 0 136 192"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="10"
                    y="10"
                    width="49"
                    height="6"
                    rx="3"
                    fill="#F1F2F3"
                  />
                  <rect
                    x="10"
                    y="20"
                    width="60"
                    height="6"
                    rx="3"
                    fill="#F1F2F3"
                  />
                  <rect
                    x="9"
                    y="56"
                    width="116"
                    height="7"
                    rx="3.5"
                    fill="#F1F2F3"
                  />
                  <rect
                    x="8"
                    y="67"
                    width="116"
                    height="7"
                    rx="3.5"
                    fill="#F1F2F3"
                  />
                  <rect
                    x="8"
                    y="78"
                    width="116"
                    height="7"
                    rx="3.5"
                    fill="#F1F2F3"
                  />
                  <rect
                    x="9"
                    y="89"
                    width="116"
                    height="7"
                    rx="3.5"
                    fill="#F1F2F3"
                  />
                  <rect
                    x="88"
                    y="100"
                    width="37"
                    height="7"
                    rx="3.5"
                    fill="#F1F2F3"
                  />
                  <rect
                    x="88"
                    y="111"
                    width="37"
                    height="7"
                    rx="3.5"
                    fill="#F1F2F3"
                  />
                  <circle cx="116.5" cy="17.5" r="8.5" fill="#F1F2F3" />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="135"
                    height="191"
                    rx="3.5"
                    stroke="#C9CCCF"
                  />
                </svg>
              </div>
            )}

            <BlockStack>
              <div style={{ marginBottom: "24px" }}>
                <BlockStack spacing="tight">
                  {opportunity.attributes.total_finance_amount !=
                    opportunity.attributes.total_sales_tax &&
                    !hasSaasOption &&
                    !hasRentalOption && (
                      <p data-test="finance-amount">
                        <Text as="span" fontWeight="semibold">
                          {opportunity?.attributes?.final_invoice_ids.length > 0
                            ? "Invoice"
                            : "Quote"}{" "}
                          Amount:
                        </Text>{" "}
                        {opportunity?.attributes?.final_invoice_id
                          ? formatCurrency(
                              opportunity.attributes.total_final_invoice_amount
                            )
                          : vendorPayment.attributes.invoice_amount}
                      </p>
                    )}
                  {!hasSaasOption && (
                    <Text variant="bodySm" tone="subdued" as="p">
                      {opportunity?.attributes?.final_invoice_ids.length > 0
                        ? "Invoice"
                        : "Quote"}{" "}
                      number: {vendorPayment.attributes.invoice_number}
                    </Text>
                  )}
                </BlockStack>
              </div>

              <InlineGrid gap="800" columns={2}>
                <BlockStack>
                  <Text variant="bodySm" as="p">
                    <Text as="span" tone="subdued">
                      BILL TO
                    </Text>
                  </Text>
                  <div
                    style={{
                      marginTop: "8px",
                    }}
                  >
                    <p>
                      <b>{opportunity.attributes.client_name}</b>
                    </p>
                    {opportunity.attributes.primary_contact_name && (
                      <p>
                        {"Attn: " + opportunity.attributes.primary_contact_name}
                      </p>
                    )}
                    {customerBillToAddress(opportunity)}
                  </div>
                </BlockStack>

                <BlockStack>
                  <Text variant="bodySm" as="p">
                    <Text as="span" tone="subdued">
                      SHIP TO
                    </Text>
                  </Text>
                  <div
                    style={{
                      marginTop: "8px",
                    }}
                  >
                    <p>
                      <b>{opportunity.attributes.client_name}</b>
                    </p>
                    {opportunity.attributes.primary_contact_name && (
                      <p>
                        {"Attn: " + opportunity.attributes.primary_contact_name}
                      </p>
                    )}
                    {vendorShipToAddress(opportunity)}
                  </div>
                </BlockStack>
              </InlineGrid>
            </BlockStack>
          </InlineStack>
        ))}
      </BlockStack>

      <br />
      <br />

      <BlockStack spacing="tight">
        {opportunity.attributes.total_finance_amount !=
          opportunity.attributes.total_sales_tax &&
          !hasSaasOption &&
          !hasRentalOption && (
            <p data-test="finance-amount">
              <Text as="span" fontWeight="semibold">
                Total amount of{" "}
                {opportunity?.attributes?.final_invoice_ids.length > 0
                  ? "invoice"
                  : "quote"}
                (s):
              </Text>{" "}
              {opportunity?.attributes?.final_invoice_ids.length > 0
                ? formatCurrency(
                    opportunity.attributes.total_final_invoice_amount
                  )
                : formatCurrency(opportunity.attributes.total_finance_amount)}
              *
            </p>
          )}

        {(hasStandardOptions || hasDollarOutOptions) && (
          <Section179Ad opportunity={opportunity} />
        )}
        <br />
      </BlockStack>

      <br />

      <BlockStack>
        <Text variant="headingXl" as="h4">
          Choose an option
        </Text>
      </BlockStack>

      <br />

      <BlockStack gap={400}>
        {listOfFinancingOptions.map((option, index) => {
          return (
            <TermCard
              key={index}
              financingOption={option}
              optionIndex={index}
              onSelectOption={handleSelectOption}
              selectedOption={selectedOption}
              handleClickShowTerms={handleClickShowTerms}
              totalFinanceAmount={opportunity.attributes.total_finance_amount}
            />
          );
        })}
      </BlockStack>

      {displayTermsModal}
      {invoiceModal}
      {termSelectionConfirmationModal}
    </FadeIn>
  );
};

SelectTerms.propTypes = {
  opportunityId: PropTypes.string,
  setCanClickNext: PropTypes.func,
  setShowNextStepSpinner: PropTypes.func,
  hasFinalInvoice: PropTypes.bool,
  hasStandardOptions: PropTypes.bool,
  hasDollarOutOptions: PropTypes.bool,
};

export { SelectTerms };
