import React from "react";

import {
  Text,
  ResourceItem,
  Avatar,
  Icon,
  InlineStack,
} from "@shopify/polaris";

import PropTypes from "prop-types";
import { toTitleCase } from "../../utilities";
import { BankIcon } from "@shopify/polaris-icons";

const PaymentMethodResourceItem = (props) => {
  const { id, card, us_bank_account, resourceItem = true } = props;

  if (card) {
    const { brand, last4 } = card;
    const brandNameTitleCase = toTitleCase(brand);

    let cardLogo;
    try {
      cardLogo = require(`../../assets/credit_cards/png/32/${brand}.png`);
    } catch (err) {
      cardLogo = require(`../../assets/credit_cards/png/32/credit_card.png`);
    }

    const media = (
      <Avatar
        size={"extraSmall"}
        source={cardLogo}
        accessibilityLabel={`Icon for ${brandNameTitleCase} ending ${last4}`}
      />
    );

    let cardContent = (
      <Text variant="bodyMd" fontWeight="bold" as="h3">
        {`${brandNameTitleCase} (••••${last4})`}
      </Text>
    );

    if (resourceItem) {
      return (
        <ResourceItem
          id={id}
          key={`key-${id}`}
          // media={<img src={cardLogo} className={"left-bar-logo"} />}
          media={media}
          accessibilityLabel={`${brandNameTitleCase} ending ${last4}`}
        >
          {cardContent}
        </ResourceItem>
      );
    } else {
      return (
        <InlineStack gap="400" wrap={false}>
          {media}
          <Text truncate={true} breakWord={false}>
            {cardContent}
          </Text>
        </InlineStack>
      );
    }
  } else if (us_bank_account) {
    const { bank_name, last4 } = us_bank_account;
    const bankNameTitleCase = toTitleCase(bank_name);

    const media = (
      <Icon
        source={BankIcon}
        accessibilityLabel={`Icon for ${bankNameTitleCase} account ending ${last4}`}
      />
    );

    let bankContent = (
      <Text variant="bodyMd" fontWeight="bold" as="h3">
        {`${bankNameTitleCase} (••••${last4})`}
      </Text>
    );

    if (resourceItem) {
      return (
        <ResourceItem
          id={id}
          key={`key-${id}`}
          media={media}
          accessibilityLabel={`${bankNameTitleCase} account ending ${last4}`}
        >
          {bankContent}
        </ResourceItem>
      );
    } else {
      return bankContent;
    }
  }
};

PaymentMethodResourceItem.propTypes = {
  id: PropTypes.string,
  card: PropTypes.object,
  us_bank_account: PropTypes.object,
  resourceItem: PropTypes.bool,
};

export default PaymentMethodResourceItem;
