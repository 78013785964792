import React, { useState, useEffect, useCallback } from "react";

import {
  Card,
  Text,
  Box,
  InlineStack,
  BlockStack,
  DropZone,
  Button,
  Icon,
  Modal,
  TextField,
  Spinner,
  Popover,
  ActionList,
  Link,
} from "@shopify/polaris";

import {
  AttachmentIcon,
  PlusCircleIcon,
  CheckCircleIcon,
  DeleteIcon,
  MenuHorizontalIcon,
} from "@shopify/polaris-icons";

import {
  useCreateOpportunityAttachmentsMutation,
  useUpdateOpportunityAttachmentMutation,
  usePurgeOpportunityAttachmentMutation,
  useDeleteOpportunityAttachmentMutation,
} from "../../../services/api";

import { DirectUploadProvider } from "react-activestorage-provider";
import CardBoxTitle from "../../../components/Card/BoxTitle";

const InternalFundingRequirements = (props) => {
  const {
    opportunityId,
    opportunityAttachments,
    refetchOpportunityAttachments,
  } = props;

  const [loadingDocuments, setLoadingDocuments] = useState({});
  const [documentPopovers, setDocumentPopovers] = useState([]);
  const [documentInput, setDocumentInput] = useState("");

  const [
    internalFundingRequirementsModalOpen,
    setInternalFundingRequirementsModalOpen,
  ] = useState(false);

  const [createOpportunityAttachments, { isLoading: isCreating }] =
    useCreateOpportunityAttachmentsMutation();
  const [updateOpportunityAttachment, { isLoading: isUpdating }] =
    useUpdateOpportunityAttachmentMutation();
  const [purgeOpportunityAttachment, { isLoading: isPurging }] =
    usePurgeOpportunityAttachmentMutation();
  const [deleteOpportunityAttachment, { isLoading: isDeleting }] =
    useDeleteOpportunityAttachmentMutation();

  const toggleInternalFundingRequirementsModalOpen = () => {
    setInternalFundingRequirementsModalOpen(
      !internalFundingRequirementsModalOpen
    );
  };

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles, handleUpload, documentId) => {
      // Set document to loading state
      setDocumentToLoadingState(documentId, true);

      // Save filename
      const uploadingFilenames = acceptedFiles.map((file) => file.name);

      handleUpload(acceptedFiles);
    },
    []
  );

  const setDocumentToLoadingState = (documentId, state) => {
    const loading = { ...loadingDocuments };
    loading[documentId] = state;
    setLoadingDocuments(loading);
  };

  const handleAttachment = (uploads, document) => {
    let documentId = document.id;

    return updateOpportunityAttachment({
      opportunityId,
      id: documentId,
      patch: {
        documents: uploads,
      },
    })
      .unwrap()
      .then(() => {
        refetchOpportunityAttachments();
        return { status: "success" };
      })
      .catch((error) => {
        setDocumentToLoadingState(documentId, false);
        console.error(error);
      });
  };

  const purgeAttachment = (document, dIndex) => {
    const documentId = document.id;
    const attachmentId = document.attributes.attachment_id[dIndex];

    setDocumentToLoadingState(documentId, true);

    return purgeOpportunityAttachment({
      opportunityId,
      id: documentId,
      attachmentId,
    })
      .unwrap()
      .then(() => {
        refetchOpportunityAttachments();
        return { status: "success" };
      })
      .catch((error) => {
        setDocumentToLoadingState(documentId, false);
        console.error(error);
      });
  };

  const deleteDocument = (document) => {
    const documentId = document.id;
    setDocumentToLoadingState(documentId, true);

    return deleteOpportunityAttachment({
      opportunityId,
      id: documentId,
    })
      .unwrap()
      .then(() => {
        refetchOpportunityAttachments();
        return { status: "success" };
      })
      .catch((error) => {
        setDocumentToLoadingState(documentId, false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (opportunityAttachments) {
      const list = {};
      const loading = {};
      const documentPopoverStatus = [];

      opportunityAttachments.forEach((attachment, index) => {
        list[attachment.attributes.name] = attachment;
        loading[attachment.id] = false;
        documentPopoverStatus.push(false);
      });

      setLoadingDocuments(loading);
      setDocumentPopovers(documentPopoverStatus);
    }
  }, [opportunityAttachments]);

  const toggleDocumentPopover = (index) => {
    const popoverStatus = [...documentPopovers];
    popoverStatus[index] = !popoverStatus[index];
    setDocumentPopovers(popoverStatus);
  };

  const addToInternalDocumentList = (publicDocuments = true) => {
    const listToAdd = [];
    if (documentInput.length > 0) listToAdd.push(documentInput);

    return createOpportunityAttachments({
      opportunityId: opportunityId,
      body: {
        documents: listToAdd,
        public: publicDocuments,
      },
    })
      .unwrap()
      .then(() => {
        if (internalFundingRequirementsModalOpen)
          toggleInternalFundingRequirementsModalOpen();
        refetchOpportunityAttachments();
        setDocumentInput("");
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
        setDocumentInput("");
        if (internalFundingRequirementsModalOpen)
          toggleInternalFundingRequirementsModalOpen();
      });
  };

  const addToInternalFundingRequirementsModal = (
    <Modal
      open={internalFundingRequirementsModalOpen}
      onClose={toggleInternalFundingRequirementsModalOpen}
      title="Add internal document"
      primaryAction={{
        content: "Add document",
        onAction: () => addToInternalDocumentList(false),
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: toggleInternalFundingRequirementsModalOpen,
        },
      ]}
    >
      <Modal.Section>
        <BlockStack>
          <TextField
            label="Folder name"
            value={documentInput}
            onChange={(value) => setDocumentInput(value)}
            autoComplete="off"
          />
        </BlockStack>
      </Modal.Section>
    </Modal>
  );

  const internalFundingRequirements = (
    <Card padding="0">
      <CardBoxTitle>
        <Text variant="headingMd" as="h6">
          Internal funding requirements
        </Text>
      </CardBoxTitle>

      {opportunityAttachments &&
        opportunityAttachments.map(
          (document, index) =>
            !document.attributes.public && (
              <Box key={index} padding="400" as="section">
                <Text variant="headingMd" as="h6">
                  {document.attributes.document === "" && (
                    <InlineStack align="space-between">
                      <InlineStack gap="400">
                        <Icon tone="subdued" source={AttachmentIcon} />
                        <Text as="span" fontWeight="semibold">
                          {document.attributes.name}
                        </Text>
                      </InlineStack>

                      <Popover
                        active={documentPopovers[index]}
                        activator={
                          <div
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              toggleDocumentPopover(index);
                            }}
                          >
                            <Icon source={MenuHorizontalIcon} />
                          </div>
                        }
                        autofocusTarget="first-node"
                        onClose={() => {
                          toggleDocumentPopover(index);
                        }}
                      >
                        <ActionList
                          actionRole="menuitem"
                          items={[
                            {
                              content: "Remove folder",
                              disabled:
                                document.attributes.document.length != 0,
                              onAction: () => {
                                deleteDocument(document);
                              },
                            },
                          ]}
                        />
                      </Popover>
                    </InlineStack>
                  )}
                </Text>
                <DirectUploadProvider
                  multiple={false}
                  onSuccess={(uploads) => {
                    handleAttachment(uploads, document);
                  }}
                  render={({ handleUpload, uploads, ready }) => (
                    <>
                      {/* Spinner when loading */}
                      {loadingDocuments[document.id] && (
                        <div
                          style={{
                            position: "relative",
                            minHeight: "118px",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "32px",
                              left: "calc(50% - 22px)",
                            }}
                          >
                            <Spinner
                              accessibilityLabel="Spinner example"
                              size="large"
                            />
                          </div>
                        </div>
                      )}

                      {/* Dropzone and uploaded content */}
                      {!loadingDocuments[document.id] && (
                        <>
                          {document.attributes.document.length > 0 && (
                            <>
                              <InlineStack align="space-between">
                                <InlineStack>
                                  <div>
                                    <Icon
                                      tone="success"
                                      source={CheckCircleIcon}
                                    />
                                  </div>
                                  <div style={{ marginLeft: "15px" }}>
                                    <Text as="span" tone="success">
                                      <b>{document.attributes.name}</b>
                                    </Text>
                                  </div>
                                </InlineStack>

                                <Popover
                                  active={documentPopovers[index]}
                                  activator={
                                    <div
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        toggleDocumentPopover(index);
                                      }}
                                    >
                                      <Icon source={MenuHorizontalIcon} />
                                    </div>
                                  }
                                  autofocusTarget="first-node"
                                  onClose={() => {
                                    toggleDocumentPopover(index);
                                  }}
                                >
                                  <ActionList
                                    actionRole="menuitem"
                                    items={[
                                      {
                                        content: "Remove folder",
                                        disabled:
                                          document.attributes.document.length !=
                                          0,
                                        onAction: () => {
                                          deleteDocument(document);
                                        },
                                      },
                                    ]}
                                  />
                                </Popover>
                              </InlineStack>

                              <br />

                              <DropZone
                                allowMultiple={true}
                                onDrop={(
                                  _dropFiles,
                                  acceptedFiles,
                                  _rejectedFiles
                                ) => {
                                  handleDropZoneDrop(
                                    _dropFiles,
                                    acceptedFiles,
                                    _rejectedFiles,
                                    handleUpload,
                                    document.id
                                  );
                                }}
                              >
                                <DropZone.FileUpload actionHint="or drop files to upload" />
                              </DropZone>

                              <br />

                              {document.attributes.document.map((d, dIndex) => (
                                <BlockStack key={dIndex}>
                                  <div
                                    style={{
                                      backgroundColor: "#F6F6F7",
                                      borderBottom: "1px solid #EBEBEB",
                                      padding: "1em",
                                    }}
                                  >
                                    <InlineStack align="space-between">
                                      <Text>
                                        {document.attributes.filenames[dIndex]
                                          ? document.attributes.filenames[
                                              dIndex
                                            ]
                                          : "-"}
                                      </Text>

                                      <InlineStack>
                                        <span
                                          style={{
                                            marginLeft: "30px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <Link url={d} removeUnderline={true}>
                                            View file
                                          </Link>
                                        </span>
                                        <span
                                          onClick={() => {
                                            purgeAttachment(document, dIndex);
                                          }}
                                          style={{
                                            marginLeft: "30px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <Icon
                                            source={DeleteIcon}
                                            tone="critical"
                                          />
                                        </span>
                                      </InlineStack>
                                    </InlineStack>
                                  </div>
                                </BlockStack>
                              ))}
                            </>
                          )}

                          <br />

                          {document.attributes.document.length == 0 && (
                            <BlockStack>
                              <InlineStack align="space-between">
                                <InlineStack>
                                  <div>
                                    <Icon source={AttachmentIcon} />
                                  </div>
                                  <div style={{ marginLeft: "15px" }}>
                                    <Text as="span">
                                      <b>{document.attributes.name}</b>
                                    </Text>
                                  </div>
                                </InlineStack>

                                <Popover
                                  active={documentPopovers[index]}
                                  activator={
                                    <div
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        toggleDocumentPopover(index);
                                      }}
                                    >
                                      <Icon source={MenuHorizontalIcon} />
                                    </div>
                                  }
                                  autofocusTarget="first-node"
                                  onClose={() => {
                                    toggleDocumentPopover(index);
                                  }}
                                >
                                  <ActionList
                                    actionRole="menuitem"
                                    items={[
                                      {
                                        content: "Remove folder",
                                        disabled:
                                          document.attributes.document.length !=
                                          0,
                                        onAction: () => {
                                          deleteDocument(document);
                                        },
                                      },
                                    ]}
                                  />
                                </Popover>
                              </InlineStack>

                              <br />

                              <DropZone
                                allowMultiple={true}
                                onDrop={(
                                  _dropFiles,
                                  acceptedFiles,
                                  _rejectedFiles
                                ) => {
                                  handleDropZoneDrop(
                                    _dropFiles,
                                    acceptedFiles,
                                    _rejectedFiles,
                                    handleUpload,
                                    document.id
                                  );
                                }}
                              >
                                <DropZone.FileUpload actionHint="or drop files to upload" />
                              </DropZone>
                            </BlockStack>
                          )}
                        </>
                      )}
                    </>
                  )}
                />
              </Box>
            )
        )}

      <Box as="section" padding="400">
        <Button fullWidth onClick={toggleInternalFundingRequirementsModalOpen}>
          <InlineStack align="center" blockAlign="center">
            <Icon tone="subdued" source={PlusCircleIcon}></Icon>
            Add document
          </InlineStack>
        </Button>
      </Box>
    </Card>
  );

  return (
    <>
      {internalFundingRequirements}

      {addToInternalFundingRequirementsModal}
    </>
  );
};

export default InternalFundingRequirements;
