import React from "react";
import PropTypes from "prop-types";
import { useField, useForm } from "@shopify/react-form";

import {
  Modal,
  FormLayout,
  TextField,
  Checkbox,
  Select,
} from "@shopify/polaris";

import {
  useUpdateAdminAccountMutation,
} from "../../services/api";

const QualificationFieldsForm = (props) => {
  const {
    account,
    accountId,
    showEditQualificationFieldsModal,
    setShowEditQualificationFieldsModal,
  } = props;

  const [updateAdminAccount, { isLoading: isAdminUpdating }] =
    useUpdateAdminAccountMutation();

  const { fields, submit, submitting } = useForm({
    fields: {
      portal_available: useField({
        value: account.attributes.portal_available || false,
        validates: [],
      }),
      submission_method: useField({
        value: account.attributes.submission_method,
        validates: [],
      }),
      rate_guidance_general: useField({
        value: account.attributes.rate_guidance_general,
        validates: [],
      }),
      rate_guidance_startup: useField({
        value: account.attributes.rate_guidance_startup,
        validates: [],
      }),
      rate_guidance_medical: useField({
        value: account.attributes.rate_guidance_medical,
        validates: [],
      }),
      cbrp_pull_type: useField({
        value: account.attributes.cbrp_pull_type,
        validates: [],
      }),
      cbr_details: useField({
        value: account.attributes.cbr_details,
        validates: [],
      }),
      ebo_details: useField({
        value: account.attributes.ebo_details,
        validates: [],
      }),
      bank_statements_required: useField({
        value: account.attributes.bank_statements_required || false,
        validates: [],
      }),
      financials_required: useField({
        value: account.attributes.financials_required || false,
        validates: [],
      }),
      financial_level: useField({
        value: account.attributes.financial_level,
        validates: [],
      }),
      e_docs_available: useField({
        value: account.attributes.e_docs_available || false,
        validates: [],
      }),
      ink_signed_app_required: useField({
        value: account.attributes.ink_signed_app_required || false,
        validates: [],
      }),
      corp_only_allowed: useField({
        value: account.attributes.corp_only_allowed || false,
        validates: [],
      }),
      startup_friendly: useField({
        value: account.attributes.startup_friendly || false,
        validates: [],
      }),
      titling_handling: useField({
        value: account.attributes.titling_handling,
        validates: [],
      }),
      lienholder_info: useField({
        value: account.attributes.lienholder_info,
        validates: [],
      }),
      paynet_base: useField({
        value: account.attributes.paynet_base,
        validates: [],
      }),
      paynet_required: useField({
        value: account.attributes.paynet_required || false,
        validates: [],
      }),
      min_fico_score: useField({
        value: account.attributes.min_fico_score,
        validates: [],
      }),
      min_time_in_business: useField({
        value: account.attributes.min_time_in_business,
        validates: [],
      }),
      min_annual_gross_revenue: useField({
        value: account.attributes.min_annual_gross_revenue,
        validates: [],
      }),
      app_only_limit_general: useField({
        value: account.attributes.app_only_limit_general,
        validates: [],
      }),
      app_only_limit_med_core: useField({
        value: account.attributes.app_only_limit_med_core,
        validates: [],
      }),
      app_only_limit_med_non_core: useField({
        value: account.attributes.app_only_limit_med_non_core,
        validates: [],
      }),
      app_only_limit_working_capital: useField({
        value: account.attributes.app_only_limit_working_capital,
        validates: [],
      }),
      min_ownership: useField({
        value: account.attributes.min_ownership,
        validates: [],
      }),
      point_range: useField({
        value: account.attributes.point_range,
        validates: [],
      }),
      doc_fee: useField({
        value: account.attributes.doc_fee,
        validates: [],
      }),
      doc_fee_additional: useField({
        value: account.attributes.doc_fee_additional,
        validates: [],
      }),
      time_to_decision: useField({
        value: account.attributes.time_to_decision,
        validates: [],
      }),
      max_term_length: useField({
        value: account.attributes.max_term_length,
        validates: [],
      }),
      structured_pays_allowed: useField({
        value: account.attributes.structured_pays_allowed || false,
        validates: [],
      }),
      deferrals_allowed: useField({
        value: account.attributes.deferrals_allowed || false,
        validates: [],
      }),
      typical_deal_size: useField({
        value: account.attributes.typical_deal_size,
        validates: [],
      }),
      credit_box_details: useField({
        value: account.attributes.credit_box_details,
        validates: [],
      }),
      prior_bk_restriction: useField({
        value: account.attributes.prior_bk_restriction,
        validates: [],
      }),
      comp_percentage_needed: useField({
        value: account.attributes.comp_percentage_needed,
        validates: [],
      }),
      age_restrictions: useField({
        value: account.attributes.age_restrictions,
        validates: [],
      }),
      fleet_requirements: useField({
        value: account.attributes.fleet_requirements,
        validates: [],
      }),
      pps_titled_allowed: useField({
        value: account.attributes.pps_titled_allowed || false,
        validates: [],
      }),
      pps_non_titled_allowed: useField({
        value: account.attributes.pps_non_titled_allowed || false,
        validates: [],
      }),
      otr_details: useField({
        value: account.attributes.otr_details,
        validates: [],
      }),
      funding_comments: useField({
        value: account.attributes.funding_comments,
        validates: [],
      }),
      restrictions: useField({
        value: account.attributes.restrictions,
        validates: [],
      }),
      additional_notes: useField({
        value: account.attributes.additional_notes,
        validates: [],
      }),
    },
    async onSubmit(form) {
      return updateAdminAccount({
        id: accountId,
        ...form,
      })
        .unwrap()
        .then(() => {
          setShowEditQualificationFieldsModal(false);
          return { status: "success" };
        })
        .catch((error) => {
          console.log(error);
          setShowEditQualificationFieldsModal(false);
          return {
            status: "fail",
            errors: [{ message: "Could not update account." }],
          };
        });
    },
  });

  const setBooleanField = (field) => {
    fields[field].onChange(!fields[field].value);
  };

  const formLayout = (
    <FormLayout>
      <FormLayout.Group>
        <TextField
          label="Rate guidance general"
          {...fields.rate_guidance_general}
        />
        <TextField
          label="Rate guidance startup"
          {...fields.rate_guidance_startup}
        />
        <TextField
          label="Rate guidance medical"
          {...fields.rate_guidance_medical}
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <Select
          label="CBRP pull type"
          options={[
            { label: "Soft", value: "Soft" },
            { label: "Hard", value: "Hard" },
          ]}
          placeholder="Select"
          {...fields.cbrp_pull_type}
        />
        <Select
          label="Submission Method"
          options={[
            { label: "Portal", value: "Portal" },
            { label: "Email", value: "Email" },
          ]}
          placeholder="Select"
          {...fields.submission_method}
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <TextField label="CBR details" multiline={4} {...fields.cbr_details} />
        <TextField label="EBO details" multiline={4} {...fields.ebo_details} />
      </FormLayout.Group>
      <FormLayout.Group>
        <Checkbox
          label="Portal Available"
          checked={fields.portal_available.value}
          onChange={() => setBooleanField("portal_available")}
        />
        <Checkbox
          label="Bank statements required"
          checked={fields.bank_statements_required.value}
          onChange={() => setBooleanField("bank_statements_required")}
        />
        <Checkbox
          label="Financials required"
          checked={fields.financials_required.value}
          onChange={() => setBooleanField("financials_required")}
        />
        <Checkbox
          label="Startup friendly"
          checked={fields.startup_friendly.value}
          onChange={() => setBooleanField("startup_friendly")}
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <Checkbox
          label="E Docs available"
          checked={fields.e_docs_available.value}
          onChange={() => setBooleanField("e_docs_available")}
        />
        <Checkbox
          label="Ink signed app required"
          checked={fields.ink_signed_app_required.value}
          onChange={() => setBooleanField("ink_signed_app_required")}
        />
        <Checkbox
          label="Corp only allowed"
          checked={fields.corp_only_allowed.value}
          onChange={() => setBooleanField("corp_only_allowed")}
        />
        <Checkbox
          label="Paynet required"
          checked={fields.paynet_required.value}
          onChange={() => setBooleanField("paynet_required")}
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <Select
          label="Financial level"
          options={[
            { label: "Tax Returns", value: "Tax Returns" },
            { label: "Reviewed", value: "Reviewed" },
            { label: "Audited", value: "Audited" },
            { label: "Standard", value: "Standard" },
          ]}
          placeholder="Select"
          {...fields.financial_level}
        />

        <Select
          label="Titling handling"
          options={[
            { label: "Customer", value: "Customer" },
            { label: "3rd Party", value: "3rd Party" },
            { label: "Lender", value: "Lender" },
          ]}
          placeholder="Select"
          {...fields.titling_handling}
        />
        <TextField label="Lienholder info" {...fields.lienholder_info} />
      </FormLayout.Group>
      <FormLayout.Group>
        <TextField label="Paynet base" {...fields.paynet_base} />
        <TextField label="Minimum FICO score" {...fields.min_fico_score} />
        <TextField
          label="Minimum time in business (year)"
          {...fields.min_time_in_business}
        />
        <TextField
          label="Minimum annual gross revenue"
          {...fields.min_annual_gross_revenue}
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <TextField
          label="App only limit general"
          {...fields.app_only_limit_general}
        />
        <TextField
          label="App only limit med core"
          {...fields.app_only_limit_med_core}
        />
        <TextField
          label="App only limit med non core"
          {...fields.app_only_limit_med_non_core}
        />
        <TextField
          label="App only limit working capital"
          {...fields.app_only_limit_working_capital}
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <TextField label="Minimum ownership" {...fields.min_ownership} />
        <TextField label="Doc fee" {...fields.doc_fee} />
      </FormLayout.Group>
      <FormLayout.Group>
        <TextField label="Point range" multiline={3} {...fields.point_range} />
        <TextField
          label="Doc fee additional"
          multiline={3}
          {...fields.doc_fee_additional}
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <TextField label="Time to decision" {...fields.time_to_decision} />
        <TextField label="Max term length" {...fields.max_term_length} />
      </FormLayout.Group>
      <FormLayout.Group>
        <Checkbox
          label="Structured pays allowed"
          checked={fields.structured_pays_allowed.value}
          onChange={() => setBooleanField("structured_pays_allowed")}
        />
        <Checkbox
          label="Deferrals allowed"
          checked={fields.deferrals_allowed.value}
          onChange={() => setBooleanField("deferrals_allowed")}
        />
        <Checkbox
          label="PPS titled allowed"
          checked={fields.pps_titled_allowed.value}
          onChange={() => setBooleanField("pps_titled_allowed")}
        />
        <Checkbox
          label="PPS non titled allowed"
          checked={fields.pps_non_titled_allowed.value}
          onChange={() => setBooleanField("pps_non_titled_allowed")}
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <TextField
          label="Fleet requirements"
          multiline={3}
          {...fields.fleet_requirements}
        />
        <TextField
          label="Credit box details"
          multiline={3}
          {...fields.credit_box_details}
        />
        <TextField
          label="Prior BK restriction"
          multiline={3}
          {...fields.prior_bk_restriction}
        />
        <TextField
          label="Compensation percentage needed"
          multiline={3}
          {...fields.comp_percentage_needed}
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <TextField label="Age restrictions" {...fields.age_restrictions} />
        <TextField label="Typical deal size" {...fields.typical_deal_size} />
      </FormLayout.Group>
      <FormLayout.Group>
        <TextField label="OTR details" multiline={3} {...fields.otr_details} />
        <TextField
          label="Funding comments"
          multiline={3}
          {...fields.funding_comments}
        />
        <TextField
          label="Restrictions"
          multiline={3}
          {...fields.restrictions}
        />
        <TextField
          label="Additional notes"
          multiline={3}
          {...fields.additional_notes}
        />
      </FormLayout.Group>
    </FormLayout>
  );

  return (
    <Modal
      open={showEditQualificationFieldsModal}
      size="large"
      onClose={() => setShowEditQualificationFieldsModal(false)}
      title="Edit qualification fields"
      primaryAction={{
        content: "Save",
        onAction: () => submit(),
        disabled: submitting || isAdminUpdating,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => setShowEditQualificationFieldsModal(false),
        },
      ]}
    >
      <div style={{ padding: "1rem" }}>{formLayout}</div>
    </Modal>
  );
};

QualificationFieldsForm.propTypes = {
  account: PropTypes.object,
  accountId: PropTypes.string,
  showEditQualificationFieldsModal: PropTypes.bool,
  setShowEditQualificationFieldsModal: PropTypes.func,
};

export default QualificationFieldsForm;
