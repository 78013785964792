import React, { useImperativeHandle, forwardRef, useEffect } from "react";

import {
  FormLayout,
  TextField,
  Select,
  Spinner,
  Form,
  Button,
  Banner,
  Link,
  BlockStack,
} from "@shopify/polaris";

import {
  useField,
  useSubmit,
  useDirty,
  makeCleanFields,
} from "@shopify/react-form";

import PropTypes from "prop-types";

import {
  useGetOpportunityCreditSubmissionQuery,
  useUpdateOpportunityCreditSubmissionMutation,
} from "../../services/api";

const ApprovedForm = forwardRef((props, ref) => {
  const {
    creditSubmissionId,
    creditSubmission: creditSubmissionObject = { attributes: {} },
    opportunityId,
    onUpdate = () => {},
    onEdit = () => {},
  } = props;

  const { data: creditSubmission = creditSubmissionObject, isLoading } =
    useGetOpportunityCreditSubmissionQuery(
      { id: creditSubmissionId, opportunityId: opportunityId },
      {
        skip: !creditSubmissionId,
      }
    );

  const [updateCreditSubmission, { isLoading: isUpdatingCreditSubmision }] =
    useUpdateOpportunityCreditSubmissionMutation();

  const endOptionOptions = ["EFA", "FMV", "$1 Out"];

  const fields = {
    approved_amount: useField({
      value: creditSubmission.attributes.approved_amount,
      validates: [],
    }),
    buy_rate: useField({
      value: creditSubmission.attributes.buy_rate,
      validates: [],
    }),
    end_option: useField({
      value: creditSubmission.attributes.end_option || undefined,
      validates: [],
    }),
    min_term_length: useField({
      value: creditSubmission.attributes.min_term_length,
      validates: [],
    }),
    max_term_length: useField({
      value: creditSubmission.attributes.max_term_length,
      validates: [],
    }),
    submitted_at: useField({
      value: new Date(
        creditSubmission.attributes.submitted_at?.split("-")
      ).toLocaleDateString("en-CA"),
      validates: [],
    }),
    approved_at: useField({
      value: new Date(
        creditSubmission.attributes.approved_at?.split("-")
      ).toLocaleDateString("en-CA"),
      validates: [],
    }),
    expires_at: useField({
      value: new Date(
        creditSubmission.attributes.expires_at?.split("-")
      ).toLocaleDateString("en-CA"),
      validates: [],
    }),
    external_identifier: useField({
      value: creditSubmission.attributes.external_identifier,
      validates: [],
    }),

    notes: useField({
      value: creditSubmission.attributes.notes,
      validates: [],
    }),
  };

  const approvedFormDirty = useDirty(fields);

  const { submit, errors } = useSubmit(async (form) => {
    return updateCreditSubmission({
      id: creditSubmissionId,
      opportunityId: opportunityId,
      ...form,
    })
      .then(({ data }) => {
        makeCleanFields(fields);
        onUpdate();
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
      });
  }, fields);

  useEffect(() => {
    onEdit(approvedFormDirty);
  }, [onEdit, approvedFormDirty]);

  useImperativeHandle(ref, () => ({
    handleSubmitForm() {
      submit();
    },
  }));

  const errorBanner = errors.length > 0 && (
    <BlockStack>
      <Banner tone="critical" title="Something went wrong">
        {errors.map((error, index) => {
          return (
            <p key={index}>
              {error.message}{" "}
              {error.account_id && (
                  <Link url={`/admin/vendors/${error.account_id}`}>
                    {error.account_name}
                  </Link>
                ) &&
                " vendor account."}
            </p>
          );
        })}
      </Banner>
    </BlockStack>
  );

  return isLoading ? (
    <Spinner />
  ) : (
    <Form onSubmit={submit}>
      <FormLayout>
        {errorBanner}
        <FormLayout.Group condensed>
          <div className="FortForm__NumericTextField">
            <TextField
              label="Buy rate"
              suffix={"%"}
              type="number"
              {...fields.buy_rate}
            />
          </div>

          <div className="FortForm__NumericTextField">
            <TextField
              label="Approved amount"
              prefix={"$"}
              type="number"
              {...fields.approved_amount}
            />
          </div>

          <Select
            label="End option"
            placeholder={"Select option"}
            options={endOptionOptions}
            {...fields.end_option}
          />
        </FormLayout.Group>
        <FormLayout.Group condensed>
          <div className="FortForm__NumericTextField">
            <TextField
              label="Minimum term"
              type="number"
              {...fields.min_term_length}
            />
          </div>
          <div className="FortForm__NumericTextField">
            <TextField
              label="Maximum term"
              type="number"
              {...fields.max_term_length}
            />
          </div>
        </FormLayout.Group>

        <FormLayout.Group condensed>
          <TextField
            label={"Submitted date"}
            type="date"
            {...fields.submitted_at}
          />

          <TextField
            label={"Approved date"}
            type="date"
            {...fields.approved_at}
          />

          <TextField
            label={"Expiration date"}
            type="date"
            {...fields.expires_at}
          />
        </FormLayout.Group>
        <FormLayout.Group condensed>
          <TextField
            label={"Approval Number"}
            {...fields.external_identifier}
          />
        </FormLayout.Group>

        <TextField
          label="Notes"
          multiline={5}
          autoComplete="off"
          {...fields.notes}
        />
        {approvedFormDirty && (
          <Button submit variant="primary">
            Save changes
          </Button>
        )}
      </FormLayout>
    </Form>
  );
});
ApprovedForm.displayName = "ApprovedForm";

ApprovedForm.propTypes = {
  creditSubmissionId: PropTypes.string,
  creditSubmission: PropTypes.object,
  opportunityId: PropTypes.string,
  onEdit: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default ApprovedForm;
