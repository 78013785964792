import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import {
  Thumbnail,
  Filters,
  ResourceList,
  ResourceItem,
  Text,
  Pagination,
  EmptyState,
  Truncate,
  Button,
  BlockStack,
  InlineStack,
} from "@shopify/polaris";
import { StoreIcon } from "@shopify/polaris-icons";

import { useGetAdminVendorProgramsQuery } from "../../../services/api";

const VendorProgramList = (props) => {
  const history = useHistory();

  const resourceName = {
    singular: "vendor program",
    plural: "vendor programs",
  };

  const {
    vendorId,
    selectable,
    filter,
    externalSelectedHandler,
    canSelectMultiple = true
  } = props;

  const [selectedItems, setSelectedItems] = useState([]);
  const [queryValue, setQueryValue] = useState(undefined);
  const [filteredItems, setFilteredItems] = useState([]);

  const handleQueryValueRemove = useCallback(
    () => setQueryValue(undefined),
    []
  );
  const handleQueryChange = (value) => setQueryValue(value);

  const { data: vendorPrograms = [], isLoading: isLoadingVendorPrograms } =
    useGetAdminVendorProgramsQuery({ vendorId: vendorId });

  const handleSetNewSelectedItems = (value) => {

    let setValue
    if (canSelectMultiple) {
      setValue = value
    } else {

      if (value.length === vendorPrograms.length) {
        setValue = []
      } else if (value.length > 1) {
        setValue = [value[value.length - 1]]
      } else {
        setValue = value
      }
    }

    // Call external handler if present
    if(externalSelectedHandler) externalSelectedHandler(setValue)
    setSelectedItems(setValue)
  }

  const filterControl = vendorPrograms.length ? (
    <Filters
      filters={[]}
      queryValue={queryValue}
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryValueRemove}
    />
  ) : undefined;

  useEffect(() => {
    if (queryValue) {
      const results = vendorPrograms.filter((item) => {
        return item.attributes.name
          .toLowerCase()
          .includes(queryValue.toLowerCase());
      });
      setFilteredItems(results);
    } else {
      if (!vendorPrograms) {
        setFilteredItems([]);
      } else {
        setFilteredItems(vendorPrograms);
      }
    }
  }, [queryValue, vendorPrograms]);

  const [currentPage, setCurrentPage] = useState(1);
  const handleNextPage = () => {
    setCurrentPage((state) => {
      if (state == paginatedFilteredItems.length) {
        return paginatedFilteredItems.length;
      }
      return state + 1;
    });
  };
  const handlePreviousPage = () => {
    setCurrentPage((state) => {
      if (state == 1) {
        return 1;
      }
      return state - 1;
    });
  };

  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [paginatedFilteredItems, setPaginatedFilteredItems] = useState([]);
  const [currentPageItems, setCurrentPageItems] = useState([]);
  useEffect(() => {
    const perPage = itemsPerPage;

    const paginatedFilteredItems = filteredItems?.vendor_programs?.data?.reduce(
      (resultArray, item, index) => {
        const pageIndex = Math.floor(index / perPage);
        if (!resultArray[pageIndex]) {
          resultArray[pageIndex] = [];
        }
        resultArray[pageIndex].push(item);
        return resultArray;
      },
      []
    );

    paginatedFilteredItems && setPaginatedFilteredItems(paginatedFilteredItems);
  }, [filteredItems, itemsPerPage]);

  useEffect(() => {
    const pageIndex = currentPage - 1;
    const currentPageItems = paginatedFilteredItems[pageIndex] || [];
    setCurrentPageItems(currentPageItems);
  }, [paginatedFilteredItems, currentPage]);

  const paginationMarkup = (
    <Pagination
      hasPrevious={currentPage != 1}
      onPrevious={handlePreviousPage}
      label={`Page ${currentPage} of ${paginatedFilteredItems.length}`}
      hasNext={currentPage < paginatedFilteredItems.length}
      onNext={handleNextPage}
    />
  );

  const emptyStateMarkup = !vendorPrograms.length ? (
    <EmptyState>
      <p>This account has no vendor programs.</p>
    </EmptyState>
  ) : undefined;

  const vendorProgramsResourceList = (
    <ResourceList
      resourceName={resourceName}
      items={currentPageItems}
      renderItem={(item, id, index) => renderItem(item, id, index)}
      selectedItems={selectedItems}
      onSelectionChange={handleSetNewSelectedItems}
      emptyState={emptyStateMarkup}
      filterControl={filter ? filterControl : false}
      loading={isLoadingVendorPrograms}
      selectable={selectable}
    />
  );

  const renderItem = (item, id, index) => {
    const {
      attributes: { name, default_financing_option_type },
    } = item;

    const thumbnail = (
      <Thumbnail
        source={StoreIcon}
        size="extraSmall"
        alt={`Icon of ${name}`}
      />
    );

    const handleAccountClick = () => {
      navigateToAdminVendorProgramShow();
    };

    const navigateToAdminVendorProgramShow = () => {
      history.push({
        pathname: `/admin/vendor_programs/${id}`,
        state: { account: { ...item } },
      });
    };

    return (
      <ResourceItem
        id={id}
        media={thumbnail}
        onClick={handleAccountClick}
        accessibilityLabel={`View details for ${name}`}
        verticalAlignment="center"
      >
        <BlockStack gap="50">
          <Text as="span" fontWeight="semibold" >
            <Truncate>{name}</Truncate>
          </Text>
          <Text variant="bodySm" as="p">
            <Truncate>{default_financing_option_type}</Truncate>
          </Text>
        </BlockStack>
      </ResourceItem>
    );
  };

  return (
    <>
      {vendorProgramsResourceList}
      {paginatedFilteredItems.length > 1 && (
        <>
          <InlineStack align="center">{paginationMarkup}</InlineStack>
          <br/>
        </>
      )}
      {vendorPrograms.length > 1 && (
        <>
          <span class="black-button">
            <InlineStack align="center"><Button>Remove Program</Button></InlineStack>
          </span>
          <br/>
        </>
      )}
    </>
  );
};

VendorProgramList.propTypes = {
  vendorId: PropTypes.string,
  selectable: PropTypes.bool,
  filter: PropTypes.bool,
  externalSelectedHandler: PropTypes.func,
  canSelectMultiple: PropTypes.bool,
};

export default VendorProgramList;
