import React, { useState, useCallback } from "react";
import {
  Card,
  FormLayout,
  TextField,
  Form,
  Button,
  Banner,
  ButtonGroup,
  Text,
  Select,
  Checkbox,
} from "@shopify/polaris";
import {
  useField,
  useForm,
  notEmpty,
  asChoiceField,
} from "@shopify/react-form";
import PropTypes from "prop-types";

import {
  useCreateGuarantorMutation,
  useCreateOpportunityContactMutation,
} from "../../../../../services/api";
import { stateOptionsShort } from "../../../../../utilities";

import DatePickerPopUp from "../../../../../components/DatePickerPopUp";

const GuarantorForm = (props) => {
  const {
    opportunityId,
    guarantorCount,
    setShowGuarantorList,
    refetchGuarantors,
    showToastWithMessage,
  } = props;

  // first create the OpportunityContact then return the guarantorId to link it to the guarantor
  const [createGuarantor, { isLoading: isCreatingGuarantor }] =
    useCreateGuarantorMutation();

  const [
    createOpportunityContact,
    { isLoading: isCreatingOpportunityContact },
  ] = useCreateOpportunityContactMutation();

  const [guarantorBirthDate, setGuarantorBirthDate] = useState("");

  const { fields, submit, submitting, reset, submitErrors } = useForm({
    fields: {
      firstname: useField({
        value: "",
        validates: [notEmpty("First name is required")],
      }),
      lastname: useField({
        value: "",
        validates: [notEmpty("Last name is required")],
      }),
      email: useField({
        value: "",
        validates: [notEmpty("Email is required")],
      }),
      ssn: useField({
        value: "",
        validates: [notEmpty("SSN is required")],
      }),
      title: useField({
        value: "",
        validates: [],
      }),
      phone: useField({
        value: "",
        validates: [],
      }),
      birth_date: useField({
        value: guarantorBirthDate,
        validates: [notEmpty("Birth date is required")],
      }),
      street: useField({
        value: "",
        validates: [notEmpty("Street is required")],
      }),
      city: useField({
        value: "",
        validates: [notEmpty("City is required")],
      }),
      state: useField({
        value: "",
        validates: [notEmpty("State is required")],
      }),
      postal_code: useField({
        value: "",
        validates: [notEmpty("Zip code is required")],
      }),
      authorized: useField({
        value: false,
        validates: [
          (value) => {
            if (!value) {
              return "Authorization to obtain consumer credit report is required";
            }
          },
        ],
      }),
    },
    async onSubmit(form) {
      delete form.authorized;

      return createOpportunityContact({
        opportunityId: opportunityId,
        notifyContacts: false,
        inviteUser: false,
        contact: { ...form },
      })
        .unwrap()
        .then((response) => {
          // Match the contact based on email address from the form
          const matchingContact = response.find(
            (contact) => contact.attributes?.email === form.email
          );

          if (!matchingContact) {
            throw new Error(
              "No matching contact found with the provided email."
            );
          }

          return createGuarantor({
            opportunityId: opportunityId,
            body: {
              entity_id: matchingContact.id,
              is_individual: true,
              authorized_at: new Date().toISOString(),
            },
          })
            .unwrap()
            .then(() => {
              reset();
              showToastWithMessage("Guarantor added successfully!");
              refetchGuarantors();
              setShowGuarantorList(true);
              // setTimeout(() => {
              //   showToastWithMessage("Guarantor added successfully!");
              //   refetchGuarantors();
              //   setShowGuarantorList(true);
              // }, 500);

              return { status: "success" };
            })
            .catch((error) => {
              console.log(error);
              return { status: "error", error };
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  const errorBanner = useCallback(() => {
    submitErrors.length > 0 && (
      <Banner tone="critical" title="Something went wrong">
        {submitErrors.map((error, index) => {
          return <p key={index}>{error.message}</p>;
        })}
      </Banner>
    );
  }, [submitErrors]);

  return (
    <Card sectioned background="bg-surface-secondary">
      <Form onSubmit={submit}>
        <FormLayout>
          {errorBanner}

          <Text variant="headingMd" as="h6">
            {`Guarantor ${guarantorCount + 1}`}
          </Text>

          <FormLayout.Group>
            <TextField
              label="First name"
              requiredIndicator
              placeholder="John"
              {...fields.firstname}
            />
            <TextField
              label="Last name"
              placeholder="Doe"
              requiredIndicator
              {...fields.lastname}
            />
          </FormLayout.Group>

          <TextField label="Title" placeholder="President" {...fields.title} />

          <TextField
            label="Email"
            placeholder="john@example.com"
            requiredIndicator
            {...fields.email}
          />
          <TextField
            label="Social Security Number"
            placeholder="111-11-1111"
            requiredIndicator
            {...fields.ssn}
          />
          <TextField
            label="Phone"
            placeholder="(555)555-1234"
            {...fields.phone}
          />

          <DatePickerPopUp
            datePickerLabel={"Date of Birth"}
            placeholder="MM/DD/YYYY"
            requiredIndicator={true}
            setEditingDate={setGuarantorBirthDate}
          />

          <Text variant="headingMd" as="h6">
            Home address
          </Text>

          <TextField label="Street name" requiredIndicator {...fields.street} />
          <FormLayout.Group>
            <TextField label="City" requiredIndicator {...fields.city} />
            <Select
              label="State"
              placeholder={"Select state"}
              autoComplete="off"
              requiredIndicator
              options={stateOptionsShort}
              {...fields.state}
            />
            <TextField
              label="Zip code"
              requiredIndicator
              {...fields.postal_code}
            />
          </FormLayout.Group>

          <Checkbox
            label={
              <Text variant="headingMd" as="h6">
                Authorization to Obtain Consumer Credit Report
              </Text>
            }
            helpText="By confirming this checkbox, the individual, who is a principal of the credit applicant listed above or a personal guarantor of its obligations, provides written instructions for FORT Capital Resources or its designee (and any assignee or potential assignee thereof) authorizes the procurement and review of his or her consumer reports, including not limited to personal credit profile, criminal background checks, as well as the corporate profile, from a national credit bureau or background search provider. Such authorization shall extend to obtaining a credit profile or criminal history background report in considering the applicant and subsequently for the purposes of update, renewal, or extension of such credit and for reviewing or collecting the resulting account. Furthermore, I acknowledge that this application is for the extension of commercial credit only and any equipment leased will be used exclusively for commercial purposes."
            {...asChoiceField(fields.authorized)}
          />

          <ButtonGroup>
            <Button onClick={() => setShowGuarantorList(true)}>Cancel</Button>
            <Button
              submit
              variant="primary"
              disabled={fields.authorized.value == false}
              loading={
                submitting ||
                isCreatingOpportunityContact ||
                isCreatingGuarantor
              }
            >
              Confirm
            </Button>
          </ButtonGroup>
        </FormLayout>
      </Form>
    </Card>
  );
};

GuarantorForm.displayName = "GuarantorForm";

GuarantorForm.propTypes = {
  opportunityId: PropTypes.string,
  guarantorCount: PropTypes.number,
  setShowGuarantorList: PropTypes.func,
  refetchGuarantors: PropTypes.func,
  showToastWithMessage: PropTypes.func,
};

export default GuarantorForm;
