import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, Text } from "@shopify/polaris";
import { BrowserView, MobileView, isMobile, isDesktop } from "react-device-detect";

// import addDocumentSVG from "../assets/addDocument.svg";

const DropQuoteCard = (props) => {
  const { dropzone, onDrop, onUploadSuccess } = props;

  const [openFileDialog, setOpenFileDialog] = useState(false);
  const handleClick = () => {
    if (isMobile) {
      setOpenFileDialog(true);
    }
  };

  return (
    <div onClick={handleClick}>
      <Card sectioned>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ flex: "1 1 auto" }}>
            <Text variant="headingMd" as="h2">Get started by uploading a quote</Text>
          </div>
        </div>

        <MobileView
          onClick={(e) => e.stopPropagation()}
          style={{ paddingTop: "18px" }}
          className={isDesktop ? "FortifyDropzone--mobileHidden" : ""}
        >
          {React.cloneElement(dropzone, {
            onDrop: onDrop,
            onUploadSuccess: onUploadSuccess,
            openFileDialog: openFileDialog,
            onFileDialogClose: () => setOpenFileDialog(false),
          })}
        </MobileView>

        <BrowserView
          onClick={(e) => e.stopPropagation()}
          style={{ paddingTop: "18px" }}
        >
          {React.cloneElement(dropzone, {
            onDrop: onDrop,
            onUploadSuccess: onUploadSuccess,
            openFileDialog: openFileDialog,
            onFileDialogClose: () => setOpenFileDialog(false),
          })}
        </BrowserView>
      </Card>
    </div>
  );
};

DropQuoteCard.propTypes = {
  dropzone: PropTypes.any,
  onDrop: PropTypes.func,
  onUploadSuccess: PropTypes.func,
};

export default DropQuoteCard;
