import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Button, TextField, Popover, DatePicker } from "@shopify/polaris";
import { formatNumericDate, convertServerDate } from "../utilities";
import { CalendarIcon } from "@shopify/polaris-icons";
import PropTypes from "prop-types";

const DatePickerPopUp = (props) => {
  const {
    setEditingDate,
    datePickerLabel,
    requiredIndicator,
    placeholder,
    initialDate,
    disabled,
  } = props;

  const [dateValue, setDateValue] = useState("");
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    []
  );

  const today = useMemo(() => new Date(), []);
  const [{ month, year }, setDate] = useState({
    month: today.getMonth(),
    year: today.getFullYear(),
  });
  const [selectedDate, setSelectedDate] = useState({
    start: null,
    end: null,
  });

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    []
  );

  const validRubyDateFormat = (date) => {
    if (!date) return "";
    let formatDate = new Date(date);
    if (isNaN(formatDate.getTime())) return "";
    let day = formatDate.getDate();
    let month = formatDate.getMonth();
    let year = formatDate.getFullYear();
    return `${year}-${month + 1}-${day}`;
  };

  const [rubyStringDate, setRubyStringDate] = useState("");
  const handleDateTextChange = useCallback(
    (value) => {
      if (!value.trim()) {
        setRubyStringDate("");
        setSelectedDate({ start: null, end: null });
        setDate({ month: today.getMonth(), year: today.getFullYear() });
        return;
      }

      const convertedDate = convertServerDate(value);
      const parsedDate = Date.parse(convertedDate);

      if (isNaN(parsedDate)) {
        setRubyStringDate("");
        setSelectedDate({ start: null, end: null });
        setDate({ month: today.getMonth(), year: today.getFullYear() });
        return;
      }

      const formattedDate = validRubyDateFormat(convertedDate);
      setRubyStringDate(formattedDate);

      let date = new Date(parsedDate);
      setSelectedDate({
        start: date,
        end: date,
      });
      let month = date.getMonth();
      let year = date.getFullYear();
      setDate({ month, year });
    },
    [today]
  );

  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    if (!dirty) {
      if (initialDate?.value) {
        setRubyStringDate(initialDate.value);

        let convertedDate = convertServerDate(initialDate.value);
        let parsedDate = Date.parse(convertedDate);
        let date = new Date(parsedDate);

        setSelectedDate({
          start: date,
          end: date,
        });
        let month = date.getMonth();
        let year = date.getFullYear();
        setDate({ month, year });

        setDateValue(formatNumericDate(date));
      } else {
        setDateValue("");

        setSelectedDate({
          start: today,
          end: today,
        });
        let month = today.getMonth();
        let year = today.getFullYear();
        setDate({ month, year });
      }
    }
  }, [initialDate]);

  useEffect(() => {
    setEditingDate(rubyStringDate);
  }, [rubyStringDate]);

  const handleDateChange = useCallback(
    (range) => {
      setDirty(true);

      if (!range.start) {
        setRubyStringDate("");
        setDateValue("");
        setSelectedDate({ start: null, end: null });
        setDate({ month: today.getMonth(), year: today.getFullYear() });
        return;
      }

      const formattedDate = validRubyDateFormat(range.start);
      setRubyStringDate(formattedDate);
      setDateValue(formatNumericDate(range.start));
      setSelectedDate(range);
      setDate({
        month: range.start.getMonth(),
        year: range.start.getFullYear(),
      });
    },
    [today]
  );

  const onBlurinvoiceDueDateChange = useCallback(() => {
    setDateValue(formatNumericDate(dateValue));
    handleDateTextChange(dateValue);
  }, [dateValue]);

  const activator = (
    <Button
      onClick={togglePopoverActive}
      icon={CalendarIcon}
      disabled={disabled}
    />
  );

  const datePopover = (
    <Popover
      active={popoverActive}
      activator={activator}
      onClose={togglePopoverActive}
      ariaHaspopup={false}
      sectioned
    >
      <DatePicker
        month={month}
        year={year}
        onChange={handleDateChange}
        onMonthChange={handleMonthChange}
        selected={selectedDate.start ? selectedDate : undefined}
      />
    </Popover>
  );

  return (
    <TextField
      label={datePickerLabel}
      value={dateValue}
      placeholder={placeholder}
      onChange={setDateValue}
      onBlur={onBlurinvoiceDueDateChange}
      autoComplete="off"
      requiredIndicator={requiredIndicator}
      connectedRight={datePopover}
      disabled={disabled}
    />
  );
};

DatePickerPopUp.displayName = "DatePickerPopUp";
DatePickerPopUp.propTypes = {
  placeholder: PropTypes.string,
  setEditingDate: PropTypes.func,
  datePickerLabel: PropTypes.string,
  requiredIndicator: PropTypes.bool,
  initialDate: PropTypes.object,
  disabled: PropTypes.bool,
};

export default DatePickerPopUp;
