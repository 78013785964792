import React, { useState, useCallback, useEffect } from "react";
import {
  Layout,
  Page,
  Card,
  InlineStack,
  Pagination,
  Text,
  TextField,
  ChoiceList,
  Banner,
  IndexFilters,
  IndexFiltersMode,
  useSetIndexFiltersMode,
} from "@shopify/polaris";

import {
  useGetLendersQuery,
  useCreateCreditSubmissionCsvMutation,
} from "../../services/api";

import CreditSubmissionTable from "../../components/Admin/CreditSubmission/CreditSubmissionTable";

const CreditSubmissionIndex = () => {
  const [Ids, setIds] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [clientIdFilter, setClientIdFilter] = useState("");
  const [selectedLender, setSelectedLender] = useState([]);
  const [lenderChoiceList, setLenderChoiceList] = useState([]);
  const [queryValue, setQueryValue] = useState("");
  const [sortSelected, setSortSelected] = useState(["created_date asc"]);
  const [selected, setSelected] = useState(0);
  const limit = 25;

  const { mode, setMode } = useSetIndexFiltersMode();
  const [itemStrings, setItemStrings] = useState([]);

  const [
    createCreditSubmissionCsv,
    { isLoading: isCreatingCreditSubmissionCsv },
  ] = useCreateCreditSubmissionCsvMutation();

  const { data: lenders = [] } = useGetLendersQuery();

  const tabs = itemStrings.map((item, index) => ({
    content: item,
    index,
    onAction: () => {},
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions: [],
  }));

  const onCreateNewView = async (value) => {
    await sleep(500);
    setItemStrings([...itemStrings, value]);
    setSelected(itemStrings.length);
    return true;
  };

  const [isExportingCsv, setIsExportingCsv] = useState(false);
  const [showExportError, setShowExportError] = useState(false);
  const [showExportSuccess, setShowExportSuccess] = useState(false);

  useEffect(() => {
    if (lenders.length > 0) {
      setLenderChoiceList(
        lenders.map((lender) => ({
          label: lender.attributes.name,
          value: lender.id,
        }))
      );
    }
  }, [lenders]);

  const handleExportToCsv = async () => {
    setShowExportError(false);
    setShowExportSuccess(false);
    setIsExportingCsv(true);

    if (Ids.length > 0) {
      createCreditSubmissionCsv({ creditSubmissionIds: Ids })
        .unwrap()
        .then(() => {
          setIsExportingCsv(false);
          setShowExportSuccess(true);
        })
        .catch((error) => {
          setShowExportError(true);
          setShowExportSuccess(false);
          console.log(error);
        });
    } else {
      setShowExportError(true);
      setShowExportSuccess(false);
    }
  };

  const handleFiltersQueryChange = useCallback((value) => {
    setQueryValue(value);
    setPage(1);
  }, []);

  const handleOnSelect = (viewIndex) => {
    setSelected(viewIndex);
    setQueryValue("");
    setPage(1);
  };

  const handleChangePage = (mode) => {
    setIsLoading(true);
    setPage((state) => {
      if (mode == "next" && state == Math.ceil(count / limit)) {
        return Math.ceil(count / limit);
      } else if (mode == "previous" && state == 1) {
        return 1;
      }

      if (mode == "next") {
        return state + 1;
      } else if (mode == "previous") {
        return state - 1;
      }
    });
  };

  const handleClientIdFilterChange = (id) => {
    setClientIdFilter(id);
  };

  const handleSelectLender = (lender) => {
    setSelectedLender(lender);
  };

  const filters = [
    {
      key: "clientId",
      label: "Client ID",
      filter: (
        <TextField
          label={""}
          value={clientIdFilter}
          onChange={handleClientIdFilterChange}
          placeholder={"Client ID..."}
          autoComplete="off"
          labelHidden
        />
      ),
      shortcut: true,
    },
    {
      key: "lender",
      label: "Lender",
      filter: (
        <ChoiceList
          title="Lender"
          titleHidden
          choices={lenderChoiceList}
          selected={selectedLender || []}
          onChange={handleSelectLender}
          allowMultiple
        />
      ),
      shortcut: true,
    },
  ];

  const tablePagination = (
    <Pagination
      hasPrevious={page != 1}
      hasNext={page < Math.ceil(count / limit)}
      onPrevious={() => {
        handleChangePage("previous");
      }}
      onNext={() => {
        handleChangePage("next");
      }}
      label={
        <Text>
          Page {page} of {Math.ceil(count / limit)}
        </Text>
      }
    />
  );

  return (
    <Page
      title="Credit submissions"
      fullWidth
      secondaryActions={[
        {
          content: "Export",
          onAction: handleExportToCsv,
          loading: isCreatingCreditSubmissionCsv || isExportingCsv,
          disabled: Ids.length == 0,
        },
      ]}
    >
      <Layout>
        {showExportError && (
          <Layout.Section>
            <Banner title="Error" tone="critical">
              <p>An error occured with the export</p>
            </Banner>
          </Layout.Section>
        )}
        {showExportSuccess && (
          <Layout.Section>
            <Banner
              title="Credit submission export processing"
              tone="success"
              onDismiss={() => {
                setShowExportSuccess(false);
              }}
            >
              <p>You'll receive an email in a few minutes when it's ready.</p>
            </Banner>
          </Layout.Section>
        )}
        <Layout.Section>
          <Card padding="0">
            <IndexFilters
              sortSelected={sortSelected}
              onSort={setSortSelected}
              queryValue={queryValue}
              queryPlaceholder={""}
              onQueryChange={handleFiltersQueryChange}
              onQueryClear={() => setQueryValue("")}
              cancelAction={{
                onAction: () => {},
                disabled: false,
                loading: false,
              }}
              tabs={tabs}
              selected={selected}
              onSelect={handleOnSelect}
              canCreateNewView={true}
              onCreateNewView={onCreateNewView}
              filters={filters}
              appliedFilters={[]}
              onClearAll={() => {}}
              mode={mode}
              setMode={setMode}
              filteringAccessibilityTooltip="Search (F)"
            />
            <CreditSubmissionTable
              setCount={setCount}
              page={page}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              limit={limit}
              setIds={setIds}
              queryValue={queryValue}
              clientIdFilter={clientIdFilter}
              lender={selectedLender.join(",")}
            />
            <InlineStack align="center">
              <div style={{ padding: "1.3rem" }}>
                {count > 0 ? tablePagination : <></>}
              </div>
            </InlineStack>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default CreditSubmissionIndex;
