import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Modal } from "@shopify/polaris";
import InvoiceEmbed from "../../../Invoice/Embed";

const InvoiceModal = (props) => {
  const {
    finalInvoiceId,
    openInvoiceModal,
    toggleOpenInvoiceModal,
    vendorPayments,
  } = props;

  useEffect(() => {
    setTimeout(() => {
      const modalDialog =
        "Polaris-Modal-Dialog__Modal Polaris-Modal-Dialog--sizeLarge";

      document
        .getElementsByClassName(modalDialog)[0]
        .classList.add("FortifyOpportunity__ViewInvoice_ModalDialog");
    }, 1);
  }, []);

  return (
    <Modal
      open={openInvoiceModal}
      title={`View ${finalInvoiceId ? "Invoice" : "Quote"}`}
      onClose={toggleOpenInvoiceModal}
      sectioned
      size="large"
    >
      {vendorPayments.map((invoice, i) => {
        return (
          invoice.attributes.invoice_url && (
            <InvoiceEmbed
              key={`invoice-${i}`}
              invoiceUrl={invoice.attributes.invoice_url}
            />
          )
        );
      })}
    </Modal>
  );
};

InvoiceModal.propTypes = {
  openInvoiceModal: PropTypes.bool,
  toggleOpenInvoiceModal: PropTypes.func,
  vendorPayments: PropTypes.array,
  finalInvoiceId: PropTypes.string,
};

export default InvoiceModal;
