import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Tabs } from "@shopify/polaris";
import PropTypes from "prop-types";
import AsyncLocalStorage from "../../AsyncLocalStorage";

const OpportunityTab = (props) => {
  const {
    opportunityId,
    opportunityDetail,
    creditSubmissions,
    fundingChecklist,
    paymentsAndReview,
    isOrgAdmin,
  } = props;

  const location = useLocation();

  const queryStrings = () => {
    return new URLSearchParams(location.search);
  };
  const initialTabState = queryStrings().get("tab");

  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback(
    async (index) => {
      // Adjust the index based on the admin status
      const adjustedIndex = !isOrgAdmin && index > 0 ? 1 : index;

      // Set the selected index state
      setSelected(adjustedIndex);

      // Define the storage key
      const key = `fortify-app-opportunity-${opportunityId}-selectedTabIndex`;

      try {
        // Attempt to store the adjusted index in AsyncLocalStorage
        await AsyncLocalStorage.setItem(key, String(adjustedIndex));
      } catch (error) {
        // Log errors to the console
        console.error("Error storing selected tab index:", error);
      }
    },
    [isOrgAdmin, opportunityId]
  );

  useEffect(() => {
    async function fetchAndSetSelectedTabIndex() {
      let selectedTabIndex = 0;

      if (initialTabState) {
        const keys = Object.keys(cardContent);
        selectedTabIndex = keys.indexOf(initialTabState);
      } else {
        const key = `fortify-app-opportunity-${opportunityId}-selectedTabIndex`;

        try {
          const retrievedTabIndex = await AsyncLocalStorage.getItem(key);
          const parsedTabIndex = parseInt(retrievedTabIndex, 10);

          if (!isNaN(parsedTabIndex)) {
            selectedTabIndex = parsedTabIndex;
          }
        } catch (error) {
          console.error("Error retrieving tab ID:", error);
          selectedTabIndex = 0;
        }
      }

      // handle the case where the user is not an org admin
      // and has fewer tabs to choose from
      if (!isOrgAdmin && selectedTabIndex > 0) {
        selectedTabIndex = 1;
      }

      setSelected(selectedTabIndex);
    }

    fetchAndSetSelectedTabIndex();
    // Include all variables used in the effect that might change over time
  }, [cardContent, initialTabState, isOrgAdmin, opportunityId]);

  const cardContent = useMemo(
    () => ({
      "opportunity-detail": opportunityDetail,
      "credit-submissions": creditSubmissions,
      "funding-checklist": fundingChecklist,
      "payments-n-review": paymentsAndReview,
    }),
    [opportunityDetail, creditSubmissions, fundingChecklist, paymentsAndReview]
  );

  const tabs = [
    {
      id: "opportunity-detail",
      content: "Opportunity detail",
      panelID: "opportunity-detail",
    },
    {
      id: "credit-submissions",
      content: "Credit submissions",
      panelID: "credit-submissions",
    },
    {
      id: "funding-checklist",
      content: "Funding checklist",
      panelID: "funding-checklist",
    },
    {
      id: "payments-n-review",
      content: "Transactions & review",
      panelID: "payments-n-review",
    },
  ].filter((tab) => {
    const allowed = ["opportunity-detail", "payments-n-review"];
    return allowed.includes(tab.id) && !isOrgAdmin ? true : isOrgAdmin;
  });

  const tabsMarkup = (
    <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} fitted>
      {/* condition only for vendor admin*/}
      {cardContent[tabs[!isOrgAdmin && selected > 0 ? 1 : selected].id]}
    </Tabs>
  );

  return tabsMarkup;
};

OpportunityTab.propTypes = {
  opportunityId: PropTypes.string,
  opportunityDetail: PropTypes.any,
  creditSubmissions: PropTypes.any,
  fundingChecklist: PropTypes.any,
  paymentsAndReview: PropTypes.any,
  isOrgAdmin: PropTypes.bool,
};

export default OpportunityTab;
