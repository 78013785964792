import React from "react";

import {
  Text,
  Link,
  Divider,
  Card,
  BlockStack,
  InlineStack,
  Bleed,
} from "@shopify/polaris";

import Opportunity from "../../types/Opportunity";

import { formatCurrency } from "../../utilities";

type Props = {
  blindDiscount: number;
  netPayment: number;
  opportunity: Opportunity;
};

const SummaryCard = (props: Props) => {
  const { opportunity, blindDiscount = 0, netPayment } = props;

  const blindDiscountPercent = blindDiscount / parseFloat(opportunity.attributes.total_finance_amount) * 100;
  const blindDiscountPercentRounded = Math.round(blindDiscountPercent * 100) / 100;

  return (
    <Card padding="400">
      <BlockStack gap="400">
        <Text variant="headingMd" as="h6">
          Summary
        </Text>

        <BlockStack gap="200">
          <InlineStack align="space-between">
            <Text as="p">
              Total Invoice
            </Text>

            <Text as="p">
              {formatCurrency(opportunity.attributes.total_finance_amount)}
            </Text>
          </InlineStack>

          <InlineStack align="space-between">
            <Text as="p">
              Blind discount
            </Text>

            <Text as="p">
              {formatCurrency(blindDiscount)}
            </Text>
          </InlineStack>

          <InlineStack align="space-between">
            <Text as="p" tone="subdued">
              Blind discount %
            </Text>

            <Text as="p" tone="subdued">
              {blindDiscountPercentRounded}%
            </Text>
          </InlineStack>

          <InlineStack align="space-between">
            <Text fontWeight="bold" as="p">
              Net payment
            </Text>

            <Text fontWeight="bold" as="p">
              {formatCurrency(netPayment)}
            </Text>
          </InlineStack>
        </BlockStack>

        <Bleed marginInline={"400"}>
          <Divider />
        </Bleed>

        <InlineStack align="start" gap="400" wrap={false}>
          <Text as="p">
            What are{" "}
            <Link url="https://help.fortifypay.com/">blind discounts?</Link>
          </Text>
        </InlineStack>
      </BlockStack>
    </Card>
  );
};

export default SummaryCard;
