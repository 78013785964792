import { configureStore } from "@reduxjs/toolkit";
// import sessionReducer from "../reducers/sessions";
import { fortApi } from "../services/api";

const store = configureStore({
  reducer: {
    [fortApi.reducerPath]: fortApi.reducer,
    // session: sessionReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: { ignoredPaths: ["fortApi.mutations"] },
    }).concat(fortApi.middleware),
});

export { store };
