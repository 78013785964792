import React from "react";
import { Banner, Layout } from "@shopify/polaris";

import { AlertCircleIcon } from "@shopify/polaris-icons";

type ApprovedBannerProps = {
  approved: boolean;
  purchaseOptionId: string;
};

const ApprovedBanner = (props: ApprovedBannerProps) => {
  const { approved, purchaseOptionId } = props;

  return approved ? (
    <Layout.Section variant="fullWidth">
      <Banner
        title="This purchase option has been approved by Fortify."
        tone="success"
      />
    </Layout.Section>
  ) : (
    purchaseOptionId && (
      <Layout.Section variant="fullWidth">
        <Banner
          title="This purchase option is pending approval by Fortify."
          tone="warning"
          icon={AlertCircleIcon}
        />
      </Layout.Section>
    )
  );
};

export default ApprovedBanner;
