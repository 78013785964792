import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import {
  Avatar,
  Card,
  Filters,
  ResourceList,
  ResourceItem,
  Text,
  LegacyStack,
  BlockStack,
  InlineStack,
  Pagination,
  EmptyState,
  Truncate,
} from "@shopify/polaris";

import { useGetAdminVendorAdminsQuery } from "../../../services/api";

const AdminList = (props) => {
  const resourceName = {
    singular: "admin",
    plural: "admins",
  };

  const {
    vendorId,
    selectable,
    filter
  } = props;

  const [selectedItems, setSelectedItems] = useState([]);
  const [queryValue, setQueryValue] = useState(undefined);
  const [filteredItems, setFilteredItems] = useState([]);

  const handleQueryValueRemove = useCallback(() => setQueryValue(undefined), []);
  const handleQueryChange = (value) => setQueryValue(value);

  const { data: admins = [], isLoading: isLoadingAdmins } = useGetAdminVendorAdminsQuery(vendorId, { skip: !vendorId });

  const filterControl = admins.length ? (
    <Filters
      filters={[]}
      queryValue={queryValue}
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryValueRemove}
    />
  ) : undefined;

  useEffect(() => {
    if (queryValue) {
      const results = admins.filter((item) => {
        return (
          item.attributes.name
            .toLowerCase()
            .includes(queryValue.toLowerCase())
        );
      });
      setFilteredItems(results);
    } else {
      if (!admins) {
        setFilteredItems([]);
      } else {
        setFilteredItems(admins);
      }
    }
  }, [queryValue, admins]);

  const [currentPage, setCurrentPage] = useState(1);
  const handleNextPage = () => {
    setCurrentPage((state) => {
      if (state == paginatedFilteredItems.length) {
        return paginatedFilteredItems.length;
      }
      return state + 1;
    });
  };
  const handlePreviousPage = () => {
    setCurrentPage((state) => {
      if (state == 1) {
        return 1;
      }
      return state - 1;
    });
  };

  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [paginatedFilteredItems, setPaginatedFilteredItems] = useState([]);
  const [currentPageItems, setCurrentPageItems] = useState([]);
  useEffect(() => {
    const perPage = itemsPerPage;

    const paginatedFilteredItems = filteredItems.reduce(
      (resultArray, item, index) => {
        const pageIndex = Math.floor(index / perPage);
        if (!resultArray[pageIndex]) {
          resultArray[pageIndex] = [];
        }
        resultArray[pageIndex].push(item);
        return resultArray;
      },
      []
    );

    setPaginatedFilteredItems(paginatedFilteredItems);
  }, [filteredItems, itemsPerPage]);

  useEffect(() => {
    const pageIndex = currentPage - 1;
    const currentPageItems = paginatedFilteredItems[pageIndex] || [];
    setCurrentPageItems(currentPageItems);
  }, [paginatedFilteredItems, currentPage]);

  const paginationMarkup = (
    <Pagination
      hasPrevious={currentPage != 1}
      onPrevious={handlePreviousPage}
      label={`Page ${currentPage} of ${paginatedFilteredItems.length}`}
      hasNext={currentPage < paginatedFilteredItems.length}
      onNext={handleNextPage}
    />
  );

  const emptyStateMarkup = !admins.length ? (
    <EmptyState>
      <p>
        This account has no admins.
      </p>
    </EmptyState>
  ) : undefined;

  const adminsResourceList = (
    <ResourceList
      resourceName={resourceName}
      items={currentPageItems}
      renderItem={(item, id, index) => renderItem(item, id, index)}
      selectedItems={selectedItems}
      onSelectionChange={setSelectedItems}
      emptyState={emptyStateMarkup}
      filterControl={filter ? filterControl : false}
      loading={isLoadingAdmins}
      selectable={selectable}
    />
  );

  const renderItem = (item, id, index) => {
    const {
      full_name
    } = item.attributes;

    return (
      <ResourceItem
        id={id}
        media={<Avatar customer size="sm" name={full_name} />}
        accessibilityLabel={`View details for contact`}
        verticalAlignment="center"
      >
        <LegacyStack spacing="tight" alignment="center" wrap={false}>
          <LegacyStack.Item fill>
            <h3 style={{ maxWidth: "320px" }}>
              <Text as="span" fontWeight="semibold" >
                <Truncate>{full_name}</Truncate>
              </Text>
            </h3>
          </LegacyStack.Item>
        </LegacyStack>
      </ResourceItem>
    );
  };

  return (
    <>
      {adminsResourceList}
      {paginatedFilteredItems.length > 1 && (
        <InlineStack align="center">{paginationMarkup}</InlineStack>
      )}
    </>
  );
};

AdminList.propTypes = {
  vendorId: PropTypes.string,
  selectable: PropTypes.bool,
  filter: PropTypes.bool,
};

export default AdminList;
