import React from "react";
import { useGetMembershipsByTeamQuery } from "../../../services/api";

import {
  Avatar,
  Spinner,
  LegacyStack,
  Tooltip,
} from "@shopify/polaris";
import PropTypes from "prop-types";

const TeamMemberAvatarList = (props) => {
  const { teamId } = props;

  const DESIGNATED_SIGNER_EMOJI = "✍️"

  const {
    data: teamMembers = [],
    isLoading,
    isFetching,
  } = useGetMembershipsByTeamQuery(teamId, { skip: !teamId});

  return (
    <LegacyStack spacing={"none"}>
      {isLoading || isFetching ? (
        <Spinner
          size={"small"}
          accessibilityLabel={"Loading team members"}
        />
      ) : (
        teamMembers.map((member, index) => {
          const { contact_email, contact_full_name, contact_initials } = member.attributes;

          return (
            <Tooltip
              content={
                <>
                  {contact_full_name} {contact_email}
                </>
              }
              key={`avatar-${index}`}
            >
              <div style={{ position: "relative", cursor: "pointer" }}>
                <Avatar size="sm" name={contact_full_name} initials={contact_initials} />
              </div>
            </Tooltip>
          );
        })
      )}
    </LegacyStack>
  );
};

TeamMemberAvatarList.propTypes = {
  teamId: PropTypes.string,
};
export default TeamMemberAvatarList;
