import React from "react";
import { AppProvider, Frame, Image, Layout, Page } from "@shopify/polaris";
import FortifyLogo from "images/fortify-logo.svg";

const LoadingScreen = () => {
  return (
    <AppProvider>
      <Frame>
        <Page>
          <Layout>
            <Layout.Section>
              <div
                style={{
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    marginTop: "-240px",
                  }}
                >
                  <Image source={FortifyLogo} />
                </div>
              </div>
            </Layout.Section>
          </Layout>
        </Page>
      </Frame>
    </AppProvider>
  );
};

export default LoadingScreen;
