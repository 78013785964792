import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Card,
  DatePicker,
  TextField,
  Button,
  Text,
  BlockStack,
  Box,
  Banner,
  Icon,
  InlineStack,
  Link,
  Popover,
  Modal,
  ButtonGroup,
} from "@shopify/polaris";
import {
  CheckIcon,
  CalendarIcon,
  EditIcon,
  AlertCircleIcon,
  XIcon,
} from "@shopify/polaris-icons";
import PropTypes from "prop-types";

import {
  formatNumericDate,
  convertServerDate,
  showPaymentScheduleTypes,
} from "../../../utilities";
import {
  useGetFinancingOptionQuery,
  useUpdateOpportunityMutation,
} from "../../../services/api";
import { useToast } from "../../../contexts/Toast";
import PaymentScheduleTable from "../../FinancingOption/Share/components/PaymentScheduleTable";

const InstallmentPaymentScheduleCard = ({
  opportunity,
  opportunityId,
  transactions,
  handleScheduleTransactionPrompt,
}) => {
  const today = new Date();

  const { data: acceptedFinancingOption } = useGetFinancingOptionQuery(
    {
      id: opportunity?.attributes?.accepted_financing_option_id,
      opportunityId: opportunityId,
    },
    {
      skip: !opportunity?.attributes?.accepted_financing_option_id,
    }
  );

  const [updateOpportunity] = useUpdateOpportunityMutation();

  const { showToastWithMessage } = useToast();

  const [{ month, year }, setDate] = useState({
    month: convertServerDate(
      opportunity?.attributes?.installment_payment_start_date || today
    ).getMonth(),
    year: convertServerDate(
      opportunity?.attributes?.installment_payment_start_date || today
    ).getFullYear(),
  });

  const [disabledDates, setDisabledDates] = useState(new Date(today.getFullYear(), today.getMonth(), 28));

  const installmentPaymentStartDatePickerRef = useRef(null);

  const [
    isEditingInstallmentPaymentStartDate,
    setIsEditingInstallmentPaymentStartDate,
  ] = useState(false);

  const [
    installmentPaymentStartDatePickerVisible,
    setInstallmentPaymentStartDatePickerVisible,
  ] = useState(false);

  const [installmentPaymentStartDateText, setInstallmentPaymentStartDateText] =
    useState("");
  const [installmentPaymentStartDate, setInstallmentPaymentStartDate] =
    useState({
      start: convertServerDate(
        opportunity?.attributes?.installment_payment_start_date
      ),
      end: convertServerDate(
        opportunity?.attributes?.installment_payment_start_date
      ),
    });

  const handleMonthChange = useCallback((month, year) => {
    setDate({ month, year });
    setDisabledDates(new Date(year, month, 28));
  },[]);

  const handleInstallmentPaymentDateChange = (range) => {
    let startDateText = formatNumericDate(range.start);
    setInstallmentPaymentStartDateText(startDateText);
    setInstallmentPaymentStartDate(range);
    setInstallmentPaymentStartDatePickerVisible(false);
  };

  const handleInstallmentPaymentDateTextChange = useCallback((value) => {
    const parsedDate = Date.parse(value);

    if (parsedDate) {
      let date = new Date(parsedDate);
      setInstallmentPaymentStartDate({
        start: date,
        end: date,
      });
      let month = date.getMonth();
      let year = date.getFullYear();
      setDate({ month, year });
    }
  }, []);

  const handleSaveInstallmentPaymentDate = useCallback(() => {
    if (!installmentPaymentStartDateText) {
      setIsEditingInstallmentPaymentStartDate(false);
      return;
    }
    updateOpportunityInstallmentPayment(installmentPaymentStartDate.start);
  }, [
    installmentPaymentStartDate.start,
    installmentPaymentStartDateText,
    updateOpportunityInstallmentPayment,
  ]);

  const handleInstallmentPaymentDateTextFieldBlur = useCallback(() => {
    setInstallmentPaymentStartDateText(
      formatNumericDate(installmentPaymentStartDateText)
    );
    handleInstallmentPaymentDateTextChange(installmentPaymentStartDateText);
  }, [handleInstallmentPaymentDateTextChange, installmentPaymentStartDateText]);

  const [
    existingInstallmentPaymentStartDate,
    setExistingInstallmentPaymentStartDate,
  ] = useState();

  useEffect(() => {
    if (opportunity?.attributes?.installment_payment_start_date) {
      const convertedDate = convertServerDate(
        new Date(opportunity.attributes.installment_payment_start_date)
      );
      setInstallmentPaymentStartDateText(formatNumericDate(convertedDate));
    }
    setExistingInstallmentPaymentStartDate(
      opportunity?.attributes?.installment_payment_start_date
    );
  }, [opportunity?.attributes?.installment_payment_start_date]);

  const updateOpportunityInstallmentPayment = useCallback(
    (newValue) => {
      let newInstallmentPaymentDate =
        newValue === null
          ? null
          : new Date(newValue || installmentPaymentStartDateText)
              .toISOString()
              .slice(0, 10); // Convert to ISO string and take only the date part

      let existingInstallmentPaymentDate = existingInstallmentPaymentStartDate
        ? new Date(existingInstallmentPaymentStartDate)
            .toISOString()
            .slice(0, 10)
        : null; // Convert existing date to ISO string format

      if (newInstallmentPaymentDate !== existingInstallmentPaymentDate) {
        return updateOpportunity({
          id: opportunityId,
          ...{
            installment_payment_start_date:
              newInstallmentPaymentDate === null
                ? null
                : new Date(newInstallmentPaymentDate),
          }, // If clearing, set to null, else convert back to Date
        })
          .unwrap()
          .then(() => {
            setIsEditingInstallmentPaymentStartDate(false);
            showToastWithMessage(
              `Installment payment start date ${
                newInstallmentPaymentDate ? "updated" : "cleared"
              }`
            );

            handleScheduleTransactionPrompt();

            return { status: "success" };
          })
          .catch((error) => {
            console.log(error);
          });
      }
      setIsEditingInstallmentPaymentStartDate(false);
      return Promise.resolve({ status: "success" });
    },
    [
      existingInstallmentPaymentStartDate,
      handleScheduleTransactionPrompt,
      installmentPaymentStartDateText,
      opportunityId,
      showToastWithMessage,
      updateOpportunity,
    ]
  );

  const financingOptionToDisplay =
    showPaymentScheduleTypes.includes(
      acceptedFinancingOption?.attributes?.financing_type
    ) && acceptedFinancingOption;

  const [orderedOpportunityTransactions, setOrderedOpportunityTransactions] =
    useState([]);
  useEffect(() => {
    if (transactions.length > 0) {
      let orderedList = [...transactions];
      orderedList.sort(
        (a, b) =>
          new Date(a.attributes.scheduled_date).getTime() -
          new Date(b.attributes.scheduled_date).getTime()
      );

      setOrderedOpportunityTransactions(orderedList);
    }
  }, [transactions]);

  const [showClearDateModal, setShowClearDateModal] = useState(false);
  const handleClearDateConfirmation = useCallback(() => {
    updateOpportunityInstallmentPayment(null);
    setInstallmentPaymentStartDateText("");
    setInstallmentPaymentStartDate({
      start: null,
      end: null,
    });
    setShowClearDateModal(false);
  }, [updateOpportunityInstallmentPayment]);

  const [datePickerSelectedDate, setDatePickerSelectedDate] = useState(null);
  useEffect(() => {
    if (installmentPaymentStartDate.start) {
      setDatePickerSelectedDate(installmentPaymentStartDate.start);
      setDate({
        month: installmentPaymentStartDate.start.getMonth(),
        year: installmentPaymentStartDate.start.getFullYear(),
      });
    } else {
      setDatePickerSelectedDate(null);
      setDate({
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      });
    }
  }, [installmentPaymentStartDate.start]);

  return (
    <Card>
      <BlockStack gap="400">
        <Text variant="headingMd" as="h6">
          Installment payment schedule
        </Text>

        <Box as="section">
          <Banner tone="info">
            <p>
              Upon authorization, transactions are created and scheduled
              according to the selected schedule and installment payment start
              date. <Link>Learn more</Link>
            </p>
          </Banner>
        </Box>

        <Box as="section">
          <BlockStack gap="100">
            <Text as="span" tone="subdued">
              Authorized
            </Text>

            <Text as="span">
              {opportunity?.attributes?.payments_authorized_at
                ? formatNumericDate(
                    new Date(opportunity.attributes.payments_authorized_at)
                  )
                : "--"}
            </Text>
          </BlockStack>
        </Box>

        <Box as="section">
          <BlockStack gap="100">
            <InlineStack gap="100" align="start">
              <Text as="span" tone="subdued">
                Installment payment start date
              </Text>

              <span>
                <Icon source={AlertCircleIcon} tone="base" />
              </span>
            </InlineStack>

            {isEditingInstallmentPaymentStartDate ? (
              <Popover
                fullWidth
                active={installmentPaymentStartDatePickerVisible}
                activator={
                  <TextField
                    role="combobox"
                    prefix={<Icon source={CalendarIcon} />}
                    value={installmentPaymentStartDateText}
                    onFocus={() =>
                      setInstallmentPaymentStartDatePickerVisible(true)
                    }
                    onChange={setInstallmentPaymentStartDateText}
                    onBlur={handleInstallmentPaymentDateTextFieldBlur}
                    autoComplete="off"
                    connectedRight={
                      <ButtonGroup variant={"segmented"}>
                        {opportunity?.attributes
                          ?.installment_payment_start_date && (
                          <Button
                            icon={XIcon}
                            variant="secondary"
                            onClick={() => setShowClearDateModal(true)}
                            accessibilityLabel="Clear installment payment start date"
                          />
                        )}
                        <Button
                          icon={CheckIcon}
                          variant="secondary"
                          onClick={handleSaveInstallmentPaymentDate}
                          accessibilityLabel="Save installment payment start date"
                        />
                      </ButtonGroup>
                    }
                  />
                }
                autofocusTarget="none"
                onClose={() =>
                  setInstallmentPaymentStartDatePickerVisible(false)
                }
                preferredAlignment="left"
                preferInputActivator={false}
                preferredPosition="below"
                preventCloseOnChildOverlayClick
              >
                <Card ref={installmentPaymentStartDatePickerRef}>
                  <DatePicker
                    month={month}
                    year={year}
                    selected={datePickerSelectedDate}
                    onMonthChange={handleMonthChange}
                    onChange={handleInstallmentPaymentDateChange}
                    disableDatesAfter={disabledDates}
                  />
                </Card>
              </Popover>
            ) : (
              <InlineStack gap="200" align="start">
                {opportunity?.attributes?.installment_payment_start_date ? (
                  <Text as="span">
                    {formatNumericDate(
                      convertServerDate(
                        new Date(
                          opportunity.attributes.installment_payment_start_date
                        )
                      )
                    )}
                  </Text>
                ) : (
                  <Text as="span" tone="subdued">
                    --
                  </Text>
                )}

                <Button
                  icon={EditIcon}
                  variant="tertiary"
                  onClick={() => setIsEditingInstallmentPaymentStartDate(true)}
                  accessibilityLabel="Edit installment payment start date"
                />
              </InlineStack>
            )}
          </BlockStack>
        </Box>

        <Box as="section">
          <BlockStack gap="200">
            <Text as="span" tone="subdued">
              Selected schedule from opportunity detail
            </Text>

            {financingOptionToDisplay && (
              <PaymentScheduleTable
                opportunity={opportunity}
                financingOption={financingOptionToDisplay}
                orderedOpportunityTransactions={orderedOpportunityTransactions}
                inCardDisplay={false}
                showSchedule={true}
                padding={false}
              />
            )}

            {!financingOptionToDisplay && (
              <Box padding="400" borderColor="border" borderWidth="025">
                <Box padding="600">
                  <BlockStack gap="50">
                    <InlineStack align="center">
                      <Text variant="headingMd" as="h6" fontWeight="bold">
                        No schedule selected
                      </Text>
                    </InlineStack>
                    <InlineStack align="center">
                      <Text as="span">
                        Select a purchase option on opportunity detail to see
                        schedule here
                      </Text>
                    </InlineStack>
                  </BlockStack>
                </Box>
              </Box>
            )}
          </BlockStack>
        </Box>
      </BlockStack>

      {showClearDateModal && (
        <Modal
          open={showClearDateModal}
          onClose={() => setShowClearDateModal(false)}
          title="Clear installment payment start date"
          primaryAction={{
            content: "Clear",
            onAction: handleClearDateConfirmation,
            destructive: true,
          }}
          secondaryActions={[
            {
              content: "Cancel",
              onAction: () => setShowClearDateModal(false),
            },
          ]}
        >
          <Modal.Section>
            <Text>
              This will clear the installment payment start date. Scheduling
              installment payment transactions requires a start date.
            </Text>
          </Modal.Section>
        </Modal>
      )}
    </Card>
  );
};

InstallmentPaymentScheduleCard.propTypes = {
  opportunity: PropTypes.object,
  opportunityId: PropTypes.string,
  transactions: PropTypes.array,
  handleScheduleTransactionPrompt: PropTypes.func,
};

export default InstallmentPaymentScheduleCard;
