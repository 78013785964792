import React from "react";
import PropTypes from "prop-types";

import { Text } from "@shopify/polaris";

const DateChunk = (props) => {
  const { index, item } = props;

  return (
    <li className="FortComments__ListItem" key={`comment-${index}`}>
      <div
        className="FortComments__TimeHeader"
        style={{ textTransform: "uppercase" }}
      >
        <Text variant="headingSm" as="h6" tone="subdued">
          {item.label}
        </Text>
      </div>
    </li>
  );
};

DateChunk.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
};

export default DateChunk;
