import React, { forwardRef } from "react";
import {
  InlineStack,
  FormLayout,
  TextField,
  Select,
  Button,
  Text,
  Divider,
  BlockStack,
  Box,
  Bleed,
} from "@shopify/polaris";
import PropTypes from "prop-types";
import { toFinanceTypeSlug } from "../../utilities";

const LenderRateForm = forwardRef((props, ref) => {
  const { index, lenderRate, addDeletedRate, removeOwnedRateItem } = props;

  const removeRate = (item, index) => {
    addDeletedRate(item.id.value);
    removeOwnedRateItem(index);
  };

  return (
    <Bleed marginInline="400" marginBlockStart="400">
      <Box
        background={index % 2 === 0 ? "bg-surface-secondary" : "bg-surface"}
        paddingInline="500"
        paddingBlockStart="400"
      >
        <BlockStack gap="600">
          <InlineStack gap="200" align="space-between" blockAlign="center">
            <Text as="h4" variant="headingMd">
              {index + 1}
            </Text>

            <FormLayout>
              <FormLayout.Group condensed>
                <div className="FortForm__NumericTextField">
                  <TextField
                    autoComplete="false"
                    label="Min term"
                    type="number"
                    suffix="months"
                    {...lenderRate.term_length_minimum}
                  />
                </div>

                <div className="FortForm__NumericTextField">
                  <TextField
                    autoComplete="false"
                    label="Max term"
                    type="number"
                    suffix="months"
                    {...lenderRate.term_length_maximum}
                  />
                </div>

                <div className="FortForm__NumericTextField">
                  <TextField
                    autoComplete="false"
                    label="Min amount"
                    type="number"
                    prefix="$"
                    {...lenderRate.minimum_lending_amount}
                  />
                </div>

                <div className="FortForm__NumericTextField">
                  <TextField
                    autoComplete="false"
                    label="Max amount"
                    type="number"
                    prefix="$"
                    {...lenderRate.maximum_lending_amount}
                  />
                </div>
              </FormLayout.Group>

              <FormLayout.Group condensed>
                <div className="FortForm__NumericTextField">
                  <TextField
                    autoComplete="false"
                    label="Interest rate"
                    type="number"
                    suffix="%"
                    {...lenderRate.interest_rate}
                  />
                </div>

                <TextField
                  autoComplete="false"
                  label="Grade"
                  {...lenderRate.grade}
                />

                <Select
                  label="Financing Type"
                  placeholder={"Select financing type"}
                  options={Object.keys(toFinanceTypeSlug)}
                  {...lenderRate.financing_type}
                />
              </FormLayout.Group>
            </FormLayout>

            <Button
              onClick={() => {
                removeRate(lenderRate, index);
              }}
              variant="plain"
              tone="critical"
            >
              Remove
            </Button>
          </InlineStack>
          <Bleed marginInline="500">
            <Divider />
          </Bleed>
        </BlockStack>
      </Box>
    </Bleed>
  );
});
LenderRateForm.displayName = "LenderRateForm";

LenderRateForm.propTypes = {
  index: PropTypes.number,
  lenderRate: PropTypes.object,
  addDeletedRate: PropTypes.func,
  removeOwnedRateItem: PropTypes.func,
};

export default LenderRateForm;
