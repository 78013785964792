import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
import {
  Page,
  Layout,
  EmptyState,
  FooterHelp,
  Link,
  Button,
} from "@shopify/polaris";
import { WithinContentContext } from "@shopify/polaris/build/esm/utilities/within-content-context";
import FortifyLogo from "../../../../app/assets/images/fortify-logo-padding.svg";
import AppProviderWrapper from "../App/AppProviderWrapper";

const ErrorScreen = ({ error = { message: "" }, handleLogout }) => {
  const { message: heading } = error;

  const [isEmailVerificationError, setIsEmailVerificationError] =
    useState(false);
  useEffect(() => {
    if (heading.includes("We've sent a verification email")) {
      setIsEmailVerificationError(true);
    }
  }, [heading]);

  const buildContent = useCallback(() => {
    return isEmailVerificationError
      ? "Click the link in your email to verify your account. If you can't find the email, check your spam folder."
      : "We were unable to authenticate you.";
  }, [isEmailVerificationError]);

  const primaryAction = useCallback(() => {
    return isEmailVerificationError ? (
      null) :
        {
          content: "Log out",
          onAction: handleLogout,
        }}
  , [isEmailVerificationError, handleLogout]);

  return (
    <AppProviderWrapper>
      <Page narrowWidth>
        <Layout sectioned>
          <div className={"FortAuth0Error__Page"}>
            <WithinContentContext.Provider value>
              <EmptyState
                image={FortifyLogo}
                imageContained
                heading={heading}
                // TODO - action for isEmailVerificationError
                // should be to resend verification email
                //
                action={primaryAction()}
              >
                {buildContent()}
              </EmptyState>
            </WithinContentContext.Provider>
          </div>

          {isEmailVerificationError && (
            <FooterHelp>
              After verifying your email{" "}
              <Button  onClick={handleLogout} variant="plain">
                log in
              </Button>
            </FooterHelp>
          )}
        </Layout>
      </Page>
    </AppProviderWrapper>
  );
};

ErrorScreen.propTypes = {
  error: PropTypes.object,
  handleLogout: PropTypes.func,
};

export default ErrorScreen;
