import React, { useState, useCallback } from "react";
import {
  Card,
  FormLayout,
  TextField,
  Form,
  Button,
  Banner,
  Toast,
  ButtonGroup,
} from "@shopify/polaris";
import { useField, useSubmit, notEmpty } from "@shopify/react-form";
import PropTypes from "prop-types";

import { useCreateDesignatedSignerMutation } from "../../../../../services/api";
import { validatePhone } from "../../../../../utilities";

const SignerForm = (props) => {
  const {
    onCreateOrUpdate = () => {},
    onEdit = () => {},
    opportunityId,
    setShowSignerList,
  } = props;

  const [createDesignatedSigner, { isLoading: isCreatingDesignatedSigner }] =
    useCreateDesignatedSignerMutation();

  const fields = {
    first_name: useField({
      value: "",
      validates: [notEmpty("First name is required")],
    }),
    last_name: useField({
      value: "",
      validates: [notEmpty("Last name is required")],
    }),
    email: useField({
      value: "",
      validates: [notEmpty("Email is required")],
    }),
    phone: useField({
      value: "",
      validates: [notEmpty("Phone number is required"),
        (phone) => {
          if (!validatePhone(phone)) {
            return "Phone number must be valid"
          }
        }
      ],
    })
  };

  const { submit, submitting, errors, setErrors } = useSubmit(async (form) => {
    return createDesignatedSigner({
      opportunityId,
      ...form,
    })
      .unwrap()
      .then(() => {
        setShowToast(true);
        setToastMessage("Contact added");

        // Show disignated signer
        setShowSignerList(true);

        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
        return { status: "fail", errors: error?.data?.errors || [] };
      });
  }, fields);

  const errorList = Array.isArray(errors) ? errors : [errors];
  const errorBanner = errorList.length > 0 && (
    <Banner status="critical" title="Something went wrong">
      {errorList.map((error, index) => {
        const errorMessage =
          typeof error === "string" ? error : "An unknown error occurred.";
        return <p key={index}>{errorMessage} </p>;
      })}
    </Banner>
  );

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const toggleShowToast = useCallback(
    () => setShowToast((active) => !active),
    []
  );
  const handleDismissToast = () => {
    toggleShowToast();
    setToastMessage("");
  };
  const toastMarkup = showToast ? (
    <Toast content={toastMessage} onDismiss={handleDismissToast} />
  ) : undefined;

  return (
    <Card sectioned>
      <Form onSubmit={submit}>
        <FormLayout>
          {errorBanner}
          <FormLayout.Group>
            <TextField
              label="First name"
              requiredIndicator
              {...fields.first_name}
            />
            <TextField
              label="Last name"
              requiredIndicator
              {...fields.last_name}
            />
          </FormLayout.Group>

          <FormLayout.Group>
            <TextField label="Email" requiredIndicator {...fields.email} />
            <TextField label="Phone number" requiredIndicator {...fields.phone} />
          </FormLayout.Group>

          <ButtonGroup>
            <Button onClick={() => setShowSignerList(true)}>Cancel</Button>
            <Button
              submit
              variant="primary"
              loading={isCreatingDesignatedSigner || submitting}
            >
              Confirm
            </Button>
          </ButtonGroup>
        </FormLayout>
      </Form>

      {toastMarkup}
    </Card>
  );
};

SignerForm.displayName = "SignerForm";

SignerForm.propTypes = {
  onCreateOrUpdate: PropTypes.func,
  onEdit: PropTypes.func,
  setShowSignerList: PropTypes.func,
};

export default SignerForm;
