import React, { useCallback, useState, useEffect } from "react";
import {
  Box,
  Text,
  Button,
  TextField,
  BlockStack,
  InlineStack,
  FormLayout,
  Badge,
  Tooltip,
} from "@shopify/polaris";
import { roundedDollarAmount } from "../../finance";
import { formatCurrency } from "../../utilities";

import { PlusIcon, InfoIcon } from "@shopify/polaris-icons";

interface InstallmentPayment {
  days: number;
  amount?: number;
}

interface Field {
  days: {
    value: number | string;
    onChange: (value: number | string) => void;
  };
  percent: {
    value: number | string;
    onChange: (value: number | string) => void;
  };
  amount: {
    value: number | string;
    onChange: (value: number | string) => void;
  };
  status: {
    value: string;
    onChange: (value: string) => void;
  };
}

type InstallmentPaymentFieldsProps = {
  fields: Field[];
  addInstallmentPayment: (params: any) => void;
  // removeInstallmentPayment: (index: number) => void;
  handleRemoveInstallmentPayment: (index: number) => void;
  removeInstallmentPayments: (index: number) => void;
  installmentPayments: InstallmentPayment[];
  navigateToTransaction: (id: string) => void;
  navigateToTransactions: () => void;
  totalFinanceAmount: number;
  usesMilestonePayments: boolean;
  downPaymentPercent: number;
  hasDownPayment: boolean;
};

const InstallmentPaymentFields = (props: InstallmentPaymentFieldsProps) => {
  const {
    fields,
    addInstallmentPayment,
    // removeInstallmentPayment,
    handleRemoveInstallmentPayment,
    removeInstallmentPayments,
    installmentPayments,
    navigateToTransaction,
    navigateToTransactions,
    totalFinanceAmount,
    usesMilestonePayments = false,
    downPaymentPercent = 0,
    hasDownPayment = false,
  } = props;

  const title = "Installment payments";

  const [lastPaymentPercent, setLastPaymentPercent] = useState(
    fields[fields.length - 1]?.percent?.value
  );

  const checkLastPaymentPercent = useCallback(() => {
    if (fields.length > 0) {
      const lastIndex = fields.length - 1;
      setLastPaymentPercent(Number(fields[lastIndex]?.percent?.value || 0));
    }
  }, [fields, setLastPaymentPercent]);

  const handleChangePaymentPercent = useCallback(
    (value: number, index: number) => {
      if (isNaN(value)) {
        value = 0;
      }

      const lastIndex = fields.length - 1;
      const lastPercent = fields[lastIndex].percent.value;
      let totalPercent = fields.reduce((acc, field, i) => {
        return acc + (i === index ? value : Number(field.percent.value));
      }, 0);

      totalPercent += hasDownPayment ? downPaymentPercent : 0;
      if (totalPercent < 100) {
        // transfer the rest to the last field
        const newLastPercent = Number(lastPercent) + (100 - totalPercent);
        fields[lastIndex].percent.onChange(newLastPercent);
        setLastPaymentPercent(newLastPercent);
        const amount = newLastPercent * 0.01 * totalFinanceAmount;
        fields[lastIndex].amount.onChange(roundedDollarAmount(amount) || 0);
      } else if (
        totalPercent > 100 &&
        Number(lastPercent) > totalPercent - 100
      ) {
        // transfer the difference to the last field
        const newLastPercent = Number(lastPercent) - (totalPercent - 100);
        fields[lastIndex].percent.onChange(newLastPercent);
        setLastPaymentPercent(newLastPercent);
        const amount = newLastPercent * 0.01 * totalFinanceAmount;
        fields[lastIndex].amount.onChange(roundedDollarAmount(amount) || 0);
      }

      fields[index].percent.onChange(value);
      const amount = value * 0.01 * totalFinanceAmount;
      fields[index].amount.onChange(roundedDollarAmount(amount) || 0);
    },
    [fields, totalFinanceAmount, downPaymentPercent, hasDownPayment]
  );

  useEffect(() => {
    checkLastPaymentPercent();
  }, [checkLastPaymentPercent]);

  const helpLink = (
    <Button
      variant="plain"
      icon={InfoIcon}
      accessibilityLabel="Learn more"
      external
      url={"https://help.fortifypay.com/"}
    />
  );

  const settingTitle = title ? (
    <InlineStack gap="200" wrap={false} align="start" blockAlign="center">
      <Text variant="headingSm" as="h6">
        {title}
      </Text>

      {helpLink}
    </InlineStack>
  ) : null;

  const headerMarkup = <Box width="100%">{settingTitle}</Box>;

  const hasCompletePayments = fields.some(
    (installmentPaymentField: Field) =>
      installmentPaymentField.status?.value === "complete"
  );

  const transactionStatusBadge = (installmentPaymentField, fieldIndex) => {
    let statusBadge = null;
    if (installmentPaymentField.status?.value === "completed" ||
        installmentPaymentField.status?.value === "complete") {
      statusBadge = <Badge tone="success">Complete</Badge>;
    } else if (installmentPaymentField.status.value === "pending") {
      statusBadge = <Badge>Pending</Badge>;
    } else if (installmentPaymentField.status.value === "scheduled") {
      statusBadge = <Badge tone="info">Scheduled</Badge>;
    }

    return (
      <InlineStack gap="100" align="center">
        {statusBadge && (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigateToTransaction(installmentPaymentField.id.value);
            }}
          >
            {statusBadge}
          </div>
        )}
        <Button
          onClick={() => handleRemoveInstallmentPayment(fieldIndex)}
          variant="plain"
          tone="critical"
          disabled={hasCompletePayments}
        >
          Remove
        </Button>
      </InlineStack>
    );
  };

  return (
    <BlockStack gap="400">
      {headerMarkup}

      {fields.length > 0 ? (
        <FormLayout>
          {fields.map((installmentPaymentField, index: number) => {
            return (
              <FormLayout.Group key={`installmentPaymentField${index}`}>
                {usesMilestonePayments ? (
                  <div className="FortForm__NumericTextField">
                    <TextField
                      label={`Installment ${index + 1}`}
                      type="number"
                      suffix="%"
                      step={1}
                      min={0}
                      autoComplete="off"
                      disabled={
                        hasCompletePayments || fields.length == index + 1
                      }
                      {...installmentPaymentField.percent}
                      onChange={(value) =>
                        handleChangePaymentPercent(parseInt(value), index)
                      }
                      value={String(installmentPaymentField.percent.value)}
                    />
                  </div>
                ) : (
                  <TextField
                    label="Days after installment payment start date"
                    type="number"
                    step={1}
                    min={1}
                    autoComplete="off"
                    disabled={hasCompletePayments}
                    {...installmentPaymentField.days}
                    value={String(installmentPaymentField.days.value)}
                  />
                )}
                <TextField
                  label="Amount"
                  type="currency"
                  prefix="$"
                  disabled
                  autoComplete="off"
                  connectedRight={transactionStatusBadge(
                    installmentPaymentField,
                    index
                  )}
                  {...installmentPaymentField.amount}
                  value={String(
                    formatCurrency(
                      installmentPaymentField.amount.value,
                      "decimal"
                    )
                  )}
                />
              </FormLayout.Group>
            );
          })}
        </FormLayout>
      ) : (
        <Text as="p" variant="bodySm">
          No installment payments added yet.
        </Text>
      )}

      {hasCompletePayments ? (
        <Box>
          <Button onClick={navigateToTransactions}>View transactions</Button>
        </Box>
      ) : (
        <Box>
          {usesMilestonePayments ? (
            <Tooltip content="The last payment must be at least 2% before adding another installment payment.">
              <Button
                icon={PlusIcon}
                disabled={+lastPaymentPercent < 2}
                onClick={() =>
                  addInstallmentPayment({
                    fields: fields,
                    removeInstallmentPayments: removeInstallmentPayments,
                  })
                }
              >
                Add installment payment
              </Button>
            </Tooltip>
          ) : (
            <Button
              icon={PlusIcon}
              onClick={() =>
                addInstallmentPayment({
                  fields: fields,
                  removeInstallmentPayments: removeInstallmentPayments,
                })
              }
            >
              Add installment payment
            </Button>
          )}
        </Box>
      )}
    </BlockStack>
  );
};

export default InstallmentPaymentFields;