import React, { useCallback, useState, useEffect } from "react";

import {
  Box,
  Text,
  Button,
  TextField,
  BlockStack,
  InlineStack,
  Badge,
  FormLayout,
  Tooltip,
} from "@shopify/polaris";
import { roundedDollarAmount } from "../../finance";
import { formatCurrency } from "../../utilities";

import { FieldBag, useReset } from "@shopify/react-form";

import { PlusIcon, InfoIcon } from "@shopify/polaris-icons";

interface Field {
  balloon_payment: {
    value: number;
    onChange: (value: number) => void;
  };
}

interface LastField {
  days: {
    value: number;
    onChange: (value: number) => void;
  };
  percent: {
    value: number;
    onChange: (value: number) => void;
  };
  amount: {
    value: number;
    onChange: (value: number) => void;
  };
  status: {
    value: string;
    onChange: (value: string) => void;
  };
}

interface TransactionObject {
  id: string;
  status: string;
}

type BalloonPaymentFieldsProps = {
  fields: Field;
  hasBalloonPayment: boolean;
  setHasBalloonPayment: (value: boolean) => void;
  balloonPaymentCompletedAt: Date | null;
  downPaymentTransaction: TransactionObject;
  navigateToTransaction: (id: string) => void;
};

const BalloonPaymentFields: React.FC<BalloonPaymentFieldsProps> = ({
  fields,
  hasBalloonPayment,
  setHasBalloonPayment,
  balloonPaymentCompletedAt,
  navigateToTransaction,
  downPaymentTransaction,
}) => {
  const title = "Balloon Payment";

  const reset = useReset(fields as unknown as FieldBag);

  const handleAddBalloonPayment = useCallback(() => {
    setHasBalloonPayment(true);
  }, [
    setHasBalloonPayment
  ]);

  const handleRemoveDownPayment = useCallback(() => {
    setHasBalloonPayment(false);
    reset();
  }, [reset, setHasBalloonPayment]);

  const helpLink = (
    <Button
      variant="plain"
      icon={InfoIcon}
      accessibilityLabel="Learn more"
      external
      url={"https://help.fortifypay.com/"}
    />
  );

  const settingTitle = title ? (
    <InlineStack gap="200" wrap={false} align="start" blockAlign="center">
      <Text variant="headingSm" as="h6">
        {title}
      </Text>

      {helpLink}
    </InlineStack>
  ) : null;

  const headerMarkup = <Box width="100%">{settingTitle}</Box>;

  const transactionStatusBadge = useCallback(() => {
    let statusBadge = <Badge>Pending</Badge>;
    if (
      downPaymentTransaction?.status === "completed" ||
      downPaymentTransaction?.status === "complete"
    ) {
      statusBadge = <Badge tone="success">Complete</Badge>;
    } else if (downPaymentTransaction?.status === "scheduled") {
      statusBadge = <Badge tone="info">Scheduled</Badge>;
    }

    return (
      <InlineStack gap="100" align="center">
        {statusBadge && (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigateToTransaction(downPaymentTransaction?.id);
            }}
          >
            {statusBadge}
          </div>
        )}
        <Button
          onClick={handleRemoveDownPayment}
          variant="plain"
          tone="critical"
        >
          Remove
        </Button>
      </InlineStack>
    );
  }, [downPaymentTransaction, handleRemoveDownPayment, navigateToTransaction]);

  return (
    <BlockStack gap="400">
      {headerMarkup}

      <FormLayout>
        {hasBalloonPayment ? (
          <FormLayout.Group>
            <TextField
              autoComplete="off"
              label="Amount"
              type={"number"}
              step={1}
              prefix="$"
              disabled={
                (balloonPaymentCompletedAt ? true : false)
              }
              connectedRight={
                balloonPaymentCompletedAt ? (
                  <Badge tone="success">Complete</Badge>
                ) : (
                  transactionStatusBadge()
                )
              }
              {...fields.balloon_payment}
              onChange={(value) =>
                fields.balloon_payment.onChange(parseFloat(value) || 0)
              }
              value={String(fields.balloon_payment.value)}
            />
          </FormLayout.Group>
        ) : (
          <Box>
            <Button icon={PlusIcon} onClick={handleAddBalloonPayment}>
                Add balloon payment
              </Button>
          </Box>
        )}
      </FormLayout>
    </BlockStack>
  );
};

export default BalloonPaymentFields;
