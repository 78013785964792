import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, LegacyStack, TextField } from "@shopify/polaris";

import { useCreateDocumentMutation } from "../../../services/api";

const AddDocumentModal = (props) => {
  const {
    openAddDocumentModal,
    toggleAddDocumentModal,
  } = props;

  const [createDocument, { isLoading: isCreatingDocument }] =
    useCreateDocumentMutation();

  const [newDocumentName, setNewDocumentName] = useState("");

  const submitDocument = () => {
    return createDocument({
      body: {
        name: newDocumentName
      }
    })
      .unwrap()
      .then(() => {
        toggleAddDocumentModal();

        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <Modal
        open={openAddDocumentModal}
        title={"New document template"}
        primaryAction={{
          content: "Save",
          onAction: submitDocument,
          loading: isCreatingDocument,
          disabled: newDocumentName === ""
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: toggleAddDocumentModal,
          }
        ]}
        onClose={toggleAddDocumentModal}
      >
        <Modal.Section>
          <LegacyStack distribution="fillEvenly">
            <LegacyStack.Item>
              <TextField
                label="Document name"
                type="text"
                placeholder="Financing Document A"
                value={newDocumentName}
                onChange={setNewDocumentName}
              />
            </LegacyStack.Item>
          </LegacyStack>
        </Modal.Section>
      </Modal>
    </>
  );
};

AddDocumentModal.propTypes = {
  openAddDocumentModal: PropTypes.func,
  toggleAddDocumentModal: PropTypes.func,
};

export default AddDocumentModal;
