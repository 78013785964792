import React from "react";
import { useHistory } from "react-router-dom";
import {
  Page,
  Layout,
  Link,
  EmptyState,
  BlockStack,
} from "@shopify/polaris";
import { WithinContentContext } from "@shopify/polaris/build/esm/utilities/within-content-context";
import fourZeroFourSVG from "../assets/404.svg";

const FourZeroFour = (props) => {
  const history = useHistory();

  const handleNavigateBack = () => {
    history.goBack();
  };

  return (
    <Page narrowWidth>
      <Layout sectioned>
        <div className={"FortFatalError__Page"}>
          <WithinContentContext.Provider value>
            <EmptyState
              image={fourZeroFourSVG}
              imageContained
              heading="Page not found."
              action={{
                content: "Go back",
                onAction: handleNavigateBack,
              }}
            >
              <BlockStack>
                <p>
                  We can&apos;t seem to find the page you are looking for. Try
                  going back to the previous page or{" "}
                  <Link
                    external
                    url={`mailto:${
                      process.env.SUPPORT_EMAIL ||
                      "info@fortcapitalresources.com"
                    }`}
                  >
                    contact us
                  </Link>{" "}
                  for more information.
                </p>
              </BlockStack>
            </EmptyState>
          </WithinContentContext.Provider>
        </div>
      </Layout>
    </Page>
  );
};

export default FourZeroFour;
