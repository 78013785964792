import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import {
  Icon,
  Button,
  Text,
  Card,
  InlineStack,
  BlockStack,
  Banner,
  Spinner,
  EmptyState,
  Tooltip,
} from "@shopify/polaris";
import { CheckCircleIcon } from "@shopify/polaris-icons";

import {
  baseQuery,
  useGetOpportunityQuery,
  useGenerateOpportunityEnvelopeTemplatesMutation,
  useLazyGetDocusignSigningUrlQuery,
  useGetDocusignEnvelopesQuery,
  useGetOpportunityEnvelopeTemplatesQuery,
} from "../../../../../services/api";

import FadeIn from "../../../../../components/FadeIn";

import { CurrentContactContext } from "../../../../../contexts/Contact";

import emptyDocumentsSrc from "../../../../../assets/emptyEnvelopeTemplates.svg";

const SignDocuments = (props) => {
  const { opportunityId, setCanClickNext } = props;

  const { currentContact, emailVerified } = useContext(CurrentContactContext);

  const {
    data: opportunity = { attributes: {} },
    isLoading: isLoadingOpportunity,
    refetch: refetchOpportunity,
    error: opportunityError,
  } = useGetOpportunityQuery(opportunityId);

  const [
    generateOpportunityEnvelopeTemplates,
    { isLoading: isGeneratingOpportunityEnvelopeTemplates },
  ] = useGenerateOpportunityEnvelopeTemplatesMutation();

  const {
    data: docusignEnvelopes = [],
    isLoading: isLoadingDocusignEnvelopes,
    refetch: refetchDocusignEnvelopes,
  } = useGetDocusignEnvelopesQuery(opportunityId, { skip: !opportunityId });

  const {
    data: opportunityEnvelopeTemplates = { length: 0 },
    isLoading: isLoadingOpportunityEnvelopeTemplates,
  } = useGetOpportunityEnvelopeTemplatesQuery(
    {
      vendorProgramId: opportunity.attributes.primary_vendor_program_id,
    },
    {
      skip: !opportunity,
    }
  );

  const [getLazyDocusignSigningUrl] = useLazyGetDocusignSigningUrlQuery();

  const [showGenerateErrorBanner, setShowGenerateErrorBanner] = useState(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const [loadingEnvelopeId, setLoadingEnvelopeId] = useState();

  useEffect(() => {
    const isEnvelopeCompletedOrSigned = (envelope) => {
      return (
        envelope.attributes.docusign_status === "completed" ||
        envelope.attributes.docusign_status === "signed"
      );
    };

    const hasPendingEnvelopes = docusignEnvelopes.some(
      (envelope) => !isEnvelopeCompletedOrSigned(envelope)
    );

    const canClickNextBasedOnEnvelopes =
      opportunityEnvelopeTemplates.length === 0 &&
      docusignEnvelopes.length === 0;

    const canClickNextBasedOnStatus =
      !isGeneratingOpportunityEnvelopeTemplates &&
      !isLoadingDocusignEnvelopes &&
      !hasPendingEnvelopes;

    // Set 'canClickNext' based on both conditions
    setCanClickNext(canClickNextBasedOnEnvelopes || canClickNextBasedOnStatus);

    // Set 'showLoadingSpinner' based on loading states
    setShowLoadingSpinner(
      isGeneratingOpportunityEnvelopeTemplates || isLoadingDocusignEnvelopes
    );
  }, [
    opportunityEnvelopeTemplates,
    docusignEnvelopes,
    isGeneratingOpportunityEnvelopeTemplates,
    isLoadingDocusignEnvelopes,
    setCanClickNext,
    setShowLoadingSpinner,
  ]);

  const handleGenerateDocumentsFromTemplates = () => {
    setShowGenerateErrorBanner(false);

    let vendorProgramId = opportunity.attributes.primary_vendor_program_id;
    let body = {
      opportunity_id: opportunity.id,
    };

    return generateOpportunityEnvelopeTemplates({
      vendorProgramId: vendorProgramId,
      body: body,
    })
      .unwrap()
      .then(() => {
        refetchOpportunity();
        refetchDocusignEnvelopes();
      })
      .catch((error) => {
        console.log(error);
        setShowGenerateErrorBanner(true);
      });
  };

  const handleViewDocuments = (envelopeId) => {
    setLoadingEnvelopeId(envelopeId);

    axiosGetDocusignDocuments(envelopeId)
      .then(() => {
        setLoadingEnvelopeId();
      })
      .catch(() => {
        setLoadingEnvelopeId();
      });
  };

  const handleSignDocuments = (envelopeId) => {
    setLoadingEnvelopeId(envelopeId);
    getLazyDocusignSigningUrl({
      opportunityId: opportunityId,
      envelopeId: envelopeId,
    })
      .unwrap()
      .then(({ redirect_url }) => {
        setLoadingEnvelopeId();
        if (redirect_url) {
          setTimeout(() => {
            window.open(redirect_url, "_top");
          });
        }
        return { status: "success" };
      })
      .catch(() => {
        setLoadingEnvelopeId();
      });
  };

  const axiosGetDocusignDocuments = async (envelopeId) => {
    const pathname = `/opportunities/${opportunityId}/opportunity_envelopes/${envelopeId}/documents`;

    const result = await baseQuery()({
      url: pathname,
      method: "GET",
      responseType: "blob",
    });

    let filename = `Document Package - ${opportunity.attributes.client_name}`;
    let file = new File([result.data], `${filename}.pdf`, {
      type: "application/pdf",
    });
    let fileUrl = window.URL.createObjectURL(file);

    window.open(fileUrl);
  };

  function documentListToString(listString) {
    let stringList = "";
    for (let i = 0; i < listString.length; i++) {
      stringList = stringList + listString[i];
      if (i != listString.length - 1) {
        stringList = stringList + ", ";
      }
    }

    return stringList;
  }

  const isComplete = (envelope) => {
    return (
      envelope.attributes.docusign_status === "signed" ||
      envelope.attributes.docusign_status === "completed" ||
      envelope.attributes.docusign_envelope_completed_at
    );
  };

  return (
    <FadeIn fadeIn>
      <BlockStack>
        <Text variant="headingXl" as="h4">
          Select the document package below to begin signing with DocuSign
        </Text>
        <br />
      </BlockStack>

      {showGenerateErrorBanner && (
        <Banner title="Error Generating Documents" tone="critical">
          An error occured while generating opportunity documents. Please
          contact customer service for further details.
        </Banner>
      )}

      {showLoadingSpinner && (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "304px",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: "calc(50% - 2.5rem)",
              top: "7.5rem",
            }}
          >
            <Spinner size="large" />
          </div>
        </div>
      )}

      {!showLoadingSpinner && (
        <>
          {!isLoadingOpportunity &&
            !opportunity.attributes.has_opportunity_envelopes && (
              <Card>
                <EmptyState
                  heading="Generate document package(s) for signature"
                  action={{
                    content: "Generate document package(s)",
                    onAction: handleGenerateDocumentsFromTemplates,
                    disabled:
                      !opportunity.attributes.has_accepted_financing_option ||
                      !opportunity.attributes.has_designated_signer,
                  }}
                  image={emptyDocumentsSrc}
                  fullWidth
                >
                  <p>
                    You can generate documents for signature once terms and
                    signer are designated.
                  </p>
                </EmptyState>
              </Card>
            )}
        </>
      )}

      {!showLoadingSpinner &&
        docusignEnvelopes.map((envelope, index) => {
          return (
            <Card key={`envelope-${index}`}>
              <BlockStack gap="200">
                <InlineStack gap="400">
                  <div>
                    <Icon
                      tone={isComplete(envelope) ? "success" : "base"}
                      source={
                        isComplete(envelope) ? CheckCircleIcon : CheckCircleIcon
                      }
                    />
                  </div>
                  <Text
                    as="span"
                    tone={isComplete(envelope) ? "success" : "subdued"}
                  >
                    <Text as="span" fontWeight="semibold">
                      <Text variant="headingMd" as="h2">
                        Docusign Envelope
                      </Text>
                    </Text>
                  </Text>
                </InlineStack>

                <div>
                  <InlineStack align="space-between">
                    <BlockStack spacing="tight">
                      <Text as="span" tone="subdued">
                        {documentListToString(
                          envelope.attributes.document_list
                        )}
                      </Text>
                    </BlockStack>

                    {!isComplete(envelope) && (
                      <Button
                        external
                        onClick={() => handleSignDocuments(envelope.id)}
                        loading={loadingEnvelopeId === envelope.id}
                        variant="primary"
                      >
                        Begin signing
                      </Button>
                    )}

                    {currentContact && isComplete(envelope) && (
                      <Button
                        external
                        onClick={() => handleViewDocuments(envelope.id)}
                        loading={loadingEnvelopeId === envelope.id}
                        variant="plain"
                        disabled={!emailVerified}
                      >
                        View documents
                      </Button>
                    )}
                  </InlineStack>
                </div>
              </BlockStack>
            </Card>
          );
        })}
    </FadeIn>
  );
};

SignDocuments.propTypes = {
  opportunityId: PropTypes.string,
  setCanClickNext: PropTypes.func,
};

export default SignDocuments;
