import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter as Router, Route } from "react-router-dom";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { Provider } from "react-redux";

import { store } from "../store";
import Auth0ProviderWithHistory from "../contexts/Auth/Auth0Provider";
import { CurrentUserProvider } from "../contexts/User";
import { CurrentContactProvider } from "../contexts/Contact";
import { FinancingProvider } from "../contexts/FinancingOption/FinancingContext";
import { AxiosInterceptorProvider } from "../contexts/Auth/AxiosInterceptorProvider";
import { CommonAuthProvider } from "../contexts/Auth/CommonAuthProvider";
import { PostHogProvider } from "posthog-js/react";

import ScrollToTop from "../components/ScrollToTop";
import App from "../components/App";

async function render() {
  const posthogOptions = {
    api_host: process.env.POSTHOG_HOST,
    opt_in_site_apps: true,
  };

  const root = ReactDOM.createRoot(
    document.body.appendChild(document.createElement("div"))
  );

  root.render(
    <Router>
      <ScrollToTop />
      <Route
        path="/*"
        render={() => (
          <PostHogProvider
            apiKey={process.env.POSTHOG_KEY}
            options={posthogOptions}
          >
            <Auth0ProviderWithHistory>
              <CommonAuthProvider>
                <AxiosInterceptorProvider>
                  <Provider store={store}>
                    <CurrentContactProvider>
                      <CurrentUserProvider>
                        <FinancingProvider>
                          <App />
                        </FinancingProvider>
                      </CurrentUserProvider>
                    </CurrentContactProvider>
                  </Provider>
                </AxiosInterceptorProvider>
              </CommonAuthProvider>
            </Auth0ProviderWithHistory>
          </PostHogProvider>
        )}
      />
    </Router>
  );
}

document.addEventListener("DOMContentLoaded", () => {
  TimeAgo.addDefaultLocale(en);
  render();
});
