import React, { useState, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { Frame, Navigation } from "@shopify/polaris";
import { useAuth } from "../../contexts/Auth/CommonAuthProvider";
import { usePostHog } from "posthog-js/react";
import { CurrentContactContext } from "../../contexts/Contact";

import Navbar from "../Nav/Navbar";

import {
  StoreIcon,
  SettingsIcon,
  HomeIcon,
  ProfileIcon,
  PersonIcon,
  ProductIcon,
  BankIcon,
  AutomationIcon,
  ReceiptDollarIcon,
  BlogIcon,
  TransactionIcon,
} from "@shopify/polaris-icons";
import FortifyLogo from "images/fortify-logo-white-topbar.svg";

const FrameWrapper = ({ children }) => {
  const { isAuthenticated, error, logout } = useAuth();
  const posthog = usePostHog();
  const location = useLocation();
  const {
    currentContact,
    currentContactId,
    currentContactHasPermission,
    isOrganizationAdmin,
    isVendorAdmin,
    isTeamAdmin,
    isTeamMember,
    isCustomer,
    isLoadingPermissions,
    permissions,
  } = useContext(CurrentContactContext);

  const handleAuth0Logout = () => {
    posthog.reset();
    logout({ returnTo: window.location.origin });
  };

  const isCurrentPath = useCallback(
    (path) => {
      return path === location.pathname;
    },
    [location.pathname]
  );

  const currentPathIncludes = useCallback(
    (path) => {
      return location.pathname.includes(path);
    },
    [location.pathname]
  );

  const getCurrentRoleTitle = useCallback(() => {
    if (isOrganizationAdmin) {
      return "Organization Admin";
    } else if (isVendorAdmin) {
      return "Vendor Admin";
    } else if (isTeamAdmin) {
      return "Team Admin";
    } else {
      return "Team Member";
    }
  }, [isOrganizationAdmin, isVendorAdmin, isTeamAdmin]);

  const getOrganizationAdminNavigationItems = useCallback(
    () => [
      {
        label: "Opportunities",
        icon: ReceiptDollarIcon,
        accessibilityLabel: "Home",
        selected:
          isCurrentPath("/") &&
          !currentPathIncludes("/vendor_admin/teams") &&
          !currentPathIncludes("/accounts"),
        url: "/",
        onClick: isMobile && handleNavigationToggle,
      },
      {
        label: "Transactions",
        icon: TransactionIcon,
        accessibilityLabel: "Transactions",
        selected: isCurrentPath("/transactions"),
        url: "/transactions",
        onClick: isMobile && handleNavigationToggle,
      },
      {
        label: "Credit submissions",
        icon: BlogIcon,
        accessibilityLabel: "Credit submissions",
        selected: isCurrentPath("/credit_submissions"),
        url: "/credit_submissions",
        onClick: isMobile && handleNavigationToggle,
      },
      {
        label: "Teams",
        icon: ProfileIcon,
        accessibilityLabel: "Teams",
        selected: currentPathIncludes("/admin/teams"),
        url: "/admin/teams",
        onClick: isMobile && handleNavigationToggle,
      },
      {
        label: "Contacts",
        icon: ProfileIcon,
        accessibilityLabel: "Contacts",
        selected: currentPathIncludes("/admin/contacts"),
        url: "/admin/contacts",
        onClick: isMobile && handleNavigationToggle,
      },
      {
        label: "Clients",
        icon: PersonIcon,
        accessibilityLabel: "Clients",
        selected: currentPathIncludes("/admin/clients"),
        url: "/admin/clients",
        onClick: isMobile && handleNavigationToggle,
      },
      {
        label: "Vendors",
        icon: ProductIcon,
        accessibilityLabel: "Vendors",
        selected: currentPathIncludes("/admin/vendors"),
        url: "/admin/vendors",
        onClick: isMobile && handleNavigationToggle,
      },
      {
        label: "Lenders",
        icon: BankIcon,
        accessibilityLabel: "Lenders",
        selected: currentPathIncludes("/admin/lenders"),
        url: "/admin/lenders",
        onClick: isMobile && handleNavigationToggle,
      },
      {
        label: "Documents",
        icon: BlogIcon,
        accessibilityLabel: "Documents",
        selected: currentPathIncludes("/admin/documents"),
        url: "/admin/documents",
        onClick: isMobile && handleNavigationToggle,
      },
      {
        label: "Vendor Programs",
        icon: AutomationIcon,
        accessibilityLabel: "Vendor Programs",
        selected: currentPathIncludes("/admin/vendor_programs"),
        url: "/admin/vendor_programs",
        onClick: isMobile && handleNavigationToggle,
      },
      {
        label: "Settings",
        icon: SettingsIcon,
        accessibilityLabel: "Admin Settings",
        selected: currentPathIncludes("/admin/settings"),
        url: "/admin/settings",
        onClick: isMobile && handleNavigationToggle,
      },
    ],
    [currentPathIncludes, handleNavigationToggle, isCurrentPath]
  );

  const getVendorAdminNavigationItems = useCallback(
    () => [
      {
        label: "Opportunities",
        icon: ReceiptDollarIcon,
        accessibilityLabel: "Home",
        selected:
          isCurrentPath("/") &&
          !currentPathIncludes("/vendor_admin/teams") &&
          !currentPathIncludes("/accounts"),
        url: "/",
        onClick: isMobile && handleNavigationToggle,
      },
      {
        label: "Transactions",
        icon: TransactionIcon,
        accessibilityLabel: "Transactions",
        selected: isCurrentPath("/transactions"),
        url: "/transactions",
        onClick: isMobile && handleNavigationToggle,
      },
      {
        label: "Clients",
        icon: StoreIcon,
        accessibilityLabel: "Clients",
        selected:
          currentPathIncludes("/accounts") &&
          !currentPathIncludes("/vendor_admin/teams") &&
          !currentPathIncludes("/admin"),
        url: "/accounts",
        onClick: isMobile && handleNavigationToggle,
      },
      {
        label: "Teams",
        icon: ProfileIcon,
        accessibilityLabel: "Teams",
        selected:
          currentPathIncludes("/vendor_admin/teams") &&
          !currentPathIncludes("/accounts") &&
          !currentPathIncludes("/admin"),
        url: "/vendor_admin/teams",
        onClick: isMobile && handleNavigationToggle,
      },
      {
        label: "Vendor Settings",
        icon: SettingsIcon,
        accessibilityLabel: "Vendor Settings",
        selected:
          currentPathIncludes("/vendor_settings") &&
          !currentPathIncludes("/admin"),
        url: "/vendor_settings",
        onClick: isMobile && handleNavigationToggle,
      },
    ],
    [currentPathIncludes, handleNavigationToggle, isCurrentPath]
  );

  const getTeamMemberNavigationItems = useCallback(
    () => [
      {
        label: "Opportunities",
        icon: ReceiptDollarIcon,
        accessibilityLabel: "Home",
        selected:
          isCurrentPath("/") &&
          !currentPathIncludes("/vendor_admin/teams") &&
          !currentPathIncludes("/accounts"),
        url: "/",
        onClick: isMobile && handleNavigationToggle,
      },
      {
        label: "Clients",
        icon: StoreIcon,
        accessibilityLabel: "Clients",
        selected:
          currentPathIncludes("/accounts") &&
          !currentPathIncludes("/vendor_admin/teams") &&
          !currentPathIncludes("/admin"),
        url: "/accounts",
        onClick: isMobile && handleNavigationToggle,
      },
      {
        label: "Teams",
        icon: ProfileIcon,
        accessibilityLabel: "Teams",
        selected: isCurrentPath("/teams"),
        url: "/teams",
        onClick: isMobile && handleNavigationToggle,
      },
    ],
    [currentPathIncludes, handleNavigationToggle, isCurrentPath]
  );

  const getNavigationItems = useCallback(() => {
    if (isOrganizationAdmin) {
      return getOrganizationAdminNavigationItems();
    } else if (isVendorAdmin) {
      return getVendorAdminNavigationItems();
    } else {
      return getTeamMemberNavigationItems();
    }
  }, [
    getOrganizationAdminNavigationItems,
    getTeamMemberNavigationItems,
    getVendorAdminNavigationItems,
    isOrganizationAdmin,
    isVendorAdmin,
  ]);

  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const handleNavigationToggle = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive
      ),
    []
  );

  const logo = {
    width: 24,
    topBarSource: FortifyLogo,
    contextualSaveBarSource: FortifyLogo,
    url: "/",
    accessibilityLabel: "Fortify App",
  };

  const topbarMarkup =
    (isAuthenticated || error) &&
    !(
      location.pathname.includes("/share") ||
      location.pathname.includes("/callback")
    ) ? (
      <Navbar
        handleAuth0Logout={handleAuth0Logout}
        handleNavigationToggle={handleNavigationToggle}
      />
    ) : null;

  const navigationMarkup = isAuthenticated &&
    !(
      location.pathname.includes("/share") ||
      location.pathname.includes("/callback") ||
      location.pathname.includes("/request_access") ||
      location.pathname.includes("/profile")
    ) &&
    (isTeamMember || isVendorAdmin || isOrganizationAdmin) && (
      <Navigation location={location.pathname}>
        <Navigation.Section
          title={getCurrentRoleTitle().toUpperCase()}
          items={getNavigationItems()}
        />
      </Navigation>
    );

  return (
    <Frame
      topBar={topbarMarkup}
      logo={logo}
      navigation={navigationMarkup}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={handleNavigationToggle}
    >
      {children}
    </Frame>
  );
};

FrameWrapper.propTypes = {
  children: PropTypes.any,
};

export default FrameWrapper;
