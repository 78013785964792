import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, LegacyStack, TextField } from "@shopify/polaris";

import { useUpdateDocumentMutation } from "../../../services/api";

const EditDocumentModal = (props) => {
  const {
    documentId,
    openEditDocumentModal,
    toggleEditDocumentModal,
    refetchDocument
  } = props;

  const [documentName, setDocumentName] = useState("");

  const [updateDocument, { isLoading: isUpdatingDocument }] =
    useUpdateDocumentMutation();

  const saveDocument = () => {
    return updateDocument({
      id: documentId,
      name: documentName,
    })
      .unwrap()
      .then(() => {
        refetchDocument();
        toggleEditDocumentModal();

        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <Modal
        open={openEditDocumentModal}
        title={"Edit Document Name"}
        primaryAction={{
          content: "Save",
          onAction: saveDocument,
          disabled: documentName === "",
          loading: isUpdatingDocument
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: toggleEditDocumentModal
          }
        ]}
        onClose={toggleEditDocumentModal}
      >
        <Modal.Section>
          <LegacyStack distribution="fillEvenly">
            <LegacyStack.Item>
              <TextField
                label="Document name"
                type="text"
                placeholder="Financing Document A"
                value={documentName}
                onChange={setDocumentName}
              />
            </LegacyStack.Item>
          </LegacyStack>
        </Modal.Section>
      </Modal>
    </>
  );
};

EditDocumentModal.propTypes = {
  documentId: PropTypes.string,
  openEditDocumentModal: PropTypes.bool,
  toggleEditDocumentModal: PropTypes.func,
  refetchDocument: PropTypes.func,
};

export default EditDocumentModal;
