import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import {
  Text,
  BlockStack,
  Button,
  FormLayout,
  ExceptionList,
  Card,
  InlineStack,
} from "@shopify/polaris";

import {
  useGetGuarantorsQuery,
  useGetClientAccountQuery,
  useGetOpportunityContactsQuery,
} from "../../../../../services/api";

import FadeIn from "../../../../../components/FadeIn";
import ShareModal from "../../components/ShareModal";
import ClientCard from "./ClientCard";
import ClientForm from "./ClientForm";
import SignerCard from "./SignerCard";
import SignerForm from "./SignerForm";
import GuarantorCard from "./GuarantorCard";
import GuarantorFrom from "./GuarantorForm";
import { AlertTriangleIcon, PlusIcon } from "@shopify/polaris-icons";
import { useToast } from "../../../../../contexts/Toast";

const ConfirmSigner = (props) => {
  const { isNetTerms, clientId, opportunityId, opportunity, setCanClickNext } =
    props;

  const { data: opportunityContacts = [] } = useGetOpportunityContactsQuery(
    opportunityId,
    { skip: !opportunityId }
  );

  const { showToastWithMessage } = useToast();

  const { data: clientAccount = { attributes: {} } } =
    useGetClientAccountQuery(clientId, { skip: !clientId });

  const {
    data: guarantors = [],
    isLoading: isGuarantorsLoading,
    refetch: refetchGuarantors,
  } = useGetGuarantorsQuery(opportunityId, { skip: !opportunityId });

  useEffect(() => {
    for (let i = 0; i < opportunityContacts.length; i++) {
      if (opportunityContacts[i].attributes.signer) {
        setCanClickNext(true);
        break;
      }
    }
  }, [opportunityContacts, setCanClickNext]);

  const [selectedContact, setSelectedContact] = useState();

  const [showSignerList, setShowSignerList] = useState(false);
  const [showGuarantorList, setShowGuarantorList] = useState(true);
  const [showClientCard, setShowClientCard] = useState(false);

  useEffect(() => {
    const selectedContact = opportunityContacts.find(
      (contact) => contact.attributes.signer
    );
    setSelectedContact(selectedContact);

    if (selectedContact) {
      setShowSignerList(true);
    }
  }, [opportunityContacts]);

  useEffect(() => {
    if (
      !isGuarantorsLoading &&
      guarantors.length === 0 &&
      opportunity?.attributes?.requires_credit_application
    ) {
      setShowGuarantorList(false);
    }
  }, [
    guarantors,
    isGuarantorsLoading,
    opportunity?.attributes?.requires_credit_application,
  ]);

  const [showContactShareModal, setShowContactShareModal] = useState(false);
  const hasOpportunityEnvelopes =
    opportunity?.attributes?.has_opportunity_envelopes;

  const shareModal = (
    <ShareModal
      showContactShareModal={showContactShareModal}
      setShowContactShareModal={setShowContactShareModal}
      opportunityId={opportunityId}
      opportunity={opportunity}
    />
  );

  return (
    <FadeIn fadeIn>
      <BlockStack>
        <Text variant="headingXl" as="h4">
          {!showSignerList || !showClientCard
            ? "Please confirm key information for document generation"
            : "Let's make sure we have the correct information..."}
        </Text>
        <Text variant="bodyLg" as="p" tone="subdued">
          Share deal with a new contact to see them in the list
        </Text>
        <br />
      </BlockStack>

      <br />

      {!showClientCard && (
        <>
          <Text variant="headingMd" as="h6">
            Confirm business details
          </Text>

          <div style={{ height: "0.5rem" }} />

          <ClientForm
            clientAccount={clientAccount}
            setShowClientCard={setShowClientCard}
          />
        </>
      )}

      {showClientCard && (
        <Card>
          <BlockStack gap={300}>
            <Text variant="headingMd" as="h6">
              Confirm business details
            </Text>

            <ClientCard clientAccount={clientAccount} />

            <FormLayout>
              <InlineStack>
                <Button
                  disabled={hasOpportunityEnvelopes}
                  onClick={() => {
                    setShowClientCard(false);
                  }}
                >
                  Edit
                </Button>
              </InlineStack>
            </FormLayout>
          </BlockStack>
        </Card>
      )}

      <br />

      {!showSignerList && (
        <>
          <Text variant="headingMd" as="h6">
            Who will sign the documents?
          </Text>

          <div style={{ height: "0.5rem" }} />

          <SignerForm
            opportunityId={opportunityId}
            setShowSignerList={setShowSignerList}
          />
        </>
      )}

      {showSignerList && (
        <Card>
          <BlockStack gap={300}>
            <Text variant="headingMd" as="h6">
              Who will sign the documents?
            </Text>

            <SignerCard
              key={0}
              signerIndex={0}
              contact={selectedContact}
              selectedContact={selectedContact}
              handleSelectSigner={() => {}}
            />

            <FormLayout>
              <ExceptionList
                items={
                  hasOpportunityEnvelopes
                    ? [
                        {
                          status: "warning",
                          icon: AlertTriangleIcon,
                          description:
                            "Documents have been generated for the selected signer. The existing document package will need to be removed before the signer can be changed.",
                        },
                      ]
                    : []
                }
              />
              <Button
                disabled={hasOpportunityEnvelopes}
                onClick={() => {
                  setShowSignerList(false);
                }}
              >
                Change signer
              </Button>
            </FormLayout>
          </BlockStack>
        </Card>
      )}

      <br />

      {!showGuarantorList && !isNetTerms && (
        <Card>
          <BlockStack gap={300}>
            <Text variant="headingMd" as="h6">
              Guarantors
            </Text>
            <Text>We may request this information to confirm approval</Text>

            <GuarantorFrom
              opportunityId={opportunityId}
              guarantorCount={guarantors.length}
              setShowGuarantorList={setShowGuarantorList}
              refetchGuarantors={refetchGuarantors}
              showToastWithMessage={showToastWithMessage}
            />
          </BlockStack>
        </Card>
      )}

      {opportunity?.attributes?.requires_credit_application &&
        !isNetTerms &&
        showGuarantorList &&
        !isGuarantorsLoading && (
          <Card>
            <BlockStack gap={300}>
              <Text variant="headingMd" as="h6">
                Guarantors
              </Text>

              <Text>We may request this information to confirm approval</Text>

              {guarantors.map((guarantor, index) => (
                <GuarantorCard
                  key={index}
                  index={index + 1}
                  guarantor={guarantor}
                />
              ))}

              <FormLayout>
                <Button
                  icon={PlusIcon}
                  onClick={() => {
                    setShowGuarantorList(false);
                  }}
                >
                  Add guarantor
                </Button>
              </FormLayout>
            </BlockStack>
          </Card>
        )}

      {shareModal}
    </FadeIn>
  );
};

ConfirmSigner.propTypes = {
  opportunityId: PropTypes.string,
  opportunity: PropTypes.object,
  setCanClickNext: PropTypes.func,
  clientId: PropTypes.string,
  isNetTerms: PropTypes.bool,
};

export default ConfirmSigner;
