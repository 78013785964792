import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import {
  IndexTable,
  Filters,
  Text,
  Pagination,
  Link,
  Box,
} from "@shopify/polaris";

import { useDebounce } from "use-debounce";
import { useGetTeamsQuery } from "../../services/api";
import TeamMemberAvatarList from "./Membership/AvatarList";

const TeamIndexTable = (props) => {
  const {
    onTeamClick,
    itemLimit = 5,
  } = props;

  const resourceName = {
    singular: 'team',
    plural: 'teams',
  };

  const [limit, setLimit] = useState(itemLimit);
  const [page, setPage] = useState(1);
  const [queryValue, setQueryValue] = useState(undefined);
  const [searchValue] = useDebounce(queryValue, 500);
  const [sortValue, setSortValue] = useState("UPDATED_AT_DESC");
  const [sortDirection, setSortDirection] = useState("descending");
  const [selected, setSelected] = useState(0);

  const [taggedWith, setTaggedWith] = useState(undefined);
  const [originatorSearch] = useDebounce(taggedWith, 500);

  const handleTaggedWithRemove = useCallback(() => setTaggedWith(null), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(undefined), []);
  const handleFiltersClearAll = useCallback(() => {
      handleTaggedWithRemove();
      handleQueryValueRemove();
    }, [ handleQueryValueRemove, handleTaggedWithRemove ]
  );

  const { data: teamData = [], isLoading: queryIsLoading } =
    useGetTeamsQuery();

  const [teams, setTeams] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let filtered = [];

    if (queryValue && teamData.length) {
      const results = teamData.filter((item) => {
        return item.attributes.name
          .toLowerCase()
          .includes(queryValue.toLowerCase());
      });

      filtered = results;
    } else {
      if (!teamData.length) {
        filtered = [];
      } else {
        filtered = teamData;
      }
    }

    setTeams(filtered);
    setIsLoading(false);

    return () => setTeams([]);
  }, [teamData, queryValue]);

  const handleTableSorting = (headingIndex, direction) => {
    let currentSortDirection = "descending";
    if (sortDirection === "ascending") {
      currentSortDirection = "descending";
      setSortDirection("descending");
    } else {
      currentSortDirection = "ascending";
      setSortDirection("ascending");
    }

    let sortTag = "";
    switch(headingIndex){
      case 1:
        sortTag = "CREATED_AT_";
        break;
      case 2:
        sortTag = "ORIGINATOR_";
        break;
      case 3:
        sortTag = "AMOUNT_";
        break;
    }

    if (currentSortDirection === "ascending") {
      sortTag = `${sortTag}ASC`;
    } else {
      sortTag = `${sortTag}DESC`;
    }

    setSortValue(sortTag);
  };

  const handleChangePage = (mode) => {
    setIsLoading(true);
    setPage((state) => {
      if (mode == "next" && state == Math.ceil(teamData.length / limit)) {
        return Math.ceil(teamData.length / limit);
      }else if(mode == "previous" && state == 1){
        return 1;
      }

      if(mode == "next"){
        return state + 1;
      }else if(mode == "previous"){
        return state - 1;
      }
    });
  };

  const rowMarkup = teams.map((item, index) => (
    <IndexTable.Row
      id={index}
      key={index}
      position={index}
    >
      <IndexTable.Cell>
        <Text variant="strong" fontWeight="bold" truncate>
          <Link
            removeUnderline
            monochrome
            onClick={() => onTeamClick(item)}
          >
            {item.attributes.name}
          </Link>
        </Text>
      </IndexTable.Cell>
      <IndexTable.Cell>
        {item.attributes.vendor_name}
      </IndexTable.Cell>
      <IndexTable.Cell>
        <div>
          <TeamMemberAvatarList
            teamId={item.attributes.id}
          />
        </div>
      </IndexTable.Cell>
      <IndexTable.Cell>
        {`${item.attributes.member_count} members`}
      </IndexTable.Cell>
    </IndexTable.Row>
    ),
  );

  const TeamIndexTable = (
    <IndexTable
      resourceName={resourceName}
      itemCount={teamData.length}
      selectable={false}
      loading={isLoading || queryIsLoading}
      headings={[
        {title: 'Team'},
        {title: 'Vendor'},
        {title: 'Members'},
        {title: ''},
      ]}
      sortable={[true, false, false, false]}
      defaultSortDirection={"descending"}
      sortDirection={sortDirection}
      onSort={handleTableSorting}
    >
      {rowMarkup}
    </IndexTable>
  );

  const tablePagination = (
    <Pagination
      hasPrevious={page != 1}
      hasNext={page < Math.ceil(teamData.length / limit)}
      onPrevious={()=>{ handleChangePage("previous") } }
      onNext={()=>{ handleChangePage("next") } }
      label={(
        <Text>
          Page {page} of {Math.ceil(teamData.length / limit)}
        </Text>
      )}
    />
  );

  const indexTablePaged = (
    <Box>
      <Box
        paddingBlockStart="200"
        paddingBlockEnd="0"
        paddingInlineStart="200"
        paddingInlineEnd="200"
      >
        <Filters
          queryValue={queryValue}
          queryPlaceholder="Search teams"
          filters={[]}
          appliedFilters={[]}
          onQueryChange={setQueryValue}
          onQueryClear={handleQueryValueRemove}
          onClearAll={handleFiltersClearAll}
        />
      </Box>
      {TeamIndexTable}
    </Box>
  );

  return indexTablePaged;
};

TeamIndexTable.propTypes = {
  onTeamClick: PropTypes.func,
  itemLimit: PropTypes.number,
};

export default TeamIndexTable;
