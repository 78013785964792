import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";

import {
  Banner,
  BlockStack,
  Spinner,
  DropZone,
  Thumbnail,
  Text,
  FormLayout,
  List,
} from "@shopify/polaris";
import { NoteIcon } from "@shopify/polaris-icons";

import { useUpdateAdminVendorProgramMutation } from "../../../services/api";

import { DirectUploadProvider } from "react-activestorage-provider";
import { isMobile } from "react-device-detect";

import FadeIn from "../../FadeIn";

const AttachmentUpload = (props) => {
  const {
    onDragEnter,
    onDragLeave,
    onDragOver,
    onDrop,
    onFileDialogClose,
    openFileDialog,
    vendorProgramId,
    children,
    isDeletingAttachment,
    finalInvoice
  } = props;

  const [updateVendorProgram, { isLoading }] =
    useUpdateAdminVendorProgramMutation();

  const [file, setFile] = useState();
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const hasError = rejectedFiles.length > 0;
  const [fileUploadError, setFileUploadError] = useState(false);

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles, handleUpload) => {
      onDrop && onDrop(_dropFiles, acceptedFiles, _rejectedFiles);
      setFileUploadError(false);
      setFile((file) => acceptedFiles[0]);
      setRejectedFiles(_rejectedFiles);

      handleUpload(acceptedFiles);
    },
    [onDrop]
  );

  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

  const fileUpload = !file && <DropZone.FileUpload actionHint=" or drop files to upload" />;
  const uploadedFiles = file && (
    <BlockStack>
      <BlockStack align="center" gap="200">
        <Thumbnail
          size="small"
          alt={file.name}
          source={
            validImageTypes.includes(file.type)
              ? window.URL.createObjectURL(file)
              : NoteIcon
          }
        />
        <div>
          {file.name} <Text variant="bodySm" as="p">{file.size} bytes</Text>
        </div>
      </BlockStack>
    </BlockStack>
  );

  const fileTypeErrorMessage = hasError && (
    <Banner title="The following files couldn’t be uploaded:" tone="critical">
      <List type="bullet">
        {rejectedFiles.map((file, index) => (
          <List.Item key={index}>
            {`"${file.name}" is not supported. File type must be .gif, .png, or .jpg.`}
          </List.Item>
        ))}
      </List>
    </Banner>
  );

  const handleAttachment = (signedIds) => {
    return updateVendorProgram({
      id: vendorProgramId,
      attachment: signedIds[0],
    })
      .unwrap()
      .then((result) => {
        setFile();
        return { status: "success" };
      })
      .catch((error) => {
        setFileUploadError(true);
        console.log(error);
      });
  };

  const token = window.localStorage.getItem("access-token");
  const client = window.localStorage.getItem("client");
  const uid = window.localStorage.getItem("uid");

  return (
    <DirectUploadProvider
      multiple={false}
      onSuccess={handleAttachment}
      headers={{ "access-token": token, client: client, uid: uid }}
      render={({ handleUpload, uploads, ready }) => {
        return <>
          {!isMobile && (
            <FadeIn fadeIn={isLoading || isDeletingAttachment}>
              <Spinner accessibilityLabel="Spinner" size="large" />
            </FadeIn>
          )}

          {isMobile && (isLoading || isDeletingAttachment) && (
            <FadeIn fadeIn={isLoading || isDeletingAttachment}>
              <Spinner accessibilityLabel="Spinner" size="large" />
            </FadeIn>
          )}

          {!isLoading && (
            <FormLayout>
              {fileUploadError && !hasError && (
                <Banner tone="critical" title="Upload failed">
                  We were unable to upload the logo you provided due to a
                  system error. Please try again in a few minutes.
                </Banner>
              )}
              {fileTypeErrorMessage}
              <DropZone
                accept={[...validImageTypes]}
                allowMultiple={false}
                dropOnPage
                errorOverlayText="File type must be .gif, .png, or .jpg"
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDragOver={onDragOver}
                onDrop={(_dropFiles, acceptedFiles, _rejectedFiles) =>
                  handleDropZoneDrop(
                    _dropFiles,
                    acceptedFiles,
                    _rejectedFiles,
                    handleUpload
                  )
                }
                onFileDialogClose={onFileDialogClose}
                openFileDialog={openFileDialog}
              >
                {uploadedFiles}
                {fileUpload}
                {children}
              </DropZone>
            </FormLayout>
          )}
        </>;
      }}
    />
  );
};

AttachmentUpload.propTypes = {
  isWithinCard: PropTypes.bool,
  onDragEnter: PropTypes.func,
  onDragLeave: PropTypes.func,
  onDragOver: PropTypes.func,
  onDrop: PropTypes.func,
  onFileDialogClose: PropTypes.func,
  openFileDialog: PropTypes.bool,
  vendorProgramId: PropTypes.string,
  children: PropTypes.object,
  isDeletingAttachment: PropTypes.bool,
};

export default AttachmentUpload;

