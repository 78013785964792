import React, { useState, useEffect, useContext } from "react";

import PropTypes from "prop-types";
import {
  BlockStack,
  DataTable,
  Scrollable,
  Text,
} from "@shopify/polaris";

import { useMeasure } from "react-use";


import TransactionBadge from "../../../../components/Transaction/TransactionBadge";
import {
  formatCurrency,
  formatNumericDate,
  convertServerDate,
} from "../../../../utilities";

import { CurrentContactContext } from "../../../../contexts/Contact";

const PaymentScheduleTable = (props) => {
  const { opportunity } = props;

  const { isOrganizationAdmin } = useContext(
    CurrentContactContext
  );

  const [paymentSchedule, setPaymentSchedule] = useState([]);
  const [ref, { width: componentWidth }] = useMeasure();
  const componentMaxWidthToTruncate = 450;

  const sigmoid = (x) => {
    return 1 / (1 + Math.exp(-x));
  };

  const widthFactor = sigmoid((componentWidth - 375) / 100);

  const widthStyle =
    componentWidth <= componentMaxWidthToTruncate
      ? componentWidth * widthFactor
      : "100%";

  const hasFullDisplay = componentWidth >= componentMaxWidthToTruncate;
  const hasTypeColumn = componentWidth >= 300;

  useEffect(() => {
    if (opportunity) {
      let paymentSched = [...opportunity.attributes.payment_schedule];
      paymentSched.sort(
        (a, b) =>
          new Date(a.date).getTime() -
          new Date(b.date).getTime()
      );
      setPaymentSchedule(paymentSched);
    }
  }, [opportunity]);

  const formattedPaymentScheduleRows =
    paymentSchedule.map((payment, index) => {
      return [
        index + 1,
        payment.date
          ? formatNumericDate(convertServerDate(payment.date))
          : "--",
        hasTypeColumn ? (
          <div key={index} style={{ width: widthStyle }}>
            <Text breakWord={false} truncate={true} as={"span"}>
              {isOrganizationAdmin ? payment.type : payment.limited_type}
            </Text>
          </div>
        ) : null,
        hasFullDisplay ? (
          <TransactionBadge key={index} status={payment.status} />
        ) : null,
        formatCurrency(payment.amount),
      ].flat();
    });

  return (
    <div ref={ref}>
      <BlockStack gap="400">
        <Scrollable shadow style={{ maxHeight: "480px" }} focusable>
          <DataTable
            columnContentTypes={[
              "text",
              "text",
              hasTypeColumn ? "text" : null,
              hasFullDisplay ? "text" : null,
              "numeric",
            ].flat()}
            headings={[
              "#",
              "Date",
              hasTypeColumn ? "Type" : null,
              hasFullDisplay ? "Status" : null,
              "Amount",
            ].flat()}
            rows={formattedPaymentScheduleRows}
            hasZebraStripingOnData
            increasedTableDensity
          />
        </Scrollable>
      </BlockStack>
    </div>
  );
};

PaymentScheduleTable.propTypes = {
  opportunity: PropTypes.object,
};

export default PaymentScheduleTable;
