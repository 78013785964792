import React from "react";
import {
  SkeletonPage,
  Layout,
  BlockStack,
  SkeletonDisplayText,
  SkeletonBodyText,
  Card,
  Divider,
  Bleed,
} from "@shopify/polaris";

type Props = {
  purchaseOptionType: string;
};

const LoadingSkeleton = (props: Props) => {
  const { purchaseOptionType } = props;

  return (
    <SkeletonPage primaryAction fullWidth={purchaseOptionType !== "net_terms"}>
      <Layout>
        <Layout.Section>
          <BlockStack gap="400">
            <Card>
              <BlockStack gap={"400"}>
                <SkeletonBodyText />
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap={"400"}>
                <BlockStack gap={"400"}>
                  <SkeletonDisplayText />
                  <SkeletonBodyText />
                  <SkeletonBodyText />
                </BlockStack>
                <Bleed marginInline={"400"}>
                  <Divider />
                </Bleed>
                <BlockStack gap={"400"}>
                  <SkeletonDisplayText />
                  <SkeletonBodyText />
                  <SkeletonBodyText />
                </BlockStack>
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap={"400"}>
                <SkeletonDisplayText />
                <SkeletonBodyText />
                <SkeletonBodyText />
              </BlockStack>
            </Card>
          </BlockStack>
        </Layout.Section>
        {purchaseOptionType !== "net_terms" && (
          <Layout.Section variant="oneThird">
            <BlockStack gap="400">
              <Card>
                <BlockStack gap={"400"}>
                  <SkeletonDisplayText />
                  <SkeletonBodyText />
                  <SkeletonBodyText />
                </BlockStack>
              </Card>
            </BlockStack>
          </Layout.Section>
        )}
      </Layout>
    </SkeletonPage>
  );
};

export default LoadingSkeleton;
