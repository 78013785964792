import React from "react";
import PropTypes from "prop-types";

import {
  Card,
  Text,
  Avatar,
  InlineStack,
} from "@shopify/polaris";

const GuarantorCard = (props) => {
  const { index, guarantor } = props;

  return (
    <>
      <Card sectioned key={`option-${index}`}>
        {/* <div onClick={() => handleSelectSigner(contact)}> */}
        <div>
          <InlineStack gap="400" align="start" blockAlign="center" wrap={false}>
            <Text>
              <Avatar
                size="sm"
                name={guarantor?.attributes?.name || ""}
                initials={guarantor?.attributes?.initials || ""}
              />
            </Text>

            <div>
              <Text variant="bodyMd" fontWeight="bold" as="h3">
                {guarantor?.attributes?.name || ""}
              </Text>

              <Text variant="bodyMd" as="span" tone="subdued">
                {guarantor?.attributes?.email || "-"}
              </Text>
            </div>
          </InlineStack>
        </div>
      </Card>
    </>
  );
};

GuarantorCard.propTypes = {
  index: PropTypes.number,
  guarantor: PropTypes.object,
};

export default GuarantorCard;
