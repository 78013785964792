import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";

import {
  Avatar,
  Icon,
  Card,
  Filters,
  Page,
  ResourceList,
  ResourceItem,
  Text,
  Box,
  BlockStack,
  InlineStack,
  Thumbnail,
  Layout,
  Pagination,
  Truncate,
  Modal,
  Tooltip,
} from "@shopify/polaris";
import { AlertCircleIcon, AlertTriangleIcon, StoreIcon } from "@shopify/polaris-icons";

import { CurrentContactContext } from "../../../contexts/Contact";

import { useGetAdminAccountsQuery } from "../../../services/api";
import AccountForm from "../../../components/Account/Form";
import FadeIn from "../../../components/FadeIn";
import LoadingScreen from "../../../components/Auth/Loading";

const AdminLenderIndex = () => {
  const resourceName = {
    singular: "lender",
    plural: "lenders",
  };

  const history = useHistory();

  const  { currentContact } = useContext(CurrentContactContext)

  const { data: accounts = [], isLoading } = useGetAdminAccountsQuery();

  const [selectedItems, setSelectedItems] = useState([]);
  const [queryValue, setQueryValue] = useState(undefined);
  const [filteredItems, setFilteredItems] = useState([]);

  const handleQueryValueRemove = useCallback(
    () => setQueryValue(undefined),
    []
  );
  const handleQueryChange = (value) => setQueryValue(value);

  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelectedTab(selectedTabIndex),
    []
  );

  const filterControl = (
    <Filters
      filters={[]}
      queryValue={queryValue}
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryValueRemove}
    />
  );

  useEffect(() => {
    let filtered = [];

    if (queryValue && accounts.length) {
      const results = accounts.filter((item) => {
        return item.attributes.name
          .toLowerCase()
          .includes(queryValue.toLowerCase());
      });

      filtered = results;
    } else {
      if (!accounts.length) {
        filtered = [];
      } else {
        filtered = accounts;
      }
    }

    const lenders = filtered.filter((account) => {
      return account.attributes.type === "Lender";
    });
    setFilteredItems(lenders);

    return () => setFilteredItems([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue, accounts.length, selectedTab]);

  const [currentPage, setCurrentPage] = useState(1);
  const handleNextPage = () => {
    setCurrentPage((state) => {
      if (state == paginatedFilteredItems.length) {
        return paginatedFilteredItems.length;
      }
      return state + 1;
    });
  };
  const handlePreviousPage = () => {
    setCurrentPage((state) => {
      if (state == 1) {
        return 1;
      }
      return state - 1;
    });
  };

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [paginatedFilteredItems, setPaginatedFilteredItems] = useState([]);
  const [currentPageItems, setCurrentPageItems] = useState([]);
  useEffect(() => {
    const perPage = itemsPerPage;

    const paginatedFilteredItems = filteredItems.reduce(
      (resultArray, item, index) => {
        const pageIndex = Math.floor(index / perPage);
        if (!resultArray[pageIndex]) {
          resultArray[pageIndex] = [];
        }
        resultArray[pageIndex].push(item);
        return resultArray;
      },
      []
    );

    setPaginatedFilteredItems(paginatedFilteredItems);
  }, [filteredItems, itemsPerPage]);

  useEffect(() => {
    const pageIndex = currentPage - 1;
    const currentPageItems = paginatedFilteredItems[pageIndex] || [];

    setCurrentPageItems(currentPageItems);
  }, [paginatedFilteredItems, currentPage]);

  const accountsResourceList = (
    <ResourceList
      resourceName={resourceName}
      items={currentPageItems}
      renderItem={(item) => renderItem(item, history)}
      selectedItems={selectedItems}
      onSelectionChange={setSelectedItems}
      filterControl={filterControl}
      loading={isLoading}
      flushFilters
    />
  );

  const paginationMarkup = (
    <Pagination
      hasPrevious={currentPage != 1}
      onPrevious={handlePreviousPage}
      label={`Page ${currentPage} of ${paginatedFilteredItems.length}`}
      hasNext={currentPage < paginatedFilteredItems.length}
      onNext={handleNextPage}
    />
  );

  const navigateToNewLenderShow = (lender) => {
    history.push({
      pathname: `/admin/lenders/${lender.id}`,
      state: {
        showToast: true,
        toastMessage: "Lender created.",
      },
    });
  };

  const accountFormRef = useRef();
  const [showAddAccountModal, setShowAddAccountModal] = useState(false);
  const handleCloseModal = () => {
    setShowAddAccountModal(false);
  };

  const [accountFormDirty, setAccountFormDirty] = useState(false);
  const handleAccountEdit = (isDirty) => {
    setAccountFormDirty(isDirty);
  };

  const handleCreateAccount = (lender) => {
    handleCloseModal();
    navigateToNewLenderShow(lender);
  };

  const addAccountModal = (
    <Modal
      open={showAddAccountModal}
      onClose={handleCloseModal}
      title={"Add Lender"}
      primaryAction={{
        content: "Save",
        onAction: () => accountFormRef.current.handleSubmitForm(),
        disabled: !accountFormDirty,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleCloseModal,
        },
      ]}
    >
      <AccountForm
        ref={accountFormRef}
        onEdit={handleAccountEdit}
        onCreateOrUpdate={handleCreateAccount}
        isLender={true}
      />
    </Modal>
  );

  const accountIndexPageMarkup = (
    <FadeIn fadeIn>
      <Page
        title="Lenders"
        secondaryActions={[
          {
            content: "Add lender",
            accessibilityLabel: "Add lender",
            onAction: () => {
              setShowAddAccountModal(true);
            },
          },
        ]}
      >
        <Layout>
          <Layout.Section>
            <Card padding="0">
              <div flush="true">{accountsResourceList}</div>
              {paginatedFilteredItems.length > 1 && (
                <Box padding="400">
                  <InlineStack align="center">{paginationMarkup}</InlineStack>
                </Box>
              )}
            </Card>
          </Layout.Section>
        </Layout>
        {addAccountModal}
      </Page>
    </FadeIn>
  );

  return currentContact ? accountIndexPageMarkup : <LoadingScreen />;
};

const renderItem = (item, history) => {
  const {
    id,
    attributes: {
      name,
      billing_address,
      billing_city,
      billing_state,
      reviewed,
      type,
      has_unapproved_opportunities,
    },
  } = item;
  const media = (
    <Avatar size="sm" name={name} initials={name?.slice(0, 1)} />
  );
  const thumbnail = (
    <Thumbnail source={StoreIcon} size="extraSmall" alt={`Icon of ${name}`} />
  );

  const handleAccountClick = () => {
    navigateToAdminAccountShow();
  };

  const navigateToAdminAccountShow = () => {
    history.push({
      pathname: `/admin/lenders/${id}`,
      state: { account: { ...item } },
    });
  };

  const billingCityState =
    billing_city && billing_state
      ? `${billing_city}, ${billing_state}`
      : billing_city
      ? billing_city
      : billing_state
      ? billing_state
      : null;

  return (
    <ResourceItem
      id={id}
      media={thumbnail}
      onClick={handleAccountClick}
      accessibilityLabel={`View details for ${name}`}
      verticalAlignment="center"
    >
      <InlineStack align="space-between">
        <BlockStack>
          <Text as="span" fontWeight="semibold" >
            <Truncate>{name}</Truncate>
          </Text>
          <Text variant="bodySm" as="p">
            <Truncate>
              {type} ∙ {billingCityState || "-"}
            </Truncate>
          </Text>
        </BlockStack>

        <div>
          <InlineStack align="end" gap="0">
            {has_unapproved_opportunities && (
              <Tooltip content="This account has unapproved financing options.">
                <Icon source={AlertCircleIcon} tone={"base"} />
              </Tooltip>
            )}

            {!reviewed && (
              <Tooltip content="This account needs review.">
                <Icon source={AlertTriangleIcon} tone={"warning"} />
              </Tooltip>
            )}
          </InlineStack>
        </div>
      </InlineStack>
    </ResourceItem>
  );
};

export default AdminLenderIndex;
