import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";

import {
  Card,
  Page,
  Layout,
  Toast,
  Box,
  Modal,
  IndexTable,
  Text,
  Select,
  InlineStack,
  Button,
} from "@shopify/polaris";

import {
  useGetVendorAdminTeamQuery,
  useGetVendorAdminMembershipsByTeamQuery,
  useInviteMembershipsByTeamMutation,
  useUpdateVendorAdminMembershipRoleMutation,
  useUpdateTeamMutation,
} from "../../../services/api";

import { formatShortDateTime } from "../../../utilities";
import FadeIn from "../../../components/FadeIn";
import LoadingScreen from "../../../components/Auth/Loading";

import VendorAdminTeamContactForm from "../../../components/VendorAdmin/Team/Contact/Form";
import VendorLockCard from "../../../components/Team/VendorLockCard";
import PurchaseOptionLockCard from "../../../components/Team/PurchaseOptionLockCard";

import { CurrentContactContext } from "../../../contexts/Contact";
import AdminTeamForm from "../../../components/Admin/Team/Form";

import CardBoxTitle from "../../../components/Card/BoxTitle";

const VendorAdminTeamShow = (props) => {
  const history = useHistory();
  const location = useLocation();

  const { id: teamId } = useParams();

  const { currentContact } = useContext(CurrentContactContext);
  const [showMembersInviteModal, setShowMembersInviteModal] = useState(false);
  const [showNewContactForm, setShowNewContactForm] = useState(false);
  const [addNewContact, setAddNewContact] = useState(false);
  const [inviteParams, setInviteParams] = useState({
    contactIds: [],
    notifyContacts: false,
    notifMsg: "",
    isManager: false,
  });
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedMemberUpdate, setSelectedMemberUpdate] = useState("");

  const [showToast, setShowToast] = useState(location?.state?.showToast);
  const [toastMessage, setToastMessage] = useState(
    location?.state?.toastMessage
  );

  const toggleShowToast = useCallback(
    () => setShowToast((active) => !active),
    []
  );
  const handleDismissToast = () => {
    const { state: currentState } = location;
    if (currentState?.toastMessage) {
      delete currentState.toastMessage;
    }
    if (currentState?.showToast) {
      delete currentState.showToast;
    }
    window.history.replaceState({ ...currentState }, "");
    toggleShowToast();
    setToastMessage();
  };
  const toastMarkup = showToast ? (
    <Toast content={toastMessage} onDismiss={handleDismissToast} />
  ) : undefined;

  const navigateToAdminIndex = useCallback(() => {
    history.push("/admin");
  }, [history]);

  useEffect(() => {
    if (!teamId) {
      navigateToAdminIndex();
    }
  }, [teamId, navigateToAdminIndex]);

  const [inviteMember, { isLoading: isInviting }] =
    useInviteMembershipsByTeamMutation();

  const [updateMemberRole, { isLoading: isUpdatingRole }] =
    useUpdateVendorAdminMembershipRoleMutation();

  const { data: team = { attributes: {} }, isLoading: isLoadingTeam } =
    useGetVendorAdminTeamQuery({ id: teamId });

  const { data: memberships = [], isLoading: isLoadingMemberships } =
    useGetVendorAdminMembershipsByTeamQuery(teamId);

  const [updateTeam, { isLoading: isUpdatingTeam }] = useUpdateTeamMutation();

  const [teamName, setTeamName] = useState("");

  useEffect(() => {
    setTeamName(team.attributes.name);
  }, [team]);

  const handleInviteToTeam = () => {
    inviteMember({ teamId, ...inviteParams })
      .then(() => {
        setToastMessage(`Contacts invited to ${teamName}`);
        setShowToast(true);
        setShowMembersInviteModal(false);

        return { status: "success" };
      })
      .catch((error) => console.log(error));
  };

  const handleSelectFocus = useCallback(
    (value) => setSelectedMemberUpdate(value),
    [selectedMemberUpdate]
  );

  const handleUpdateRole = useCallback(
    (value) => {
      updateMemberRole({
        memberId: selectedMemberUpdate,
        team_id: teamId,
        isManager: value == "Admin",
      })
        .then(() => {
          setToastMessage(`Role updated to ${value}`);
          setShowToast(true);

          return { status: "success" };
        })
        .catch((error) => console.log(error));
    },
    [selectedMemberUpdate]
  );

  const updateTeamName = useCallback(() => {
    return updateTeam({ team_id: teamId, team_name: teamName })
      .unwrap()
      .then((response) => {
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
        return {
          status: "fail",
          errors: [{ message: "Could not update team name." }],
        };
      });
  }, [teamName]);

  const toggleVendorLock = () => {
    return updateTeam({ team_id: teamId })
      .unwrap()
      .then((response) => {
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
        return {
          status: "fail",
          errors: [{ message: "Could not toggle vendor lock." }],
        };
      });
  };

  const togglePurchaseOptionLock = () => {
    return updateTeam({ team_id: teamId, financing_option_locked: true })
      .unwrap()
      .then((response) => {
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
        return {
          status: "fail",
          errors: [{ message: "Could not toggle purchase option lock." }],
        };
      });
  };

  const rowMarkup =
    memberships.length > 0
      ? memberships.map((item, index) => (
          <IndexTable.Row id={index} key={index} position={index}>
            <IndexTable.Cell>
              <Text variant="strong" fontWeight="bold" truncate>
                {item.attributes.contact_full_name}
              </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>{item.attributes.contact_email}</IndexTable.Cell>
            <IndexTable.Cell>
              {item.attributes.last_logged_in_at
                ? formatShortDateTime(
                    new Date(item.attributes.last_logged_in_at)
                  )
                : ""}
            </IndexTable.Cell>
            <IndexTable.Cell>
              <Select
                options={["Member", "Admin"]}
                onFocus={() => handleSelectFocus(item.attributes.id)}
                onChange={handleUpdateRole}
                value={item.attributes.is_team_admin ? "Admin" : "Member"}
              />
            </IndexTable.Cell>
          </IndexTable.Row>
        ))
      : [];

  const labelStyles = {
    minHeight: "36px",
    paddingTop: "4px",
  };

  const teamContactFormRef = useRef();

  const inviteMembersModal = (
    <Modal
      open={showMembersInviteModal}
      onClose={() => setShowMembersInviteModal(false)}
      title={`Invite new members to ${teamName}`}
      large={true}
      primaryAction={{
        content: "Send",
        loading: addNewContact,
        onAction: () => {
          if (showNewContactForm) {
            setAddNewContact(true);
          } else {
            handleInviteToTeam();
            setShowMembersInviteModal(false);
          }
        },
      }}
    >
      <Modal.Section>
        <VendorAdminTeamContactForm
          onAfterShareWithContacts={() => {}}
          teamId={teamId}
          memberships={memberships}
          ref={teamContactFormRef}
          setInviteParams={setInviteParams}
          isShowNewContactForm={setShowNewContactForm}
          addNewContact={addNewContact}
          setAddNewContact={setAddNewContact}
        />
      </Modal.Section>
    </Modal>
  );

  const handleTeamUpdate = () => {
    setToastMessage("Team updated");
    setShowToast(true);
  };

  const pageMarkup = (
    <FadeIn fadeIn>
      <Page fullWidth>
        <Layout>
          {/* Team details */}
          <Layout.Section>
            <Card padding="0">
              <CardBoxTitle>
                <Text variant="headingMd" as="h6">
                  Basic information
                </Text>
              </CardBoxTitle>
              <Box padding="400">
                <AdminTeamForm
                  team={team}
                  teamId={team.id}
                  onCreateOrUpdate={handleTeamUpdate}
                  onEdit={() => {}}
                />
              </Box>
            </Card>

            <br />
            <VendorLockCard team={team} toggleVendorLock={toggleVendorLock} />
            <br />
            <PurchaseOptionLockCard team={team} togglePurchaseOptionLock={togglePurchaseOptionLock} />
            <br />

            <Card padding="0">
              <Box padding="400">
                <InlineStack align="space-between">
                  <Text variant="headingMd" as="h6">
                    Members
                  </Text>
                  <Button
                    variant="primary"
                    onClick={() => setShowMembersInviteModal(true)}
                  >
                    Invite members
                  </Button>
                </InlineStack>
              </Box>
              <Box>
                <IndexTable
                  resourceName={{
                    singular: "member",
                    plural: "members",
                  }}
                  itemCount={memberships.length}
                  selectable={false}
                  loading={isLoadingMemberships}
                  headings={[
                    { title: "User" },
                    { title: "Email" },
                    { title: "Last login" },
                    { title: "Role" },
                  ]}
                  sortable={[true, false, false, false]}
                  defaultSortDirection={"descending"}
                >
                  {rowMarkup}
                </IndexTable>
              </Box>
            </Card>
          </Layout.Section>
          {inviteMembersModal}
          {toastMarkup}
        </Layout>
      </Page>
    </FadeIn>
  );

  return currentContact ? pageMarkup : <LoadingScreen />;
};

export default VendorAdminTeamShow;
