import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";

import {
  Icon,
  Tooltip,
  Box,
  InlineStack,
  Badge,
  // ButtonGroup,
  Button,
  Popover,
  ActionList,
} from "@shopify/polaris";
import {
  ExternalIcon,
  ConnectIcon,
  MenuHorizontalIcon,
} from "@shopify/polaris-icons";

import PaymentMethodResourceItem from "../Stripe/PaymentMethodResourceItem";

const PaymentMethodCard = (props) => {
  const {
    validatePurchaseMethod,
    stripeSetupIntent,
    opportunity,
    paymentMethod,
    allPaymentMethodsLength,
    index,
  } = props;

  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const activator = (
    <Button
      textAlign="left"
      variant="tertiary"
      onClick={toggleActive}
      icon={MenuHorizontalIcon}
    />
  );

  return (
    <Box
      padding="400"
      paddingBlockEnd={
        index + 1 === allPaymentMethodsLength ? "400" : "0"
      }
      as="section"
      key={index}
    >
      <InlineStack align="space-between" wrap={false}>
        <InlineStack
          align="space-between"
          blockAlign={"center"}
          wrap={false}
          gap="200"
        >
          <PaymentMethodResourceItem
            id={paymentMethod.id}
            card={paymentMethod.card}
            us_bank_account={paymentMethod.us_bank_account}
            resourceItem={false}
          />

          {paymentMethod?.destination == "connected" && (
            <Tooltip content={"Connected account"}>
              <Icon source={ConnectIcon} tone={"interactive"} />
            </Tooltip>
          )}

          {stripeSetupIntent?.attributes?.status ===
            "valid_payment" && <Badge tone="success">Valid</Badge>}
          {stripeSetupIntent?.attributes?.status ===
            "failed_payment" && (
            <Badge tone="critical">Failed</Badge>
          )}
          {stripeSetupIntent?.attributes?.status === "pending" && (
            <Badge>Pending</Badge>
          )}
        </InlineStack>

        {opportunity?.attributes?.stripe_customer_link && (
          <Popover
            active={active}
            activator={activator}
            autofocusTarget="first-node"
            onClose={toggleActive}
          >
            <ActionList
              actionRole="menuitem"
              items={[
                {
                  content: "View in Stripe",
                  suffix: <Icon source={ExternalIcon} />,
                  external: true,
                  target: "_blank",
                  url: opportunity?.attributes?.stripe_customer_link,
                },
                {
                  content: 'Validate',
                  onAction: validatePurchaseMethod,
                  disabled: (
                    stripeSetupIntent?.attributes?.valid &&
                    stripeSetupIntent?.attributes?.status === "valid_payment"
                  )
                },
              ]}
            />
          </Popover>
        )}
      </InlineStack>
    </Box>
  );

};


PaymentMethodCard.propTypes = {
  opportunity: PropTypes.object,
  paymentMethod: PropTypes.object,
  index: PropTypes.number,
  validatePurchaseMethod: PropTypes.func,
  stripeSetupIntent: PropTypes.object,
  allPaymentMethodsLength: PropTypes.number,
};

export default PaymentMethodCard;
