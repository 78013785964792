import React, { useState, useCallback, useContext } from "react";
import {
  IndexFilters,
  useSetIndexFiltersMode,
  Text,
  InlineStack,
  Card,
  Layout,
  Page,
  Pagination,
  InlineGrid,
  Banner,
} from "@shopify/polaris";
import { formatCurrency } from "../../utilities";
import {
  useLazyGetTransactionsQuery,
  useCreateTransactionCsvMutation,
} from "../../services/api";

import { CurrentContactContext } from "../../contexts/Contact";
import TransactionTable from "../../components/Transaction/TransactionTable";

const TransactionIndex = () => {
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const limit = 25;
  const [page, setPage] = useState(1);
  const [statusName, setStatusName] = useState("All");
  const [itemStrings, setItemStrings] = useState([
    "All",
    "Pending",
    "Scheduled",
    "Failed",
    "Completed",
  ]);
  const [queryPlaceholder, setQueryPlaceholder] = useState(
    `Searching in ${statusName.toLowerCase()} filter`
  );
  const [isLoading, setIsLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [volume, setVolume] = useState(0);
  const [ids, setIds] = useState("");
  const [queryValue, setQueryValue] = useState("");

  const { currentContactId } = useContext(CurrentContactContext);

  const [
    getLazyTransactionsQuery,
    { isLoading: isLoadingTransactionsForExport },
    lastPromiseInfo,
  ] = useLazyGetTransactionsQuery();

  const [createTransactionCsv, { isLoading: isCreatingTransactionCsv }] =
    useCreateTransactionCsvMutation();

  const [isExportingCsv, setIsExportingCsv] = useState(false);
  const [showExportError, setShowExportError] = useState(false);
  const [showExportSuccess, setShowExportSuccess] = useState(false);

  const deleteView = (index) => {
    const newItemStrings = [...itemStrings];
    newItemStrings.splice(index, 1);
    setItemStrings(newItemStrings);
    setSelected(0);
  };

  const duplicateView = async (name) => {
    setItemStrings([...itemStrings, name]);
    setSelected(itemStrings.length);
    await sleep(1);
    return true;
  };

  const tabs = itemStrings.map((item, index) => ({
    content: item,
    index,
    onAction: () => {},
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions: [],
  }));
  const [selected, setSelected] = useState(0);
  const onCreateNewView = async (value) => {
    await sleep(500);
    setItemStrings([...itemStrings, value]);
    setSelected(itemStrings.length);
    return true;
  };

  const { mode, setMode } = useSetIndexFiltersMode();
  const onHandleCancel = () => {};

  const onHandleSave = async () => {
    await sleep(1);
    return true;
  };

  const handleOnSelect = (viewIndex) => {
    setSelected(viewIndex);
    setStatusName(itemStrings[viewIndex]);
    setQueryPlaceholder(
      `Searching in ${itemStrings[viewIndex].toLowerCase()} filter`
    );
    setQueryValue("");
    setPage(1);
  };

  const handleExportToCsv = async () => {
    setShowExportError(false);
    setShowExportSuccess(false);
    setIsExportingCsv(true);

    createTransactionCsv({
      status: statusName,
      search: queryValue,
    })
      .unwrap()
      .then(() => {
        setIsExportingCsv(false);
        setShowExportSuccess(true);
      })
      .catch((error) => {
        setShowExportError(true);
        setShowExportSuccess(false);
        console.log(error);
      });
  };

  const primaryAction =
    selected === 0
      ? {
          type: "save-as",
          onAction: onCreateNewView,
          disabled: false,
          loading: false,
        }
      : {
          type: "save",
          onAction: onHandleSave,
          disabled: false,
          loading: false,
        };

  const handleFiltersQueryChange = useCallback((value) => {
    setQueryValue(value);
    setPage(1);
  }, []);

  const handleChangePage = (mode) => {
    setIsLoading(true);
    setPage((state) => {
      if (mode == "next" && state == Math.ceil(count / limit)) {
        return Math.ceil(count / limit);
      } else if (mode == "previous" && state == 1) {
        return 1;
      }

      if (mode == "next") {
        return state + 1;
      } else if (mode == "previous") {
        return state - 1;
      }
    });
  };

  const tablePagination = (
    <Pagination
      hasPrevious={page != 1}
      hasNext={page < Math.ceil(count / limit)}
      onPrevious={() => {
        handleChangePage("previous");
      }}
      onNext={() => {
        handleChangePage("next");
      }}
      label={
        <Text>
          Page {page} of {Math.ceil(count / limit)}
        </Text>
      }
    />
  );

  const sortOptions = [
    {
      label: "Created date",
      value: "created_date asc",
      directionLabel: "Ascending",
    },
    {
      label: "Created date",
      value: "created_date desc",
      directionLabel: "Descending",
    },
    {
      label: "Opportunity name",
      value: "opportunity_name asc",
      directionLabel: "A-Z",
    },
    {
      label: "Opportunity name",
      value: "opportunity_name desc",
      directionLabel: "Z-A",
    },
    {
      label: "Transaction type",
      value: "transaction_type asc",
      directionLabel: "A-Z",
    },
    {
      label: "Transaction type",
      value: "transaction_type desc",
      directionLabel: "Z-A",
    },
    { label: "Amount", value: "amount asc", directionLabel: "Ascending" },
    { label: "Amount", value: "amount desc", directionLabel: "Descending" },
    {
      label: "Scheduled date",
      value: "scheduled_date asc",
      directionLabel: "Ascending",
    },
    {
      label: "Scheduled date",
      value: "scheduled_date desc",
      directionLabel: "Descending",
    },
    {
      label: "Completed date",
      value: "completed_date asc",
      directionLabel: "Ascending",
    },
    {
      label: "Completed date",
      value: "completed_date desc",
      directionLabel: "Descending",
    },
  ];
  const [sortSelected, setSortSelected] = useState(["created_date asc"]);

  return (
    <Page
      title="Transactions"
      fullWidth
      secondaryActions={[
        {
          content: "Export",
          onAction: handleExportToCsv,
          loading: isLoadingTransactionsForExport || isExportingCsv,
        },
      ]}
    >
      <Layout>
        <Layout.Section>
          {showExportError && (
            <>
              <Banner title="Error" tone="critical">
                <p>An error occured with the export</p>
              </Banner>

              <br />
            </>
          )}

          <InlineGrid gap="400" columns={2}>
            <Card>
              <Text as="p" fontWeight="bold" tone="subdued">
                Count of transactions
              </Text>

              <br />

              <Text variant="heading3xl" as="h2">
                {count}
              </Text>
            </Card>
            <Card>
              <Text as="p" fontWeight="bold" tone="subdued">
                Transaction volume
              </Text>

              <br />

              <Text variant="heading3xl" as="h2">
                {formatCurrency(volume)}
              </Text>
            </Card>
          </InlineGrid>
        </Layout.Section>
        <Layout.Section>
          <Card padding="0">
            <IndexFilters
              sortOptions={sortOptions}
              sortSelected={sortSelected}
              onSort={setSortSelected}
              queryValue={queryValue}
              queryPlaceholder={queryPlaceholder}
              onQueryChange={handleFiltersQueryChange}
              onQueryClear={() => {}}
              primaryAction={primaryAction}
              cancelAction={{
                onAction: onHandleCancel,
                disabled: false,
                loading: false,
              }}
              tabs={tabs}
              selected={selected}
              onSelect={handleOnSelect}
              canCreateNewView={false}
              onCreateNewView={onCreateNewView}
              filters={[]}
              appliedFilters={[]}
              onClearAll={() => {}}
              mode={mode}
              setMode={setMode}
              hideFilters
              filteringAccessibilityTooltip="Search (F)"
            />
            {/*
              Indextable placed on a separate component
              for IndexFilters to work
            */}
            <TransactionTable
              page={page}
              limit={limit}
              statusName={statusName}
              queryValue={queryValue}
              setCount={setCount}
              setVolume={setVolume}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setIds={setIds}
              sort={sortSelected}
              setSortSelected={setSortSelected}
            />
            <InlineStack align="center">
              <div style={{ padding: "1.3rem" }}>
                {count > 0 ? tablePagination : <></>}
              </div>
            </InlineStack>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default TransactionIndex;
