import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  IndexTable,
  Filters,
  Text,
  Pagination,
  InlineStack,
  Link,
} from "@shopify/polaris";

import { useDebounce } from "use-debounce";
import {
  useGetAdminVendorProgramsQuery
} from "../../services/api";

const VendorProgramIndexTable = (props) => {

  const history = useHistory();

  const resourceName = {
    singular: 'vendor program',
    plural: 'vendor programs',
  };
  const truncateWidth = 16;

  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [queryValue, setQueryValue] = useState(undefined);
  const [searchValue] = useDebounce(queryValue, 500);
  const [sortValue, setSortValue] = useState("UPDATED_AT_DESC");
  // const [sortDirection, setSortDirection] = useState("descending");
  const [selected, setSelected] = useState(0);
  const [isLoading, setIsLoading] = useState(false);


  const handleQueryValueRemove = useCallback(() => setQueryValue(undefined), []);
  const handleFiltersClearAll = useCallback(() => {
      handleQueryValueRemove();
    }, [handleQueryValueRemove]
  );

  const {
    data: vendorProgramData = { count: 0 },
    isLoading: queryIsLoading,
    isFetching: queryIsFetching,
  } = useGetAdminVendorProgramsQuery({
        vendorId: null,
        page: page,
        limit: limit,
        search: searchValue,
      });

  const [vendorPrograms, setVendorPrograms] = useState([]);

  useEffect(()=> {
    if(vendorProgramData.count){
      let lowerLimit = (page - 1) * limit;
      let upperLimit = page * limit;
      let list = [];

      let ocount = 0;
      for(let lcount = lowerLimit; lcount < upperLimit; lcount++){
        if(vendorProgramData.vendor_programs.data[ocount]){
          list[lcount] = vendorProgramData.vendor_programs.data[ocount];
        }
        ocount += 1;
      }

      setVendorPrograms(list);
      setIsLoading(false);
    } else {
      setVendorPrograms([]);
      setIsLoading(false);
    }
  }, [vendorProgramData]);

  useEffect(() => {
    setPage(1);
  }, [searchValue]);

  const handleChangePage = (mode) => {
    setIsLoading(true);
    setPage((state) => {
      if (mode == "next" && state == Math.ceil(vendorProgramData.count / limit)) {
        return Math.ceil(vendorProgramData.count / limit);
      }else if(mode == "previous" && state == 1){
        return 1;
      }

      if(mode == "next"){
        return state + 1;
      }else if(mode == "previous"){
        return state - 1;
      }
    });
  };

  const handleVendorProgramClick = (vendor_program) => {
    history.push({
      pathname: `/admin/vendor_programs/${vendor_program.attributes.id}`,
      state: { account: { ...vendor_program } },
    });
  };

  const rowMarkup = (vendorPrograms.length > 0 ? vendorPrograms.map((item, index) => (
      <IndexTable.Row
        id={index}
        key={index}
        position={index}
      >
        <IndexTable.Cell>
          <Text variant="strong" fontWeight="bold" truncate>
            <Link
              removeUnderline
              monochrome
              onClick={()=>handleVendorProgramClick(item)}
            >
              {item.attributes.name}
            </Link>
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <div style={{width: `${truncateWidth}rem`}}>
            <Text truncate as="p">
              { item.attributes.financing_option_templates.length > 0 ?
                item.attributes.financing_option_templates.join(", ") : "--"}
            </Text>
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <div style={{width: `${truncateWidth}rem`}}>
            <Text truncate as="p">
              { item.attributes.envelope_templates.length > 0 ?
                item.attributes.envelope_templates.join(", ") : "--"}
            </Text>
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <div style={{width: `${truncateWidth}rem`}}>
            <Text truncate as="p">
              { item.attributes.document_list?.length > 0 ?
                item.attributes.document_list.join(", ") : "--"}
            </Text>
          </div>
        </IndexTable.Cell>
      </IndexTable.Row>
    ),
  ) : []);

  const tablePagination = (
    <Pagination
      hasPrevious={page != 1}
      hasNext={page < Math.ceil(vendorProgramData.count / limit)}
      onPrevious={()=>{ handleChangePage("previous") } }
      onNext={()=>{ handleChangePage("next") } }
      label={(
        <Text>
          Page {page} of {Math.ceil(vendorProgramData.count / limit)}
        </Text>
      )}
    />
  );

  return (
    <div>
      <div>
        <Filters
          queryValue={queryValue}
          filters={[]}
          appliedFilters={[]}
          onQueryChange={setQueryValue}
          onQueryClear={handleQueryValueRemove}
          onClearAll={handleFiltersClearAll}
        />
      </div>
      <IndexTable
        resourceName={resourceName}
        itemCount={vendorProgramData.count}
        selectable={false}
        loading={isLoading || queryIsLoading || queryIsFetching}
        headings={[
          {title: 'Vendor program'},
          {title: 'Finance options templates'},
          {title: 'Envelope templates'},
          {title: 'Attachment templates'},
        ]}
        sortable={[true, false, false, false]}
        defaultSortDirection={"descending"}
        // sortDirection={sortDirection}
        // onSort={handleTableSorting}
      >
        {rowMarkup}
      </IndexTable>
      <InlineStack align="center">
        <div style={{padding: "1.0rem"}}>
          {vendorProgramData.count > 0 ?
           tablePagination : <></>
          }
        </div>
      </InlineStack>
    </div>
  );
};

VendorProgramIndexTable.propTypes = {
  //itemLimit: PropTypes.number,
};

export default VendorProgramIndexTable;
