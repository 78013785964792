import React, { useState, useCallback } from "react";
import {
  Card,
  FormLayout,
  TextField,
  Form,
  Button,
  Banner,
  Toast,
  ButtonGroup,
  Select,
  Icon,
  Tooltip,
} from "@shopify/polaris";
import { useField, useSubmit, notEmpty } from "@shopify/react-form";
import PropTypes from "prop-types";

import { useUpdateClientAccountMutation } from "../../../../../services/api";

import { stateOptions } from "../../../../../utilities";
import { InfoIcon } from "@shopify/polaris-icons";

const ClientForm = (props) => {
  const { clientAccount, setShowClientCard } = props;

  const [updateClient, { isLoading: isUpdating }] =
    useUpdateClientAccountMutation();

  const fields = {
    name: useField({
      value: (clientAccount?.attributes?.reviewed ? clientAccount?.attributes?.name : ""),
      validates: [notEmpty("Name is required")],
    }),
    billing_street: useField({
      value: clientAccount?.attributes?.billing_street || "",
      validates: [],
    }),
    billing_city: useField({
      value: clientAccount?.attributes?.billing_city || "",
      validates: [],
    }),
    billing_state: useField({
      value: clientAccount?.attributes?.billing_state || "",
      validates: [],
    }),
    billing_postal_code: useField({
      value: clientAccount?.attributes?.billing_postal_code || "",
      validates: [],
    }),
  };

  const { submit, submitting, errors } = useSubmit(async (form) => {
    return updateClient({
      id: clientAccount?.attributes?.id,
      ...form,
    })
      .unwrap()
      .then(() => {
        setToastMessage("Business details updated");
        setShowToast(true);

        setTimeout(() => {
          setShowClientCard(true);
        }, 300);

        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
        return { status: "fail", errors: error?.data?.errors || [] };
      });
  }, fields);

  const errorList = Array.isArray(errors) ? errors : [errors];
  const errorBanner = errorList.length > 0 && (
    <Banner status="critical" title="Something went wrong">
      {errorList.map((error, index) => {
        const errorMessage =
          typeof error === "string" ? error : "An unknown error occurred.";
        return <p key={index}>{errorMessage} </p>;
      })}
    </Banner>
  );

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const toggleShowToast = useCallback(
    () => setShowToast((active) => !active),
    []
  );
  const handleDismissToast = () => {
    toggleShowToast();
    setToastMessage("");
  };
  const toastMarkup = showToast ? (
    <Toast content={toastMessage} onDismiss={handleDismissToast} />
  ) : undefined;

  return (
    <Card sectioned>
      <Form onSubmit={submit}>
        <FormLayout>
          {errorBanner}
          <FormLayout.Group>
            <TextField
              label="Legal name"
              requiredIndicator
              tone="magic"
              helpText="Exactly as it appears on your corporate registration."
              connectedRight={
                <Tooltip content="If the name entered here does not match your registration, we'll need to follow up with a signed amenedment, and this will delay funding.">
                  <Icon source={InfoIcon} tone="magic" />
                </Tooltip>
              }
              {...fields.name}
            />
          </FormLayout.Group>

          <FormLayout.Group>
            <TextField label="Street address" {...fields.billing_street} />
          </FormLayout.Group>

          <FormLayout.Group>
            <TextField label="City" {...fields.billing_city} />
            <Select
              label="State"
              placeholder={"Select state"}
              options={stateOptions}
              {...fields.billing_state}
            />
            <TextField label="Zip code" {...fields.billing_postal_code} />
          </FormLayout.Group>

          <ButtonGroup>
            <Button onClick={() => setShowClientCard(true)}>Cancel</Button>
            <Button
              submit
              variant="primary"
              disabled={isUpdating || submitting}
              loading={isUpdating || submitting}
            >
              Confirm
            </Button>
          </ButtonGroup>
        </FormLayout>
      </Form>

      {toastMarkup}
    </Card>
  );
};

ClientForm.displayName = "ClientForm";

ClientForm.propTypes = {
  clientAccount: PropTypes.object,
  teamId: PropTypes.string,
  contactIsPresent: PropTypes.bool,
  setShowClientCard: PropTypes.func,
};

export default ClientForm;
