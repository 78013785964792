import React from "react";

import {
  InlineGrid,
  Card,
  SkeletonDisplayText,
  Text,
} from "@shopify/polaris";
import { isMobile } from "react-device-detect";

const HomeTableSkeletonCards = () => {
  return (
    <InlineGrid gap="400" columns={isMobile ? 1 : 3}>
      <Card>
        <Text as="p" fontWeight="bold" tone="subdued">
          Total opportunities
        </Text>

        <br />

        <SkeletonDisplayText size="large" />
      </Card>
      <Card>
        <Text as="p" fontWeight="bold" tone="subdued">
          Total volume
        </Text>

        <br />

        <SkeletonDisplayText size="large" />
      </Card>
      <Card>
        <Text as="p" fontWeight="bold" tone="subdued">
          Average Opportunity amount
        </Text>

        <br />

        <SkeletonDisplayText size="large" />
      </Card>
    </InlineGrid>
  );
};

export default HomeTableSkeletonCards;
