import React from "react";
import PropTypes from "prop-types";

import {
  Avatar,
  Card,
  Box,
  Text,
  LegacyStack,
  Button,
  Tooltip,
} from "@shopify/polaris";
import { XSmallIcon, EmailNewsletterIcon } from "@shopify/polaris-icons";

import {
  formatHumanFriendlyTimeDifference,
} from "../../utilities";

const CommentChunk = (props) => {
  const {
    index,
    item,
    currentContact,
    notification,
    handleDeleteCommentRequest,
    handleMarkUnreadComment
  } = props

  return (
    <li
      className="FortComments__ListItem"
      key={`comment-${index}`}
    >
      <div className="FortComments__Comment">
        <Card>
          <Box>
            <div className="FortComments__HeaderWrapper">
                <Avatar
                  initials={item.attributes.author_initials}
                />
              <div className="FortComments__Header">
                <LegacyStack spacing="tight" alignment="center">
                  <LegacyStack.Item>
                    <Text as="span" fontWeight="semibold" >
                      {item.attributes.author_name}
                    </Text>
                  </LegacyStack.Item>

                  <LegacyStack.Item fill>
                    <Text tone="subdued">
                      {formatHumanFriendlyTimeDifference(
                        new Date(item.attributes.created_at)
                      )}
                    </Text>
                  </LegacyStack.Item>

                  { (
                    currentContact.attributes.is_organization_admin ||
                    currentContact.id === item.attributes.author_id
                  ) &&
                  (
                    <LegacyStack.Item>
                      {notification && notification.read_at == null ?
                         (<></>) : (
                          <Tooltip content="Mark as unread">
                            <Button
                              icon={EmailNewsletterIcon}
                              color="base"
                              onClick={() => {
                                handleMarkUnreadComment(notification.id);
                              }}
                              variant="plain"
                            />
                          </Tooltip>
                      )}
                      <Button
                        icon={XSmallIcon}
                        color="base"
                        onClick={() => {
                          handleDeleteCommentRequest(item.id);
                        }}
                        variant="plain" />
                    </LegacyStack.Item>
                  )
                  }


                </LegacyStack>
              </div>
            </div>

            <div className="FortComments__ContentWrapper">
              <p className="FortComments__Content">
                {item.attributes.note}
              </p>
            </div>
          </Box>
        </Card>
      </div>
    </li>
  );
};

CommentChunk.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  notification: PropTypes.object,
  currentContact: PropTypes.object,
  handleDeleteCommentRequest: PropTypes.func,
  handleMarkUnreadComment: PropTypes.func,
};

export default CommentChunk;
