import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Modal, ChoiceList } from "@shopify/polaris";

import { useUpdateDocumentMutation } from "../../../services/api";
import { toFinanceTypeSlug } from "../../../utilities";

const EditDocumentAssociationModal = (props) => {
  const {
    documentId,
    document,
    openEditDocumentAssociationModal,
    toggleEditDocumentAssociationModal,
    refetchDocument
  } = props;

  const [selectedFinanceOptions, setSelectedFinanceOptions] = useState([]);

  const [updateDocument, { isLoading: isUpdatingDocument }] =
    useUpdateDocumentMutation();

  const saveDocument = () => {
    return updateDocument({
      id: documentId,
      financing_option_type: selectedFinanceOptions,
    })
      .unwrap()
      .then(() => {
        refetchDocument();
        toggleEditDocumentAssociationModal();

        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if(document.attributes.financing_option_type?.length > 0) {
      let financeTypeRows = []
      for(let i = 0; i < document.attributes.financing_option_type.length ; i++) {
        financeTypeRows.push(document.attributes.financing_option_type[i].name)
      }

      setSelectedFinanceOptions(financeTypeRows)
    }
  }, [document, openEditDocumentAssociationModal])

  const documentTypeChoices = Object.entries(toFinanceTypeSlug).map(([key, value]) => ({
    label: key,
    value: value
  }));

  return (
    <>
      <Modal
        open={openEditDocumentAssociationModal}
        title={"Document association"}
        primaryAction={{
          content: "Save",
          onAction: saveDocument,
          loading: isUpdatingDocument
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: toggleEditDocumentAssociationModal
          }
        ]}
        onClose={toggleEditDocumentAssociationModal}
      >
        <Modal.Section>
          <ChoiceList
            allowMultiple
            title="While the customer is checking out"
            choices={documentTypeChoices}
            selected={selectedFinanceOptions}
            onChange={setSelectedFinanceOptions}
          />
        </Modal.Section>
      </Modal>
    </>
  );
};

EditDocumentAssociationModal.propTypes = {
  documentId: PropTypes.string,
  document: PropTypes.object,
  openEditDocumentAssociationModal: PropTypes.bool,
  toggleEditDocumentAssociationModal: PropTypes.func,
  refetchDocument: PropTypes.func,
};

export default EditDocumentAssociationModal;
