import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useDebounce } from "use-debounce";

import {
  Card,
  Page,
  Layout,
  Labelled,
  Modal,
  Toast,
  Banner,
  BlockStack,
  Button,
  Autocomplete,
  Icon,
  Spinner,
  TextField,
  InlineStack,
  InlineGrid,
  Text
} from "@shopify/polaris";
import { SearchIcon } from "@shopify/polaris-icons";

import {
  useGetAdminAccountQuery,
  useCreateAdminAccountReviewMutation,
  useGetAdminVendorProgramsQuery,
  useGetAdminAccountTeamsQuery,
  useSearchAdminTeamsQuery,
  useUpdateAdminVendorTeamsMutation,
  useSearchContactsQuery,
  useUpdateAdminVendorAdminsMutation,
  useGetAdminVendorAdminsQuery,
  useCreateAdminTeamMutation,
  useUpdateAdminVendorVendorProgramMutation
} from "../../../services/api";

import AccountForm from "../../../components/Account/Form";
import { formatLongDateTime } from "../../../utilities";
import TeamList from "../../../components/Team/List";
import AdminList from "../../../components/Admin/Vendor/AdminList";
import FadeIn from "../../../components/FadeIn";
import AdminVendorOpportunityList from "../../../components/Admin/Vendor/OpportunityList";
import LoadingScreen from "../../../components/Auth/Loading";
import VendorProgramList from "../../../components/Admin/Vendor/VendorProgramList";
import VendorContactForm from "../../../components/VendorContact/Form";

import { CurrentContactContext } from "../../../contexts/Contact";

const AdminVendorShow = (props) => {
  const history = useHistory();
  const location = useLocation();

  const { currentContact } = useContext(CurrentContactContext);

  const { id: vendorId } = useParams();

  useEffect(() => {
    if (!vendorId) {
      navigateToAdminAccountsIndex();
    }
  }, [vendorId, navigateToAdminAccountsIndex]);

  const { account: accountObject } = location?.state?.account
    ? location.state
    : {
        account: { attributes: {} },
      };

  const { data: account = accountObject } = useGetAdminAccountQuery(vendorId);
  const [createAccountReview, { isLoading: isCreatingAccountReview }] =
    useCreateAdminAccountReviewMutation();

  const { data: vendorPrograms = [], isLoading: isLoadingVendorPrograms } =
    useGetAdminVendorProgramsQuery({ vendorId: vendorId });

  const {
    data: teams = [],
    refetch: refetchTeams,
    isLoading: isLoadingTeams,
  } = useGetAdminAccountTeamsQuery(vendorId, { skip: !vendorId });

  const [teamName, setTeamName] = useState("");

  const [teamSearchValue, setTeamSearchValue] = useState("");
  const [teamSearchQuery] = useDebounce(teamSearchValue, 500);
  const { data: searchTeams = [], isLoading: isLoadingSearchTeams } =
    useSearchAdminTeamsQuery({ search: teamSearchQuery });

  const [updateAdminVendorTeams, { isLoading: isUpdatingAdminVendorTeams }] =
    useUpdateAdminVendorTeamsMutation();

  const [contactSearchValue, setContactSearchValue] = useState("");
  const [contactSearchQuery] = useDebounce(contactSearchValue, 500);

  const { data: searchContacts = [], isLoading: isLoadingSearchContacts } =
    useSearchContactsQuery({ search: contactSearchQuery });

  const {
    data: admins = [],
    refetch: refetchAdmins,
    isLoading: isLoadingAdmins,
  } = useGetAdminVendorAdminsQuery(vendorId, { skip: !vendorId });

  const [updateAdminVendorAdmins, { isLoading: isUpdatingAdminVendorAdmins }] =
    useUpdateAdminVendorAdminsMutation();

  const [createAdminTeam, { isLoading: isCreatingTeam }] =
    useCreateAdminTeamMutation();

  const [updateAdminVendorVendorProgram, { isLoading: isUpdatingAdminVendorVendorProgram }] =
    useUpdateAdminVendorVendorProgramMutation();

  const [teamOptions, setTeamOptions] = useState([]);

  useEffect(() => {
    if (searchTeams.length > 0) {
      const teamList = searchTeams.map((team, index) => {
        return {
          value: team.id,
          label: team.attributes.name,
        };
      });

      setTeamOptions(teamList);
    }
  }, [searchTeams]);

  const [selectedTeamOptions, setSelectedTeamOptions] = useState([]);
  const [selectedTeamOptionIds, setSelectedTeamOptionIds] = useState([]);

  const handleSelectTeam = useCallback(
    (selectedTeamIds) => {
      if (selectedTeamIds.includes(selectedTeamIds)) {
        return;
      } else {
        setSelectedTeamOptionIds(selectedTeamIds);

        const newSelectedTeams = searchTeams.filter((team) => {
          return selectedTeamIds.includes(team.id);
        });

        setSelectedTeamOptions([
          ...new Set([...selectedTeamOptions, ...newSelectedTeams]),
        ]);
      }
    },
    [searchTeams, selectedTeamOptions]
  );

  const [selectedContactOptions, setSelectedContactOptions] = useState([]);
  const [selectedContactOptionIds, setSelectedContactOptionIds] = useState([]);
  const [contactOptions, setContactOptions] = useState([]);

  const handleSelectContact = useCallback(
    (selectedContactIds) => {
      if (selectedContactIds.includes(selectedContactIds)) {
        return;
      } else {
        setSelectedContactOptionIds(selectedContactIds);

        const newSelectedContacts = searchContacts.filter((contact) => {
          return selectedContactIds.includes(contact.id);
        });

        setSelectedContactOptions([
          ...new Set([...selectedContactOptions, ...newSelectedContacts]),
        ]);
      }
    },
    [searchContacts, selectedContactOptions]
  );

  useEffect(() => {
    if (searchContacts.length > 0) {
      const contactList = searchContacts.map((contact, index) => {
        return {
          value: contact.id,
          label: `${contact.attributes.full_name} - ${contact.attributes.email}`,
        };
      });

      setContactOptions(contactList);
    }
  }, [searchContacts]);

  const navigateToAdminAccountsIndex = useCallback(() => {
    history.push("/admin");
  }, [history]);

  const navigateToOpportunityShow = useCallback(
    (clientId, opportunityId) => {
      history.push(`/clients/${clientId}/opportunities/${opportunityId}`);
    },
    [history]
  );

  const handleCreateOpportunity = () => {
    console.log("TODO: handle admin create opportunity");
  };

  const handleOpportunityClick = (opportunity) => {
    navigateToOpportunityShow(opportunity.attributes.client_id, opportunity.id);
  };

  const opportunitiesResourceList = (
    <AdminVendorOpportunityList
      stageFilters={[]}
      onCreateOpportunity={handleCreateOpportunity}
      onOpportunityClick={handleOpportunityClick}
      tagContext={account?.attributes?.tag_context || null}
      vendorId={vendorId}
    />
  );

  const [showEditAccountDetailsModal, setShowAccountDetailsModal] =
    useState(false);
  const handleEditAccountDetails = () => {
    setShowAccountDetailsModal(true);
  };
  const handleCloseModal = () => {
    setShowAccountDetailsModal(false);
    setShowReviewAccountModal(false);
  };

  const [accountFormDirty, setAccountFormDirty] = useState(false);
  const handleAccountEdit = (isDirty) => {
    setAccountFormDirty(isDirty);
  };
  const handleAccountUpdate = (data) => {
    handleCloseModal();
    setShowToast(true);
    setToastMessage("Account updated.");
  };

  const accountFormRef = useRef();

  const editAccountModal = (
    <Modal
      open={showEditAccountDetailsModal}
      onClose={handleCloseModal}
      title="Edit account"
      primaryAction={{
        content: "Save",
        onAction: () => accountFormRef.current.handleSubmitForm(),
        disabled: !accountFormDirty,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleCloseModal,
        },
      ]}
    >
      <AccountForm
        ref={accountFormRef}
        account={account}
        onCreateOrUpdate={handleAccountUpdate}
        onEdit={handleAccountEdit}
        isAdminContext={true}
      />
    </Modal>
  );

  const [showReviewAccountModal, setShowReviewAccountModal] = useState(false);
  const handleReviewAccount = () => {
    return createAccountReview({
      accountId: vendorId,
    })
      .unwrap()
      .then(({ data }) => {
        handleCloseModal();
        setShowToast(true);
        setToastMessage("Account reviewed.");
        return { status: "success" };
      })
      .catch((error) => {
        alert("Failed to add review.");
        console.log(error);
      });
  };

  const reviewAccountModal = (
    <Modal
      open={showReviewAccountModal}
      onClose={handleCloseModal}
      title="Review account"
      primaryAction={{
        content: "Add review",
        onAction: handleReviewAccount,
        loading: isCreatingAccountReview,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleCloseModal,
        },
      ]}
      sectioned
    >
      Please check the legal business name and all other account details before
      adding your review.
    </Modal>
  );

  const [showToast, setShowToast] = useState(location?.state?.showToast);
  const [toastMessage, setToastMessage] = useState(
    location?.state?.toastMessage
  );
  const toggleShowToast = useCallback(
    () => setShowToast((active) => !active),
    []
  );
  const handleDismissToast = () => {
    const { state: currentState } = location;
    if (currentState?.toastMessage) {
      delete currentState.toastMessage;
    }
    if (currentState?.showToast) {
      delete currentState.showToast;
    }
    window.history.replaceState({ ...currentState }, "");
    toggleShowToast();
    setToastMessage();
  };
  const toastMarkup = showToast ? (
    <Toast content={toastMessage} onDismiss={handleDismissToast} />
  ) : undefined;

  const reviewedByMessage = account.attributes.reviewed
    ? `Last reviewed by ${
        account.attributes.reviewed_by_name
      } on ${formatLongDateTime(new Date(account.attributes.reviewed_at))}`
    : "Needs review";

  const labelStyles = {
    minHeight: "36px",
    paddingTop: "4px",
  };

  const handleCreateTeam = () => {
    createAdminTeam({ vendor_id: vendorId, team_name: teamName })
      .then(() => {
        setShowToast(true);
        setToastMessage("Team created.");
        setShowAddTeamModal(false);

        return { status: "success" };
      })
      .catch((error) => console.log(error));
    setShowNewTeamModal(false);
  };

  const handleAddNewTeam = () => {
    return updateAdminVendorTeams({ id: vendorId, ids: selectedTeamOptionIds })
      .unwrap()
      .then((response) => {
        refetchTeams();
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
        return {
          status: "fail",
          errors: [{ message: "Could not update account." }],
        };
      });
  };

  const [showAddTeamModal, setShowAddTeamModal] = useState(false);
  const [showNewTeamModal, setShowNewTeamModal] = useState(false);

  const teamTextField = (
    <>
      <Autocomplete.TextField
        label={""}
        onChange={setTeamSearchValue}
        prefix={<Icon source={SearchIcon} tone="base" />}
        placeholder="Search"
        value={teamSearchValue}
        connectedRight={
          <Button
            onClick={handleAddNewTeam}
            loading={false}
            disabled={
              !selectedTeamOptionIds.length ||
              isLoadingTeams ||
              isUpdatingAdminVendorTeams
            }
          >
            Add
          </Button>
        }
      />
    </>
  );

  const newTeamModal = (
    <Modal
      open={showNewTeamModal}
      onClose={() => setShowNewTeamModal(false)}
      title={`New team for ${account.attributes.name}`}
      primaryAction={{
        content: "Save",
        onAction: () => {
          handleCreateTeam();
        },
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => setShowNewTeamModal(false),
        },
      ]}
    >
      <Modal.Section>
        <div style={{ paddingTop: "0.5rem" }}>
          <TextField
            label={"Team name"}
            type={"text"}
            autoComplete={"off"}
            value={teamName}
            onChange={setTeamName}
          />
        </div>
      </Modal.Section>
    </Modal>
  );

  const addTeamModal = (
    <Modal
      open={showAddTeamModal}
      onClose={() => setShowAddTeamModal(false)}
      title={"Add Team"}
      large={true}
      primaryAction={{
        content: "Done",
        onAction: () => {
          setShowAddTeamModal(false);
        },
      }}
    >
      <Modal.Section>
        <Autocomplete
          allowMultiple
          id={"add-team-autocomplete"}
          options={teamOptions}
          selected={selectedTeamOptionIds}
          onSelect={handleSelectTeam}
          textField={teamTextField}
          loading={isLoadingSearchTeams}
          emptyState={"No teams found"}
        />
      </Modal.Section>
      <Modal.Section flush>
        {isLoadingTeams || isUpdatingAdminVendorTeams ? (
          <div style={{ height: "150px", width: "100%", textAlign: "center" }}>
            <span style={{ position: "relative", top: "40px" }}>
              <Spinner size="large" />
            </span>
          </div>
        ) : (
          <TeamList vendorId={vendorId} selectable={false} filter={false} />
        )}
      </Modal.Section>
    </Modal>
  );

  const handleAddNewAdmins = () => {
    const payload = { ...inviteParams };
    payload.ids = payload.contactIds;
    delete payload.contactIds;

    return updateAdminVendorAdmins({
      id: vendorId,
      ...payload,
    })
      .unwrap()
      .then((response) => {
        refetchAdmins();
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
        return {
          status: "fail",
          errors: [{ message: "Could not update account." }],
        };
      });
  };

  const [showAddAdminModal, setShowAddAdminModal] = useState(false);
  const [showNewContactForm, setShowNewContactForm] = useState(false);
  const [addNewContact, setAddNewContact] = useState(false);
  const [inviteParams, setInviteParams] = useState({
    contactIds: [],
    notifyContacts: false,
    notifMsg: "",
    isManager: false,
  });

  const [notifyContacts, setNotifyContacts] = useState(false);
  const vendorContactFormRef = useRef();

  const handleNotifyContacts = useCallback((notify) => {
    setNotifyContacts(notify);
  }, []);

  const handleAfterAddAdmin = () => {
    setNotifyContacts(false);
    setToastMessage("Admins added");
    toggleShowToast();
  };

  const addAdminModal = (
    <Modal
      open={showAddAdminModal}
      onClose={() => setShowAddAdminModal(false)}
      title={"Add admin"}
      large={true}
      primaryAction={{
        content: "Add",
        loading: addNewContact,
        onAction: () => {
          if (showNewContactForm) {
            setAddNewContact(true);
          } else {
            handleAddNewAdmins();
            setShowAddAdminModal(false);
          }
        },
      }}
    >
      <Modal.Section>
        <VendorContactForm
          onNotifyContacts={(notify) => {
            handleNotifyContacts(notify);
          }}
          onAfterShareWithContacts={handleAfterAddAdmin}
          vendorId={vendorId}
          ref={vendorContactFormRef}
          setInviteParams={setInviteParams}
          isShowNewContactForm={setShowNewContactForm}
          addNewContact={addNewContact}
          setAddNewContact={setAddNewContact}
        />
      </Modal.Section>
    </Modal>
  );

  const [showEditVendorProgramModal, setShowEditVendorProgramModal] = useState(false)
  const [selectedVendorProgramList, setSelectedVendorProgramList] = useState([])
  const [loadingSaveVendorProgram, setLoadingSaveVendorProgram] = useState(false)

  const handleSaveNewVendorProgram = () => {
    setLoadingSaveVendorProgram(true)

    let payload = {
      vendor_program_id: selectedVendorProgramList[0]
    }

    return updateAdminVendorVendorProgram({
      id: vendorId,
      ...payload,
    })
      .unwrap()
      .then((response) => {
        setShowEditVendorProgramModal(false)
        setLoadingSaveVendorProgram(false)

        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
        return {
          status: "fail",
          errors: [{ message: "Could not update vendor." }],
        };
      });
  }

  const editVendorProgramModal = (
    <Modal
      open={showEditVendorProgramModal}
      onClose={() => setShowEditVendorProgramModal(false)}
      title={"Edit vendor program"}
      large={true}
      primaryAction={{
        content: "Save",
        loading: loadingSaveVendorProgram,
        onAction: handleSaveNewVendorProgram,
      }}
    >
    <VendorProgramList
        vendorId={null}
        selectable={true}
        filter={true}
        canSelectMultiple={false}
        externalSelectedHandler={setSelectedVendorProgramList}
      />
    </Modal>
  );

  const pageMarkup = (
    <FadeIn fadeIn>
      <Page
        title={account.attributes.name}
        subtitle={`${account.attributes.type} Account`}
        secondaryActions={[
          {
            content: "Review",
            onAction: () => setShowReviewAccountModal(true),
          },
        ]}
        fullWidth
      >
        <Layout>
          {!account.attributes.reviewed && (
            <Layout.Section>
              <Banner
                title="This account has not been reviewed by a Fortify admin."
                action={{
                  content: "Review",
                  onAction: () => setShowReviewAccountModal(true),
                }}
                tone="warning"
              ></Banner>
            </Layout.Section>
          )}

          {/* Account details */}
          <Layout.Section primary>
            <Card>
              <div style={{ paddingBottom: "16px"}}>
                <InlineStack gap="200" align="space-between">
                  <Text variant="headingMd" as="h6">
                    Account information
                  </Text>

                  <Button  onClick={handleEditAccountDetails} variant="plain">
                    Edit
                  </Button>
                </InlineStack>
              </div>

              <InlineGrid gap="400" columns={2}>
                <Labelled label="Legal name">
                  <div style={labelStyles}>
                    <BlockStack gap="100">
                      <p>{account.attributes.name}</p>
                    </BlockStack>
                  </div>
                </Labelled>

                <Labelled label="DBA">
                  <div style={labelStyles}>
                    <BlockStack gap="100">
                      <p>{account.attributes.dba || "-"}</p>
                    </BlockStack>
                  </div>
                </Labelled>
              </InlineGrid>

              <Labelled label="Street address">
                <div style={labelStyles}>
                  <BlockStack gap="100">
                    <p>{account.attributes.billing_street || "-"}</p>
                  </BlockStack>
                </div>
              </Labelled>

              <InlineGrid gap="400" columns={3}>
                <Labelled label="City">
                  <div style={labelStyles}>
                    <BlockStack gap="100">
                      <p>{account.attributes.billing_city || "-"}</p>
                    </BlockStack>
                  </div>
                </Labelled>

                <Labelled label="State">
                  <div style={labelStyles}>
                    <BlockStack gap="100">
                      <p>{account.attributes.billing_state || "-"}</p>
                    </BlockStack>
                  </div>
                </Labelled>

                <Labelled label="Zip code">
                  <div style={labelStyles}>
                    <BlockStack gap="100">
                      <p>{account.attributes.billing_postal_code || "-"}</p>
                    </BlockStack>
                  </div>
                </Labelled>
              </InlineGrid>

              {account.attributes.reviewed && (
                <div subdued>
                  <BlockStack>
                    <p>
                      <Text as="span" tone="subdued">
                        {reviewedByMessage}
                      </Text>
                    </p>
                  </BlockStack>
                </div>
              )}
            </Card>

            <br/>

            {vendorId && account.attributes.type != "Lender" && (
              <Card padding="0">
                <div style={{ padding: "16px"}}>
                  <Text variant="headingMd" as="h6">
                    Opportunities
                  </Text>
                </div>
                {opportunitiesResourceList}
              </Card>
            )}
          </Layout.Section>

          {/* Contact list */}
          {account.attributes.type == "Vendor" && (
            <Layout.Section variant="oneThird">
              <Card padding="0">
                <div style={{ paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px"}}>
                  <InlineStack gap="200" align="space-between">
                    <Text variant="headingMd" as="h6">
                      Vendor Program
                    </Text>

                    <Button  onClick={() => { setShowEditVendorProgramModal(true) }} variant="plain">
                      Edit
                    </Button>
                  </InlineStack>
                </div>

                <VendorProgramList
                  vendorId={vendorId}
                  selectable={true}
                  filter={false}
                />
              </Card>

              <br/>

              <Card padding="0">
                <div style={{ paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px"}}>
                  <InlineStack gap="200" align="space-between">
                    <Text variant="headingMd" as="h6">
                      Admins
                    </Text>

                    <Button  onClick={() => { setShowAddAdminModal(true) }} variant="plain">
                      Add admin
                    </Button>
                  </InlineStack>
                </div>

                <AdminList
                  vendorId={vendorId}
                  selectable={true}
                  filter={false}
                />
              </Card>

              <br/>

              <Card padding="0">
                <div style={{ paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px"}}>
                  <InlineStack gap="200" align="space-between">
                    <Text variant="headingMd" as="h6">
                      Teams
                    </Text>

                    <Button  onClick={() => { setShowNewTeamModal(true) }} variant="plain">
                      Create team
                    </Button>
                  </InlineStack>
                </div>

                <TeamList
                  vendorId={vendorId}
                  selectable={true}
                  filter={false}
                />
              </Card>
            </Layout.Section>
          )}
        </Layout>

        {editAccountModal}
        {reviewAccountModal}
        {addTeamModal}
        {newTeamModal}
        {addAdminModal}
        {editVendorProgramModal}
        {toastMarkup}
      </Page>
    </FadeIn>
  );

  return currentContact ? pageMarkup : <LoadingScreen />;
};

export default AdminVendorShow;
