import React, { useCallback, useEffect, useState } from "react";

import {
  TextField,
  RangeSlider,
  Checkbox,
  FormLayout,
  BlockStack,
  Button,
  Text,
  InlineStack,
  Box,
} from "@shopify/polaris";
import { PlusIcon, InfoIcon } from "@shopify/polaris-icons";
import { asChoiceField } from "@shopify/react-form";

import { formatCurrency } from "../../utilities";

type DiscountFieldsProps = {
  fields: any;
  totalFinanceAmount: number;
};

const DiscountFields = (props: DiscountFieldsProps) => {
  const { fields, totalFinanceAmount } = props;

  const [hasDiscount, setHasDiscount] = useState(false);
  useEffect(() => {
    if (fields.discount.value > 0) {
      setHasDiscount(true);
    } else {
      setHasDiscount(false);
    }
  }, [fields.discount.value]);

  const title = "Customer discount";

  const helpLink = (
    <Button
      variant="plain"
      icon={InfoIcon}
      accessibilityLabel="Learn more"
      external
      url={"https://help.fortifypay.com/"}
    />
  );

  const settingTitle = title ? (
    <InlineStack gap="200" wrap={false} align="start" blockAlign="center">
      <Text variant="headingSm" as="h6">
        {title}
      </Text>

      {helpLink}
    </InlineStack>
  ) : null;

  const headerMarkup = <Box width="100%">{settingTitle}</Box>;

  const handleChangeDiscount = useCallback(
    (newValue: number) =>
      fields.discount.onChange(Math.round(newValue * 100) / 100 / 100),
    [fields.discount]
  );

  const handleChangeDiscountTextField = useCallback(
    (newValue: any) => {
      fields.discount.onChange(Math.round(newValue * 100) / 100 / 100);
    },
    [fields.discount]
  );

  const handleRemoveDiscount = useCallback(() => {
    setHasDiscount(false);
    fields.discount.onChange(0);
  }, [fields.discount]);

  return (
    <BlockStack gap="400">
      {headerMarkup}

      {hasDiscount ? (
        <FormLayout>
          <RangeSlider
            label="Discount"
            min={1}
            max={100}
            step={0.1}
            value={fields.discount.value * 100}
            onChange={handleChangeDiscount}
            error={fields.discount.error}
            prefix={
              <div className="FortCalculator__SellRateTextField">
                <TextField
                  label=""
                  value={String(
                    Math.round(fields.discount.value * 100 * 100) / 100
                  )}
                  onChange={handleChangeDiscountTextField}
                  step={0.1}
                  suffix="%"
                  autoComplete="off"
                  type="number"
                />
              </div>
            }
            suffix={
              <Box paddingInlineStart={"200"}>
                <Button
                  onClick={handleRemoveDiscount}
                  variant="plain"
                  tone="critical"
                >
                  Remove
                </Button>
              </Box>
            }
          />

          <FormLayout.Group condensed>
            <Checkbox
              label="Make discount visible"
              {...asChoiceField(fields.discount_visible)}
            />
          </FormLayout.Group>
        </FormLayout>
      ) : (
        <Box>
          <Button icon={PlusIcon} onClick={() => setHasDiscount(true)}>
            Add discount
          </Button>
        </Box>
      )}
    </BlockStack>
  );
};

export default DiscountFields;
