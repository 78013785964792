import React, { useEffect, useState } from "react";

import { Page, Layout, Card, Text, BlockStack } from "@shopify/polaris";

import Confetti from "./Confetti";

type Props = {
  foo: string;
  bar: string;
  baz: number;
};

const Sandbox = ({ foo, bar, baz }: Props) => {
  // This is a sandbox component. Use it to test out new components or
  // functionality.

  console.log(foo, bar, baz);

  // loadContent() handles the files that the
  // the plugin system doesn't support

  // for Card title
  // set Card padding="0"
  // <Box
  //   paddingBlockEnd="0"
  //   paddingInlineEnd="400"
  //   paddingBlockStart="400"
  //   paddingInlineStart="400"
  // >
  //   <Text variant="headingMd" as="h6">Vendor quotes</Text>
  // </Box>

  // for Card title with action
  // set Card padding="0"
  // <Box
  //   paddingBlockEnd="0"
  //   paddingInlineEnd="400"
  //   paddingBlockStart="400"
  //   paddingInlineStart="400"
  // >
  //   <InlineStack blockAlign="stretch">
  //     <Text variant="headingMd" as="h6">Vendor quotes</Text>
  //     <Button
  //       variant="plain"
  //       onClick={() => {
  //
  //       }}
  //     >
  //       card action
  //     </Button>
  //   </InlineStack>
  // </Box>


  return (
    <Page>
      <Layout>
        <Layout.Section>
          <Confetti active foo={"12"} />

          <BlockStack>
            <Card>
              <Text as="p">Text in a card</Text>

              <Text as="h1">More text in a card</Text>

              <Text as="p">Foo: {foo}</Text>
              <Text as="p">Bar: {bar}</Text>
              <Text as="p">Baz: {baz}</Text>
            </Card>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </Page>
  );
};

export default Sandbox;
