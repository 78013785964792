import React from "react";
import PropTypes from "prop-types";
import { Text, Link } from "@shopify/polaris";
import { formatCurrency } from "../../../../../utilities";

const Section179Ad = (props) => {
  const { opportunity } = props;

  return (
    <>
      <div data-test="sec-179-deduction">
        <Text variant="bodySm" as="p">
          *May be eligible for{" "}
          {formatCurrency(opportunity.attributes.total_finance_amount * 0.35)}{" "}
          Section 179 deduction.{" "}
          <Link removeUnderline={true} url={"/section-179-calculator"} external>
            Learn more
          </Link>
        </Text>
      </div>
    </>
  );
};
Section179Ad.propTypes = {
  opportunity: PropTypes.object,
};

export default Section179Ad;
