import React, { useState, useCallback, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
  Avatar,
  TextField,
  Button,
  Text,
  Modal,
  Checkbox,
  BlockStack,
  Toast,
} from "@shopify/polaris";

import {
  useField,
  useForm,
  notEmpty,
  lengthLessThan,
} from "@shopify/react-form";

import { useAuth0 } from "@auth0/auth0-react";

import {
  useGetCommentsQuery,
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useMarkUnreadCommentNotificationMutation,
  useGetOpportunityTransactionsQuery,
  useLazyGetCommentsQuery,
} from "../../../javascript/services/api";

import DateChunk from "./DateChunk";
import ActivityChunk from "./ActivityChunk";
import CommentChunk from "./CommentChunk";

import { CurrentContactContext } from "../../contexts/Contact";
import AsyncLocalStorage from "../../../javascript/AsyncLocalStorage";

const OpportunityCommentList = (props) => {
  const { commentableId, commentableType } = props;

  const { currentContact } = useContext(CurrentContactContext);

  const [showComments, setShowComments] = useState(true);
  const [showActivities, setShowActivities] = useState(true);

  const { data: transactions = [], isLoading: isLoadingTransactions = false } =
    useGetOpportunityTransactionsQuery(commentableId, { skip: !commentableId });

  const {
    data: comments = [],
    isLoading: isLoadingComments,
    refetch: refetchComments,
  } = useGetCommentsQuery(
    {
      commentableId: commentableId,
      comments: showComments,
      activity: showActivities,
    },
    { skip: !commentableId }
  );

  const [transactionComments, setTransctionComments] = useState([]);

  const [
    getLazyGetCommentsQuery,
    { isLoading: isLoadingTransactionComments },
    lastPromiseInfo,
  ] = useLazyGetCommentsQuery();

  // Get comments from transaction activity feed
  useEffect(() => {
    async function fetchTransactionComments() {
      if (transactions) {
        let allTransactionActivity = [];
        for (let i = 0; i < transactions.length; i++) {
          let transactionId = transactions[i].id;

          let rawTransactionActivities = await getLazyGetCommentsQuery({
            commentableId: transactionId,
            comments: false,
            activity: true,
          });

          if (rawTransactionActivities.isError) {
            console.log("ERROR");
            console.log(rawTransactionActivities);
            return;
          }

          let transactionActivityData = rawTransactionActivities.data;
          for (let j = 0; j < transactionActivityData.length; j++) {
            allTransactionActivity.push(transactionActivityData[j]);
          }
        }

        setTransctionComments(allTransactionActivity);
      }
    }

    fetchTransactionComments();
  }, [getLazyGetCommentsQuery, transactions]);

  const [deleteComment] =
    useDeleteCommentMutation();

  const [createComment] =
    useCreateCommentMutation();

  const changeShowComments = (value) => {
    setShowComments(value);
    refetchComments();
  };

  const [markUnreadCommentNotification] =
    useMarkUnreadCommentNotificationMutation();

  const [commentActivityList, setCommentActivityList] = useState([]);

  useEffect(() => {
    let combinedList = [...comments, ...transactionComments];

    combinedList.sort(function (a, b) {
      let aDate = a.attributes.created_at
        ? new Date(a.attributes.created_at)
        : new Date(a.attributes.timestamp);
      let bDate = b.attributes.created_at
        ? new Date(b.attributes.created_at)
        : new Date(b.attributes.timestamp);
      if (bDate > aDate) {
        return 1;
      } else {
        return -1;
      }
    });

    let combinedListWithDates = [];

    for (let i = 0; i < combinedList.length; i++) {
      if (i == 0) {
        let currentDate = combinedList[i].attributes.created_at
          ? new Date(combinedList[i].attributes.created_at)
          : new Date(combinedList[i].attributes.timestamp);

        const month = currentDate.toLocaleString("default", { month: "long" });
        const day = currentDate.getDate();
        const year = currentDate.getFullYear();

        let label = `${month} ${day}`;
        if (year != new Date().getFullYear()) {
          label = `${label}, ${year}`;
        }

        combinedListWithDates.push({ type: "date", label: label });
      } else {
        let currentDate = combinedList[i].attributes.created_at
          ? new Date(combinedList[i].attributes.created_at)
          : new Date(combinedList[i].attributes.timestamp);
        let lastDate = combinedList[i - 1].attributes.created_at
          ? new Date(combinedList[i - 1].attributes.created_at)
          : new Date(combinedList[i - 1].attributes.timestamp);
        if (
          currentDate.getMonth() != lastDate.getMonth() ||
          currentDate.getDate() != lastDate.getDate()
        ) {
          const month = currentDate.toLocaleString("default", {
            month: "long",
          });
          const day = currentDate.getDate();
          const year = currentDate.getFullYear();

          let label = `${month} ${day}`;
          if (year != new Date().getFullYear()) {
            label = `${label}, ${year}`;
          }
          combinedListWithDates.push({ type: "date", label: label });
        }
      }

      combinedListWithDates.push(combinedList[i]);
    }

    setCommentActivityList(combinedListWithDates);
  }, [comments, showComments, showActivities, transactionComments]);

  const {
    fields,
    submit,
    submitting,
    reset,
    submitErrors,
    makeClean,
    dirty: commentFormDirty,
  } = useForm({
    fields: {
      commentBoxText: useField({
        value: "",
        validates: [
          notEmpty("Comment text is required"),
          lengthLessThan(2000, "Comment must be less than 2000 characters"),
        ],
      }),
    },
    async onSubmit({ commentBoxText }) {
      // Return if no text
      if (commentBoxText === "") return;

      let body = {
        note: commentBoxText,
        commentable_type: commentableType,
        commentable_id: commentableId,
      };

      try {
        const key = `fortify-app-opportunity-${commentableId}-commentbox`;
        await AsyncLocalStorage.removeItem(key);
        localStorage.removeItem(key);
      } catch (error) {
        console.error("Error clearing saved comment:", error);
      }

      return createComment({
        body: body,
      })
        .then(() => {
          reset();
          setShowToast(true);
          setToastMessage("Comment added.");

          return { status: "success" };
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  const [commentIdToDelete, setCommentIdToDelete] = useState();

  const handleDeleteCommentRequest = (id) => {
    setCommentIdToDelete(id);
    handleShowDeleteCommentConfirmationModal();
  };

  const handleDeleteComment = () => {
    return deleteComment({
      id: commentIdToDelete,
      commentableId: commentableId,
    })
      .then(() => {
        handleCloseModal();
        setShowToast(true);
        setToastMessage("Comment deleted.");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMarkUnreadCommentNotification = (notification_id) => {
    return markUnreadCommentNotification({
      notification_id: notification_id
    })
      .then(() => {
        setShowToast(true);
        setToastMessage("Comment marked unread.");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    async function saveComment () {
      const key = `fortify-app-opportunity-${commentableId}-commentbox`;

      try {
        // Attempt to store the adjusted index in AsyncLocalStorage
        await AsyncLocalStorage.setItem(key, JSON.stringify({value: fields.commentBoxText.value, timestamp: Date.now()}));
      } catch (error) {
        // Log errors to the console
        console.error("Error storing comment", error);
      }
    }
    if (fields.commentBoxText.value.length > 0) {
      saveComment();
    }
  },[fields.commentBoxText.value, commentableId]);


  useEffect(() => {
    const commentThreshold = 1000 * 60 * 60 * 6; // 6 hours until it expires
    // Retrieve the views object from localStorage, or initialize an empty object if not set
    const key = `fortify-app-opportunity-${commentableId}-commentbox`;

    try {
      const savedComment = localStorage.getItem(key);
      const commentObj = savedComment ? JSON.parse(savedComment) : {};

      const now = Date.now();
      if (savedComment) {
        if (now - commentObj.timestamp > commentThreshold) {
          fields.commentBoxText.value = "";
          localStorage.removeItem(key);
        } else {
          fields.commentBoxText.value = commentObj.value;
        }
      }
    } catch (error) {
      console.error("Error retrieving comment:", error);
    }
  }, []);

  const [
    showDeleteCommentConfirmationModal,
    setShowDeleteCommentConfirmationModal,
  ] = useState(false);
  const handleShowDeleteCommentConfirmationModal = () => {
    setShowDeleteCommentConfirmationModal(true);
  };
  const handleCloseModal = () => {
    setShowDeleteCommentConfirmationModal(false);
  };
  const deleteCommentConfirmationModal = (
    <Modal
      open={showDeleteCommentConfirmationModal}
      onClose={handleCloseModal}
      title={"Delete comment"}
      primaryAction={{
        content: "Delete",
        onAction: handleDeleteComment,
        destructive: true,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleCloseModal,
        },
      ]}
    >
      <Modal.Section>
        <BlockStack>
          <Text variant="headingMd" as="h2">
            Are you sure?
          </Text>
          <p>
            Confirm that you would like to remove this comment from the Activity
            timeline.
          </p>
        </BlockStack>
      </Modal.Section>
    </Modal>
  );

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const toggleShowToast = useCallback(
    () => setShowToast((active) => !active),
    []
  );
  const handleDismissToast = () => {
    toggleShowToast();
    setToastMessage("");
  };
  const toastMarkup = showToast ? (
    <Toast content={toastMessage} onDismiss={handleDismissToast} />
  ) : undefined;

  const commentFieldClass = classNames({
    FortComments__CommentField: true,
    "FortComments__CommentField--empty-list": !comments.length,
  });

  return (
    <div className="FortComments__Wrapper">
      <div className="FortComments__SectionHeader">
        <Text variant="headingMd" as="h2">
          Activity
        </Text>

        <div>
          <Checkbox
            label="Show comments"
            checked={showComments}
            onChange={changeShowComments}
          />
        </div>
      </div>

      <div className="FortComments__Timeline">
        <div className={commentFieldClass}>
          <div
            style={{
              marginRight: "0.6rem",
              paddingLeft: "0.4rem",
              zIndex: "4",
            }}
          >
            <Avatar
              initials={
                currentContact?.attributes.initials
                  ? currentContact.attributes.initials
                  : ""
              }
              name={
                currentContact?.attributes.name
                  ? currentContact.attributes.name
                  : ""
              }
            />
          </div>

          <div className="FortComments__TextField">
            <TextField
              {...fields.commentBoxText}
              placeholder={"Leave a comment..."}
              multiline={1}
              autoComplete="off"
              helpText={"Only you, your team, and admins can see comments"}
              connectedRight={
                <Button
                  disabled={!commentFormDirty}
                  onClick={submit}
                  variant="primary"
                >
                  Post
                </Button>
              }
            />
          </div>
        </div>

        <div className="FortComments__Box">
          <ul className="FortComments__List">
            {commentActivityList.map((item, index) => {
              if (item.type === "date") {
                return <DateChunk key={`date-chunk${index}`} item={item} index={index} />;
              } else if (item.attributes.is_activity) {
                return <ActivityChunk key={`activity-chunk${index}`} item={item} index={index} />;
              } else {
                return (
                  <CommentChunk
                    key={`comment-chunk${index}`}
                    item={item}
                    index={index}
                    currentContact={currentContact}
                    notification={item.attributes.contact_notification}
                    handleDeleteCommentRequest={(id) => handleDeleteCommentRequest(id)}
                    handleMarkUnreadComment={(id) => handleMarkUnreadCommentNotification(id)}
                  />
                );
              }
            })}
          </ul>
        </div>
      </div>

      {deleteCommentConfirmationModal}
      {toastMarkup}
    </div>
  );
};

OpportunityCommentList.propTypes = {
  commentableId: PropTypes.string,
  commentableType: PropTypes.string,
};

export default OpportunityCommentList;
