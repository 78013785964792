import React, { useState, useCallback, useRef, useContext } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Button,
  Icon,
  Text,
  BlockStack,
  InlineStack,
} from "@shopify/polaris";
import { LinkIcon, LockIcon, GlobeIcon } from "@shopify/polaris-icons";
import OpportunityContactForm from "../../../../components/OpportunityContact/Form";
import OpportunityContactList from "../../../../components/OpportunityContact/List";
import { useUpdateOpportunityMutation } from "../../../../services/api";
import { CurrentContactContext } from "../../../../contexts/Contact";

const ShareModal = (props) => {
  const {
    contactId,
    opportunityId,
    opportunity,
    toggleShowToast,
    showContactShareModal,
    setCustomToastMessage,
    setShowContactShareModal,
  } = props;

  const [updateOpportunity, { isLoading: isUpdatingOpportunity }] =
    useUpdateOpportunityMutation();

  const [hideNewContactForm, setHideNewContactForm] = useState(false);

  const [isLoadingAccessToggle, setIsLoadingAccessToggle] = useState(false);

  const { currentContact, isCustomer } = useContext(CurrentContactContext);

  const handleHideNewContactForm = useCallback((hide) => {
    setHideNewContactForm(hide);
  }, []);

  const handleAfterShareWithContacts = () => {
    setHideNewContactForm(false);
    setCustomToastMessage("Access updated");
    toggleShowToast();
    setShowContactShareModal(false);
  };

  const handleAfterRemoveOpportunityContacts = (removedContactsLength) => {
    setCustomToastMessage(
      `${pluralize("Contact", removedContactsLength)} removed`
    );
    toggleShowToast();
  };

  const toggleAccess = () => {
    setIsLoadingAccessToggle(true);
    return updateOpportunity({
      id: opportunityId,
      public: !opportunity.attributes.public,
    })
      .unwrap()
      .then(() => {
        setCustomToastMessage("Access updated");
        toggleShowToast();

        setTimeout(() => {
          setIsLoadingAccessToggle(false);
        }, 800);
        return { status: "status" };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const opportunityContactFormRef = useRef();

  const copyOpportunityShareLink = () => {
    let hostname = window.location.hostname;
    hostname = hostname == "localhost" ? `${hostname}:3000` : hostname;
    const opportunityShareLink = `${hostname}/opportunities/${opportunityId}/share`;
    navigator.clipboard.writeText(opportunityShareLink);
    setCustomToastMessage("Link copied");
    toggleShowToast();
  };

  return (
    <Modal
      open={showContactShareModal}
      onClose={() => setShowContactShareModal(false)}
      title={"Share opportunity"}
      primaryAction={{
        content: "Done",
        onAction: () => {
          setShowContactShareModal(false);
        },
      }}
      secondaryActions={[
        {
          content: "Copy link",
          icon: <Icon source={LinkIcon} tone="base" />,
          onAction: () => copyOpportunityShareLink(),
        },
      ]}
    >
      <Modal.Section>
        <OpportunityContactForm
          onShowNewContactForm={(hide) => {
            handleHideNewContactForm(hide);
          }}
          onAfterShareWithContacts={handleAfterShareWithContacts}
          opportunityId={opportunityId}
          contactId={contactId}
          ref={opportunityContactFormRef}
        />
      </Modal.Section>
      {!hideNewContactForm && (
        <Modal.Section flush>
          <div style={{ padding: "20px 20px 0" }}>
            <Text variant="headingSm" as="h3">
              People with acccess
            </Text>
          </div>
          <OpportunityContactList
            opportunityId={opportunityId}
            onRemoveContacts={handleAfterRemoveOpportunityContacts}
            removeable={true}
          />
        </Modal.Section>
      )}
      <Modal.Section>
        <BlockStack gap="400">
          <Text variant="headingSm" as="h3">
            General access
          </Text>
          <InlineStack blockAlign="center" align="space-between" gap="400">
            <InlineStack blockAlign="center" gap="400">
              <div
                style={{
                  backgroundColor: "var(--p-color-bg-strong)",
                  height: "32px",
                  width: "32px",
                  borderRadius: "48px",
                  display: "flex",
                }}
              >
                <Icon source={opportunity.attributes.public ?
                  GlobeIcon : LockIcon} />
              </div>
              {opportunity.attributes.public ? (
                <BlockStack>
                  <Text variant="headingSm" as="h4">
                    Anyone with the link can view
                  </Text>
                  <p>Anyone on the internet with the link can view.</p>
                </BlockStack>
              ) : (
                <BlockStack>
                  <Text variant="headingSm" as="h4">
                    Restricted
                  </Text>
                  <p>Only people with access can open the link.</p>
                </BlockStack>
              )}
            </InlineStack>
            <Button
              loading={isLoadingAccessToggle}
              onClick={() => {
                toggleAccess();
              }}
            >
              { opportunity.attributes.public ? "Make private" :
                "Make public" }
            </Button>
          </InlineStack>
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
};

ShareModal.propTypes = {
  contactId: PropTypes.string,
  opportunityId: PropTypes.string,
  opportunity: PropTypes.object,
  toggleShowToast: PropTypes.func,
  setCustomToastMessage: PropTypes.func,
  showContactShareModal: PropTypes.bool,
  setShowContactShareModal: PropTypes.func,
};

export default ShareModal;
