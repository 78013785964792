import React, { useEffect, useState } from "react";

import { Select, FormLayout, Box } from "@shopify/polaris";

type TimingFieldProps = {
  fields: any;
  period: string;
  paymentType: string;
  purchaseOptionSegmentFields: Array<any>;
  handlePaymentTypeSelectChange: (selected: string, id: string) => void;
};

const TimingFields = (props: TimingFieldProps) => {
  const {
    fields,
    period = "monthly",
    paymentType = "advance",
    purchaseOptionSegmentFields,
    handlePaymentTypeSelectChange,
  } = props;

  const paymentTypeOptions = [
    { label: "Advance", value: "advance" },
    { label: "Arrears", value: "arrears" },
  ];
  const periodOptions = [{ label: "Monthly", value: "monthly" }];

  const [lockPaymentType, setLockPaymentType] = useState(false)
  const [optionsForPaymentType, setOptionsForPaymentType] = useState(paymentTypeOptions)
  useEffect(() => {
    if (purchaseOptionSegmentFields?.length > 0) {
      let destroyCount = 0
      for(let i = 0; i < purchaseOptionSegmentFields.length; i++) {
        if(purchaseOptionSegmentFields[i]._destroy.value) destroyCount += 1
      }

      if (destroyCount == purchaseOptionSegmentFields.length) {
        setOptionsForPaymentType(paymentTypeOptions)
        setLockPaymentType(false)
      } else { 
        setOptionsForPaymentType([{ label: "Advance", value: "advance" }])
        setLockPaymentType(true)
      }
    } else {
      setOptionsForPaymentType(paymentTypeOptions)
      setLockPaymentType(false)
    }
  }, [fields, purchaseOptionSegmentFields])

  return (
    <FormLayout>
      <FormLayout.Group>
        <Select
          label="Period"
          options={periodOptions}
          {...fields.period}
        />
        <Select
          label="Payment Type"
          options={optionsForPaymentType}
          {...fields.payment_type}
          disabled={lockPaymentType}
        />
      </FormLayout.Group>
    </FormLayout>
  );
};

export default TimingFields;
