import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import LoadingScreen from "./Auth/Loading";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      // await loginWithRedirect();
    };
    fn();
  }, [isLoading, isAuthenticated]);

  return (
    <>
      <Route
        {...rest}
        component={withAuthenticationRequired(Component, {
          onRedirecting: () => <LoadingScreen fade={false} />,
        })}
      />
    </>
  );
};
ProtectedRoute.propTypes = {
  component: PropTypes.any,
};

export default ProtectedRoute;
