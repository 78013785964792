import React, { useState, useCallback, useEffect, useContext } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";

import {
  useGetNewFinancingOptionQuery,
  useGetOpportunityQuery,
  useCreateFinancingOptionMutation,
} from "../../services/api";

import FinanceCalculator from "../../components/FinancingOption/FinanceCalculator/Index";
import LoadingSkeleton from "../../components/FinancingOption/FinanceCalculator/components/LoadingSkeleton";

import LoadingScreen from "../../components/Auth/Loading";

import { CurrentContactContext } from "../../contexts/Contact";
import { FinancingContext } from "../../contexts/FinancingOption/FinancingContext";
import PurchaseOptionCalculator from "../../components/PurchaseOption/Calculator";
import { toHumanReadableFinanceType } from "../../utilities";

const FinancingOptionsNew = () => {
  const location = useLocation();
  const history = useHistory();

  const { currentContact } = useContext(CurrentContactContext);

  const { opportunityId, purchaseOptionType } = useParams();

  const opportunityObject = {
    attributes: {},
  };

  const {
    data: opportunity = opportunityObject,
    isLoading: isLoadingOpportunity,
  } = useGetOpportunityQuery(opportunityId);

  const { data: newFinancingOption, isLoading: isLoadingFinancingOption } =
    useGetNewFinancingOptionQuery({
      opportunityId: opportunityId,
    });

  const [createFinancingOption, { isLoading: isCreatingFinancingOption }] =
    useCreateFinancingOptionMutation();

  const clientId = opportunity.attributes.client_id;

  const navigateToOpportunitiesShow = useCallback(
    (state = {}) => {
      history.push({
        pathname: `/clients/${clientId}/opportunities/${opportunityId}`,
        state: state,
      });
    },
    [clientId, history, opportunityId]
  );

  const handleSavePurchaseOption = useCallback(
    (form) => {
      const filteredSegments = form.financing_option_segments_attributes
        .filter((segment) => {
          return segment._destroy !== true;
        })
        .map((segment) => {
          return { count: segment.count, value: segment.value };
        });

      form.financing_option_segments_attributes = filteredSegments;

      return createFinancingOption({
        opportunityId: opportunityId,
        ...form,
      })
        .unwrap()
        .then(() => {
          navigateToOpportunitiesShow({
            showToast: true,
            toastMessage: "Financing option created.",
          });

          return { status: "success" };
        })
        .catch((error) => {
          console.log(error);

          return { status: "fail", errors: [error] };
        });
    },
    [createFinancingOption, opportunityId, navigateToOpportunitiesShow]
  );

  const pageMarkup = (
    <>
      {isLoadingOpportunity || isLoadingFinancingOption ? (
        <LoadingSkeleton purchaseOptionType={purchaseOptionType} />
      ) : (
        <PurchaseOptionCalculator
          onBackAction={() => navigateToOpportunitiesShow()}
          opportunity={opportunity}
          purchaseOption={newFinancingOption}
          purchaseOptionType={purchaseOptionType}
          onSaveAction={handleSavePurchaseOption}
        />
      )}
    </>
  );

  return currentContact ? pageMarkup : <LoadingScreen />;
};

FinancingOptionsNew.propTypes = {};

export default FinancingOptionsNew;
