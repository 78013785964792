import React from "react";
import PropTypes from "prop-types";
import { LegacyCard, IndexTable, Tag } from "@shopify/polaris";

const VendorAdminSettingsTagsTab = (props) => {
  const {
    vendor,
    tags,
    fetchingTags,
    isCreatingTag,
    isDeletingTags,
    selectedResources,
    setSelectedResources,
    allResourcesSelected,
    setAllResourcesSelected,
    setOpenConfirmationModal,
    handleCreateTagModalActiveChange,
  } = props;

  const resourceName = {
    singular: "vendor tag",
    plural: "vendor tags",
  };

  const promotedBulkActions = [
    {
      content: "Delete vendor tags",
      onAction: () => {
        setOpenConfirmationModal(true);
      },
    },
  ];

  const handleTagSelectionChange = (selectionType, toggleType, tagId) => {
    if (selectionType === "single") {
      if (selectedResources?.includes(tagId)) {
        setSelectedResources(selectedResources.filter((id) => id !== tagId));
      } else {
        setSelectedResources([...selectedResources, tagId]);
      }
    } else {
      setAllResourcesSelected(toggleType);
      if (toggleType) {
        setSelectedResources(tags.map((tag) => tag.id));
      } else {
        setSelectedResources([]);
      }
    }
  };

  const rowMarkup = tags.map((tag, index) => (
    <IndexTable.Row
      id={tag.id}
      key={tag.id}
      selected={selectedResources?.includes(tag.id)}
      position={index}
    >
      <IndexTable.Cell>
        <Tag>{tag.attributes.name}</Tag>
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <LegacyCard>
      <LegacyCard.Header
        title="Vendor tags"
        actions={[
          {
            content: "New vendor tag",
            onAction: handleCreateTagModalActiveChange,
          },
        ]}
      ></LegacyCard.Header>
      <br />
      <IndexTable
        resourceName={resourceName}
        itemCount={tags.length}
        selectedItemsCount={
          allResourcesSelected ? "All" : selectedResources.length
        }
        onSelectionChange={handleTagSelectionChange}
        promotedBulkActions={promotedBulkActions}
        loading={fetchingTags || isCreatingTag || isDeletingTags}
        headings={[{ title: "Tags" }]}
      >
        {rowMarkup}
      </IndexTable>
    </LegacyCard>
  );
};

VendorAdminSettingsTagsTab.propTypes = {
  vendor: PropTypes.any,
  tags: PropTypes.array,
  fetchingTags: PropTypes.bool,
  isCreatingTag: PropTypes.bool,
  isDeletingTags: PropTypes.bool,
  selectedResources: PropTypes.array,
  setSelectedResources: PropTypes.func,
  allResourcesSelected: PropTypes.bool,
  setAllResourcesSelected: PropTypes.func,
  setOpenConfirmationModal: PropTypes.func,
  handleCreateTagModalActiveChange: PropTypes.func,
};

export default VendorAdminSettingsTagsTab;
