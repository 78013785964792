import React, { useState, useCallback, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import {
  Button,
  LegacyStack,
  Text,
  ResourceItem,
  ResourceList,
  EmptyState,
  Icon,
  Thumbnail,
  Modal,
  RadioButton,
  Tooltip,
  Toast,
} from "@shopify/polaris";
import {
  CheckCircleIcon,
  NoteIcon,
  AlertTriangleIcon,
  AlertCircleIcon,
  ThumbsUpIcon,
} from "@shopify/polaris-icons";

import { useAuth0 } from "@auth0/auth0-react";
import { CurrentContactContext } from "../../contexts/Contact";

import {
  useUpdateOpportunityContactMutation,
  useGetFinancingOptionsQuery,
  useUpdateFinancingOptionMutation,
  useDeleteFinancingOptionMutation,
  useCreateAdminFinancingOptionApprovalMutation,
  useRemoveAdminFinancingOptionApprovalMutation,
  useCreateOpportunityEventMutation,
} from "../../services/api";
import { FinancingContext } from "../../contexts/FinancingOption/FinancingContext";
import PropTypes from "prop-types";
import OpportunityContactList from "../OpportunityContact/List";
import emptySearchCompanies from "../../assets/emptySearchCompanies.svg";

const FinancingOptionList = (props) => {
  const {
    opportunity,
    opportunityId,
    canCreateFinanceOption = false,
    contacts,
  } = props;

  const resourceName = {
    singular: "purchase option",
    plural: "purchase options",
  };

  const history = useHistory();

  const { user } = useAuth0();
  const { currentContactId, isOrganizationAdmin, isVendorAdmin } = useContext(
    CurrentContactContext
  );

  const [contactList, setContactList] = useState([]);
  const setEditableContactList = () => {
    let tempList = [];
    for (let i = 0; i < contacts.length; i++) {
      let tempContact = JSON.parse(JSON.stringify(contacts[i]));
      tempList.push(tempContact);
    }
    tempList.sort((a, b) => (a.attributes.email > b.attributes.email ? 1 : -1));
    setContactList(tempList);
  };
  useEffect(() => {
    setEditableContactList();
  }, [contacts]);

  const navigateToFinancingOptionsNew = (purchaseOptionType) => {
    history.push({
      pathname: `/opportunities/${opportunityId}/purchase_options/${purchaseOptionType}/new`,
      state: { opportunity: { ...opportunity } },
    });
  };

  const { data: financingOptions = [], isLoading: isLoadingFinancingOptions } =
    useGetFinancingOptionsQuery(opportunityId);

  const [updateFinancingOption, { isLoading: isUpdatingFinancingOption }] =
    useUpdateFinancingOptionMutation();
  const [deleteFinancingOption, { isLoading: isDeletingFinancingOption }] =
    useDeleteFinancingOptionMutation();
  const [createApproval, { isLoading: isCreatingApproval }] =
    useCreateAdminFinancingOptionApprovalMutation();

  const [removeApproval, { isLoading: isRemovingApproval }] =
    useRemoveAdminFinancingOptionApprovalMutation();

  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  const hasAcceptedFinancingOption = filteredItems.some(
    (item) => item?.attributes?.selected
  );

  useEffect(() => {
    if (financingOptions.length) {
      setFilteredItems(financingOptions);
    }
  }, [financingOptions]);

  const handleConfirmSignerOrMarkAsAccepted = () => {
    if (!contactList.some((contact) => contact.attributes.signer)) {
      setShowConfirmDesignatedSignerModal(true);
    } else {
      handleMarkAsAccepted();
    }
  };

  const [
    updateOpportunityContact,
    { isLoading: isUpdatingOpportunityContact },
  ] = useUpdateOpportunityContactMutation();

  const handleMarkAsAccepted = () => {
    const selectedFinancingOptionId = selectedItems[0];
    updateFinancingOption({
      id: selectedFinancingOptionId,
      opportunityId: opportunityId,
      selected: true,
    })
      .then((response) => {
        if (response?.data) {
          let returnedOption = response.data
          let selected = returnedOption.attributes?.selected
          if(selected) {
            handleCreateOpportunityEvent("FinancingOptionSelected");

            switch(returnedOption.attributes.financing_type) {
              case "Net terms":
                handleCreateOpportunityEvent("OpportunityNetTermsSelected")
                break
              case "Standard financing":
                handleCreateOpportunityEvent("OpportunityStandardFinancingSelected")
                break
              case "Fair market value":
                handleCreateOpportunityEvent("OpportunityFairMarketValueSelected")
                break
              case "Dollar out lease":
                handleCreateOpportunityEvent("OpportunityDollarOutLeaseSelected")
                break
              case "Rental":
                handleCreateOpportunityEvent("OpportunityRentalSelected")
                break
              case "Software as a service":
                handleCreateOpportunityEvent("OpportunitySoftwareAsAServiceSelected")
                break
              default:
                break
            }
          }
        }

        setSelectedItems([]);
        closeSignerModal();
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteFinancingOptions = () => {
    selectedItems.forEach((financingOptionId, index) => {
      return deleteFinancingOption({ id: financingOptionId, opportunityId })
        .unwrap()
        .then((response) => {
          setSelectedItems([]);
          setFilteredItems([]);
          return { status: "success" };
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const handleCreateOpportunityEvent = useCallback(
    (type) => {
      return createOpportunityEvent({
        opportunityId: opportunityId,
        contact_id: currentContactId,
        type: type,
      })
        .unwrap()
        .then()
        .catch((error) => {
          console.log(error);
        });
    },
    [user, createOpportunityEvent, opportunityId]
  );

  const handleCreateApprovals = () => {
    selectedItems.forEach((financingOptionId) => {
      return createApproval({ financingOptionId: financingOptionId })
        .unwrap()
        .then((response) => {
          setToastMessage("Purchase option approved.");
          setShowToast(true);
          setSelectedItems([]);
          handleCreateOpportunityEvent("FinancingOptionApproved");
          return { status: "success" };
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const handleRemoveApprovals = () => {
    selectedItems.forEach((financingOptionId) => {
      return removeApproval({ financingOptionId: financingOptionId })
        .unwrap()
        .then((response) => {
          setToastMessage("Purchase option unapproved.");
          setShowToast(true);
          setSelectedItems([]);
          handleCreateOpportunityEvent("FinancingOptionUnapproved");
          return { status: "success" };
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const selectedItemsMissingPaymentInfo = selectedItems.some((item) => {
    const selectedItem = filteredItems.find((itemObject) => {
      return itemObject.id == item;
    });

    if (selectedItem) {
      if (selectedItem.attributes.type == "net_terms") return false;

      return !selectedItem.attributes.base_payment;
    }

    return "-";
  });

  const promotedBulkActions = [
    {
      content: `Delete ${
        selectedItems.length == 1
          ? resourceName["singular"]
          : resourceName["plural"]
      }`,
      loading: isDeletingFinancingOption,
      onAction: handleDeleteFinancingOptions,
    },
    isOrganizationAdmin &&
      {
        content: "Approve",
        onAction: handleCreateApprovals,
      },
    isOrganizationAdmin &&
      {
        content: "Unapprove",
        onAction: handleRemoveApprovals,
      },
    {
      content: "Mark as accepted",
      onAction: handleConfirmSignerOrMarkAsAccepted,
      disabled: selectedItems.length != 1 || selectedItemsMissingPaymentInfo,
      indicator: selectedItems.length == 1 && !hasAcceptedFinancingOption,
    },
  ].filter((n) => n);

  const [createOpportunityEvent, { isLoading: isCreatingOpportunityEvent }] =
    useCreateOpportunityEventMutation();

  const [showCreateFinancingOptionModal, setShowCreateFinancingOptionModal] =
    useState(false);

  const handleCreateFinancingOption = () => {
    setShowCreateFinancingOptionModal(true);
  };
  const handleCloseModal = () => {
    setShowCreateFinancingOptionModal(false);
  };

  const renderItem = (item, itemId, index) => {
    const {
      id,
      attributes: {
        approved,
        name,
        selected,
        financing_type,
        term_length,
        title,
        interest_rate_visible,
        sell_rate,
        updated_at,
      },
    } = item;

    const thumbnail = (
      <Thumbnail source={NoteIcon} size="small" alt="Purchase option" />
    );

    const handleFinancingClick = (financingOption) => {
      const { id } = financingOption;

      if (!opportunity.attributes.financing_option_locked) {
        history.push({
          pathname: `/opportunities/${opportunityId}/purchase_options/${id}`,
          state: {
            opportunity: opportunity,
            financingOption: financingOption,
          },
        });
      }
    };

    const netTermsSubDetails = () => {
      const payments = item.attributes.installment_payments;
      const downPayment = item.attributes.down_payment;
      let subDetail = "";

      if (payments?.length > 0) {
        subDetail += ` •`;
        if (downPayment && downPayment > 0) subDetail += ` Down payment`;

        const installmentCount = payments.filter(payment => payment.days > 0 || payment.percentage > 0).length;

        if (installmentCount > 0) {
          if (downPayment && downPayment > 0) subDetail += ` followed by`;
          subDetail += ` ${installmentCount} installment${installmentCount > 1 ? 's' : ''}`;
        }
      }
      return subDetail;
    };

    const resourceItem = (
      <ResourceItem
        id={id}
        key={[id, selected, updated_at]}
        media={thumbnail}
        onClick={() => handleFinancingClick(item)}
        accessibilityLabel={`View details for ${title}`}
        verticalAlignment="center"
      >
        <LegacyStack
          spacing="extraLoose"
          alignment="center"
          distribution="leading"
        >
          <LegacyStack.Item fill>
            <LegacyStack alignment="center">
              <LegacyStack.Item>
                <h3 style={{ maxWidth: "320px" }}>
                  <Text as="span" fontWeight="semibold">
                    <span data-test="financing-option-name">
                      {title || `${financing_type} option`}
                    </span>
                  </Text>
                </h3>
                <Text variant="bodySm" as="p">
                  {item.attributes.interest_rate_visible
                    ? `${item.attributes.customer_interest_rate}% • ${financing_type}`
                    : financing_type}
                  {financing_type == "Net terms"
                    ? netTermsSubDetails()
                    : ` • ${term_length} months`}
                </Text>
              </LegacyStack.Item>

              {selected && (
                <LegacyStack alignment={"center"} spacing={"tight"}>
                  <Icon source={CheckCircleIcon} tone={"success"} />
                  <Text tone="success">
                    <span data-test="accepted-indicator">Accepted</span>
                  </Text>
                </LegacyStack>
              )}

              {!item.attributes.base_payment &&
                item.attributes.type != "net_terms" && (
                  <LegacyStack alignment={"center"} spacing={"tight"}>
                    <Icon source={AlertTriangleIcon} tone={"warning"} />
                    <Text tone="subdued">No payment set</Text>
                  </LegacyStack>
                )}
            </LegacyStack>
          </LegacyStack.Item>

          {!approved && (
            <LegacyStack.Item>
              <Tooltip content="This financing option needs approval.">
                <Icon source={AlertCircleIcon} tone={"base"} />
              </Tooltip>
            </LegacyStack.Item>
          )}

          {approved && (
            <LegacyStack.Item>
              <Tooltip content="This financing option is approved.">
                <Icon source={ThumbsUpIcon} tone={"primary"} />
              </Tooltip>
            </LegacyStack.Item>
          )}
        </LegacyStack>
      </ResourceItem>
    );
    if (opportunity.attributes.financing_option_locked) {
      return (
        <Tooltip
          preferredPosition="above"
          content="Contact your vendor admin to edit this option"
        >
          {resourceItem}
        </Tooltip>
      );
    } else {
      return resourceItem;
    }
  };

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const toggleShowToast = useCallback(
    () => setShowToast((active) => !active),
    []
  );
  const handleDismissToast = () => {
    toggleShowToast();
    setToastMessage("");
  };
  const toastMarkup = showToast ? (
    <Toast content={toastMessage} onDismiss={handleDismissToast} />
  ) : undefined;

  const emptyStateMarkup = !filteredItems.length ? (
    <EmptyState
      heading="You don't have any purchase options"
      action={{
        content: "Create purchase option",
        onAction: handleCreateFinancingOption,
        disabled: !canCreateFinanceOption,
      }}
      image={emptySearchCompanies}
    >
      {canCreateFinanceOption ? (
        <p>Create your first option to see them here.</p>
      ) : (
        <p>Create a vendor quote/invoice first.</p>
      )}
    </EmptyState>
  ) : undefined;

  const financingOptionsResourceList = (
    <ResourceList
      resourceName={resourceName}
      items={filteredItems}
      renderItem={(item, id, index) => {
        return renderItem(item, id, index);
      }}
      selectedItems={selectedItems}
      onSelectionChange={setSelectedItems}
      selectable={true}
      promotedBulkActions={promotedBulkActions}
      alternateTool={
        <Button onClick={handleCreateFinancingOption}>
          Create {resourceName["singular"]}
        </Button>
      }
      loading={
        isLoadingFinancingOptions ||
        isUpdatingFinancingOption ||
        isDeletingFinancingOption
      }
      emptyState={emptyStateMarkup}
    />
  );

  const lockedFinancingOptionsResourceList = (
    <ResourceList
      resourceName={resourceName}
      items={filteredItems}
      renderItem={(item, id, index) => {
        return renderItem(item, id, index);
      }}
      loading={
        isLoadingFinancingOptions ||
        isUpdatingFinancingOption ||
        isDeletingFinancingOption
      }
      emptyState={emptyStateMarkup}
    />
  );

  // context api handles values from FinancingContext
  const { newFinancingOptionType, handleNewFinancingOptionTypeChange } =
    useContext(FinancingContext);
  const handleNewFinancingTypeRadioChange = useCallback(
    (_checked, newValue) => {
      handleNewFinancingOptionTypeChange(newValue);
    },
    [handleNewFinancingOptionTypeChange]
  );

  const [availableFinancingOptionTypes, setAvailableFinancingOptionTypes] =
    useState(["standard"]);

  // set type defaults using opportunity
  useEffect(() => {
    if (opportunity.attributes.available_financing_option_types) {
      setAvailableFinancingOptionTypes(
        opportunity.attributes.available_financing_option_types
      );
    }

    if (opportunity.attributes.default_finance_type) {
      handleNewFinancingOptionTypeChange(
        opportunity.attributes.default_finance_type
      );
    } else {
      handleNewFinancingOptionTypeChange(availableFinancingOptionTypes[0]);
    }
  }, [
    availableFinancingOptionTypes,
    handleNewFinancingOptionTypeChange,
    opportunity.attributes.available_financing_option_types,
    opportunity.attributes.default_finance_type,
  ]);

  const createFinancingOptionModal = showCreateFinancingOptionModal && (
    <Modal
      open={showCreateFinancingOptionModal}
      onClose={handleCloseModal}
      title={"Select purchase option type"}
      primaryAction={{
        content: "Select",
        id: "selectFinancingOptionTypeButton",
        onAction: () => navigateToFinancingOptionsNew(newFinancingOptionType),
        disabled: availableFinancingOptionTypes.length < 1,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleCloseModal,
        },
      ]}
    >
      <Modal.Section>
        <LegacyStack vertical>
          {availableFinancingOptionTypes.length < 1 &&
            canCreateFinanceOption && (
              <p>
                No available purchase option types. Please contact support
                regarding this vendor's program configuration.
              </p>
            )}

          {availableFinancingOptionTypes.includes("standard") && (
            <RadioButton
              label="Standard financing"
              checked={newFinancingOptionType === "standard"}
              id="standard"
              name="newFinancingOptionTypes"
              onChange={handleNewFinancingTypeRadioChange}
            />
          )}
          {availableFinancingOptionTypes.includes("subscription") && (
            <RadioButton
              label="Subscription license agreement (SLA)"
              id="subscription"
              name="newFinancingOptionTypes"
              checked={newFinancingOptionType === "subscription"}
              onChange={handleNewFinancingTypeRadioChange}
            />
          )}
          {availableFinancingOptionTypes.includes("fair_market_value") && (
            <RadioButton
              label="Fair market value (FMV)"
              id="fair_market_value"
              name="newFinancingOptionTypes"
              checked={newFinancingOptionType === "fair_market_value"}
              onChange={handleNewFinancingTypeRadioChange}
            />
          )}
          {availableFinancingOptionTypes.includes("dollar_out_lease") && (
            <RadioButton
              label="Dollar out lease"
              id="dollar_out_lease"
              name="newFinancingOptionTypes"
              checked={newFinancingOptionType === "dollar_out_lease"}
              onChange={handleNewFinancingTypeRadioChange}
            />
          )}
          {availableFinancingOptionTypes.includes("saas") && (
            <RadioButton
              label="Software as a service (SaaS)"
              id="saas"
              name="newFinancingOptionTypes"
              checked={newFinancingOptionType === "saas"}
              onChange={handleNewFinancingTypeRadioChange}
            />
          )}
          {availableFinancingOptionTypes.includes("rental") && (
            <RadioButton
              label="Rental"
              id="rental"
              name="newFinancingOptionTypes"
              checked={newFinancingOptionType === "rental"}
              onChange={handleNewFinancingTypeRadioChange}
            />
          )}
          {availableFinancingOptionTypes.includes("net_terms") && (
            <RadioButton
              label="Net terms"
              id="net_terms"
              name="newFinancingOptionTypes"
              checked={newFinancingOptionType === "net_terms"}
              onChange={handleNewFinancingTypeRadioChange}
            />
          )}
        </LegacyStack>
      </Modal.Section>
    </Modal>
  );

  const hasDesignatedSigner = contactList.some(
    (contact) => contact.attributes.signer
  );

  const [
    showConfirmDesignatedSignerModal,
    setShowConfirmDesignatedSignerModal,
  ] = useState(false);

  const closeSignerModal = () => {
    setShowConfirmDesignatedSignerModal(false);
  };
  const confirmDesignatedSignerModal = showConfirmDesignatedSignerModal && (
    <Modal
      open={showConfirmDesignatedSignerModal}
      onClose={closeSignerModal}
      title={"Confirm designated signer"}
      primaryAction={{
        content: "Confirm",
        onAction: handleMarkAsAccepted,
        disabled: !hasDesignatedSigner,
        loading: isUpdatingOpportunityContact,
      }}
    >
      <OpportunityContactList opportunityId={opportunityId} />
    </Modal>
  );

  const financingOptionListMarkup = (
    <>
      {opportunity.attributes.financing_option_locked
        ? lockedFinancingOptionsResourceList
        : financingOptionsResourceList}
      {createFinancingOptionModal}
      {confirmDesignatedSignerModal}
      {toastMarkup}
    </>
  );

  return financingOptionListMarkup;
};
FinancingOptionList.propTypes = {
  canCreateFinanceOption: PropTypes.bool,
  contacts: PropTypes.array,
  opportunity: PropTypes.object,
  opportunityId: PropTypes.string,
};

export default FinancingOptionList;
