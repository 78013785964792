import React, { useState, useEffect, useCallback } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import VendorAdminTeams from "./VendorAdmin/Team/Index";
import VendorAdminTeamShow from "./VendorAdmin/Team/Show";

// TODO VendorAdmin Client list if needed to make a separate
// Current setup: Vendor Admins Client list is on Home Pages
// import AdminClientIndex from "./Admin/Client/Index";
// import AdminClientShow from "./Admin/Client/Show";

// import AdminVendorIndex from "./Admin/Vendor/Index";
// import AdminVendorShow from "./Admin/Vendor/Show";

const VendorAdmin = (props) => {
  return (
    <>
      <Switch>
        <Route
          exact
          path="/vendor_admin/teams"
          render={(props) => <VendorAdminTeams {...props} />}
        />
        <Route
          exact
          path="/vendor_admin/teams/:id"
          render={(props) => <VendorAdminTeamShow {...props} />}
        />
        {/*
        <Route 
          exact
          path="/vendor_settings"
          component={VendorAdminSettingsIndex}
        />
        <Route 
          exact
          path="/vendor_settings/:id"
          component={VendorAdminSettingsShow}
        />
        <Route
          exact
          path="/admin/clients"
          render={(props) => <AdminClientIndex {...props} />}
        />
        <Route
          exact
          path="/admin/clients/:id"
          render={(props) => <AdminClientShow {...props} />}
        />
        <Route
          exact
          path="/admin/vendors"
          render={(props) => <AdminVendorIndex {...props} />}
        />
        <Route
          exact
          path="/admin/vendors/:id"
          render={(props) => <AdminVendorShow {...props} />}
        />
        */}
      </Switch>
    </>
  );
};

export default VendorAdmin;
