import React, { useState } from "react";

import {
  ChoiceList,
  Listbox,
  Collapsible,
  Scrollable,
  Button,
  InlineStack
} from "@shopify/polaris";

import { taskNameToChoices } from "../../utilities";

const TaskFilter = (props) => {
  const {
    filterValues,
    filterObject,
    handleFilterValueChange
  } = props;

  const segments = [
    {
      label: 'Quote',
      id: 'quote',
      value: 'quote'
    },
    {
      label: 'Purchase Option',
      id: 'purchase_option',
      value: 'purchase_option'
    },
    {
      label: 'Pre-approved',
      id: 'purchase_option_approved',
      value: 'purchase_option_approved'
    },
    {
      label: 'Credit review',
      id: 'credit_review',
      value: 'credit_review'
    },
    {
      label: 'Signer',
      id: 'signer',
      value: 'signer'
    },
    {
      label: 'File upload',
      id: 'file_upload',
      value: 'file_upload'
    },
    {
      label: 'Envelope',
      id: 'envelope',
      value: 'envelope'
    },
    {
      label: 'Payment auth',
      id: 'payment_auth',
      value: 'payment_auth'
    },
    {
      label: 'Invoice',
      id: 'invoice',
      value: 'invoice'
    },
    {
      label: 'Transactions',
      id: 'transaction',
      value: 'transaction'
    }
  ]

  const [collapsibleOpen, setCollapsibleOpen] = useState({
    'quote': false,
    'purchase_option': false
  })

  const handleSegmentSelect = (value) => {
    let newCollapsibleOpen = JSON.parse(JSON.stringify(collapsibleOpen))
    newCollapsibleOpen[value] = !collapsibleOpen[value]

    setCollapsibleOpen(newCollapsibleOpen)
  }

  const handleActiveOptionChange = (value, domId) => {}

  const handleTaskSelectionChange = (taskName, value) => {
    let currentTaskValues = JSON.parse(JSON.stringify(filterValues['task']))
    currentTaskValues[taskName] = value

    handleFilterValueChange(currentTaskValues)
  }

  const clearTaskValue = (taskName) => {
    let currentTaskValues = JSON.parse(JSON.stringify(filterValues['task']))
    delete currentTaskValues[taskName]

    handleFilterValueChange(currentTaskValues)
  }

  // Task Filter
  const taskFilter = (
    <Scrollable
      shadow
      style={{
        position: 'relative',
        height: '292px',
        padding: 'var(--p-space-200) 0',
        borderBottomLeftRadius: 'var(--p-border-radius-200)',
        borderBottomRightRadius: 'var(--p-border-radius-200)',
      }}
      onScrolledToBottom={() => {}}
    >
      <Listbox
        accessibilityLabel="Select a requirement"
        onSelect={handleSegmentSelect}
        onActiveOptionChange={handleActiveOptionChange}
      >

        { 
          segments.map(({label, id, value}) => {
            return (
              <div>
                <div style={{ paddingTop: "0.25em", paddingBottom: "0.5em"}}>
                  <InlineStack
                    gap="400"
                    blockAlign="center"
                    align="space-between"
                  >
                    <div style={{ cursor: "pointer" }} onClick={() => { handleSegmentSelect(id)}}>
                      {label}
                    </div>
                    
                    { filterValues['task'][id]?.length > 0 && 
                      <Button variant="plain" onClick={() => { clearTaskValue(id) }}>Clear</Button> 
                    }
                  </InlineStack>
                </div>

                <Collapsible
                  open={collapsibleOpen[id]}
                  id={`collapsible-${id}`}
                  transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
                  expandOnPrint
                >
                  <ChoiceList
                    title={label}
                    titleHidden
                    choices={taskNameToChoices[id]}
                    selected={filterValues['task'][id] || []}
                    onChange={(value) => { handleTaskSelectionChange(id, value) }}
                  />
                </Collapsible>
              </div>
            );
          }) 
        }
      </Listbox>
    </Scrollable>
  )

  return taskFilter
};

export default TaskFilter;
