import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Page,
  Layout,
  Link,
  EmptyState,
  BlockStack,
} from "@shopify/polaris";
import { WithinContentContext } from "@shopify/polaris/build/esm/utilities/within-content-context";
import fatalErrorSVG from "../assets/fatalError.svg";

const FatalError = (props) => {
  const { hideRefreshAction = false } = props;
  const history = useHistory();

  const handleReloadPage = () => {
    history.push("/");
    window.location.reload();
  };

  return (
    <Page narrowWidth>
      <Layout sectioned>
        <div className={"FortFatalError__Page"}>
          <WithinContentContext.Provider value>
            <EmptyState
              image={fatalErrorSVG}
              imageContained
              heading="There is a problem loading this page."
              action={
                hideRefreshAction
                  ? null
                  : {
                      content: "Reload this page",
                      onAction: handleReloadPage,
                    }
              }
            >
              <BlockStack>
                <p>
                  Your browser did something unexpected. Please try again. If
                  the error continues, try disabling all browser extensions.{" "}
                  <Link
                    external
                    url={`mailto:${
                      process.env.SUPPORT_EMAIL ||
                      "info@fortcapitalresources.com"
                    }`}
                  >
                    Contact us
                  </Link>{" "}
                  if the problem persists.
                </p>
              </BlockStack>
            </EmptyState>
          </WithinContentContext.Provider>
        </div>
      </Layout>
    </Page>
  );
};

FatalError.propTypes = {
  hideRefreshAction: PropTypes.bool,
};

export default FatalError;
