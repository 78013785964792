import React, { useCallback, useState, useEffect } from "react";

import {
  Box,
  Text,
  Button,
  TextField,
  BlockStack,
  InlineStack,
  Badge,
  FormLayout,
  Tooltip,
} from "@shopify/polaris";
import { roundedDollarAmount } from "../../finance";
import { formatCurrency } from "../../utilities";

import { FieldBag, useReset } from "@shopify/react-form";

import { PlusIcon, InfoIcon } from "@shopify/polaris-icons";

interface Field {
  down_payment_percent: {
    value: number;
    onChange: (value: number) => void;
  };
  down_payment: {
    value: number;
    onChange: (value: number) => void;
  };
}

interface LastField {
  days: {
    value: number;
    onChange: (value: number) => void;
  };
  percent: {
    value: number;
    onChange: (value: number) => void;
  };
  amount: {
    value: number;
    onChange: (value: number) => void;
  };
  status: {
    value: string;
    onChange: (value: string) => void;
  };
}

interface TransactionObject {
  id: string;
  status: string;
}

type DownPaymentFieldsProps = {
  fields: Field;
  hasDownPayment: boolean;
  setHasDownPayment: (value: boolean) => void;
  downPaymentCompletedAt: Date | null;
  usesMilestonePayments: boolean;
  totalFinanceAmount: number;
  installmentPayments: LastField[];
  downPaymentTransaction: TransactionObject;
  navigateToTransaction: (id: string) => void;
};

const DownPaymentFields: React.FC<DownPaymentFieldsProps> = ({
  fields,
  hasDownPayment,
  setHasDownPayment,
  downPaymentCompletedAt,
  usesMilestonePayments = false,
  totalFinanceAmount,
  installmentPayments = [],
  navigateToTransaction,
  downPaymentTransaction,
}) => {
  const title = "Down payment";

  const reset = useReset(fields as unknown as FieldBag);

  const [lastPaymentPercent, setLastPaymentPercent] = useState(
    installmentPayments[installmentPayments.length - 1]?.percent?.value
  );

  const checkLastPaymentPercent = useCallback(() => {
    if (installmentPayments.length == 0) return;

    const lastIndex = installmentPayments.length - 1;
    setLastPaymentPercent(installmentPayments[lastIndex]?.percent?.value);
  }, [installmentPayments, setLastPaymentPercent]);

  useEffect(() => {
    checkLastPaymentPercent();
  }, [checkLastPaymentPercent]);

  const handleDownPaymentPercentChange = useCallback(
    (value: number, existingTotalFinanceAmount: number = null) => {
      if (isNaN(value)) {
        value = 0;
      }
      const currentTotalFinanceAmount =
        existingTotalFinanceAmount || totalFinanceAmount;

      const lastIndex = installmentPayments.length - 1;
      const lastPercent = installmentPayments[lastIndex]?.percent?.value;

      let totalPercent = installmentPayments.reduce((acc, field) => {
        acc += field.percent.value;
        return acc;
      }, 0);

      totalPercent += value;

      if (totalPercent < 100) {
        // transfer the rest to the last field
        const newLastPercent = lastPercent + (100 - totalPercent);
        installmentPayments[lastIndex]?.percent?.onChange(newLastPercent);
        setLastPaymentPercent(newLastPercent);
        const amount = newLastPercent * 0.01 * currentTotalFinanceAmount;
        installmentPayments[lastIndex]?.amount?.onChange(
          roundedDollarAmount(amount) || 0
        );
      } else if (totalPercent > 100 && lastPercent > totalPercent - 100) {
        // transfer the difference to the last field
        const newLastPercent = lastPercent - (totalPercent - 100);
        installmentPayments[lastIndex]?.percent?.onChange(newLastPercent);
        setLastPaymentPercent(newLastPercent);
        const amount = newLastPercent * 0.01 * currentTotalFinanceAmount;
        installmentPayments[lastIndex]?.amount?.onChange(
          roundedDollarAmount(amount) || 0
        );
      }

      fields.down_payment_percent.onChange(value);
      const amount = value * 0.01 * currentTotalFinanceAmount;

      fields.down_payment.onChange(roundedDollarAmount(amount));
    },
    [fields, installmentPayments, totalFinanceAmount]
  );

  const handleModifyDownPaymentPercent = useCallback(
    (create: boolean) => {
      let parts = installmentPayments.length;
      if (create) parts += 1;

      const evenPartPercentage: number = +(100 / parts).toFixed();
      let totalPercentage: number = 100;
      let j = 0;

      for (let i = 1; i <= parts; i++) {
        if (create && i == 1) {
          // Down payment
          fields.down_payment_percent.onChange(evenPartPercentage);
          fields.down_payment.onChange(
            evenPartPercentage * 0.01 * totalFinanceAmount
          );
        } else if (i == parts) {
          // Last installment payment
          // Set remainder
          const amount = totalPercentage * 0.01 * totalFinanceAmount;
          installmentPayments[j].percent.onChange(totalPercentage);
          installmentPayments[j].amount.onChange(
            roundedDollarAmount(amount) || 0
          );
        } else {
          // Middle installment payments
          // Set part percentage
          const amount = evenPartPercentage * 0.01 * totalFinanceAmount;
          installmentPayments[j].percent.onChange(evenPartPercentage);
          installmentPayments[j].amount.onChange(
            roundedDollarAmount(amount) || 0
          );

          j += 1;
        }

        totalPercentage = totalPercentage - evenPartPercentage;
      }
    },
    [fields, installmentPayments, totalFinanceAmount]
  );

  const handleAddDownPayment = useCallback(() => {
    setHasDownPayment(true);
    if (usesMilestonePayments) {
      handleModifyDownPaymentPercent(true);
    }
  }, [
    setHasDownPayment,
    handleModifyDownPaymentPercent,
    usesMilestonePayments,
  ]);

  const handleRemoveDownPayment = useCallback(() => {
    setHasDownPayment(false);
    reset();
    handleModifyDownPaymentPercent(false);
  }, [handleModifyDownPaymentPercent, reset, setHasDownPayment]);

  const handleSwitchToMilestonePayment = useCallback(() => {
    if (hasDownPayment) {
      const paymentCount = installmentPayments.length + 1; // Always add 1 for the down payment
      const assignPercent = 100 / paymentCount;
      const existingTotalFinanceAmount =
        totalFinanceAmount + fields.down_payment.value;
      handleDownPaymentPercentChange(
        Number(assignPercent.toFixed(0)),
        existingTotalFinanceAmount
      );
    }
  }, [
    hasDownPayment,
    installmentPayments.length,
    totalFinanceAmount,
    fields.down_payment.value,
    handleDownPaymentPercentChange,
  ]);

  useEffect(() => {
    // update down payment percent when switched to milestone payments
    if (usesMilestonePayments) {
      handleSwitchToMilestonePayment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usesMilestonePayments]);

  const helpLink = (
    <Button
      variant="plain"
      icon={InfoIcon}
      accessibilityLabel="Learn more"
      external
      url={"https://help.fortifypay.com/"}
    />
  );

  const settingTitle = title ? (
    <InlineStack gap="200" wrap={false} align="start" blockAlign="center">
      <Text variant="headingSm" as="h6">
        {title}
      </Text>

      {helpLink}
    </InlineStack>
  ) : null;

  const headerMarkup = <Box width="100%">{settingTitle}</Box>;

  const transactionStatusBadge = useCallback(() => {
    let statusBadge = <Badge>Pending</Badge>;
    if (
      downPaymentTransaction?.status === "completed" ||
      downPaymentTransaction?.status === "complete"
    ) {
      statusBadge = <Badge tone="success">Complete</Badge>;
    } else if (downPaymentTransaction?.status === "scheduled") {
      statusBadge = <Badge tone="info">Scheduled</Badge>;
    }

    return (
      <InlineStack gap="100" align="center">
        {statusBadge && (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigateToTransaction(downPaymentTransaction?.id);
            }}
          >
            {statusBadge}
          </div>
        )}
        <Button
          onClick={handleRemoveDownPayment}
          variant="plain"
          tone="critical"
        >
          Remove
        </Button>
      </InlineStack>
    );
  }, [downPaymentTransaction, handleRemoveDownPayment, navigateToTransaction]);

  return (
    <BlockStack gap="400">
      {headerMarkup}

      <FormLayout>
        {hasDownPayment ? (
          <FormLayout.Group>
            {usesMilestonePayments && (
              <div className="FortForm__NumericTextField">
                <TextField
                  autoComplete="off"
                  label="Percent"
                  type="number"
                  suffix="%"
                  min={0}
                  disabled={downPaymentCompletedAt ? true : false}
                  {...fields.down_payment_percent}
                  onChange={(value) => {
                    handleDownPaymentPercentChange(parseInt(value));
                  }}
                  value={String(fields.down_payment_percent.value)}
                />
              </div>
            )}
            <TextField
              autoComplete="off"
              label="Amount"
              type={usesMilestonePayments ? "text" : "number"}
              step={1}
              prefix="$"
              disabled={
                (downPaymentCompletedAt ? true : false) || usesMilestonePayments
              }
              connectedRight={
                downPaymentCompletedAt ? (
                  <Badge tone="success">Complete</Badge>
                ) : (
                  transactionStatusBadge()
                )
              }
              {...fields.down_payment}
              onChange={(value) =>
                fields.down_payment.onChange(parseFloat(value) || 0)
              }
              value={String(
                usesMilestonePayments
                  ? formatCurrency(fields.down_payment.value, "decimal")
                  : fields.down_payment.value
              )}
            />
          </FormLayout.Group>
        ) : (
          <Box>
            {usesMilestonePayments ? (
              <Tooltip content="The last payment must be at least 2% before adding a down payment.">
                <Button
                  icon={PlusIcon}
                  onClick={handleAddDownPayment}
                  disabled={lastPaymentPercent < 2}
                >
                  Add down payment
                </Button>
              </Tooltip>
            ) : (
              <Button icon={PlusIcon} onClick={handleAddDownPayment}>
                Add down payment
              </Button>
            )}
          </Box>
        )}
      </FormLayout>
    </BlockStack>
  );
};

export default DownPaymentFields;
