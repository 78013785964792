import React from "react";
import PropTypes from "prop-types";

const DelayRender = (props) => {
  const [show, setShow] = React.useState(false);
  const { delay, children } = props;

  React.useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, delay);
  }, [show]);

  if (!show) return null;

  return children;
};
DelayRender.propTypes = {
  children: PropTypes.any,
  delay: PropTypes.number
}

export default DelayRender;
