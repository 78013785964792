import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
} from "react";
import { useDebounce } from "use-debounce";
import {
  useSearchContactsQuery,
  useAddAdminMutation,
} from "../../services/api";
import { useField, useForm, notEmpty } from "@shopify/react-form";
import {
  Autocomplete,
  FormLayout,
  Icon,
  LegacyStack,
  Tag,
  TextField,
  Checkbox,
  Text,
} from "@shopify/polaris";
import { SearchIcon, PlusCircleIcon } from "@shopify/polaris-icons";
import PropTypes from "prop-types";
import { validateEmail } from "../../utilities";

const AdminContactForm = forwardRef((props, ref) => {
  const {
    onAfterShareWithContacts,
    onNotifyContacts,
    setInviteParams,
    isShowNewContactForm,
    addNewContact,
    setAddNewContact,
  } = props;

  const [notifMsg, setNotifMsg] = useState("");
  const [notifyContacts, setNotifyContacts] = useState(false);
  const handleCheckboxChange = useCallback((newChecked) => {
    setNotifyContacts(newChecked);
    onNotifyContacts(newChecked);
    setNotifMsg("");
  }, []);

  const [createOrgAdminContact, { isLoading: isCreatingOrgAdminContact }] =
    useAddAdminMutation();

  const [contactSearchValue, setContactSearchValue] = useState("");
  const [contactSearchQuery] = useDebounce(contactSearchValue, 500);

  const [showNewContactForm, setShowNewContactForm] = useState(false);

  const { data: searchContacts = [], isLoading: isLoadingSearchContacts } =
    useSearchContactsQuery({ search: contactSearchQuery });

  const [selectedContactOptions, setSelectedContactOptions] = useState([]);
  const [selectedContactOptionIds, setSelectedContactOptionIds] = useState([]);
  const [contactOptions, setContactOptions] = useState([]);

  const handleSelectContact = useCallback(
    (selectedContactIds) => {
      if (selectedContactIds.includes(selectedContactIds)) {
        return;
      } else {
        setSelectedContactOptionIds(selectedContactIds);

        const newSelectedContacts = searchContacts.filter((contact) => {
          return selectedContactIds.includes(contact.id);
        });

        setSelectedContactOptions([
          ...new Set([...selectedContactOptions, ...newSelectedContacts]),
        ]);
      }
    },
    [searchContacts, selectedContactOptions]
  );

  const handleRemoveContact = useCallback(
    (contact) => {
      const options = [...selectedContactOptions];
      options.splice(options.indexOf(contact), 1);
      setSelectedContactOptions(options);

      const optionIds = [...selectedContactOptionIds];
      optionIds.splice(optionIds.indexOf(contact.id), 1);
      setSelectedContactOptionIds(optionIds);
      if (optionIds.length < 1) {
        setNotifyContacts(false);
        onNotifyContacts(false);
      }
    },
    [selectedContactOptionIds, selectedContactOptions]
  );

  // const shareButton = useRef(null);

  useEffect(() => {
    setInviteParams({
      notifMsg,
      notifyContacts,
      contactIds: selectedContactOptionIds,
    });
  }, [notifyContacts, notifMsg, selectedContactOptionIds, setInviteParams]);

  useEffect(() => {
    if (searchContacts.length > 0) {
      const contactList = searchContacts.map((contact, index) => {
        return {
          value: contact.id,
          label: `${contact.attributes.full_name} - ${contact.attributes.email}`,
        };
      });

      setContactOptions(contactList);
    }
  }, [searchContacts]);

  const verticalContentMarkup =
    selectedContactOptions.length > 0 ? (
      <LegacyStack spacing="extraTight" align="center" wrap>
        {selectedContactOptions.map((contact, index) => {
          return (
            <Tag
              key={`option${index}`}
              onRemove={() => handleRemoveContact(contact)}
            >
              {contact.attributes.full_name}
            </Tag>
          );
        })}
      </LegacyStack>
    ) : null;

  const contactShareTextField = (
    <>
      <Autocomplete.TextField
        label={"Share with"}
        onChange={setContactSearchValue}
        prefix={<Icon source={SearchIcon} tone="base" />}
        placeholder="Search"
        value={contactSearchValue}
        verticalContent={verticalContentMarkup}
      />
      <div
        style={{
          margin: "0.4rem",
          display: !selectedContactOptionIds.length ? "none" : "",
        }}
      >
        <Checkbox
          disabled={!selectedContactOptionIds.length}
          label="Notify contacts?"
          checked={notifyContacts}
          onChange={handleCheckboxChange}
        />
      </div>
      {notifyContacts && (
        <div style={{ marginTop: "1rem" }}>
          <Text variant="headingSm" as="h3">Notification Message</Text>
          <br />
          <TextField
            label=""
            disabled={!notifyContacts}
            value={notifMsg}
            onChange={setNotifMsg}
            multiline={2}
            autoComplete="off"
          />
        </div>
      )}
    </>
  );

  const {
    fields,
    submit,
    submitting,
    reset,
    submitErrors,
    makeClean,
    dirty: newContactFormDirty,
  } = useForm({
    fields: {
      first_name: useField({
        value: "",
        validates: [notEmpty("First name is required")],
      }),
      last_name: useField({
        value: "",
        validates: [notEmpty("Last name is required")],
      }),
      email: useField({
        value: "",
        validates: [
          notEmpty("Email is required"),
          (email) => {
            if (email.length <= 3 || !validateEmail(email)) {
              return "Email must be valid";
            }
          },
        ],
      }),
    },
    async onSubmit(form) {
      return createOrgAdminContact({
        notifyContacts: true,
        notifMsg: notifMsg,
        ...form,
      })
        .unwrap()
        .then(() => {
          setAddNewContact(false);
          isShowNewContactForm(false);
          setSelectedContactOptionIds([]);
          setSelectedContactOptions([]);
          setContactSearchValue("");

          setShowNewContactForm(false);
          setNotifyContacts(false);
          setNotifMsg("");

          // pull focus from autocomplete
          // shareButton.current.click();
          onAfterShareWithContacts();
          return { status: "success" };
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  useEffect(() => {
    if (addNewContact) {
      submit();
    }
  }, [addNewContact]);

  return <>
    {showNewContactForm ? (
      (<FormLayout>
        <FormLayout.Group>
          <TextField label="First name" type="text" {...fields.first_name} />
          <TextField label="Last name" type="text" {...fields.last_name} />
        </FormLayout.Group>
        <TextField label="Email" type="email" {...fields.email} />
        <TextField
          label="Message"
          type="text"
          multiline={2}
          value={notifMsg}
          onChange={setNotifMsg}
        />
      </FormLayout>)
    ) : (
      <>
        <Autocomplete
          allowMultiple
          id={"contact-share-autocomplete"}
          options={contactOptions}
          selected={selectedContactOptionIds}
          onSelect={handleSelectContact}
          textField={contactShareTextField}
          loading={isLoadingSearchContacts}
          emptyState={"No contacts found"}
          preferredPosition={"below"}
          actionBefore={{
            accessibilityLabel: "Invite contact",
            content: "Invite a new contact to this vendor",
            ellipsis: true,
            icon: PlusCircleIcon,
            onAction: () => {
              isShowNewContactForm(true);
              setShowNewContactForm(true);
              setNotifyContacts(true);
              setNotifMsg("");
            },
          }}
        />
      </>
    )}
  </>;
});
AdminContactForm.displayName = "AdminContactForm";
AdminContactForm.propTypes = {
  onNotifyContacts: PropTypes.func,
  onAfterShareWithContacts: PropTypes.func,
  setInviteParams: PropTypes.func,
  isShowNewContactForm: PropTypes.func,
  addNewContact: PropTypes.any,
  setAddNewContact: PropTypes.any,
};

export default AdminContactForm;

