import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import {
  Badge,
  Filters,
  IndexTable,
  ResourceList,
  ResourceItem,
  Text,
  LegacyStack,
  BlockStack,
  InlineStack,
  Pagination,
  EmptyState,
  Icon,
  Truncate,
  ChoiceList,
  Tag,
  Link,
  Modal,
  SkeletonBodyText,
  SkeletonThumbnail,
} from "@shopify/polaris";

import { useDebounce } from "use-debounce";
import { NoteIcon } from "@shopify/polaris-icons";

import {
  useGetAdminVendorOpportunitiesQuery,
  useUpdateTagOpportunitiesMutation,
  useGetTagsQuery,
} from "../../../services/api";

import { formatCurrency } from "../../../utilities";
import emptySearchCompanies from "../../../assets/emptySearchCompanies.svg";

const AdminVendorOpportunityList = (props) => {
  const resourceName = {
    singular: "opportunity",
    plural: "opportunites",
  };

  const {
    stageFilters,
    format,
    onCreateOpportunity,
    onOpportunityClick,
    tagContext,
    vendorId,
    pageLimit = 5,
  } = props;

  const [queryValue, setQueryValue] = useState(undefined);
  const [searchValue] = useDebounce(queryValue, 500);
  const [sortValue, setSortValue] = useState("UPDATED_AT_DESC");
  const [selectedItems, setSelectedItems] = useState([]);
  const [allSelected, setAllSeleted] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedResources, setSelectedResources] = useState([]);

  // const {selectedResources, allResourcesSelected, handleSelectionChange} =
  //   useIndexResourceState(customers);

  const handleQueryValueRemove = useCallback(
    () => setQueryValue(undefined),
    []
  );
  const handleQueryChange = (value) => setQueryValue(value);

  const [opportunityStage, setOpportunityStage] = useState([...stageFilters]);
  const handleOpportunityStageChange = useCallback((value) => {
    setOpportunityStage(value);
  }, []);

  const [limit, setLimit] = useState(pageLimit);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [disambiguatedFilters, setDisambiguatedFilters] = useState(null);

  const {
    data: { opportunities = [], included = [], meta = {} } = {},
    isLoading: queryIsLoading,
    isFetching: queryIsFetching,
  } = useGetAdminVendorOpportunitiesQuery({
    vendorId: vendorId,
    page: page,
    limit: limit,
    filters: disambiguatedFilters,
    sort: sortValue,
    search: searchValue,
  });

  const [updateTagOpportunities, { isLoading: isTagging }] =
    useUpdateTagOpportunitiesMutation();

  const { data: tags, isLoading: fetchingTags } = useGetTagsQuery();

  const [choiceListTags, setChoiceListTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState(
    fetchingTags ? [] : tags[0]?.name
  );
  const [opportunityTags, setOpportunityTags] = useState([]);

  const handleSelectedTagChange = useCallback((tagVal) => {
    setSelectedTag(tagVal);
  }, []);

  useEffect(() => {
    if (tags) {
      setChoiceListTags(
        tags.map((tag) => {
          return { label: tag.attributes.name, value: tag.id };
        })
      );
    }
  }, [tags]);

  useEffect(() => {
    const count = meta.total_count || 0;
    if (count && opportunities) {
      const emptyOpportunity = {
        attributes: {
          name: "",
          stage_name: "",
          total_finance_amount: "",
          financing_option_count: "",
          financing_type: "",
          has_financing_option: "",
          has_unapproved_financing_options: "",
        },
      };

      const list = new Array(count).fill(emptyOpportunity);

      const lowerLimit = (page - 1) * limit;
      const upperLimit = Math.min(page * limit, count);

      opportunities.forEach((opportunity, index) => {
        const listIndex = lowerLimit + index;
        if (listIndex < upperLimit) {
          list[listIndex] = opportunity;
        }
      });

      setFilteredItems(list);
    } else {
      setFilteredItems([]);
    }
    setIsLoading(false);
  }, [limit, opportunities, page, meta.total_count]);

  useEffect(() => {
    if (queryValue && queryValue.length > 0) {
      setAppliedFilters([]);
      setDisambiguatedFilters(null);
      handleOpportunityStageRemove();
    }
  }, [handleOpportunityStageRemove, queryValue]);

  const handleSelectionChange = (selectionType, toggleType, selection) => {
    let selectionList = [...selectedResources];

    if (selectionType === "single") {
      // Check if in selection
      let inSelectionIndex = -1;
      for (let i = 0; i < selectedResources.length; i++) {
        if (selectedResources[i] === selection) {
          inSelectionIndex = i;
          break;
        }
      }

      if (inSelectionIndex != -1) {
        // Remove if in list
        selectionList.splice(inSelectionIndex, 1);
      } else {
        // Add if not
        selectionList.push(selection);
      }

      setSelectedResources(selectionList);
      setAllSeleted(false);
    } else {
      if (allSelected || selectedResources.length === opportunities.length) {
        // Clear selection
        setSelectedResources([]);
        setAllSeleted(false);
      } else {
        // Select all
        let list = [];
        for (let i = 0; i < limit; i++) {
          list.push(i);
        }
        setSelectedResources(list);
        setAllSeleted(true);
      }
    }
  };

  const filters = [
    {
      key: "opportunityStage",
      label: "Stage",
      filter: (
        <ChoiceList
          title="Opportunity stage"
          titleHidden
          choices={[
            { label: "Lead", value: "Lead" },
            { label: "Estimated terms", value: "Estimated terms" },
            { label: "Credit review", value: "Credit review" },
            { label: "Approved", value: "Approved" },
            { label: "Booked", value: "Booked" },
            { label: "Funded", value: "Funded" },
          ]}
          selected={opportunityStage || []}
          onChange={handleOpportunityStageChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
  ];

  const handleOpportunityStageRemove = useCallback(
    (value) => {
      let opportunityStageList = [...opportunityStage];
      let index = 0;
      for (let i = 0; i < opportunityStage.length; i++) {
        if (value === opportunityStage[i]) index = i;
      }
      opportunityStageList.splice(index, 1);
      setOpportunityStage(opportunityStageList);
    },
    [opportunityStage]
  );

  const handleFiltersClearAll = useCallback(() => {
    handleOpportunityStageRemove();
    handleQueryValueRemove();
  }, [handleOpportunityStageRemove, handleQueryValueRemove]);

  const filterControl =
    opportunities.length || disambiguatedFilters || queryValue ? (
      <Filters
        filters={[]}
        appliedFilters={appliedFilters}
        queryValue={queryValue}
        queryPlaceholder="Search opportunities"
        onQueryChange={handleQueryChange}
        onQueryClear={handleQueryValueRemove}
        onClearAll={handleFiltersClearAll}
      />
    ) : undefined;

  useEffect(() => {
    if (!isEmpty(opportunityStage)) {
      let disambiguatedFilterList = disambiguateLabel(
        "opportunityStage",
        opportunityStage
      );
      let filterList = [];
      const key = "opportunityStage";
      for (let i = 0; i < opportunityStage.length; i++) {
        filterList.push({
          key: opportunityStage[i],
          label: opportunityStage[i],
          onRemove: handleOpportunityStageRemove,
        });
      }
      setAppliedFilters(filterList);
      setDisambiguatedFilters(disambiguatedFilterList);
    } else {
      setAppliedFilters([]);
      setDisambiguatedFilters(null);
    }
  }, [handleOpportunityStageRemove, opportunityStage]);

  useEffect(() => {
    let filteredItems = [...opportunities];

    setFilteredItems(filteredItems);

    return () => setFilteredItems([]);
  }, [queryValue, opportunities, opportunityStage, sortValue]);

  const handleNextPage = () => {
    setIsLoading(true);
    setPage((state) => {
      if (state == Math.ceil(meta.total_count / limit)) {
        return Math.ceil(meta.total_count / limit);
      }
      return state + 1;
    });
  };
  const handlePreviousPage = () => {
    setIsLoading(true);
    setPage((state) => {
      if (state == 1) {
        return 1;
      }
      return state - 1;
    });
  };

  const [paginatedFilteredItems, setPaginatedFilteredItems] = useState([]);
  const [currentPageItems, setCurrentPageItems] = useState([]);
  useEffect(() => {
    const perPage = limit;

    if (filteredItems.length) {
      const paginatedFilteredItems = filteredItems.reduce(
        (resultArray, item, index) => {
          const pageIndex = Math.floor(index / perPage);
          if (!resultArray[pageIndex]) {
            resultArray[pageIndex] = [];
          }
          if (item) resultArray[pageIndex].push(item);

          return resultArray;
        },
        []
      );

      setPaginatedFilteredItems(paginatedFilteredItems);
    }

    return () => setPaginatedFilteredItems([]);
  }, [filteredItems, limit]);

  useEffect(() => {
    if (paginatedFilteredItems.length) {
      const currentPageItems = paginatedFilteredItems[page - 1] || [];
      setCurrentPageItems(currentPageItems);
    }

    return () => setCurrentPageItems([]);
  }, [paginatedFilteredItems, page]);

  const handleTagOpportunities = () => {
    let opportunityIds = [];

    if (allSelected) {
      opportunityIds = opportunities.map((opportunity) => opportunity.id);
    } else {
      opportunityIds = selectedResources.map((resourceIndex) => {
        if (filteredItems[resourceIndex]) {
          return filteredItems[resourceIndex].id;
        }
      });
    }

    console.log("tagContext", tagContext);
    return updateTagOpportunities({
      opportunity_ids: opportunityIds.join(","),
      tag_context: tagContext,
      tag_ids: selectedTag.join(","),
    })
      .unwrap()
      .then(() => {
        setAllSeleted(false);
        setSelectedItems([]);
        setOpenSelectTagModal(false);
        handleSelectedTagChange([]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const untagOpportunities = () => {
    let opportunityIds = [];

    if (allSelected) {
      opportunityIds = opportunities.map((opportunity) => opportunity.id);
    } else {
      opportunityIds = selectedResources.map((resourceIndex) => {
        if (filteredItems[resourceIndex]) {
          return filteredItems[resourceIndex].id;
        }
      });
    }

    return updateTagOpportunities({
      opportunity_ids: opportunityIds.join(","),
      tag_context: tagContext,
    })
      .unwrap()
      .then(() => {
        setAllSeleted(false);
        setSelectedResources([]);
        setOpenSelectTagModal(false);
        handleSelectedTagChange([]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRemoveTag = (opportunityId, tag) => {
    return updateTagOpportunities({
      opportunity_id: opportunityId,
      tag_context: tagContext,
      tag_name: tag,
      single_remove: true,
    })
      .unwrap()
      .then(() => {
        setAllSeleted(false);
        setSelectedResources([]);
        setOpenSelectTagModal(false);
        handleSelectedTagChange([]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [openSelectTagModal, setOpenSelectTagModal] = useState(false);

  const selectTagModal = (
    <Modal
      open={openSelectTagModal}
      onClose={() => setOpenSelectTagModal(false)}
      title="Add tag"
      loading={isTagging}
      primaryAction={{
        content: "Add tag",
        onAction: handleTagOpportunities,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => setOpenSelectTagModal(false),
        },
      ]}
    >
      <Modal.Section>
        {tags && (
          <ChoiceList
            allowMultiple
            title="Available tags"
            choices={choiceListTags || []}
            selected={selectedTag || []}
            onChange={handleSelectedTagChange}
          />
        )}
      </Modal.Section>
    </Modal>
  );

  const promotedBulkActions = [
    {
      content: "Add tag",
      onAction: () => setOpenSelectTagModal(true),
    },
    {
      content: "Remove all tags",
      onAction: untagOpportunities,
    },
  ];

  const paginationMarkup = (
    <Pagination
      hasPrevious={page != 1}
      onPrevious={handlePreviousPage}
      label={`Page ${page} of ${Math.ceil(meta.total_count / limit)}`}
      hasNext={page < Math.ceil(meta.total_count / limit)}
      onNext={handleNextPage}
    />
  );

  const emptyStateMarkup =
    !opportunities.length &&
    !isLoading &&
    !disambiguatedFilters &&
    !queryValue ? (
      <EmptyState
        heading="Create opportunities and generate documents"
        action={{
          content: "Create opportunity",
          onAction: onCreateOpportunity,
        }}
        secondaryAction={{
          content: "Learn more",
          url: "https://help.fortifypay.com/how-to-create-an-opportunity",
          external: true,
        }}
        image={emptySearchCompanies}
      >
        <p>
          Create a new opportunity for this account to track financing options
          and documents.
        </p>
      </EmptyState>
    ) : undefined;

  const disambiguateLabel = (key, value) => {
    switch (key) {
      case "opportunityStage":
        return value.map((val) => `${val}`).join(",");
      default:
        return value;
    }
  };

  const isEmpty = (value) => {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  };

  const renderItem = (item, onOpportunityClick, format, isLoading) => {
    if (isLoading) {
      return skeletonFormat();
    }

    return itemFormat(item, onOpportunityClick);
  };

  const itemFormat = (item, onOpportunityClick) => {
    const {
      id,
      attributes: {
        name,
        human_stage_name,
        total_finance_amount,
        product_description,
        originator_name,
      },
    } = item;

    const formattedTotalFinanceAmount = formatCurrency(total_finance_amount);

    return (
      <ResourceItem
        id={id}
        onClick={() => onOpportunityClick(item)}
        verticalAlignment="center"
        media={<Icon source={NoteIcon} tone="subdued" />}
      >
        <InlineStack align="space-between">
          <div>
            <h3>
              <Text as="span" fontWeight="semibold">
                <Truncate>{name}</Truncate>
              </Text>
            </h3>
            <Text variant="bodySm" as="p">
              <div style={{ maxWidth: "500px" }}>
                <Truncate>
                  {originator_name || "-"} ∙ {product_description}
                </Truncate>
              </div>
            </Text>
          </div>

          <InlineStack>
            {
              //isDesktop
              false && (
                <div
                  style={{
                    width: "125px",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Badge>{human_stage_name || "Lead"}</Badge>
                </div>
              )
            }

            <div style={{ width: "100px", textAlign: "right" }}>
              <p>{formattedTotalFinanceAmount}</p>
            </div>
          </InlineStack>
        </InlineStack>
      </ResourceItem>
    );
  };

  const rowMarkup = filteredItems.map((opportunity, index) => {

    const {
      id,
      attributes: {
        name,
        total_finance_amount,
        product_description,
        originator_name,
        vendor_tags = [],
      },
    } = opportunity;

    if (vendor_tags == undefined) return null;
    const formattedTotalFinanceAmount = formatCurrency(total_finance_amount);

    return (
      <IndexTable.Row
        id={index}
        key={index}
        selected={selectedResources.includes(index)}
        position={index}
      >
        <IndexTable.Cell>
          <Link
            removeUnderline
            monochrome
            onClick={() => onOpportunityClick(opportunity)}
          >
            <Text
              variant="strong"
              fontWeight="bold"
              as={"span"}
              truncate={true}
            >
              {name}
            </Text>
          </Link>
          <Text variant="bodySm" as="p">
            <div style={{ maxWidth: "500px" }}>
              <Truncate>
                {originator_name || "-"} ∙ {product_description}
              </Truncate>
            </div>
          </Text>
        </IndexTable.Cell>

        <IndexTable.Cell>
          <Text alignment={"end"} numeric>
            {formattedTotalFinanceAmount}
          </Text>
        </IndexTable.Cell>

        <IndexTable.Cell>
          <InlineStack wrap={false} gap="200">
            {vendor_tags.length > 0 ? (
              vendor_tags.map((tag, index) => (
                <Tag key={index} onRemove={() => handleRemoveTag(id, tag)}>
                  {tag}
                </Tag>
              ))
            ) : (
              <></>
            )}
          </InlineStack>
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  const skeletonFormat = () => {
    return (
      <ResourceItem
        media={<SkeletonThumbnail size="small" />}
        accessibilityLabel={`View details for ...`}
        verticalAlignment="center"
      >
        <BlockStack gap="100">
          <div style={{ width: "200px" }}>
            <SkeletonBodyText lines={1} />
          </div>
          <SkeletonBodyText lines={1} />
          <SkeletonBodyText lines={1} />
          <SkeletonBodyText lines={1} />
        </BlockStack>
      </ResourceItem>
    );
  };

  const opportunitiesResourceList = (
    <ResourceList
      selectable
      resourceName={resourceName}
      items={currentPageItems}
      renderItem={(item) =>
        renderItem(item, onOpportunityClick, format, isLoading)
      }
      promotedBulkActions={promotedBulkActions}
      selectedItems={selectedItems}
      selectedItemsCount={allSelected ? "All" : selectedItems.length}
      onSelectionChange={setSelectedItems}
      emptyState={emptyStateMarkup}
      filterControl={filterControl}
      sortValue={sortValue}
      sortOptions={[{ label: "Most recent", value: "UPDATED_AT_DESC" }]}
      onSortChange={(selected) => {
        setSortValue(selected);
      }}
      loading={isLoading || queryIsLoading || queryIsFetching}
      flushFilters
    />
  );

  const opportunityIndexTable = (
    <IndexTable
      selectable
      resourceName={resourceName}
      itemCount={opportunities?.length || 0}
      selectedItemsCount={allSelected ? "All" : selectedResources.length}
      onSelectionChange={handleSelectionChange}
      headings={[
        { title: "Opportunity" },
        { title: "Amount", alignment: "end" },
        { title: "Tag" },
      ]}
      emptyState={emptyStateMarkup}
      promotedBulkActions={promotedBulkActions}
      loading={isLoading || queryIsLoading || queryIsFetching || isTagging}
    >
      {rowMarkup}
    </IndexTable>
  );

  return (
    <>
      {opportunityIndexTable}
      <br />
      {/* {opportunitiesResourceList} */}
      {paginatedFilteredItems.length > 1 && (
        <LegacyStack distribution={"center"}>{paginationMarkup}</LegacyStack>
      )}
      {selectTagModal}
    </>
  );
};

AdminVendorOpportunityList.propTypes = {
  format: PropTypes.string,
  stageFilters: PropTypes.array,
  onCreateOpportunity: PropTypes.func,
  onOpportunityClick: PropTypes.func,
  tagContext: PropTypes.string,
  vendorId: PropTypes.string,
  pageLimit: PropTypes.number,
};

export default AdminVendorOpportunityList;
