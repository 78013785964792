import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useEffect,
  useContext,
} from "react";
import { useHistory } from "react-router-dom";
import {
  FormLayout,
  TextField,
  BlockStack,
  Select,
  Button,
  Card,
  Box,
  Banner,
  Modal,
  Text,
  Tooltip,
  LegacyStack,
  Checkbox,
  Badge,
  ProgressBar,
  Divider,
} from "@shopify/polaris";
import { useField, useForm, notEmpty } from "@shopify/react-form";
import PropTypes from "prop-types";
import {
  useGetTeamsQuery,
  useGetClientsQuery,
  // useCreateVendorPaymentConfirmationMutation,
  useGenerateVendorPaymentMutation,
  useCreateVendorPaymentOpportunityMutation,
} from "../../services/api";
import {
  convertStateName,
  stateOptionsShort,
} from "../../utilities";
import { CurrentContactContext } from "../../contexts/Contact";

const CreateOpportunityForm = forwardRef((props, ref) => {
  const {
    handleErrorMessage,
    onConfirmationLoading,
    stateClientId,
    existingClientData,
  } = props;

  const history = useHistory();
  const navigateToOpportunityShow = useCallback(
    (clientId, opportunityId) => {
      history.push(`/clients/${clientId}/opportunities/${opportunityId}`);
    },
    [history]
  );

  const { currentContact } = useContext(CurrentContactContext);

  const [generateVendorPayment, {}] =
    useGenerateVendorPaymentMutation();

  const [createVendorPaymentOpportunity, { isLoading }] =
    useCreateVendorPaymentOpportunityMutation();

  const { data: teamData = [], isLoading: queryIsLoading } = useGetTeamsQuery({
    contactId: currentContact.id,
  });

  const { data: clientData = [], isLoading: clientIsLoading } = useGetClientsQuery();

  const [vendorPaymentId, setVendorPaymentId] = useState('');
  const [teams, setTeams] = useState([]);
  const [clients, setClients] = useState([]);
  const [confirmedClient, setConfirmedClient] = useState({});
  // const [confirmedContact, setConfirmedContact] = useState({});
  const [confirmedVendor, setConfirmedVendor] = useState({});
  const [confirmedVendorPayment, setConfirmedVendorPayment] = useState({});

  const [matchedClient, setMatchedClient] = useState({});
  // const [matchedContact, setMatchedContact] = useState({});
  const [matchedVendor, setMatchedVendor] = useState({});

  const [submitMatchedClient, setSubmitMatchedClient] = useState(false);
  const [submitMatchedContact, setSubmitMatchedContact] = useState(false);
  const [submitMatchedVendor, setSubmitMatchedVendor] = useState(false);
  const [teamId, setTeamId] = useState(teamData[0]?.id);
  const [clientId, setClientId] = useState(clientData[0]?.id);
  const [vendorLocked, setVendorLocked] = useState(
    teamData[0]?.attributes?.vendor_locked
  );
  const [selectedTeam, setSelectedTeam] = useState(teamData[0]);
  const [selectedClient, setSelectedClient] = useState(clientData[0]);
  const handleTeamSelection = useCallback(
    (value) => {
      const selectedTeam = teamData.find(
        (team) => team?.attributes?.name == value
      );
      setSelectedTeam(selectedTeam);
    },
    [teamData]
  );

  useEffect(() => {
    if (teamData.length > 0) {
      const teamOption = teamData.map((t) => t?.attributes?.name);
      setTeams(teamOption);

      setSelectedTeam(teamData[0]);
    }
  }, [teamData]);

  useEffect(() => {
    if (selectedTeam) {
      setTeamId(selectedTeam?.id);
      setVendorLocked(selectedTeam?.attributes?.vendor_locked);

      // if vendor locked, force vendor name value in fields
      if(selectedTeam?.attributes?.vendor_locked) {
        fields.vendor_name.newDefaultValue(selectedTeam.attributes.locked_vendor_name)
      } else {
        fields.vendor_name.newDefaultValue("")
      }
    }
  }, [selectedTeam, teamData]);

  useEffect(() => {
    if (clientData.length > 0) {
      const clientOption = clientData.map((c) => c?.attributes?.name);
      setClients(clientOption);

      setSelectedClient(clientData[0]);
    }
  }, [clientData]);

  useEffect(() => {
    if (selectedClient) {
      setClientId(selectedClient?.id);
      // setClientFieldsDefaults(selectedClient);
    }
  }, [selectedClient, clientData]);

  const setClientFieldsDefaults = useCallback((selectedClient) => {
    fields.customer_client_name.newDefaultValue(selectedClient.attributes.name);
    fields.customer_client_billing_street.newDefaultValue(selectedClient.attributes.billing_street);
    fields.customer_client_billing_city.newDefaultValue(selectedClient.attributes.billing_city);
    fields.customer_client_billing_state.newDefaultValue(convertStateName(selectedClient.attributes.billing_state, 2));
    fields.customer_client_billing_postal_code.newDefaultValue(selectedClient.attributes.billing_postal_code);
  }, [fields]);

  const basePaymentField = useField({
    value: ``,
    validates: [notEmpty("Base payment is required.")],
  });

  const { fields, submit, submitting, reset, submitErrors, makeClean, dirty } =
    useForm({
      fields: {
        // CUSTOMER ACCOUNT
        customer_client_name: useField({
          value: (stateClientId ? existingClientData.attributes.name : ""),
          validates: [notEmpty("Account name is required")],
        }),
        customer_client_billing_street: useField({
          value: (stateClientId ? existingClientData.attributes.billing_street : ""),
          validates: [notEmpty("Account street address is required")],
        }),
        customer_client_billing_city: useField({
          value: (stateClientId ? existingClientData.attributes.billing_city : ""),
          validates: [],
        }),
        customer_client_billing_state: useField({
          value: (stateClientId ? convertStateName(existingClientData.attributes.billing_state, 2) : undefined),
          validates: [],
        }),
        customer_client_billing_postal_code: useField({
          value: (stateClientId ? existingClientData.attributes.billing_postal_code : undefined),
          validates: [],
        }),

        // CUSTOMER CONTACT
        // ...(isAdmin && contactFields),

        // QUOTE/INVOICE DETAILS
        vendor_name: useField({
          value: "",
          validates: [notEmpty("Vendor name is required.")],
        }),
        invoice_number: useField({
          value: "",
          validates: [notEmpty("Quote number is required.")],
        }),
        invoice_amount: useField({
          value: ``,
          validates: [notEmpty("Quote amount is required.")],
        }),
        sales_tax: useField({
          value: ``,
          validates: [],
        }),

        // SHIPPING DETAILS
        location_address: useField({
          value: "",
          validates: [notEmpty("Shipping street is required.")],
        }),
        location_city: useField({
          value: "",
          validates: [],
        }),
        location_state: useField({
          value: "",
          validates: [],
        }),
        location_zip: useField({
          value: "",
          validates: [],
        }),
      },
      async onSubmit(form) {
        onConfirmationLoading(true);
        const payload = formPayload(form);

        setConfirmedClient(payload.customer_client);
        setConfirmedVendor(payload.vendor);

        setMatchedClient({});
        setSubmitMatchedClient(false);

        setMatchedVendor({});
        setSubmitMatchedVendor(false);

        return generateVendorPayment({
          vendorLocked: vendorLocked,
          teamId: teamId,
          clientId: stateClientId,
          ...payload,
        })
          .unwrap()
          .then((response) => {
            setVendorPaymentId(response.vendor_payment.id);
            const hasMatches = stateClientId ? false : handleConfirmResponseMatches(response);
            if (hasMatches) {
              if (stateClientId) {
                handleSubmitMatches();
              } else { handleOpenMatchModal(); }
            } else {
              handleSubmitPayloadAsEntered(payload, response.vendor_payment.id);
            }

            onConfirmationLoading(false);

            return { status: "success" };
          })
          .catch((error) => {
            onConfirmationLoading(false);
            console.log(error);
          });
      },
    });

  const formPayload = (form) => {
    const customer_client = {
      name: form.customer_client_name,
      billing_street: form.customer_client_billing_street,
      billing_city: form.customer_client_billing_city,
      billing_state: form.customer_client_billing_state,
      billing_postal_code: form.customer_client_billing_postal_code,
    };

    const vendor_payment = {
      base_payment: form?.base_payment ? form.base_payment : undefined,
      product_type: form.product_type,
      invoice_number: form.invoice_number,
      invoice_amount: form.invoice_amount,
      sales_tax: form.sales_tax,
      location_address: form.location_address,
      location_city: form.location_city,
      location_state: form.location_state,
      location_zip: form.location_zip,
    };
    const vendor = {
      name: form.vendor_name,
    };
    return {
      customer_client: { ...customer_client },
      vendor_payment: { ...vendor_payment },
      vendor: { ...vendor },
    };
  };

  useImperativeHandle(ref, () => ({
    handleSubmitForm() {
      submit();
    },
  }));

  const handleConfirmResponseMatches = (response) => {
    let requiresClientMatch = false,
      requiresVendorMatch = false;

    if (response.customer_client) {
      setMatchedClient(response.customer_client);
      setSubmitMatchedClient(true);
      requiresClientMatch = true;
    }
    if (response.vendor) {
      if (selectedTeam.attributes.vendor_locked) {
        setSubmitMatchedVendor(false);
        requiresVendorMatch = false;
      } else {
        setMatchedVendor(response.vendor);
        setSubmitMatchedVendor(true);
        requiresVendorMatch = true;
      }
    }
    if (response.vendor_payment) {
      setConfirmedVendorPayment(response.vendor_payment);
    }

    return requiresClientMatch || requiresVendorMatch;
  };

  const [activeMatchModal, setActiveMatchModal] = useState(false);
  const handleOpenMatchModal = () => {
    setActiveMatchModal(true);
  };
  const handleCloseModal = () => {
    setActiveMatchModal(false);
  };

  const handleClientSelectionChange = useCallback(
    (newChecked) => setSubmitMatchedClient(newChecked),
    []
  );
  const handleVendorSelectionChange = useCallback(
    (newChecked) => setSubmitMatchedVendor(newChecked),
    []
  );

  const buildMatchPayload = useCallback(() => {
    return {
      customer_client: submitMatchedClient ? matchedClient : confirmedClient,
      vendor: submitMatchedVendor ? matchedVendor : confirmedVendor,
    };
  }, [
    confirmedClient,
    confirmedVendor,
    matchedClient,
    matchedVendor,
    submitMatchedClient,
    submitMatchedVendor,
  ]);

  const handleSubmitPayloadAsEntered = (payload, passedVendorPaymentId = "") => {
    const payloadStructured = {
      customer_client: payload.customer_client,
      vendor: payload.vendor,
    };

    if(!passedVendorPaymentId) return

    return createVendorPaymentOpportunity({
      vendorPaymentId: passedVendorPaymentId,
      vendorLocked: vendorLocked,
      teamId: teamId,
      // clientId: clientId,
      ...payloadStructured,
    })
      .unwrap()
      .then((response) => {
        handleCloseModal();
        navigateToOpportunityShow(response.attributes.client_id, response.id);
        return { status: "success" };
      })
      .catch((error) => {
        handleCloseModal();
        handleErrorMessage(error);
      });
  };

  const handleSubmitMatches = useCallback(() => {
    const payload = buildMatchPayload();

    return createVendorPaymentOpportunity({
      vendorPaymentId: vendorPaymentId,
      vendorLocked: vendorLocked,
      teamId: teamId,
      // clientId: clientId,
      ...payload,
    })
      .unwrap()
      .then((response) => {
        handleCloseModal();
        navigateToOpportunityShow(response.attributes.client_id, response.id);
        return { status: "success" };
      })
      .catch((error) => {
        handleCloseModal();
        handleErrorMessage(error);
      });
  }, [
    buildMatchPayload,
    createVendorPaymentOpportunity,
    handleErrorMessage,
    navigateToOpportunityShow,
  ]);

  const matchModal = (
    <Modal
      open={activeMatchModal}
      onClose={handleCloseModal}
      title="Verify account and contact selection"
      primaryAction={{
        content: "Confirm",
        onAction: handleSubmitMatches,
      }}
    >
      <Modal.Section>
        <BlockStack gap="200">
          <Banner tone="info" title="Existing records found.">
            <p>
              We found existing records similar to what you entered. Please
              select the records you would like to use.
            </p>
          </Banner>
        </BlockStack>
      </Modal.Section>

      {matchedClient.attributes && (
        <Modal.Section>
          <BlockStack gap="200">
            <Text variant="headingMd" as="h2">Account details</Text>

            <LegacyStack alignment={"leading"} distribution={"fillEvenly"}>
              <LegacyStack.Item>
                <FormLayout>
                  <Text variant="headingSm" as="h3">Existing account</Text>
                  <Checkbox
                    label={
                      <>
                        <span>{matchedClient.attributes.name}</span>
                        <br />
                        <Text variant="bodySm" tone="subdued">
                          {matchedClient.attributes.billing_address ? (
                            <>
                              {matchedClient.attributes.billing_street}
                              <br />
                              {matchedClient.attributes.billing_city ||
                                "-"}, {matchedClient.attributes.billing_state}{" "}
                              {matchedClient.attributes.billing_postal_code}
                            </>
                          ) : (
                            "No billing address"
                          )}
                        </Text>
                      </>
                    }
                    checked={submitMatchedClient}
                    onChange={handleClientSelectionChange}
                  />
                </FormLayout>
              </LegacyStack.Item>

              <LegacyStack.Item>
                <FormLayout>
                  <Text variant="headingSm" as="h3">Entered account</Text>
                  <Checkbox
                    label={
                      <>
                        <span>{confirmedClient.name}</span>
                        <br />
                        <Text variant="bodySm" tone="subdued">
                          {confirmedClient.billing_street}
                          <br />
                          {confirmedClient.billing_city},{" "}
                          {confirmedClient.billing_state}{" "}
                          {confirmedClient.billing_postal_code}
                        </Text>
                      </>
                    }
                    checked={!submitMatchedClient}
                    onChange={(newChecked) =>
                      handleClientSelectionChange(!newChecked)
                    }
                  />
                </FormLayout>
              </LegacyStack.Item>
            </LegacyStack>
          </BlockStack>
        </Modal.Section>
      )}

      {matchedVendor.attributes && (
        <Modal.Section>
          <BlockStack gap="200">
            <Text variant="headingMd" as="h2">Vendor details</Text>

            <LegacyStack alignment={"leading"} distribution={"fillEvenly"}>
              <LegacyStack.Item>
                <FormLayout>
                  <Text variant="headingSm" as="h3">Existing vendor</Text>
                  <Checkbox
                    label={
                      <>
                        <span>{matchedVendor.attributes.name}</span>
                        <br />
                        <Text variant="bodySm" tone="subdued">Vendor</Text>
                      </>
                    }
                    checked={submitMatchedVendor}
                    onChange={handleVendorSelectionChange}
                  />
                </FormLayout>
              </LegacyStack.Item>

              <LegacyStack.Item>
                <FormLayout>
                  <Text variant="headingSm" as="h3">Entered vendor</Text>
                  <Checkbox
                    label={
                      <>
                        <span>{confirmedVendor.name}</span>
                        <br />
                        <Text variant="bodySm" tone="subdued">Vendor</Text>
                      </>
                    }
                    checked={!submitMatchedVendor}
                    disabled={true}
                    onChange={(newChecked) =>
                      handleVendorSelectionChange(!newChecked)
                    }
                  />
                </FormLayout>
              </LegacyStack.Item>
            </LegacyStack>
          </BlockStack>
        </Modal.Section>
      )}
    </Modal>
  );

  const ConfidenceBadge = (props) => {
    const { color, value } = props;
    return (
      (<Badge tone={color} size="small">
        <Text variant="bodySm" as="p">{value != 0 ? value : "--"}%</Text>
      </Badge>)
    );
  };
  ConfidenceBadge.propTypes = {
    color: PropTypes.string,
    value: PropTypes.number,
  };

  const labelAction = (attribute, field, isSelect = false) => {
    const { score, ocr_score, value } = attribute || {
      score: 0,
      ocr_score: 0,
      value: null,
    };
    const { iconCritical, iconWarning, iconSuccess, iconSubdued } = {
      iconCritical: "critical", // "#d72c0d",
      iconWarning: "warning", // "#ca9500",
      iconSuccess: "success", // "#007f5f",
      iconSubdued: "subdued", //"#8c9196",
    };

    const pipColor = () => {
      if (value === null) {
        return iconSubdued;
      }

      if (ocr_score <= 0.7 || score <= 0.5) {
        return iconCritical;
      }

      if (score > 0.5 && score <= 0.7) {
        return iconWarning;
      }

      if (ocr_score >= 0.9 && score >= 0.7) {
        return iconSuccess;
      }
    };

    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          paddingBottom: "8px",
        }}
      >
        <Tooltip
          content={
            score && value !== null
              ? `Fortify AI confidence score: ${Math.round(score * 100)}%`
              : `No value found for ${field}.`
          }
        >
          <ConfidenceBadge color={pipColor()} value={Math.round(score * 100)} />
        </Tooltip>
      </div>
    );
  };

  const completeFieldsProgress = (fields) => {
    let progress = 0;
    let completeFields = [];
    let fieldset = [];

    for (const [key, fieldValue] of Object.entries(fields)) {
      fieldset.push(key);
      if (fieldValue.value) {
        completeFields.push(key);
      }
    }

    progress =
      Math.round(
        (completeFields.length / Object.entries(fields).length) * 100
      ) || 0;

    return progress;
  };

  const [formProgress, setFormProgress] = useState(
    completeFieldsProgress(fields)
  );
  useEffect(() => {
    const progress = completeFieldsProgress(fields);
    setFormProgress(progress);
    return () => setFormProgress(0);
  }, [fields]);

  const FormProgressBar = ({ progress }) => {
    return <ProgressBar progress={progress} animated={false} />;
  };
  FormProgressBar.propTypes = {
    progress: PropTypes.number,
  };

  useEffect(() => {
    if(submitErrors.length > 0){
      console.log(submitErrors);
      console.dir(submitErrors);
    }
  }, [submitErrors]);

  const errorBanner =
    submitErrors.length > 0 ? (
      <Banner tone="critical">
        <p>
          There were some issues with your submission. Please correct the errors
          below.
        </p>
      </Banner>
    ) : null;

  const handleCopyAccountAddress = () => {
    fields.location_address.newDefaultValue(
      stateClientId ? existingClientData?.attributes?.billing_street :
      fields.customer_client_billing_street.value
    );
    fields.location_city.newDefaultValue(
      stateClientId ? existingClientData?.attributes?.billing_city :
      fields.customer_client_billing_city.value
    );
    fields.location_state.newDefaultValue(
      stateClientId ? convertStateName(existingClientData.attributes.billing_state, 2) :
      fields.customer_client_billing_state.value
    );
    fields.location_zip.newDefaultValue(
      stateClientId ? existingClientData?.attributes?.billing_postal_code :
      fields.customer_client_billing_postal_code.value
    );
  };

  const CardSectionTitle = (title) => (
    <Box paddingBlockEnd="300">
      <Text variant="headingSm" as="h3">
        {title}
      </Text>
    </Box>
  );

  return <>
    <Card padding="0">
      <Box padding="400">
        <BlockStack gap="200">
          {errorBanner}
          <Divider borderColor="transparent"/>
          <Text variant="bodySm" tone="subdued">
            {formProgress}% Complete
          </Text>
          <FormProgressBar progress={formProgress} />
        </BlockStack>
      </Box>
      {/* <Divider borderColor="border"/> */}
      {/* <Box padding="5"> */}
      {/*   {CardSectionTitle("Client")} */}
      {/*   <Select */}
      {/*     options={clients} */}
      {/*     value={selectedClient?.attributes?.name} */}
      {/*     onChange={handleClientSelection} */}
      {/*   /> */}
      {/* </Box> */}
      <Divider borderColor="border"/>
      <Box padding="400">
        {CardSectionTitle(vendorLocked ?
          "Team - Vendor locked" : "Team")}
        <Select
          options={teams}
          value={selectedTeam?.attributes?.name}
          onChange={handleTeamSelection}
        />
      </Box>
      <Divider borderColor="border"/>
      <Box padding="400">
        {CardSectionTitle("Account details")}
        <FormLayout>
          <TextField
            label={"Company name"}
            type={"text"}
            {...fields.customer_client_name}
            disabled={stateClientId || false}
            requiredIndicator
          />
          <TextField
            label={"Street address"}
            type={"text"}
            {...fields.customer_client_billing_street}
            disabled={stateClientId || false}
            requiredIndicator
          />
          {!stateClientId && (
            <>
              <TextField
                label={"City"}
                type={"text"}
                {...fields.customer_client_billing_city}
              />
              <Select
                label="State"
                placeholder={"Select state"}
                options={stateOptionsShort}
                {...fields.customer_client_billing_state}
              />
              <TextField
                label={"Zip code"}
                type={"text"}
                {...fields.customer_client_billing_postal_code}
              />
            </>
          )}
        </FormLayout>
      </Box>
      <Divider borderColor="border"/>
      <Box padding="400">
        {CardSectionTitle("Quote details")}
        <FormLayout>
          {!selectedTeam?.attributes?.vendor_locked && (
            <TextField
              label={"Vendor name"}
              type={"text"}
              {...fields.vendor_name}
              requiredIndicator
              disabled={selectedTeam?.attributes?.vendor_locked}
            />
          )}
          <TextField
            label={"Quote number"}
            type={"text"}
            {...fields.invoice_number}
            requiredIndicator
          />
          <TextField
            label={"Quote total amount"}
            type={"text"}
            {...fields.invoice_amount}
            requiredIndicator
          />
          <TextField
            label={"Sales tax"}
            type={"text"}
            {...fields.sales_tax}
          />
        </FormLayout>
      </Box>
      <Divider borderColor="border"/>
      <Box padding="400">
        {CardSectionTitle("Shipping details")}
        <FormLayout>
          <Button onClick={handleCopyAccountAddress}>
            Copy from Account Details
          </Button>
          <TextField
            label={"Shipping street"}
            type={"text"}
            {...fields.location_address}
            requiredIndicator
          />
          <TextField
            label={"Shipping city"}
            type={"text"}
            {...fields.location_city}
          />
          <Select
            label="Shipping state"
            placeholder={"Select state"}
            options={stateOptionsShort}
            {...fields.location_state}
          />
          <TextField
            label={"Zip code"}
            type={"text"}
            {...fields.location_zip}
          />
        </FormLayout>
      </Box>
    </Card>
    {matchModal}
  </>;
});
CreateOpportunityForm.displayName = "CreateOpportunityForm";

CreateOpportunityForm.propTypes = {
  handleErrorMessage: PropTypes.func,
  onConfirmationLoading: PropTypes.func,
  stateClientId: PropTypes.string,
  existingClientData: PropTypes.object,
};

export default CreateOpportunityForm;
