import React from "react";
import PropTypes from "prop-types";
import { Modal, BlockStack, Text } from "@shopify/polaris";

const DisplayTermsModal = (props) => {
  const {
    showDisplayTermsModal,
    toggleShowDisplayTermsModal,
    financingOption,
  } = props;

  return (
    <Modal
      open={showDisplayTermsModal}
      title={"Term details"}
      onClose={toggleShowDisplayTermsModal}
      secondaryActions={[
        {
          content: "Close",
          onAction: toggleShowDisplayTermsModal,
        },
      ]}
    >
      <Modal.Section>
        {financingOption.attributes.financing_type === "Net terms" && (
          <BlockStack gap="200">
            <Text variant="headingMd" as="h4">
              Net terms
            </Text>
            <Text>
              Payment will be due on the installment payment start date,
              according to the selected schedule.
            </Text>
          </BlockStack>
        )}

        {!(financingOption.attributes.financing_type === "Net terms") &&
          financingOption.attributes.approved === false &&
          !financingOption.attributes.buy_rate && (
            <BlockStack gap="200">
              <Text variant="headingMd" as="h4">
                Estimate
              </Text>
              <Text>
                Based on an average scoring of companies in your industry, for
                like product and transaction size, this is an estimated payment
                you can use for budgetary purposes. You can assume this to be
                your payment for this term and structure give or take
                $100/month.
              </Text>
            </BlockStack>
          )}

        {financingOption.attributes.approved === false &&
          financingOption.attributes.buy_rate && (
            <BlockStack gap="200">
              <Text variant="headingMd" as="h4">
                Pre-approved
              </Text>
              <Text>TBD</Text>
            </BlockStack>
          )}


        {!(financingOption.attributes.financing_type === "Net terms") &&
          (financingOption.attributes.approved === true || financingOption.attributes.buy_rate) && (
            <BlockStack gap="200">
              <Text variant="headingMd" as="h4">
                Approved
              </Text>
              <Text>
                These are approved terms for your consideration. No additional
                information is needed for review. Once you select an option and
                an authorized signatory is confirmed we will send out finance
                documents for review and execution.
              </Text>
            </BlockStack>
          )}
      </Modal.Section>
    </Modal>
  );
};

DisplayTermsModal.propTypes = {
  showDisplayTermsModal: PropTypes.bool,
  toggleShowDisplayTermsModal: PropTypes.func,
  financingOption: PropTypes.object,
  opportunityId: PropTypes.string,
  opportunity: PropTypes.object,
};

export default DisplayTermsModal;
