import React, { useState, useEffect } from "react";

import {
  useField,
  useForm,
  notEmpty,
  asChoiceField,
} from "@shopify/react-form";

import {
  Modal,
  FormLayout,
  RadioButton,
  Select,
  TextField,
} from "@shopify/polaris";

import PropTypes from "prop-types";

import { isDateNotPast, convertServerDate } from "../../../utilities";

import { useUpdateOpportunityTransactionMutation } from "../../../services/api";

import DatePickerPopUp from "../../../components/DatePickerPopUp";

const EditTransactionModal = (props) => {
  const {
    transactionId,
    transaction = {},
    opportunityId,
    showEditTransactionModal,
    toggleEditTransactionModal,
    setToastMessage,
    setShowToast,
    isVendorAdmin,
    isOrganizationAdmin,
  } = props;

  const [updateTransaction, { isLoading: isUpdatingTransaction }] =
    useUpdateOpportunityTransactionMutation();

  const payableDetailOptions = [
    {
      label: "Vendor Payout - Net terms installment payment",
      value: "Vendor Payout - Net terms installment payment",
    },
    {
      label: "Vendor Payout - Down Payment",
      value: "Vendor Payout - Down Payment",
    },
    {
      label: "Vendor Payout - Early Commencement",
      value: "Vendor Payout - Early Commencement",
    },
    {
      label: "Vendor Payout - Full",
      value: "Vendor Payout - Full",
    },
    {
      label: "Vendor Payout - Progress Payment",
      value: "Vendor Payout - Progress Payment",
    },
    {
      label: "Expense - Progress Payment Interest Cost",
      value: "Expense - Progress Payment Interest Cost",
    },
    {
      label: "Expense - UCC filing",
      value: "Expense - UCC filing",
    },
    {
      label: "Expense - On-site inspection",
      value: "Expense - On-site inspection",
    },
    {
      label: "Expense - Lien search",
      value: "Expense - Lien search",
    },
    {
      label: "Expense - Lender doc fee",
      value: "Expense - Lender doc fee",
    },
    {
      label: "Expense - Transaction expense",
      value: "Expense - Transaction expense",
    },
    {
      label: "Fee - Vendor Referral",
      value: "Fee - Vendor Referral",
    },
    {
      label: "Fee - Associate Referral",
      value: "Fee - Associate Referral",
    },
    {
      label: "Fee - Commission or Referral",
      value: "Fee - Commission or Referral",
    },
    {
      label: "Fee - LoC interest",
      value: "Fee - LoC interest",
    },
    {
      label: "Fee - Other",
      value: "Fee - Other",
    },
    {
      label: "Fee - Other fee to Lender",
      value: "Fee - Other fee to Lender",
    },
  ];

  const scheduledPayableDetails = [
    "Vendor Payout - Net terms installment payment".toLowerCase(),
    "Vendor Payout - Down Payment".toLowerCase(),
  ];

  const vendorAdminPayableDetailOptions = [
    {
      label: "Vendor Payout - Net terms installment payment",
      value: "Vendor Payout - Net terms installment payment",
    },
    {
      label: "Vendor Payout - Down Payment",
      value: "Vendor Payout - Down Payment",
    },
  ];

  const receivableDetailOptions = [
    {
      label: "Customer Charge - Net terms installment payment",
      value: "Customer Charge - Net terms installment payment",
    },
    {
      label: "Customer Charge - Base Payment",
      value: "Customer Charge - Base Payment",
    },
    {
      label: "Customer Charge - Advance Payment",
      value: "Customer Charge - Advance Payment",
    },
    {
      label: "Customer Charge - Pro Rata",
      value: "Customer Charge - Pro Rata",
    },
    {
      label: "Lender Charge - Bank Fund Out",
      value: "Lender Charge - Bank Fund Out",
    },
    {
      label: "Customer Charge - Customer Charge",
      value: "Customer Charge - Customer Charge",
    },
    {
      label: "Customer Charge - Commitment Deposit",
      value: "Customer Charge - Commitment Deposit",
    },
    {
      label: "Customer Charge - Progress Payment",
      value: "Customer Charge - Progress Payment",
    },
    {
      label: "Vendor Charge - Subsidy",
      value: "Vendor Charge - Subsidy",
    },
    {
      label: "Fee - Origination Fee from Customer",
      value: "Fee - Origination Fee from Customer",
    },
    {
      label: "Fee - Origination Fee from Lender",
      value: "Fee - Origination Fee from Lender",
    },
    {
      label: "Fee - Doc fee",
      value: "Fee - Doc fee",
    },
    {
      label: "Fee - Late fee",
      value: "Fee - Late fee",
    },
    {
      label: "Fee - NSF",
      value: "Fee - NSF",
    },
    {
      label: "Fee - Other",
      value: "Fee - Other",
    },
  ];

  const {
    fields,
    submit: submitUpdateTransaction,
    submitting,
    reset: resetEditTransactionForm,
    submitErrors,
    makeClean,
    dirty: editTransactionFormDirty,
  } = useForm({
    fields: {
      amount: useField({
        value: transaction ? transaction.attributes.amount : "",
        validates: [notEmpty("Transaction amount is required")],
      }),
      detail: useField({
        value: transaction ? transaction.attributes.detail : "",
        validates: [notEmpty("Transaction detail is required")],
      }),
      transaction_type: useField({
        value: transaction ? transaction.attributes.transaction_type : "",
        validates: [notEmpty("Transaction type is required")],
      }),
      scheduled_date: useField({
        value: transaction?.attributes.scheduled_date || "",
        // set required when details are installment payment or downpayment
        validates: scheduledPayableDetails.includes(
          transaction?.attributes?.detail?.toLowerCase()
        )
          ? [
              notEmpty("Scheduled date is required"),
              (value) => {
                if (!isOrganizationAdmin && value && !isDateNotPast(value)) {
                  return "Scheduled date cannot be in the past";
                }
              },
            ]
          : [],
      }),
      completed_at: useField({
        value: transaction
          ? transaction.attributes.completed_at
            ? new Date(
                convertServerDate(transaction.attributes.completed_at)
              ).toLocaleDateString("en-CA")
            : ""
          : "",
        validates: [],
      }),
    },
    async onSubmit(form) {
      return updateTransaction({
        opportunityId: opportunityId,
        id: transactionId,
        ...form,
      })
        .unwrap()
        .then(() => {
          if (showEditTransactionModal) toggleEditTransactionModal();
          setShowConfirmModal(false);

          resetEditTransactionForm();

          setToastMessage("Transaction updated");
          setShowToast(true);

          return { status: "success" };
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const toggleShowConfirmModal = () => {
    setShowConfirmModal(!showConfirmModal);
  };

  const handleCloseModal = (modalToClose = "edit") => {
    if (modalToClose == "edit") {
      resetEditTransactionForm();
    }
    toggleEditTransactionModal();
    setShowConfirmModal(false);
  };

  const confirmModal = (
    <Modal
      open={showConfirmModal}
      onClose={() => handleCloseModal("confirm")}
      title="Confirm schedule transaction"
      primaryAction={{
        content: "Confirm",
        loading: isUpdatingTransaction,
        onAction: () => {
          submitUpdateTransaction();
        },
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => handleCloseModal("confirm"),
        },
      ]}
    >
      <Modal.Section>
        <p>
          {`Are you sure? Scheduling this transaction will notify the ${
            transaction?.attributes?.detail?.includes("Lender") ? "" : "customer and"
          } vendor admin of the transaction schedule date.`}
        </p>
      </Modal.Section>
    </Modal>
  );

  const submitOrShowConfirmModal = () => {
    if (
      editTransactionFormDirty &&
      transaction.attributes.status == "pending" &&
      fields.scheduled_date
    ) {
      // Show confirm modal first if transitioning from pending to scheduled
      toggleEditTransactionModal();
      toggleShowConfirmModal();
    } else {
      submitUpdateTransaction();
    }
  };

  const editModal = showEditTransactionModal && (
    <Modal
      open={toggleEditTransactionModal}
      onClose={() => handleCloseModal("edit")}
      title={"Edit transaction"}
      primaryAction={{
        content: "Edit transaction",
        onAction: submitOrShowConfirmModal,
        disabled:
          !editTransactionFormDirty || transaction
            ? transaction.attributes.status === "completed"
            : false,
        loading: submitting,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => handleCloseModal("edit"),
        },
      ]}
    >
      {!isVendorAdmin && (
        <Modal.Section>
          <FormLayout>
            <FormLayout.Group>
              <RadioButton
                label="Payable"
                id="payable"
                name="payable"
                helpText="FORT Capital is making a payment."
                {...asChoiceField(fields.transaction_type, "payable")}
                disabled
              />

              <RadioButton
                label="Receivable"
                id="receivable"
                name="receivable"
                helpText="FORT Capital is receiving a payment."
                {...asChoiceField(fields.transaction_type, "receivable")}
                disabled
              />
            </FormLayout.Group>
          </FormLayout>
        </Modal.Section>
      )}

      <Modal.Section>
        <FormLayout>
          {fields.transaction_type.value == "payable" && (
            <Select
              label="Transaction type"
              options={payableDetailOptions}
              placeholder="Select"
              {...fields.detail}
              disabled
            />
          )}

          {fields.transaction_type.value == "receivable" && (
            <Select
              label="Transaction type"
              options={receivableDetailOptions}
              placeholder="Select"
              {...fields.detail}
              disabled
            />
          )}

          <div className="FortForm__NumericTextField">
            <TextField
              autoComplete="off"
              label="Amount"
              prefix={"$"}
              type="number"
              {...fields.amount}
              disabled={
                transaction
                  ? transaction.attributes.status === "completed"
                  : false
              }
            />
          </div>

          <div>
            <DatePickerPopUp
              datePickerLabel={"Scheduled date"}
              placeholder="mm/dd/yyyy"
              setEditingDate={(rubyStringDate) => {
                fields.scheduled_date.onChange(rubyStringDate);
              }}
              initialDate={fields.scheduled_date}
              disabled={
                transaction
                  ? transaction.attributes.status === "completed"
                  : false
              }
            />
          </div>

          <div>
            <DatePickerPopUp
              datePickerLabel={"Completed date"}
              placeholder="mm/dd/yyyy"
              setEditingDate={(rubyStringDate) => {
                fields.completed_at.onChange(rubyStringDate);
              }}
              initialDate={fields.completed_at}
              disabled={
                transaction
                  ? transaction.attributes.status === "completed"
                  : false
              }
            />
          </div>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  return (
    <>
      {editModal}
      {confirmModal}
    </>
  );
};

export default EditTransactionModal;

EditTransactionModal.propTypes = {
  transactionId: PropTypes.string.isRequired,
  transaction: PropTypes.object.isRequired,
  opportunityId: PropTypes.string.isRequired,
  showEditTransactionModal: PropTypes.bool.isRequired,
  toggleEditTransactionModal: PropTypes.func.isRequired,
  setToastMessage: PropTypes.func.isRequired,
  setShowToast: PropTypes.func.isRequired,
  isVendorAdmin: PropTypes.bool.isRequired,
  isOrganizationAdmin: PropTypes.bool.isRequired,
};
