import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";

import { Card, Page, Layout } from "@shopify/polaris";
import { isMobile } from "react-device-detect";

import AdminOpportunityTable from "../../../components/Admin/Opportunity/Table";
import FadeIn from "../../../components/FadeIn";
import LoadingScreen from "../../../components/Auth/Loading";
import AttachmentUpload from "../../../components/VendorPayment/AttachmentUpload";
import DropQuoteCard from "../../../components/DropQuoteCard";

import { CurrentContactContext } from "../../../contexts/Contact";

const Opportunities = () => {
  const history = useHistory();

  const { currentContact } = useContext(CurrentContactContext)

  const handleOpportunityClick = (opportunity) => {
    const clientId = opportunity.attributes.client_id;
    history.push({
      pathname: `/clients/${clientId}/opportunities/${opportunity.id}`,
      state: { opportunity: { ...opportunity } },
    });
  };

  const [isDraggingInvoice, setIsDraggingInvoice] = useState(false);
  const [hasDroppedInvoice, setHasDroppedInvoice] = useState(false);
  const handleInvoiceDragOver = () => {
    setIsDraggingInvoice(true);
  };
  const handleInvoiceDragEnter = () => {
    setIsDraggingInvoice(true);
  };
  const handleInvoiceDragLeave = () => {
    setIsDraggingInvoice(false);
  };
  const handleInvoiceDrop = () => {
    setHasDroppedInvoice(true);
  };
  const navigateToConfirmInvoice = (vendorPayment) => {
    history.push(`/invoices/${vendorPayment.id}/confirm`);
  };
  const handleInvoiceUploadSuccess = (vendorPayment) => {
    navigateToConfirmInvoice(vendorPayment);
  };

  const PageMarkup = (
    <>
      {isDraggingInvoice && (
        <FadeIn fadeIn={isDraggingInvoice}>
          <div
            style={{
              height: "calc(100vh - 5.6rem)",
              width: "99.9%",
              padding: "20px",
            }}
          >
            <AttachmentUpload
              onDragEnter={handleInvoiceDragEnter}
              onDragLeave={handleInvoiceDragLeave}
              onDrop={handleInvoiceDrop}
              onUploadSuccess={handleInvoiceUploadSuccess}
            >
              <div
                style={{
                  height: "calc(100vh - 182px)",
                  position: "relative",
                  bottom: "calc(100vh - 150px)",
                }}
              ></div>
            </AttachmentUpload>
          </div>
        </FadeIn>
      )}

      {!isDraggingInvoice && (
        <FadeIn fadeIn={!isDraggingInvoice}>
          <Page fullWidth title="Opportunities">
            <Layout>
              <Layout.Section>
                <DropQuoteCard
                  onDrop={handleInvoiceDrop}
                  onUploadSuccess={handleInvoiceUploadSuccess}
                  dropzone={
                    <AttachmentUpload
                      onDragOver={handleInvoiceDragOver}
                      isWithinCard
                    />
                  }
                />

                {!isMobile && (
                  <div style={{ display: "none" }}>
                    <AttachmentUpload onDragOver={handleInvoiceDragOver} />
                  </div>
                )}
              </Layout.Section>

              <Layout.Section>
                <Card>
                  <AdminOpportunityTable
                    onOpportunityClick={handleOpportunityClick}
                  />
                </Card>
              </Layout.Section>
            </Layout>
          </Page>
        </FadeIn>
      )}
    </>
  );

  return currentContact ? PageMarkup : <LoadingScreen />;
};

export default Opportunities;
