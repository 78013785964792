import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import {
  Avatar,
  Card,
  Filters,
  ResourceList,
  ResourceItem,
  Text,
  LegacyStack,
  Pagination,
  EmptyState,
  Truncate,
} from "@shopify/polaris";

import { useGetAdminAccountTeamsQuery } from "../../services/api";

const TeamList = (props) => {
  const resourceName = {
    singular: "team",
    plural: "teams",
  };

  const history = useHistory();
  const navigateTo = (path) => {
    history.push(path);
  };

  const {
    vendorId,
    selectable,
    filter
  } = props;

  const [selectedItems, setSelectedItems] = useState([]);
  const [queryValue, setQueryValue] = useState(undefined);
  const [filteredItems, setFilteredItems] = useState([]);

  const handleQueryValueRemove = useCallback(() => setQueryValue(undefined), []);
  const handleQueryChange = (value) => setQueryValue(value);

  const { data: teams = [], isLoading: isLoadingTeams } = useGetAdminAccountTeamsQuery(vendorId, { skip: !vendorId });

  const filterControl = teams.length ? (
    <Filters
      filters={[]}
      queryValue={queryValue}
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryValueRemove}
    />
  ) : undefined;

  useEffect(() => {
    if (queryValue) {
      const results = teams.filter((item) => {
        return (
          item.attributes.name
            .toLowerCase()
            .includes(queryValue.toLowerCase())
        );
      });
      setFilteredItems(results);
    } else {
      if (!teams) {
        setFilteredItems([]);
      } else {
        setFilteredItems(teams);
      }
    }
  }, [queryValue, teams]);

  const [currentPage, setCurrentPage] = useState(1);
  const handleNextPage = () => {
    setCurrentPage((state) => {
      if (state == paginatedFilteredItems.length) {
        return paginatedFilteredItems.length;
      }
      return state + 1;
    });
  };
  const handlePreviousPage = () => {
    setCurrentPage((state) => {
      if (state == 1) {
        return 1;
      }
      return state - 1;
    });
  };

  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [paginatedFilteredItems, setPaginatedFilteredItems] = useState([]);
  const [currentPageItems, setCurrentPageItems] = useState([]);
  useEffect(() => {
    const perPage = itemsPerPage;

    const paginatedFilteredItems = filteredItems.reduce(
      (resultArray, item, index) => {
        const pageIndex = Math.floor(index / perPage);
        if (!resultArray[pageIndex]) {
          resultArray[pageIndex] = [];
        }
        resultArray[pageIndex].push(item);
        return resultArray;
      },
      []
    );

    setPaginatedFilteredItems(paginatedFilteredItems);
  }, [filteredItems, itemsPerPage]);

  useEffect(() => {
    const pageIndex = currentPage - 1;
    const currentPageItems = paginatedFilteredItems[pageIndex] || [];
    setCurrentPageItems(currentPageItems);
  }, [paginatedFilteredItems, currentPage]);

  const navigateToCreditSubmissionNew = () => {
    history.push({
      pathname: `/opportunities/${opportunityId}/credit_submissions/new`,
      state: { opportunity: { ...opportunity } },
    });
  };

  const onTeamClick = (team) => {
    history.push({
      pathname: `/admin/teams/${team.id}`,
      state: { team: { ...team } },
    });
  }

  const paginationMarkup = (
    <Pagination
      hasPrevious={currentPage != 1}
      onPrevious={handlePreviousPage}
      label={`Page ${currentPage} of ${paginatedFilteredItems.length}`}
      hasNext={currentPage < paginatedFilteredItems.length}
      onNext={handleNextPage}
    />
  );

  const emptyStateMarkup = !teams.length ? (
    <EmptyState>
      <p>
        This account has no teams.
      </p>
    </EmptyState>
  ) : undefined;

  const teamsResourceList = (
    <ResourceList
      resourceName={resourceName}
      items={currentPageItems}
      renderItem={(item, id, index) => renderItem(item, id, index)}
      selectedItems={selectedItems}
      onSelectionChange={setSelectedItems}
      emptyState={emptyStateMarkup}
      filterControl={filter ? filterControl : false}
      loading={isLoadingTeams}
      selectable={selectable}
    />
  );

  const renderItem = (item, id, index) => {
    const {
      name
    } = item.attributes;

    return (
      <ResourceItem
        id={id}
        media={<Avatar customer size="sm" name={'namename'} />}
        onClick={() => onTeamClick(item)}
        accessibilityLabel={`View details for contact`}
        verticalAlignment="center"
      >
        <LegacyStack spacing="tight" alignment="center" wrap={false}>
          <LegacyStack.Item fill>
            <h3 style={{ maxWidth: "320px" }}>
              <Text as="span" fontWeight="semibold" >
                <Truncate>{name}</Truncate>
              </Text>
            </h3>
          </LegacyStack.Item>
        </LegacyStack>
      </ResourceItem>
    );
  };

  return (
    <>
      {teamsResourceList}
      {paginatedFilteredItems.length > 1 && (
        <Card>
          <LegacyStack distribution={"center"}>{paginationMarkup}</LegacyStack>
        </Card>
      )}
    </>
  );
};

TeamList.propTypes = {
  vendorId: PropTypes.string,
  selectable: PropTypes.bool,
  filter: PropTypes.bool,
};

export default TeamList;
