import React, { useState, useEffect } from "react";
import {
  ResourceList,
  Box,
  Text,
  InlineStack,
  Button,
  Avatar,
  ResourceItem,
  Modal,
  Spinner,
  InlineGrid,
  Link,
} from "@shopify/polaris";

import { ViewIcon, HideIcon, DeleteIcon } from "@shopify/polaris-icons";
import PropTypes from "prop-types";

import {
  useRemoveGuarantorsMutation,
  useGetContactQuery,
} from "../../services/api";

import { useToast } from "../../contexts/Toast";

interface GuarantorAttributes {
  name: string;
  is_individual: boolean;
  entity_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  birth_date: string;
  ssn: string;
}

interface Guarantor {
  id: string;
  attributes: GuarantorAttributes;
}

interface GuarantorListProps {
  guarantors: Guarantor[];
  isLoading: boolean;
  opportunityId: string;
  onAddGuarantor: () => void;
}

const GuarantorList: React.FC<GuarantorListProps> = ({
  guarantors,
  isLoading,
  opportunityId,
}) => {
  const { showToastWithMessage } = useToast();

  const [guarantorContactId, setGuarantorContactId] = useState<string | null>(
    null
  );

  const {
    data: currentGuarantorContact,
    isLoading: isLoadingGuarantorContact,
  } = useGetContactQuery(guarantorContactId, { skip: !guarantorContactId }) as {
    data: Guarantor;
    isLoading: boolean;
    refetch: () => void;
  };

  const [removeGuarantors, { isLoading: isRemovingGuarantor }] =
    useRemoveGuarantorsMutation();

  const [showGuarantorContactSpinner, setShowGuarantorContactSpinner] =
    useState(false);

  const [showSSN, setShowSSN] = useState(false);

  useEffect(() => {
    setShowGuarantorContactSpinner(false);
  }, [currentGuarantorContact]);

  const [showGuarantorContactFormModal, setShowGuarantorContactFormModal] =
    useState(false);
  const toggleShowGuarantorContactModal = () => {
    setShowGuarantorContactFormModal(!showGuarantorContactFormModal);
  };
  const openGuarantorContactModal = (id) => {
    if (currentGuarantorContact?.id == id)
      setShowGuarantorContactSpinner(false);
    else setShowGuarantorContactSpinner(true);

    setShowGuarantorContactFormModal(true);
    setGuarantorContactId(id);
  };
  const closeGuarantorContactModal = () => {
    setGuarantorContactId(null);
    toggleShowGuarantorContactModal();
    setShowGuarantorContactSpinner(true);
  };

  const removeSelectedGuarantors = (guarantorId: string) => {
    return removeGuarantors({
      opportunityId: opportunityId,
      body: [guarantorId],
    })
      .unwrap()
      .then(() => {
        showToastWithMessage("Guarantor removed");

        return { status: "success" };
      })
      .catch((error) => {
        alert("Failed to remove guarantor.");
        console.log(error);
      });
  };

  const guarantorsEmptyStateSideCard = (
    <Box padding="400">
      <InlineStack gap="200" align="center">
        <Text as="span" tone="subdued">
          No guarantors.
        </Text>
      </InlineStack>
    </Box>
  );

  const guarantorContactFormModal = showGuarantorContactFormModal && (
    <Modal
      open={showGuarantorContactFormModal}
      onClose={closeGuarantorContactModal}
      title="Contact Details"
    >
      <Modal.Section>
        {!guarantorContactId ||
        showGuarantorContactSpinner ||
        isLoadingGuarantorContact ? (
          <div
            style={{
              position: "relative",
              minHeight: "118px",
              width: "100%",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "32px",
                left: "calc(50% - 22px)",
              }}
            >
              <Spinner accessibilityLabel="Spinner example" size="large" />
            </div>
          </div>
        ) : (
          <>
            <InlineGrid columns={2}>
              <div>
                <Text variant="bodyMd" as="p" fontWeight="bold">
                  <Text as="span">First name</Text>
                </Text>
                <p>
                  {currentGuarantorContact?.attributes?.first_name
                    ? currentGuarantorContact?.attributes?.first_name
                    : "--"}
                </p>
              </div>

              <div>
                <Text variant="bodyMd" as="p" fontWeight="bold">
                  <Text as="span">Last name</Text>
                </Text>
                <p>
                  {currentGuarantorContact?.attributes?.last_name
                    ? currentGuarantorContact?.attributes?.last_name
                    : "--"}
                </p>
              </div>
            </InlineGrid>

            <br />

            <InlineStack>
              <div>
                <Text variant="bodyMd" as="p" fontWeight="bold">
                  <Text as="span">Email</Text>
                </Text>
                <p>
                  {currentGuarantorContact?.attributes?.email
                    ? currentGuarantorContact?.attributes?.email
                    : "--"}
                </p>
              </div>
            </InlineStack>

            <br />

            <InlineStack>
              <div>
                <Text variant="bodyMd" as="p" fontWeight="bold">
                  <Text as="span">Phone number</Text>
                </Text>
                <p>
                  {currentGuarantorContact?.attributes?.phone
                    ? currentGuarantorContact?.attributes?.phone
                    : "--"}
                </p>
              </div>
            </InlineStack>

            <br />

            <InlineStack>
              <div>
                <Text variant="bodyMd" as="p" fontWeight="bold">
                  <Text as="span">Birth date</Text>
                </Text>
                <p>
                  {currentGuarantorContact?.attributes?.birth_date
                    ? currentGuarantorContact?.attributes?.birth_date
                    : "--"}
                </p>
              </div>
            </InlineStack>

            <br />

            <InlineStack>
              <div>
                <Text variant="bodyMd" as="p" fontWeight="bold">
                  <InlineStack>
                    <Text as="span">SSN</Text>

                    {currentGuarantorContact?.attributes?.ssn && !showSSN && (
                      <Button
                        variant="plain"
                        onClick={() => {
                          setShowSSN(true);
                        }}
                        icon={ViewIcon}
                      />
                    )}
                    {currentGuarantorContact?.attributes?.ssn && showSSN && (
                      <Button
                        variant="plain"
                        onClick={() => {
                          setShowSSN(false);
                        }}
                        icon={HideIcon}
                      />
                    )}
                  </InlineStack>
                </Text>
                <p>
                  {currentGuarantorContact?.attributes?.ssn &&
                    !showSSN &&
                    "•••••••••"}
                  {currentGuarantorContact?.attributes?.ssn &&
                    showSSN &&
                    currentGuarantorContact?.attributes?.ssn}
                  {!currentGuarantorContact?.attributes?.ssn && "--"}
                </p>
              </div>
            </InlineStack>
          </>
        )}
      </Modal.Section>
    </Modal>
  );

  const [showGuarantorDeleteButton, setShowGuarantorDeleteButton] =
    useState("");
  const [showConfirmRemoveGuarantorModal, setShowConfirmRemoveGuarantorModal] =
    useState("");

  const confirmRemoveGuarantorModal = (
    <Modal
      open={showConfirmRemoveGuarantorModal !== ""}
      onClose={() => setShowConfirmRemoveGuarantorModal("")}
      primaryAction={{
        content: "Remove",
        onAction: () => {
          const id = showConfirmRemoveGuarantorModal;
          removeSelectedGuarantors(id)
            .then(() => {
              setShowConfirmRemoveGuarantorModal("");
            })
            .catch((error) => {
              console.error("Failed to remove guarantor:", error);
            });
        },
        disabled: isRemovingGuarantor,
        loading: isRemovingGuarantor,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => setShowConfirmRemoveGuarantorModal(""),
        },
      ]}
      title="Remove guarantor"
    >
      <Modal.Section>
        <Text as="p">Are you sure you want to remove this guarantor?</Text>
      </Modal.Section>
    </Modal>
  );

  const renderGuarantorItem = (attributes: GuarantorAttributes, id: string) => {
    const { name, is_individual, entity_id } = attributes;

    const icon = <Avatar customer={is_individual} size="sm" name={name} />;

    return (
      <ResourceItem
        id={id}
        media={icon}
        accessibilityLabel={`View details for guarantor`}
        verticalAlignment="center"
        onMouseOver={() => setShowGuarantorDeleteButton(id)}
        onMouseOut={() => setShowGuarantorDeleteButton("")}
        onClick={() => {}}
      >
        <InlineGrid columns="1fr auto">
          <h3>
            <Text as="span" fontWeight="semibold">
              <Link
                onClick={() => {
                  if (is_individual) openGuarantorContactModal(entity_id);
                }}
                monochrome
                removeUnderline
              >
                {name}
              </Link>
            </Text>
          </h3>

          {showGuarantorDeleteButton === id && (
            <Button
              variant="plain"
              icon={DeleteIcon}
              onClick={() => setShowConfirmRemoveGuarantorModal(id)}
            />
          )}
        </InlineGrid>
      </ResourceItem>
    );
  };

  return (
    <>
      <ResourceList
        resourceName={{
          singular: "guarantor",
          plural: "guarantors",
        }}
        emptyState={guarantorsEmptyStateSideCard}
        items={guarantors}
        renderItem={(item) => renderGuarantorItem(item.attributes, item.id)}
        loading={isLoading}
        // selectedItems={selectedGuarantors}
        // onSelectionChange={(selectedItems) =>
        //   setSelectedGuarantors(selectedItems as string[])
        // }
        // alternateTool={
        //   <Button onClick={handleAddGuarantor}>Add Guarantor</Button>
        // }
        // promotedBulkActions={guarantorBulkActions}
        // selectable
      />
      {guarantorContactFormModal}
      {confirmRemoveGuarantorModal}
    </>
  );
};

GuarantorList.propTypes = {
  guarantors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default GuarantorList;
