import React, { useEffect, useState } from "react";
import http from "../../http-common";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import LoadingScreen from "../../components/Auth/Loading";
import { returnPublicRoutes } from "../../services/utility";

const AxiosInterceptorContext = React.createContext({ isTokenSet: false });

const setAxiosTokenInterceptor = async (accessToken) => {
  http.interceptors.request.use(async (config) => {
    if (accessToken) {
      if (config.headers) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      } else {
        config.headers = {
          Authorization: `Bearer ${accessToken}`,
        };
      }
    }
    return config;
  });
};

export const useAxiosInterceptor = () => {
  const context = React.useContext(AxiosInterceptorContext);
  if (context === undefined) {
    throw new Error(
      "useAxiosInterceptor must be used within an AxiosInterceptorProvider"
    );
  }
  return context;
};

export const AxiosInterceptorProvider = ({ children }) => {
  const {
    isAuthenticated,
    isLoading: isLoadingAuth,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();
  const [isTokenSet, setIsTokenSet] = useState(false);
  const [isLoadingToken, setIsLoadingToken] = useState(true);
  const [isPublicRoute, setIsPublicRoute] = useState(false);

  useEffect(() => {
    const setToken = async () => {
      setIsLoadingToken(true);
      try {
        const accessToken = await getAccessTokenSilently();
        setAxiosTokenInterceptor(accessToken);
        setIsTokenSet(true);
        setIsLoadingToken(false);
      } catch (e) {
        console.log("Caught auth0 getAccessToken error: ", e);
        if (e.error === "login_required") {
          loginWithRedirect();
        }
        if (e.error === "consent_required") {
          loginWithRedirect();
        }
        if (e.error === "invalid_grant") {
          loginWithRedirect();
        }
        throw e;
      }
    };

    if (isAuthenticated) {
      setToken();
    }

    // Make public routes public (/share, /section-179-calculator)
    if (returnPublicRoutes()) {
      setIsPublicRoute(true);
      setIsLoadingToken(false);
    }
  }, [isAuthenticated, getAccessTokenSilently, loginWithRedirect]);

  useEffect(() => {}, [
    isLoadingAuth,
    isLoadingToken,
    isAuthenticated,
    isPublicRoute,
  ]);

  if (isLoadingAuth || isLoadingToken || (!isAuthenticated && !isPublicRoute))
    return <LoadingScreen />;

  return (
    <AxiosInterceptorContext.Provider value={{ isTokenSet }}>
      {(isTokenSet || isPublicRoute) && children}
    </AxiosInterceptorContext.Provider>
  );
};
AxiosInterceptorProvider.propTypes = {
  children: PropTypes.any,
};
