import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import {
  Badge,
  ResourceList,
  ResourceItem,
  Text,
  BlockStack,
  InlineStack,
  Pagination,
  EmptyState,
  Icon,
  Truncate,
  SkeletonBodyText,
  SkeletonThumbnail,
} from "@shopify/polaris";

import { NoteIcon } from "@shopify/polaris-icons";

import { useGetAdminClientOpportunitiesQuery } from "../../../services/api";

import { formatCurrency } from "../../../utilities";
import emptySearchCompanies from "../../../assets/emptySearchCompanies.svg";

import { isDesktop } from "react-device-detect";

const AdminClientOpportunityList = (props) => {
  const resourceName = {
    singular: "opportunity",
    plural: "opportunites",
  };

  const {
    format,
    onCreateOpportunity,
    onOpportunityClick,
    clientId,
    pageLimit = 5,
  } = props;

  const [sortValue, setSortValue] = useState("UPDATED_AT_DESC");
  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  const [limit, setLimit] = useState(pageLimit);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const {
    data: opportunityData = {},
    isLoading: queryIsLoading,
    isFetching: queryIsFetching,
  } = useGetAdminClientOpportunitiesQuery({
    clientId: clientId,
    page: page,
    limit: limit,
    sort: sortValue,
    // search: searchValue,
  });

  const [opportunities, setOpportunities] = useState([]);

  useEffect(() => {
    let count = opportunityData.count;
    if (count) {
      let emptyOpportunity = {
        attributes: {
          name: "",
          stage_name: "",
          total_finance_amount: "",
          financing_option_count: "",
          financing_type: "",
          has_financing_option: "",
          has_unapproved_financing_options: "",
        },
      };

      let list = [];

      for (let i = 0; i < count; i++) {
        list.push(emptyOpportunity);
      }

      let lowerLimit = (page - 1) * limit;
      let upperLimit = page * limit;
      if (list[lowerLimit]?.attributes?.name === "") {
        let i = 0;
        for (let j = lowerLimit; j < upperLimit; j++) {
          list[j] = opportunityData.opportunities.data[i];
          i = i + 1;
        }
      }

      setOpportunities(list);
    } else {
      setOpportunities([]);
    }
    setIsLoading(false);
  }, [limit, opportunityData, page]);

  useEffect(() => {
    let filteredItems = [...opportunities];

    setFilteredItems(filteredItems);

    return () => setFilteredItems([]);
  }, [opportunities]);

  const handleNextPage = () => {
    setIsLoading(true);
    setPage((state) => {
      if (state == Math.ceil(opportunityData.count / limit)) {
        return Math.ceil(opportunityData.count / limit);
      }
      return state + 1;
    });
  };
  const handlePreviousPage = () => {
    setIsLoading(true);
    setPage((state) => {
      if (state == 1) {
        return 1;
      }
      return state - 1;
    });
  };

  const [paginatedFilteredItems, setPaginatedFilteredItems] = useState([]);
  const [currentPageItems, setCurrentPageItems] = useState([]);
  useEffect(() => {
    const perPage = limit;

    if (filteredItems.length) {
      const paginatedFilteredItems = filteredItems.reduce(
        (resultArray, item, index) => {
          const pageIndex = Math.floor(index / perPage);
          if (!resultArray[pageIndex]) {
            resultArray[pageIndex] = [];
          }
          if (item) resultArray[pageIndex].push(item);

          return resultArray;
        },
        []
      );

      setPaginatedFilteredItems(paginatedFilteredItems);
    }

    return () => setPaginatedFilteredItems([]);
  }, [filteredItems, limit]);

  useEffect(() => {
    if (paginatedFilteredItems.length) {
      const currentPageItems = paginatedFilteredItems[page - 1] || [];
      setCurrentPageItems(currentPageItems);
    }

    return () => setCurrentPageItems([]);
  }, [paginatedFilteredItems, page]);

  const paginationMarkup = (
    <Pagination
      hasPrevious={page != 1}
      onPrevious={handlePreviousPage}
      label={`Page ${page} of ${Math.ceil(opportunityData.count / limit)}`}
      hasNext={page < Math.ceil(opportunityData.count / limit)}
      onNext={handleNextPage}
    />
  );

  const emptyStateMarkup =
    !opportunities.length && !isLoading ? (
      <EmptyState
        heading="Create opportunities and generate documents"
        action={{
          content: "Create opportunity",
          onAction: onCreateOpportunity,
        }}
        secondaryAction={{
          content: "Learn more",
          url: "https://help.fortifypay.com/how-to-create-an-opportunity",
          external: true,
        }}
        image={emptySearchCompanies}
      >
        <p>
          Create a new opportunity for this account to track financing options
          and documents.
        </p>
      </EmptyState>
    ) : undefined;

  const opportunitiesResourceList = (
    <ResourceList
      resourceName={resourceName}
      items={currentPageItems}
      renderItem={(item) =>
        renderItem(item, onOpportunityClick, format, isLoading)
      }
      selectedItems={selectedItems}
      onSelectionChange={setSelectedItems}
      emptyState={emptyStateMarkup}
      sortValue={sortValue}
      sortOptions={[{ label: "Most recent", value: "UPDATED_AT_DESC" }]}
      onSortChange={(selected) => {
        setSortValue(selected);
      }}
      loading={isLoading || queryIsLoading || queryIsFetching}
      flushFilters
    />
  );

  return (
    <>
      {opportunitiesResourceList}
      {paginatedFilteredItems.length > 1 && (
        <BlockStack distribution={"center"}>{paginationMarkup}</BlockStack>
      )}
    </>
  );
};

const renderItem = (item, onOpportunityClick, format, isLoading) => {
  if (isLoading) {
    return skeletonFormat();
  }

  return itemFormat(item, onOpportunityClick);
};

const itemFormat = (item, onOpportunityClick) => {
  const {
    id,
    attributes: {
      name,
      human_stage_name,
      total_finance_amount,
      product_description,
      originator_name,
    },
  } = item;

  const formattedTotalFinanceAmount = formatCurrency(total_finance_amount);

  return (
    <ResourceItem
      id={id}
      onClick={() => onOpportunityClick(item)}
      verticalAlignment="center"
      media={<Icon source={NoteIcon} tone="subdued" />}
    >
      <InlineStack align="space-between">
        <div>
          <h3>
            <Text as="span" fontWeight="semibold">
              <Truncate>{name}</Truncate>
            </Text>
          </h3>
          <Text variant="bodySm" as="p">
            <div style={{ maxWidth: "750px" }}>
              <Truncate>
                {originator_name || "-"} ∙ {product_description}
              </Truncate>
            </div>
          </Text>
        </div>

        <InlineStack>
          {isDesktop && (
            <div
              style={{ width: "125px", display: "flex", justifyContent: "end" }}
            >
              <Badge>{human_stage_name || "Lead"}</Badge>
            </div>
          )}

          <div style={{ width: "100px", textAlign: "right" }}>
            <p>{formattedTotalFinanceAmount}</p>
          </div>
        </InlineStack>
      </InlineStack>
    </ResourceItem>
  );
};

const skeletonFormat = () => {
  return (
    <ResourceItem
      media={<SkeletonThumbnail size="small" />}
      accessibilityLabel={`View details for ...`}
      verticalAlignment="center"
    >
      <BlockStack gap="100">
        <div style={{ width: "200px" }}>
          <SkeletonBodyText lines={1} />
        </div>
        <SkeletonBodyText lines={1} />
        <SkeletonBodyText lines={1} />
        <SkeletonBodyText lines={1} />
      </BlockStack>
    </ResourceItem>
  );
};

AdminClientOpportunityList.propTypes = {
  format: PropTypes.string,
  stageFilters: PropTypes.array,
  onCreateOpportunity: PropTypes.func,
  onOpportunityClick: PropTypes.func,
  clientId: PropTypes.string,
  pageLimit: PropTypes.number,
};

export default AdminClientOpportunityList;
