import React, { useEffect, useState, useContext } from "react";
import { useField, useForm, notEmpty } from "@shopify/react-form";
import { useHistory } from "react-router-dom";

import {
  Page,
  Banner,
  Card,
  Text,
  Box,
  InlineGrid,
  TextField,
  BlockStack,
  FooterHelp,
  Link,
  PageActions,
} from "@shopify/polaris";

import { CurrentContactContext } from "../contexts/Contact";
import { useAuth0 } from "@auth0/auth0-react";
import { useUpdateContactMutation } from "../services/api";

const Profile = () => {
  const history = useHistory();

  const { currentContact } = useContext(CurrentContactContext);
  const { user } = useAuth0();

  const [updateContact, { isLoading: updatingContact }] =
    useUpdateContactMutation();

  const [contactId, setContactId] = useState("");
  const [showDetailsBanner, setShowDetailsBanner] = useState(true);

  const navigateToHomePage = () => {
    history.push({
      pathname: "/",
    });
  };

  useEffect(() => {
    if (currentContact) {
      setContactId(currentContact.id);
    }
  }, [currentContact]);

  const { fields, submit, submitErrors } = useForm({
    fields: {
      first_name: useField({
        value: currentContact.attributes.first_name || user.given_name || "",
        validates: [notEmpty("First name is required")],
      }),
      last_name: useField({
        value: currentContact.attributes.last_name || user.family_name || "",
        validates: [notEmpty("Last name is required")],
      }),
    },
    async onSubmit(form) {
      return updateContact({
        id: contactId,
        ...form,
      })
        .unwrap()
        .then(() => {
          navigateToHomePage();

          return { status: "success" };
        })
        .catch((error) => {
          return { status: "fail", errors: [error] };
        });
    },
  });

  return (
    <Page
      title="Profile"
      primaryAction={{
        content: "Save",
        onAction: submit,
        disabled: contactId.length < 1,
        loading: updatingContact,
      }}
    >
      <BlockStack gap={"400"}>
        <div>
          {!currentContact.attributes.has_complete_profile &&
            showDetailsBanner && (
              <Banner
                tone="info"
                title="Complete your profile"
                onDismiss={() => setShowDetailsBanner(false)}
              >
                We ask everyone for a few details to help us verify your
                identity.
              </Banner>
            )}

          {submitErrors.length > 0 && (
            <Banner tone="critical" title="Error">
              <p>There was an error updating your profile.</p>
            </Banner>
          )}
        </div>

        <InlineGrid columns={{ xs: "1fr", md: "2fr 5fr" }} gap="500">
          <Box
            as="section"
            paddingInlineStart={{ xs: 4, sm: 0 }}
            paddingInlineEnd={{ xs: 4, sm: 0 }}
          >
            <BlockStack gap="400">
              <Text as="h3" variant="headingMd">
                Legal name
              </Text>
              <Text as="p" variant="bodyMd">
                Please use your legal first and last name. This information may
                be used in documentation.
              </Text>
            </BlockStack>
          </Box>
          <Box width="100%" padding="0">
            <Card roundedAbove="sm">
              <BlockStack gap="400">
                <TextField
                  label={"First name"}
                  type={"text"}
                  {...fields.first_name}
                />
                <TextField
                  label={"Last name"}
                  type={"text"}
                  {...fields.last_name}
                />
              </BlockStack>
            </Card>

            <PageActions
              primaryAction={{
                content: "Save",
                onAction: submit,
                disabled: contactId.length < 1,
                loading: updatingContact,
              }}
            />
          </Box>
        </InlineGrid>

        <FooterHelp>
          Learn more about how we{" "}
          <Link url="https://help.fortifypay.com/">
            use personal information
          </Link>
        </FooterHelp>
      </BlockStack>
    </Page>
  );
};

export default Profile;
