import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { IndexTable, Text, Link, Badge } from "@shopify/polaris";

const FinanceOptionIndexTable = (props) => {
  const {
    templates,
    allSelected,
    selectedResources,
    isFetchingTemplates,
    handleSelectionChange,
    handleOpenConfirmationModal,
    handleShowEditFinanceOptionModal,
  } = props;

  const resourceName = {
    singular: "template",
    plural: "templates",
  };

  const promotedBulkActions = [
    {
      content: "Delete templates",
      onAction: () => handleOpenConfirmationModal("finance"),
    },
  ];

  const rowMarkup =
    templates.length > 0
      ? templates.map((item, index) => (
          <IndexTable.Row
            id={`finance-${index}`}
            key={index}
            position={index}
            selected={selectedResources.includes(`finance-${index}`)}
          >
            <IndexTable.Cell>
              <Text variant="strong" fontWeight="bold" truncate>
                <Link
                  removeUnderline
                  monochrome
                  onClick={() => handleShowEditFinanceOptionModal(item)}
                >
                  {item.attributes.name}
                </Link>
              </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <Text as="p">{item.attributes.financing_type}</Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <Text as="p">
                {`${item.attributes.customer_interest_rate || "--"}%`}
              </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
              <Text as="p">
                {`${item.attributes.estimated_buy_rate || "--"}%`}
              </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
              {item.attributes.interest_rate_visible ? (
                <Badge tone="success">Visible</Badge>
              ) : item.attributes.interest_rate_visible === false ? (
                <Badge>Hidden</Badge>
              ) : (
                <></>
              )}
            </IndexTable.Cell>
          </IndexTable.Row>
        ))
      : [];

  return (
    <div>
      <IndexTable
        resourceName={resourceName}
        itemCount={templates.length}
        selectable={true}
        onSelectionChange={(selectType, toggleType, selection) =>
          handleSelectionChange(selectType, toggleType, selection, "finance")
        }
        selectedItemsCount={allSelected ? "All" : selectedResources.length}
        promotedBulkActions={promotedBulkActions}
        loading={isFetchingTemplates}
        headings={[
          { title: "Term" },
          { title: "Type" },
          { title: "Client rate" },
          { title: "Buy rate" },
          { title: "Rate visibility" },
        ]}
        sortable={[false, false, false, false, false]}
        defaultSortDirection={"descending"}
      >
        {rowMarkup}
      </IndexTable>
    </div>
  );
};

FinanceOptionIndexTable.propTypes = {
  templates: PropTypes.array,
  allSelected: PropTypes.bool,
  selectedResources: PropTypes.array,
  isFetchingTemplates: PropTypes.bool,
  handleSelectionChange: PropTypes.func,
  handleOpenConfirmationModal: PropTypes.func,
  handleShowEditFinanceOptionModal: PropTypes.func,
};

export default FinanceOptionIndexTable;
