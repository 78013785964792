import React, { useState, useEffect, useContext, useCallback } from "react";

import {
  Button,
  Card,
  FormLayout,
  SkeletonBodyText,
  BlockStack,
  Text,
} from "@shopify/polaris";

import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import {
  useGetContactQuery,
  useCreateStripeSetupIntentMutation,
  useUpdateStripeCustomerReferenceMutation,
  useUpdateOpportunityStageMutation,
  useCreateOpportunityEventMutation,
  useCloneStripePaymentMethodsMutation,
} from "../../services/api";
import PaymentMethodList from "./PaymentMethodList";
import PropTypes from "prop-types";
import { CurrentContactContext } from "../../contexts/Contact";

const CustomerCardSetupForm = (props) => {
  const {
    opportunityId,
    opportunityStageName,
    designatedSignerId,
    vendorId,
    clientSecret,
    forceShowCard,
    paymentsAuthorizedAt,
    setForceShowCard,
    setClientSecret,
    setUniqueElementsKey,
    setErrorBannerMessage,
    setShowGenerateErrorBanner,
    setShowGeneralBanner,
    setGeneralBannerMessage,
    setGeneralBannerStatus,
    setCanClickNext,
    stripeSetupIntent,
    stripePaymentMethodList,
  } = props;

  const stripe = useStripe();
  const elements = useElements();

  const { currentContact } = useContext(CurrentContactContext);

  const { data: designatedSigner, isLoading: isLoadingDesignatedSigner } =
    useGetContactQuery(designatedSignerId, { skip: !designatedSignerId });

  const [savingPaymentMethod, setSavingPaymentMethod] = useState(false);
  const [currentClientSecret, setCurrentClientSecret] = useState(
    clientSecret || null
  );
  const [reloadingPaymentMethodList, setReloadingPaymentMethodList] =
    useState(false);

  const [createStripeSetupIntent, { isLoading: isCreatingSetupIntent }] =
    useCreateStripeSetupIntentMutation();

  const [
    updateStripeCustomerReference,
    { isLoading: isUpdatingCustomerReference },
  ] = useUpdateStripeCustomerReferenceMutation();

  const [
    cloneStripePaymentMethods,
    { isLoading: isClosingStripePaymentMethods },
  ] = useCloneStripePaymentMethodsMutation();

  const [updateOpportunityStage, { isLoading: isUpdatingOpportunityStage }] =
    useUpdateOpportunityStageMutation();

  useEffect(() => {
    if (stripePaymentMethodList.length > 0 && paymentsAuthorizedAt) {
      setCanClickNext(true);
    }
  }, [stripePaymentMethodList, paymentsAuthorizedAt]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const hasClientSecret = new URLSearchParams(window.location.search).get(
      "setup_intent_client_secret"
    );

    // Retrieve the SetupIntent
    stripe.retrieveSetupIntent(currentClientSecret).then(({ setupIntent }) => {
      switch (setupIntent.status) {
        case "succeeded":
          if (hasClientSecret) {
            setGeneralBannerStatus("success");
            setGeneralBannerMessage(
              "Success! Your payment method has been saved."
            );
            setShowGeneralBanner(true);
          }
          break;

        case "processing":
          if (hasClientSecret) {
            setGeneralBannerStatus("info");
            setGeneralBannerMessage(
              "Processing payment details. We'll update you when processing is complete."
            );
            setShowGeneralBanner(true);
          }
          break;

        case "requires_payment_method":
          if (hasClientSecret) {
            setErrorBannerMessage(
              "Failed to process payment details. Please try another payment method."
            );
            setShowGenerateErrorBanner(true);
          }
          break;
      }
    });
  }, [stripe, currentClientSecret]);

  const handleUpdateOpportunityBookedStage = () => {
    return updateOpportunityStage({
      id: opportunityId,
      stage_name: "booked",
    })
      .unwrap()
      .then(() => {
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [createOpportunityEvent, { isLoading: isCreatingOpportunityEvent }] =
    useCreateOpportunityEventMutation();

  const handleCreateOpportunityEvent = useCallback(
    (type) => {
      return createOpportunityEvent({
        opportunityId: opportunityId,
        type: type,
        contact_id: currentContact?.id,
      })
        .unwrap()
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [createOpportunityEvent, opportunityId]
  );

  const handleCompleteSetupIntentSubmit = async (event) => {
    setReloadingPaymentMethodList(true);
    setSavingPaymentMethod(true);

    // Reset all banners
    setShowGenerateErrorBanner(false);
    setErrorBannerMessage("");

    setShowGeneralBanner(false);
    setGeneralBannerMessage("");

    let hostUrl = process.env.APPLICATION_HOST;
    if (process.env.APPLICATION_HOST === "app.fortifypay.com")
      hostUrl = "https://" + hostUrl;
    else hostUrl = "http://" + hostUrl;

    const { setupIntent, error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${hostUrl}/opportunities/${opportunityId}/share`,
      },
      redirect: "if_required",
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorBannerMessage(error.message);
      setShowGenerateErrorBanner(true);
      setSavingPaymentMethod(false);
      setReloadingPaymentMethodList(false);
    } else {
      if (
        setupIntent.status === "succeeded" ||
        setupIntent.status === "requires_action"
      ) {
        if (setupIntent.status === "requires_action") {
          stripe
            .handleNextAction({ clientSecret: setupIntent.client_secret })
            .then((result) => {
              console.log("handle next action result", result);
            });
        }

        if (setupIntent?.payment_method) {
          setCurrentClientSecret(setupIntent?.client_secret);

          const paymentMethodId = setupIntent?.payment_method;

          updateStripeCustomerReference({
            contactId: designatedSignerId,
            // TODO: I believe this is always supposed to update the platform stripe customer reference
            // before cloning the payment method. This would not have a vendorId on this call.  Monitor for
            // any errors.
            // 
            // vendorId: vendorId,
            stripe_payment_method_id: paymentMethodId,
            stripe_setup_intent_status: setupIntent?.status,
          })
            .then(() => {
              if (setupIntent?.status === "succeeded") {
                cloneStripePaymentMethods({
                  contactId: designatedSignerId,
                  vendorId: vendorId,
                })
                  .then(() => {
                    setGeneralBannerStatus("success");
                    setGeneralBannerMessage(
                      "Success! Your payment method has been saved."
                    );

                    // Fire stripe payment details event
                    handleCreateOpportunityEvent("PaymentAuthorizationSaved");

                    setShowGeneralBanner(true);
                    setSavingPaymentMethod(false);

                    // Hide card element
                    // setShowCardElement(false);
                    setForceShowCard(false);

                    const previousStages = [
                      "lead",
                      "estimated_terms",
                      "credit_review",
                      "approved",
                    ];
                    if (previousStages.includes(opportunityStageName)) {
                      handleUpdateOpportunityBookedStage();
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            })
            .catch((error) => {
              console.log(error);

              setErrorBannerMessage(
                "A problem prevented this payment method from being saved. Please try again."
              );
              setShowGenerateErrorBanner(true);
              setSavingPaymentMethod(false);
              setReloadingPaymentMethodList(false);
              // setShowLoadingSpinner(false);
            });
        }
      } else {
        // Some other status was returned other than an error
        // that we do not have handling for
        setErrorBannerMessage(
          "A problem prevented this payment method from being saved. Please try again."
        );
        setShowGenerateErrorBanner(true);
        setSavingPaymentMethod(false);
        setReloadingPaymentMethodList(false);
        // setShowLoadingSpinner(false);

        // clears payment element fields
        elements.getElement("payment").clear();
      }
    }
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    handleCompleteSetupIntentSubmit(event);
  };

  const handleCreateNewSetupIntent = () => {
    setShowGeneralBanner(false);

    return createStripeSetupIntent({
      contactId: designatedSignerId,
      vendorId: vendorId,
      opportunityId: opportunityId,
    })
      .unwrap()
      .then((result) => {
        setClientSecret(result.attributes.client_secret);
        setUniqueElementsKey(Date.now());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRemovePaymentMethod = () => {
    handleCreateNewSetupIntent();
  };

  const [iframesLoaded, setIframesLoaded] = useState(false);

  const handlePaymentElementReady = () => {
    setIframesLoaded(true);
  };

  const paymentElementStyle = {
    visibility: iframesLoaded ? "visible" : "hidden",
    opacity: iframesLoaded ? 1 : 0,
    transition: "opacity 0.5s ease-in-out",
  };

  return (
    <form onSubmit={handleSubmit}>
      {stripeSetupIntent.attributes.stripe_status ===
        "requires_payment_method" && (
        <Card>
          <BlockStack gap="400">
            <Text variant="headingMd" as="h6">
              Payment method
            </Text>
            <FormLayout>
              <div style={paymentElementStyle}>
                {!isLoadingDesignatedSigner && (
                  <PaymentElement
                    onReady={handlePaymentElementReady}
                    options={{
                      defaultValues: {
                        billingDetails: {
                          name: designatedSigner?.attributes?.full_name,
                          email: designatedSigner?.attributes?.email,
                        },
                      },
                    }}
                  />
                )}
              </div>
              {iframesLoaded ? (
                <Button
                  submit
                  disabled={!stripe || !elements || !iframesLoaded}
                  loading={savingPaymentMethod || reloadingPaymentMethodList}
                  variant={"primary"}
                >
                  Save payment method
                </Button>
              ) : (
                <SkeletonBodyText lines={6} />
              )}
            </FormLayout>
          </BlockStack>
        </Card>
      )}

      {[
        "requires_action",
        "requires_confirmation",
        "processing",
        "canceled",
        "succeeded",
      ].includes(stripeSetupIntent.attributes.stripe_status) && (
        <PaymentMethodList
          contactId={designatedSignerId}
          vendorId={vendorId}
          stripeSetupIntent={stripeSetupIntent}
          handleRemovePaymentMethod={handleRemovePaymentMethod}
          localShowLoadingSpinner={isCreatingSetupIntent}
        />
      )}
    </form>
  );
};

CustomerCardSetupForm.propTypes = {
  setCanClickNext: PropTypes.func,
  designatedSignerId: PropTypes.string,
  vendorId: PropTypes.string,
  opportunityId: PropTypes.string,
  opportunityStageName: PropTypes.string,
  clientSecret: PropTypes.string,
  forceShowCard: PropTypes.bool,
  paymentsAuthorizedAt: PropTypes.string,
  setForceShowCard: PropTypes.func,
  setClientSecret: PropTypes.func,
  setUniqueElementsKey: PropTypes.func,
  setErrorBannerMessage: PropTypes.func,
  setShowGenerateErrorBanner: PropTypes.func,
  setShowGeneralBanner: PropTypes.func,
  setGeneralBannerMessage: PropTypes.func,
  setGeneralBannerStatus: PropTypes.func,
  stripeSetupIntent: PropTypes.object,
  stripePaymentMethodList: PropTypes.array,
};

export default CustomerCardSetupForm;
