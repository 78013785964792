import React, { useEffect, useContext } from "react";

import { CurrentContactContext } from "../../contexts/Contact";
import LoadingScreen from "../../components/Auth/Loading";

const Auth0Callback = () => {
  const { currentContact } = useContext(CurrentContactContext);

  useEffect(() => {
    if (currentContact && currentContact?.attributes.invite_opportunity_id) {
      let opportunityId = currentContact.attributes.invite_opportunity_id;
      window.location.replace(
        `/opportunities/${opportunityId}/share`,
        "_blank"
      );
    }
  }, [currentContact]);

  const pageMarkup = (
    <LoadingScreen />
  );

  return pageMarkup;
};

export default Auth0Callback;
