import React from "react";
import {
  Box,
  Text,
  Button,
  TextField,
  BlockStack,
  InlineStack,
  FormLayout,
} from "@shopify/polaris";

import { PlusIcon, InfoIcon } from "@shopify/polaris-icons";

type PurchaseOptionSegmentFieldsProps = {
  fields: any;
  addPurchaseOptionSegment: () => void;
  removePurchaseOptionSegment: (index: number) => void;
};

const PurchaseOptionSegmentFields = (
  props: PurchaseOptionSegmentFieldsProps
) => {
  const { fields, addPurchaseOptionSegment, removePurchaseOptionSegment } =
    props;

  const title = "Custom segments";

  const helpLink = (
    <Button
      variant="plain"
      icon={InfoIcon}
      accessibilityLabel="Learn more"
      external
      url={"https://help.fortifypay.com/"}
    />
  );

  const settingTitle = title ? (
    <InlineStack gap="200" wrap={false} align="start" blockAlign="center">
      <Text variant="headingSm" as="h6">
        {title}
      </Text>

      {helpLink}
    </InlineStack>
  ) : null;

  const headerMarkup = <Box width="100%">{settingTitle}</Box>;

  const filteredFields = fields.filter((segment) => {
    return segment._destroy.value !== true;
  });

  const handlePurchaseOptionSegmentCountChange = (
    field,
    value: string | number
  ) => {
    if (typeof value !== "number") {
      value = parseInt(value);
    }

    return field.count.onChange(value);
  };

  return (
    <BlockStack gap="400">
      {headerMarkup}

      {filteredFields.length > 0 && (
        <FormLayout>
          {filteredFields.map((purchaseOptionSegmentField, index: number) => {
            return (
              <FormLayout.Group key={`purchaseOptionSegment${index}`} condensed>
                <TextField
                  label="Payments"
                  type="number"
                  step={1}
                  min={1}
                  autoComplete="off"
                  connectedRight={
                    <Box paddingInlineStart={"200"} paddingInlineEnd={"100"}>
                      <Text variant="headingMd" as="p">
                        @
                      </Text>
                    </Box>
                  }
                  value={purchaseOptionSegmentField.count.value}
                  error={purchaseOptionSegmentField.count.error}
                  onChange={(value) =>
                    handlePurchaseOptionSegmentCountChange(
                      purchaseOptionSegmentField,
                      value
                    )
                  }
                />

                <TextField
                  label="Amount"
                  prefix="$"
                  type="number"
                  connectedRight={
                    <Box paddingInlineStart={"200"}>
                      <Button
                        onClick={() =>
                          removePurchaseOptionSegment(
                            purchaseOptionSegmentField
                          )
                        }
                        variant="plain"
                        tone="critical"
                      >
                        Remove
                      </Button>
                    </Box>
                  }
                  {...purchaseOptionSegmentField.value}
                />
              </FormLayout.Group>
            );
          })}
        </FormLayout>
      )}

      <Box>
        <Button icon={PlusIcon} onClick={() => addPurchaseOptionSegment()}>
          Add custom segment
        </Button>
      </Box>
    </BlockStack>
  );
};

export default PurchaseOptionSegmentFields;
