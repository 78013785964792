import React from "react";
import { useHistory } from "react-router-dom";

import { Card, Page, Layout } from "@shopify/polaris";

import FadeIn from "../../components/FadeIn";
import TeamIndexTable from "../../components/Team/IndexTable";

const Teams = () => {
  const history = useHistory();

  const navigateToVendorAdminTeamShow = (team) => {
    history.push({
      pathname: `/teams/${team.id}`,
      state: { team: { ...team } },
    });
  };

  const pageIndexMarkup = (
    <FadeIn fadeIn>
      <Page fullWidth title="Teams">
        <Layout>
          <Layout.Section primary>
            <Card>
              <TeamIndexTable
                onTeamClick={navigateToVendorAdminTeamShow}
                itemLimit={10}
                isVendorAdmin={false}
              />
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </FadeIn>
  );

  return pageIndexMarkup;
};

export default Teams;
