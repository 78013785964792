import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  LegacyCard,
  IndexTable,
  Badge,
  Tag,
} from "@shopify/polaris";

const AdminSettingsTagsTab = (props) => {
  const {
    tags,
    fetchingTags,
    isCreatingTag,
    isDeletingTags,
    selectedResources,
    setSelectedResources,
    allResourcesSelected,
    setAllResourcesSelected,
    setOpenConfirmationModal,
    setConfirmRevokeAdminRole,
    handleCreateTagModalActiveChange,
  } = props;

  const resourceName = {
    singular: 'tag',
    plural: 'tags',
  };

  const promotedBulkActions = [
    {
      content: "Delete tags",
      onAction: () => {
        setOpenConfirmationModal(true);
        setConfirmRevokeAdminRole(false);
      },
    },
  ];

  const handleTagSelectionChange = (selectionType, toggleType, tagId) => {
    if (selectionType === 'single') {
      if(selectedResources?.includes(tagId)){
        setSelectedResources(selectedResources.filter(id => id !== tagId));
      }else{
        setSelectedResources([...selectedResources, tagId]);
      }
    }else{
      setAllResourcesSelected(toggleType);
      if(toggleType){
        setSelectedResources(tags.map(tag => tag.id));
      }else{
        setSelectedResources([]);
      }
    }
  };

  const rowMarkup = tags.map((tag, index) => (
      <IndexTable.Row
        id={tag.id}
        key={tag.id}
        selected={selectedResources?.includes(tag.id)}
        position={index}
      >
        <IndexTable.Cell>
          <Tag>{tag.attributes.name}</Tag>
        </IndexTable.Cell>
      </IndexTable.Row>
    ),
  );

  return (
    <LegacyCard>
      <LegacyCard.Header
        title="Opportunity tags"
        actions={[
          {
            content: 'New tag',
            onAction: handleCreateTagModalActiveChange,
          },
        ]}
      >
      </LegacyCard.Header>
      <br/>
      <IndexTable
        resourceName={resourceName}
        itemCount={tags.length}
        selectedItemsCount={
          allResourcesSelected ? 'All' : selectedResources.length
        }
        onSelectionChange={handleTagSelectionChange}
        promotedBulkActions={promotedBulkActions}
        loading={fetchingTags || isCreatingTag || isDeletingTags}
        headings={[
          {title: 'Tags'},
        ]}
      >
        {rowMarkup}
      </IndexTable>
    </LegacyCard>
  );
};

AdminSettingsTagsTab.propTypes = {
  tags: PropTypes.array,
  fetchingTags: PropTypes.bool,
  isCreatingTag: PropTypes.bool,
  isDeletingTags: PropTypes.bool,
  selectedResources: PropTypes.array,
  setSelectedResources: PropTypes.func,
  allResourcesSelected: PropTypes.bool,
  setAllResourcesSelected: PropTypes.func,
  setOpenConfirmationModal: PropTypes.func,
  setConfirmRevokeAdminRole: PropTypes.func,
  handleCreateTagModalActiveChange: PropTypes.func,
};

export default AdminSettingsTagsTab;
