import React, { useState, useEffect, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  IndexTable,
  useIndexResourceState,
  Text,
  Link,
} from "@shopify/polaris";
import PropTypes from "prop-types";
import { formatCurrency, formatNumericDate } from "../../utilities";
import { useGetTransactionsQuery } from "../../services/api";
import TransactionBadge from "./TransactionBadge";
import { CurrentContactContext } from "../../contexts/Contact";

const TransactionTable = (props) => {
  const {
    page,
    limit,
    statusName,
    queryValue,
    setCount,
    setVolume,
    isLoading,
    setIsLoading,
    setIds,
    sort,
    setSortSelected
  } = props;

  const history = useHistory();
  const [transactions, setTransactions] = useState([]);
  const resourceName = {
    singular: "transaction",
    plural: "transactions",
  };

  const { isOrganizationAdmin } = useContext(
    CurrentContactContext
  );

  const {
    data: transactionData = { count: 0 },
    isLoading: queryIsLoading,
    isFetching: queryIsFetching,
  } = useGetTransactionsQuery({
    page: page,
    status: statusName,
    search: queryValue,
    sort: sort,
    limit,
  });

  useEffect(() => {
    if (transactionData.count) {
      let lowerLimit = (page - 1) * limit;
      let upperLimit = page * limit;
      let list = [];
      let ids = [];

      let ocount = 0;
      for (let lcount = lowerLimit; lcount < upperLimit; lcount++) {
        if (transactionData.transactions.data[ocount]) {
          list[lcount] = transactionData.transactions.data[ocount];
        }
        ocount += 1;
      }

      setTransactions(list);
      setIds(transactionData.ids.join(","));
      setIsLoading(false);
      setCount(transactionData.count);
      setVolume(transactionData.volume);
    } else {
      setTransactions([]);
      setIsLoading(false);
      setIds("");
      setCount(0);
      setVolume(0);
    }
  }, [transactionData]);

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(transactions);

  const handleOpportunityClick = (transaction) => {
    const opportunity = transactionData.opportunities.data.find((op) => {
      return op.id === transaction.attributes.opportunity_id;
    });
    const clientId = opportunity.attributes.client_id;
    history.push({
      pathname: `/clients/${clientId}/opportunities/${opportunity.id}`,
      search: "?tab=payments-n-review",
      state: { opportunity: { ...opportunity } },
    });
  };

  const [sortDirection, setSortDirection] = useState('descending');

  const handleSortColumn = (headingIndex, direction) => {

    let sortString = ''
    if(headingIndex == 0) {
      sortString = 'opportunity_name '
    } else if (headingIndex == 1) {
      sortString = 'transaction_type '
    } else if (headingIndex == 2) {
      sortString = 'amount '
    } else if (headingIndex == 4) {
      sortString = 'scheduled_date '
    } else if (headingIndex == 5) {
      sortString = 'completed_date '
    }

    if (sortDirection === "ascending") {
      sortString += 'desc'
      setSortDirection("descending")
    } else {
      sortString += 'asc'
      setSortDirection("ascending")
    }

    setSortSelected([sortString])
  }

  const rowMarkup = transactions.map((item, index) => (
    <IndexTable.Row
      id={index}
      key={index}
      selected={selectedResources.includes(item.attributes.id)}
      position={index}
    >
      <IndexTable.Cell>
        <Text variant="strong" fontWeight="bold" truncate>
          <Link
            removeUnderline
            monochrome
            onClick={() => handleOpportunityClick(item)}
          >
            {item.attributes.opportunity_name}
          </Link>
        </Text>
      </IndexTable.Cell>
      <IndexTable.Cell>{isOrganizationAdmin ? item.attributes.detail : item.attributes.limited_detail}</IndexTable.Cell>
      <IndexTable.Cell>
        {formatCurrency(item.attributes.amount)}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {!isOrganizationAdmin && item.attributes.detail.includes("Lender Payment") ? (
          "--"
        ) : (
          <TransactionBadge status={item.attributes.status} />
        )}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {item.attributes.scheduled_date ? formatNumericDate(new Date(item.attributes.scheduled_date?.split("-"))) : "--/--/--"}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {item.attributes.is_completed
          ? `${formatNumericDate(
              new Date(item.attributes.completed_at?.split("-"))
            )}`
          : "--/--/--"}
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  return (
    <IndexTable
      resourceName={resourceName}
      itemCount={transactionData.count}
      selectable={false}
      sortable={[true, true, true, false, true, true]}
      onSort={handleSortColumn}
      sortDirection={sortDirection}
      // onSelectionChange={handleSelectionChange}
      // selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
      loading={queryIsLoading || queryIsFetching || isLoading}
      headings={[
        { title: "Opportunity" },
        { title: "Transaction type" },
        { title: "Amount" },
        { title: "Status" },
        { title: "Scheduled" },
        { title: "Completed" },
      ]}
    >
      {rowMarkup}
    </IndexTable>
  );
};

TransactionTable.propTypes = {
  page: PropTypes.number,
  limit: PropTypes.number,
  statusName: PropTypes.string,
  queryValue: PropTypes.string,
  setCount: PropTypes.func,
  setVolume: PropTypes.func,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  setIds: PropTypes.func,
};

export default TransactionTable;
