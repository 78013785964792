import React from "react";
import PropTypes from "prop-types";

import {
  Card,
  BlockStack,
  InlineStack,
  InlineGrid,
  Button,
  Layout,
  Text,
} from "@shopify/polaris";

const QualificationFields = (props) => {
  const { account, setShowEditQualificationFieldsModal } = props;

  const labelStyles = {
    minHeight: "36px",
    paddingTop: "4px",
    color: "black",
  };

  return (
    <Layout.Section>
      <Card>
        <div style={{ paddingBottom: "16px" }}>
          <InlineStack gap="200" align="space-between">
            <Text variant="headingMd" as="h6">
              Opportunity qualification fields
            </Text>

            <Button onClick={() => setShowEditQualificationFieldsModal(true)} variant="plain">
              Edit fields
            </Button>
          </InlineStack>
        </div>

        <div>
          <InlineGrid gap="400" columns={2}>
            <Text tone="subdued">
              Portal available
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.portal_available ? "Yes" : "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              Submission method
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.submission_method || "-"}</p>
                </BlockStack>
              </div>
            </Text>
          </InlineGrid>
        </div>

        <div>
          <InlineGrid gap="400" columns={3}>
            <Text tone="subdued">
              Rate guidance general
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.rate_guidance_general || "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              Rate guidance startup
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.rate_guidance_startup || "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              Rate guidance medical
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.rate_guidance_medical || "-"}</p>
                </BlockStack>
              </div>
            </Text>
          </InlineGrid>
        </div>

        <div>
          <InlineGrid gap="400" columns={3}>
            <Text tone="subdued">
              CBRP pull type
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.cbrp_pull_type || "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              CBR details
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.cbr_details || "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              EBO details
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.ebo_details || "-"}</p>
                </BlockStack>
              </div>
            </Text>
          </InlineGrid>
        </div>

        <div>
          <InlineGrid gap="400" columns={3}>
            <Text tone="subdued">
              Bank statements required
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>
                    {account.attributes.bank_statements_required ? "Yes" : "-"}
                  </p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              Financials required
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.financials_required ? "Yes" : "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              Financial level
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.financial_level || "-"}</p>
                </BlockStack>
              </div>
            </Text>
          </InlineGrid>
        </div>

        <div>
          <InlineGrid gap="400" columns={3}>
            <Text tone="subdued">
              E Docs available
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.e_docs_available ? "Yes" : "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              Ink signed app required
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>
                    {account.attributes.ink_signed_app_required ? "Yes" : "-"}
                  </p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              Corp only allowed
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.corp_only_allowed ? "Yes" : "-"}</p>
                </BlockStack>
              </div>
            </Text>
          </InlineGrid>
        </div>

        <div>
          <InlineGrid gap="400" columns={3}>
            <Text tone="subdued">
              Startup friendly
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.startup_friendly ? "Yes" : "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              Titling handling
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.titling_handling || "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              Lienholder info
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.lienholder_info || "-"}</p>
                </BlockStack>
              </div>
            </Text>
          </InlineGrid>
        </div>

        <div>
          <InlineGrid gap="400" columns={5}>
            <Text tone="subdued">
              Paynet base
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.paynet_base || "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              Paynet required
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.paynet_required ? "Yes" : "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              Minimum FICO score
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.min_fico_score || "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              Minimum time in business (year)
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.min_time_in_business || "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              Minimum annual gross revenue
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.min_annual_gross_revenue || "-"}</p>
                </BlockStack>
              </div>
            </Text>
          </InlineGrid>
        </div>

        <div>
          <InlineGrid gap="400" columns={4}>
            <Text tone="subdued">
              App only limit general
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.app_only_limit_general || "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              App only limit med core
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.app_only_limit_med_core || "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              App only limit med non core
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.app_only_limit_med_non_core || "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              App only limit working capital
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>
                    {account.attributes.app_only_limit_working_capital || "-"}
                  </p>
                </BlockStack>
              </div>
            </Text>
          </InlineGrid>
        </div>

        <div>
          <InlineGrid gap="400" columns={4}>
            <Text tone="subdued">
              Minimum ownership
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.min_ownership || "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              Point range
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.point_range || "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              Doc fee
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.doc_fee || "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              Doc fee additional
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.doc_fee_additional || "-"}</p>
                </BlockStack>
              </div>
            </Text>
          </InlineGrid>
        </div>

        <div>
          <InlineGrid gap="400" columns={4}>
            <Text tone="subdued">
              Time to decision
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.time_to_decision || "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              Max term length
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.max_term_length || "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              Structured pays allowed
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>
                    {account.attributes.structured_pays_allowed ? "Yes" : "-"}
                  </p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              Deferrals allowed
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.deferrals_allowed ? "Yes" : "-"}</p>
                </BlockStack>
              </div>
            </Text>
          </InlineGrid>
        </div>

        <div>
          <InlineGrid gap="400" columns={4}>
            <Text tone="subdued">
              Typical deal size
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.typical_deal_size || "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              Credit box details
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.credit_box_details || "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              Prior BK restriction
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.prior_bk_restriction || "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              Compensation percentage needed
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.comp_percentage_needed || "-"}</p>
                </BlockStack>
              </div>
            </Text>
          </InlineGrid>
        </div>

        <div>
          <InlineGrid gap="400" columns={4}>
            <Text tone="subdued">
              Age restrictions
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.age_restrictions || "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              Fleet requirements
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.fleet_requirements || "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              PPS titled allowed
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.pps_titled_allowed ? "Yes" : "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              PPS non titled allowed
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>
                    {account.attributes.pps_non_titled_allowed ? "Yes" : "-"}
                  </p>
                </BlockStack>
              </div>
            </Text>
          </InlineGrid>
        </div>

        <div>
          <InlineGrid gap="400" columns={4}>
            <Text tone="subdued">
              OTR details
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.otr_details || "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              Funding comments
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.funding_comments || "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              Restrictions
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.restrictions || "-"}</p>
                </BlockStack>
              </div>
            </Text>

            <Text tone="subdued">
              Additional notes
              <div style={labelStyles}>
                <BlockStack alignment="trailing" spacing="tight">
                  <p>{account.attributes.additional_notes || "-"}</p>
                </BlockStack>
              </div>
            </Text>
          </InlineGrid>
        </div>
      </Card>
    </Layout.Section>
  );
};

QualificationFields.propTypes = {
  account: PropTypes.object,
  setShowEditQualificationFieldsModal: PropTypes.func,
};

export default QualificationFields;
