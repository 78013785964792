import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useForm, useField, notEmpty } from "@shopify/react-form";
import {
  Card,
  Page,
  Layout,
} from "@shopify/polaris";
import { StoreIcon } from "@shopify/polaris-icons";

import { CurrentContactContext } from "../../../contexts/Contact";

import AdminDocumentsList from "./List";
import AddDocumentModal from "./AddDocumentModal";
import FadeIn from "../../../components/FadeIn";
import LoadingScreen from "../../../components/Auth/Loading";

const AdminDocuments = () => {

  const { currentContact } = useContext(CurrentContactContext)

  const [openAddDocumentModal, setOpenAddDocumentModal] = useState(false);
  const toggleAddDocumentModal = () => { setOpenAddDocumentModal(!openAddDocumentModal)}
  const addDocumentModal = <AddDocumentModal openAddDocumentModal={openAddDocumentModal} toggleAddDocumentModal={toggleAddDocumentModal} />

  const PageMarkup = (
    <FadeIn fadeIn>
      <Page
        fullWidth
        title="Documents"
        secondaryActions={[
          {
            content: "Add document template",
            accessibilityLabel: "Add document template",
            onAction: () => { setOpenAddDocumentModal(true) },
          },
        ]}
      >
        <Layout>
          <Layout.Section>
            <Card padding="0">
              <AdminDocumentsList />
            </Card>

            <br /><br />
          </Layout.Section>
        </Layout>

        {addDocumentModal}
      </Page>
    </FadeIn>
  );

  return currentContact ? PageMarkup : <LoadingScreen />;
};

export default AdminDocuments;
