import React, { useState, useEffect } from "react";

import {
  ChoiceList,
  TextField,
  RangeSlider,
  Autocomplete,
  LegacyStack,
  Icon,
  Tag,
} from "@shopify/polaris";

import { dateOptions } from "../../utilities";
import TaskFilter from "./TaskFilter";
import { SearchIcon } from "@shopify/polaris-icons";

const ViewFilter = (props) => {
  const {
    filterKey,
    filterObject,
    initialValues,
    filterValues,
    setFilterValues,
    savedViews,
    selectedView,
    handleUpdateSavedView,
    isUpdatingSavedView,
    appliedFilters,
    setAppliedFilters,
    viewFilterSelectedValues,
    setViewFilterSelectedValues,
    viewFilterHasSearchFocus,
    handleSearchValueChange,
    searchFilterOptions,
    handleRemoveViewFilter,
    isLoadingDynamic,
  } = props;

  // Function to set values
  const handleFilterValueChange = (value) => {
    // Reject if initial value or empty
    // if (value === initialValues[filterKey] || value === "" || value == undefined) return

    // Set Value in Parent component
    const newValues = JSON.parse(JSON.stringify(filterValues));
    newValues[filterKey] = value;

    setFilterValues(newValues);

    // Set Applied Filters in Parent component
    let newAppliedFilters = [];

    for (let i = 0; i < appliedFilters.length; i++) {
      if (appliedFilters[i].key !== filterKey) {
        newAppliedFilters.push(appliedFilters[i]);
      }
    }

    // Set label according to filter type
    let filterLabel = filterObject.label + ": ";
    let label = "";
    switch (filterObject.type) {
      case "choice_string":
        if (filterObject.dynamic) {
          label = value;
          filterLabel = filterLabel + value;
        } else {
          for (let i = 0; i < filterObject.choices.length; i++) {
            if (filterObject.choices[i].value == value) {
              label = filterObject.choices[i].label;
              filterLabel = filterLabel + filterObject.choices[i].label;
              break;
            }
          }
        }
        break;
      case "date":
        for (let i = 0; i < dateOptions.length; i++) {
          if (dateOptions[i].value == value) {
            label = dateOptions[i].label;
            filterLabel = filterLabel + dateOptions[i].label;
            break;
          }
        }
        break;
      case "search_string":
        label = value;
        filterLabel = filterLabel + value;
        break;
      case "task":
        label = "Requirements";
        filterLabel = "Requirements";
        break;
      default:
        label = value;
        filterLabel = filterLabel + value;
        break;
    }

    newAppliedFilters.push({
      key: filterKey,
      label: filterLabel,
      onRemove: function () {
        handleRemoveViewFilter(selectedView, filterKey);
      },
    });

    setAppliedFilters(newAppliedFilters);

    // Update Query
    let currentSavedViewIndex = selectedView - 1;
    let currentSavedView = savedViews[currentSavedViewIndex];

    let savedViewFilters = JSON.parse(currentSavedView.attributes.filters);
    let queryString = filterObject.query;

    switch (filterObject.type) {
      case "choice_string":
        savedViewFilters[filterKey] = {
          query: queryString,
          value: value,
          label: label,
        };
        break;
      case "date":
        savedViewFilters[filterKey] = {
          query: queryString,
          value: value,
          label: label,
        };
        break;
      case "search_string":
        savedViewFilters[filterKey] = {
          query: queryString,
          value: value,
          label: label,
        };
        break;
      case "task":
        // Build Query
        let taskKeys = Object.keys(value);

        let taskQueryString = "";
        let taskValues = [];
        for (let i = 0; i < taskKeys.length; i++) {
          let currentKey = taskKeys[i];
          taskQueryString = taskQueryString + currentKey + "_status = ?";

          taskValues.push(value[currentKey][0]);

          if (i != taskKeys.length - 1) {
            taskQueryString = taskQueryString + " AND ";
          }
        }

        savedViewFilters[filterKey] = {
          query: taskQueryString,
          value: taskValues,
          label: "Requirements",
        };
        break;
      default:
        break;
    }

    // Create special case for new stage filter
    if (filterKey == "stagename") {
      let specialQueryString = queryString.replace("?", value);
      savedViewFilters[filterKey] = {
        query: specialQueryString,
        value: "",
        label: label,
      };
    }

    let stringSavedViewFilters = JSON.stringify(savedViewFilters);

    handleUpdateSavedView(selectedView, undefined, stringSavedViewFilters);
  };

  // Function set search filter icon
  const handleSearchFilterIcon = (value) => {
    let newSelectedValues = JSON.parse(
      JSON.stringify(viewFilterSelectedValues)
    );
    newSelectedValues[filterKey] = value;
    setViewFilterSelectedValues(newSelectedValues);
  };

  const handleRemoveFilterIcon = () => {
    let newValues = JSON.parse(JSON.stringify(filterValues));
    delete newValues[filterKey];
    setFilterValues(newValues);

    let newSelectedValues = JSON.parse(
      JSON.stringify(viewFilterSelectedValues)
    );
    delete newSelectedValues[filterKey];
    setViewFilterSelectedValues(newSelectedValues);

    let currentSavedViewIndex = selectedView - 1;
    let currentSavedView = savedViews[currentSavedViewIndex];
    let savedViewFilters = JSON.parse(currentSavedView.attributes.filters);
    delete savedViewFilters[filterKey];

    let stringSavedViewFilters = JSON.stringify(savedViewFilters);

    handleUpdateSavedView(selectedView, undefined, stringSavedViewFilters);
  };

  // Choice Filter
  const choiceListFilter = () => {
    const { label, choices = [], dynamic, multiple } = filterObject;

    let choiceListChoices = dynamic
      ? searchFilterOptions[filterKey] || []
      : choices;

    return (
      <ChoiceList
        title={label}
        titleHidden
        choices={choiceListChoices}
        selected={filterValues[filterKey] || []}
        onChange={handleFilterValueChange}
        disabled={isUpdatingSavedView}
        allowMultiple={multiple}
      />
    );
  };

  // Text Filter
  const textFieldFilter = () => {
    const { label } = filterObject;

    return (
      <TextField
        label={label}
        value={filterValues[filterKey]}
        onChange={handleFilterValueChange}
        autoComplete="off"
        labelHidden
      />
    );
  };

  const [searchTextFilterText, setSearchTextFilterText] = useState("");

  // Search Text Filter
  const searchTextFieldFilter = () => {
    const { label } = filterObject;

    return (
      <>
        {filterKey in viewFilterSelectedValues &&
        filterValues[filterKey] &&
        filterValues[filterKey]?.length > 0 ? (
          <LegacyStack spacing="extraTight" alignment="center">
            <Tag
              key={`option-${filterKey}-${filterValues[filterKey]}`}
              onRemove={handleRemoveFilterIcon}
            >
              <p>{filterValues[filterKey]}</p>
            </Tag>
          </LegacyStack>
        ) : (
          <Autocomplete
            id={`${label}-autocomplete`}
            options={
              searchFilterOptions[filterKey]?.length > 0
                ? searchFilterOptions[filterKey]
                : []
            }
            selected={[]}
            onSelect={(value) => {
              handleSearchFilterIcon(value);
              handleSearchValueChange(value);
              handleFilterValueChange(value);
            }}
            textField={
              <Autocomplete.TextField
                onChange={(value) => {
                  setSearchTextFilterText(value);
                  handleSearchValueChange(value);
                  handleFilterValueChange(value);
                }}
                value={searchTextFilterText}
                prefix={<Icon source={SearchIcon} tone="base" />}
                placeholder={`Search ${label}...`}
                focused={viewFilterHasSearchFocus}
              />
            }
            loading={isLoadingDynamic}
            listTitle={label}
            preferredPosition="bottom"
          />
        )}
      </>
    );
  };

  // Range Filter - Currently not working properly
  const rangeSliderFilter = () => {
    const { label, min, max, step } = filterObject;

    return (
      <RangeSlider
        label={label}
        labelHidden
        value={filterValues[filterKey]}
        prefix="$"
        output
        min={min}
        max={max}
        step={step}
        onChange={handleFilterValueChange}
      />
    );
  };

  // Date Filter
  const dateFilter = () => {
    const { label } = filterObject;

    return (
      <ChoiceList
        title={label}
        titleHidden
        choices={dateOptions}
        selected={filterValues[filterKey] || []}
        onChange={handleFilterValueChange}
      />
    );
  };

  // Task Filter
  const taskFilter = () => {
    const { label } = filterObject;

    return (
      <TaskFilter
        filterValues={filterValues}
        filterObject={filterObject}
        handleFilterValueChange={handleFilterValueChange}
      />
    );
  };

  const returnFilter = () => {
    const { type } = filterObject;

    switch (type) {
      case "free_string":
        return textFieldFilter();
        break;
      case "search_string":
        return searchTextFieldFilter();
        break;
      case "choice_string":
        return choiceListFilter();
        break;
      case "number":
        return rangeSliderFilter();
        break;
      case "date":
        return dateFilter();
        break;
      case "task":
        return taskFilter();
        break;
      default:
        return null;
    }
  };

  return returnFilter();
};

export default ViewFilter;
