import React, {
  useState,
  useCallback,
  useContext,
  useRef,
} from "react";
import { useParams } from "react-router-dom";
import {
  Banner,
  Layout,
  Page,
  PageActions,
  Spinner,
  Link,
  Card,
  InlineStack,
  Text,
} from "@shopify/polaris";
// import { MobileView, isMobile } from "react-device-detect";

import {
  useGetVendorPaymentAttachmentQuery,
} from "../../services/api";
import FadeIn from "../../components/FadeIn";
import InvoiceConfirmForm from "../../components/Invoice/Confirm/Form";
import InvoiceEmbed from "./Embed";
import LoadingScreen from "../../components/Auth/Loading";

import { CurrentContactContext } from "../../contexts/Contact";

const InvoiceConfirm = () => {
  const { id: vendorPaymentId } = useParams();

  const { currentContact } = useContext(CurrentContactContext);

  const {
    data: vendorPayment = { attributes: {} },
    isLoading: isLoadingVendorPayment,
  } = useGetVendorPaymentAttachmentQuery(
    { vendorPaymentId: vendorPaymentId },
    { skip: !vendorPaymentId }
  );

  const confirmFormRef = useRef();

  const handleConfirmInvoiceUpload = () => {
    confirmFormRef.current.handleSubmitForm();
  };

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState("");

  const handleErrorMessage = useCallback((error) => {
    console.log(error);
    setShowErrorBanner(true);
    let message;

    if (error.data?.legal_business_name == "has already been taken") {
      message =
        "Could not create opportunity.  Account name has already been taken.";
    } else {
      message = "Could not create opportunity.";
    }
    setSubmitErrorMessage(message);
  }, []);

  const submitErrorBanner = showErrorBanner ? (
    <Banner tone="critical" title="Something went wrong">
      {submitErrorMessage}
    </Banner>
  ) : null;

  const [isLoadingConfirmation, setIsLoadingConfirmation] = useState(false);
  const handleConfirmationLoadingState = (isLoading) => {
    setIsLoadingConfirmation(isLoading);
  };

  const pageMarkup = (
    <FadeIn fadeIn={true}>
      <Page
        title="Quote upload"
        fullWidth
        primaryAction={{
          content: "Confirm",
          onAction: handleConfirmInvoiceUpload,
          loading: isLoadingConfirmation,
        }}
      >
        <Layout>
          {!showErrorBanner && (
            <Layout.Section>
              <Banner title="Quote uploaded." tone="success">
                Confirm AI generated data and complete required fields.{" "}
                <Link url="https://help.fortifypay.com/how-to-create-an-opportunity">
                  Learn more about Fortify AI
                </Link>
              </Banner>
            </Layout.Section>
          )}

          {showErrorBanner && (
            <Layout.Section>{submitErrorBanner}</Layout.Section>
          )}

          {isLoadingVendorPayment ? (
            <React.Fragment>
              <Layout.Section>
                <Card>
                  <InlineStack align="center">
                    <Spinner />
                  </InlineStack>
                </Card>
              </Layout.Section>
              <Layout.Section variant="oneThird">
                <Card>
                  <Text variant="headingMd" as="h2">
                    Confirm fields
                  </Text>
                </Card>
              </Layout.Section>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Layout.Section>
                {vendorPayment.attributes.invoice_url && (
                  <Card>
                    <InvoiceEmbed
                      fullHeight
                      invoiceUrl={vendorPayment.attributes.invoice_url}
                    />
                  </Card>
                )}
              </Layout.Section>
              <Layout.Section variant="oneThird">
                {!isLoadingVendorPayment &&
                  vendorPayment.attributes.ocr_response_normalized && (
                    <InvoiceConfirmForm
                      handleErrorMessage={handleErrorMessage}
                      onConfirmationLoading={handleConfirmationLoadingState}
                      invoiceData={
                        vendorPayment.attributes.ocr_response_normalized
                      }
                      vendorPaymentId={vendorPaymentId}
                      ref={confirmFormRef}
                    />
                  )}
              </Layout.Section>
            </React.Fragment>
          )}
          <Layout.Section>
            <PageActions
              primaryAction={{
                content: "Confirm",
                onAction: handleConfirmInvoiceUpload,
                loading: isLoadingConfirmation,
              }}
            />
          </Layout.Section>
        </Layout>
      </Page>
    </FadeIn>
  );

  return currentContact ? pageMarkup : <LoadingScreen />;
};

export default InvoiceConfirm;
