import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Tabs,
  IndexTable,
  Filters,
  Text,
  Badge,
  Pagination,
  LegacyStack,
  Truncate,
  Link,
  Modal,
  BlockStack
} from "@shopify/polaris";

import { useDebounce } from "use-debounce";
import { formatCurrency, formatNumericDate } from "../../../utilities";
import {
  useGetAdminOpportunitiesQuery,
  useDeleteAdminOpportunitiesMutation
} from "../../../services/api";

const AdminOpportunityTable = (props) => {
  const { onOpportunityClick, itemLimit = 50 } = props;

  const location = useLocation();
  const history = useHistory();

  const [selected, setSelected] = useState(0);

  const [limit, setLimit] = useState(itemLimit);
  const [page, setPage] = useState(1);
  const [queryValue, setQueryValue] = useState(undefined);
  const [searchValue] = useDebounce(queryValue, 500);
  const [sortValue, setSortValue] = useState("UPDATED_AT_DESC");
  const [sortDirection, setSortDirection] = useState("descending");
  const [disambiguatedFilters, setDisambiguatedFilters] = useState(null);

  const [taggedWith, setTaggedWith] = useState(undefined);
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    []
  );
  const [originatorSearch] = useDebounce(taggedWith, 500);

  const tabs = [
    {
      id: "all",
      content: "All",
      panelID: "all",
    },
    {
      id: "lead",
      content: "Lead",
      panelID: "lead",
    },
    {
      id: "estimated_terms",
      content: "Estimated Terms",
      panelID: "estimated-terms",
    },
    {
      id: "credit_review",
      content: "Credit Review",
      panelID: "credit-review",
    },
    {
      id: "approved",
      content: "Approved",
      panelID: "approved",
    },
    {
      id: "booked",
      content: "Booked",
      panelID: "booked",
    },
    {
      id: "funded",
      content: "Funded",
      panelID: "funded",
    },
    {
      id: "closed_lost",
      content: "Closed/Lost",
      panelID: "closed_lost",
    },
  ];

  const handleTabChange = useCallback((selectedTab) => {
    if (selectedTab === 0) {
      setDisambiguatedFilters(null);
    } else {
      setDisambiguatedFilters([tabs[selectedTab].id]);
    }
    setPage(1);
    setSelected(selectedTab);

    refetchOpportunities();
  }, []);

  const {
    data: opportunityData = { count: 0 },
    isLoading: queryIsLoading,
    isFetching: queryIsFetching,
    refetch: refetchOpportunities,
  } = useGetAdminOpportunitiesQuery({
    page: page,
    limit: limit,
    filters: disambiguatedFilters,
    sort: sortValue,
    search: searchValue,
    originator: originatorSearch,
  });

  const [deleteAdminOpportunities, { isLoading: isDeleting }] =
    useDeleteAdminOpportunitiesMutation();

  const [opportunities, setOpportunities] = useState([]);
  const [currentlyDisplayedOpportunities, setCurrentlyDisplayedOpportunities] =
    useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedResources, setSelectedResources] = useState([]);
  const [allSelected, setAllSeleted] = useState(false);

  const handleSelectionChange = (selectionType, toggleType, selection) => {
    let selectionList = [...selectedResources]

    if (selectionType === "single") {
      // Check if in selection
      let inSelectionIndex = -1
      for(let i = 0; i < selectedResources.length; i++) {
        if (selectedResources[i] === selection) {
          inSelectionIndex = i
          break
        }
      }

      if(inSelectionIndex != -1) {
        // Remove if in list
        selectionList.splice(inSelectionIndex, 1)
      } else {
        // Add if not
        selectionList.push(selection)
      }

      setSelectedResources(selectionList)
      setAllSeleted(false)
    } else {
      if(allSelected || selectedResources.length === opportunities.length) {
        // Clear selection
        setSelectedResources([])
        setAllSeleted(false)
      } else {
        // Select all
        let list = []
        for(let i = 0; i < limit; i++) { list.push(i) }
        console.log(list)
        setSelectedResources(list)
        setAllSeleted(true)
      }
    }
  }

  useEffect(() => {
    let count = opportunityData.count;
    if (count) {
      let emptyOpportunity = {
        attributes: {
          name: "",
          stage_name: "",
          total_finance_amount: "",
        },
      };

      let list = [];

      for (let i = 0; i < count; i++) {
        list.push(emptyOpportunity);
      }

      let lowerLimit = (page - 1) * limit;
      let upperLimit = page * limit;

      if (list[lowerLimit]?.attributes?.name === "") {
        let i = 0;
        for (let j = lowerLimit; j < upperLimit; j++) {
          if (opportunityData.opportunities.data[i]) {
            list[j] = opportunityData.opportunities.data[i];
          }
          i = i + 1;
        }
      }

      setOpportunities(list);
      setCurrentlyDisplayedOpportunities(list.slice(lowerLimit, upperLimit));

      setIsLoading(false);
    } else {
      setOpportunities([]);
      setIsLoading(false);
    }
  }, [opportunityData, selected]);

  useEffect(() => {
    setPage(1);
  }, [searchValue]);

  const resourceName = {
    singular: "opportunity",
    plural: "opportunities",
  };

  const handleTaggedWithRemove = useCallback(() => setTaggedWith(null), []);
  const handleQueryValueRemove = () => setQueryValue(undefined);
  const handleClearAll = useCallback(() => {
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [handleQueryValueRemove, handleTaggedWithRemove]);
  const handleSortChange = useCallback((value) => setSortValue(value), []);

  const filters = [];
  const appliedFilters = [];

  const sortOptions = [{ label: "Most recent", value: "UPDATED_AT_DESC" }];

  const rowMarkup = currentlyDisplayedOpportunities.map((item, index) => (
    <IndexTable.Row id={index} key={index} position={index} selected={selectedResources.includes(index)}>
      <IndexTable.Cell>
        <div style={{ width: "200px" }}>
          <Text as="span" fontWeight="semibold" >
            <Truncate>
              <Link
                monochrome
                removeUnderline
                onClick={() => onOpportunityClick(item)}
              >
                {item.attributes.name}
              </Link>
            </Truncate>
          </Text>
        </div>
      </IndexTable.Cell>
      <IndexTable.Cell>
        {formatNumericDate(new Date(item.attributes.created_at))}
      </IndexTable.Cell>
      <IndexTable.Cell>{item.attributes.originator_name}</IndexTable.Cell>
      <IndexTable.Cell>
        {formatCurrency(item.attributes.total_finance_amount)}
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Badge>{item.attributes.human_stage_name}</Badge>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Text tone="subdued">
        </Text>
      </IndexTable.Cell>
    </IndexTable.Row>
  ));

  function disambiguateLabel(key, value) {
    switch (key) {
      case "originator":
        return `Originator: ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }

  const handleNextPage = () => {
    setIsLoading(true);
    setPage((state) => {
      if (state == Math.ceil(opportunityData.count / limit)) {
        return Math.ceil(opportunityData.count / limit);
      }
      return state + 1;
    });
  };

  const handlePreviousPage = () => {
    setIsLoading(true);
    setPage((state) => {
      if (state == 1) {
        return 1;
      }
      return state - 1;
    });
  };

  const handleOnSort = (headingIndex, direction) => {
    let currentSortDirection = "descending";
    if (sortDirection === "ascending") {
      currentSortDirection = "descending";
      setSortDirection("descending");
    } else {
      currentSortDirection = "ascending";
      setSortDirection("ascending");
    }

    let sortTag = "";
    if (headingIndex === 1) {
      sortTag = sortTag + "CREATED_AT_";
    } else if (headingIndex === 2) {
      sortTag = sortTag + "ORIGINATOR_";
    } else if (headingIndex === 3) {
      sortTag = sortTag + "AMOUNT_";
    }

    if (currentSortDirection === "ascending") {
      sortTag = sortTag + "ASC";
    } else {
      sortTag = sortTag + "DESC";
    }

    setSortValue(sortTag);
    refetchOpportunities();
  };

  const bulkDeleteOpportunities = () => {
    const opportunityIds = []

    if (!allSelected) {
      for(let i = 0; i < selectedResources.length; i++) {
        opportunityIds.push(opportunities[selectedResources[i]].id)
      }
    }

    return deleteAdminOpportunities({
      filters: disambiguatedFilters,
      search: searchValue,
      opportunityIds: opportunityIds
    })
      .unwrap()
      .then(() => {
        setAllSeleted(false);
        setSelectedResources([]);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const handleOpenConfirmationModal = () => { setOpenConfirmationModal(true) }
  const handleCloseConfirmationModal = () => { setOpenConfirmationModal(false) }
  const handleConfirmDeleteModal = () => {
    bulkDeleteOpportunities()
    setOpenConfirmationModal(false)
  }

  const areYouSureModal = (
    <Modal
      open={openConfirmationModal}
      onClose={handleCloseConfirmationModal}
      title="Are you sure?"
      primaryAction={{
        content: "Confirm",
        onAction: handleConfirmDeleteModal,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: handleCloseConfirmationModal,
        },
      ]}
    >
      <Modal.Section>
        <BlockStack>
          <p>You are about to delete { allSelected ? opportunityData.count : selectedResources.length} opportunities. This action cannot be undone.</p>
        </BlockStack>
      </Modal.Section>
    </Modal>
  )

  const promotedBulkActions = [
    {
      content: 'Delete opportunities',
      onAction: handleOpenConfirmationModal,
    },
  ];

  const paginationMarkup = (
    <Pagination
      hasPrevious={page != 1}
      onPrevious={handlePreviousPage}
      label={`Page ${page} of ${Math.ceil(opportunityData.count / limit)}`}
      hasNext={page < Math.ceil(opportunityData.count / limit)}
      onNext={handleNextPage}
    />
  );

  const tabsMarkup = (
    <>
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange} fitted>
        <br />

        <div style={{ padding: "16px", display: "flex" }}>
          <div style={{ flex: 1 }}>
            <Filters
              queryValue={queryValue}
              filters={filters}
              appliedFilters={appliedFilters}
              onQueryChange={setQueryValue}
              onQueryClear={handleQueryValueRemove}
              onClearAll={handleClearAll}
            />
          </div>
        </div>

        <IndexTable
          resourceName={resourceName}
          itemCount={opportunityData.count}
          selectable={true}
          onSelectionChange={handleSelectionChange}
          selectedItemsCount={ allSelected ? 'All' : selectedResources.length }
          promotedBulkActions={promotedBulkActions}
          headings={[
            { title: "Opportunity" },
            { title: "Date" },
            { title: "Originator" },
            { title: "Amount" },
            { title: "Stage" },
            { title: "Recent Comment" },
          ]}
          sortable={[false, true, true, true, false, false]}
          onSort={handleOnSort}
          sortDirection={sortDirection}
          loading={isLoading || queryIsLoading || queryIsFetching || isDeleting}
        >
          {rowMarkup}
        </IndexTable>

        <br />

        <LegacyStack distribution={"center"}>{paginationMarkup}</LegacyStack>

        <br />
      </Tabs>

      {areYouSureModal}
    </>
  );

  return tabsMarkup;
};

AdminOpportunityTable.propTypes = {
  onOpportunityClick: PropTypes.func,
  itemLimit: PropTypes.number,
};

export default AdminOpportunityTable;
