import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import LoadingScreen from "../../components/Auth/Loading";
import ErrorScreen from "../../components/Auth/Error";
import VerifyEmail from "../../components/Auth/VerifyEmail";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { returnPublicRoutes } from '../../services/utility'

const AuthContext = React.createContext({
  isAuthenticated: false,
  isLoading: true,
});

export const CommonAuthProvider = ({ children }) => {
  const location = useLocation();
  const { search } = location;
  const query = queryString.parse(search);
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
    error,
    user,
    logout,
  } = useAuth0();

  const value = {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    error,
    user,
    logout,
  };

  useEffect(() => {
    // Make public routes public (/share, /section-179-calculator)
    if (returnPublicRoutes()) return;
    if (isLoading || isAuthenticated || error) return;

    const { login_hint, screen_hint } = query;

    loginWithRedirect({
      appState: { returnTo: window.location.pathname },
      authorizationParams: {
        login_hint,
        // screen_hint forces the login/signup page to display
        screen_hint,
      },
    });
  }, [
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    error,
    query,
    location.pathname,
  ]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorScreen error={error} user={user} handleLogout={logout} />;
  }

  if (user && !user.email_verified && !location.pathname.includes("/share")) {
    return <VerifyEmail user={user} handleLogout={logout} />;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

CommonAuthProvider.propTypes = {
  children: PropTypes.any,
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a CommonAuthProvider");
  }
  return context;
};
