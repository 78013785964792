import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { useDebounce } from "use-debounce";

import {
  Autocomplete,
  Icon,
  LegacyStack,
  Tag,
  Text,
  FormLayout,
  TextField,
} from "@shopify/polaris";
import { useField, useForm, notEmpty } from "@shopify/react-form";
import { SearchIcon, PlusCircleIcon } from "@shopify/polaris-icons";
import PropTypes from "prop-types";
import { validateEmail } from "../../../../utilities";

import {
  useSearchContactsQuery,
  useCreateVendorAdminContactMutation,
  useAddContactsAsVendorAdminMutation,
} from "../../../../services/api";

const VendorContactForm = forwardRef((props, ref) => {
  const {
    vendorId,
    vendorAdmins,
    onAfterShareWithContacts,
    isShowNewContactForm,
    addNewContact,
    setAddNewContact,
  } = props;

  const [contactSearchValue, setContactSearchValue] = useState("");
  const [showNewContactForm, setShowNewContactForm] = useState(false);
  const [notifMsg, setNotifMsg] = useState("");
  const [contactSearchQuery] = useDebounce(contactSearchValue, 500);

  const { data: searchContacts = [], isLoading: isLoadingSearchContacts } =
    useSearchContactsQuery(
      { search: contactSearchQuery }
      // {
      //   skip: contactSearchQuery.length > 0 && contactSearchQuery.length < 3,
      // }
    );

  const [selectedContactOptions, setSelectedContactOptions] = useState([]);
  const [selectedContactOptionIds, setSelectedContactOptionIds] = useState([]);
  const [contactOptions, setContactOptions] = useState([]);

  const [addContactsAsVendorAdmin, { isLoading: isAddingVendorAdmin }] =
    useAddContactsAsVendorAdminMutation();

  const [createVendorAdminContact, { isLoading: isCreatingVendorAdmin }] =
    useCreateVendorAdminContactMutation();

  const handleSelectContact = useCallback(
    (selectedContactIds) => {
      if (selectedContactIds.includes(selectedContactIds)) {
        return;
      } else {
        setSelectedContactOptionIds(selectedContactIds);

        const newSelectedContacts = searchContacts.filter((contact) => {
          return selectedContactIds.includes(contact.id);
        });

        setSelectedContactOptions([
          ...new Set([...selectedContactOptions, ...newSelectedContacts]),
        ]);
      }
    },
    [searchContacts, selectedContactOptions]
  );

  const handleRemoveContact = useCallback(
    (contact) => {
      const options = [...selectedContactOptions];
      options.splice(options.indexOf(contact), 1);
      setSelectedContactOptions(options);

      const optionIds = [...selectedContactOptionIds];
      optionIds.splice(optionIds.indexOf(contact.id), 1);
      setSelectedContactOptionIds(optionIds);
    },
    [selectedContactOptionIds, selectedContactOptions]
  );

  const shareButton = useRef(null);

  const handleAddAdminToVendor = () => {
    if (!selectedContactOptionIds.length) return;
    return addContactsAsVendorAdmin({
      vendorId,
      contactId: selectedContactOptionIds,
    })
      .unwrap()
      .then(() => {
        setSelectedContactOptionIds([]);
        setSelectedContactOptions([]);
        setContactSearchValue("");

        // pull focus from autocomplete
        //shareButton.current.click();

        //onAfterShareWithContacts();

        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (searchContacts.length > 0) {
      let contactList = searchContacts.map((contact, index) => {
        return {
          value: contact.id,
          label: `${contact.attributes.full_name} - ${contact.attributes.email}`,
        };
      });

      let filteredContactList = [];
      for (let i = 0; i < contactList.length; i++) {
        let inMemberships = false;
        for (let j = 0; j < vendorAdmins.length; j++) {
          if (vendorAdmins[j].attributes.contact_id === contactList[i].value)
            inMemberships = true;
        }

        if (!inMemberships) filteredContactList.push(contactList[i]);
      }

      setContactOptions(filteredContactList);
    }
  }, [searchContacts, vendorAdmins]);

  useImperativeHandle(ref, () => ({
    handleAddAdminToVendor: handleAddAdminToVendor,
  }));

  useEffect(() => {
    if (addNewContact) {
      submit();
    }
  }, [addNewContact]);

  const {
    fields,
    submit,
    submitting,
    reset,
    submitErrors,
    makeClean,
    dirty: newContactFormDirty,
  } = useForm({
    fields: {
      first_name: useField({
        value: "",
        validates: [notEmpty("First name is required")],
      }),
      last_name: useField({
        value: "",
        validates: [notEmpty("Last name is required")],
      }),
      email: useField({
        value: "",
        validates: [
          notEmpty("Email is required"),
          (email) => {
            if (email.length <= 3 || !validateEmail(email)) {
              return "Email must be valid";
            }
          },
        ],
      }),
    },
    async onSubmit(form) {
      return createVendorAdminContact({
        vendorId: vendorId,
        notifyContacts: true,
        inviteUser: true,
        notifMsg: notifMsg,
        contact: { ...form },
      })
        .unwrap()
        .then(() => {
          setAddNewContact(false);
          isShowNewContactForm(false);
          setSelectedContactOptionIds([]);
          setSelectedContactOptions([]);
          setContactSearchValue("");

          setShowNewContactForm(false);
          setNotifMsg("");

          // pull focus from autocomplete
          shareButton.current.click();

          onAfterShareWithContacts();

          return { status: "success" };
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  const verticalContentMarkup =
    selectedContactOptions.length > 0 ? (
      <LegacyStack spacing="extraTight" alignment="center">
        {selectedContactOptions.map((contact, index) => {
          return (
            <Tag
              key={`option${index}`}
              onRemove={() => handleRemoveContact(contact)}
            >
              {contact.attributes.full_name}
            </Tag>
          );
        })}
      </LegacyStack>
    ) : null;

  const contactShareTextField = (
    <>
      <Autocomplete.TextField
        label={""}
        onChange={setContactSearchValue}
        prefix={<Icon source={SearchIcon} tone="base" />}
        placeholder="Search"
        value={contactSearchValue}
        verticalContent={verticalContentMarkup}
        // connectedRight={
        //   <Button
        //     onClick={handleAddAdminToVendor}
        //     loading={false}
        //     disabled={!selectedContactOptionIds.length}
        //   >
        //     Add
        //   </Button>
        // }
      />
    </>
  );

  return <>
    {showNewContactForm ? (
      (<FormLayout>
        <FormLayout.Group>
          {/* polaris-migrator: Unable to migrate the following expression. Please upgrade manually. */}
          <TextField label="First name" type="text" {...fields.first_name} />
          {/* polaris-migrator: Unable to migrate the following expression. Please upgrade manually. */}
          <TextField label="Last name" type="text" {...fields.last_name} />
        </FormLayout.Group>
        {/* polaris-migrator: Unable to migrate the following expression. Please upgrade manually. */}
        <TextField label="Email" type="email" {...fields.email} />
        <TextField
          label="Message"
          type="text"
          multiline={2}
          value={notifMsg}
          onChange={setNotifMsg}
        />
        {/* <Button
          onClick={submit}
          loading={isCreatingVendorAdmin}
          disabled={!newContactFormDirty}
        >
          Add contact
        </Button> */}
      </FormLayout>)
    ) : (
      <>
        <Autocomplete
          allowMultiple
          id={"contact-share-autocomplete"}
          options={contactOptions}
          selected={selectedContactOptionIds}
          onSelect={handleSelectContact}
          textField={contactShareTextField}
          loading={isLoadingSearchContacts}
          emptyState={"No contacts found"}
          preferredPosition={"below"}
          actionBefore={{
            accessibilityLabel: "Invite contact",
            content: "Invite a new contact to this vendor",
            ellipsis: true,
            icon: PlusCircleIcon,
            onAction: () => {
              isShowNewContactForm(true);
              setShowNewContactForm(true);
              setNotifMsg("");
            },
          }}
        />
        <Text visuallyHidden as="h2">
          <input ref={shareButton} alt="share button input reference" />
        </Text>
      </>
    )}
  </>;
});
VendorContactForm.displayName = "VendorContactForm";
VendorContactForm.propTypes = {
  addNewContact: PropTypes.bool,
  setAddNewContact: PropTypes.func,
  isShowNewContactForm: PropTypes.func,
  onAfterShareWithContacts: PropTypes.func,
  vendorId: PropTypes.string,
  vendorAdmins: PropTypes.array,
};

export default VendorContactForm;
