import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";

import {
  Layout,
  Link,
  Icon,
  Button,
  ProgressBar,
  Text,
  BlockStack,
  InlineStack,
  Truncate,
  Toast,
} from "@shopify/polaris";
import { ChevronRightIcon, ShareIcon } from "@shopify/polaris-icons";

// import ShareModal from "../../components/ShareModal";
import ShareModal from "../../../../../components/Opportunity/ShareModal";

const ShareHeader = (props) => {
  const history = useHistory();
  const location = useLocation();
  const {
    contactId,
    opportunity,
    vendorProgram,
    progress = 25,
  } = props;

  const navigateHome = useCallback(() => {
    history.push("/");
  }, [history]);

  const navigateSection179 = useCallback(() => {
    history.push("/section-179-calculator");
  }, [history]);

  const displayVendorLogo = () => {
    if (vendorProgram.attributes.attachment_url) {
      return <img src={vendorProgram.attributes.attachment_url} className={"left-bar-logo"} />;
    }else{
      try {
        const vendorName = vendorProgram.attributes.name_slug;
        const src = require(`../../../../../assets/${vendorName}/logo.png`);

        if (vendorName === "default") {
          return <img src={src} className={"left-bar-logo default-logo"} />;
        }

        return <img src={src} className={"left-bar-logo"} />;
      } catch (err) {
        return;
      }
    }
  };

  const [showContactShareModal, setShowContactShareModal] =
    useState(contactId != undefined || false);

  const [showToast, setShowToast] = useState(location?.state?.showToast);
  const [customToastMessage, setCustomToastMessage] = useState("");
  const toggleShowToast = useCallback(
    () => setShowToast((active) => !active),
    []
  );

  const shareModal = (
    <ShareModal
      contactId={contactId}
      opportunityId={opportunity.id}
      opportunity={opportunity}
      toggleShowToast={toggleShowToast}
      showContactShareModal={showContactShareModal}
      setCustomToastMessage={setCustomToastMessage}
      setShowContactShareModal={setShowContactShareModal}
    />
  );

  const handleDismissToast = () => {
    const { state: currentState } = location;
    if (currentState) {
      delete currentState.toastMessage;
      delete currentState.showToast;
    }

    window.history.replaceState({ ...currentState }, "");
    setCustomToastMessage("");
    toggleShowToast();
  };

  const toastMarkup = showToast ? (
    <Toast
      content={location?.state?.toastMessage || customToastMessage}
      onDismiss={handleDismissToast}
    />
  ) : undefined;

  return <>
    <Layout.Section>
      <InlineStack align="space-between">
          <InlineStack spacing="tight">
            <Link monochrome onClick={navigateHome}>
              Home
            </Link>
            <Icon source={ChevronRightIcon} tone={"subdued"} />
            <div style={{ marginTop: "5px" }}>
              <Truncate>
                <p>{opportunity.attributes.name}</p>
              </Truncate>
            </div>
          </InlineStack>


          <Button  icon={ShareIcon} onClick={() => { setShowContactShareModal(true) }}>
            Share
          </Button>

      </InlineStack>
    </Layout.Section>

    <Layout.Section>{displayVendorLogo()}</Layout.Section>

    <Layout.Section>
      <BlockStack>
        <Text variant="headingLg" as="h5">
          {opportunity.attributes.client_name}
        </Text>

        <br/>
        
        <ProgressBar progress={progress} size="small" tone={"primary"} />
      </BlockStack>
    </Layout.Section>
    <Layout.Section></Layout.Section>

    {shareModal}
    {toastMarkup}
  </>;
};

ShareHeader.propTypes = {
  contactId: PropTypes.string,
  opportunity: PropTypes.object,
  vendorProgram: PropTypes.object,
  progress: PropTypes.number,
};

export default ShareHeader;
