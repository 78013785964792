import React, { useState, useContext, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { EmptyState, Layout, Page, Toast } from "@shopify/polaris";
import { WithinContentContext } from "@shopify/polaris/build/esm/utilities/within-content-context";
import { CurrentContactContext } from "../../contexts/Contact";
import { useAuth0 } from "@auth0/auth0-react";

import FortifyLogo from "../../../assets/images/fortify-logo-padding.svg";

import { useRequestOpportunityAccessMutation } from "../../services/api";
import LoadingScreen from "../../components/Auth/Loading";

const RequestAccess = (props) => {
  const history = useHistory();
  const { currentContact, isOrganizationAdmin } = useContext(
    CurrentContactContext
  );
  const { loginWithRedirect } = useAuth0();

  const { opportunityId: paramsOpportunityId, clientId: paramsClientId } =
    useParams();
  const opportunityId = paramsOpportunityId || props.opportunityId;
  const clientId = paramsClientId || props.clientId;

  const [requestSent, setRequestSent] = useState(false);

  const [requestOpportunityAccess, { isLoading: isRequesting }] =
    useRequestOpportunityAccessMutation();

  const navigateToHome = useCallback(() => {
    history.push("/");
  }, [history]);

  useEffect(() => {
    // check if current contact has access to this opportunity
    // redirect to request access page if access is already granted
    if (currentContact) {
      if (
        isOrganizationAdmin ||
        currentContact.attributes.opportunity_ids.includes(opportunityId)
      ) {
        history.push({
          pathname: `/clients/${clientId}/opportunities/${opportunityId}`,
        });
      }
    } else {
      loginWithRedirect({
        appState: { returnTo: window.location.pathname },
      });
    }
  }, [
    clientId,
    currentContact,
    history,
    isOrganizationAdmin,
    opportunityId,
    loginWithRedirect,
  ]);

  const handleRequestAccess = useCallback(() => {
    return requestOpportunityAccess({ id: opportunityId })
      .unwrap()
      .then((response) => {
        setRequestSent(true);
        setToastMessage("Access requested");
        toggleShowToast();
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
        return {
          status: "fail",
          errors: [{ message: "Could not send request." }],
        };
      });
  }, [opportunityId, requestOpportunityAccess, toggleShowToast]);

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const toggleShowToast = useCallback(
    () => setShowToast((active) => !active),
    []
  );
  const handleDismissToast = () => {
    toggleShowToast();
    setToastMessage("");
  };
  const toastMarkup = showToast ? (
    <Toast content={toastMessage} onDismiss={handleDismissToast} />
  ) : undefined;

  return currentContact ? (
    <Page narrowWidth>
      <Layout sectioned>
        <div className={"FortRequestAccess__Page"}>
          <WithinContentContext.Provider value>
            <EmptyState
              image={FortifyLogo}
              imageContained
              heading={
                requestSent
                  ? "Access requested"
                  : "You don't have access to this page"
              }
              action={{
                content: "Back home",
                onAction: navigateToHome,
                disabled: isRequesting,
              }}
              secondaryAction={
                requestSent
                  ? null
                  : {
                      content: "Request access",
                      onAction: () => handleRequestAccess(),
                      loading: isRequesting,
                    }
              }
              footerContent={
                <p style={{ width: "26rem", marginTop: "0.7rem" }}>
                  You are logged in as {currentContact.attributes.email}
                </p>
              }
            >
              {requestSent ? (
                <p>You will be notified by email if access is granted</p>
              ) : (
                <p>
                  You can access this page if the owner approves your request.
                </p>
              )}
            </EmptyState>
          </WithinContentContext.Provider>
        </div>
      </Layout>
      {toastMarkup}
    </Page>
  ) : (
    <LoadingScreen fade={false} />
  );
};

RequestAccess.propTypes = {
  opportunityId: PropTypes.string,
  clientId: PropTypes.string,
};

export default RequestAccess;
