import React from "react";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";
import { Text, BlockStack } from "@shopify/polaris";

const quotes = [
  "Reading quote data...",
  "Processing line items...",
  "Checking grammar and spelling...",
  "Adjusting the gravitational constant...",
  "Reticulating splines...",
  "Moving satellites into position...",
  "Spinning the wheel of fortune...",
  "Adjusting flux capacitor...",
  "Reassuring AI...",
  "May the force be with you...",
  "Checking things twice...",
  "Entangling superstrings...",
  "Searching for plot device...",
  "Installing dependencies...",
  "Distracted by cat gifs...",
  "Updating dependencies...",
  "Loading funny message...",
  "Mining some bitcoins...",
  "Updating to Windows Vista...",
  "Initializing the initializer...",
  "Pushing pixels...",
  "Downloading downloader...",
  "Debugging debugger...",
  "Grabbing extra minions...",
  "Doing the heavy lifting...",
  "Shifting the bits..."
];

const setQuotes = () => {
  return styled.div`
    width: 100%;
    height: auto;
    max-width: 320px;
    margin: 48px auto;
    text-align: center;
    transform: translateY(-50%);
    z-index: 999999;
  `;
};

const transition = keyframes`
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
`;

const setLoader = ({ speed }) => {
  return styled.div`
    width: 100%;
    height: 8px;
    background-color: #449da7;
    border-radius: var(--p-border-radius-base);
    max-width: 320px;
    animation: ${transition} ${speed}s cubic-bezier(0.25, 0.1, 0.25, 1) infinite;
  `;
};

class LoadingText extends React.Component {
  static get propTypes() {
    return {
      loading: PropTypes.bool.isRequired,
      random: PropTypes.bool,
      speed: PropTypes.number,
      backgroundColorQuotes: PropTypes.string,
      backgroundColorBackground: PropTypes.string,
      opacityBackground: PropTypes.number,
      fontSizeTitle: PropTypes.string,
      colorTitle: PropTypes.string,
      fontFamilyTitle: PropTypes.string,
      isMobile: PropTypes.bool
    };
  }

  constructor(props) {
    super(props);

    this.state = {
      currentQuotesIndex: 0,
    };

    this.startQuotes();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.loading !== nextProps.loading && !nextProps.loading) {
      clearInterval(this.quotesInterval);
    }
  }

  componentWillUnmount() {
    clearInterval(this.quotesInterval);
  }

  getNextQuote(quotes) {
    // if at the end of our quotes, then go back to the first quote
    if (this.state.currentQuotesIndex === quotes.length - 1) {
      return 0;
    }

    return this.state.currentQuotesIndex + 1;
  }

  getRandomQuote(quotes) {
    return Math.floor(Math.random() * quotes.length);
  }

  startQuotes() {
    const speedInMilliseconds = this.props.speed * 1000;

    this.quotesInterval = setInterval(() => {
      this.setState({
        currentQuotesIndex: this.props.random
          ? this.getRandomQuote(quotes)
          : this.getNextQuote(quotes),
      });
    }, speedInMilliseconds);
  }

  render() {
    const Loader = setLoader(this.props);
    const Quotes = setQuotes();

    return (
      <Quotes>
        <BlockStack>
          {!this.props.isMobile && <Text variant="headingMd" as="h2">{quotes[this.state.currentQuotesIndex]}</Text>}
          {this.props.isMobile && <p>{quotes[this.state.currentQuotesIndex]}</p>}
          <Loader />
        </BlockStack>
      </Quotes>
    );
  }
}

LoadingText.defaultProps = {
  random: false,
  speed: 1.5,
  backgroundColorLoader: "yellow",
  backgroundColorQuotes: "black",
  backgroundColorBackground: "black",
  opacityBackground: 0.5,
  fontSizeTitle: "18px",
  colorTitle: "white",
  fontFamilyTitle: "Arial",
};

export default LoadingText;
