import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useRef,
  useContext,
} from "react";
import { useDebounce } from "use-debounce";

import {
  Autocomplete,
  Icon,
  LegacyStack,
  Tag,
  Checkbox,
  TextField,
  Select,
  FormLayout,
} from "@shopify/polaris";
import { useField, useForm, notEmpty } from "@shopify/react-form";
import { SearchIcon, PlusCircleIcon } from "@shopify/polaris-icons";
import PropTypes from "prop-types";
import { validateEmail } from "../../../../utilities";

import {
  useSearchContactsQuery,
  useInviteMembershipsByTeamMutation,
  useCreateTeamContactMutation,
} from "../../../../services/api";
import { CurrentContactContext } from "../../../../contexts/Contact";

const VendorAdminTeamContactForm = forwardRef((props, ref) => {
  const {
    teamId,
    memberships,
    onAfterShareWithContacts,
    setInviteParams,
    isShowNewContactForm,
    addNewContact,
    setAddNewContact,
  } = props;

  const [createMembershipsByTeam, { isLoading: isCreatingMemberships }] =
    useInviteMembershipsByTeamMutation();

  const [createTeamContact, { isLoading: isCreatingTeamContact }] =
    useCreateTeamContactMutation();

  const { currentContact } = useContext(CurrentContactContext);
  const [contactSearchValue, setContactSearchValue] = useState("");
  const [contactSearchQuery] = useDebounce(contactSearchValue, 500);
  const [notifyContacts, setNotifyContacts] = useState(false);
  const [showNewContactForm, setShowNewContactForm] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [notifMsg, setNotifMsg] = useState("");
  const handleSelectChange = useCallback((value) => {
    setSelectedRole(value);
  }, []);
  const handleCheckboxChange = useCallback((newChecked) => {
    setNotifyContacts(newChecked);
    setNotifMsg("");
  }, []);

  const { data: searchContacts = [], isLoading: isLoadingSearchContacts } =
    useSearchContactsQuery({ search: contactSearchQuery });

  const [selectedContactOptions, setSelectedContactOptions] = useState([]);
  const [selectedContactOptionIds, setSelectedContactOptionIds] = useState([]);
  const [contactOptions, setContactOptions] = useState([]);

  const handleSelectContact = useCallback(
    (selectedContactIds) => {
      if (selectedContactIds.includes(selectedContactIds)) {
        return;
      } else {
        setSelectedContactOptionIds(selectedContactIds);

        const newSelectedContacts = searchContacts.filter((contact) => {
          return selectedContactIds.includes(contact.id);
        });

        setSelectedContactOptions([
          ...new Set([...selectedContactOptions, ...newSelectedContacts]),
        ]);
      }
    },
    [searchContacts, selectedContactOptions]
  );

  useEffect(() => {
    if (addNewContact) {
      submit();
    }
  }, [addNewContact]);

  const handleRemoveContact = useCallback(
    (contact) => {
      const options = [...selectedContactOptions];
      options.splice(options.indexOf(contact), 1);
      setSelectedContactOptions(options);

      const optionIds = [...selectedContactOptionIds];
      optionIds.splice(optionIds.indexOf(contact.id), 1);
      setSelectedContactOptionIds(optionIds);
    },
    [selectedContactOptionIds, selectedContactOptions]
  );

  const shareButton = useRef(null);

  useEffect(() => {
    setInviteParams({
      notifMsg,
      notifyContacts,
      contactIds: selectedContactOptionIds,
      isManager: selectedRole == "Admin",
    });
  }, [notifyContacts, selectedRole, notifMsg, selectedContactOptionIds]);

  useEffect(() => {
    if (searchContacts.length > 0) {
      let contactList = searchContacts.map((contact, index) => {
        return {
          value: contact.id,
          label: `${contact.attributes.full_name} - ${contact.attributes.email}`,
        };
      });

      let filteredContactList = [];
      for (let i = 0; i < contactList.length; i++) {
        let inMemberships = false;
        for (let j = 0; j < memberships.length; j++) {
          if (memberships[j].attributes.contact_id === contactList[i].value)
            inMemberships = true;
        }

        if (!inMemberships) filteredContactList.push(contactList[i]);
      }

      setContactOptions(filteredContactList);
    }
  }, [searchContacts, memberships]);

  const {
    fields,
    submit,
    submitting,
    reset,
    submitErrors,
    makeClean,
    dirty: newContactFormDirty,
  } = useForm({
    fields: {
      first_name: useField({
        value: "",
        validates: [notEmpty("First name is required")],
      }),
      last_name: useField({
        value: "",
        validates: [notEmpty("Last name is required")],
      }),
      email: useField({
        value: "",
        validates: [
          notEmpty("Email is required"),
          (email) => {
            if (email.length <= 3 || !validateEmail(email)) {
              return "Email must be valid";
            }
          },
        ],
      }),
    },
    async onSubmit(form) {
      return createTeamContact({
        teamId: teamId,
        notifyContacts: true,
        inviteUser: true,
        notifMsg: notifMsg,
        teamAdmin: selectedRole == "Admin",
        contact: { ...form },
      })
        .unwrap()
        .then(() => {
          setAddNewContact(false);
          isShowNewContactForm(false);
          setSelectedContactOptionIds([]);
          setSelectedContactOptions([]);
          setContactSearchValue("");

          setShowNewContactForm(false);
          setNotifyContacts(false);
          setNotifMsg("");

          // pull focus from autocomplete
          shareButton.current.click();

          onAfterShareWithContacts();

          return { status: "success" };
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  const verticalContentMarkup =
    selectedContactOptions.length > 0 ? (
      <LegacyStack spacing="extraTight" alignment="center">
        {selectedContactOptions.map((contact, index) => {
          return (
            <Tag
              key={`option${index}`}
              onRemove={() => handleRemoveContact(contact)}
            >
              {contact.attributes.full_name}
            </Tag>
          );
        })}
      </LegacyStack>
    ) : null;

  const contactShareTextField = (
    <>
      <Autocomplete.TextField
        label={""}
        onChange={setContactSearchValue}
        prefix={<Icon source={SearchIcon} tone="base" />}
        placeholder="Search"
        value={contactSearchValue}
        verticalContent={verticalContentMarkup}
        connectedRight={
          <div style={{ paddingLeft: "5px" }}>
            <Select
              options={["Member", "Admin"]}
              onChange={handleSelectChange}
              value={selectedRole}
            />
          </div>
        }
      />
    </>
  );

  return <>
    {showNewContactForm ? (
      (<FormLayout>
        <FormLayout.Group>
          {/* polaris-migrator: Unable to migrate the following expression. Please upgrade manually. */}
          <TextField label="First name" type="text" {...fields.first_name} />
          {/* polaris-migrator: Unable to migrate the following expression. Please upgrade manually. */}
          <TextField label="Last name" type="text" {...fields.last_name} />
        </FormLayout.Group>
        {/* polaris-migrator: Unable to migrate the following expression. Please upgrade manually. */}
        <TextField label="Email" type="email" {...fields.email} />
        <TextField
          label="Message"
          type="text"
          multiline={2}
          value={notifMsg}
          onChange={setNotifMsg}
        />
      </FormLayout>)
    ) : (
      <>
        <FormLayout>
          <Autocomplete
            allowMultiple
            id={"contact-share-autocomplete"}
            options={contactOptions}
            selected={selectedContactOptionIds}
            onSelect={handleSelectContact}
            textField={contactShareTextField}
            loading={isLoadingSearchContacts}
            emptyState={"No contacts found"}
            preferredPosition={"below"}
            actionBefore={{
              accessibilityLabel: "Invite contact",
              content: "Invite a new contact to this team",
              ellipsis: true,
              icon: PlusCircleIcon,
              onAction: () => {
                isShowNewContactForm(true);
                setShowNewContactForm(true);
                setNotifyContacts(true);
                setNotifMsg("");
              },
            }}
          />
          <Checkbox
            label="Notify contacts"
            checked={notifyContacts}
            onChange={handleCheckboxChange}
          />
          {notifyContacts && (
            <TextField
              label="Message"
              value={notifMsg}
              onChange={setNotifMsg}
              multiline={2}
              autoComplete="off"
            />
          )}
        </FormLayout>
      </>
    )}
  </>;
});
VendorAdminTeamContactForm.displayName = "TeamContactForm";
VendorAdminTeamContactForm.propTypes = {
  onAfterShareWithContacts: PropTypes.func,
  setInviteParams: PropTypes.func.isRequired,
  teamId: PropTypes.string,
  memberships: PropTypes.array,
  isShowNewContactForm: PropTypes.func,
  addNewContact: PropTypes.any,
  setAddNewContact: PropTypes.any,
};

export default VendorAdminTeamContactForm;
