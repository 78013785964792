import React, { useState, useCallback, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  useField,
  useForm,
  notEmpty,
} from "@shopify/react-form";

import {
  Card,
  Page,
  ResourceItem,
  ResourceList,
  Layout,
  Spinner,
  Text,
  OptionList,
  DropZone,
  Icon,
  Truncate,
  TextField,
  BlockStack,
  InlineStack,
  InlineGrid,
  Button,
} from "@shopify/polaris";

import { AttachmentIcon } from "@shopify/polaris-icons";

import {
  useCreateOpportunityCreditSubmissionMutation,
  useGetOpportunityQuery,
  useGetLendersQuery,
} from "../../services/api";
import { formatCurrency } from "../../utilities";
import pluralize from "../../pluralizeConfig";

import LoadingScreen from "../../components/Auth/Loading";

import { CurrentContactContext } from "../../contexts/Contact";

const CreditSubmissionNew = (props) => {
  const history = useHistory();

  const { currentContact } = useContext(CurrentContactContext)

  const { opportunityId: opportunityId } = useParams();

  const { data: opportunity = { attributes: {} } } = useGetOpportunityQuery(
    opportunityId,
    { skip: !opportunityId }
  );

  const { data: lenders, isLoading: isLoadingLenders } = useGetLendersQuery();
  const [selectedLenders, setSelectedLenders] = useState([]);
  const [lenderOptionList, setLenderOptionList] = useState([]);
  useEffect(() => {
    let lenderList = [];
    for (let i = 0; i < lenders?.length; i++) {
      lenderList.push({
        value: lenders[i].attributes.name,
        label: lenders[i].attributes.name,
      });
    }
    setLenderOptionList(lenderList);
  }, [lenders]);

  const navigateBack = () => {
    history.push({
      pathname: `/clients/${opportunity.attributes.client_id}/opportunities/${opportunityId}`,
      search: `?tab=credit-submissions`,
    });
  };

  const navigateToOpportunitiesShow = (state = {}) => {
    history.push({
      pathname: `/clients/${opportunity.attributes.client_id}/opportunities/${opportunityId}`,
      search: `?tab=credit-submissions`,
      state: state,
    });
  };

  const navigateToAccount = () => {
    history.push({
      pathname: `/admin/clients/${opportunity.attributes.client_id}`,
    });
  };

  const [createCreditSubmission, { isLoading: isCreatingCreditSubmission }] =
    useCreateOpportunityCreditSubmissionMutation();

  const {
    fields,
    submit,
    submitting,
    reset,
    submitErrors,
    makeClean,
    dirty,
    dynamicLists,
  } = useForm({
    fields: {
      amount_requested: useField({
        value: opportunity.attributes.total_finance_amount,
        validates: [
          notEmpty("Amount requested is required."),
        ],
      }),
    },
    async onSubmit(form) {
      let lenderIds = [];
      for (let i = 0; i < selectedLenders.length; i++) {
        for (let j = 0; j < lenders.length; j++) {
          if (lenders[j].attributes.name === selectedLenders[i])
            lenderIds.push(lenders[j].id);
        }
      }

      return createCreditSubmission({
        opportunityId: opportunityId,
        lender_ids: lenderIds,
        ...form,
      })
        .unwrap()
        .then(() => {
          navigateToOpportunitiesShow({
            showToast: true,
            toastMessage: "Credit submission created.",
          });
          return { status: "success" };
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  const primaryAction = {
    content: `Create credit ${pluralize("submission", selectedLenders.length)}`,
    disabled: selectedLenders.length == 0,
    loading: submitting || isCreatingCreditSubmission,
    onAction: submit,
  };

  const [files, setFiles] = useState([]);

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );

  const attachmentResourceName = {
    singular: "attachment",
    plural: "attachments",
  };

  const attachmentResourceList = (attachment) => {
    return (
      <ResourceItem
        id={"id"}
        onClick={() => console.log("TODO: handle attachment click")}
        accessibilityLabel={`Quote attachment`}
        verticalAlignment="center"
      >
        <InlineStack gap="400">
          <div className="credit-submissions-icon">
            <Icon source={AttachmentIcon} tone="subdued" />
          </div>
          <div style={{ marginTop: "10px" }}>
            <h3>
              <Text as="span" fontWeight="semibold" >Quote</Text>
            </h3>
          </div>
        </InlineStack>
      </ResourceItem>
    );
  };

  const pageMarkup = (
    <Page
      title={opportunity?.attributes.name || ""}
      subtitle={"New credit submission"}
      primaryAction={primaryAction}
      backAction={{ content: "Back to credit submissions", onAction: navigateBack }}
      fullWidth
    >

      <Layout>
        <Layout.Section primary>
          {/* DETAILS */}
          <Card>
            <div>
              <InlineStack>
                <div className="FortForm__NumericTextField" style={{ width: "100%"}}>
                  <TextField
                    label="Amount Requested"
                    prefix={"$"}
                    type={"number"}
                    {...fields.amount_requested}
                  />
                </div>
              </InlineStack>
            </div>

            <br/>

            <div>
              <InlineStack align="space-between">
                <Text variant="headingMd" as="h6">Opportunity details</Text>
                <Button
                  variant="plain"
                  onClick={navigateToOpportunitiesShow}
                >
                  Edit Opportunity
                </Button>
              </InlineStack>

              <br/>

              <BlockStack gap="100">
                <Text variant="bodySm" as="p">
                  <Text as="span" tone="subdued">
                    Product description
                  </Text>
                </Text>
                <p>
                  <Truncate>
                    {opportunity?.attributes.product_description}
                  </Truncate>
                </p>
              </BlockStack>

              <br />

              <InlineGrid gap="200" columns={2}>
                <BlockStack gap="100">
                  <Text variant="bodySm" as="p">
                    <Text as="span" tone="subdued">Structure</Text>
                  </Text>
                  <p>{opportunity?.attributes.financing_option_structure}</p>
                </BlockStack>
                <BlockStack gap="100">
                  <Text variant="bodySm" as="p">
                    <Text as="span" tone="subdued">Timing</Text>
                  </Text>
                  <p>{opportunity?.attributes.payment_type || "-"}</p>
                </BlockStack>
              </InlineGrid>

              <br />

              <BlockStack gap="100">
                <Text variant="bodySm" as="p">
                  <Text as="span" tone="subdued">
                    Total quote amounts
                  </Text>
                </Text>
                <p>
                  {formatCurrency(
                    opportunity?.attributes.total_finance_amount +
                      opportunity?.attributes.total_sales_tax
                  )}
                </p>
              </BlockStack>
            </div>

            <br/>

            <div>
              <InlineStack align="space-between">
                <Text variant="headingMd" as="h6">Client account</Text>
                <Button
                  variant="plain"
                  onClick={navigateToAccount}
                >
                  Edit account
                </Button>
              </InlineStack>

              <br/>

              <BlockStack gap="100">
                <Text variant="bodySm" as="p">
                  <Text as="span" tone="subdued">Legal name</Text>
                </Text>
                <p>{opportunity?.attributes.client_name}</p>
              </BlockStack>
              <br />

              <InlineGrid gap="200" columns={4}>
                <BlockStack gap="100">
                  <Text variant="bodySm" as="p">
                    <Text as="span" tone="subdued">Time in business</Text>
                  </Text>
                  <p>4 years</p>
                </BlockStack>
                <BlockStack gap="100">
                  <Text variant="bodySm" as="p">
                    <Text as="span" tone="subdued">UCCs</Text>
                  </Text>
                  <p>4</p>
                </BlockStack>
                <BlockStack gap="100">
                  <Text variant="bodySm" as="p">
                    <Text as="span" tone="subdued">Employees</Text>
                  </Text>
                  <p>45</p>
                </BlockStack>
                <BlockStack gap="100">
                  <Text variant="bodySm" as="p">
                    <Text as="span" tone="subdued">Comp credit</Text>
                  </Text>
                  <p>$450,000</p>
                </BlockStack>
              </InlineGrid>
              <br />

              <BlockStack gap="100">
                <Text variant="bodySm" as="p">
                  <Text as="span" tone="subdued">Street Address</Text>
                </Text>
                <p>{opportunity?.attributes.customer_billing_street}</p>
              </BlockStack>
              <br />

              <InlineGrid gap="200" columns={3}>
                <BlockStack gap="100">
                  <Text variant="bodySm" as="p">
                    <Text as="span" tone="subdued">City</Text>
                  </Text>
                  <p>{opportunity?.attributes.customer_billing_city}</p>
                </BlockStack>
                <BlockStack gap="100">
                  <Text variant="bodySm" as="p">
                    <Text as="span" tone="subdued">State</Text>
                  </Text>
                  <p>{opportunity?.attributes.customer_billing_state}</p>
                </BlockStack>
                <BlockStack gap="100">
                  <Text variant="bodySm" as="p">
                    <Text as="span" tone="subdued">Zip</Text>
                  </Text>
                  <p>{opportunity?.attributes.customer_billing_postal_code}</p>
                </BlockStack>
              </InlineGrid>
            </div>
          </Card>

          <br />

          {/* ATTACHMENTS */}
          <Card>
            <InlineStack>
              <Text variant="headingMd" as="h6">Attachments</Text>
            </InlineStack>

            <br/>

            <div>
              <DropZone onDrop={handleDropZoneDrop} disabled>
                <DropZone.FileUpload actionHint="or drop files to upload" />
              </DropZone>
            </div>

            <div flush>
              <ResourceList
                resourceName={attachmentResourceName}
                items={[]}
                renderItem={(item) => attachmentResourceList(item)}
                selectedItems={[]}
                onSelectionChange={() => {}}
                loading={false}
                showHeader
                selectable
              />
            </div>
          </Card>
        </Layout.Section>

        <Layout.Section variant="oneThird">
          {/* LENDERS SECTION */}
          <Card>
            <InlineStack align="space-between">
              <Text variant="headingMd" as="h6">Lenders</Text>
            </InlineStack>

            <br/>

            <div>
              <Text as="span" tone="subdued">
                Select the lenders that will receive this submission.
              </Text>
            </div>
            {isLoadingLenders && (
              <div>
                <div style={{ margin: "auto", width: "10%" }}>
                  <Spinner />
                </div>
              </div>
            )}
            {!isLoadingLenders && (
              <div flush>
                <OptionList
                  onChange={setSelectedLenders}
                  options={lenderOptionList}
                  selected={selectedLenders}
                  allowMultiple
                />
              </div>
            )}
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );

  return currentContact ? pageMarkup : <LoadingScreen />;
};

export default CreditSubmissionNew;
