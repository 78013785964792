import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import http from "../http-common";

const CurrentUserContext = React.createContext();

const CurrentUserProvider = ({ children }) => {
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  useEffect(() => {
    http.defaults.headers.common["current-timezone"] = timezone;
  }, [timezone]);

  return (
    <CurrentUserContext.Provider
      value={{
        timezone: timezone,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

CurrentUserProvider.propTypes = {
  children: PropTypes.any,
};

export { CurrentUserContext, CurrentUserProvider };
