import React from "react";
import PropTypes from "prop-types";
import {
  Page as PolarisPage,
  Layout,
  EmptyState,
  FooterHelp,
  Button,
} from "@shopify/polaris";
import { WithinContentContext } from "@shopify/polaris/build/esm/utilities/within-content-context";
import FortifyLogo from "../../../assets/images/fortify-logo-padding.svg";
import AppProviderWrapper from "../App/AppProviderWrapper";

const VerifyEmail = ({ handleLogout }) => {
  const heading = "We've sent a verification email";

  return (
    <AppProviderWrapper>
      <PolarisPage narrowWidth>
        <Layout sectioned>
          <div className={"FortAuth0Error__Page"}>
            <WithinContentContext.Provider value>
              <EmptyState
                image={FortifyLogo}
                imageContained
                heading={heading}
                // TODO - action for isEmailVerificationError
                // should be to resend verification email
                //
                action={undefined}
              >
                Click the link in your email to verify your account. If you
                can't find the email, check your spam folder.
              </EmptyState>
            </WithinContentContext.Provider>
          </div>

          <FooterHelp>
            After verifying your email{" "}
            <Button plain onClick={handleLogout}>
              log in
            </Button>
          </FooterHelp>
        </Layout>
      </PolarisPage>
    </AppProviderWrapper>
  );
};

VerifyEmail.propTypes = {
  handleLogout: PropTypes.func,
};

export default VerifyEmail;
