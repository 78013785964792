import React from "react";

import {
  Text,
  Button,
  Divider,
  Card,
  BlockStack,
  InlineStack,
  Bleed,
  Icon,
  Tooltip,
} from "@shopify/polaris";

import Opportunity from "../../types/Opportunity";

import { formatCurrency } from "../../utilities";

import { LockIcon } from "@shopify/polaris-icons";

type Props = {
  lenderBuyRate: number;
  estimatedLenderBuyRate: number;
  buyRate: number;
  sellRate: number;
  customerInterestRate: number;
  residualValue: number;
  blindDiscount: number;
  buyRateSpread: number;
  sellRateSpread: number;
  netPayment: number;
  opportunity: Opportunity;
  onEditSummary: () => void;
};

const OrgAdminSummaryCard = (props: Props) => {
  const {
    lenderBuyRate,
    estimatedLenderBuyRate,
    buyRate,
    sellRate,
    residualValue,
    customerInterestRate,
    blindDiscount = 0,
    buyRateSpread,
    sellRateSpread,
    netPayment,
    opportunity,
    onEditSummary,
  } = props;

  const blindDiscountPercent =
    (blindDiscount / parseFloat(opportunity.attributes.total_finance_amount)) *
    100;
  const blindDiscountPercentRounded =
    Math.round(blindDiscountPercent * 100) / 100;

  const customerRateSpread = blindDiscount * -1;

  const totalSpread =
    buyRateSpread + sellRateSpread + opportunity.attributes.documentation_fee;
  const totalSpreadPercent =
    (totalSpread / parseFloat(opportunity.attributes.total_finance_amount)) *
    100;
  const totalSpreadPercentRounded = Math.round(totalSpreadPercent * 100) / 100;

  const formattedBuyRateSpread = formatCurrency(
    buyRateSpread === 0 ? Math.abs(buyRateSpread) : buyRateSpread
  );

  return (
    <Card padding="400">
      <BlockStack gap="400">
        <InlineStack align="space-between">
          <Text variant="headingMd" as="h6">
            Admin Summary
          </Text>

          <Button variant="plain" onClick={onEditSummary}>
            Edit
          </Button>
        </InlineStack>

        <BlockStack gap="200">
          <InlineStack align="space-between">
            <Text as="p">Lender buy rate</Text>

            <InlineStack>
              {lenderBuyRate && (
                <Tooltip content="Inherited from selected credit submission">
                  <Icon source={LockIcon} tone="subdued" />
                </Tooltip>
              )}
              <Text as="p">{lenderBuyRate || estimatedLenderBuyRate}%</Text>
            </InlineStack>
          </InlineStack>

          <InlineStack align="space-between">
            <Text as="p">Buy rate</Text>

            <Text as="p">{buyRate}%</Text>
          </InlineStack>

          <InlineStack align="space-between">
            <Text as="p">Sell rate</Text>

            <Text as="p">{sellRate}%</Text>
          </InlineStack>

          <InlineStack align="space-between">
            <Text as="p">Customer rate</Text>

            <Text as="p">{customerInterestRate}%</Text>
          </InlineStack>
        </BlockStack>

        <Bleed marginInline={"400"}>
          <Divider />
        </Bleed>

        <BlockStack gap="200">
          <InlineStack align="space-between">
            <Text as="p">Total Invoice</Text>

            <Text as="p">
              {formatCurrency(opportunity.attributes.total_finance_amount)}
            </Text>
          </InlineStack>

          <InlineStack align="space-between">
            <Text as="p">Blind discount</Text>

            <Text as="p">{formatCurrency(blindDiscount)}</Text>
          </InlineStack>

          <InlineStack align="space-between">
            <Text as="p" tone="subdued">
              Blind discount %
            </Text>

            <Text as="p" tone="subdued">
              {blindDiscountPercentRounded}%
            </Text>
          </InlineStack>

          <InlineStack align="space-between">
            <Text as="p">Net payment</Text>

            <Text as="p">{formatCurrency(netPayment)}</Text>
          </InlineStack>

          <InlineStack align="space-between">
            <Text as="p">Residual</Text>

            <Text as="p">{formatCurrency(residualValue)}</Text>
          </InlineStack>
        </BlockStack>

        <Bleed marginInline={"400"}>
          <Divider />
        </Bleed>

        <BlockStack gap="200">
          <InlineStack align="space-between">
            <Text as="p">Doc fee</Text>

            <Text as="p">
              {opportunity.attributes.documentation_fee_formatted}
            </Text>
          </InlineStack>

          <InlineStack align="space-between">
            <Text as="p">Buy rate spread</Text>

            <Text as="p">{formattedBuyRateSpread}</Text>
          </InlineStack>

          <InlineStack align="space-between">
            <Text as="p">Sell rate spread</Text>

            <Text as="p">{formatCurrency(sellRateSpread)}</Text>
          </InlineStack>

          <InlineStack align="space-between">
            <Text as="p">Customer rate spread</Text>

            <Text as="p">{formatCurrency(customerRateSpread || 0)}</Text>
          </InlineStack>

          <InlineStack align="space-between">
            <Text fontWeight="bold" as="p">
              Total spread
            </Text>

            <Text fontWeight="bold" as="p">
              {formatCurrency(totalSpread)}
            </Text>
          </InlineStack>

          <InlineStack align="space-between">
            <Text as="p" tone="subdued">
              Total Spread %
            </Text>

            <Text as="p" tone="subdued">
              {totalSpreadPercentRounded}%
            </Text>
          </InlineStack>
        </BlockStack>
      </BlockStack>
    </Card>
  );
};

export default OrgAdminSummaryCard;
