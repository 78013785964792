import React, { useContext } from "react";
// import { useParams } from "react-router-dom";

import {
  BlockStack,
  Card,
  Text,
} from "@shopify/polaris";

import LoadingScreen from "../../components/Auth/Loading";

import { CurrentContactContext } from "../../contexts/Contact";

const OpportunitiesEmbeddedSendingSent = () => {

  const { currentContact } = useContext(CurrentContactContext)

  // const { id: opportunityId } = useParams();

  const embeddedSendingLayout = (
    <Card padding="0">
      <Box padding="400">
        <BlockStack gap="200">
          <Text variant="headingLg" as="p">
            Sending Successful!
          </Text>
        </BlockStack>
        <BlockStack gap="200">
          <Text variant="headingLg" as="p">
            Documents have been successfully sent to the the customer
          </Text>
        </BlockStack>
      </Box>
    </Card>
  )

  return currentContact ? embeddedSendingLayout : <LoadingScreen />;
};

export default OpportunitiesEmbeddedSendingSent;
