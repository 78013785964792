// https://scottspence.com/posts/react-context-api#use-the-react-context-api

import React from "react";
import PropTypes from "prop-types";

export const FinancingContext = React.createContext();

const financingTypes = [
  "standard",
  "subscription",
  "fair_market_value",
  "net_terms",
  "dollar_out_lease",
  "saas",
  "rental"
];

export class FinancingProvider extends React.Component {
  state = {
    newFinancingOptionType: undefined,
  };

  handleNewFinancingOptionTypeChange = (type) => {
    if (financingTypes.includes(type)) {
      this.setState({ newFinancingOptionType: type });
    }
  };

  render() {
    return (
      <FinancingContext.Provider
        value={{
          ...this.state,
          handleNewFinancingOptionTypeChange:
            this.handleNewFinancingOptionTypeChange,
        }}
      >
        {this.props.children}
      </FinancingContext.Provider>
    );
  }
}

FinancingProvider.propTypes = {
  children: PropTypes.any,
};
