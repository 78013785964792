import React, { useState, useEffect, useCallback, useContext } from "react";
import {
  useField,
  useForm,
  notEmpty,
  useChoiceField,
} from "@shopify/react-form";
import { useHistory, useParams } from "react-router-dom";

import {
  Page,
  InlineStack,
  BlockStack,
  Layout,
  Modal,
  Spinner,
  TextField,
  Button,
  Select,
  Text,
  Checkbox,
  Icon,
  FormLayout,
  Card,
  Badge,
  Divider,
  RangeSlider,
  Box,
  Banner,
} from "@shopify/polaris";
import { PlusCircleIcon, PlusIcon } from "@shopify/polaris-icons";

import {
  useGetAdminVendorProgramQuery,
  useGetAdminVendorProgramTemplatesQuery,
  useUpdateAdminVendorProgramMutation,
  useCreateFinancingOptionTemplateMutation,
  useUpdateFinancingOptionTemplateMutation,
  useCreateVendorProgramEnvelopeTemplateMutation,
  useDeleteVendorProgramTemplatesMutation,
  useGetDocumentsQuery,
  useDeleteVendorProgramAttachmentMutation,
} from "../../../services/api";

import { toSentenceCase, toHumanReadableFinanceType } from "../../../utilities";

import FadeIn from "../../../components/FadeIn";
import AttachmentUpload from "../../../components/Admin/VendorProgram/AttachmentUpload";
import FinanceOptionIndexTable from "../../../components/VendorProgram/FinanceOptionIndexTable";
import EnvelopeIndexTable from "../../../components/VendorProgram/EnvelopeIndexTable";
import AttachmentsIndexTable from "../../../components/VendorProgram/AttachmentsIndexTable";
import LoadingScreen from "../../../components/Auth/Loading";

import { CurrentContactContext } from "../../../contexts/Contact";
import { useToast } from "../../../contexts/Toast";

const AdminVendorProgramsShow = () => {
  const history = useHistory();

  const { currentContact } = useContext(CurrentContactContext);

  const { showToastWithMessage } = useToast();

  const { id: vendorProgramId } = useParams();

  const navigateToAdminVendorProgram = () => {
    history.push({
      pathname: `/admin/vendor_programs`,
    });
  };

  const { data: vendorProgram = {}, isLoading } =
    useGetAdminVendorProgramQuery(vendorProgramId);

  const {
    data: financeOptionTemplates = [],
    isLoading: isLoadingFinanceOptionTemplates,
    isFetching: isFetchingFinanceOptionTemplates,
  } = useGetAdminVendorProgramTemplatesQuery({
    id: vendorProgramId,
    template: "financing_options",
  });

  const {
    data: envelopeTemplates = [],
    isLoading: isLoadingEnvelopeTemplates,
    isFetching: isFetchingEnvelopeTemplates,
  } = useGetAdminVendorProgramTemplatesQuery({
    id: vendorProgramId,
    template: "envelopes",
  });

  const [deleteVendorProgramTemplates, { isLoading: isDeleting }] =
    useDeleteVendorProgramTemplatesMutation();

  const { data: documents = [], isLoading: isLoadingDocuments } =
    useGetDocumentsQuery({ search: "" });

  const [deleteVendorProgramAttachment, { isLoading: isDeletingAttachment }] =
    useDeleteVendorProgramAttachmentMutation();

  const [requiresCreditReview, setRequiresCreditReview] = useState(false);
  const [achPaymentsOnly, setAchPaymentsOnly] = useState(false);
  const [requiresCreditApplication, setRequiresCreditApplication] =
    useState(false);
  const [name, setName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [docusignBrandId, setDocusignBrandId] = useState("");

  const [showEditVendorProgramModal, setShowEditVendorProgramModal] =
    useState(false);
  const [showFinanceOptionModal, setShowFinanceOptionModal] = useState(false);
  const [showAddEnvelopeModal, setShowAddEnvelopeModal] = useState(false);
  const [showAddAttachmentModal, setShowAddAttachmentModal] = useState(false);

  const [paymentStructure, setPaymentStructure] =
    useState("Scheduled payments");
  const [showPercentageErrorBanner, setShowPercentageErrorBanner] =
    useState(false);
  const [financingTypes, setFinancingTypes] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [envelopeFinancingType, setEnvelopeFinancingType] = useState("");
  const [visibleClientRate, setVisibleClientRate] = useState(false);
  const [baseSegmentsMonths, setBaseSegmentsMonths] = useState(0);
  const [counterSignature, setCounterSignature] = useState(false);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [attachmentDocuments, setAttachmentDocuments] = useState({
    0: "",
    1: "",
    2: "",
  });

  // TODO: Refactor to selectedId array like selectedEnvelopeTemplateDocuments
  const [financeTypesChecks, setFinanceTypesChecks] = useState([]);

  // NOTE: Using selectedEnvelopeTemplateDocuments to keep track of selected
  // documents for envelope template rather than the "checks" style used for
  // finance types and attachments. This should be easier to manage and will
  // allow resets to be cleaner.
  const [
    selectedEnvelopeTemplateDocuments,
    setSelectedEnvelopeTemplateDocuments,
  ] = useState([]);
  // TODO: Refactor to selectedId array like selectedEnvelopeTemplateDocuments
  const [attachmentChecks, setAttachmentChecks] = useState([]);
  const [segments, setSegments] = useState([]);
  const [interestRate, setInterestRate] = useState("");
  const [buyRate, setBuyRate] = useState("");
  const [sellRate, setSellRate] = useState("");

  // Net Terms Values
  const [customerDiscount, setCustomerDiscount] = useState(0);
  const [discountVisible, setDiscountVisible] = useState(false);
  const [downPaymentVisible, setDownPaymentVisible] = useState(false);
  const [paymentInstallments, setPaymentInstallments] = useState([]);

  const [enableDownPaymentField, setEnableDownPaymentField] = useState(false);

  const [submittingEnvelopeTemplate, setSubmittingEnvelopeTemplate] =
    useState(false);

  const [financeOptionTemplateModalTitle, setFinanceOptionTemplateModalTitle] =
    useState(`Add financing option template`);

  const [toUpdateFinanceOptionTemplate, setToUpdateFinanceOptionTemplate] =
    useState(false);

  const [financeOptionTemplateId, setFinanceOptionTemplateId] = useState(null);

  const handleDownPaymentVisibleChange = (val) => {
    fields.down_payment.value = "";
    setDownPaymentVisible(val);
  };

  const handleChangeAllowedFinanceTypes = (index) => {
    setFinanceTypesChecks({
      ...financeTypesChecks,
      [index]: !financeTypesChecks[index],
    });
  };

  const handleChangeSelectedEnvelopeTemplateDocuments = (
    checked,
    documentId
  ) => {
    if (checked) {
      setSelectedEnvelopeTemplateDocuments([
        ...selectedEnvelopeTemplateDocuments,
        documentId,
      ]);
    } else {
      setSelectedEnvelopeTemplateDocuments(
        selectedEnvelopeTemplateDocuments.filter(
          (document) => document != documentId
        )
      );
    }
  };

  const handleChangeAttachmentChecks = (index) => {
    setAttachmentChecks({
      ...attachmentChecks,
      [index]: !attachmentChecks[index],
    });
  };

  const handleChangeAttachmentDocuments = (value, index) => {
    setAttachmentDocuments({
      ...attachmentDocuments,
      [index]: value,
    });
  };

  const handleChangeSegments = (value, key, index) => {
    let segment = segments[index];
    segment[`${key}`] = value;
    setSegments({
      ...segments,
      [index]: segment,
    });
  };
  const addSegments = useCallback(() => {
    if (Object.keys(segments).length > 0) {
      const segmentKeys = Object.keys(segments);
      setSegments({
        ...segments,
        [`${Number(segmentKeys[segmentKeys.length - 1]) + 1}`]: {
          months: 0,
          amount: 0,
        },
      });
    } else {
      setSegments({
        0: { months: 0, amount: 0 },
      });
    }
  }, [segments]);
  const deleteSegments = (index) => {
    let tempSegments = { ...segments };
    delete tempSegments[index];
    setSegments({ ...tempSegments });
  };

  const [selectedFOResources, setSelectedFOResources] = useState([]);
  const [selectedEnResources, setSelectedEnResources] = useState([]);
  const [selectedAttachResources, setSelectedAttachResources] = useState([]);

  const [allSelectedFinance, setAllSelectedFinance] = useState(false);
  const [allSelectedEnvelope, setAllSelectedEnvelope] = useState(false);
  const [allSelectedAttachment, setAllSelectedAttachment] = useState(false);

  const [selectedTemplateBulkDelete, setSelectedTemplateBulkDelete] =
    useState("");
  const [isDeletingTemplates, setIsDeletingTemplates] = useState(false);

  const handleSelectTemplateBulkDelete = useCallback(
    (tableTemplate) => setSelectedTemplateBulkDelete(tableTemplate),
    [selectedTemplateBulkDelete]
  );

  const bulkDeleteFinanceOptionTemplates = () => {
    let templateIds = [];
    let whichAllSelected = false;
    let whichSelectedResources = [];
    let whichTemplates = [];

    switch (selectedTemplateBulkDelete) {
      case "finance":
        whichAllSelected = allSelectedFinance;
        whichTemplates = [...financeOptionTemplates];
        whichSelectedResources = [...selectedFOResources];
        break;
      case "envelope":
        whichAllSelected = allSelectedEnvelope;
        whichTemplates = [...envelopeTemplates];
        whichSelectedResources = [...selectedEnResources];
        break;
      case "attachment":
        whichAllSelected = allSelectedAttachment;
        whichTemplates = [
          ...vendorProgram.attributes.opportunity_attachment_templates,
        ];
        whichSelectedResources = [...selectedAttachResources];
        break;
    }

    // allSelected
    if (whichAllSelected) {
      templateIds = whichTemplates.map((templates) => {
        return templates.attributes.id;
      });
      //for (let i = 0; i < whichTemplates.count; i++) {
      //  templateIds.push(whichTemplates.data[i].id);
      //}
    } else {
      whichSelectedResources.forEach((resource) => {
        const resourceIndex = resource.split("-")[1];
        if (whichTemplates[resourceIndex]) {
          templateIds.push(whichTemplates[resourceIndex].id);
        }
      });
    }
    // TODO: Set generic api hook for vendor program templates delete
    return deleteVendorProgramTemplates({
      id: vendorProgramId,
      which_template: selectedTemplateBulkDelete,
      templateIds: templateIds.join(","),
    })
      .unwrap()
      .then(() => {
        handleIndexTableSelection([], selectedTemplateBulkDelete, false);
        setIsDeletingTemplates(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteAttachment = () => {
    return deleteVendorProgramAttachment({ id: vendorProgramId })
      .unwrap()
      .then(() => {
        handleIndexTableSelection([], selectedTemplateBulkDelete, false);
        setIsDeletingTemplates(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const handleOpenConfirmationModal = useCallback(
    (selectedTable) => {
      handleSelectTemplateBulkDelete(selectedTable);
      setOpenConfirmationModal(true);
    },
    [selectedTemplateBulkDelete]
  );
  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };
  const handleConfirmDeleteModal = () => {
    setIsDeletingTemplates(true);
    setOpenConfirmationModal(false);

    bulkDeleteFinanceOptionTemplates();
  };

  const handleSelectionChange = (
    selectionType,
    toggleType,
    selection,
    tableSelect
  ) => {
    let resourceList = [];
    let selectionList = [];
    let allSelectedResource = false;
    let selectedTemplates = [];

    switch (tableSelect) {
      case "finance":
        resourceList = [...selectedFOResources];
        selectionList = [...selectedFOResources];
        allSelectedResource = allSelectedFinance;
        selectedTemplates = [...financeOptionTemplates];
        break;
      case "envelope":
        resourceList = [...selectedEnResources];
        selectionList = [...selectedEnResources];
        allSelectedResource = allSelectedEnvelope;
        selectedTemplates = [...envelopeTemplates];
        break;
      case "attachment":
        resourceList = [...selectedAttachResources];
        selectionList = [...selectedAttachResources];
        allSelectedResource = allSelectedAttachment;
        selectedTemplates = [
          ...vendorProgram.attributes.opportunity_attachment_templates,
        ];
        break;
    }

    if (selectionType === "single") {
      // Check if in selection
      const inSelectionIndex = resourceList.find((resource) => {
        return resource == selection;
      });

      if (inSelectionIndex) {
        // Remove if in list
        selectionList.splice(inSelectionIndex, 1);
      } else {
        // Add if not
        selectionList.push(selection);
      }

      handleIndexTableSelection(selectionList, tableSelect, false);
    } else {
      if (
        allSelectedResource ||
        resourceList.length === selectedTemplates.length
      ) {
        // Clear selection
        handleIndexTableSelection([], tableSelect, false);
      } else {
        // Select all
        const list = selectedTemplates.map(
          (template, index) => `${tableSelect}-${index}`
        );
        handleIndexTableSelection(list, tableSelect, true);
      }
    }
  };

  const handleIndexTableSelection = (
    selectionList,
    tableSelect,
    isSelectAll
  ) => {
    switch (tableSelect) {
      case "finance":
        setSelectedFOResources(selectionList);
        setAllSelectedFinance(isSelectAll);
        break;
      case "envelope":
        setSelectedEnResources(selectionList);
        setAllSelectedEnvelope(isSelectAll);
        break;
      case "attachment":
        setSelectedAttachResources(selectionList);
        setAllSelectedAttachment(isSelectAll);
        break;
    }
  };

  const { fields, submit, reset, makeClean } = useForm({
    fields: {
      custom_name: useField({
        value: "",
        validates: [],
      }),
      financing_type: useField({
        value: financingTypes[0]?.value || "",
        validates: [],
      }),
      payment_type: useField({
        value: vendorProgram
          ? vendorProgram?.attributes?.all_payment_type[0]
          : "",
        validates: [],
      }),
      down_payment: useField({
        value: "",
        validates:
          downPaymentVisible && paymentStructure === "Scheduled payments"
            ? [notEmpty("Down payment can't be 0")]
            : [],
      }),
      down_payment_percent: useField({
        value: "",
        validates:
          downPaymentVisible && paymentStructure === "Milestone payments"
            ? [notEmpty("Down payment can't be 0")]
            : [],
      }),
      default_total_financing_amount: useField({
        value: "",
        validates: []
      }),
      // estimated_buy_rate: useField({
      //   value: "",
      //   validates: [notEmpty("Estimated buy rate is required")],
      // }),
      // // client_rate
      // interest_rate: useField({
      //   value: "",
      //   validates: [notEmpty("Interest rate is required")],
      // }),
    },
    async onSubmit(form) {
      let percentPaymentInstallments = [];

      if (paymentStructure === "Milestone payments") {
        // Percents are edited / showed edited as integers but stored as decimals
        if (fields.down_payment_percent.value) {
          form.down_payment_percent = Number(
            fields.down_payment_percent.value * 0.01
          );
        }
        percentPaymentInstallments =
          paymentInstallments.map((payment) => ({
            percent: Number(payment.percent * 0.01),
            amount: 0,
          })) || [];
      }

      let totalSegmentCount = 0;
      const customSegments = Object.keys(segments).map((index) => {
        totalSegmentCount += Number(segments[index].months);
        return {
          count: segments[index].months,
          amount: segments[index].amount,
        };
      });

      const financeType = toHumanReadableFinanceType[form.financing_type];
      delete form.financing_type;

      // Insert net terms details somewhere here (?)
      let installmentPaymentsToUpdate = [];
      if (percentPaymentInstallments.length > 0) {
        installmentPaymentsToUpdate = percentPaymentInstallments;
      } else if (paymentInstallments.length > 0) {
        installmentPaymentsToUpdate = paymentInstallments;
      }

      const newFinanceOptionTemplateFormPayload = {
        id: vendorProgramId,
        interest_rate_visible: visibleClientRate,
        segments: customSegments,
        term_length: totalSegmentCount + Number(baseSegmentsMonths),
        customer_interest_rate: interestRate,
        estimated_buy_rate: buyRate,
        sell_rate: sellRate,
        financing_type: financeType,
        installment_payments: installmentPaymentsToUpdate,
        discount: customerDiscount,
        discount_visible: discountVisible,
        ...form,
      };

      if (newFinanceOptionTemplateFormPayload.down_payment == "") {
        newFinanceOptionTemplateFormPayload.down_payment = 0;
      }

      // reset form
      setPaymentInstallments([]);
      setSegments({ 0: { months: 0, amount: 0 } });
      setInterestRate("");
      setBuyRate("");
      setSellRate("");
      setCustomerDiscount(0);
      setDiscountVisible(false);
      setDownPaymentVisible(false);
      fields.down_payment.value = "";
      fields.down_payment_percent.value = "";

      return (
        toUpdateFinanceOptionTemplate
          ? updateFinancingOptionTemplate({
              financing_option_template_id: financeOptionTemplateId,
              ...newFinanceOptionTemplateFormPayload,
            })
          : createFinancingOptionTemplate({
              ...newFinanceOptionTemplateFormPayload,
            })
      )
        .unwrap()
        .then(() => {
          makeClean();
          reset();
          setBuyRate("");
          setSellRate("");
          setInterestRate("");
          setSegments({ 0: { months: 0, amount: 0 } });
          setBaseSegmentsMonths(0);
          handleChangeVisibleClientRate(false);
          setShowFinanceOptionModal(false);
          setToUpdateFinanceOptionTemplate(false);
          setFinanceOptionTemplateId(null);
          showToastWithMessage(`Template updated`);
          return { status: "success" };
        })
        .catch((error) => {
          console.log(error);
          // setShowErrorBanner(true);
          return {
            status: "fail",
            errors: [
              { message: "Could not create financing option template." },
            ],
          };
        });
    },
  });

  useEffect(() => {
    const withDownPayments = [
      "standard",
      "fair_market_value",
      "dollar_out_lease",
      "saas",
      "rental",
    ];

    if (withDownPayments.includes(fields.financing_type.value)) {
      setEnableDownPaymentField(true);
    } else {
      setEnableDownPaymentField(false);
    }
    // Comment out, prevents down payment value from being pulled from financing option
    // fields.down_payment.value = "";
  }, [fields.financing_type.value]);

  const handleCloseFinanceOptionModal = () => {
    reset();
    setBuyRate("");
    setSellRate("");
    setInterestRate("");
    setSegments({ 0: { months: 0, amount: 0 } });
    setBaseSegmentsMonths(0);
    handleChangeVisibleClientRate(false);
    setShowFinanceOptionModal(false);
    setDiscountVisible(false);
    setShowPercentageErrorBanner(false);
    setToUpdateFinanceOptionTemplate(false);
    setFinanceOptionTemplateId(null);
  };

  const handleShowEditFinanceOptionModal = (financeOption) => {
    setFinanceOptionTemplateModalTitle(`Edit finance option template`);
    setFinanceOptionTemplateId(financeOption.id);
    setToUpdateFinanceOptionTemplate(true);
    fields.custom_name.value = financeOption.attributes.custom_name;
    fields.financing_type.value = financeOption.attributes.type;
    fields.payment_type.value = financeOption.attributes.payment_type;
    setInterestRate(financeOption.attributes.customer_interest_rate);
    setVisibleClientRate(financeOption.attributes.interest_rate_visible);
    setBuyRate(financeOption.attributes.estimated_buy_rate);
    setSellRate(financeOption.attributes.sell_rate);
    setDiscountVisible(financeOption.attributes.discount_visible);
    setCustomerDiscount(financeOption.attributes.discount);

    setPaymentStructure(
      financeOption.attributes.uses_milestone_payments
        ? "Milestone payments"
        : "Scheduled payments"
    );

    if (financeOption.attributes.default_total_financing_amount > 0) {
      fields.default_total_financing_amount.value = financeOption.attributes.default_total_financing_amount
    }

    if (financeOption.attributes.down_payment > 0) {
      setDownPaymentVisible(true);
      fields.down_payment.value = financeOption.attributes.down_payment;
    }

    // Percents are edited / showed edited as integers but stored as decimals
    if (financeOption.attributes.down_payment_percent > 0) {
      setDownPaymentVisible(true);
      fields.down_payment_percent.value =
        financeOption.attributes.down_payment_percent * 100;
    }

    if (financeOption.attributes.installment_payments.length > 0) {
      setPaymentInstallments(
        financeOption.attributes.installment_payments.map((installment) => ({
          days: installment?.days || 0,
          percent: installment?.percent * 100 || 0,
          amount: installment.amount,
        }))
      );
    }

    let totalSegmentMonths = 0;
    if (financeOption.attributes.segments.length > 0) {
      setSegments(
        financeOption.attributes.segments.map((segment) => {
          totalSegmentMonths += Number(segment.count);
          return {
            months: segment.count,
            amount: segment.amount,
          };
        })
      );
    }

    setBaseSegmentsMonths(
      Number(financeOption.attributes.term_length) - totalSegmentMonths
    );
    setShowFinanceOptionModal(true);
  };

  const handleSubmitTemplate = useCallback(() => {
    setSubmittingEnvelopeTemplate(true);

    return createEnvelopeTemplate({
      id: vendorProgramId,
      financing_type: toHumanReadableFinanceType[envelopeFinancingType],
      countersignature_required: counterSignature,
      document_ids: selectedEnvelopeTemplateDocuments.join(","),
    })
      .unwrap()
      .then((response) => {
        setSelectedEnvelopeTemplateDocuments([]);
        setShowAddEnvelopeModal(false);
        setSubmittingEnvelopeTemplate(false);
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
        // setShowErrorBanner(true);
        return {
          status: "fail",
          errors: [{ message: "Could not create envelope template." }],
        };
      });
  }, [
    createEnvelopeTemplate,
    vendorProgramId,
    envelopeFinancingType,
    counterSignature,
    selectedEnvelopeTemplateDocuments,
  ]);

  const handleSetCounterSignature = useCallback(
    (checked) => setCounterSignature(checked),
    []
  );
  const handleChangeVisibleClientRate = useCallback(
    (checked) => setVisibleClientRate(checked),
    []
  );
  const handleSelectEnvelopeFinancingType = useCallback(
    (value) => {
      let filteredDocu = [];
      vendorProgram.attributes.finance_type_docu_list.forEach((docu) => {
        if (docu.type == value) {
          filteredDocu.push(docu);
        }
      });

      setEnvelopeFinancingType(value);
      setSelectedEnvelopeTemplateDocuments([]);
    },
    [vendorProgram]
  );

  useEffect(() => {
    setRequiresCreditReview(
      vendorProgram?.attributes?.requires_credit_submission
    );
    setAchPaymentsOnly(vendorProgram?.attributes?.ach_payments_only);
    setRequiresCreditApplication(
      vendorProgram?.attributes?.requires_credit_application
    );
  }, [vendorProgram, showEditVendorProgramModal]);

  useEffect(() => {
    setName(vendorProgram?.attributes?.name);
    setDisplayName(vendorProgram?.attributes?.name);
    setDocusignBrandId(vendorProgram?.attributes?.docusign_brand_id);

    if (vendorProgram?.attributes) {
      setFinanceTypesChecks(
        Object.keys(toHumanReadableFinanceType).map((key) => {
          const vpFinanceTypes = vendorProgram.attributes.finance_types;
          return vpFinanceTypes.includes(key);
        })
      );
      setFinancingTypes(
        vendorProgram.attributes.finance_types.map((financeType) => {
          const financeTypeLabel = toHumanReadableFinanceType[financeType];
          return { label: financeTypeLabel, value: financeType };
        })
      );

      setEnvelopeFinancingType(
        vendorProgram.attributes.finance_types[0] || "standard"
      );

      setPaymentTypes(
        vendorProgram.attributes.all_payment_type.map((payment) => {
          return { label: toSentenceCase(payment), value: payment };
        })
      );
      setAttachmentChecks(
        vendorProgram.attributes.all_opportunity_attachment_templates.map(
          (docu) => {
            if (vendorProgram.attributes.opportunity_attachment_templates) {
              return vendorProgram.attributes.opportunity_attachment_templates.includes(
                docu
              );
            } else {
              return false;
            }
          }
        )
      );
    }
  }, [vendorProgram]);

  useEffect(() => {
    if (documents.length && financingTypes.length > 0) {
      const filteredDocuments = documents.filter((document) => {
        return financingTypes.some((financeType) => {
          return document.attributes.financing_option_type.some(
            (documentFinanceType) => {
              return documentFinanceType.name == financeType.value;
            }
          );
        });
      });

      setFilteredDocuments(filteredDocuments);
    }
  }, [documents, financingTypes]);

  const [updateVendorProgram, { isLoading: isUpdatingVendorProgram }] =
    useUpdateAdminVendorProgramMutation();

  const [
    createFinancingOptionTemplate,
    { isLoading: isCreatingFinanceOptionTemplate },
  ] = useCreateFinancingOptionTemplateMutation();

  const [
    updateFinancingOptionTemplate,
    { isLoading: isUpdateFinanceOptionTemplate },
  ] = useUpdateFinancingOptionTemplateMutation();

  const [createEnvelopeTemplate, { isLoading: isCreatingEnvelopeTemplate }] =
    useCreateVendorProgramEnvelopeTemplateMutation();

  const changeVendorProgram = useCallback(() => {
    let attachmentSet = Object.keys(attachmentDocuments).map((index) => {
      return attachmentDocuments[index];
    });
    vendorProgram.attributes.all_opportunity_attachment_templates.forEach(
      (docu, index) => {
        if (attachmentChecks[index]) {
          attachmentSet.push(docu);
        }
      }
    );
    attachmentSet = attachmentSet.filter((docu) => {
      return docu != "";
    });

    const financeTypesSet = [];
    // financingTypeList
    Object.keys(toHumanReadableFinanceType).forEach((key, index) => {
      financeTypesSet.push({
        finance_type: key,
        allowed: financeTypesChecks[index],
      });
    });

    return updateVendorProgram({
      id: vendorProgramId,
      attachment_set: attachmentSet,
      allowed_finance_types: financeTypesSet,
      name: name,
      requires_credit_submission: requiresCreditReview,
      requires_credit_application: requiresCreditApplication,
      ach_payments_only: achPaymentsOnly,
      docusign_brand_id: docusignBrandId,
    })
      .unwrap()
      .then((response) => {
        setAttachmentDocuments({ 0: "", 1: "", 2: "" });
        setShowAddAttachmentModal(false);
        setShowEditVendorProgramModal(false);

        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
        return {
          status: "fail",
          errors: [{ message: "Could not update program." }],
        };
      });
  }, [
    achPaymentsOnly,
    attachmentDocuments,
    docusignBrandId,
    vendorProgram,
    updateVendorProgram,
    vendorProgramId,
    attachmentChecks,
    financeTypesChecks,
    name,
    requiresCreditReview,
    requiresCreditApplication,
  ]);

  const editVendorProgramModal = (
    <Modal
      open={showEditVendorProgramModal}
      onClose={() => setShowEditVendorProgramModal(false)}
      title={"Edit program"}
      disabled={isUpdatingVendorProgram}
      primaryAction={{
        content: "Save",
        loading: isUpdatingVendorProgram,
        onAction: changeVendorProgram,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => setShowEditVendorProgramModal(false),
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <TextField
            label="Name"
            value={name}
            onChange={setName}
            disabled={isUpdatingVendorProgram}
            autoComplete="off"
          />

          <TextField
            label="Docusign brand ID"
            value={docusignBrandId}
            onChange={setDocusignBrandId}
            disabled={isUpdatingVendorProgram}
            autoComplete="off"
          />

          <Checkbox
            label={"Credit review required"}
            checked={requiresCreditReview}
            onChange={setRequiresCreditReview}
          />

          <Checkbox
            label={"ACH payments only"}
            checked={achPaymentsOnly}
            onChange={setAchPaymentsOnly}
          />

          <Checkbox
            label={"Requires credit application"}
            checked={requiresCreditApplication}
            onChange={setRequiresCreditApplication}
          />
        </FormLayout>
      </Modal.Section>

      <Modal.Section>
        <BlockStack gap="400">
          <Text variant="headingSm" as="h3">
            ALLOWED FINANCE TYPES
          </Text>

          <FormLayout>
            {Object.keys(toHumanReadableFinanceType).map((key, index) => (
              <Checkbox
                key={index}
                label={toHumanReadableFinanceType[key]}
                value={key}
                checked={financeTypesChecks[index]}
                onChange={() => handleChangeAllowedFinanceTypes(index)}
              />
            ))}
          </FormLayout>
        </BlockStack>
      </Modal.Section>
    </Modal>
  );

  const handleChangeDiscount = useCallback((newValue) => {
    setCustomerDiscount(newValue);
  }, []);

  const handleChangeDiscountTextField = useCallback((newValue) => {
    if (newValue == "-") return;
    if (newValue > 100) {
      newValue = 100;
    }
    setCustomerDiscount(newValue);
  }, []);

  const handleDiscountVisibleChange = useCallback(
    (val) => setDiscountVisible(val),
    []
  );

  const handleAddPayments = useCallback(() => {
    setPaymentInstallments(() => {
      const paymentAmount = 0;
      let payments = paymentInstallments.map((payment) => {
        return { ...payment, amount: paymentAmount.toFixed(2) };
      });
      if (paymentStructure === "Scheduled payments") {
        payments.push({
          days: 1,
          amount: paymentAmount.toFixed(2),
        });
      } else {
        payments.push({
          percent: 1,
          amount: paymentAmount.toFixed(2),
        });
      }
      return payments;
    });
  }, [paymentInstallments]);

  const handleRemovePayment = useCallback(
    (index) => {
      let payments = [...paymentInstallments];
      payments.splice(index, 1);
      setPaymentInstallments(() => {
        const paymentAmount = 0;
        payments = payments.map((payment) => {
          return { ...payment, amount: paymentAmount.toFixed(2) };
        });
        return payments;
      });
    },
    [paymentInstallments]
  );

  const handleEditPayment = useCallback(
    (index, days_percent) => {
      let payments = [...paymentInstallments];
      if (paymentStructure === "Scheduled payments") {
        payments.splice(index, 1, {
          days: Number(days_percent),
          amount: Number(paymentInstallments[index].amount) || 0,
        });
      } else {
        payments.splice(index, 1, {
          percent: Number(days_percent),
          amount: Number(paymentInstallments[index].amount) || 0,
        });
      }
      setPaymentInstallments(payments);
    },
    [paymentInstallments]
  );

  const handlePaymentStructureChange = useCallback(
    (val) => {
      if (paymentInstallments.length > 0 || downPaymentVisible) {
        let updatedInstallments = [];
        if (val === "Scheduled payments") {
          updatedInstallments = paymentInstallments.map((installment) => ({
            days: 0.0,
            amount: installment.amount,
          }));
          fields.down_payment_percent.value = "";
        } else {
          const percents =
            100 / (paymentInstallments.length + (downPaymentVisible ? 1 : 0));
          updatedInstallments = paymentInstallments.map((installment) => ({
            percent: Number(percents).toFixed(0),
            amount: installment.amount,
          }));
          fields.down_payment_percent.value = percents.toFixed(0);
        }

        fields.down_payment.value = "";
        setPaymentInstallments(updatedInstallments);
      }
      setShowPercentageErrorBanner(false);
      setPaymentStructure(val);
    },
    [paymentStructure, paymentInstallments, downPaymentVisible]
  );

  const handleSubmitFinanceOptionTemplate = useCallback(() => {
    if (paymentStructure === "Milestone payments") {
      if (paymentInstallments.length > 0) {
        let totalPercent = paymentInstallments.reduce((t, payment) => {
          return t + Number(payment.percent);
        }, 0);
        totalPercent =
          Number(totalPercent) +
          (downPaymentVisible ? Number(fields.down_payment_percent.value) : 0);

        if (totalPercent != 100) {
          setShowPercentageErrorBanner(true);
        } else {
          submit();
        }
      } else {
        setShowPercentageErrorBanner(true);
      }
    } else {
      submit();
    }
  }, [
    submit,
    paymentStructure,
    paymentInstallments,
    downPaymentVisible,
    fields,
  ]);

  const financeOptionTemplateModal = (
    <Modal
      open={showFinanceOptionModal}
      onClose={() => handleCloseFinanceOptionModal()}
      title={financeOptionTemplateModalTitle}
      loading={isCreatingFinanceOptionTemplate || isUpdateFinanceOptionTemplate}
      primaryAction={{
        content: "Save",
        loading:
          isCreatingFinanceOptionTemplate || isUpdateFinanceOptionTemplate,
        onAction: () => handleSubmitFinanceOptionTemplate(),
      }}
      secondaryActions={[
        {
          content: "Cancel",
          loading:
            isCreatingFinanceOptionTemplate || isUpdateFinanceOptionTemplate,
          onAction: () => handleCloseFinanceOptionModal(),
        },
      ]}
    >
      {showPercentageErrorBanner && (
        <Modal.Section>
          <Banner tone="critical" title="Something went wrong">
            <p>
              The total percentage of all payments must be 100% including down
              payment
            </p>
          </Banner>
          <br />
        </Modal.Section>
      )}

      <Modal.Section>
        <BlockStack gap="200">
          <TextField
            label="Custom name"
            autoComplete="off"
            {...fields.custom_name}
          />

          <Text fontWeight="semibold" as="p">
            {`Type`.toUpperCase()}
          </Text>

          <Select
            label="Finance option type"
            options={financingTypes}
            {...fields.financing_type}
          />
        </BlockStack>
      </Modal.Section>

      {fields.financing_type.value === "net_terms" && (
        <>
          <Modal.Section>
            <Text variant="headingMd" as="h2">
              Discount
            </Text>

            <br />

            <RangeSlider
              label="Customer discount"
              min={0}
              max={100}
              step={1}
              value={customerDiscount}
              onChange={handleChangeDiscount}
              prefix={
                <div className="FortCalculator__SellRateTextField">
                  <TextField
                    autoComplete="off"
                    value={customerDiscount}
                    onChange={handleChangeDiscountTextField}
                    step={1}
                    suffix="%"
                    type="number"
                  />
                </div>
              }
            />

            <br />

            <Checkbox
              label="Make discount visible in client portal"
              checked={discountVisible}
              onChange={handleDiscountVisibleChange}
            />
          </Modal.Section>

          <Modal.Section>
            <Text variant="headingMd" as="h2">
              Payments
            </Text>

            <br />

            <Select
              label="Payment structure"
              options={["Scheduled payments", "Milestone payments"]}
              onChange={handlePaymentStructureChange}
              value={paymentStructure}
            />

            <br />

            <Text variant="headingSm" as="h6">
              Down payment
            </Text>

            <br />

            {downPaymentVisible ? (
              <BlockStack gap="200">
                <InlineStack align="space-between">
                  {paymentStructure === "Scheduled payments" ? (
                    <TextField
                      label="Amount"
                      step={1}
                      type="number"
                      autoComplete="off"
                      {...fields.down_payment}
                    />
                  ) : (
                    <TextField
                      label="Percentage"
                      step={1}
                      min={1}
                      type="number"
                      prefix="%"
                      autoComplete="off"
                      {...fields.down_payment_percent}
                    />
                  )}

                  <Button
                    onClick={() => handleDownPaymentVisibleChange(false)}
                    variant="plain"
                    tone="critical"
                  >
                    Delete
                  </Button>
                </InlineStack>
              </BlockStack>
            ) : (
              <Box paddingBlockEnd={"400"}>
                <Button
                  onClick={() => handleDownPaymentVisibleChange(true)}
                  icon={PlusIcon}
                >
                  Down payment
                </Button>
              </Box>
            )}

            <br />

            <Text variant="headingSm" as="h6">
              Installment
            </Text>

            {paymentInstallments.length > 0 &&
              paymentInstallments.map((payment, index) => {
                return (
                  <div key={index}>
                    <br />

                    <BlockStack gap="200">
                      <InlineStack align="space-between">
                        <InlineStack align="start">
                          {paymentStructure === "Scheduled payments" ? (
                            <TextField
                              label="Days after installment payment start date"
                              value={payment.days}
                              onChange={(value) =>
                                handleEditPayment(index, value)
                              }
                              type="number"
                              step={1}
                              autoComplete="off"
                            />
                          ) : (
                            <TextField
                              label="Percentage of installment payment"
                              value={payment.percent}
                              prefix="%"
                              onChange={(value) =>
                                handleEditPayment(index, value)
                              }
                              type="number"
                              step={1}
                              min={1}
                              autoComplete="off"
                            />
                          )}
                        </InlineStack>
                        <Button
                          onClick={() => handleRemovePayment(index)}
                          variant="plain"
                          tone="critical"
                        >
                          Delete
                        </Button>
                      </InlineStack>
                    </BlockStack>
                  </div>
                );
              })}

            <br />

            <Button onClick={handleAddPayments} icon={PlusIcon}>
              Installment payment
            </Button>
          </Modal.Section>
        </>
      )}

      {fields.financing_type.value != "net_terms" && (
        <>
          <Modal.Section>
            <Text fontWeight="semibold" as="p">
              {`Timing`.toUpperCase()}
            </Text>
            <div style={{ marginTop: "1rem" }}>
              <Select
                label="Payment type"
                options={paymentTypes}
                {...fields.payment_type}
              />
            </div>
          </Modal.Section>
          <Modal.Section>
            <div style={{ marginBottom: "1rem" }}>
              <Text fontWeight="semibold" as="p">
                {`Rates`.toUpperCase()}
              </Text>
            </div>
            <InlineStack>
              <div style={{ width: "5rem" }}>
                <TextField
                  label="Customer interest rate"
                  value={interestRate}
                  onChange={setInterestRate}
                />
              </div>
              <div style={{ marginTop: "2.8rem", marginLeft: "1.5rem" }}>
                <Checkbox
                  label="Make customer rate visible in client portal"
                  checked={visibleClientRate}
                  onChange={handleChangeVisibleClientRate}
                />
              </div>
            </InlineStack>
            <div style={{ width: "5rem", marginTop: "0.5rem" }}>
              <TextField
                label="Estimated buy rate"
                value={buyRate}
                onChange={setBuyRate}
              />
            </div>
            <div style={{ width: "5rem", marginTop: "0.5rem" }}>
              <TextField
                label="Sell rate"
                value={sellRate}
                onChange={setSellRate}
              />
            </div>
          </Modal.Section>

          {enableDownPaymentField && (
            <Modal.Section>
              <div style={{ marginBottom: "1rem" }}>
                <Text fontWeight="semibold" as="p">
                  Down payment
                </Text>
              </div>

              {downPaymentVisible ? (
                <BlockStack gap="200">
                  <InlineStack align="space-between">
                    <TextField
                      label="Amount"
                      step={1}
                      type="number"
                      autoComplete="off"
                      {...fields.down_payment}
                    />
                    <Button
                      onClick={() => handleDownPaymentVisibleChange(false)}
                      variant="plain"
                      tone="critical"
                    >
                      Delete
                    </Button>
                  </InlineStack>
                </BlockStack>
              ) : (
                <Button
                  onClick={() => handleDownPaymentVisibleChange(true)}
                  icon={PlusIcon}
                >
                  Down payment
                </Button>
              )}

              <br />
            </Modal.Section>
          )}

          {fields.financing_type.value == "rental" && 
            <Modal.Section>
              <div style={{ marginBottom: "1rem" }}>
                <Text fontWeight="semibold" as="p">
                  Default Total Financing Amount
                </Text>

                <br/>

                <TextField
                  autoComplete="off"
                  {...fields.default_total_financing_amount}
                />
              </div>


            </Modal.Section>
          }

          <Modal.Section>
            <div style={{ marginBottom: "1rem" }}>
              <Text fontWeight="semibold" as="p">
                {`Custom segments`.toUpperCase()}
              </Text>
            </div>
            {Object.getOwnPropertyNames(segments).length != 0 &&
              Object.keys(segments).map((index) => (
                <InlineStack key={index} gap="300">
                  <div style={{ width: "5rem" }}>
                    <TextField
                      label="Months"
                      value={segments[index].months}
                      onChange={(value) =>
                        handleChangeSegments(value, "months", index)
                      }
                      autoComplete="off"
                    />
                  </div>
                  <div style={{ marginTop: "1.9rem" }}>
                    <Text>@</Text>
                  </div>
                  <div style={{ width: "5rem" }}>
                    <TextField
                      label="Amount"
                      value={segments[index].amount}
                      onChange={(value) =>
                        handleChangeSegments(value, "amount", index)
                      }
                      autoComplete="off"
                    />
                  </div>
                  <div style={{ marginTop: "1.9rem", marginLeft: "auto" }}>
                    <Button
                      onClick={() => deleteSegments(index)}
                      variant="plain"
                      tone="critical"
                    >
                      Delete
                    </Button>
                  </div>
                </InlineStack>
              ))}
            <div style={{ marginTop: "1rem" }}>
              <Button size="medium" onClick={addSegments}>
                <Icon source={PlusCircleIcon} tone="base" />
              </Button>
            </div>
          </Modal.Section>
          <Modal.Section>
            <div style={{ marginBottom: "1rem" }}>
              <Text fontWeight="semibold" as="p">
                {`Base segments`.toUpperCase()}
              </Text>
            </div>
            <div style={{ width: "5rem" }}>
              <TextField
                label="Months"
                value={baseSegmentsMonths}
                onChange={setBaseSegmentsMonths}
                autoComplete="off"
              />
            </div>
          </Modal.Section>
        </>
      )}
    </Modal>
  );

  const [availableEnvelopeDocuments, setAvailableEnvelopeDocuments] = useState(
    []
  );
  useEffect(() => {
    const availableEnvelopeDocuments = filteredDocuments.filter((document) => {
      return document.attributes.financing_option_type.some(
        (type) => type.name == envelopeFinancingType
      );
    });
    setAvailableEnvelopeDocuments(availableEnvelopeDocuments);
  }, [filteredDocuments, envelopeFinancingType]);

  const handleCloseAddEnvelopeTemplateModal = () => {
    setSelectedEnvelopeTemplateDocuments([]);
    setShowAddEnvelopeModal(false);
  };

  const [integratedPaymentsOn, setIntegratedPaymentsOn] = useState(false);
  const [connectedStripeAccountId, setConnectedStripeAccountId] =
    useState(null);
  const [applicationFee, setApplicationFee] = useState(null);

  useEffect(() => {
    if (vendorProgram?.attributes?.stripe_payments) {
      setIntegratedPaymentsOn(vendorProgram.attributes.stripe_payments);
      setConnectedStripeAccountId(
        vendorProgram.attributes.stripe_connected_account_id
      );
      setApplicationFee(vendorProgram.attributes.stripe_application_fee);
    }
  }, [vendorProgram]);

  const [showEditPaymentsSettingsModal, setShowEditPaymentsSettingsModal] =
    useState(false);

  const handleUpdatePaymentsSettings = (
    passPaymentsOn = false,
    paymentsValue = false
  ) => {
    let form = {};

    if (passPaymentsOn) {
      form.stripe_payments = paymentsValue;
    } else {
      form.stripe_payments = integratedPaymentsOn;
    }

    if (connectedStripeAccountId)
      form.stripe_connected_account_id = connectedStripeAccountId;
    if (applicationFee) form.stripe_application_fee = applicationFee;

    return updateVendorProgram({ id: vendorProgramId, ...form })
      .unwrap()
      .then((response) => {
        if (showEditPaymentsSettingsModal) {
          setShowEditPaymentsSettingsModal(false);
        }
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
        // setShowErrorBanner(true);
        return {
          status: "fail",
          errors: [{ message: "Could not update vendor program" }],
        };
      });
  };

  const editPaymentsSettingsModal = (
    <Modal
      open={showEditPaymentsSettingsModal}
      onClose={() => {
        setShowEditPaymentsSettingsModal(false);
      }}
      title={"Payments settings"}
      primaryAction={{
        content: "Save",
        loading: isUpdatingVendorProgram,
        onAction: () => {
          handleUpdatePaymentsSettings();
        },
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            setShowEditPaymentsSettingsModal(false);
          },
        },
      ]}
    >
      <Modal.Section>
        <TextField
          label="Connected Stripe account ID"
          value={connectedStripeAccountId}
          onChange={setConnectedStripeAccountId}
          autoComplete="off"
        />

        <br />

        <TextField
          label="Application fee"
          type="number"
          value={applicationFee}
          onChange={setApplicationFee}
          suffix="%"
          autoComplete="off"
        />
      </Modal.Section>
    </Modal>
  );

  const addEnvelopeModal = (
    <Modal
      open={showAddEnvelopeModal}
      onClose={handleCloseAddEnvelopeTemplateModal}
      title={"Add envelope template"}
      loading={submittingEnvelopeTemplate}
      primaryAction={{
        content: "Save",
        loading: submittingEnvelopeTemplate,
        disabled: filteredDocuments.length < 1,
        onAction: () => handleSubmitTemplate(),
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleCloseAddEnvelopeTemplateModal,
        },
      ]}
    >
      <Modal.Section>
        <Select
          label="Type"
          options={financingTypes}
          onChange={handleSelectEnvelopeFinancingType}
          value={envelopeFinancingType}
        />
      </Modal.Section>
      <Modal.Section>
        <div style={{ marginBottom: "1rem" }}>
          <Text variant="headingSm" as="h3">
            {`Countersignature`.toUpperCase()}
          </Text>
        </div>
        <Checkbox
          label={"Requires countersignature"}
          checked={counterSignature}
          onChange={handleSetCounterSignature}
        />
      </Modal.Section>
      <Modal.Section>
        <div style={{ marginBottom: "1rem" }}>
          <Text variant="headingSm" as="h3">
            {`Opportunity documents`.toUpperCase()}
          </Text>
        </div>
        {availableEnvelopeDocuments.map((document, index) => (
          <div style={{ marginTop: "0.5rem" }} key={index}>
            <Checkbox
              label={document.attributes.name}
              checked={selectedEnvelopeTemplateDocuments.includes(document.id)}
              value={document.id}
              onChange={(checked) =>
                handleChangeSelectedEnvelopeTemplateDocuments(
                  checked,
                  document.id
                )
              }
            />
            <br />
          </div>
        ))}
      </Modal.Section>
    </Modal>
  );

  const addAttachmentModal = (
    <Modal
      open={showAddAttachmentModal}
      onClose={() => setShowAddAttachmentModal(false)}
      title={"Add attachment template"}
      loading={isUpdatingVendorProgram}
      primaryAction={{
        content: "Save",
        loading: isUpdatingVendorProgram,
        onAction: () => changeVendorProgram(),
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => setShowAddAttachmentModal(false),
        },
      ]}
    >
      <Modal.Section>
        <div style={{ marginBottom: "1rem" }}>
          <Text fontWeight="semibold" as="p">
            {`Preset templates`.toUpperCase()}
          </Text>
        </div>
        {vendorProgram?.attributes?.all_opportunity_attachment_templates.map(
          (docu, index) => (
            <div style={{ marginTop: "0.5rem" }} key={index}>
              <Checkbox
                label={docu}
                checked={attachmentChecks[index]}
                onChange={() => handleChangeAttachmentChecks(index)}
              />
              <br />
            </div>
          )
        )}
        <div style={{ marginBottom: "1rem", marginTop: "1rem" }}>
          <Text fontWeight="semibold" as="p">
            {`or, enter a cutomer document name below`.toUpperCase()}
          </Text>
        </div>
        {Object.keys(attachmentDocuments).map((index) => (
          <div key={index}>
            <TextField
              value={attachmentDocuments[index]}
              onChange={(value) =>
                handleChangeAttachmentDocuments(value, index)
              }
              placeholder="type new document name here"
              autoComplete="off"
            />
            <br />
          </div>
        ))}
      </Modal.Section>
    </Modal>
  );

  const areYouSureModal = (
    <Modal
      open={openConfirmationModal}
      onClose={handleCloseConfirmationModal}
      title="Are you sure?"
      loading={isDeletingTemplates}
      primaryAction={{
        content: "Confirm",
        loading: isDeletingTemplates,
        onAction: handleConfirmDeleteModal,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleCloseConfirmationModal,
        },
      ]}
    >
      <Modal.Section>
        <Text as="p" variant="bodySm">
          You are about to delete{" "}
          {selectedTemplateBulkDelete == "finance"
            ? `${selectedTemplateBulkDelete} option`
            : selectedTemplateBulkDelete}{" "}
          templates. This action cannot be undone.
        </Text>
      </Modal.Section>
    </Modal>
  );

  const pageMarkup = (
    <FadeIn fadeIn>
      {isLoading ? (
        <Spinner />
      ) : (
        <Page
          backAction={{ onAction: navigateToAdminVendorProgram }}
          title={displayName}
          subtitle={"Vendor Program"}
          fullWidth
        >
          <Layout>
            <Layout.Section>
              <Card>
                <div style={{ paddingBottom: "16px" }}>
                  <InlineStack gap="200" align="space-between">
                    <Text variant="headingMd" as="h6">
                      Settings
                    </Text>

                    <Button
                      onClick={() => setShowEditVendorProgramModal(true)}
                      variant="plain"
                    >
                      Edit
                    </Button>
                  </InlineStack>
                </div>

                <div>
                  <Text variant="bodyLg" as="h6" fontWeight="semibold">
                    Program name
                  </Text>
                  <div style={{ marginTop: "0.5rem" }}>
                    <Text variant="bodyMd" as="p">
                      {displayName}
                    </Text>
                  </div>
                  <div style={{ marginTop: "2.5rem" }}>
                    <Text variant="bodyLg" as="h6" fontWeight="semibold">
                      Docusign brand ID
                    </Text>
                  </div>
                  <div style={{ marginTop: "0.5rem" }}>
                    <Text variant="bodyMd" as="p">
                      {vendorProgram.attributes.docusign_brand_id || "--"}
                    </Text>
                  </div>
                  <div style={{ marginTop: "2rem" }}>
                    <BlockStack gap="200">
                      <Text variant="bodyLg" as="h6" fontWeight="semibold">
                        Logo
                      </Text>

                      <br />

                      <InlineStack gap="200">
                        {vendorProgram.attributes.attachment_url ? (
                          <div style={{ width: 114, height: 114 }}>
                            <img
                              src={vendorProgram.attributes.attachment_url}
                              className={"left-bar-logo"}
                            />
                          </div>
                        ) : (
                          <div style={{ width: 114, height: 114 }}>
                            <AttachmentUpload
                              vendorProgramId={vendorProgramId}
                              isDeletingAttachment={isDeletingAttachment}
                            />
                          </div>
                        )}
                      </InlineStack>

                      {vendorProgram.attributes.attachment_url && (
                        <div style={{ width: 114 }}>
                          <Button
                            onClick={handleDeleteAttachment}
                            loading={isDeletingAttachment}
                            variant="plain"
                            tone="critical"
                          >
                            Remove logo
                          </Button>
                        </div>
                      )}
                    </BlockStack>
                  </div>
                  <div style={{ marginTop: "2.5rem" }}>
                    <Text variant="bodyLg" as="h6" fontWeight="semibold">
                      Allowed finance types
                    </Text>
                  </div>
                  <div style={{ marginTop: "0.5rem" }}>
                    <Text variant="bodyMd" as="p">
                      {vendorProgram.attributes.finance_types
                        .map((name) => {
                          return toHumanReadableFinanceType[name];
                        })
                        .join(", ")}
                    </Text>
                  </div>
                  <div style={{ marginTop: "2.5rem" }}>
                    <Text variant="bodyLg" as="h6" fontWeight="semibold">
                      Credit review required
                    </Text>
                  </div>
                  <div style={{ marginTop: "0.5rem" }}>
                    <Text variant="bodyMd" as="p">
                      {vendorProgram.attributes.requires_credit_submission
                        ? "True"
                        : "False"}
                    </Text>
                  </div>

                  <div style={{ marginTop: "2.5rem" }}>
                    <Text variant="bodyLg" as="h6" fontWeight="semibold">
                      ACH payments only
                    </Text>
                  </div>
                  <div style={{ marginTop: "0.5rem" }}>
                    <Text variant="bodyMd" as="p">
                      {vendorProgram.attributes.ach_payments_only
                        ? "True"
                        : "False"}
                    </Text>
                  </div>

                  <div style={{ marginTop: "2.5rem" }}>
                    <Text variant="bodyLg" as="h6" fontWeight="semibold">
                      Requires credit application
                    </Text>
                  </div>
                  <div style={{ marginTop: "0.5rem" }}>
                    <Text variant="bodyMd" as="p">
                      {vendorProgram.attributes.requires_credit_application
                        ? "True"
                        : "False"}
                    </Text>
                  </div>
                </div>
              </Card>

              <br />

              <Card padding="0">
                <div
                  style={{
                    paddingTop: "20px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    paddingBottom: "20px",
                  }}
                >
                  <BlockStack gap="500">
                    <InlineStack gap="200" align="space-between">
                      <div style={{ width: "180px" }}>
                        <InlineStack gap="200">
                          <Text variant="strong" fontWeight="bold">
                            Integrated payments
                          </Text>

                          {integratedPaymentsOn && (
                            <Badge tone="success">On</Badge>
                          )}
                          {!integratedPaymentsOn && <Badge>Off</Badge>}
                        </InlineStack>
                      </div>

                      <Button
                        size="slim"
                        loading={isUpdatingVendorProgram}
                        onClick={() => {
                          setIntegratedPaymentsOn(!integratedPaymentsOn);
                          handleUpdatePaymentsSettings(
                            true,
                            !integratedPaymentsOn
                          );
                        }}
                      >
                        {integratedPaymentsOn && "Turn off"}
                        {!integratedPaymentsOn && "Turn on"}
                      </Button>
                    </InlineStack>

                    <div>
                      <Text variant="bodyMd" tone="subdued">
                        {!integratedPaymentsOn &&
                          "When integrated payments is off, as customers select the net terms option, the vendor admins will be notified to process the payment manually."}
                        {integratedPaymentsOn &&
                          "When integrted payments is on, all Net terms purchases will be charged to the connected Stripe account according to the details below"}
                      </Text>
                    </div>
                  </BlockStack>
                </div>

                {integratedPaymentsOn && (
                  <>
                    <Divider />

                    <div style={{ padding: "20px" }}>
                      <BlockStack>
                        <InlineStack gap="200" align="space-between">
                          <Text
                            as="span"
                            variant="bodyMd"
                            fontWeight="semibold"
                          >
                            Payments settings
                          </Text>

                          <Button
                            onClick={() => {
                              setShowEditPaymentsSettingsModal(true);
                            }}
                            variant="plain"
                          >
                            Edit
                          </Button>
                        </InlineStack>

                        <div style={{ marginTop: "1rem" }}>
                          <Text variant="bodyMd" tone="subdued">
                            Connected Stripe account ID
                          </Text>
                        </div>
                        <div style={{ marginTop: "0.5rem" }}>
                          <Text variant="bodyMd">
                            {vendorProgram?.attributes
                              ?.stripe_connected_account_id
                              ? vendorProgram?.attributes
                                  ?.stripe_connected_account_id.length > 0
                                ? vendorProgram?.attributes
                                    ?.stripe_connected_account_id
                                : "-"
                              : "-"}
                          </Text>
                        </div>

                        <div style={{ marginTop: "1rem" }}>
                          <Text variant="bodyMd" tone="subdued">
                            Application fee
                          </Text>
                        </div>

                        <div style={{ marginTop: "0.5rem" }}>
                          <Text variant="bodyMd">
                            {vendorProgram?.attributes?.stripe_application_fee
                              ? vendorProgram?.attributes
                                  ?.stripe_application_fee + "%"
                              : "-"}
                          </Text>
                        </div>
                      </BlockStack>
                    </div>
                  </>
                )}
              </Card>

              <br />

              <Card padding="0">
                <div style={{ padding: "16px 16px 0 16px" }}>
                  <InlineStack gap="200" align="space-between">
                    <Text variant="headingMd" as="h6">
                      Financing option templates
                    </Text>

                    <Button
                      onClick={() => {
                        // Reset these values
                        fields.custom_name.value = "";
                        fields.down_payment.value = "";

                        setShowFinanceOptionModal(true);
                        setFinanceOptionTemplateModalTitle(
                          `Add financing option template`
                        );
                      }}
                      variant="plain"
                    >
                      Add template
                    </Button>
                  </InlineStack>
                </div>

                <div style={{ marginTop: "1rem" }}>
                  {financeOptionTemplates && (
                    <FinanceOptionIndexTable
                      isFetchingTemplates={
                        isLoadingFinanceOptionTemplates ||
                        isFetchingFinanceOptionTemplates
                      }
                      templates={financeOptionTemplates}
                      handleOpenConfirmationModal={handleOpenConfirmationModal}
                      handleSelectionChange={handleSelectionChange}
                      allSelected={allSelectedFinance}
                      selectedResources={selectedFOResources}
                      handleShowEditFinanceOptionModal={
                        handleShowEditFinanceOptionModal
                      }
                    />
                  )}
                </div>
              </Card>

              <br />

              <Card padding="0">
                <div style={{ padding: "16px 16px 0 16px" }}>
                  <InlineStack gap="200" align="space-between">
                    <Text variant="headingMd" as="h6">
                      Envelope templates
                    </Text>

                    <Button
                      onClick={() => {
                        setShowAddEnvelopeModal(true);
                      }}
                      variant="plain"
                    >
                      Add envelope template
                    </Button>
                  </InlineStack>
                </div>

                <div style={{ marginTop: "1rem" }}>
                  <EnvelopeIndexTable
                    templates={envelopeTemplates}
                    allSelected={allSelectedEnvelope}
                    selectedResources={selectedEnResources}
                    handleSelectionChange={handleSelectionChange}
                    handleOpenConfirmationModal={handleOpenConfirmationModal}
                    isFetchingTemplates={
                      isFetchingEnvelopeTemplates || isLoadingEnvelopeTemplates
                    }
                    isCreatingEnvelopeTemplate={isCreatingEnvelopeTemplate}
                  />
                </div>
              </Card>

              <div style={{ marginTop: "1rem", marginBottom: "2rem" }}>
                <Card padding="0">
                  <div style={{ padding: "16px 16px 0 16px" }}>
                    <InlineStack gap="200" align="space-between">
                      <Text variant="headingMd" as="h6">
                        Attachments templates
                      </Text>

                      <Button
                        onClick={() => {
                          setShowAddAttachmentModal(true);
                        }}
                        variant="plain"
                      >
                        Add attachment template
                      </Button>
                    </InlineStack>
                  </div>

                  <div style={{ marginTop: "1rem" }}>
                    {vendorProgram?.attributes
                      ?.opportunity_attachment_templates && (
                      <AttachmentsIndexTable
                        vendorProgramId={vendorProgramId}
                        attachments={
                          vendorProgram?.attributes
                            ?.opportunity_attachment_templates
                        }
                        setIsDeletingTemplates={setIsDeletingTemplates}
                      />
                    )}
                  </div>
                </Card>
              </div>
            </Layout.Section>

            {editPaymentsSettingsModal}
            {editVendorProgramModal}
            {financeOptionTemplateModal}
            {addEnvelopeModal}
            {addAttachmentModal}
            {areYouSureModal}
          </Layout>
        </Page>
      )}
    </FadeIn>
  );

  return currentContact ? pageMarkup : <LoadingScreen />;
};

export default AdminVendorProgramsShow;
