import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";

import {
  Filters,
  ResourceList,
  ResourceItem,
  Text,
  InlineStack,
  Truncate,
} from "@shopify/polaris";

import { useDebounce } from "use-debounce";

import { useGetDocumentsQuery } from "../../../services/api";

const AdminDocumentsList = (props) => {

  const history = useHistory();

  const resourceName = {
    singular: "document",
    plural: "documents",
  };

  const {
    stageFilters,
    format,
    onCreateOpportunity,
    onOpportunityClick,
    clientAccountId,
    accountId,
    pageLimit = 5,
  } = props;

  const [queryValue, setQueryValue] = useState(undefined);
  const [searchValue] = useDebounce(queryValue, 500);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  const handleQueryValueRemove = useCallback(
    () => setQueryValue(undefined),
    []
  );
  const handleQueryChange = (value) => setQueryValue(value);

  const [limit, setLimit] = useState(pageLimit);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const { data: documents = [], isLoading: isLoadingDocuments } =
    useGetDocumentsQuery({ search: searchValue });

  const handleFiltersClearAll = useCallback(() => {
    handleQueryValueRemove();
  }, [handleQueryValueRemove]);

  const filterControl =
    documents.length || queryValue ? (
      <Filters
        queryValue={queryValue}
        queryPlaceholder="Filter programs"
        filters={[]}
        appliedFilters={[]}
        onQueryChange={handleQueryChange}
        onQueryClear={handleQueryValueRemove}
        onClearAll={handleFiltersClearAll}
      />
    ) : undefined;

  const handleNextPage = () => {
    setIsLoading(false);
    setPage((state) => {
      if (state == Math.ceil(documents.length / limit)) {
        return Math.ceil(documents.length / limit);
      }
      return state + 1;
    });
  };
  const handlePreviousPage = () => {
    setIsLoading(false);
    setPage((state) => {
      if (state == 1) {
        return 1;
      }
      return state - 1;
    });
  };

  const [paginatedFilteredItems, setPaginatedFilteredItems] = useState([]);
  const [currentPageItems, setCurrentPageItems] = useState([]);

  const emptyStateMarkup =
    !documents.length &&
    !isLoading &&
    !isLoadingDocuments &&
    !queryValue ? (
      <p>No documents found</p>
    ) : undefined;

  const documentsResourceList = (
    <ResourceList
      resourceName={resourceName}
      items={documents}
      renderItem={(item) =>
        renderItem(item, history)
      }
      selectedItems={selectedItems}
      onSelectionChange={setSelectedItems}
      emptyState={emptyStateMarkup}
      filterControl={filterControl}
      loading={isLoading || isLoadingDocuments}
      flushFilters
    />
  );

  return (
    <>
      {documentsResourceList}
    </>
  );
};

const renderItem = (document, history) => {

  const navigateToDocumentShow = () => {
    history.push({
      pathname: `/admin/documents/${document.id}`,
      state: { document: { ...document } },
    });
  };

  return (
    <ResourceItem
        id={document.id}
        onClick={navigateToDocumentShow}
        verticalAlignment="center"
      >
      <InlineStack alignment="center" wrap={false}>
          <div>
            <h3>
              <Text as="span" fontWeight="semibold" >
                <Truncate>
                  {document.attributes.name}
                </Truncate>
              </Text>
            </h3>
          </div>
      </InlineStack>
    </ResourceItem>
  )
};

AdminDocumentsList.propTypes = {
};

export default AdminDocumentsList;
