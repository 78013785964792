import React, { useState, useCallback, useRef, useContext } from "react";
import PropTypes from "prop-types";
import pluralize from "pluralize";
import {
  Modal,
  Button,
  ButtonGroup,
  Icon,
  Text,
  BlockStack,
  InlineStack,
} from "@shopify/polaris";
import {
  LinkIcon,
  LockIcon,
  GlobeIcon,
  EmailIcon,
} from "@shopify/polaris-icons";

import OpportunityContactForm from "../OpportunityContact/Form";
import OpportunityContactList from "../OpportunityContact/List";
import { useUpdateOpportunityMutation } from "../../services/api";
import { CurrentContactContext } from "../../contexts/Contact";

const ShareModal = (props) => {
  const {
    contactId,
    opportunityId,
    opportunity,
    toggleShowToast,
    showContactShareModal,
    setCustomToastMessage,
    setShowContactShareModal,
  } = props;

  const [updateOpportunity, { isLoading: isUpdatingOpportunity }] =
    useUpdateOpportunityMutation();

  const [hideNewContactForm, setHideNewContactForm] = useState(false);
  const [showOpportunityContactList, setShowOpportunityContactList] =
    useState(true);

  const [isLoadingAccessToggle, setIsLoadingAccessToggle] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const [hasContactsSelected, setHasContactsSelected] = useState(false);
  const [selectedContactEmails, setSelectedContactEmails] = useState([]);

  const { currentContact, isCustomer } = useContext(CurrentContactContext);

  const handleHideNewContactForm = useCallback((hide) => {
    setHideNewContactForm(hide);
  }, []);

  const handleSelectContact = () => {
    setHasContactsSelected(true);
    setShowOpportunityContactList(false);
    setIsSharing(true);
  };

  const handleDeselectContacts = () => {
    setHasContactsSelected(false);
    setShowOpportunityContactList(true);
    setIsSharing(false);
  };

  const handleNewContact = () => {
    setShowOpportunityContactList(false);
    setIsSharing(true);
  };

  const handleShareWithOrCreateContacts = () => {
    opportunityContactFormRef.current.handleShareWithOrCreateContacts();
  };

  const handleAfterShareWithContacts = () => {
    setIsSharing(false);
    setHideNewContactForm(false);
    setShowOpportunityContactList(true);
    setCustomToastMessage("Opportunity shared");
    toggleShowToast();
    setShowContactShareModal(false);
  };

  const handleCancelSharing = () => {
    setShowOpportunityContactList(true);
    setHideNewContactForm(false);
    setIsSharing(false);
    opportunityContactFormRef.current.handleHideNewContactForm();
    opportunityContactFormRef.current.resetContactSelection();
  };

  const handleAfterRemoveOpportunityContacts = (removedContactsLength) => {
    setCustomToastMessage(
      `${pluralize("Contact", removedContactsLength)} removed`
    );
    toggleShowToast();
  };

  const toggleAccess = () => {
    setIsLoadingAccessToggle(true);
    return updateOpportunity({
      id: opportunityId,
      public: !opportunity.attributes.public,
    })
      .unwrap()
      .then(() => {
        setCustomToastMessage("Access updated");
        toggleShowToast();

        setTimeout(() => {
          setIsLoadingAccessToggle(false);
        }, 800);
        return { status: "status" };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const opportunityContactFormRef = useRef();

  const opportunityShareLink = () => {
    let hostname = window.location.hostname;
    hostname = hostname == "localhost" ? `${hostname}:3000` : hostname;
    return `${hostname}/opportunities/${opportunityId}/share`;
  };

  const copyOpportunityShareLink = () => {
    navigator.clipboard.writeText(opportunityShareLink());
    setCustomToastMessage("Link copied");
    toggleShowToast();
  };

  // const copyOpportunityShareLink = () => {
  //   let hostname = window.location.hostname;
  //   hostname = hostname == "localhost" ? `${hostname}:3000` : hostname;
  //   const opportunityShareLink = `${hostname}/opportunities/${opportunityId}/share`;
  //   navigator.clipboard.writeText(opportunityShareLink);
  //   setCustomToastMessage("Link copied");
  //   toggleShowToast();
  // };

  const launchEmailClient = useCallback(() => {
    const subject = `Purchase options for ${opportunity.attributes.name}`;
    const body = `Hello,%0D%0A%0D%0APlease see your opportunity link here: ${opportunityShareLink()}%0D%0A%0D%0ABe sure to save support@fortifypay.com to your contacts so you never miss an important update regarding your purchase.%0D%0A%0D%0ALet us know if you have any questions,%0D%0A%0D%0A${
      currentContact?.attributes?.full_name || ""
    }`;
    const email =
      selectedContactEmails.length > 0
        ? `mailto:${selectedContactEmails}?subject=${subject}&body=${body}`
        : `mailto:?subject=${subject}&body=${body}`;

    window.open(email, "_blank");
  }, [selectedContactEmails]);

  const handleCloseModal = () => {
    handleCancelSharing();
    setShowContactShareModal(false);
  };

  return (
    <Modal
      open={showContactShareModal}
      onClose={handleCloseModal}
      title={"Share opportunity"}
      primaryAction={
        isSharing
          ? {
              content: "Share",
              onAction: handleShareWithOrCreateContacts,
            }
          : {
              content: "Done",
              onAction: () => {
                setShowContactShareModal(false);
              },
            }
      }
      secondaryActions={
        isSharing ? [{ content: "Cancel", onAction: handleCancelSharing }] : []
      }
      footer={
        !isSharing ? (
          <ButtonGroup>
            <Button icon={LinkIcon} onClick={copyOpportunityShareLink}>
              Copy link
            </Button>
            {currentContact && !isCustomer && (
              <Button icon={EmailIcon} onClick={launchEmailClient}>
                Compose email
              </Button>
            )}
          </ButtonGroup>
        ) : hasContactsSelected && currentContact && !isCustomer ? (
          <Button icon={EmailIcon} onClick={launchEmailClient}>
            Share & compose email
          </Button>
        ) : null
      }
      // secondaryActions={[
      //   {
      //     content: "Copy link",
      //     icon: <Icon source={LinkIcon} color="base" />,
      //     onAction: () => copyOpportunityShareLink(),
      //   },
      // ]}
    >
      <Modal.Section>
        <OpportunityContactForm
          onShowNewContactForm={(hide) => {
            handleHideNewContactForm(hide);
          }}
          onAfterShareWithContacts={handleAfterShareWithContacts}
          onNewContact={handleNewContact}
          onSelectContact={handleSelectContact}
          onDeselectContacts={handleDeselectContacts}
          setSelectedContactEmails={setSelectedContactEmails}
          opportunityId={opportunityId}
          contactId={contactId}
          ref={opportunityContactFormRef}
        />
      </Modal.Section>
      {!hideNewContactForm && showOpportunityContactList && (
        <Modal.Section flush>
          <div style={{ padding: "20px 20px 0" }}>
            <Text variant="headingSm" as="h3">
              People with acccess
            </Text>
          </div>
          <OpportunityContactList
            opportunityId={opportunityId}
            onRemoveContacts={handleAfterRemoveOpportunityContacts}
            removeable={true}
          />
        </Modal.Section>
      )}
      {currentContact && !isCustomer && !isSharing && (
        <Modal.Section>
          <BlockStack gap={"4"}>
            <Text variant="headingSm" as="h3">
              General access
            </Text>
            <InlineStack blockAlign="center" align="space-between" gap={"4"}>
              <InlineStack blockAlign="center" gap={"4"}>
                <div
                  style={{
                    backgroundColor: "var(--p-color-bg-strong)",
                    height: "32px",
                    width: "32px",
                    borderRadius: "48px",
                    display: "flex",
                  }}
                >
                  <Icon
                    source={
                      opportunity.attributes.public ? GlobeIcon : LockIcon
                    }
                  />
                </div>
                {opportunity.attributes.public ? (
                  <BlockStack>
                    <Text variant="headingXs" as="h4">
                      Anyone with the link can view
                    </Text>
                    <p>Anyone on the internet with the link can view.</p>
                  </BlockStack>
                ) : (
                  <BlockStack>
                    <Text variant="headingXs" as="h4">
                      Restricted
                    </Text>
                    <p>Only people with access can open the link.</p>
                  </BlockStack>
                )}
              </InlineStack>
              <Button
                loading={isLoadingAccessToggle}
                onClick={() => {
                  toggleAccess();
                }}
              >
                {opportunity.attributes.public ? "Make private" : "Make public"}
              </Button>
            </InlineStack>
          </BlockStack>
        </Modal.Section>
      )}
    </Modal>
  );
};

ShareModal.propTypes = {
  contactId: PropTypes.string,
  opportunityId: PropTypes.string,
  opportunity: PropTypes.object,
  toggleShowToast: PropTypes.func,
  setCustomToastMessage: PropTypes.func,
  showContactShareModal: PropTypes.bool,
  setShowContactShareModal: PropTypes.func,
};

export default ShareModal;
