import React, {
  useImperativeHandle,
  forwardRef,
  useEffect,
  useState,
} from "react";
import {
  FormLayout,
  TextField,
  Select,
  Form,
  Banner,
  Modal,
} from "@shopify/polaris";
import { useField, useForm, notEmpty } from "@shopify/react-form";
import PropTypes from "prop-types";

import {
  useUpdateAdminVendorProgramMutation,
  useCreateAdminVendorProgramMutation,
} from "../../services/api";

const VendorProgramForm = forwardRef((props, ref) => {
  const {
    history,
    vendorProgramId,
    vendorProgram,
    onCreateOrUpdate,
    onEdit,
    setIsCreatingVendorProgram,
  } = props;

  const [updateAdminVendorProgram, { isLoading: isVendorProgramUpdating }] =
    useUpdateAdminVendorProgramMutation();

  const [createAdminVendorProgram, { isLoading: isAddingVendorProgram }] =
    useCreateAdminVendorProgramMutation();

  const navigateToVendorProgramShow = (state = {}, newVendorProgramId) => {
    history.push({
      pathname: `/admin/vendor_programs/${newVendorProgramId}`,
      state: state,
    });
  };

  const [vendorProgramRequiresCreditSubmission] = useState(
    vendorProgram
      ? vendorProgram.attributes.requires_credit_submission
        ? "true"
        : "false"
      : "false"
  );

  const {
    fields,
    submit,
    submitting,
    reset,
    submitErrors,
    makeClean,
    dirty: vendorProgramFormDirty,
  } = useForm({
    fields: {
      name: useField({
        value: vendorProgram ? vendorProgram.attributes.name : "",
        validates: [notEmpty("Vendor Program name is required")],
      }),
      requires_credit_submission: useField({
        value: vendorProgramRequiresCreditSubmission,
        validates: [],
      }),
      standard_interest_rate: useField({
        value: vendorProgram
          ? vendorProgram.attributes.standard_interest_rate
          : 0,
        validates: [],
      }),
      standard_buy_rate: useField({
        value: vendorProgram ? vendorProgram.attributes.standard_buy_rate : 0,
        validates: [],
      }),
      term_length_max: useField({
        value: vendorProgram ? vendorProgram.attributes.term_length_max : 72,
        validates: [],
      }),
      term_length_min: useField({
        value: vendorProgram ? vendorProgram.attributes.term_length_min : 12,
        validates: [],
      }),
    },
    async onSubmit(form) {
      if (vendorProgramId.length > 0) {
        return updateAdminVendorProgram({ id: vendorProgramId, ...form })
          .unwrap()
          .then((response) => {
            makeClean();
            onCreateOrUpdate(response);
            return { status: "success" };
          })
          .catch((error) => {
            console.log(error);
            setShowErrorBanner(true);
            return {
              status: "fail",
              errors: [{ message: "Could not update vendor program" }],
            };
          });
      } else {
        return createAdminVendorProgram({ ...form })
          .unwrap()
          .then((newVendorProgram) => {
            setIsCreatingVendorProgram(false);
            makeClean();
            onCreateOrUpdate();
            navigateToVendorProgramShow(
              {
                showToast: true,
                toastMessage: "Vendor Program created.",
              },
              newVendorProgram.id
            );
            return { status: "success" };
          })
          .catch((error) => {
            console.log(error);
            setShowErrorBanner(true);
            return {
              status: "fail",
              errors: [{ message: "Could not create vendor program" }],
            };
          });
      }
    },
  });

  useEffect(() => {
    onEdit(vendorProgramFormDirty);
  }, [onEdit, vendorProgramFormDirty]);

  useImperativeHandle(ref, () => ({
    handleSubmitForm() {
      submit();
    },
  }));

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const errorBanner = submitErrors.length > 0 && showErrorBanner && (
    <Banner tone="critical" title="Something went wrong">
      {submitErrors.map((error, index) => {
        return <p key={index}>{error.message}</p>;
      })}
    </Banner>
  );

  const yesOrNoOption = [
    { label: "Yes", value: "true" },
    { label: "No", value: "false" },
  ];

  return (
    <>
      <Modal.Section>
        <Form onSubmit={submit}>
          <FormLayout>
            {errorBanner}
            <TextField
              label="Name"
              {...fields.name}
              disabled={vendorProgramId.length > 0}
            />
            <Select
              label="Requires Credit Submission"
              options={yesOrNoOption}
              {...fields.requires_credit_submission}
            />
            <FormLayout.Group>
              <TextField
                label="Standard Buy Rate"
                {...fields.standard_buy_rate}
              />
              <TextField
                label="Standard Interest Rate"
                {...fields.standard_interest_rate}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField
                label="Minimum Term Length"
                {...fields.term_length_min}
              />
              <TextField
                label="Maximum Term Length"
                {...fields.term_length_max}
              />
            </FormLayout.Group>
          </FormLayout>
        </Form>
      </Modal.Section>
    </>
  );
});
VendorProgramForm.displayName = "VendorProgramForm";

VendorProgramForm.propTypes = {
  history: PropTypes.object,
  vendorProgramId: PropTypes.string,
  vendorProgram: PropTypes.object,
  onCreateOrUpdate: PropTypes.func,
  onEdit: PropTypes.func,
  setIsCreatingVendorProgram: PropTypes.func,
};

export default VendorProgramForm;
