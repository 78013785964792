import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import {
  Page,
  Card,
  Tabs,
  Modal,
  TextField,
  Text,
  Button,
  BlockStack,
  FormLayout,
} from "@shopify/polaris";

import { CurrentContactContext } from "../../../contexts/Contact";
import AdminSettingsOrgAdminsTab from "../../../components/Admin/Settings/Tabs/OrgAdmins";
import AdminSettingsTagsTab from "../../../components/Admin/Settings/Tabs/Tags";
import AdminContactForm from "../../../components/AdminContact/Form";

import {
  useGetAdminsQuery,
  useGetTagsQuery,
  useCreateTagMutation,
  useDeleteTagsMutation,
  useAddAdminMutation,
  useRevokeAdminsMutation,
} from "../../../services/api";

const AdminSettings = () => {
  const { currentContact } = useContext(CurrentContactContext);

  const { data: admins = [], isLoading: fetchingAdmins } = useGetAdminsQuery();

  const { data: tagsData = null, isLoading: fetchingTags } = useGetTagsQuery();

  const [createTag, { isLoading: isCreatingTag }] = useCreateTagMutation();

  const [deleteTags, { isLoading: isDeletingTags }] = useDeleteTagsMutation();

  const [addOrgAdminContact, { isLoading: isAddingOrgAdminContact }] =
    useAddAdminMutation();

  const [revokeOrgAdminRole, { isLoading: isRevokingOrgAdmin }] =
    useRevokeAdminsMutation();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selected, setSelected] = useState(0);
  const [tagName, setTagName] = useState("");
  const [tags, setTags] = useState([]);
  const [allTagsSelected, setAllTagsSelected] = useState(false);
  const [selectedTagIds, setSelectedTagIds] = useState([]);
  const [createTagModalActive, setCreateTagModalActive] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [confirmRevokeAdminRole, setConfirmRevokeAdminRole] = useState(true);
  const [showAddAdminModal, setShowAddAdminModal] = useState(false);
  const [showNewContactForm, setShowNewContactForm] = useState(false);
  const [addNewContact, setAddNewContact] = useState(false);
  const [inviteParams, setInviteParams] = useState({
    contactIds: [],
    notifyContacts: false,
    notifMsg: "",
  });

  const [notifyContacts, setNotifyContacts] = useState(false);
  const vendorContactFormRef = useRef();

  const handleNotifyContacts = useCallback((notify) => {
    setNotifyContacts(notify);
  }, []);

  const handleAfterAddAdmin = () => {
    setNotifyContacts(false);
    setToastMessage("Admins added");
    toggleShowToast();
  };

  const handleAddNewAdmins = () => {
    const payload = { ...inviteParams };
    payload.ids = payload.contactIds;
    delete payload.contactIds;

    return addOrgAdminContact({ ...payload })
      .unwrap()
      .then((response) => {
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTagSelectionChange = useCallback(
    (selectedTagIds) => setSelectedTagIds(selectedTagIds),
    [selectedTagIds]
  );

  const handleAllTagsSelectionChange = useCallback(() => {
    () => setAllTagsSelected(!allTagsSelected);
  }, [allTagsSelected]);

  const handleCreateTagModalActiveChange = useCallback(
    () => setCreateTagModalActive(!createTagModalActive),
    [createTagModalActive]
  );

  const handleSetTagName = useCallback((value) => setTagName(value), []);

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    []
  );

  const handleCreateTag = () => {
    return createTag({ tag: tagName })
      .unwrap()
      .then(() => {
        setTagName("");
        handleCreateTagModalActiveChange();
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteTags = () => {
    setOpenConfirmationModal(false);

    return deleteTags({ tag_ids: selectedTagIds.join(",") })
      .unwrap()
      .then(() => {
        handleTagSelectionChange([]);
        handleAllTagsSelectionChange(false);
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRevokeOrgAdminRole = () => {
    setOpenConfirmationModal(false);

    return revokeOrgAdminRole({ ids: selectedUsers })
      .unwrap()
      .then((response) => {
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const handleConfirmDeleteModal = () => {
  //   bulkDeleteTags();
  //   setOpenConfirmationModal(false);
  // };

  useEffect(() => {
    if (tagsData) {
      setTags(tagsData);
    }
  }, [tagsData]);

  const tabs = [
    {
      id: "org_admins",
      content: "Organization Admins",
      accessibilityLabel: "Organization Admins",
      panelID: "org-admins",
      tab: (
        <div style={{ marginTop: "1.5rem" }}>
          <AdminSettingsOrgAdminsTab
            admins={admins}
            setSelectedUsers={setSelectedUsers}
            setShowAddAdminModal={setShowAddAdminModal}
            setOpenConfirmationModal={setOpenConfirmationModal}
            setConfirmRevokeAdminRole={setConfirmRevokeAdminRole}
            isRevokingOrgAdmin={isRevokingOrgAdmin}
            currentContactId={currentContact.attributes.id}
            fetchingAdmins={fetchingAdmins}
          />
        </div>
      ),
    },
    {
      id: "tags",
      content: "Tags",
      accessibilityLabel: "Tags",
      panelID: "tags",
      tab: (
        <div style={{ marginTop: "1.5rem" }}>
          <AdminSettingsTagsTab
            tags={tags}
            fetchingTags={fetchingTags}
            isCreatingTag={isCreatingTag}
            isDeletingTags={isDeletingTags}
            selectedResources={selectedTagIds}
            setSelectedResources={handleTagSelectionChange}
            allResourcesSelected={allTagsSelected}
            setAllResourcesSelected={handleAllTagsSelectionChange}
            setConfirmRevokeAdminRole={setConfirmRevokeAdminRole}
            setOpenConfirmationModal={setOpenConfirmationModal}
            handleCreateTagModalActiveChange={handleCreateTagModalActiveChange}
          />
        </div>
      ),
    },
  ];

  const areYouSureModal = (
    <Modal
      open={openConfirmationModal}
      onClose={() => setOpenConfirmationModal(false)}
      title="Are you sure?"
      primaryAction={{
        content: "Confirm",
        onAction: confirmRevokeAdminRole
          ? handleRevokeOrgAdminRole
          : handleDeleteTags,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => setOpenConfirmationModal(false),
        },
      ]}
    >
      <Modal.Section>
        <Text variant="bodyMd" as="p">
          {confirmRevokeAdminRole
            ? `You are about to revoke organization admin roles on ${selectedUsers.length} users.`
            : `You are about to delete ${selectedTagIds.length} tags. This action cannot be undone.`}
        </Text>
        <Text variant="bodyMd" as="p">
          {confirmRevokeAdminRole
            ? `This will limit access.`
            : `Deleting tags will remove them from all opportunities.`}
        </Text>
      </Modal.Section>
    </Modal>
  );

  const createTagModal = (
    <Modal
      open={createTagModalActive}
      onClose={handleCreateTagModalActiveChange}
      title="New tag"
      loading={isCreatingTag}
      primaryAction={{
        content: "Save",
        onAction: handleCreateTag,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleCreateTagModalActiveChange,
        },
      ]}
    >
      <Modal.Section>
        <TextField
          label="Tag name"
          value={tagName}
          onChange={handleSetTagName}
          helpText="Can create multiple tags by separating with commas. Ex: 'Tag 1, Tag 2'"
          autoComplete="off"
        />
      </Modal.Section>
    </Modal>
  );

  const addAdminModal = (
    <Modal
      open={showAddAdminModal}
      onClose={() => setShowAddAdminModal(false)}
      title={"Add admin"}
      large={true}
      primaryAction={{
        content: "Add",
        loading: addNewContact,
        onAction: () => {
          if (showNewContactForm) {
            setAddNewContact(true);
          } else {
            handleAddNewAdmins();
            setShowAddAdminModal(false);
          }
        },
      }}
    >
      <Modal.Section>
        <AdminContactForm
          onNotifyContacts={(notify) => {
            handleNotifyContacts(notify);
          }}
          onAfterShareWithContacts={handleAfterAddAdmin}
          ref={vendorContactFormRef}
          setInviteParams={setInviteParams}
          isShowNewContactForm={setShowNewContactForm}
          addNewContact={addNewContact}
          setAddNewContact={setAddNewContact}
        />
      </Modal.Section>
    </Modal>
  );

  return (
    <Page fullWidth title="FORT Capital Organization Settings">
      <BlockStack gap={"400"}>
        <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
          {tabs[selected].tab}
        </Tabs>

        <Card>
          <BlockStack gap={"400"}>
            <Text variant="headingMd" as="h6">
              Beta features
            </Text>
            <FormLayout>
              <Button id="beta-button">Enable beta features</Button>
            </FormLayout>
          </BlockStack>
        </Card>
      </BlockStack>
      {createTagModal}
      {areYouSureModal}
      {addAdminModal}
    </Page>
  );
};

export default AdminSettings;
