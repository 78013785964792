import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";

import {
  Card,
  Box,
  InlineStack,
  FooterHelp,
  Link,
  Page,
  Button,
  Text,
  LegacyStack,
  Layout,
  Labelled,
  Modal,
  Toast,
} from "@shopify/polaris";

import {
  useGetClientAccountQuery,
  useCreateOpportunityMutation,
} from "../../services/api";

import AccountForm from "../../components/Account/Form";
import FadeIn from "../../components/FadeIn";
import ClientOpportunityList from "../../components/Client/OpportunityList";
import LoadingScreen from "../../components/Auth/Loading";

import CardBoxTitle from "../../components/Card/BoxTitle";

import { CurrentContactContext } from "../../contexts/Contact";

const AccountsShow = (props) => {
  const history = useHistory();
  const location = useLocation();

  const { currentContact } = useContext(CurrentContactContext)

  const { id: accountId } = useParams();

  useEffect(() => {
    if (!accountId) {
      navigateToAccountsIndex();
    }
  }, [navigateToAccountsIndex, accountId]);

  const {
    data: clientAccount = { attributes: {} },
    isLoading: isLoadingClient,
  } = useGetClientAccountQuery(accountId);

  const [createOpportunity, { isLoading: isCreatingOpportunity }] =
    useCreateOpportunityMutation();

  const navigateToAccountsIndex = useCallback(() => {
    history.push("/accounts");
  }, [history]);

  const navigateToNewOpportunityShow = (opportunity) => {
    history.push({
      pathname: `/clients/${accountId}/opportunities/${opportunity.id}`,
      state: {
        opportunity: { ...opportunity },
        showToast: true,
        toastMessage: "Opportunity created.",
      },
    });
  };

  const [showEditAccountDetailsModal, setShowAccountDetailsModal] =
    useState(false);
  const handleEditAccountDetails = () => {
    setShowAccountDetailsModal(true);
  };
  const handleCloseModal = () => {
    setShowAccountDetailsModal(false);
    // setShowContactFormModal(false);
  };

  const [accountFormDirty, setAccountFormDirty] = useState(false);
  const handleAccountEdit = (isDirty) => {
    setAccountFormDirty(isDirty);
  };
  const handleAccountUpdate = (data) => {
    handleCloseModal();
    setShowToast(true);
    setToastMessage("Account updated.");
  };

  // const [showContactFormModal, setShowContactFormModal] = useState(false);
  // const handleCreateContact = () => {
  //   setShowContactFormModal(true);
  //   setSelectedContact();
  // };

  const buildOpportunity = () => {
    return {
      client_id: accountId,
    };
  };

  const handleCreateOpportunity = () => {
    const opportunity = buildOpportunity();

    createOpportunity({ accountId, opportunity: opportunity })
      .then(({ data: opportunity }) => {
        navigateToNewOpportunityShow(opportunity);
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const accountFormRef = useRef();

  const editAccountModal = (
    <Modal
      open={showEditAccountDetailsModal}
      onClose={handleCloseModal}
      title="Edit account"
      primaryAction={{
        content: "Save",
        onAction: () => accountFormRef.current.handleSubmitForm(),
        disabled: !accountFormDirty,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleCloseModal,
        },
      ]}
    >
      <AccountForm
        ref={accountFormRef}
        account={clientAccount}
        onCreateOrUpdate={handleAccountUpdate}
        onEdit={handleAccountEdit}
      />
    </Modal>
  );

  const footerHelp = (
    <FooterHelp>
      Learn more about{" "}
      <Link
        external
        url={"https://help.fortifypay.com/how-to-create-an-opportunity"}
      >
        managing clients
      </Link>
    </FooterHelp>
  );

  const handleOpportunityClick = (opportunity) => {
    history.push({
      pathname: `/clients/${accountId}/opportunities/${opportunity.id}`,
      state: { opportunity: { ...opportunity } },
    });
  };

  const opportunitiesResourceList = (
    <ClientOpportunityList
      stageFilters={[]}
      onCreateOpportunity={handleCreateOpportunity}
      onOpportunityClick={handleOpportunityClick}
      clientId={accountId}
    />
  );

  const [showToast, setShowToast] = useState(location?.state?.showToast);
  const [toastMessage, setToastMessage] = useState(
    location?.state?.toastMessage
  );
  const toggleShowToast = useCallback(
    () => setShowToast((active) => !active),
    []
  );
  const handleDismissToast = () => {
    const { state: currentState } = location;
    if (currentState?.toastMessage) {
      delete currentState.toastMessage;
    }
    if (currentState?.showToast) {
      delete currentState.showToast;
    }
    window.history.replaceState({ ...currentState }, "");
    toggleShowToast();
    setToastMessage();
  };
  const toastMarkup = showToast ? (
    <Toast content={toastMessage} onDismiss={handleDismissToast} />
  ) : undefined;

  const labelStyles = {
    minHeight: "36px",
    paddingTop: "4px",
  };

  const pageMarkup = (
    <FadeIn fadeIn>
      <Page
        title={clientAccount.attributes.name}
        subtitle={`${clientAccount.attributes.type}`}
      >
        <Layout>
          {/* Account details */}
          <Layout.Section primary>
            <Card padding="0">
              <Box padding="400">
                <InlineStack align="space-between">
                  <Text variant="headingMd" as="h2">
                    Account information
                  </Text>
                  <Button  onClick={() => handleEditAccountDetails()} variant="plain">
                    Edit client
                  </Button>
                </InlineStack>
              </Box>
              <Box padding="400">
                <LegacyStack
                  alignment={"trailing"}
                  distribution={"fillEvenly"}
                  spacing={"loose"}
                >
                  <Labelled label="Legal name">
                    <div style={labelStyles}>
                      <LegacyStack alignment="trailing" spacing="tight">
                        <LegacyStack alignment="center" spacing="tight">
                          <p>{clientAccount.attributes.name}</p>
                        </LegacyStack>
                      </LegacyStack>
                    </div>
                  </Labelled>

                  <Labelled label="DBA">
                    <div style={labelStyles}>
                      <LegacyStack alignment="trailing" spacing="tight">
                        <LegacyStack alignment="center" spacing="tight">
                          <p>{clientAccount.attributes.dba || "-"}</p>
                        </LegacyStack>
                      </LegacyStack>
                    </div>
                  </Labelled>
                </LegacyStack>
              </Box>

              <Box padding="400">
                <Labelled label="Street address">
                  <div style={labelStyles}>
                    <LegacyStack alignment="trailing" spacing="tight">
                      <LegacyStack alignment="center" spacing="tight">
                        <p>{clientAccount.attributes.billing_street || "-"}</p>
                      </LegacyStack>
                    </LegacyStack>
                  </div>
                </Labelled>

                <LegacyStack
                  alignment={"trailing"}
                  distribution={"fillEvenly"}
                  spacing={"loose"}
                >
                  <Labelled label="City">
                    <div style={labelStyles}>
                      <LegacyStack alignment="trailing" spacing="tight">
                        <LegacyStack alignment="center" spacing="tight">
                          <p>{clientAccount.attributes.billing_city || "-"}</p>
                        </LegacyStack>
                      </LegacyStack>
                    </div>
                  </Labelled>

                  <Labelled label="State">
                    <div style={labelStyles}>
                      <LegacyStack alignment="trailing" spacing="tight">
                        <LegacyStack alignment="center" spacing="tight">
                          <p>{clientAccount.attributes.billing_state || "-"}</p>
                        </LegacyStack>
                      </LegacyStack>
                    </div>
                  </Labelled>

                  <Labelled label="Zip code">
                    <div style={labelStyles}>
                      <LegacyStack alignment="trailing" spacing="tight">
                        <LegacyStack alignment="center" spacing="tight">
                          <p>
                            {clientAccount.attributes.billing_postal_code ||
                              "-"}
                          </p>
                        </LegacyStack>
                      </LegacyStack>
                    </div>
                  </Labelled>
                </LegacyStack>
              </Box>
            </Card>
          </Layout.Section>

          <Layout.Section>
            <Card padding="0">
              <CardBoxTitle>
                <InlineStack align="space-between">
                  <Text variant="headingMd" as="h6">Opportunities</Text>
                  <Button
                    variant="primary"
                    onClick={handleCreateOpportunity}
                    disabled="true"
                  >
                    Create opportunity
                  </Button>
                </InlineStack>
              </CardBoxTitle>
              {opportunitiesResourceList}
            </Card>
          </Layout.Section>
          <Layout.Section>{footerHelp}</Layout.Section>
        </Layout>

        {editAccountModal}
        {toastMarkup}
      </Page>
    </FadeIn>
  );

  return currentContact ? pageMarkup : <LoadingScreen />
};

export default AccountsShow;
