import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Text,
  Box,
  Card,
  Button,
  Badge,
  BlockStack,
  InlineStack,
  useBreakpoints,
} from '@shopify/polaris';

const PurchaseOptionLockCard = (props) => {
  const {
    team,
    togglePurchaseOptionLock
  } = props;

  const [purchaseOptionLocked, setPurchaseOptionLocked] = useState(team?.attributes?.financing_option_locked);
  const [isLoadingPurchaseOptionLock, setIsLoadingPurchaseOptionLock] = useState(false);
  const handleTogglePurchaseOptionLock = useCallback(() => {
    setIsLoadingPurchaseOptionLock(true);
    setPurchaseOptionLocked((purchaseOptionLocked) => !purchaseOptionLocked);
    togglePurchaseOptionLock();
  }, []);
  const contentStatus = team?.attributes?.financing_option_locked ? 'Turn off' : 'Turn on';
  const toggleId = 'setting-toggle-uuid';
  const descriptionId = 'setting-toggle-description-uuid';
  const {mdDown} = useBreakpoints();
  const badgeStatus = team?.attributes?.financing_option_locked ? 'success' : undefined;
  const badgeContent = team?.attributes?.financing_option_locked ? 'On' : 'Off';

  useEffect(() => {
    if(isLoadingPurchaseOptionLock){
      setIsLoadingPurchaseOptionLock(false);
    }
  }, [team]);

  const settingStatusMarkup = (
    <Badge
      tone={badgeStatus}
      toneAndProgressLabelOverride={`Setting is ${badgeContent}`}
    >
      {badgeContent}
    </Badge>
  );

  const settingTitle = (
    <InlineStack gap="200">
      <label htmlFor={toggleId}>
        <div style={{ marginTop: "2.5px" }}>
          <Text variant="headingMd" as="h6">
            Purchase option lock
          </Text>
        </div>
      </label>
      {settingStatusMarkup}
    </InlineStack>
  );

  const actionMarkup = (
    <Button
      role="switch"
      id={toggleId}
      ariaChecked={team?.attributes?.financing_option_locked ? 'true' : 'false'}
      onClick={handleTogglePurchaseOptionLock}
      size="slim"
      loading={isLoadingPurchaseOptionLock}
    >
      {contentStatus}
    </Button>
  );

  const headerMarkup = (
    <Box width="100%">
      <InlineStack gap="200" align="space-between">
        {settingTitle}
        {!mdDown ? (
          <Box minWidth="fit-content">
            {actionMarkup}
          </Box>
        ) : null}
      </InlineStack>
    </Box>
  );

  const descriptionMarkup = (
    <BlockStack gap="400">
      <Text id={descriptionId} variant="bodyMd" as="p" tone="subdued">
        When purchase option lock is on, members cannot add, remove, or edit purchase options.
        When purchase option lock is off, members can adjust terms for any option.
      </Text>
      {mdDown ? (
        <Box width="100%">
          {actionMarkup}
        </Box>
      ) : null}
    </BlockStack>
  );

  return (
    <Card>
      <BlockStack gap={{xs: '4', sm: '5'}}>
        <Box width="100%">
          <BlockStack gap={{xs: '100', sm: '200'}}>
            {headerMarkup}
            {descriptionMarkup}
          </BlockStack>
        </Box>
        { team?.attributes?.financing_option_locked ?
        (<Text variant="bodyMd" as="p">
           This team is locked to purchase option templates
         </Text>)
        : (<></>)}
      </BlockStack>
    </Card>
  );

};

PurchaseOptionLockCard.propTypes = {
  team: PropTypes.any,
  togglePurchaseOptionLock: PropTypes.func,
};

export default PurchaseOptionLockCard;


