import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import {
  IndexTable,
  Filters,
  Text,
  Pagination,
  LegacyStack,
  Link,
  Badge,
  useIndexResourceState,
} from "@shopify/polaris";

import { useDebounce } from "use-debounce";

import { useUpdateAdminVendorProgramMutation } from "../../services/api";

const AttachmentsIndexTable = (props) => {
  const { vendorProgramId, attachments, setIsDeletingTemplates } = props;

  const history = useHistory();

  const [updateVendorProgram, { isLoading: isUpdatingVendorProgram }] =
    useUpdateAdminVendorProgramMutation();

  const resourceName = {
    singular: "attachment template",
    plural: "attachment templates",
  };

  const [sortValue, setSortValue] = useState("UPDATED_AT_DESC");

  const [allSelected, setAllSelected] = useState(false);
  const [selectedResources, setSelectedResources] = useState([]);

  const handleAttachmentsSelectionChange = (
    selectionType,
    toggleType,
    selection
  ) => {
    if (selectionType === "single") {
      if (toggleType) {
        let listOfResources = [...selectedResources];
        listOfResources.push(attachments[selection]);

        if (listOfResources.length === attachments.length) setAllSelected(true);
        setSelectedResources(listOfResources);
      } else {
        let listOfResources = [...selectedResources];
        let removeIndex = listOfResources.indexOf(attachments[selection]);
        listOfResources.splice(removeIndex, 1);

        setAllSelected(false);
        setSelectedResources(listOfResources);
      }
    } else if (selectionType === "page") {
      if (toggleType) {
        setSelectedResources(attachments);
        setAllSelected(true);
      } else {
        setSelectedResources([]);
        setAllSelected(false);
      }
    }
  };

  const bulkDeleteAttacmentTemplates = () => {
    let newAttachments = [...attachments];
    for (let i = 0; i < selectedResources.length; i++) {
      let deleteIndex = newAttachments.indexOf(selectedResources[i]);
      if (deleteIndex >= 0) newAttachments.splice(deleteIndex, 1);
    }

    return updateVendorProgram({
      id: vendorProgramId,
      attachment_set: newAttachments,
    })
      .unwrap()
      .then((response) => {
        setSelectedResources([]);
        setAllSelected(false);

        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
        return {
          status: "fail",
          errors: [{ message: "Could not update program." }],
        };
      });
  };

  const promotedBulkActions = [
    {
      content: "Delete",
      onAction: bulkDeleteAttacmentTemplates,
    },
  ];

  const rowMarkup =
    attachments?.length > 0
      ? attachments.map((item, index) => (
          <IndexTable.Row
            id={index}
            key={index}
            selected={selectedResources.includes(item)}
            position={index}
          >
            <IndexTable.Cell>
              <Text variant="strong" fontWeight="bold" truncate>
                <Link
                  removeUnderline
                  monochrome
                  // onClick={()=>handleVendorProgramClick(item)}
                >
                  {item}
                </Link>
              </Text>
            </IndexTable.Cell>
          </IndexTable.Row>
        ))
      : [];

  return (
    <div>
      <IndexTable
        resourceName={resourceName}
        itemCount={attachments?.length}
        selectable={true}
        headings={[{ title: "Names" }]}
        sortable={[false]}
        defaultSortDirection={"descending"}
        onSelectionChange={handleAttachmentsSelectionChange}
        selectedItemsCount={allSelected ? "All" : selectedResources.length}
        bulkActions={[]}
        promotedBulkActions={promotedBulkActions}
        loading={isUpdatingVendorProgram}
      >
        {attachments ? rowMarkup : <></>}
      </IndexTable>
    </div>
  );
};

AttachmentsIndexTable.propTypes = {
  attachments: PropTypes.any,
};

export default AttachmentsIndexTable;
