import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useContext,
} from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";

import {
  Card,
  Page,
  ResourceItem,
  ResourceList,
  Layout,
  Select,
  Text,
  DropZone,
  Icon,
  Truncate,
  Toast,
  FormLayout,
  Modal,
  Banner,
  TextField,
  OptionList,
  BlockStack,
  InlineStack,
  InlineGrid,
  Button,
} from "@shopify/polaris";

import { AttachmentIcon } from "@shopify/polaris-icons";

import {
  useGetOpportunityCreditSubmissionQuery,
  useGetOpportunityQuery,
  useGetAccountQuery,
  useUpdateOpportunityCreditSubmissionMutation,
  useUpdateOpportunityCreditSubmissionSubmitToLenderMutation,
  useUpdateOpportunityCreditSubmissionSelectLenderMutation,
  useUpdateOpportunityCreditSubmissionDeselectLenderMutation,
  useGetGuarantorsQuery,
  useGetOpportunityAttachmentsQuery,
} from "../../services/api";

import { useDynamicList, useForm, notEmpty } from "@shopify/react-form";

import {
  convertServerDate,
  formatCurrency,
  formatShortDate,
} from "../../../javascript/utilities";

import ApprovedForm from "../../components/CreditSubmission/ApprovedForm";
import DeclinedForm from "../../components/CreditSubmission/DeclinedForm";
import NeedsInfoForm from "../../components/CreditSubmission/NeedsInfoForm";
import PendingForm from "../../components/CreditSubmission/PendingForm";

import LoadingScreen from "../../components/Auth/Loading";

import { CurrentContactContext } from "../../contexts/Contact";

const CreditSubmissionShow = () => {
  const history = useHistory();
  const location = useLocation();

  const { currentContact } = useContext(CurrentContactContext);

  const { id: creditSubmissionId, opportunityId: opportunityId } = useParams();

  const { data: opportunity } = useGetOpportunityQuery(opportunityId);
  const { data: creditSubmission = { attributes: {} } } =
    useGetOpportunityCreditSubmissionQuery({
      id: creditSubmissionId,
      opportunityId: opportunityId,
    });

  // TODO: refactor to useGetLenderQuery
  const { data: account = { attributes: {} } } = useGetAccountQuery(
    creditSubmission?.attributes.lender_id,
    {
      skip: !creditSubmission?.attributes.lender_id,
    }
  );

  const {
    data: guarantors = [],
    isLoading: isGuarantorsLoading = false,
    refetch: refetchGuarantors,
  } = useGetGuarantorsQuery(opportunityId, { skip: !opportunityId });

  const {
    data: opportunityAttachments,
    isLoading = false,
    refetch: refetchOpportunityAttachments,
  } = useGetOpportunityAttachmentsQuery(
    { opportunityId: opportunityId, isPublic: false },
    { skip: !opportunityId }
  );

  const [attachmentOptions, setAttachmentOptions] = useState([]);

  useEffect(() => {
    if (opportunityAttachments) {
      let opSelect = [];

      for (let i = 0; i < opportunityAttachments.length; i++) {
        if (!opportunityAttachments[i].attributes.filename) {
          continue;
        }

        opSelect.push({
          value: opportunityAttachments[i],
          label:
            opportunityAttachments[i].attributes.name +
            " - " +
            opportunityAttachments[i].attributes.filename,
        });
      }

      setAttachmentOptions(opSelect);
    }
  }, [opportunityAttachments]);

  const decisionOptions = [
    { label: "Pending", value: "Pending" },
    { label: "Needs more info", value: "Needs more info" },
    { label: "Approved", value: "Approved" },
    { label: "Declined", value: "Declined" },
  ];

  const navigateBack = () => {
    history.push({
      pathname: `/clients/${opportunity.attributes.client_id}/opportunities/${opportunityId}`,
      search: `?tab=credit-submissions`,
    });
  };

  const navigateToEditOpportunity = () => {
    history.push({
      pathname: `/clients/${opportunity.attributes.client_id}/opportunities/${opportunityId}`,
    });
  };

  const [updateCreditSubmissionSubmitToLender, { isLoading: isSubmitting }] =
    useUpdateOpportunityCreditSubmissionSubmitToLenderMutation();

  const [openSubmitToLenderModal, setOpenSubmitToLenderModal] = useState(false);
  const toggleSubmitToLenderModal = () => {
    setOpenSubmitToLenderModal(!openSubmitToLenderModal);
  };

  const [openSubmitSsnToMacquarieModal, setOpenSubmitSsnToMacquarieModal] =
    useState(false);
  const toggleOpenSubmitSsnToMacquarieModal = () => {
    setOpenSubmitSsnToMacquarieModal(!openSubmitSsnToMacquarieModal);
  };

  const [ssnNumbers, setSsnNumbers] = useState([]);

  const submitToLender = (ssn) => {
    setLenderErrorMessage(undefined);
    setMultipleLenderErrorMessage([]);

    let documents = [];
    for (let i = 0; i < selectedAttachments.length; i++) {
      documents.push(selectedAttachments[i].id);
    }

    return updateCreditSubmissionSubmitToLender({
      id: creditSubmissionId,
      opportunityId: opportunityId,
      ssn: ssn || [],
      body: {
        documents: documents,
      },
    })
      .unwrap()
      .then(() => {
        setSsnNumbers([]);

        setToastMessage("Application Submitted");
        setShowToast(true);
        setLenderErrorMessage(undefined);

        return { status: "success" };
      })
      .catch((error) => {
        toggleLenderErrorBanner();
        setSsnNumbers([]);

        console.log(error);

        if (Array.isArray(error.data.table.errors)) {
          let errorMessages = error.data.table.errors;

          setMultipleLenderErrorMessage(errorMessages);
        } else if (error.data.table.errors.message_text) {
          setLenderErrorMessage(error.data.table.errors.message_text);
        } else {
          setLenderErrorMessage(error.data.table.errors);
        }
      });
  };

  const submitToLenderModal = (
    <Modal
      open={openSubmitToLenderModal}
      title={"Are you sure?"}
      primaryAction={{
        content: "Confirm",
        onAction: () => {
          if (account.attributes.lender_integration === "macquarie") {
            toggleOpenSubmitSsnToMacquarieModal();
          } else {
            submitToLender();
          }
          toggleSubmitToLenderModal();
        },
        disabled:
          process.env.APPLICATION_HOST === "app.fortifypay.com" ? true : false,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            toggleSubmitToLenderModal();
            toggleLenderErrorBanner();
          },
          key: 1,
        },
      ]}
      onClose={toggleSubmitToLenderModal}
    >
      <Modal.Section>
        <BlockStack>
          <p>
            An application will be sent to Macquarie with the information
            contained on this credit submission. This cannot be undone.
          </p>

          {process.env.APPLICATION_HOST === "app.fortifypay.com" && (
            <p>
              <Text as="span" tone="critical">
                Macquarie integration temporarily disabled in production. 🚧
                Work in progress 🚧
              </Text>
            </p>
          )}
        </BlockStack>
      </Modal.Section>
    </Modal>
  );

  const emptySsnFactory = ({ name, guarantorId }) => {
    return {
      name: name || "uy",
      guarantorId: guarantorId || "",
      ssn: "",
    };
  };

  const [showSsnErrorBanner, setShowSsnErrorBanner] = useState(false);
  const [ssnErrorMessage, setSsnErrorMessage] = useState("");
  const ssnValidationErrorBanner = showSsnErrorBanner && (
    <Modal.Section>
      <Banner tone="critical" title={`Error: Invalid SSN Input`}>
        <p>{ssnErrorMessage}</p>
      </Banner>
    </Modal.Section>
  );

  const { fields: ssnFields, addItem: addSsnFieldItem } = useDynamicList(
    {
      list: ssnNumbers,
      validates: {
        ssn: (ssn) => {
          if (ssn.length != 9) {
            setSsnErrorMessage("Should be a 9 digit number");
            setShowSsnErrorBanner(true);
            return "Should be a 9 digit number";
          }

          let isNumbersOnly = /^\d+$/.test(ssn);
          if (!isNumbersOnly) {
            setSsnErrorMessage("Should only contain numbers");
            setShowSsnErrorBanner(true);
            return "Should only contain numbers";
          }
        },
        value: [notEmpty("SSN is required.")],
      },
    },
    emptySsnFactory
  );

  useEffect(() => {
    if (guarantors.length > 0) {
      for (let i = 0; i < guarantors.length; i++) {
        if (guarantors[i].attributes.is_individual) {
          addSsnFieldItem({
            name: guarantors[i].attributes.name,
            guarantorId: guarantors[i].id,
          });
        }
      }
    }
  }, [guarantors]);

  const {
    fields,
    submit: submitToMacquarie,
    submitting,
    reset,
    submitErrors,
    makeClean,
    dirty,
    dynamicLists,
  } = useForm({
    fields: {
      ssnNumberFields: ssnFields,
    },
    async onSubmit(form) {
      submitToLender(form.ssnNumberFields);

      toggleOpenSubmitSsnToMacquarieModal();
    },
  });

  const [selectedAttachments, setSelectedAttachments] = useState([]);

  const submitSsnToMacquarieModal = (
    <Modal
      open={openSubmitSsnToMacquarieModal}
      title={"Additional information required"}
      primaryAction={{
        content: "Confirm",
        onAction: () => {
          setShowSsnErrorBanner(false);
          submitToMacquarie();
        },
        disabled: !dirty,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => {
            toggleOpenSubmitSsnToMacquarieModal();
            toggleLenderErrorBanner();
          },
          key: 1,
        },
      ]}
      onClose={toggleOpenSubmitSsnToMacquarieModal}
    >
      {ssnValidationErrorBanner}

      <Modal.Section>
        <p>
          Maybe some nice information here about Macquarie needing your SSN, and
          that it won't be stored on the database.
        </p>

        <br />

        <FormLayout>
          {ssnFields.map((field, index) => (
            <div key={field.id}>
              <TextField
                placeholder="SSN Number (9 digit number)"
                label={field.name.value + " SSN Number"}
                value={field.ssn.value}
                onChange={field.ssn.onChange}
              />

              <br />
            </div>
          ))}
        </FormLayout>
      </Modal.Section>

      {attachmentOptions.length > 0 && (
        <Modal.Section flush>
          <OptionList
            title={"Attachments"}
            onChange={setSelectedAttachments}
            options={attachmentOptions}
            selected={selectedAttachments}
            allowMultiple
          />
        </Modal.Section>
      )}
    </Modal>
  );

  const [showSendToLenderSuccessBanner, setShowSendToLenderSuccessBanner] =
    useState(false);
  const [showSendToLenderErrorBanner, setShowSendToLenderErrorBanner] =
    useState(false);
  const toggleLenderSuccessBanner = () => {
    setShowSendToLenderSuccessBanner(!showSendToLenderSuccessBanner);
  };
  const toggleLenderErrorBanner = () => {
    setShowSendToLenderErrorBanner(!showSendToLenderErrorBanner);
  };

  const lenderSuccessBanner = showSendToLenderSuccessBanner && (
    <Banner tone="success" title="Success banner">
      <p>Success!!!</p>
    </Banner>
  );

  const defaultErrorMessage = () => {
    return `Awaiting feedback from ${account.attributes.name} technical team
    on final configuration 😎`;
  };
  const [lenderErrorMessage, setLenderErrorMessage] = useState(undefined);
  const [multipleLenderErrorMessage, setMultipleLenderErrorMessage] = useState(
    []
  );
  const lenderErrorBanner = showSendToLenderErrorBanner && (
    <Layout.Section>
      <Banner
        tone="critical"
        title={`Error: ${account.attributes.name} API Service`}
      >
        {multipleLenderErrorMessage.length > 0 ? (
          <>
            {multipleLenderErrorMessage.map((error, index) => (
              <li key={index}>{error.message_text}</li>
            ))}
          </>
        ) : (
          <p>{lenderErrorMessage || defaultErrorMessage()}</p>
        )}
      </Banner>
    </Layout.Section>
  );

  const submittedBanner = creditSubmission?.attributes.submitted_at && (
    <Layout.Section>
      <Banner tone="info" title="Application Submitted">
        <p>
          Application was submitted on{" "}
          {formatShortDate(
            convertServerDate(
              new Date(creditSubmission.attributes.submitted_at)
            )
          )}
          .
        </p>
      </Banner>
    </Layout.Section>
  );

  const [canSelectAsLender, setCanSelectAsLender] = useState(false);
  const [updateCreditSubmissionSelectLender, { isLoading: isSelecting }] =
    useUpdateOpportunityCreditSubmissionSelectLenderMutation();

  const handleSelectLender = () => {
    return updateCreditSubmissionSelectLender({
      id: creditSubmissionId,
      opportunityId: opportunityId,
    })
      .unwrap()
      .then(() => {
        setToastMessage("Selected as Lender");
        setShowToast(true);

        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [updateCreditSubmissionDeselectLender, { isLoading: isDeselecting }] =
    useUpdateOpportunityCreditSubmissionDeselectLenderMutation();

  const handleDeselectLender = () => {
    return updateCreditSubmissionDeselectLender({
      id: creditSubmissionId,
      opportunityId: opportunityId,
    })
      .unwrap()
      .then(() => {
        setToastMessage("Deselected Lender");
        setShowToast(true);

        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [files, setFiles] = useState([]);
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const attachmentResourceName = {
    singular: "attachment",
    plural: "attachments",
  };
  const attachmentResourceList = (attachment) => {
    return (
      <ResourceItem
        id={"id"}
        onClick={() => {}}
        accessibilityLabel={`Quote attachment`}
        verticalAlignment="center"
      >
        <InlineStack gap="200">
          <div className="credit-submissions-icon">
            <Icon source={AttachmentIcon} tone="subdued" />
          </div>
          <div style={{ marginTop: "10px" }}>
            <h3>
              <Text as="span" fontWeight="semibold">
                Quote
              </Text>
            </h3>
          </div>
        </InlineStack>
      </ResourceItem>
    );
  };

  const handleDecisionChange = useCallback(
    (value) => {
      return updateCreditSubmission({
        id: creditSubmissionId,
        opportunityId: opportunityId,
        decision: value,
      })
        .unwrap()
        .then(() => {
          setToastMessage("Credit submission updated.");
          setShowToast(true);
          return { status: "success" };
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [creditSubmissionId, opportunityId, updateCreditSubmission]
  );

  const makeDoubleDigitString = function (number) {
    if (number < 10) {
      return "0" + number.toString();
    } else {
      return number.toString();
    }
  };

  useEffect(() => {
    if (creditSubmission) {
      if (creditSubmission.attributes.decision === "Approved") {
        setCanSelectAsLender(true);
      } else {
        setCanSelectAsLender(false);
      }
    }
  }, [creditSubmission]);

  const [updateCreditSubmission, { isLoading: isUpdatingCreditSubmision }] =
    useUpdateOpportunityCreditSubmissionMutation();

  const approvedFormRef = useRef();

  const [showToast, setShowToast] = useState(location?.state?.showToast);
  const [toastMessage, setToastMessage] = useState("");
  const toggleShowToast = useCallback(
    () => setShowToast((active) => !active),
    []
  );
  const handleDismissToast = () => {
    toggleShowToast();
    setToastMessage("");
  };
  const toastMarkup = showToast ? (
    <Toast content={toastMessage} onDismiss={handleDismissToast} />
  ) : undefined;

  const secondaryActions = [
    ...(creditSubmission.attributes.decision === "Pending"
      ? [
          {
            content: "Submit to lender",
            disabled: false,
            onAction: toggleSubmitToLenderModal,
            loading: isSubmitting,
          },
        ]
      : []),
    {
      content: creditSubmission.attributes.selected_at
        ? "Deselect lender"
        : "Select as lender",
      disabled: creditSubmission.attributes.selected_at
        ? false
        : !canSelectAsLender,
      onAction: creditSubmission.attributes.selected_at
        ? handleDeselectLender
        : handleSelectLender,
    },
  ].flat();

  const pageMarkup = (
    <Page
      title={opportunity?.attributes.name || ""}
      subtitle={`${account.attributes.name} credit submission`}
      secondaryActions={secondaryActions}
      backAction={{ content: "Back to opportunity", onAction: navigateBack }}
    >
      <Layout>
        {/* {isSubmitting && (
          <Layout.Section>
            <div style={{ width: "100%", textAlign: "center" }}>
              <Spinner size="large" />
            </div>
          </Layout.Section>
        )} */}

        {lenderSuccessBanner}
        {lenderErrorBanner}
        {submittedBanner}

        <Layout.Section>
          {/* LENDERS DECISION */}
          <Card>
            <InlineStack align="space-between">
              <Text variant="headingMd" as="h6">
                Lender's decision
              </Text>
            </InlineStack>

            <br />

            <div>
              <FormLayout>
                <Select
                  label="Decision"
                  options={decisionOptions}
                  onChange={handleDecisionChange}
                  value={creditSubmission.attributes.decision || undefined}
                />
                {creditSubmission.attributes.decision == "Pending" && (
                  <PendingForm
                    creditSubmission={creditSubmission}
                    creditSubmissionId={creditSubmissionId}
                    opportunityId={opportunityId}
                    onEdit={() => {}}
                    onUpdate={() => {}}
                  />
                )}
              </FormLayout>
            </div>

            {creditSubmission.attributes.decision !== "Pending" && (
              <div>
                <br />

                {creditSubmission.attributes.decision == "Approved" && (
                  <ApprovedForm
                    ref={approvedFormRef}
                    creditSubmission={creditSubmission}
                    creditSubmissionId={creditSubmissionId}
                    opportunityId={opportunityId}
                    onEdit={() => {}}
                    onUpdate={() => {}}
                  />
                )}

                {creditSubmission.attributes.decision == "Needs more info" && (
                  <NeedsInfoForm
                    creditSubmission={creditSubmission}
                    creditSubmissionId={creditSubmissionId}
                    opportunityId={opportunityId}
                  />
                )}

                {creditSubmission.attributes.decision == "Declined" && (
                  <DeclinedForm
                    creditSubmission={creditSubmission}
                    creditSubmissionId={creditSubmissionId}
                    opportunityId={opportunityId}
                  />
                )}
              </div>
            )}
          </Card>

          <br />

          {/* ACCOUNT REFERENCE */}
          <Card>
            <InlineStack align="space-between">
              <Text variant="headingMd" as="h6">
                Details
              </Text>
              <Button variant="plain" onClick={navigateToEditOpportunity}>
                Edit Opportunity
              </Button>
            </InlineStack>

            <br />

            <div>
              <BlockStack gap="100">
                <Text variant="bodySm" as="p">
                  <Text as="span" tone="subdued">
                    Product description
                  </Text>
                </Text>
                <p>
                  <Truncate>
                    {opportunity?.attributes.product_description}
                  </Truncate>
                </p>
              </BlockStack>

              <br />

              <InlineGrid gap="200" columns={2}>
                <BlockStack gap="100">
                  <Text variant="bodySm" as="p">
                    <Text as="span" tone="subdued">
                      Structure
                    </Text>
                  </Text>
                  <p>{opportunity?.attributes.financing_option_structure}</p>
                </BlockStack>
                <BlockStack gap="100">
                  <Text variant="bodySm" as="p">
                    <Text as="span" tone="subdued">
                      Timing
                    </Text>
                  </Text>
                  <p>{opportunity?.attributes.payment_type || "-"}</p>
                </BlockStack>
              </InlineGrid>

              <br />

              <BlockStack gap="100">
                <Text variant="bodySm" as="p">
                  <Text as="span" tone="subdued">
                    Total Quote Amounts
                  </Text>
                </Text>
                <p>
                  {formatCurrency(
                    opportunity?.attributes.total_finance_amount +
                      opportunity?.attributes.total_sales_tax
                  )}
                </p>
              </BlockStack>
            </div>

            <br />

            <InlineStack align="space-between">
              <Text variant="headingMd" as="h6" tone="subdued">
                Client account
              </Text>
            </InlineStack>

            <br />

            <div>
              <BlockStack gap="100">
                <Text variant="bodySm" as="p">
                  <Text as="span" tone="subdued">
                    Legal name
                  </Text>
                </Text>
                <p>{opportunity?.attributes.client_name}</p>
              </BlockStack>

              <br />

              <BlockStack gap="100">
                <Text variant="bodySm" as="p">
                  <Text as="span" tone="subdued">
                    Street Address
                  </Text>
                </Text>
                <p>{opportunity?.attributes.customer_billing_street}</p>
              </BlockStack>

              <br />

              <InlineGrid gap="200" columns={3}>
                <BlockStack gap="100">
                  <Text variant="bodySm" as="p">
                    <Text as="span" tone="subdued">
                      City
                    </Text>
                  </Text>
                  <p>{opportunity?.attributes.customer_billing_city}</p>
                </BlockStack>
                <BlockStack gap="100">
                  <Text variant="bodySm" as="p">
                    <Text as="span" tone="subdued">
                      State
                    </Text>
                  </Text>
                  <p>{opportunity?.attributes.customer_billing_state}</p>
                </BlockStack>
                <BlockStack gap="100">
                  <Text variant="bodySm" as="p">
                    <Text as="span" tone="subdued">
                      Zip
                    </Text>
                  </Text>
                  <p>{opportunity?.attributes.customer_billing_postal_code}</p>
                </BlockStack>
              </InlineGrid>
            </div>

            <br />

            <div subdued title={"Credit Submission"}>
              <BlockStack gap="100">
                <Text variant="bodySm" as="p">
                  <Text as="span" tone="subdued">
                    Amount Requested
                  </Text>
                </Text>
                <p>
                  {formatCurrency(
                    creditSubmission?.attributes.amount_requested
                  )}
                </p>
              </BlockStack>
              <br />
            </div>
          </Card>

          <br />

          {/* ATTACHMENTS */}
          <Card>
            <InlineStack align="space-between">
              <Text variant="headingMd" as="h6">
                Attachments
              </Text>
            </InlineStack>

            <br />

            <div>
              <DropZone onDrop={handleDropZoneDrop} disabled>
                <DropZone.FileUpload actionHint="or drop files to upload" />
              </DropZone>
            </div>

            <div flush>
              <ResourceList
                resourceName={attachmentResourceName}
                items={[]}
                renderItem={(item) => attachmentResourceList(item)}
                selectedItems={[]}
                onSelectionChange={() => {}}
                loading={false}
                showHeader
                selectable
              />
            </div>
          </Card>

          <br />
          <br />
        </Layout.Section>

        <Layout.Section></Layout.Section>
      </Layout>

      {toastMarkup}

      {submitToLenderModal}
      {submitSsnToMacquarieModal}
    </Page>
  );

  return currentContact ? pageMarkup : <LoadingScreen />;
};

export default CreditSubmissionShow;
