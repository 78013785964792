import React from "react";
import { Button, Icon, TextField } from "@shopify/polaris";
import { CheckIcon } from "@shopify/polaris-icons";
import PropTypes from "prop-types";

class DateTextField extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { inputName, onChange, onDateSave } = this.props;
    return (
      <TextField
          {...this.props}
          onChange={(value) => {
            onChange({ target: { value: value } });
          }}
          connectedRight={
            <Button

              onClick={() => {
                onDateSave(inputName);
              }}
              variant="primary">
              <Icon tone={"success"} source={CheckIcon} />
            </Button>
          }
        />
    );
  }
}

DateTextField.displayName = "DateTextField";
DateTextField.propTypes = {
  inputName: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onDateSave: PropTypes.func,
};

export default DateTextField;
