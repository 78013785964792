import React, { useState, useEffect } from "react";
import { useDebounce } from "use-debounce";
import {
  useSearchOpportunityOriginatorsQuery,
  useUpdateOpportunityOriginatorMutation,
} from "../../services/api";

import {
  Autocomplete,
  Button,
  Icon,
  LegacyStack,
  Tag,
  BlockStack,
} from "@shopify/polaris";
import { SearchIcon } from "@shopify/polaris-icons";
import PropTypes from "prop-types";

const OpportunityOriginatorForm = (props) => {
  const { opportunityId, onUpdateOriginator, selectedOriginator } = props;

  const [
    updateOpportunityOriginator,
    { isLoading: isUpdatingOpportunityOriginator },
  ] = useUpdateOpportunityOriginatorMutation();

  const [selectedOriginatorOptions, setSelectedOriginatorOptions] = useState(
    []
  );
  const [originatorOptions, setOriginatorOptions] = useState([]);
  const [originatorSearchValue, setOriginatorSearchValue] = useState("");
  const [originatorSearchQuery] = useDebounce(originatorSearchValue, 500);
  const [skipOriginatorQuery, setSkipOriginatorQuery] = useState(false);
  const [originatorHasSearchFocus, setOriginatorHasSearchFocus] =
    useState(false);

  const {
    data: searchOriginators = [],
    isLoading: isLoadingSearchOriginators,
    isFetching: isFetchingSearchOriginators,
  } = useSearchOpportunityOriginatorsQuery(
    { search: originatorSearchQuery, opportunityId: opportunityId },
    {
      skip: skipOriginatorQuery,
    }
  );

  const [queryValue, setQueryValue] = useState(undefined);
  const [searchValue] = useDebounce(queryValue, 300);

  const handleOriginatorQueryChange = (value) => {
    setQueryValue(value);
    setOriginatorHasSearchFocus(true);
    setOriginatorSearchValue(value);
  };

  useEffect(() => {
    if (searchValue) {
      setSkipOriginatorQuery(false);
      setSelectedOriginatorOptions([]);
    }
  }, [searchValue]);

  const onSelectOriginator = (value) => {
    setSelectedOriginatorOptions(value);
    setOriginatorHasSearchFocus(false);

    const membershipId = value[0].id;
    const form = { originator: { id: membershipId } };

    return updateOpportunityOriginator({
      opportunityId: opportunityId,
      ...form,
    })
      .unwrap()
      .then(() => {
        onUpdateOriginator();
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRemoveOriginator = () => {
    setSelectedOriginatorOptions([]);
  };

  const handleCancelSelectOriginator = () => {
    setSelectedOriginatorOptions([...selectedOriginator]);
  };

  useEffect(() => {
    if (searchOriginators.length) {
      let originatorList = [];
      for (let i = 0; i < searchOriginators.length; i++) {
        originatorList.push({
          value: searchOriginators[i],
          label: `${searchOriginators[i].attributes.contact_full_name} - ${searchOriginators[i].attributes.contact_email} - ${searchOriginators[i].attributes.team_name}`,
        });
      }
      setOriginatorOptions(originatorList);
    }
  }, [searchOriginators]);

  useEffect(() => {
    if (selectedOriginator) {
      setSelectedOriginatorOptions([...selectedOriginator]);
    }
  }, [selectedOriginator]);

  const originatorTextField =
    selectedOriginatorOptions.length > 0 ? (
      <LegacyStack spacing="extraTight" alignment="center">
        {selectedOriginatorOptions.map((option) => {
          return (
            <Tag
              key={`option-${option.id}`}
              onRemove={handleRemoveOriginator}
            >
              <p>
                {option.attributes.full_name} - {option.attributes.team_name}
              </p>
            </Tag>
          );
        })}
      </LegacyStack>
    ) : (
      <Autocomplete.TextField
        onChange={handleOriginatorQueryChange}
        value={originatorSearchValue}
        prefix={<Icon source={SearchIcon} tone="base" />}
        placeholder="Search contacts..."
        focused={originatorHasSearchFocus}
        connectedRight={
          <Button onClick={handleCancelSelectOriginator}>Cancel</Button>
        }
      />
    );

  const emptyState = (
    <>
      <Icon source={SearchIcon} />
      <div style={{ textAlign: "center" }}>
        <BlockStack>Could not find any results</BlockStack>
      </div>
    </>
  );

  return (
    <Autocomplete
      id={"originator-autocomplete"}
      options={originatorOptions}
      selected={selectedOriginatorOptions}
      onSelect={onSelectOriginator}
      textField={originatorTextField}
      loading={isLoadingSearchOriginators || isFetchingSearchOriginators}
      listTitle="Contacts"
      preferredPosition="bottom"
      emptyState={emptyState}
    />
  );
};

OpportunityOriginatorForm.propTypes = {
  opportunityId: PropTypes.string,
  onUpdateOriginator: PropTypes.func,
  selectedOriginator: PropTypes.array,
};

export default OpportunityOriginatorForm;
