import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Text,
  Button,
  Tooltip,
  Divider,
  TextField,
  BlockStack,
  InlineStack,
  FormLayout,
  RangeSlider,
} from "@shopify/polaris";

import { PlusIcon, InfoIcon } from "@shopify/polaris-icons";

type BaseSegmentFieldsProps = {
  fields: any;
  period?: string;
  solvingMode?: string;
  setBaseTermLength: (value: number) => void;
  totalFinanceAmount: number;
};

const BaseSegmentFields = (props: BaseSegmentFieldsProps) => {
  const {
    fields,
    period = "monthly",
    solvingMode,
    setBaseTermLength,
    totalFinanceAmount,
  } = props;

  const title = "Base segment";

  const helpLink = (
    <Button
      variant="plain"
      icon={InfoIcon}
      accessibilityLabel="Learn more"
      external
      url={"https://help.fortifypay.com/"}
    />
  );

  const settingTitle = title ? (
    <InlineStack gap="200" wrap={false} align="start" blockAlign="center">
      <Text variant="headingSm" as="h6">
        {title}
      </Text>

      {helpLink}
    </InlineStack>
  ) : null;

  const headerMarkup = <Box width="100%">{settingTitle}</Box>;

  const periodAsLabel = useCallback(() => {
    switch (period) {
      case "monthly":
        return "Months";
      case "quarterly":
        return "Quarters";
      case "anually":
        return "Years";
      default:
        return "Periods";
    }
  }, [period]);

  const handleChangeBaseTermLength = useCallback(
    (value: number) => {
      fields.base_term_length.onChange(value);
      setBaseTermLength(value);
    },
    [fields.base_term_length, setBaseTermLength]
  );

  const handleChangeBaseTermLengthTextField = useCallback(
    (value: string) => {
      fields.base_term_length.onChange(value);
      setBaseTermLength(Number(value));
    },
    [fields.base_term_length, setBaseTermLength]
  );

  const [initialBasePayment, setInitialBasePayment] = useState(1000);
  useEffect(() => {
    if (solvingMode === "rate") {
      setInitialBasePayment(fields.base_payment.value);
    }
  }, [solvingMode]);

  return (
    <BlockStack gap="400">
      {headerMarkup}

      <FormLayout>
        <RangeSlider
          label={periodAsLabel()}
          min={1}
          max={84}
          step={1}
          value={fields.base_term_length.value}
          onChange={handleChangeBaseTermLength}
          error={fields.base_term_length.error}
          prefix={
            <div className="FortCalculator__SellRateTextField">
              <TextField
                label=""
                type="number"
                autoComplete="off"
                onChange={handleChangeBaseTermLengthTextField}
                onBlur={fields.base_term_length.onBlur}
                value={fields.base_term_length.value}
              />
            </div>
          }
        />

        <RangeSlider
          label="Payment"
          min={0}
          max={
            initialBasePayment * 4 > totalFinanceAmount / 4
              ? initialBasePayment * 4
              : totalFinanceAmount / 4
          }
          step={10}
          disabled={solvingMode === "payment"}
          {...fields.base_payment}
          prefix={
            <div className="FortCalculator__PaymentTextField">
              <TextField
                label=""
                type="number"
                autoComplete="off"
                prefix="$"
                onChange={fields.base_payment.onChange}
                onBlur={fields.base_payment.onBlur}
                value={fields.base_payment.value}
                disabled={solvingMode === "payment"}
              />
            </div>
          }
        />

        {/* <TextField
          label="Payment"
          prefix="$"
          disabled={solvingMode === "payment"}
          {...fields.base_payment}
        /> */}
      </FormLayout>
    </BlockStack>
  );
};

export default BaseSegmentFields;
