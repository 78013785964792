import {
  IndexTable,
  IndexFilters,
  useSetIndexFiltersMode,
  Text,
  ChoiceList,
  Card,
  BlockStack,
  InlineGrid,
  Link,
  InlineStack,
  Pagination,
  Tag,
  Modal,
  Avatar,
  Badge,
  Icon,
  ProgressBar,
  Box,
  Tooltip,
} from "@shopify/polaris";
import React, { useState, useCallback, useEffect } from "react";

import { useDebounce } from "use-debounce";

import { NotificationFilledIcon } from "@shopify/polaris-icons";

import {
  useGetOpportunityStatsQuery,
  useGetOpportunitiesQuery,
  useUpdateTagOpportunitiesMutation,
  useDeleteOpportunitiesMutation,
  useGetSavedViewsQuery,
  useCreateSavedViewMutation,
  useUpdateSavedViewMutation,
  useDeleteSavedViewMutation,
  useSearchContactsQuery,
  useGetTagsQuery,
  useGetTeamsQuery,
  useGetVendorsQuery,
} from "../../services/api";
import {
  formatCurrency,
  formatNumericDate,
  dateOptions,
  taskLabels,
  taskNameToLabel,
  taskLabelToName,
  taskNameToChoices,
} from "../../utilities";

import {
  filters,
  attributes,
  initialValues,
  allowedTeamAdminAttributes,
} from "../../services/filters/opportunity";
import ViewFilter from "./ViewFilter";
import HomeTableSkeletonCards from "./HomeTableSkeletonCards";
import { isMobile } from "react-device-detect";
import TaskList from "./TaskList";
import TaskListProgressBar from "./TaskListProgressBar";

const HomeTableWithIndexFilter = (props) => {
  const {
    isCurrentContactOrgAdmin,
    isVendorAdmin,
    onOpportunityClick,
    setExportSearchValue,
    setExportSortValue,
    setExportTabFilter,
    setExportSavedView,
  } = props;

  const headings = [
    { title: "Opportunity" },
    { title: "Last modified" },
    { title: "Status" },
    { title: "Originator" },
    { title: "Amount", alignment: "end" },
    // { title: "Tasks" },
    { title: "Tag" },
  ];

  const { data: tags = [], isLoading: fetchingTags } = useGetTagsQuery();

  const limit = 25;
  const [page, setPage] = useState(1);
  const [queryValue, setQueryValue] = useState(undefined);
  const [searchValue] = useDebounce(queryValue, 500);
  const [sortValue, setSortValue] = useState("UPDATED_AT_DESC");
  const [sortDirection, setSortDirection] = useState("descending");
  const [tabFilter, setTabFilter] = useState(null);
  const [selected, setSelected] = useState(0);
  const [selectedTag, setSelectedTag] = useState(
    fetchingTags ? [] : tags[0]?.name
  );
  const [savedViewSearch, setSavedViewSearch] = useState(null);
  const [choiceListTags, setChoiceListTags] = useState([]);
  const [taskName, setTaskName] = useState(undefined);
  const [taskStatus, setTaskStatus] = useState(undefined);

  const [loadedLocalStorageView, setLoadedLocalStorageView] = useState(false);

  const handleSelectedTagChange = useCallback((tagVal) => {
    setSelectedTag(tagVal);
  }, []);

  const {
    data: opportunityData = { count: 0 },
    isLoading: queryIsLoading,
    isFetching: queryIsFetching,
    refetch: refetchOpportunities,
  } = useGetOpportunitiesQuery(
    {
      page: page,
      limit: limit,
      filters: tabFilter,
      task_name: taskName,
      status: taskStatus,
      sort: sortValue,
      search: searchValue,
      savedView: savedViewSearch,
    },
    { skip: !loadedLocalStorageView }
  );

  const {
    data: opportunityStatsData,
    isLoading: isLoadingStats,
    isFetching: isFetchingStats,
  } = useGetOpportunityStatsQuery(
    {
      filters: tabFilter,
      task_name: taskName,
      status: taskStatus,
      search: searchValue,
      savedView: savedViewSearch,
    },
    { skip: !loadedLocalStorageView }
  );

  useEffect(() => {
    if (tags) {
      setChoiceListTags(
        tags.map((tag) => {
          return { label: tag.attributes.name, value: tag.id };
        })
      );
    }
  }, [tags]);

  const [deleteOpportunities, { isLoading: isDeleting }] =
    useDeleteOpportunitiesMutation();

  const [updateTagOpportunities, { isLoading: isTagging }] =
    useUpdateTagOpportunitiesMutation();

  const {
    data: savedViews,
    isLoading: isLoadingSavedViews,
    refetch: refetchSavedViews,
  } = useGetSavedViewsQuery();

  const [createSavedView, { isLoading: isCreatingSavedView }] =
    useCreateSavedViewMutation();

  const [updateSavedView, { isLoading: isUpdatingSavedView }] =
    useUpdateSavedViewMutation();

  const [deleteSavedView, { isLoading: isDeletingSavedView }] =
    useDeleteSavedViewMutation();

  const [originatorSearchValue, setOriginatorSearchValue] = useState("");
  const [originatorSearchQuery] = useDebounce(originatorSearchValue, 500);

  const { data: searchContacts = [], isLoading: isLoadingSearchContacts } =
    useSearchContactsQuery(
      { search: originatorSearchQuery },
      { skip: !originatorSearchQuery || originatorSearchQuery?.length === 0 }
    );

  const { data: searchTeams = [], isLoading: isLoadingSearchTeams } =
    useGetTeamsQuery({ is_home_index: true });

  const [opportunities, setOpportunities] = useState([]);
  const [isOpportunitiesLoading, setIsOpportunitiesLoading] = useState(true);
  const [selectedResources, setSelectedResources] = useState([]);
  const [allSelected, setAllSeleted] = useState(false);

  const [selectedTaskStatus, setSelectedTaskStatus] = useState(undefined);

  const handleSelectionChange = (selectionType, toggleType, selection) => {
    let selectionList = [...selectedResources];

    if (selectionType === "single") {
      // Check if in selection
      let inSelectionIndex = -1;
      for (let i = 0; i < selectedResources.length; i++) {
        if (selectedResources[i] === selection) {
          inSelectionIndex = i;
          break;
        }
      }

      if (inSelectionIndex != -1) {
        // Remove if in list
        selectionList.splice(inSelectionIndex, 1);
      } else {
        // Add if not
        selectionList.push(selection);
      }

      setSelectedResources(selectionList);
      setAllSeleted(false);
    } else {
      if (allSelected || selectedResources.length === opportunities.length) {
        // Clear selection
        setSelectedResources([]);
        setAllSeleted(false);
      } else {
        // Select all
        let list = [];
        for (let i = 0; i < limit; i++) {
          list.push(i);
        }
        setSelectedResources(list);
        setAllSeleted(true);
      }
    }
  };

  const resourceName = {
    singular: "opportunity",
    plural: "opportunities",
  };

  useEffect(() => {
    if (opportunityData?.opportunities?.data) {
      
      // Set up all tags
      let allTags = {}
      if (opportunityData?.opportunities?.included) {
        for(let i = 0; i < opportunityData?.opportunities?.included.length; i++) {
          let currentTag = opportunityData.opportunities.included[i]
          allTags[currentTag.id] = { name: currentTag.attributes.name, count: currentTag.attributes.taggings_count }
        }
      }
      
      // Set page and opportunities
      let lowerLimit = (page - 1) * limit;
      let upperLimit = page * limit;
      let list = [];

      let ocount = 0;
      for (let lcount = lowerLimit; lcount < upperLimit; lcount++) {
        if (opportunityData?.opportunities?.data[ocount]) {
          let o = { ...opportunityData.opportunities.data[ocount] }

          let oTags = []
          for(let i = 0; i < o.relationships?.tags?.data.length; i++) {
            let tagId = o.relationships.tags.data[i].id
            oTags.push(allTags[tagId])
          }

          let vTags = []
          for(let i = 0; i < o.relationships?.vendor_tags?.data.length; i++) {
            let tagId = o.relationships.vendor_tags.data[i].id
            vTags.push(allTags[tagId])
          }

          o.tags = oTags
          o.vendor_tags = vTags

          list[lcount] = o;
        }
        ocount += 1;
      }

      // Get and set Tags
      let all_tags = tags;

      let tagOptions = [];
      for (let i = 0; i < all_tags.length; i++) {
        tagOptions.push({
          key: all_tags[i].id,
          label: all_tags[i].attributes.name,
          value: all_tags[i].attributes.name,
        });
      }

      let creditReviewOptions = [
        {
          key: "1_credit_review_option",
          label: "Requested",
          value: "Requested",
        },
        {
          key: "2_credit_review_option",
          label: "Not Requested",
          value: "Not Requested",
        },
      ];

      let filterOptions = JSON.parse(JSON.stringify(searchFilterOptions));
      filterOptions["tags"] = tagOptions;
      filterOptions["credit_review"] = creditReviewOptions;

      let statusOptions = [];
      [
        "1,Open,open",
        "2,Closed/Lost,closed_lost",
        "3,Closed/Won,closed_won",
        "4,TBD,tbd",
      ].forEach((option) => {
        const op = option.split(",");
        statusOptions.push({
          key: `${op[0]}_${op[2]}`,
          label: op[1],
          value: op[2],
        });
      });
      filterOptions["status"] = statusOptions;

      if (opportunityData?.financing_type_list) {
        let financingTypes = opportunityData.financing_type_list;
        let financingTypeOptions = [];
        for (let i = 0; i < financingTypes.length; i++) {
          financingTypeOptions.push({
            key: `${i}_${financingTypes[i]}`,
            label: financingTypes[i],
            value: financingTypes[i],
          });
        }
        filterOptions["financing_type"] = financingTypeOptions;
      }

      setSearchFilterOptions(filterOptions);

      setOpportunities(list);
      setIsOpportunitiesLoading(false);
    } else if (
      opportunityStatsData?.count == 0 &&
      opportunityStatsData?.volume == 0 &&
      opportunityStatsData?.average == 0
    ) {
      setOpportunities([]);
      setIsOpportunitiesLoading(false);
    }
  }, [
    opportunityData,
    opportunityStatsData?.average,
    opportunityStatsData?.count,
    opportunityStatsData?.volume,
    page,
    searchFilterOptions,
    selected,
    tags,
  ]);

  useEffect(() => {
    setPage(1);
  }, [searchValue]);

  useEffect(() => {
    if (savedViews) {
      let viewNames = [];

      for (let i = 0; i < savedViews.length; i++) {
        viewNames.push(savedViews[i].attributes.name);
      }

      // setItemStrings(["All", ...taskLabels, ...viewNames]);
      setItemStrings(["Open", ...viewNames]);
    }
  }, [savedViews]);

  const handleTableSorting = (headingIndex, direction) => {
    let currentSortDirection = "descending";
    if (sortDirection === "ascending") {
      currentSortDirection = "descending";
      setSortDirection("descending");
    } else {
      currentSortDirection = "ascending";
      setSortDirection("ascending");
    }

    let sortTag = "";
    switch (headingIndex) {
      case 1:
        sortTag = "CREATED_AT_";
        break;
      case 2:
        sortTag = "ORIGINATOR_";
        break;
      case 3:
        sortTag = "AMOUNT_";
        break;
    }

    if (currentSortDirection === "ascending") {
      sortTag = `${sortTag}ASC`;
    } else {
      sortTag = `${sortTag}DESC`;
    }

    setSortValue(sortTag);
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // const [itemStrings, setItemStrings] = useState(["All", ...taskLabels]);
  const [itemStrings, setItemStrings] = useState(["All"]);

  const handleDeleteSavedView = (index) => {
    // Find existing saved view
    // let savedViewIndex = index - (taskLabels.length + 1);
    let savedViewIndex = index - 1;
    let savedViewId = savedViews[savedViewIndex].id;

    return deleteSavedView({
      id: savedViewId,
    })
      .unwrap()
      .then(() => {
        const newItemStrings = [...itemStrings];
        newItemStrings.splice(index, 1);
        setItemStrings(newItemStrings);
        setSelected(0);

        // delete the saved view from local storage
        localStorage.removeItem("fortify-app-selected-view-name");
        localStorage.removeItem("fortify-app-selected-view-name-value");

        return true;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteView = (index) => {
    handleDeleteSavedView(index);
  };

  const [selectedView, setSelectedView] = useState(0);

  const handleSelectNewView = (viewIndex) => {
    let selectedViewName = itemStrings[viewIndex];

    if (viewIndex == 0) {
      setTaskName(undefined);
      setSelectedTaskStatus([]);
      setTaskStatus(undefined);
      // reset sorting for "Open" default view
      setSortValue("UPDATED_AT_DESC");
      setSortSelected(["updated_at desc"]);
    }

    setTabFilter(null);
    setSavedViewSearch(selectedViewName);

    // Set page to one
    setPage(1);
    setSelectedView(viewIndex);

    // Save view name to local storage
    localStorage.setItem("fortify-app-selected-view-name", selectedViewName);

    // Save view value to local storage
    localStorage.setItem("fortify-app-selected-view-name-value", "Complete");
  };

  useEffect(() => {
    const savedSelectedView = localStorage.getItem(
      "fortify-app-selected-view-name"
    );

    if (savedViews) {
      if (savedSelectedView) {
        if (savedSelectedView === "All") {
          setLoadedLocalStorageView(true);
          return;
        }

        setTabFilter(null);
        setSavedViewSearch(savedSelectedView);

        let savedViewIndex = savedViews.findIndex(
          (view) => view.attributes.name === savedSelectedView
        );

        if (savedViewIndex !== -1) {
          setSelectedView(savedViewIndex + 1);
        }

        // Set page to one
        setPage(1);
      }

      setLoadedLocalStorageView(true);
    }
  }, [savedViews, loadedLocalStorageView]); // Add loadedLocalStorageView as a dependency

  const handleCreateNewSavedView = (viewName, index = 0) => {
    let filterString = "{}";

    // Find existing saved view
    // if (index >= taskLabels.length + 1) {
    //   let savedViewIndex = index - (taskLabels.length + 1);
    //   let currentSavedView = savedViews[savedViewIndex];

    //   filterString = currentSavedView.attributes.filters;
    // }

    return createSavedView({
      saved_view: {
        name: viewName,
        type: "OpportunitySavedView",
        filters: filterString,
        sort: sortValue,
      },
    })
      .unwrap()
      .then(() => {
        // Set view search to name
        setSavedViewSearch(viewName);

        // Set to new saved view - Should be last in itemStrings
        setSelectedView(itemStrings.length);

        // Old Code - Search in itemStrings
        // for (let i = 0; i < itemStrings.length; i++) {
        //   if (itemStrings[i] === viewName) {
        //     setSelectedView(i);
        //     break;
        //   }
        // }

        // Refetch saved view
        refetchSavedViews();

        return true;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const duplicateView = async (name, index) => {
    handleCreateNewSavedView(name, index);
  };

  const onCreateNewView = (value) => {
    handleCreateNewSavedView(value);
  };

  const handleUpdateSavedView = (index, rename, newFilters) => {
    // Find existing saved view
    // let savedViewIndex = index - (taskLabels.length + 1);
    let savedViewIndex = index - 1;
    let savedViewId = savedViews[savedViewIndex].id;

    // Actual Update
    let saved_view = {};

    if (rename) {
      saved_view.name = rename;
    }
    if (newFilters) {
      saved_view.filters = newFilters;
    }

    if (sortValue) {
      saved_view.sort = sortValue;
    }

    return updateSavedView({
      id: savedViewId,
      saved_view: saved_view,
    })
      .unwrap()
      .then(() => {
        if (rename) {
          const newItemsStrings = tabs.map((item, idx) => {
            if (idx === index) {
              return rename;
            }
            return item.content;
          });

          setItemStrings(newItemsStrings);

          // Refetch Opportunities if filters were changed
          if (newFilters) refetchOpportunities();

          return true;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const tabs = itemStrings.map((item, index) => ({
    content: item,
    index,
    onAction: () => {},
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions:
      index <= 0
        ? []
        : [
            {
              type: "rename",
              onAction: () => {},
              onPrimaryAction: (value) => {
                handleUpdateSavedView(index, value, undefined);
              },
            },
            {
              type: "duplicate",
              onPrimaryAction: async (value) => {
                await sleep(1);

                duplicateView(value, index);
                return true;
              },
            },
            {
              type: "delete",
              onPrimaryAction: async () => {
                deleteView(index);
              },
            },
          ],
  }));

  const sortOptions = [
    { label: "Opportunity", value: "name asc", directionLabel: "A-Z" },
    { label: "Opportunity", value: "name desc", directionLabel: "Z-A" },

    // {
    //   label: "Completed tasks",
    //   value: "completed_tasks_count asc",
    //   directionLabel: "Ascending",
    // },
    // {
    //   label: "Completed tasks",
    //   value: "completed_tasks_count desc",
    //   directionLabel: "Descending",
    // },

    {
      label: "Last modified",
      value: "updated_at asc",
      directionLabel: "Ascending",
    },
    {
      label: "Last modified",
      value: "updated_at desc",
      directionLabel: "Descending",
    },

    {
      label: "Amount",
      value: "amount asc",
      directionLabel: "Ascending",
    },
    {
      label: "Amount",
      value: "amount desc",
      directionLabel: "Descending",
    },

    { label: "Status", value: "stagename asc", directionLabel: "A-Z" },
    { label: "Status", value: "stagename desc", directionLabel: "Z-A" },
  ];

  const [sortSelected, setSortSelected] = useState(["updated_at desc"]);

  const handleSortChange = (value) => {
    setSortSelected(value);

    const savedViewIndex = selectedView - 1;
    let currentSortValue = value[0];
    let sortString = currentSortValue.toUpperCase();
    let savedViewId = savedViews[savedViewIndex].id;
    sortString = sortString.split(" ");
    sortString = sortString[0] + "_" + sortString[1];

    setSortValue(sortString);

    if (savedViewId) {
      updateSavedView({
        id: savedViewId,
        saved_view: { sort: sortString },
      })
        .unwrap()
        .then()
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const { mode, setMode } = useSetIndexFiltersMode();
  const onHandleCancel = () => {};

  const onHandleSave = async () => {
    await sleep(1);
    return true;
  };

  const primaryAction =
    selectedView === 0
      ? {
          type: "save-as",
          onAction: onCreateNewView,
          disabled: false,
          loading: false,
        }
      : {
          type: "save",
          onAction: onHandleSave,
          disabled: false,
          loading: false,
        };

  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    []
  );
  const handleFilterQueryClear = useCallback(
    () => setQueryValue(undefined),
    []
  );

  const handleFiltersClearAll = useCallback(() => {
    // Set page to 1
    setPage(1);

    // Ignore clear - Should not be able to remove filter
    // if (selectedView < taskLabels.length + 1) return;

    setViewFilterValues(initialValues);
    setAppliedFilters([]);

    // let savedViewIndex = selectedView - (taskLabels.length + 1);
    let savedViewIndex = selectedView - 1;
    let savedViewId = savedViews[savedViewIndex].id;

    return updateSavedView({
      id: savedViewId,
      saved_view: { filters: "{}", sort: null },
    })
      .unwrap()
      .then(() => {
        refetchOpportunities();
      })
      .catch((error) => {
        console.log(error);
      });
  }, [selectedView]);

  const [viewFilters, setViewFilters] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [viewFilterValues, setViewFilterValues] = useState(initialValues);
  const [viewFilterSelectedValues, setViewFilterSelectedValues] = useState({});

  const [vendorNameSearch, setVendorNameSearch] = useState(
    initialValues["vendor_name"]
  );
  const [vendorNameSearchDebounce] = useDebounce(vendorNameSearch, 500);

  useEffect(() => {
    setVendorNameSearch(viewFilterValues["vendor_name"]);
  }, [viewFilterValues]);

  const { data: searchVendors = [], isLoading: isLoadingSearchVendors } =
    useGetVendorsQuery({
      search: vendorNameSearchDebounce,
      is_home_index: true,
    });

  useEffect(() => {
    // Properly set search vendors

    if (searchVendors.length > 0) {
      // Reset other search
      setOriginatorSearchValue(null);

      let vendorOptions = [];
      for (let i = 0; i < searchVendors.length; i++) {
        vendorOptions.push({
          key: searchVendors[i].id,
          label: searchVendors[i].attributes.name,
          value: searchVendors[i].attributes.name,
        });
      }

      let filterOptions = JSON.parse(JSON.stringify(searchFilterOptions));
      filterOptions["vendor_name"] = vendorOptions;

      setSearchFilterOptions(filterOptions);
    }
  }, [searchVendors]);

  // Task Filters

  const onChangeSetViewFilters = useCallback(
    (queryValue) => {
      setSelectedTaskStatus(queryValue);
      setTaskStatus(queryValue[0]);

      setAutomaticStatusFilterForTaskViews(queryValue, taskName);

      // Save change to local storage
      localStorage.setItem(
        "fortify-app-selected-view-name-value",
        queryValue[0]
      );
    },
    [taskName]
  );

  const setAutomaticStatusFilterForTaskViews = useCallback(
    (queryTaskStatus = [], task) => {
      let filterKey = "taskName";
      let taskNameKey = taskName || task;

      // Push status filter filter
      setViewFilters([
        {
          key: filterKey,
          label: "Status",
          filter: (
            <ChoiceList
              title="Status"
              choices={taskNameToChoices[taskNameKey]}
              selected={queryTaskStatus || []}
              onChange={onChangeSetViewFilters}
            />
          ),
        },
      ]);

      if (queryTaskStatus[0]) {
        let newAppliedFilters = [];
        newAppliedFilters.push({
          key: filterKey,
          label: `Status`,
          onRemove: () => {}, //Purposefully blank - Should NOT be removed
        });

        setAppliedFilters(newAppliedFilters);
      }
    },
    [selectedTaskStatus, taskName]
  );

  // Search Filter
  const [searchFilterOptions, setSearchFilterOptions] = useState({
    originator_name: [],
    team_name: [],
  });
  const [viewFilterHasSearchFocus, setViewFilterHasSearchFocus] =
    useState(false);

  const setFilterListForSavedView = () => {
    let filterList = [];
    for (let i = 0; i < attributes.length; i++) {
      let attribute = attributes[i];
      let filter = filters[attribute];

      // Filter by user authorization (Org Admin, Team Admin, etc)
      // If tags and not org admin or vendor admin, skip
      if (!(isCurrentContactOrgAdmin || isVendorAdmin) && attribute === "tags") continue;
      // If team admin or member (not vendor admin or org admin), limit filters
      if (
        !isCurrentContactOrgAdmin &&
        !isVendorAdmin &&
        allowedTeamAdminAttributes.indexOf(attribute) < 0
      )
        continue;

      let originatorHandle = (value) => {
        filters[attribute].handleSearchChange(
          value,
          handleSearchFilterValueChangeForOriginator
        );
      };

      let teamHandle = (value) => {
        filters[attribute].handleSearchChange(
          value,
          handleSearchFilterValueChangeForTeams
        );
      };

      let vendorHandle = (value) => {
        filters[attribute].handleSearchChange(
          value,
          handleSearchFilterValueChangeForVendors
        );
      };

      let searchValueHandler = (value) => {
        switch (attribute) {
          case "originator_name":
            originatorHandle(value);
            break;
          case "team_name":
            teamHandle(value);
            break;
          case "vendor_name":
            vendorHandle(value);
            break;
          default:
            break;
        }
      };

      filterList.push({
        key: attribute,
        label: filters[attribute]?.label,
        filter: (
          <ViewFilter
            filterKey={attribute}
            filterObject={filter}
            initialValues={initialValues}
            filterValues={viewFilterValues}
            setFilterValues={setViewFilterValues}
            savedViews={savedViews}
            selectedView={selectedView}
            handleUpdateSavedView={handleUpdateSavedView}
            isUpdatingSavedView={isUpdatingSavedView}
            appliedFilters={appliedFilters}
            setAppliedFilters={setAppliedFilters}
            viewFilterSelectedValues={viewFilterSelectedValues}
            setViewFilterSelectedValues={setViewFilterSelectedValues}
            viewFilterHasSearchFocus={viewFilterHasSearchFocus}
            handleSearchValueChange={(value) => {
              searchValueHandler(value);
            }}
            searchFilterOptions={searchFilterOptions}
            handleRemoveViewFilter={handleRemoveViewFilter}
            isLoadingDynamic={
              isLoadingSearchContacts ||
              isLoadingSearchTeams ||
              isLoadingSearchVendors ||
              queryIsFetching ||
              queryIsLoading
            }
          />
        ),
        shortcut: true,
      });
    }

    setViewFilters(filterList);
  };

  useEffect(() => {
    if (selectedView == 0) {
      setViewFilters([]);
      // } else if (selectedView < taskLabels.length + 1) {
      //   setAutomaticStatusFilterForTaskViews(selectedTaskStatus);
    } else {
      setFilterListForSavedView();
    }
  }, [
    viewFilterValues,
    selectedView,
    searchFilterOptions,
    viewFilterSelectedValues,
    savedViews,
  ]);

  useEffect(() => {
    if (searchContacts.length > 0) {
      let contactOptions = [];
      for (let i = 0; i < searchContacts.length; i++) {
        let contactLabel = "";

        var regExp = /[a-zA-Z]/g;
        if (regExp.test(searchContacts[i].attributes.full_name))
          contactLabel = searchContacts[i].attributes.full_name + " - ";
        contactLabel = contactLabel + searchContacts[i].attributes.email;

        contactOptions.push({
          key: searchContacts[i].id,
          // For some reason, adding the media causes the list to error
          // media: (
          //   <Avatar
          //     size="xs"
          //     initials={searchContacts[i].attributes.initials}
          //   />
          // ),
          label: contactLabel,
          value: searchContacts[i].attributes.email,
        });
      }

      let filterOptions = JSON.parse(JSON.stringify(searchFilterOptions));
      filterOptions["originator_name"] = contactOptions;

      setSearchFilterOptions(filterOptions);
    }
  }, [searchContacts]);

  const handleRemoveViewFilter = useCallback(
    (selectedView, filterKey) => {
      // Set page to one
      setPage(1);

      // Remove value
      // let currentViewIndex = selectedView - (taskLabels.length + 1);
      let currentViewIndex = selectedView - 1;
      let currentViewFilters = JSON.parse(
        savedViews[currentViewIndex].attributes.filters
      );
      delete currentViewFilters[filterKey];

      let newAppliedFilters = [];
      if (appliedFilters.length > 0) {
        for (let i = 0; i < appliedFilters.length; i++) {
          if (appliedFilters[i].key !== filterKey) {
            newAppliedFilters.push(appliedFilters[i]);
          }
        }

        setAppliedFilters(newAppliedFilters);
      } else {
        for (let i = 0; i < Object.keys(currentViewFilters).length; i++) {
          let filterKey = Object.keys(currentViewFilters)[i];

          // Skip stage name - To remove soon
          // if (filterKey === "stagename") {
          //   i = i + 1;
          //   if (i < Object.keys(currentViewFilters).length) {
          //     filterKey = Object.keys(currentViewFilters)[i];
          //   } else {
          //     return;
          //   }
          // }

          let filterLabel = filters[filterKey].label;
          if (
            (currentViewFilters[filterKey].value != [] ||
              currentViewFilters[filterKey].value != "") &&
            currentViewFilters[filterKey].value != initialValues[filterKey]
          ) {
            filterLabel =
              filterLabel + ": " + currentViewFilters[filterKey].label;
          }

          newAppliedFilters.push({
            key: filterKey,
            label: filterLabel,
            onRemove: function () {
              handleRemoveViewFilter(selectedView, filterKey);
            },
          });
        }

        setAppliedFilters(newAppliedFilters);
      }

      let currentViewFilterString = JSON.stringify(currentViewFilters);

      // Update BE
      handleUpdateSavedView(selectedView, undefined, currentViewFilterString);

      // Reset to initial value
      currentViewFilters[filterKey] = initialValues[filterKey];

      setViewFilterValues(currentViewFilters);
    },
    [viewFilterValues, savedViews]
  );

  useEffect(() => {
    // Allows the filters and their chosen values to persist
    // if (selectedView > taskLabels.length) {
    if (selectedView > 0) {
      let appliedViewFilters = [];
      let appliedFilterValues = JSON.parse(JSON.stringify(initialValues));

      // let currentViewIndex = selectedView - (taskLabels.length + 1);
      let currentViewIndex = selectedView - 1;

      if (savedViews[currentViewIndex]) {
        if (savedViews[currentViewIndex]?.attributes?.sort) {
          const sortIndex = sortOptions.findIndex((option) => {
            const value = option.value.replace(/ /g, "_").toUpperCase();
            return savedViews[currentViewIndex]?.attributes?.sort.includes(
              value
            );
          });

          if (sortIndex >= 0) {
            setSortSelected([sortOptions[sortIndex].value]);
          }
        }

        let currentViewFilters = JSON.parse(
          savedViews[currentViewIndex].attributes.filters
        );

        for (let i = 0; i < Object.keys(currentViewFilters).length; i++) {
          let filterKey = Object.keys(currentViewFilters)[i];

          let filterLabel = filters[filterKey].label;
          // Handle stage name filter setting specifically (filter label first)
          if (filterKey === "stagename") {
            filterLabel = "Stage: " + currentViewFilters[filterKey].label;
          } else if (filterKey === "task") {
            filterLabel = "Requirements";
          } else if (
            (currentViewFilters[filterKey].value != [] ||
              currentViewFilters[filterKey].value != "") &&
            currentViewFilters[filterKey].value != initialValues[filterKey]
          ) {
            filterLabel =
              filterLabel + ": " + currentViewFilters[filterKey].label;
          }

          appliedViewFilters.push({
            key: filterKey,
            label: filterLabel,
            onRemove: function () {
              handleRemoveViewFilter(selectedView, filterKey);
            },
          });

          if (
            filterKey === "created_at" ||
            filterKey === "booked_at" ||
            filterKey === "funded_at" ||
            filterKey === "approved_at"
          ) {
            for (let i = 0; i < dateOptions.length; i++) {
              if (currentViewFilters[filterKey].label == dateOptions[i].label) {
                appliedFilterValues[filterKey] = dateOptions[i].value;
              }
            }
          } else if (filterKey === "task") {
            let taskBreakingString = currentViewFilters[
              filterKey
            ].query.replaceAll("_status = ?", "");
            let taskKeys = taskBreakingString.split(" AND ");
            let taskValues = currentViewFilters[filterKey].value;

            let taskValue = {};
            for (let i = 0; i < taskKeys.length; i++) {
              let key = taskKeys[i];
              taskValue[key] = [taskValues[i]];
            }

            appliedFilterValues[filterKey] = taskValue;
          } else {
            appliedFilterValues[filterKey] =
              currentViewFilters[filterKey].value;
          }

          // Then handle specific stagename value
          if (filterKey === "stagename") {
            if (filterLabel.indexOf("Lost") >= 0) {
              appliedFilterValues[filterKey] = "('closed_lost')";
            } else if (filterLabel.indexOf("Won") >= 0) {
              appliedFilterValues[filterKey] = "('closed_won')";
            } else {
              appliedFilterValues[filterKey] =
                "('lead', 'estimated_terms', 'credit_review', 'approved', 'booked', 'funding')";
            }
          }
        }

        setSortValue(savedViews[currentViewIndex]?.attributes?.sort);
        setAppliedFilters(appliedViewFilters);
        setViewFilterValues(appliedFilterValues);
      }
    }
  }, [selectedView, savedViews]);

  const handleSearchFilterValueChangeForOriginator = (value) => {
    setOriginatorSearchValue(value);
  };

  const handleSearchFilterValueChangeForTeams = () => {
    // Reset other search
    setOriginatorSearchValue(null);

    let teamOptions = [];
    for (let i = 0; i < searchTeams.length; i++) {
      teamOptions.push({
        key: searchTeams[i].id,
        label: searchTeams[i].attributes.name,
        value: searchTeams[i].attributes.name,
      });
    }

    let filterOptions = JSON.parse(JSON.stringify(searchFilterOptions));
    filterOptions["team_name"] = teamOptions;

    setSearchFilterOptions(filterOptions);
  };

  const handleSearchFilterValueChangeForVendors = () => {
    // Reset other search
    setOriginatorSearchValue(null);

    let vendorOptions = [];
    for (let i = 0; i < searchVendors.length; i++) {
      vendorOptions.push({
        key: searchVendors[i].id,
        label: searchVendors[i].attributes.name,
        value: searchVendors[i].attributes.name,
      });
    }

    let filterOptions = JSON.parse(JSON.stringify(searchFilterOptions));
    filterOptions["vendor_name"] = vendorOptions;

    setSearchFilterOptions(filterOptions);
  };

  useEffect(() => {
    if (!viewFilterHasSearchFocus) {
      setOriginatorSearchValue(null);
      setSearchFilterOptions({});
    }
  }, [viewFilterHasSearchFocus]);

  // Handle changes outside of table
  useEffect(() => {
    setExportSearchValue(searchValue);
    setExportSortValue(sortValue);
    setExportTabFilter(tabFilter);
    setExportSavedView(savedViewSearch);
  }, [searchValue, sortValue, tabFilter, savedViewSearch]);

  const handleChangePage = (mode) => {
    setIsOpportunitiesLoading(true);
    setPage((state) => {
      if (
        mode == "next" &&
        state == Math.ceil(opportunityStatsData?.count / limit)
      ) {
        return Math.ceil(opportunityStatsData?.count / limit);
      } else if (mode == "previous" && state == 1) {
        return 1;
      }

      if (mode == "next") {
        return state + 1;
      } else if (mode == "previous") {
        return state - 1;
      }
    });
  };

  const bulkDeleteOpportunities = () => {
    const opportunityIds = [];

    if (allSelected) {
      for (let i = 0; i < opportunityStatsData?.count; i++) {
        opportunityIds.push(opportunityData.opportunities.data[i].id);
      }
    } else {
      for (let i = 0; i < selectedResources.length; i++) {
        let selectedResourceIndex = selectedResources[i];
        if (opportunities[selectedResourceIndex]) {
          opportunityIds.push(opportunities[selectedResourceIndex].id);
        }
      }
    }

    return deleteOpportunities({
      opportunityIds: opportunityIds,
    })
      .unwrap()
      .then(() => {
        setAllSeleted(false);
        setSelectedResources([]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTagOpportunities = () => {
    let opportunityIds = [];

    if (allSelected) {
      opportunityIds = opportunityData.opportunities.data.map(
        (opportunity) => opportunity.id
      );
    } else {
      opportunityIds = selectedResources.map((resourceIndex) => {
        if (opportunities[resourceIndex]) {
          return opportunities[resourceIndex].id;
        }
      });
    }

    return updateTagOpportunities({
      opportunity_ids: opportunityIds.join(","),
      tag_ids: selectedTag.join(","),
    })
      .unwrap()
      .then(() => {
        setAllSeleted(false);
        setSelectedResources([]);
        setOpenSelectTagModal(false);
        handleSelectedTagChange([]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const untagOpportunities = () => {
    let opportunityIds = [];

    if (allSelected) {
      opportunityIds = opportunityData.opportunities.data.map(
        (opportunity) => opportunity.id
      );
    } else {
      opportunityIds = selectedResources.map((resourceIndex) => {
        if (opportunities[resourceIndex]) {
          return opportunities[resourceIndex].id;
        }
      });
    }

    return updateTagOpportunities({
      opportunity_ids: opportunityIds.join(","),
    })
      .unwrap()
      .then(() => {
        setAllSeleted(false);
        setSelectedResources([]);
        setOpenSelectTagModal(false);
        handleSelectedTagChange([]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRemoveTag = (opportunityId, tag) => {
    return updateTagOpportunities({
      opportunity_id: opportunityId,
      tag_name: tag.name,
      single_remove: true,
    })
      .unwrap()
      .then(() => {
        setAllSeleted(false);
        setSelectedResources([]);
        setOpenSelectTagModal(false);
        handleSelectedTagChange([]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [openSelectTagModal, setOpenSelectTagModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const handleOpenConfirmationModal = () => {
    setOpenConfirmationModal(true);
  };
  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };
  const handleConfirmDeleteModal = () => {
    bulkDeleteOpportunities();
    setOpenConfirmationModal(false);
  };

  const areYouSureModal = (
    <Modal
      open={openConfirmationModal}
      onClose={handleCloseConfirmationModal}
      title="Are you sure?"
      primaryAction={{
        content: "Confirm",
        onAction: handleConfirmDeleteModal,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleCloseConfirmationModal,
        },
      ]}
    >
      <Modal.Section>
        <Text variant="bodyMd" as="p">
          You are about to delete{" "}
          {allSelected ? opportunityStatsData?.count : selectedResources.length}{" "}
          opportunities. This action cannot be undone.
        </Text>
      </Modal.Section>
    </Modal>
  );

  const selectTagModal = (
    <Modal
      open={openSelectTagModal}
      onClose={() => setOpenSelectTagModal(false)}
      title="Add tag"
      loading={isTagging}
      primaryAction={{
        content: "Add tag",
        onAction: handleTagOpportunities,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: () => setOpenSelectTagModal(false),
        },
      ]}
    >
      <Modal.Section>
        {tags && (
          <ChoiceList
            allowMultiple
            title="Available tags"
            choices={choiceListTags || []}
            selected={selectedTag || []}
            onChange={handleSelectedTagChange}
          />
        )}
      </Modal.Section>
    </Modal>
  );

  const promotedBulkActions = [
    {
      content: "Add tag",
      onAction: () => setOpenSelectTagModal(true),
    },
    {
      content: "Remove all tags",
      onAction: untagOpportunities,
    },
    {
      content: "Delete opportunities",
      onAction: handleOpenConfirmationModal,
    },
  ];

  const vendorPromotedBulkActions = promotedBulkActions.filter(
    (action) => action.content !== "Delete opportunities"
  )

  const tablePagination = (
    <Pagination
      hasPrevious={page != 1}
      hasNext={page < Math.ceil(opportunityStatsData?.count / limit)}
      onPrevious={() => handleChangePage("previous")}
      onNext={() => handleChangePage("next")}
      label={
        <Text>
          Page {page} of {Math.ceil(opportunityStatsData?.count / limit)}
        </Text>
      }
    />
  );

  const [indexTableIsLoading, setIndexTableIsLoading] = useState(false);
  useEffect(() => {
    setIndexTableIsLoading(
      isLoadingSavedViews ||
        isOpportunitiesLoading ||
        queryIsLoading ||
        queryIsFetching ||
        isTagging ||
        isCreatingSavedView ||
        isDeletingSavedView ||
        isUpdatingSavedView
    );
  }, [
    isOpportunitiesLoading,
    queryIsLoading,
    queryIsFetching,
    isLoadingSavedViews,
    isTagging,
    isCreatingSavedView,
    isDeletingSavedView,
    isUpdatingSavedView,
  ]);

  const rowMarkup = opportunities.map((item, index) => {
    const isOpportunityTbd = item.attributes.stagename === "tbd";
    const isOpportunityClosedLost = item.attributes.stagename === "closed_lost";
    const isOpportunityClosedWon = item.attributes.stagename === "closed_won";
    const isOpportunityOpen =
      !isOpportunityClosedLost && !isOpportunityClosedWon && !isOpportunityTbd;

    return (
      <IndexTable.Row
        id={index}
        key={index}
        position={index}
        selected={selectedResources.includes(index) /*|| allSelected*/}
      >
        <IndexTable.Cell>
          <Link
            removeUnderline
            monochrome
            onClick={() => onOpportunityClick(item)}
          >
            <Box maxWidth={"24vw"}>
              <InlineStack gap="200" blockAlign="center">
                {/* Temporarily disabling this until we have a better handle on database notifications. */}
                {/* {opportunityData.has_notification_ids.includes(item.id) && (
                  <Icon source={NotificationFilledIcon} tone="info" />
                )} */}

                <Text
                  variant="strong"
                  fontWeight="bold"
                  as={"span"}
                  truncate={true}
                >
                  {item.attributes.name}
                </Text>
              </InlineStack>
            </Box>
          </Link>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {formatNumericDate(new Date(item.attributes.updated_at))}
        </IndexTable.Cell>

        <IndexTable.Cell>
          {isOpportunityTbd && <Badge tone="warning">TBD</Badge>}
          {isOpportunityClosedLost && <Badge tone="critical">Lost</Badge>}
          {isOpportunityClosedWon && <Badge tone="success">Won</Badge>}
          {isOpportunityOpen && <Badge tone="info">Open</Badge>}
        </IndexTable.Cell>

        <IndexTable.Cell>{item.attributes.originator_name}</IndexTable.Cell>

        <IndexTable.Cell>
          <Text alignment={"end"} numeric>
            {formatCurrency(item.attributes.total_amount)}
          </Text>
        </IndexTable.Cell>

        {/* <IndexTable.Cell>
          <Tooltip
            hasUnderline
            persistOnClick
            content={
              <Box padding={"200"}>
                <TaskList opportunityId={item.id} />
              </Box>
            }
          >
            <Box minWidth={"120px"} maxWidth={"180px"}>
              <TaskListProgressBar
                completedTasksCount={item.attributes.completed_tasks_count}
                totalTasksCount={item.attributes.total_tasks_count}
                opportunityId={item.id}
                hasPopover={false}
              />
            </Box>
          </Tooltip>
        </IndexTable.Cell> */}

        {(isCurrentContactOrgAdmin || isVendorAdmin) && (
          <IndexTable.Cell>
            <InlineStack wrap={false} gap="200">
              {isCurrentContactOrgAdmin && item.tags && item?.tags?.map((tag, index) => (
                <Tag key={index} onRemove={() => handleRemoveTag(item.id, tag)}>
                  {tag.name}
                </Tag>
              ))}

              {(isCurrentContactOrgAdmin || isVendorAdmin) && item.vendor_tags && item?.vendor_tags?.map((tag, index) => (
                <Tag key={index} onRemove={() => handleRemoveTag(item.id, tag)}>
                  {tag.name}
                </Tag>
              ))}
            </InlineStack>
          </IndexTable.Cell>
        )}
      </IndexTable.Row>
    );
  });

  return (
    <BlockStack gap="400">
      {isLoadingStats || isFetchingStats ? (
        <HomeTableSkeletonCards />
      ) : (
        <InlineGrid gap="400" columns={isMobile ? 1 : 3}>
          <Card>
            <Text as="p" fontWeight="bold" tone="subdued">
              Total opportunities
            </Text>

            <br />

            <Text variant="heading2xl" as="h2">
              {opportunityStatsData?.count}
            </Text>
          </Card>
          <Card>
            <Text as="p" fontWeight="bold" tone="subdued">
              Total volume
            </Text>

            <br />

            <Text variant="heading2xl" as="h2">
              {opportunityStatsData?.volume
                ? formatCurrency(opportunityStatsData?.volume)
                : "-"}
            </Text>
          </Card>
          <Card>
            <Text as="p" fontWeight="bold" tone="subdued">
              Average Opportunity amount
            </Text>

            <br />

            <Text variant="heading2xl" as="h2">
              {opportunityStatsData?.average
                ? formatCurrency(opportunityStatsData?.average)
                : "-"}
            </Text>
          </Card>
        </InlineGrid>
      )}

      <Card padding={"0"}>
        <IndexFilters
          sortOptions={sortOptions}
          sortSelected={sortSelected}
          queryValue={queryValue}
          queryPlaceholder="Searching in all"
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={handleFilterQueryClear}
          onSort={handleSortChange}
          primaryAction={primaryAction}
          cancelAction={{
            onAction: onHandleCancel,
            disabled: false,
            loading: false,
          }}
          tabs={tabs}
          selected={selectedView}
          onSelect={handleSelectNewView}
          canCreateNewView
          onCreateNewView={onCreateNewView}
          filters={viewFilters}
          appliedFilters={appliedFilters}
          onClearAll={handleFiltersClearAll}
          mode={mode}
          setMode={setMode}
          loading={indexTableIsLoading}
        />

        <IndexTable
          resourceName={resourceName}
          itemCount={opportunities?.length || 0}
          selectable={true}
          onSelectionChange={handleSelectionChange}
          selectedItemsCount={allSelected ? "All" : selectedResources.length}
          promotedBulkActions={
            !(isCurrentContactOrgAdmin || isVendorAdmin) ? [] :
              isVendorAdmin ? vendorPromotedBulkActions : promotedBulkActions
            }
          loading={indexTableIsLoading}
          headings={
            isCurrentContactOrgAdmin || isVendorAdmin
              ? headings
              : headings.filter((heading) => heading.title !== "Tag")
          }
          sortable={[false, true, false, true, true, false]}
          defaultSortDirection={"descending"}
          sortDirection={sortDirection}
          onSort={handleTableSorting}
        >
          {rowMarkup}
        </IndexTable>

        <InlineStack align={"center"}>
          <div style={{ padding: "1.3rem" }}>
            {opportunityStatsData?.count > 0 ? tablePagination : <></>}
          </div>
        </InlineStack>
      </Card>

      {areYouSureModal}
      {selectTagModal}
    </BlockStack>
  );
};

export default HomeTableWithIndexFilter;
