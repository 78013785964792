import React from "react";
import PropTypes from "prop-types";

import { Card, Badge, Text, Avatar, InlineStack } from "@shopify/polaris";

const SignerCard = (props) => {
  const { contact, signerIndex, handleSelectSigner, selectedContact } = props;

  const DESIGNATED_SIGNER_EMOJI = "✍️";

  const isSelectedOption = selectedContact
    ? selectedContact.id == contact?.id
    : false;
  const cardClass = isSelectedOption
    ? "FortSigner__PublicCardUpdated FortSigner__PublicCardUpdated--selected"
    : contact?.attributes?.signer
    ? "FortSigner__PublicCardUpdated FortSigner__PublicCardUpdated--selected"
    : "FortSigner__PublicCardUpdated";

  return (
    <>
      <Card sectioned key={`option-${signerIndex}`}>
        <div className={cardClass} onClick={() => handleSelectSigner(contact)}>
          <InlineStack gap="400" align="start" blockAlign="center" wrap={false}>
            <Text>
              <Avatar
                size="sm"
                name={contact?.attributes?.full_name || ""}
                initials={contact?.attributes?.initials || ""}
              />
            </Text>

            <div>
              <Text variant="bodyMd" fontWeight="bold" as="h3">
                {contact?.attributes?.full_name || ""}
              </Text>

              <Text variant="bodyMd" as="span" tone="subdued">
                {contact?.attributes?.email || "-"}
              </Text>

              <br/>

              <Text variant="bodyMd" as="span" tone="subdued">
                {contact?.attributes?.phone || "-"}
              </Text>
            </div>

            {contact?.attributes?.signer && (
              <Badge>{DESIGNATED_SIGNER_EMOJI} Designated signer</Badge> 
            )}
          </InlineStack>
        </div>
      </Card>
    </>
  );
};

SignerCard.propTypes = {
  signerIndex: PropTypes.number,
  contact: PropTypes.object,
  handleSelectSigner: PropTypes.func,
  selectedContact: PropTypes.object,
};

export default SignerCard;
