import React, { useState, useCallback, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

// import ReactTimeAgo from "react-time-ago";
import {
  LegacyCard,
  FooterHelp,
  Link,
  Page,
  Layout,
} from "@shopify/polaris";

import { useCreateVendorMutation } from "../../services/api";
import { toTitleCase, convertStateName } from "../../utilities";

import VendorForm from "../../components/Vendor/Form";
import AccountSearchForm from "../Account/SearchForm";
import LoadingScreen from "../../components/Auth/Loading";

import { CurrentContactContext } from "../../contexts/Contact";

const VendorsNew = () => {
  const history = useHistory();
  const location = useLocation();

  const { currentContact } = newContact(CurrentContactContext)

  const navigateToPreviousPath = useCallback(
    (state = {}) => {
      history.push({
        pathname: location.state?.previousPath,
        state: state,
      });
    },
    [history, location.state?.previousPath]
  );

  const [createVendor, { isLoading }] = useCreateVendorMutation();
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState();
  const [newVendor, setNewVendor] = useState();

  const vendorFromCompany = (company) => {
    return {
      name: company.name,
      opencorporates_company_number: company.company_number,
      opencorporates_company_type: company.company_type,
      opencorporates_current_status: company.current_status,
      opencorporates_jurisdiction_code: company.jurisdiction_code,
      opencorporates_incorporation_date: company.incorporation_date,
      opencorporates_registry_url: company.registry_url,
      opencorporates_url: company.opencorporates_url,
      billing_street: company.registered_address?.street_address
        ? toTitleCase(company.registered_address?.street_address)
        : company.registered_address?.street_address,
      billing_city: company.registered_address?.locality
        ? toTitleCase(company.registered_address?.locality)
        : company.registered_address?.locality,
      billing_state:
        company.registered_address?.region &&
        company.registered_address?.region.length == 2
          ? convertStateName(company.registered_address?.region)
          : "",
      billing_postal_code: company.registered_address?.postal_code,
      billing_country: company.registered_address?.country,
    };
  };

  const handleSelectVendorProfile = async (company = {}) => {
    setSelectedCompany(company);
    const newVendor = vendorFromCompany(company);
    setNewVendor({ attributes: { ...newVendor } });
  };

  const handleCreateVendor = (vendor) => {
    if (location.state?.previousPath) {
      navigateToPreviousPath({
        showToast: true,
        toastMessage: "Vendor account added.",
      });
    } else {
      history.push("/");
    }
  };

  const accountFormRef = useRef();

  const handleAddVendor = () => {
    accountFormRef.current.handleSubmitForm();
  };

  // const errorBanner = showErrorBanner && (
  //   <Banner
  //     status="critical"
  //     title="Something went wrong"
  //     onDismiss={() => setShowErrorBanner(false)}
  //   >
  //     There is already an account with this legal business name. Please choose
  //     another.
  //   </Banner>
  // );

  const footerHelp = (
    <FooterHelp>
      Learn more about{" "}
      <Link external url={"https://help.fortifypay.com/how-to-create-an-opportunity"}>
        managing accounts
      </Link>
    </FooterHelp>
  );

  const pageMarkup = (
    <Page
      title={"New vendor"}
      primaryAction={{ content: "Add vendor", onAction: handleAddVendor }}
    >
      <Layout>
        <Layout.Section>
          <LegacyCard>
            <LegacyCard.Section>
              <AccountSearchForm
                selectCompany={handleSelectVendorProfile}
                query={location?.state?.vendorName}
                resourceName="vendor"
              />
            </LegacyCard.Section>
          </LegacyCard>

          <LegacyCard>
            <LegacyCard.Section>
              <VendorForm
                ref={accountFormRef}
                vendor={newVendor}
                onCreateOrUpdate={handleCreateVendor}
              />
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section>{footerHelp}</Layout.Section>
      </Layout>
    </Page>
  );

  return currentContact ? pageMarkup : <LoadingScreen />
};

export default VendorsNew;
