import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Page,
  Layout,
  Banner,
} from "@shopify/polaris";

import {
  useLazyGetOpportunitiesQuery,
  useCreateOpportunityCsvMutation,
} from "../../services/api";
import { isMobile } from "react-device-detect";

import FadeIn from "../../components/FadeIn";
import AttachmentUpload from "../../components/VendorPayment/AttachmentUpload";
import DropQuoteCard from "../../components/DropQuoteCard";
import HomeTableWithIndexFilter from "../../components/Opportunity/HomeTableWithIndexFilter";

import LoadingScreen from "../../components/Auth/Loading";

import { CurrentContactContext } from "../../contexts/Contact";

const HomeIndex = () => {
  const history = useHistory();

  const navigateToConfirmInvoice = (vendorPayment) => {
    history.push(`/invoices/${vendorPayment.id}/confirm`);
  };

  const {
    currentContact,
    currentContactId,
    isOrganizationAdmin,
    isVendorAdmin,
  } = useContext(CurrentContactContext);

  useEffect(() => {
    if (!currentContact.attributes.has_complete_profile) {
      history.push(`/profile`);
    }
  }, [currentContact]);

  const [
    getLazyGetOpportunitiesQuery,
    { isLoading: isLoadingOpportunitiesForExport },
    lastPromiseInfo,
  ] = useLazyGetOpportunitiesQuery();

  const [
    createOpportunityCsv,
    { isLoading: isCreatingOpportunityCsv },
  ] = useCreateOpportunityCsvMutation();

  const [isExportingCsv, setIsExportingCsv] = useState(false);
  const [showExportError, setShowExportError] = useState(false);
  const [showExportSuccess, setShowExportSuccess] = useState(false);

  const handleNewOpportunityClick = () => {
    history.push("/opportunity/new");
  };

  const handleOpportunityClick = (opportunity) => {
    const clientId = opportunity.attributes.client_id;
    history.push({
      pathname: `/clients/${clientId}/opportunities/${opportunity.id}`,
      state: { opportunity: { ...opportunity } },
    });
  };

  const [searchValue, setSearchValue] = useState("");
  const [sortValue, setSortValue] = useState("UPDATED_AT_DESC");
  const [tabFilter, setTabFilter] = useState(null);
  const [savedView, setSavedView] = useState(null);

  const [isDraggingInvoice, setIsDraggingInvoice] = useState(false);
  const [hasDroppedInvoice, setHasDroppedInvoice] = useState(false);
  const handleInvoiceDragOver = () => {
    setIsDraggingInvoice(true);
  };
  const handleInvoiceDragEnter = () => {
    setIsDraggingInvoice(true);
  };
  const handleInvoiceDragLeave = () => {
    setIsDraggingInvoice(false);
  };
  const handleInvoiceDrop = () => {
    setTimeout(() => {
      setHasDroppedInvoice(true);
    }, 3500);
  };
  const handleInvoiceUploadSuccess = (vendorPayment) => {
    navigateToConfirmInvoice(vendorPayment);
  };

  const handleExportToCsv = async () => {
    setShowExportError(false);
    setShowExportSuccess(false);
    setIsExportingCsv(true);

    let rawResponse = await getLazyGetOpportunitiesQuery({
      filters: tabFilter,
      sort: sortValue,
      search: searchValue,
      savedView: savedView,
      forExport: true,
    });

    if (rawResponse.isError) {
      setShowExportError(true);
      setIsExportingCsv(false);
      return;
    }

    let rawOpportunities = rawResponse.data.opportunities.data;
    let opportunityIds = [];
    for (let i = 0; i < rawOpportunities.length; i++) {
      opportunityIds.push(rawOpportunities[i].id);
    }

    createOpportunityCsv({
      opportunityIds: opportunityIds,
      contact_id: currentContactId
    }).unwrap().then((result) => {
      setIsExportingCsv(false);
      setShowExportSuccess(true);
    }).catch((error) => {
      setShowExportError(true);
      setShowExportSuccess(false);
      console.log(error);
    });

  };

  const pageIndexMarkup = (
    <>
      {isDraggingInvoice && (
        <FadeIn fadeIn={isDraggingInvoice}>
          <div
            style={{
              height: "calc(100vh - 5.6rem)",
              width: "99.9%",
              padding: "20px",
            }}
          >
            <AttachmentUpload
              onDragEnter={handleInvoiceDragEnter}
              onDragLeave={handleInvoiceDragLeave}
              onDrop={handleInvoiceDrop}
              onUploadSuccess={handleInvoiceUploadSuccess}
              hasDroppedInvoice={hasDroppedInvoice}
            >
              <div
                style={{
                  height: "calc(100vh - 182px)",
                  position: "relative",
                  bottom: "calc(100vh - 150px)",
                }}
              ></div>
            </AttachmentUpload>
          </div>
        </FadeIn>
      )}
      {!isDraggingInvoice && (
        <FadeIn fadeIn={!isDraggingInvoice}>
          <Page
            fullWidth
            title="Opportunities"
            primaryAction={{
              content: "Create opportunity",
              onAction: handleNewOpportunityClick,
            }}
            secondaryActions={[
              ...(isOrganizationAdmin || isVendorAdmin
                ? [
                    {
                      content: "Export",
                      onAction: handleExportToCsv,
                      loading:
                        isLoadingOpportunitiesForExport || isExportingCsv,
                    },
                  ]
                : []),
            ]}
          >
            <Layout>
              {showExportError && (
                <Layout.Section>
                  <Banner title="Error" tone="critical">
                    <p>An error occured with the export</p>
                  </Banner>
                </Layout.Section>
              )}

              {showExportSuccess && (
                <Layout.Section>
                  <Banner title="Opportunity export processing" tone="success" onDismiss={() => { setShowExportSuccess(false) }}>
                    <p>You'll receive an email in a few minutes when it's ready.</p>
                  </Banner>
                </Layout.Section>
              )}
              <Layout.Section>
                <DropQuoteCard
                  onDrop={handleInvoiceDrop}
                  onUploadSuccess={handleInvoiceUploadSuccess}
                  dropzone={
                    <AttachmentUpload
                      onDragOver={handleInvoiceDragOver}
                      hasDroppedInvoice={hasDroppedInvoice}
                      isWithinCard
                    />
                  }
                />

                {!isMobile && (
                  <div style={{ display: "none" }}>
                    <AttachmentUpload onDragOver={handleInvoiceDragOver} />
                  </div>
                )}
              </Layout.Section>

              <Layout.Section primary>
                <HomeTableWithIndexFilter
                  isCurrentContactOrgAdmin={
                    currentContact.attributes.is_organization_admin
                  }
                  isVendorAdmin={isVendorAdmin}
                  onOpportunityClick={handleOpportunityClick}
                  setExportSearchValue={setSearchValue}
                  setExportSortValue={setSortValue}
                  setExportTabFilter={setTabFilter}
                  setExportSavedView={setSavedView}
                />
              </Layout.Section>
            </Layout>
          </Page>
        </FadeIn>
      )}
    </>
  );

  return currentContact ? pageIndexMarkup : <LoadingScreen />;
};

export default HomeIndex;
