import React from "react";

import {
  BlockStack,
  Spinner,
  InlineStack,
  Text,
  Box,
  SkeletonBodyText,
} from "@shopify/polaris";
import { Pip } from "@shopify/polaris/build/esm/components/Badge/components/Pip/Pip";

import PropTypes from "prop-types";
import { useGetOpportunityTasksQuery } from "../../services/api";

const TaskList = (props) => {
  const { opportunityId } = props;

  const { data: tasks, isLoading: isLoadingTasks } =
    useGetOpportunityTasksQuery(opportunityId, { skip: !opportunityId });

  return (
    <>
      {isLoadingTasks && (
        <BlockStack gap="200">
          {[...Array(5)].map((_, index) => {
            return (
              <Box key={`task${index}`} minWidth="140px" maxWidth="240px">
                <SkeletonBodyText lines={3} />
              </Box>
            );
          })}
        </BlockStack>
      )}

      {!isLoadingTasks && (
        <BlockStack gap="200">
          {tasks?.map((task, index) => {
            return (
              <InlineStack
                key={`task${index}`}
                gap="200"
                align={"start"}
                blockAlign={"start"}
                wrap={false}
              >
                <Box minHeight={"20px"}>
                  <Pip
                    progress={
                      task.attributes.complete ? "complete" : "incomplete"
                    }
                  />
                </Box>

                <Text
                  as="span"
                  tone={task.attributes.complete ? "subdued" : ""}
                  textDecorationLine={
                    task.attributes.complete ? "line-through" : false
                  }
                >
                  {task.attributes.description}
                </Text>
              </InlineStack>
            );
          })}
        </BlockStack>
      )}
    </>
  );
};

TaskList.propTypes = {
  opportunityId: PropTypes.string.isRequired,
};

export default TaskList;
