import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  IndexTable,
  useIndexResourceState,
  Text,
  Link,
  Badge,
} from "@shopify/polaris";
import PropTypes from "prop-types";
import {
  formatCurrency,
  capitalizeFirstLetter,
  formatNumericDate,
  convertServerDate,
} from "../../../utilities";
import { useGetAdminCreditSubmissionsQuery } from "../../../services/api";

const CreditSubmissionTable = (props) => {
  const {
    setCount,
    page,
    limit,
    isLoading,
    setIsLoading,
    setIds,
    queryValue,
    clientIdFilter,
    clientShowPage = false,
    lender,
  } = props;

  const history = useHistory();
  // const [opportunities, setOpportunities] = useState([]);
  const [creditSubmissions, setCreditSubmissions] = useState([]);
  const resourceName = {
    singular: "credit submission",
    plural: "credit submissions",
  };

  const {
    data: creditSubmissionData = { count: 0 },
    isLoading: queryIsLoading,
  } = useGetAdminCreditSubmissionsQuery({
    page,
    limit,
    clientIdFilter,
    clientShowPage,
    lender,
    search: queryValue,
  });

  useEffect(() => {
    if (creditSubmissionData.count > 0) {
      let lowerLimit = (page - 1) * limit;
      let upperLimit = page * limit;
      let list = [];

      let ocount = 0;
      for (let lcount = lowerLimit; lcount < upperLimit; lcount++) {
        if (creditSubmissionData.credit_submissions.data[ocount]) {
          list[lcount] = creditSubmissionData.credit_submissions.data[ocount];
        }
        ocount += 1;
      }

      setCreditSubmissions(list);
      setIds(creditSubmissionData.ids.join(","));
      setCount(creditSubmissionData.count);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setCreditSubmissions([]);
      setIds("");
      setCount(0);
    }
  }, [creditSubmissionData]);

  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(creditSubmissions);

  const handleCreditSubmissionClick = (creditSubmission) => {
    const clientId = creditSubmission.attributes.client_id;
    const opportunityId = creditSubmission.attributes.opportunity_id;

    history.push({
      pathname: `/clients/${clientId}/opportunities/${opportunityId}`,
    });
  };

  const rowMarkup = creditSubmissions.map((item, index) => {
    const decisionBadgeStatus = () => {
      let status;
      switch (item.attributes.decision) {
        case "Approved":
          status = "success";
          break;
        case "Needs more info":
          status = "warning";
          break;
        case "Declined":
          status = "critical";
          break;
        default:
          status = "default";
          break;
      }
      return status;
    };
    return (
      <IndexTable.Row
        id={index}
        key={index}
        selected={selectedResources.includes(item.attributes.id)}
        position={index}
      >
        <IndexTable.Cell>
          <Text variant="strong" fontWeight="bold" truncate>
            <Link
              removeUnderline
              monochrome
              onClick={() => handleCreditSubmissionClick(item)}
            >
              {item.attributes.opportunity_name}
            </Link>
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>{item.attributes.client_name}</IndexTable.Cell>
        <IndexTable.Cell>{item.attributes.lender_name}</IndexTable.Cell>
        <IndexTable.Cell>
          {item.attributes.submitted_at
            ? formatNumericDate(convertServerDate(item.attributes.submitted_at))
            : "--"}
        </IndexTable.Cell>
        <IndexTable.Cell>
          <div style={{ marginTop: "10px" }}>
            <Badge tone={decisionBadgeStatus()}>
              {capitalizeFirstLetter(item.attributes.decision)}
            </Badge>
            {item.attributes.selected_at && (
              <span style={{ marginLeft: "5px" }}>
                <Badge tone="success">Selected</Badge>
              </span>
            )}
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {formatCurrency(item.attributes.amount_requested)}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {formatCurrency(item.attributes.approved_amount)}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {item.attributes.approved_at
            ? formatNumericDate(convertServerDate(item.attributes.approved_at))
            : "--"}
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  return (
    <IndexTable
      resourceName={resourceName}
      itemCount={creditSubmissionData.count || 0}
      selectable={false}
      sortable={[false, false, false, false, false]}
      onSelectionChange={handleSelectionChange}
      selectedItemsCount={
        allResourcesSelected ? "All" : selectedResources.length
      }
      loading={queryIsLoading || isLoading}
      headings={[
        { title: "Opportunity" },
        { title: "Client" },
        { title: "Lender" },
        { title: "Submitted" },
        { title: "Status" },
        { title: "Requested amount" },
        { title: "Approved amount" },
        { title: "Approved" },
      ]}
    >
      {rowMarkup}
    </IndexTable>
  );
};

CreditSubmissionTable.propTypes = {
  count: PropTypes.number,
  setCount: PropTypes.func,
  page: PropTypes.number,
  limit: PropTypes.number,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  setIds: PropTypes.func,
  queryValue: PropTypes.string,
  clientIdFilter: PropTypes.string,
  clientShowPage: PropTypes.bool,
  lender: PropTypes.string,
};

export default CreditSubmissionTable;
