import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Page,
  Layout,
  ResourceItem,
  ResourceList,
  Text,
  Card
} from "@shopify/polaris";

import { useGetVendorsQuery } from "../../../services/api";

import FadeIn from "../../../components/FadeIn";
import LoadingScreen from "../../../components/Auth/Loading";

import { CurrentContactContext } from "../../../contexts/Contact";

const VendorAdminSettingsIndex = (props) => {
  const history = useHistory();

  const { currentContact } = useContext(CurrentContactContext)

  const { data: vendors = [], isLoading: isLoadingVendors } =
    useGetVendorsQuery({filter: "vendor_admin"});

  const navigateToVendorSettingsShow = (vendor) => {
    history.push(`/vendor_settings/${vendor.id}`);
  };

  const vendorRenderItem = (item) => {
    return (
      <ResourceItem
        id={item.attributes.name}
        accessibilityLabel={`View details for ${item.attributes.name}`}
        onClick={ () => { navigateToVendorSettingsShow(item) }}
      >
          <Text variant="bodyMd" fontWeight="bold" as="h3">
            {item.attributes.name}
          </Text>
      </ResourceItem>
    );
  }

  const vendorResourceList = (
    <Card padding="0">
      <ResourceList
        resourceName={{singular: 'vendor', plural: 'vendors'}}
        items={vendors}
        renderItem={vendorRenderItem}
      />
    </Card>
  )

  const pageIndexMarkup = (
    <>
      <FadeIn fadeIn>
        <Page
          fullWidth
          title="Vendor Settings"
        >
          <Layout>
            <Layout.Section>
              {vendorResourceList}
            </Layout.Section>
          </Layout>
        </Page>
      </FadeIn>
    </>
  );

  return currentContact ? pageIndexMarkup : <LoadingScreen />;
};

export default VendorAdminSettingsIndex;
