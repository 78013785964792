import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  LegacyCard,
  IndexTable,
  Text,
  useIndexResourceState,
} from "@shopify/polaris";

const AdminSettingsOrgAdminsTab = (props) => {
  const {
    admins,
    fetchingAdmins,
    currentContactId,
    isRevokingOrgAdmin,
    setSelectedUsers,
    setShowAddAdminModal,
    setOpenConfirmationModal,
    setConfirmRevokeAdminRole,
  } = props;

  const resourceName = {
    singular: 'user',
    plural: 'users',
  };

  const promotedBulkActions = [
    {
      content: "Revoke role",
      onAction: () => {
        setOpenConfirmationModal(true);
        setConfirmRevokeAdminRole(true);
      },
    },
  ];

  const {selectedResources, allResourcesSelected, handleSelectionChange} =
    useIndexResourceState(admins);

  useEffect(() => {
    setSelectedUsers([ ...selectedResources ]);
  }, [selectedResources, setSelectedUsers]);

  const rowMarkup = admins.map((user, index) => (
      <IndexTable.Row
        id={user.attributes.id}
        key={user.attributes.id}
        selected={selectedResources.includes(user.attributes.id)}
        position={index}
        disabled={currentContactId == user.attributes.id}
      >
        <IndexTable.Cell>
          <Text variant="bodyMd" fontWeight="bold" as="span">
            {user.attributes.full_name}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>{user.attributes.email}</IndexTable.Cell>
        <IndexTable.Cell>{user.attributes.last_logged_in_at}</IndexTable.Cell>
      </IndexTable.Row>
    ),
  );

  return (
    <>
      <LegacyCard>
        <LegacyCard.Header
          title="Admins"
          actions={[
            {
              content: 'Add admin',
              onAction: () => setShowAddAdminModal(true),
            },
          ]}
        >
        </LegacyCard.Header>
        <br/>
        <IndexTable
          resourceName={resourceName}
          itemCount={admins.length}
          selectedItemsCount={
            allResourcesSelected ? 'All' : selectedResources.length
          }
          onSelectionChange={handleSelectionChange}
          promotedBulkActions={promotedBulkActions}
          loading={fetchingAdmins || isRevokingOrgAdmin}
          headings={[
            {title: 'User'},
            {title: 'Email'},
            {title: 'Last login'},
          ]}
        >
          {rowMarkup}
        </IndexTable>
      </LegacyCard>
    </>
  );
};

AdminSettingsOrgAdminsTab.propTypes = {
  admins: PropTypes.any,
  fetchingAdmins: PropTypes.bool,
  currentContactId: PropTypes.string,
  isRevokingOrgAdmin: PropTypes.bool,
  setSelectedUsers: PropTypes.func,
  setShowAddAdminModal: PropTypes.func,
  setOpenConfirmationModal: PropTypes.func,
  setConfirmRevokeAdminRole: PropTypes.func,
};

export default AdminSettingsOrgAdminsTab;

