import React, {
  useImperativeHandle,
  forwardRef,
  useEffect,
  useState,
  useCallback,
} from "react";
import {
  FormLayout,
  TextField,
  Select,
  Spinner,
  Form,
  Button,
  Banner,
  Modal,
  Text,
} from "@shopify/polaris";
import { useField, useForm, notEmpty } from "@shopify/react-form";
import PropTypes from "prop-types";
import { WandIcon } from "@shopify/polaris-icons";

import {
  useGetAccountQuery,
  useGetTeamsQuery,
  useCreateAccountMutation,
  useCreateVendorMutation,
  useCreateLenderMutation,
  useUpdateAdminAccountMutation,
  useCreateClientMutation,
  useUpdateClientMutation,
} from "../../services/api";
import { stateOptions, toTitleCase, convertStateName } from "../../utilities";
import AccountSearchForm from "../../features/Account/SearchForm";

const AccountForm = forwardRef((props, ref) => {
  const {
    accountId,
    account: accountObject = { attributes: {} },
    isAdminUser = false,
    isAdminContext = false,
    onCreateOrUpdate = () => {},
    onEdit = () => {},
    resourceName,
    searchQuery,
    isClient,
    isVendor,
    isLender,
  } = props;

  const { data: account = accountObject, isLoading = false } =
    useGetAccountQuery(accountId, { skip: !accountId });

  const { data: teamData = [], isLoading: isLoadingTeams } = useGetTeamsQuery();
  const [teams, setTeams] = useState([]);
  const [selectedTeamId, setSelectedTeamId] = useState();
  const [selectedTeam, setSelectedTeam] = useState();

  useEffect(() => {
    if (teamData.length > 0) {
      const teamOption = teamData.map((team) => team?.attributes?.name);
      setTeams(teamOption);
      setSelectedTeam(teamData[0].attributes.name);
      setSelectedTeamId(teamData[0].id);
    }
  }, [teamData]);

  useEffect(() => {
    if (selectedTeam && teamData.length > 0) {
      const team = teamData.find(
        (team) => team?.attributes?.name == selectedTeam
      );
      setSelectedTeamId(team?.attributes?.id);
    }
  }, [selectedTeam, teamData]);

  const [updateClient, { isLoading: isUpdating }] = useUpdateClientMutation();

  const [updateAdminAccount, { isLoading: isAdminUpdating }] =
    useUpdateAdminAccountMutation();

  const [createAccount, { isLoading: isCreatingAccount }] =
    useCreateAccountMutation();
  const [createVendor, { isLoading: isCreatingVendor }] =
    useCreateVendorMutation();
  const [createLender, { isLoading: isCreatingLender }] =
    useCreateLenderMutation();

  const [createClient, { isLoading: isCreatingClient }] =
    useCreateClientMutation();

  const {
    fields,
    submit,
    submitting,
    reset,
    submitErrors,
    makeClean,
    dirty: accountFormDirty,
  } = useForm({
    fields: {
      name: useField({
        value: account ? account.attributes.name : "",
        validates: [notEmpty("Account name is required")],
      }),
      dba: useField({
        value: account
          ? account.attributes.dba || ""
          : newAccount
          ? newAccount.attributes.name || ""
          : "",
        validates: [],
      }),
      ein: useField({
        value: account ? account.attributes.ein : "",
        validates: [],
      }),
      billing_street: useField({
        value: account ? account.attributes.billing_street : "",
        validates: [],
      }),
      billing_country: useField({
        value: account ? account.attributes.billing_country : "",
        validates: [],
      }),
      billing_city: useField({
        value: account ? account.attributes.billing_city : "",
        validates: [],
      }),
      billing_state: useField({
        value: account ? account.attributes.billing_state || undefined : "",
        validates: [],
      }),
      billing_postal_code: useField({
        value: account ? account.attributes.billing_postal_code : "",
        validates: [],
      }),
    },
    async onSubmit(form) {
      if (account.id) {
        if (!isAdminContext) {
          delete form.name;
        }

        const updateFunction = isAdminContext
          ? updateAdminAccount
          : updateClient;

        const payload = isAdminContext
          ? { id: account.id, ...form }
          : { id: account.id, ...form };

        return updateFunction(payload)
          .unwrap()
          .then((response) => {
            makeClean();
            onCreateOrUpdate(response);
            return { status: "success" };
          })
          .catch((error) => {
            console.log(error);
            setShowErrorBanner(true);
            return {
              status: "fail",
              errors: [{ message: "Could not update account." }],
            };
          });
      } else {
        const newAccountFormPayload = newAccount
          ? { ...newAccount.attributes, ...form }
          : { ...form };
        if (resourceName == "account") {
          return createAccount({ ...newAccountFormPayload })
            .unwrap()
            .then((response) => {
              makeClean();
              onCreateOrUpdate(response);
              return { status: "success" };
            })
            .catch((error) => {
              console.log(error);
              setShowErrorBanner(true);
              let message = "Could not create account.";
              if (error?.data?.message) {
                if (
                  Object.keys(error.data.message).includes(
                    "legal_business_name"
                  )
                ) {
                  message = `Could not create account. Legal business name ${error.data.message["legal_business_name"]}.`;
                }
              }
              return {
                status: "fail",
                errors: [{ message: message }],
              };
            });
        } else if (isClient) {
          return createClient({
            teamId: selectedTeamId,
            ...newAccountFormPayload,
          })
            .unwrap()
            .then((response) => {
              makeClean();
              onCreateOrUpdate(response);
              return { status: "success" };
            })
            .catch((error) => {
              console.log(error);
              setShowErrorBanner(true);
              let message = "Could not create client.";
              if (error?.data?.message) {
                if (
                  Object.keys(error.data.message).includes(
                    "legal_business_name"
                  )
                ) {
                  message = `Could not create vendor. Legal business name ${error.data.message["legal_business_name"]}.`;
                }
              }
              return {
                status: "fail",
                errors: [{ message: message }],
              };
            });
        } else if (isVendor) {
          return createVendor({ ...newAccountFormPayload })
            .unwrap()
            .then((response) => {
              makeClean();
              onCreateOrUpdate(response);
              return { status: "success" };
            })
            .catch((error) => {
              console.log(error);
              setShowErrorBanner(true);
              let message = "Could not create vendor.";
              if (error?.data?.message) {
                if (
                  Object.keys(error.data.message).includes(
                    "legal_business_name"
                  )
                ) {
                  message = `Could not create vendor. Legal business name ${error.data.message["legal_business_name"]}.`;
                }
              }
              return {
                status: "fail",
                errors: [{ message: message }],
              };
            });
        } else if (isLender) {
          return createLender({ ...newAccountFormPayload })
            .unwrap()
            .then((response) => {
              makeClean();
              onCreateOrUpdate(response);
              return { status: "success" };
            })
            .catch((error) => {
              console.log(error);
              setShowErrorBanner(true);
              let message = "Could not create lender.";
              if (error?.data?.message) {
                if (
                  Object.keys(error.data.message).includes(
                    "legal_business_name"
                  )
                ) {
                  message = `Could not create lender. Legal business name ${error.data.message["legal_business_name"]}.`;
                }
              }
              return {
                status: "fail",
                errors: [{ message: message }],
              };
            });
        }
      }
    },
  });

  useEffect(() => {
    onEdit(accountFormDirty);
  }, [onEdit, accountFormDirty]);

  useImperativeHandle(ref, () => ({
    handleSubmitForm() {
      submit();
    },
  }));

  const accountFromCompany = (company) => {
    return {
      name: company.name,
      opencorporates_company_number: company.company_number,
      opencorporates_company_type: company.company_type,
      opencorporates_current_status: company.current_status,
      opencorporates_jurisdiction_code: company.jurisdiction_code,
      opencorporates_incorporation_date: company.incorporation_date,
      opencorporates_registry_url: company.registry_url,
      opencorporates_url: company.opencorporates_url,
      billing_street: company.registered_address?.street_address
        ? toTitleCase(company.registered_address?.street_address)
        : company.registered_address?.street_address,
      billing_city: company.registered_address?.locality
        ? toTitleCase(company.registered_address?.locality)
        : company.registered_address?.locality,
      billing_state:
        company.registered_address?.region &&
        company.registered_address?.region.length == 2
          ? convertStateName(company.registered_address?.region)
          : "",
      billing_postal_code: company.registered_address?.postal_code,
      billing_country: company.registered_address?.country,
    };
  };

  const [newAccount, setNewAccount] = useState();

  const handleSelectCompany = (company = {}) => {
    const newAccountFromCompany = accountFromCompany(company);
    setNewAccount({ attributes: { ...newAccountFromCompany } });
    fields.name.onChange(newAccountFromCompany.name || "");
    // Comment out phone & email fields - Not accepted in lender inputs anymore
    // fields.email.onChange(newAccountFromCompany.email || "");
    // fields.phone.onChange(newAccountFromCompany.phone || "");
    fields.billing_street.onChange(newAccountFromCompany.billing_street || "");
    fields.billing_state.onChange(newAccountFromCompany.billing_state || "");
    fields.billing_city.onChange(newAccountFromCompany.billing_city || "");
    fields.billing_postal_code.onChange(
      newAccountFromCompany.billing_postal_code || ""
    );
    fields.billing_country.onChange(
      newAccountFromCompany.billing_country || ""
    );
  };

  const handleDeselectCompany = () => {
    setNewAccount();
    fields.name.onChange();
    fields.billing_street.onChange();
    fields.billing_state.onChange();
    fields.billing_city.onChange();
    fields.billing_postal_code.onChange();
    fields.billing_country.onChange();
  };

  const handleFormatLegalName = useCallback(() => {
    const formattedName = toTitleCase(fields.name.value);
    fields.name.onChange(formattedName);
  }, [fields.name]);

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const errorBanner = submitErrors.length > 0 && showErrorBanner && (
    <Banner tone="critical" title="Something went wrong">
      {submitErrors.map((error, index) => {
        return <p key={index}>{error.message}</p>;
      })}
    </Banner>
  );

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      {(!account.id || isAdminContext || isAdminUser) && (
        <Modal.Section>
          <AccountSearchForm
            selectCompany={handleSelectCompany}
            deselectCompany={handleDeselectCompany}
            resourceName={resourceName}
            query={searchQuery}
          />
        </Modal.Section>
      )}
      {!account.id && isClient && (
        <Modal.Section>
          <Select
            label="Team"
            options={teams}
            value={selectedTeam}
            onChange={setSelectedTeam}
          />
        </Modal.Section>
      )}
      <Modal.Section>
        <Form onSubmit={submit}>
          <FormLayout>
            {errorBanner}
            <FormLayout.Group>
              <TextField
                label="Legal name"
                {...fields.name}
                requiredIndicator
                disabled={isAdminContext ? false : account.id || newAccount}
                placeholder={"Search for company name above"}
                connectedRight={
                  isAdminContext && (
                    <Button
                      icon={WandIcon}
                      onClick={handleFormatLegalName}
                      disabled
                    />
                  )
                }
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField label="DBA" {...fields.dba} />
            </FormLayout.Group>
            <FormLayout.Group>
              <TextField label="EIN" {...fields.ein} />
            </FormLayout.Group>
            <TextField label="Street address" {...fields.billing_street} />
            <FormLayout.Group>
              <TextField label="City" {...fields.billing_city} />
              <Select
                label="State"
                placeholder={"Select state"}
                options={stateOptions}
                {...fields.billing_state}
              />
              <TextField label="Zip code" {...fields.billing_postal_code} />
              <Text visuallyHidden as="h2">
                <TextField label="Country" {...fields.billing_country} />
              </Text>
            </FormLayout.Group>
            {account && accountFormDirty && false && (
              <Button
                submit

                loading={
                  isUpdating ||
                  submitting ||
                  isCreatingAccount ||
                  isCreatingClient ||
                  isCreatingLender ||
                  isCreatingVendor
                }
                variant="primary">
                Save
              </Button>
            )}
          </FormLayout>
        </Form>
      </Modal.Section>
    </>
  );
});
AccountForm.displayName = "AccountForm";

AccountForm.defaultProps = {
  isVendor: false,
  isLender: false,
  isClient: false,
};

AccountForm.propTypes = {
  accountId: PropTypes.number,
  account: PropTypes.object,
  isAdminContext: PropTypes.bool,
  isAdminUser: PropTypes.bool,
  onCreateOrUpdate: PropTypes.func,
  onEdit: PropTypes.func,
  resourceName: PropTypes.string,
  searchQuery: PropTypes.string,
  isClient: PropTypes.bool.isRequired,
  isVendor: PropTypes.bool.isRequired,
  isLender: PropTypes.bool.isRequired,
};

export default AccountForm;
