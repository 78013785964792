import React, { useState, useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Card,
  IndexTable,
  Text,
  BlockStack,
  Modal,
  Box,
  InlineStack,
  Button,
} from "@shopify/polaris";

import { useGetVendorAdminsQuery } from "../../../../services/api";

import { useDebounce } from "use-debounce";
import {
  formatShortDateTime,
} from "../../../../utilities";
import VendorContactForm from "../../../../components/VendorAdmin/Vendor/Contact/Form";

import CardBoxTitle from "../../../../components/Card/BoxTitle";

const VendorAdminListTab = (props) => {
  const { vendorId } = props;

  const resourceName = {
    singular: "admin",
    plural: "admins",
  };

  const [queryValue, setQueryValue] = useState(undefined);
  const [searchValue] = useDebounce(queryValue, 500);
  const [sortValue, setSortValue] = useState("UPDATED_AT_DESC");
  const [sortDirection, setSortDirection] = useState("descending");
  const [showVendorAdminInviteModal, setShowVendorAdminInviteModal] =
    useState(false);
  const [showNewContactForm, setShowNewContactForm] = useState(false);
  const [addNewContact, setAddNewContact] = useState(false);

  const [taggedWith, setTaggedWith] = useState(undefined);
  const [originatorSearch] = useDebounce(taggedWith, 500);

  const handleTaggedWithRemove = useCallback(() => setTaggedWith(null), []);
  const handleQueryValueRemove = useCallback(
    () => setQueryValue(undefined),
    []
  );
  // const handleFiltersClearAll = useCallback(() => {
  //   handleTaggedWithRemove();
  //   handleQueryValueRemove();
  // }, [handleQueryValueRemove, handleTaggedWithRemove]);

  const { data: admins = [], isLoading: queryIsLoading } =
    useGetVendorAdminsQuery({ vendorId: vendorId });

  useEffect(() => {
    console.log(`vendor admin list is loading list: ${queryIsLoading}`);
  }, [queryIsLoading]);

  const handleTableSorting = (headingIndex, direction) => {
    let currentSortDirection = "descending";
    if (sortDirection === "ascending") {
      currentSortDirection = "descending";
      setSortDirection("descending");
    } else {
      currentSortDirection = "ascending";
      setSortDirection("ascending");
    }

    let sortTag = "";
    switch (headingIndex) {
      case 1:
        sortTag = "CREATED_AT_";
        break;
      case 2:
        sortTag = "ORIGINATOR_";
        break;
      case 3:
        sortTag = "AMOUNT_";
        break;
    }

    if (currentSortDirection === "ascending") {
      sortTag = `${sortTag}ASC`;
    } else {
      sortTag = `${sortTag}DESC`;
    }

    setSortValue(sortTag);
  };

  const rowMarkup =
    admins.length > 0
      ? admins.map((item, index) => (
          <IndexTable.Row id={index} key={index} position={index}>
            <IndexTable.Cell>
              <Text variant="strong" fontWeight="bold" truncate>
                {item.attributes.full_name}
              </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>{item.attributes.email}</IndexTable.Cell>
            <IndexTable.Cell>
              {item.attributes.last_logged_in_at
                ? formatShortDateTime(
                    new Date(item.attributes.last_logged_in_at)
                  )
                : ""}
            </IndexTable.Cell>
          </IndexTable.Row>
        ))
      : [];

  const AdminIndexTable = (
    <IndexTable
      resourceName={resourceName}
      itemCount={admins.length}
      selectable={false}
      loading={queryIsLoading}
      headings={[
        { title: "User" },
        { title: "Email" },
        { title: "Last login" },
      ]}
      sortable={[true, false, false, false]}
      defaultSortDirection={"descending"}
      sortDirection={sortDirection}
      onSort={handleTableSorting}
    >
      {rowMarkup}
    </IndexTable>
  );

  const vendorContactFormRef = useRef();

  const inviteVendorAdminModal = (
    <Modal
      open={showVendorAdminInviteModal}
      onClose={() => setShowVendorAdminInviteModal(false)}
      title={"Add vendor admin"}
      large={true}
      primaryAction={{
        content: "Add",
        loading: addNewContact,
        onAction: () => {
          if (showNewContactForm) {
            setAddNewContact(true);
          } else {
            vendorContactFormRef.current.handleAddAdminToVendor();
            setShowVendorAdminInviteModal(false);
          }
        },
      }}
    >
      <Modal.Section>
        <VendorContactForm
          onAfterShareWithContacts={() => {}}
          isShowNewContactForm={setShowNewContactForm}
          vendorId={vendorId}
          vendorAdmins={admins}
          ref={vendorContactFormRef}
          addNewContact={addNewContact}
          setAddNewContact={setAddNewContact}
        />
      </Modal.Section>
    </Modal>
  );

  const indexTablePaged = (
    <div style={{ paddingTop: "1rem" }}>
      <Card padding="0">
        <CardBoxTitle>
          <InlineStack align="space-between">
            <Text variant="headingMd" as="h6">Admins</Text>
            <Button
              variant="primary"
              onClick={() => { setShowVendorAdminInviteModal(true); }}
            >
              Add admin
            </Button>
          </InlineStack>
        </CardBoxTitle>
        <Box as="section" paddingInlineStart="400">
          <Text as="span" tone="subdued">
            Admins have full access to teams, settings, reports, and features
          </Text>
        </Box>
        {/* <div style={{ paddingTop: "0.5rem", paddingBottom: "1.5rem" }}> */}
        <Box as="section" paddingBlockStart="200" paddingBlockEnd="400">
          {AdminIndexTable}
          <BlockStack inlineAlign={"center"}></BlockStack>
        </Box>
      </Card>
      {inviteVendorAdminModal}
    </div>
  );

  return indexTablePaged;
};

VendorAdminListTab.propTypes = {
  vendorId: PropTypes.any,
};

export default VendorAdminListTab;
