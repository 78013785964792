import React, { useCallback } from "react";

import {
  Text,
  Button,
  Card,
  BlockStack,
  InlineStack,
  Box,
} from "@shopify/polaris";
import { InfoIcon } from "@shopify/polaris-icons";

type Props = {
  solvingMode: string;
  setSolvingMode: (solvingMode: string) => void;
};

const CalculatorModeToggle = (props: Props) => {
  const { solvingMode, setSolvingMode } = props;

  const title = "Calculator mode";
  const toggleId = "solve-mode";

  const formattedSolvingMode = useCallback(() => {
    switch (solvingMode) {
      case "payment":
        return "base payment";
      case "rate":
        return "interest rate";
      case "total":
        return "total amount";
    }
  }, [solvingMode]);

  const handleSolvingModeToggle = useCallback(() => {
    switch (solvingMode) {
      case "payment":
        setSolvingMode("rate");
        break;
      case "rate":
        setSolvingMode("payment");
        break;
      case "total":
        setSolvingMode("total");
        break;
    }
  }, [solvingMode, setSolvingMode]);

  const helpLink = (
    <Button
      variant="plain"
      icon={InfoIcon}
      accessibilityLabel="Learn more"
      external
      url={"https://help.fortifypay.com/"}
    />
  );

  const settingTitle = title ? (
    <InlineStack gap="200" wrap={false}>
      <InlineStack gap="200" align="start" blockAlign="center">
        <label htmlFor={toggleId}>
          <Text variant="headingMd" as="h6">
            {title}
          </Text>
        </label>
        <InlineStack gap="200" align="center" blockAlign="center">
          {helpLink}
        </InlineStack>
      </InlineStack>
    </InlineStack>
  ) : null;

  const headerMarkup = (
    <Box width="100%">
      <InlineStack
        gap="1200"
        align="space-between"
        blockAlign="start"
        wrap={false}
      >
        {settingTitle}
      </InlineStack>
    </Box>
  );

  const contentStatus = solvingMode === "payment"
    ? "Solve for interest rate"
    : "Solve for base payment";
  const actionMarkup = (
    <Button
      role="switch"
      id={toggleId}
      onClick={handleSolvingModeToggle}
      size="slim"
    >
      {contentStatus}
    </Button>
  );

  const description =
    "Calculator can either solve for base payment or interest rate.";

  const descriptionMarkup = (
    <BlockStack gap="400">
      <Text variant="bodyMd" as="p" tone="subdued">
        {description}
      </Text>
    </BlockStack>
  );

  

  return (
    <Card>
      <BlockStack gap={{ xs: "400", sm: "500" }}>
        <Box width="100%">
          <BlockStack gap={{ xs: "200", sm: "400" }}>
            {/* {headerMarkup} */}
            {descriptionMarkup}
          </BlockStack>
        </Box>

        <InlineStack gap="400" align={"space-between"} blockAlign={"center"}>
          <Text variant="bodyMd" as="p">
            This calculator is solving for{" "}
            <b>{formattedSolvingMode()}.</b>
          </Text>

          <Box>
            <InlineStack align="start">{actionMarkup}</InlineStack>
          </Box>
        </InlineStack>
      </BlockStack>
    </Card>
  );
};

export default CalculatorModeToggle;
