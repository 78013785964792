import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Box,
  Text,
  Link,
  Checkbox,
  Spinner,
  BlockStack,
} from "@shopify/polaris";
import {
  useGetVendorProgramQuery,
  useUpdateAdminVendorProgramMutation
 } from "../../../../services/api";

const VendorAdminIntegratedPaymentsSettings = (props) => {
  const { vendor } = props;

  const { data: vendorProgram, isLoading: isLoadingVendorProgram } =
    useGetVendorProgramQuery({ id: vendor.attributes.vendor_program_id }, { skip: !vendor?.attributes?.vendor_program_id });

  const [updateAdminVendorProgram, { isLoading: isVendorProgramUpdating }] =
    useUpdateAdminVendorProgramMutation();

  useEffect(() => {
    if(vendorProgram) {
      setEmailSettings(vendorProgram.attributes.email_settings)
    }
  }, [vendorProgram])

  const [emailSettings, setEmailSettings] = useState({ failed_payment_email: true, payment_reminder_email: true})

  const handleChangeEmailSettings = (newChecked, id) => {
    let newEmailSettings = JSON.parse(JSON.stringify(emailSettings))
    newEmailSettings[id] = newChecked

    setEmailSettings(newEmailSettings)

    return updateAdminVendorProgram({ id: vendor.attributes.vendor_program_id, email_settings: newEmailSettings })
      .unwrap()
      .then((response) => {
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
        return {
          status: "fail",
          errors: [{ message: "Could not update vendor program" }],
        };
      });
  }

  const tab = (
    <>
      <br />

      { (isLoadingVendorProgram || isVendorProgramUpdating) && (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "304px",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: "calc(50% - 2.5rem)",
              top: "7.5rem",
            }}
          >
            <Spinner size="large" />
          </div>
        </div>
      )}

      { (!isLoadingVendorProgram && !isVendorProgramUpdating) && (
        <BlockStack gap="400">
          <Card padding="0">
            <Box padding="400">
              <BlockStack>
                <Text variant="headingMd" as="h6">
                  Connected Account
                </Text>

                <br />

                <Text as="p" tone="subdued">
                  {vendorProgram?.attributes?.stripe_connected_account_id}
                </Text>

              </BlockStack>
            </Box>
          </Card>

          <Card padding="0">
            <Box padding="400">
              <BlockStack>
                <Checkbox
                  id="failed_payment_email"
                  label={
                    <BlockStack>
                      <Text as="p">
                        Send failed email recovery email sequence
                      </Text>

                      <Link url="https://help.fortifypay.com/how-often-does-fortify-send-failed-payment-emails" external>
                        Learn more about Fortify failed payment recovery
                      </Link>
                    </BlockStack>
                  }
                  checked={emailSettings.failed_payment_email}
                  onChange={handleChangeEmailSettings}
                />

                <br />

                <Checkbox
                  id="payment_reminder_email"
                  label={"Send upcoming payment reminder 2 days before payment is pulled"}
                  checked={emailSettings.payment_reminder_email}
                  onChange={handleChangeEmailSettings}
                />
              </BlockStack>
            </Box>
          </Card>
        </BlockStack>
      )}

    </>
  );

  return tab;
};

VendorAdminIntegratedPaymentsSettings.propTypes = {
  vendor: PropTypes.any,
};

export default VendorAdminIntegratedPaymentsSettings;
