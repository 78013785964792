import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";

import {
  Card,
  Page,
  InlineStack,
  Button,
  Layout,
  Badge,
  Toast,
  ResourceItem,
  Truncate,
  Avatar,
  Modal,
  IndexTable,
  Text,
  Select,
  Spinner,
  BlockStack,
} from "@shopify/polaris";

import { DeleteIcon } from "@shopify/polaris-icons";

import {
  useGetAdminTeamQuery,
  useGetMembershipsByAdminTeamQuery,
  useInviteMembershipsByTeamMutation,
  useUpdateAdminMembershipRoleMutation,
  useDeleteAdminMembershipMutation,
  useUpdateTeamMutation,
} from "../../../services/api";

import { formatShortDateTime } from "../../../utilities";
import FadeIn from "../../../components/FadeIn";
import LoadingScreen from "../../../components/Auth/Loading";

import AdminTeamContactForm from "../../../components/Admin/Team/Contact/Form";
import VendorLockCard from "../../../components/Team/VendorLockCard";
import PurchaseOptionLockCard from "../../../components/Team/PurchaseOptionLockCard";

import { CurrentContactContext } from "../../../contexts/Contact";
import AdminTeamForm from "../../../components/Admin/Team/Form";

const AdminTeamShow = (props) => {
  const history = useHistory();
  const location = useLocation();

  const { id: teamId } = useParams();

  const [inviteParams, setInviteParams] = useState({
    contactIds: [],
    notifyContacts: false,
    notifMsg: "",
    isManager: false,
  });
  const [showMembersInviteModal, setShowMembersInviteModal] = useState(false);
  const [showNewContactForm, setShowNewContactForm] = useState(false);
  const [addNewContact, setAddNewContact] = useState(false);
  const [selectedMemberUpdate, setSelectedMemberUpdate] = useState("");
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState(null);

  const { currentContact, isOrganizationAdmin } = useContext(CurrentContactContext);

  const navigateToAdminIndex = useCallback(() => {
    history.push("/admin");
  }, [history]);

  useEffect(() => {
    if (!teamId) {
      navigateToAdminIndex();
    }
  }, [teamId, navigateToAdminIndex]);

  const [inviteMember, { isLoading: isInviting }] =
    useInviteMembershipsByTeamMutation();

  const [updateMemberRole, { isLoading: isUpdatingRole }] =
    useUpdateAdminMembershipRoleMutation();

  const [removeMembership, { isLoading: isRemovingMember }] =
    useDeleteAdminMembershipMutation();

  const { data: team = { attributes: {} }, isLoading: isLoadingTeam } =
    useGetAdminTeamQuery({ id: teamId });

  const {
    data: memberships = [],
    isLoading: isLoadingMemberships,
    isFetching: isFetchingMemberships,
  } = useGetMembershipsByAdminTeamQuery(teamId);

  const [updateTeam, { isLoading: isUpdatingTeam }] = useUpdateTeamMutation();

  const toggleVendorLock = () => {
    return updateTeam({ team_id: teamId })
      .unwrap()
      .then((response) => {
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
        return {
          status: "fail",
          errors: [{ message: "Could not toggle vendor lock." }],
        };
      });
  };

  const togglePurchaseOptionLock = () => {
    return updateTeam({ team_id: teamId, financing_option_locked: true })
      .unwrap()
      .then((response) => {
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
        return {
          status: "fail",
          errors: [{ message: "Could not toggle purchase option lock." }],
        };
      });
  };

  const handleInviteToTeam = () => {
    inviteMember({ teamId, ...inviteParams })
      .then(() => {
        setToastMessage(`Contacts invited to ${team.attributes.name}`);
        setShowToast(true);
        setShowMembersInviteModal(false);

        return { status: "success" };
      })
      .catch((error) => console.log(error));
  };

  const handleSelectFocus = useCallback(
    (value) => setSelectedMemberUpdate(value),
    [selectedMemberUpdate]
  );

  const handleUpdateRole = useCallback(
    (value) => {
      updateMemberRole({
        memberId: selectedMemberUpdate,
        team_id: teamId,
        isManager: value == "Admin",
        isFinanceSpecialist: value == "Finance specialist",
      })
        .then(() => {
          setToastMessage(`Role updated to ${value}`);
          setShowToast(true);

          return { status: "success" };
        })
        .catch((error) => console.log(error));
    },
    [selectedMemberUpdate]
  );

  const handleRemoveMember = () => {
    removeMembership({
      memberId: memberToRemove.attributes.id,
      team_id: teamId,
    }).then(() => {
      setToastMessage(`${memberToRemove.attributes.contact_full_name} has been removed from the team`);
      setShowToast(true);
      setMemberToRemove(null);
      return { status: "success" };
    }).catch((error) => console.log(error));
  };

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const toggleShowToast = useCallback(
    () => setShowToast((active) => !active),
    []
  );
  const handleDismissToast = () => {
    toggleShowToast();
    setToastMessage("");
  };
  const toastMarkup = showToast ? (
    <Toast content={toastMessage} onDismiss={handleDismissToast} />
  ) : undefined;

  const handleOpenConfirmationModal = (member) => {
    setMemberToRemove(member);
    setOpenConfirmationModal(true);
  };
  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(false);
    setMemberToRemove(null);
  };
  const handleConfirmDeleteModal = () => {
    handleRemoveMember();
    setOpenConfirmationModal(false);
  };

  const areYouSureModal = (
    <Modal
      open={openConfirmationModal}
      onClose={handleCloseConfirmationModal}
      title="Are you sure?"
      loading={isRemovingMember}
      primaryAction={{
        content: "Confirm",
        onAction: handleConfirmDeleteModal,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleCloseConfirmationModal,
        },
      ]}
    >
      <Modal.Section>
        <Text variant="bodyMd" as="span">
          You are about to remove this member from the team.
        </Text>
      </Modal.Section>
    </Modal>
  );

  const rowMarkup =
    memberships.length > 0
      ? memberships.map((item, index) => (
          <IndexTable.Row id={index} key={index} position={index}>
            <IndexTable.Cell>
              <Text variant="strong" fontWeight="bold" truncate>
                {item.attributes.contact_full_name}
              </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>{item.attributes.contact_email}</IndexTable.Cell>
            <IndexTable.Cell>
              {item.attributes.last_logged_in_at
                ? formatShortDateTime(
                    new Date(item.attributes.last_logged_in_at)
                  )
                : ""}
            </IndexTable.Cell>
            <IndexTable.Cell>
              {(isUpdatingRole ||
                isFetchingMemberships ||
                isLoadingMemberships) &&
              selectedMemberUpdate == item.attributes.id ? (
                <div
                  style={{
                    height: "36px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Spinner size="small" />
                </div>
              ) : (
                <InlineStack gap="300" align="end">
                  <Select
                    options={ isOrganizationAdmin ?
                      ["Member", "Finance specialist", "Admin"] : ["Member", "Admin"]}
                    onFocus={() => handleSelectFocus(item.attributes.id)}
                    onChange={handleUpdateRole}
                    value={
                      item.attributes.is_team_admin ? "Admin" :
                      item.attributes.is_finance_specialist ?
                      "Finance specialist" : "Member"
                    }
                    disabled={
                      isUpdatingRole ||
                      isFetchingMemberships ||
                      isLoadingMemberships
                    }
                  />
                  <Button
                    icon={DeleteIcon}
                    onClick={() => handleOpenConfirmationModal(item)}
                    tone="critical"
                  />
                </InlineStack>
              )}
            </IndexTable.Cell>
          </IndexTable.Row>
        ))
      : [];

  const labelStyles = {
    minHeight: "36px",
    paddingTop: "4px",
  };

  const inviteMembersModal = (
    <Modal
      open={showMembersInviteModal}
      onClose={() => setShowMembersInviteModal(false)}
      title={`Invite new members to ${team.attributes.name}`}
      large={true}
      primaryAction={{
        content: "Send",
        loading: addNewContact,
        onAction: () => {
          if (showNewContactForm) {
            setAddNewContact(true);
          } else {
            handleInviteToTeam();
            setShowMembersInviteModal(false);
          }
        },
      }}
    >
      <Modal.Section>
        <AdminTeamContactForm
          isOrganizationAdmin={isOrganizationAdmin}
          onAfterShareWithContacts={() => {}}
          teamId={teamId}
          memberships={memberships}
          ref={teamContactFormRef}
          setInviteParams={setInviteParams}
          isShowNewContactForm={setShowNewContactForm}
          addNewContact={addNewContact}
          setAddNewContact={setAddNewContact}
        />
      </Modal.Section>
    </Modal>
  );

  const renderMembershipItem = (item) => {
    const {
      id,
      attributes: { contact_full_name, roles, contact_email, contact_initials },
    } = item;

    const avatar = (
      <div className="FortComments__Avatar">
        <Avatar
          initials={contact_initials}
          name={contact_full_name}
          size={"sm"}
        />
      </div>
    );

    return (
      <ResourceItem
        id={id}
        media={avatar}
        accessibilityLabel={`View details for ${contact_full_name}`}
        verticalAlignment="center"
      >
        <BlockStack spacing="extraLoose" alignment="center" wrap={false}>
          <div>
            <Text as="span" fontWeight="semibold" >
              <Truncate>{contact_full_name}</Truncate>
            </Text>
            <Text variant="bodySm" as="p">
              <Truncate>{contact_email}</Truncate>
            </Text>
          </div>

          {roles.map((role, index) => {
            return (
              <div key={index}>
                <Badge>{role.name}</Badge>
              </div>
            );
          })}
        </BlockStack>
      </ResourceItem>
    );
  };

  const teamContactFormRef = useRef();

  const handleTeamUpdate = () => {
    setToastMessage("Team updated");
    setShowToast(true);
  };

  const pageMarkup = (
    <FadeIn fadeIn>
      <Page fullWidth>
        <Layout>
          {/* Team details */}
          <Layout.Section>
            <Card>
              <div style={{ paddingBottom: "16px" }}>
                <InlineStack gap="200" align="space-between">
                  <Text variant="headingMd" as="h6">
                    Basic information
                  </Text>
                </InlineStack>
              </div>

              <div>
                <AdminTeamForm
                  team={team}
                  teamId={team.id}
                  onCreateOrUpdate={handleTeamUpdate}
                  onEdit={() => {}}
                />
              </div>
            </Card>
          </Layout.Section>

          <Layout.Section>
            <VendorLockCard team={team} toggleVendorLock={toggleVendorLock} />
          </Layout.Section>
          <Layout.Section>
            <PurchaseOptionLockCard team={team} togglePurchaseOptionLock={togglePurchaseOptionLock} />
          </Layout.Section>

          <Layout.Section>
            <Card padding="0">
              <div style={{ padding: "20px"}}>
                <InlineStack gap="200" align="space-between">
                  <Text variant="headingMd" as="h6">
                    Members
                  </Text>

                  <Button  onClick={() => { setShowMembersInviteModal(true) }} variant="plain">
                    Add members
                  </Button>
                </InlineStack>
              </div>

              <div>
                <IndexTable
                  resourceName={{
                    singular: "member",
                    plural: "members",
                  }}
                  itemCount={memberships.length}
                  selectable={false}
                  loading={isLoadingMemberships || isRemovingMember}
                  headings={[
                    { title: "User" },
                    { title: "Email" },
                    { title: "Last login" },
                    { title: "" },
                  ]}
                  sortable={[true, false, false, false]}
                  defaultSortDirection={"descending"}
                >
                  {rowMarkup}
                </IndexTable>
              </div>
            </Card>
          </Layout.Section>
          {inviteMembersModal}
          {areYouSureModal}
          {toastMarkup}
        </Layout>
      </Page>
    </FadeIn>
  );

  return currentContact ? pageMarkup : <LoadingScreen />;
};

export default AdminTeamShow;
