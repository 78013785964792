import React from "react";

import {
  FormLayout,
  Button,
  Card,
  Text,
  ResourceList,
  ResourceItem,
  Avatar,
  Banner,
  Icon,
  BlockStack,
} from "@shopify/polaris";

import PropTypes from "prop-types";
import { toTitleCase } from "../../utilities";
import { BankIcon } from "@shopify/polaris-icons";
import { useGetStripeListAllPaymentMethodsQuery } from "../../services/api";

const PaymentMethodList = (props) => {
  const {
    contactId,
    vendorId,
    stripeSetupIntent,
    handleRemovePaymentMethod,
    localShowLoadingSpinner,
  } = props;

  const {
    data: allPaymentMethods = [],
    isLoading: isLoadingAllPaymentMethods,
    isFetching: isFetchingAllPaymentMethods,
  } = useGetStripeListAllPaymentMethodsQuery(
    {
      contactId: contactId,
      vendorId: vendorId,
      onClientPortal: true,
    },
    { skip: !contactId || !vendorId }
  );

  const nextAction = stripeSetupIntent?.attributes?.next_action;

  const requiresActionBanner = () => {
    if (!nextAction) return;

    if (nextAction.verify_with_microdeposits) {
      return (
        <Banner
          title="Further action required"
          action={{
            content: "Verify account",
            url: nextAction.verify_with_microdeposits.hosted_verification_url,
            external: true,
          }}
          status="warning"
        >
          {nextAction.verify_with_microdeposits.microdeposit_type ===
            "descriptor_code" &&
            "A small amount was deposited in your account. To verify this account, enter the 6-digit code from that deposit."}
          {nextAction.verify_with_microdeposits.microdeposit_type ===
            "amounts" &&
            "Two micro-deposits were sent to your account. To verify this account, confirm the amount of these deposits."}
        </Banner>
      );
    } else {
      return (
        <Banner title="Further action required" status="warning">
          This account requires further verification. Please contact support for
          next steps.
        </Banner>
      );
    }
  };

  const combinedPaymentMethods = () => {
    if (stripeSetupIntent.attributes.stripe_status == "requires_action") {
      return [stripeSetupIntent.attributes.payment_method];
    } else {
      return allPaymentMethods;
    }
  };

  return (
    <Card>
      <BlockStack gap="400">
        <Text variant="headingMd" as="h6">
          Payment method
        </Text>
        <FormLayout>
          {stripeSetupIntent.attributes.stripe_status == "requires_action" &&
            requiresActionBanner()}

          <ResourceList
            resourceName={{ singular: "card", plural: "cards" }}
            items={combinedPaymentMethods()}
            loading={isLoadingAllPaymentMethods || isFetchingAllPaymentMethods}
            renderItem={(item) => {
              const { id, card, us_bank_account } = item;

              if (card) {
                const { brand, last4 } = card;
                const brandNameTitleCase = toTitleCase(brand);

                let cardLogo;
                try {
                  cardLogo = require(`../../assets/credit_cards/png/32/${brand}.png`);
                } catch (err) {
                  cardLogo = require(`../../assets/credit_cards/png/32/credit_card.png`);
                }

                const media = (
                  <Avatar
                    size={"extraSmall"}
                    source={cardLogo}
                    accessibilityLabel={`Icon for ${brandNameTitleCase} ending ${last4}`}
                  />
                );

                return (
                  <ResourceItem
                    id={id}
                    key={`key-${id}`}
                    media={media}
                    accessibilityLabel={`${brandNameTitleCase} ending ${last4}`}
                  >
                    <Text variant="bodyMd" fontWeight="bold" as="h3">
                      {`${brandNameTitleCase} (••••${last4})`}
                    </Text>
                  </ResourceItem>
                );
              } else if (us_bank_account) {
                const { bank_name, last4 } = us_bank_account;
                const bankNameTitleCase = toTitleCase(bank_name);

                const media = (
                  <Icon
                    source={BankIcon}
                    accessibilityLabel={`Icon for ${bankNameTitleCase} account ending ${last4}`}
                  />
                );

                return (
                  <ResourceItem
                    id={id}
                    key={`key-${id}`}
                    media={media}
                    accessibilityLabel={`${bankNameTitleCase} account ending ${last4}`}
                  >
                    <Text variant="bodyMd" fontWeight="bold" as="h3">
                      {`${bankNameTitleCase} (••••${last4})`}
                    </Text>
                  </ResourceItem>
                );
              }
            }}
          />

          <Button
            onClick={handleRemovePaymentMethod}
            loading={localShowLoadingSpinner}
          >
            Remove payment method
          </Button>
        </FormLayout>
      </BlockStack>
    </Card>
  );
};

PaymentMethodList.propTypes = {
  contactId: PropTypes.string,
  vendorId: PropTypes.string,
  stripeSetupIntent: PropTypes.object,
  handleRemovePaymentMethod: PropTypes.func,
  localShowLoadingSpinner: PropTypes.bool,
};

export default PaymentMethodList;
