import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";

import { useFetchContactQuery } from "../services/api";
import LoadingScreen from "../components/Auth/Loading";

const CurrentContactContext = React.createContext();

const CurrentContactProvider = ({ children }) => {
  const {
    user,
    isAuthenticated,
    isLoading: isLoadingAuth,
    loginWithRedirect,
  } = useAuth0();

  const [currentContact, setCurrentContact] = useState(null);
  const [contactPermissions, setContactPermissions] = useState({});
  const [isOrganizationAdmin, setIsOrganizationAdmin] = useState(false);
  const [isVendorAdmin, setIsVendorAdmin] = useState(false);
  const [isFinanceSpecialist, setIsFinanceSpecialist] = useState(false);
  const [isTeamAdmin, setIsTeamAdmin] = useState(false);
  const [isTeamMember, setIsTeamMember] = useState(false);
  const [isCustomer, setIsCustomer] = useState(false);

  const {
    data: contact,
    isLoading,
    isFetching,
    refetch: refetchCurrentContact,
    error,
  } = useFetchContactQuery(user?.email, {
    skip: !user || !isAuthenticated || isLoadingAuth,
  });

  useEffect(() => {
    if (error) {
      if (error.status == 401) {
        loginWithRedirect();
      }
    }
  }, [error, loginWithRedirect]);

  // Set Current Contact
  useEffect(() => {
    if (contact) {
      setCurrentContact(contact);
      setContactPermissions(contact.attributes.permissions);
    }
  }, [contact, isLoading]);

  useEffect(() => {
    if (isLoading) return;

    if (hasPermission("organization_admin")) setIsOrganizationAdmin(true);
    if (hasPermission("vendor_admin")) setIsVendorAdmin(true);
    if (hasPermission("team_finance_specialist")) setIsFinanceSpecialist(true);
    if (hasPermission("team_admin")) setIsTeamAdmin(true);
    if (hasPermission("team_member")) setIsTeamMember(true);

    if (contactPermissions == {}) return;

    const hasNoPermissions = Object.values(contactPermissions).every(
      (permission) => permission == false
    );

    if (hasNoPermissions) setIsCustomer(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactPermissions, hasPermission]);

  useEffect(() => {
    if (
      isOrganizationAdmin ||
      isVendorAdmin ||
      isFinanceSpecialist ||
      isTeamAdmin ||
      isTeamMember
    )
      setIsCustomer(false);
  }, [
    isOrganizationAdmin,
    isVendorAdmin,
    isFinanceSpecialist,
    isTeamAdmin,
    isTeamMember,
  ]);

  const hasPermission = useCallback(
    (permission, resource_id = null) => {
      if (contactPermissions[permission]) {
        if (permission == "organization_admin")
          return contactPermissions[permission];
        if (!resource_id) return contactPermissions[permission].length > 0;
        return contactPermissions[permission].includes(resource_id);
      }
      return false;
    },
    [contactPermissions]
  );

  if (isLoading || isFetching) return <LoadingScreen />;

  return (
    <CurrentContactContext.Provider
      value={{
        currentContactId: currentContact?.id ? currentContact.id : null,
        currentContact: currentContact,
        currentContactHasPermission: hasPermission,
        isCustomer: isCustomer,
        isOrganizationAdmin: isOrganizationAdmin,
        isVendorAdmin: isVendorAdmin,
        isFinanceSpecialist: isFinanceSpecialist,
        isTeamAdmin: isTeamAdmin,
        isTeamMember: isTeamMember,
        permissions: contactPermissions,
        emailVerified: user?.email_verified,
        isLoadingPermissions: isLoading || isFetching,
        refetchCurrentContact: refetchCurrentContact,
      }}
    >
      {children}
    </CurrentContactContext.Provider>
  );
};

CurrentContactProvider.propTypes = {
  children: PropTypes.any,
};

export { CurrentContactProvider, CurrentContactContext };
