import React, {
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";

import {
  FormLayout,
  TextField,
  Spinner,
  Form,
  Button,
  Banner,
  Link,
  BlockStack,
} from "@shopify/polaris";

import {
  useField,
  useSubmit,
  useDirty,
  makeCleanFields,
} from "@shopify/react-form";

import PropTypes from "prop-types";

import {
  useGetOpportunityCreditSubmissionQuery,
  useUpdateOpportunityCreditSubmissionMutation,
} from "../../services/api";

const PendingForm = forwardRef((props, ref) => {
  const {
    creditSubmissionId,
    creditSubmission: creditSubmissionObject = { attributes: {} },
    opportunityId,
    onUpdate = () => {},
    onEdit = () => {},
  } = props;

  const { data: creditSubmission = creditSubmissionObject, isLoading } =
    useGetOpportunityCreditSubmissionQuery(
      { id: creditSubmissionId, opportunityId: opportunityId },
      {
        skip: !creditSubmissionId,
      }
    );

  const [updateCreditSubmission, { isLoading: isUpdatingCreditSubmision }] =
    useUpdateOpportunityCreditSubmissionMutation();


  const fields = {
    submitted_at: useField({
      value: new Date(
        creditSubmission.attributes.submitted_at?.split("-")
      ).toLocaleDateString("en-CA"),
      validates: [],
    }),

    notes: useField({
      value: creditSubmission.attributes.notes,
      validates: [],
    }),
  };

  const pendingFormDirty = useDirty(fields);

  const { submit, errors } = useSubmit(async (form) => {
    return updateCreditSubmission({
      id: creditSubmissionId,
      opportunityId: opportunityId,
      ...form,
    })
      .then(() => {
        makeCleanFields(fields);
        onUpdate();
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
      });
  }, fields);

  useEffect(() => {
    onEdit(pendingFormDirty);
  }, [onEdit, pendingFormDirty]);

  useImperativeHandle(ref, () => ({
    handleSubmitForm() {
      submit();
    },
  }));

  const errorBanner = errors.length > 0 && (
    <BlockStack>
      <Banner tone="critical" title="Something went wrong">
        {errors.map((error, index) => {
          return (
            <p key={index}>
              {error.message}{" "}
              {error.account_id && (
                  <Link url={`/admin/vendors/${error.account_id}`}>
                    {error.account_name}
                  </Link>
                ) &&
                " vendor account."}
            </p>
          );
        })}
      </Banner>
    </BlockStack>
  );

  return isLoading ? (
    <Spinner />
  ) : (
    <Form onSubmit={submit}>
      <FormLayout>
        {errorBanner}
        <FormLayout.Group condensed>
          <TextField
            label={"Submitted date"}
            type="date"
            {...fields.submitted_at}
          />
        </FormLayout.Group>

        <TextField
          label="Notes"
          multiline={5}
          autoComplete="off"
          {...fields.notes}
        />
        {pendingFormDirty && (
          <Button submit variant="primary">
            Save changes
          </Button>
        )}
      </FormLayout>
    </Form>
  );
});
PendingForm.displayName = "PendingForm";

PendingForm.propTypes = {
  creditSubmissionId: PropTypes.string,
  creditSubmission: PropTypes.object,
  opportunityId: PropTypes.string,
  onEdit: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default PendingForm;
