import React, { ReactNode, useState, useContext, createContext } from 'react';
import { Toast } from '@shopify/polaris';

// Context for the toast
const ToastContext = createContext<{ showToastWithMessage: (message: string) => void } | undefined>(undefined);

// Custom hook for toast logic
export function useToast() {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error(`useToast must be used within a ToastProvider`);
  }
  return context;
}

// Provider component that also includes Toast markup
interface ToastProviderProps {
  children: ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const showToastWithMessage = (message: string) => {
    setToastMessage(message);
    setShowToast(true);
  };

  const handleDismissToast = () => {
    setShowToast(false);
    setToastMessage('');
  };

  const toastMarkup = showToast ? (
    <Toast content={toastMessage} onDismiss={handleDismissToast} />
  ) : null;

  return (
    <ToastContext.Provider value={{ showToastWithMessage }}>
      {children}
      {toastMarkup}
    </ToastContext.Provider>
  );
};
