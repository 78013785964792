import React, { useState, useCallback, useContext, useEffect } from "react";

import {
  Text,
  TextField,
  RangeSlider,
  BlockStack,
  Checkbox,
  FormLayout,
  Button,
} from "@shopify/polaris";
import { asChoiceField, useReset } from "@shopify/react-form";

import { CurrentContactContext } from "../../contexts/Contact";

import Opportunity from "../../types/Opportunity";
import FinancingOption from "../../types/FinancingOption";
import { LockFilledIcon, LockIcon, ResetIcon } from "@shopify/polaris-icons";

type InterestRateFieldsProps = {
  fields: any;
  // sellRate: number;
  // setSellRate: Function;
  // opportunity: Opportunity;
  // isSolvingBasePayment: boolean;
  solvingMode: string;
};

const InterestRateFields = (props: InterestRateFieldsProps) => {
  const {
    fields,
    // sellRate,
    // setSellRate,
    solvingMode,
  } = props;

  const { isOrganizationAdmin, currentContactHasPermission } = useContext(
    CurrentContactContext
  );

  // Set to locked by default
  const [customerRateLocked, setCustomerRateLocked] = useState(true);

  useEffect(() => {
    if(fields.sell_rate.value != fields.customer_interest_rate.value) {
      setCustomerRateLocked(false)
    }
  }, [])

  const handleChangeCustomerInterestRate = useCallback(
    // (newValue: number) => setCustomerInterestRate(newValue / 100),
    (newValue: number) =>
      fields.customer_interest_rate.onChange(newValue / 100),
    []
  );

  const handleChangeCustomerInterestRateTextField = useCallback(
    (newValue: any) => {
      console.log("changing customer interest rate text field");
      if (newValue == "-") return;
      // setCustomerInterestRate(newValue);
      fields.customer_interest_rate.onChange(newValue);
    },
    []
  );

  const handleChangeSellRate = useCallback(
    // (newValue: number) => setSellRate(newValue / 100),
    (newValue: number) => {
      fields.sell_rate.onChange(newValue / 100);
      if (customerRateLocked) {
        handleChangeCustomerInterestRate(newValue);
      }
    },
    [customerRateLocked]
  );

  const handleChangeSellRateTextField = useCallback(
    (newValue: any) => {
      if (newValue == "-") return;
      // setSellRate(newValue);
      fields.sell_rate.onChange(newValue);
      if (customerRateLocked) {
        handleChangeCustomerInterestRateTextField(newValue);
      }
    },
    [customerRateLocked]
  );

  const resetFields = customerRateLocked
    ? useReset(fields)
    : useReset(fields.sell_rate);

  return (
    // <BlockStack>
    // </BlockStack>
    <FormLayout>
      {(isOrganizationAdmin ||
        currentContactHasPermission("finance_specialist")) && (
        <RangeSlider
          label="Sell rate"
          min={0}
          max={30 * 100}
          step={1}
          value={fields.sell_rate.value * 100}
          onChange={handleChangeSellRate}
          error={fields.sell_rate.error}
          prefix={
            <div className="FortCalculator__SellRateTextField">
              <TextField
                label=""
                // value={sellRate.toString()}
                value={fields.sell_rate.value.toString()}
                onChange={handleChangeSellRateTextField}
                step={0.01}
                suffix="%"
                autoComplete="off"
                type="number"
              />
            </div>
          }
          suffix={
            <Button
              accessibilityLabel="Reset sell rate to default"
              icon={ResetIcon}
              disabled={!fields.sell_rate.dirty}
              onClick={resetFields}
            />
          }
        />
      )}

      <RangeSlider
        label={"Customer interest rate"}
        min={0}
        max={30 * 100}
        step={1}
        // value={customerInterestRate * 100}
        value={fields.customer_interest_rate.value * 100}
        onChange={handleChangeCustomerInterestRate}
        error={fields.customer_interest_rate.error}
        disabled={solvingMode === "rate" || customerRateLocked}
        prefix={
          <div className="FortCalculator__SellRateTextField">
            <TextField
              label=""
              // value={customerInterestRate.toString()}
              value={fields.customer_interest_rate.value.toString()}
              onChange={handleChangeCustomerInterestRateTextField}
              disabled={solvingMode === "rate"}
              step={0.01}
              suffix="%"
              autoComplete="off"
              type="number"
            />
          </div>
        }
        suffix={
          <Button
            onClick={() => {
              handleChangeCustomerInterestRate(fields.sell_rate.value * 100);
              setCustomerRateLocked(!customerRateLocked);
            }}
            accessibilityLabel="Lock customer rate to sell rate"
            pressed={customerRateLocked}
            icon={customerRateLocked ? LockFilledIcon : LockIcon}
          />
        }
      />

      <FormLayout.Group condensed>
        <Checkbox
          label="Make customer rate visible"
          // checked={clientRateVisible}
          // onChange={handleClientRateVisibleChange}
          {...asChoiceField(fields.interest_rate_visible)}
        />
      </FormLayout.Group>
    </FormLayout>
  );
};

export default InterestRateFields;
