export const invertMapKeysAndValues = (stringMap) => {
  const newStringMap = {};
  for (let key in stringMap) {
    const val = stringMap[key];
    newStringMap[val] = key;
  }
  return newStringMap;
};

export const returnPublicRoutes = () => {
  const publicRoutes = ["/share", "/section-179-calculator", "/calculator"];
  return publicRoutes.some(route => location.pathname.includes(route));
};
