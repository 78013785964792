import React, { useCallback, useState } from "react";

import { Text, Card, InlineStack, BlockStack, Box } from "@shopify/polaris";
import pluralize from "../../pluralizeConfig";

import { formatCurrency } from "../../utilities";

import ScheduleBarChart from "../FinancingOption/ScheduleBarChart";

interface PurchaseOptionSegment {
  count: number;
  value?: number;
}

type Props = {
  invoiceTotal: number;
  termLength: number;
  netPayment: number;
  totalFinanceAmount: number;
  downPayment: number;
  purchaseOptionSegments: PurchaseOptionSegment[];
  amortizationSchedulePayment: number;
  period: string;
  purchaseOptionType: string;
  discount: number;
};

const OverviewCard = (props: Props) => {
  const {
    invoiceTotal,
    termLength,
    netPayment,
    totalFinanceAmount,
    downPayment,
    purchaseOptionSegments,
    amortizationSchedulePayment,
    period = "monthly",
    purchaseOptionType,
    discount,
  } = props;

  const VerticalDivider = () => (
    <div
      style={{
        borderLeft: "var(--p-border-width-025) solid var(--p-color-border)",
        height: "48px",
        margin: "0 1rem",
      }}
    />
  );

  const periodAsLabel = useCallback(() => {
    switch (period) {
      case "monthly":
        return "Month";
      case "quarterly":
        return "Quarter";
      case "anually":
        return "Year";
      default:
        return "Period";
    }
  }, [period]);

  const discountAmount = formatCurrency((invoiceTotal || 0) * (discount || 0));

  return (
    <Card>
      {purchaseOptionType !== "net_terms" ? (
        <InlineStack blockAlign="center" align="space-evenly" wrap={false}>
          <BlockStack gap="200">
            <Text as="p" tone="subdued">
              Total amount of invoice(s)
            </Text>

            <Text variant="headingXl" as="h4">
              {formatCurrency(totalFinanceAmount)}
            </Text>
          </BlockStack>

          <VerticalDivider />

          <BlockStack gap="200">
            <Text as="p" tone="subdued">
              Total term length
            </Text>

            <Text variant="headingXl" as="h4">
              {termLength} {pluralize(periodAsLabel(), termLength)}
            </Text>
          </BlockStack>

          <VerticalDivider />

          <Box maxWidth="180px">
            <ScheduleBarChart
              wrapWidth={true}
              termLength={termLength}
              downPayment={downPayment}
              customSegments={purchaseOptionSegments}
              payment={amortizationSchedulePayment}
            />
          </Box>
        </InlineStack>
      ) : (
        <InlineStack blockAlign="center" align="space-evenly" wrap={false}>
          <BlockStack gap="200">
            <Text as="p" tone="subdued">
              Total amount of invoice(s)
            </Text>

            <Text variant="headingXl" as="h4">
              {formatCurrency(invoiceTotal)}
            </Text>
          </BlockStack>

          <VerticalDivider />

          <BlockStack gap="200">
            <Text as="p" tone="subdued">
              Discount amount
            </Text>

            <Text variant="headingXl" as="h4">
              {discountAmount}
            </Text>
          </BlockStack>

          <VerticalDivider />

          <BlockStack gap="200">
            <Text as="p" tone="subdued">
              Total amount of payments
            </Text>

            <Text variant="headingXl" as="h4">
              {formatCurrency(netPayment)}
            </Text>
          </BlockStack>
        </InlineStack>
      )}
    </Card>
  );
};

export default OverviewCard;
