import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "/api/v1",
  headers: {
    "Content-type": "application/json",
  },
});

// No longer have the concept of a current team

// axiosInstance.interceptors.request.use((config) => {
//   const storedTeamId = localStorage.getItem("current-team-id");

//   console.log("storedTeamId: ", storedTeamId);

//   if (storedTeamId) {
//     config.headers.common["current-team-id"] = storedTeamId;
//   }

//   return config;
// });

export default axiosInstance;
