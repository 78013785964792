import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import AdminClientIndex from "./Admin/Client/Index";
import AdminClientShow from "./Admin/Client/Show";

import AdminContacts from "./Admin/Contact/Index";

import AdminLenderIndex from "./Admin/Lender/Index";
import AdminLenderShow from "./Admin/Lender/Show";

import AdminOpportunities from "./Admin/Opportunity/Index";

import AdminTeams from "./Admin/Team/Index";
import AdminTeamShow from "./Admin/Team/Show";

import AdminVendorIndex from "./Admin/Vendor/Index";
import AdminVendorShow from "./Admin/Vendor/Show";

import AdminVendorPrograms from "./Admin/VendorProgram/Index";
import AdminVendorProgramsShow from "./Admin/VendorProgram/Show";

import AdminDocuments from "./Admin/Document/Index";
import AdminDocumentShow from "./Admin/Document/Show";

import AdminSettings from "./Admin/Settings/Index";

const Admin = (props) => {
  return (
    <>
      <Switch>
        <Route exact path="/admin">
          <Redirect to="/admin/opportunities" />
        </Route>
        <Route
          exact
          path="/admin/clients"
          render={(props) => <AdminClientIndex {...props} />}
        />
        <Route
          exact
          path="/admin/clients/:id"
          render={(props) => <AdminClientShow {...props} />}
        />
        <Route
          exact
          path="/admin/vendors"
          render={(props) => <AdminVendorIndex {...props} />}
        />
        <Route
          exact
          path="/admin/vendors/:id"
          render={(props) => <AdminVendorShow {...props} />}
        />
        <Route
          exact
          path="/admin/lenders"
          render={(props) => <AdminLenderIndex {...props} />}
        />
        <Route
          exact
          path="/admin/lenders/:id"
          render={(props) => <AdminLenderShow {...props} />}
        />
        <Route
          exact
          path="/admin/vendor_programs/:id"
          render={(props) => <AdminVendorProgramsShow {...props} />}
        />
        <Route
          exact
          path="/admin/vendor_programs"
          render={(props) => <AdminVendorPrograms {...props} />}
        />
        <Route
          exact
          path="/admin/opportunities"
          render={(props) => <AdminOpportunities {...props} />}
        />
        <Route
          exact
          path="/admin/teams"
          render={(props) => <AdminTeams {...props} />}
        />
        <Route
          exact
          path="/admin/teams/:id"
          render={(props) => <AdminTeamShow {...props} />}
        />
        <Route
          exact
          path="/admin/contacts"
          render={(props) => <AdminContacts {...props} />}
        />

        <Route
          exact
          path="/admin/documents"
          render={(props) => <AdminDocuments {...props} />}
        />

        <Route
          exact
          path="/admin/documents/:id"
          render={(props) => <AdminDocumentShow {...props} />}
        />

        <Route
          exact
          path="/admin/settings"
          render={(props) => <AdminSettings {...props} />}
        />
      </Switch>
    </>
  );
};

export default Admin;
