import React, { useState, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useForm, useField, notEmpty } from "@shopify/react-form";
import {
  Card,
  Page,
  Layout,
  Modal,
  FormLayout,
  TextField,
  Toast,
} from "@shopify/polaris";

import { CurrentContactContext } from "../../../contexts/Contact";

import ContactAdminList from "../../../components/Contact/AdminList";
import FadeIn from "../../../components/FadeIn";
import LoadingScreen from "../../../components/Auth/Loading";

import { validateEmail, validatePhone } from "../../../utilities";
import {
  useCreateAdminContactMutation,
  useUpdateAdminContactMutation,
  useCreateContactCsvMutation,
  baseQuery,
} from "../../../services/api";

const AdminContacts = () => {
  const history = useHistory();

  const { currentContact, isOrganizationAdmin } = useContext(CurrentContactContext)

  const [showContactFormModal, setShowContactFormModal] = useState(false);
  const [viewingContact, setViewingContact] = useState(false);
  const [orgAdmin, setOrgAdmin] = useState(false);
  const handleChange = useCallback(
    (newChecked) => setOrgAdmin(newChecked),
    [],
  );

  const [
    createContactCsv,
    { isLoading: isCreatingContactCsv },
  ] = useCreateContactCsvMutation();

  const [createAdminContact, { isLoading: isCreatingContact }] =
    useCreateAdminContactMutation();

  const [updateAdminContact, { isLoading: isUpdatingContact }] =
    useUpdateAdminContactMutation();

  const [editingContact, setEditingContact] = useState();

  const {
    fields,
    submit,
    submitting,
    reset,
    submitErrors,
    makeClean,
    dirty: contactFormDirty,
  } = useForm({
    fields: {
      first_name: useField({
        value: editingContact?.attributes?.first_name || "",
        validates: [notEmpty("First name is required")],
      }),
      last_name: useField({
        value: editingContact?.attributes?.last_name || "",
        validates: [notEmpty("Last name is required")],
      }),
      email: useField({
        value: editingContact?.attributes?.email || "",
        validates: [
          notEmpty("Email is required"),
          (email) => {
            if (email.length <= 3 || !validateEmail(email)) {
              return "Email must be valid";
            }
          },
        ],
      }),
      phone: useField({
        value: editingContact?.attributes?.phone || "",
        validates: [notEmpty("Phone number is required"),
          (phone) => {
            if (!validatePhone(phone)) {
              return "Phone must be valid"
            }
          }
        ]
      })
    },
    async onSubmit(form) {
      if (editingContact) {
        return updateAdminContact({ contactId: editingContact.id, ...form })
          .unwrap()
          .then(() => {
            setShowToast(true);
            setToastMessage("Contact saved.");
            setEditingContact();
            handleCloseModal();
            makeClean();

            return { status: "success" };
          })
          .catch((error) => console.log(error));
      } else {
        return createAdminContact({ orgAdmin, ...form })
          .unwrap()
          .then(() => {
            setShowToast(true);
            setToastMessage("Contact added.");
            handleCloseModal();
            makeClean();

            return { status: "success" };
          })
          .catch((error) => console.log(error));
      }
    },
  });

  const manualReset = () => {
    fields.first_name = ""
  }

  const handleCloseModal = () => {
    if(viewingContact) setEditingContact();
    setViewingContact(false);
    setShowContactFormModal(false);
  };

  const handleContactRowClick = (contact) => {
    setViewingContact(true);
    setEditingContact(contact);
    setShowContactFormModal(true);
  };

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const toggleShowToast = useCallback(
    () => setShowToast((active) => !active),
    []
  );
  const handleDismissToast = () => {
    toggleShowToast();
    setToastMessage("");
  };
  const toastMarkup = showToast ? (
    <Toast content={toastMessage} onDismiss={handleDismissToast} />
  ) : undefined;

  const contactFormModal = (
    <Modal
      open={showContactFormModal}
      title={editingContact ? "Edit contact" : "Add contact"}
      primaryAction={{
        content: editingContact ? "Save" : "Add",
        onAction: submit,
        disabled: !contactFormDirty,
        loading: isCreatingContact || isUpdatingContact,
      }}
      onClose={handleCloseModal}
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <TextField label="First name" {...fields.first_name} />
            <TextField label="Last name" {...fields.last_name} />
          </FormLayout.Group>
          <TextField
            label="Email"
            type="email"
            {...fields.email}
            disabled={editingContact}
          />
          <TextField
            label="Phone number"
            {...fields.phone}
          />
          {/*
            <Checkbox
              label="Organization Admin"
              checked={orgAdmin}
              onChange={handleChange}
            />
          */}
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  const exportContactCsv = async () => {
  
    const pathname = `/csv_exports/contacts`;
    const result = await baseQuery()({
      url: pathname,
      method: "POST",
      data: {},
      responseType: "blob",
    });

    let filename = `Contacts`;
    let file = new File([result.data], `${filename}.csv`, {
      type: "application/pdf",
    });
    let fileUrl = window.URL.createObjectURL(file);

    let fileLink = document.createElement("a");
    fileLink.href = fileUrl;
    fileLink.download = `${filename}.csv`;
    fileLink.click();
  }

  const PageMarkup = (
    <FadeIn fadeIn>
      <Page
        fullWidth
        title="Contacts"
        secondaryActions={[
          {
            content: "Add contact",
            accessibilityLabel: "Add contact",
            onAction: () => {
              setShowContactFormModal(true);
            },
          },
          {
            content: "Export CSV",
            accessibilityLabel: "Export CSV",
            disabled: !isOrganizationAdmin,
            onAction: () => {
              exportContactCsv()
            }
          }
        ]}
      >
        <Layout>
          <Layout.Section>
            <Card padding="0">
              <ContactAdminList onRowClick={handleContactRowClick} />
            </Card>
          </Layout.Section>
        </Layout>
        {contactFormModal}
        {toastMarkup}
      </Page>
    </FadeIn>
  );

  return currentContact ? PageMarkup : <LoadingScreen />;
};

export default AdminContacts;
