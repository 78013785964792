import { format } from "timeago.js";

const stateOptions = [
  "Alaska",
  "Alabama",
  "Arkansas",
  "American Samoa",
  "Arizona",
  "California",
  "Colorado",
  "Connecticut",
  "District of Columbia",
  "Delaware",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Iowa",
  "Idaho",
  "Illinois",
  "Indiana",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Massachusetts",
  "Maryland",
  "Maine",
  "Michigan",
  "Minnesota",
  "Missouri",
  "Mississippi",
  "Montana",
  "North Carolina",
  "North Dakota",
  "Nebraska",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "Nevada",
  "New York",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Virginia",
  "Virgin Islands",
  "Vermont",
  "Washington",
  "Wisconsin",
  "West Virginia",
  "Wyoming",
];

const stateOptionsShort = [
  "AK",
  "AL",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
  "AB",
  "BC",
  "MB",
  "NB",
  "NL",
  "NS",
  "NT",
  "NU",
  "ON",
  "PE",
  "QC",
  "SK",
  "YT",
];

const countryOptions = ["USA", "Canada"];

const equipmentProductTypeOptions = [
  "Biomass Processing",
  "Building Structures",
  "Energy",
  "Gaming",
  "Gym",
  "Heavy Machinery",
  "Industrial",
  "Invasive Medical Equipment",
  "IT Equipment",
  "Lasers",
  "Machinery",
  "Material Handling",
  "Medical",
  "Office",
  "Other Essential Use Assets",
  "Packaging/Labeling",
  "Resources",
  "Restaurant",
  "Service",
  "Scientific",
  "Soft cost",
  "Software / SaaS",
  "Titled Asset",
  "Turf",
  "UG",
  "White Goods",
  "Mobile devices",
  "Turbines",
];

/**
 * Converts a date string from the server into a JavaScript Date object, ensuring that the date remains the same regardless of the client's timezone.
 * This function assumes the server date is in the format "YYYY-MM-DD".
 * @param serverDateString - The date string from the server.
 * @returns {Date} A JavaScript Date object representing the same calendar date that was provided in the server date string.
 */
const convertServerDate = (serverDateString: string): Date => {
  if (!serverDateString) {
    return null;
  }

  const date = new Date(serverDateString);
  const userTimezoneOffset = date.getTimezoneOffset() * 60000; // Convert offset to milliseconds
  const correctedDate = new Date(date.getTime() + userTimezoneOffset);

  return correctedDate;
};

/**
 * Copies the given text to the clipboard.
 * @param text - The text to copy to the clipboard.
 * @returns A Promise that resolves when the text has copied to the clipboard.
 */
const copyTextToClipboard = (text: string) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  return navigator.clipboard.writeText(text);
};

const fallbackCopyTextToClipboard = (text: string) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
};

/**
 * Formats a human-friendly time difference between the current date and the given date.
 * If the time difference is greater than one month, the date is formatted using the `formatShortDate` function.
 * Otherwise, the date is formatted using the `format` function with the "en_US" locale.
 * @param dateTime The date to format the time difference for.
 * @returns A human-friendly string representing the time difference between the current date and the given date.
 */
const formatHumanFriendlyTimeDifference = (dateTime: Date) => {
  const oneMonthAgo = 30 * 24 * 60 * 60 * 1000;
  const today = new Date();
  const timeDifference = today.getTime() - dateTime.getTime();

  if (timeDifference > oneMonthAgo) {
    return formatShortDate(dateTime);
  } else {
    return format(dateTime, "en_US");
  }
};

/**
 * Formats a Date object into a long date and time string.
 * @param datetime - The Date object to format.
 * @returns A string in the format "Weekday, Month Day, Year at Hour:Minute".
 */
const formatLongDateTime = (datetime: Date) => {
  const dateString = datetime.toLocaleString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const timeString = datetime.toLocaleString("en-US", {
    hour: "numeric",
    minute: "2-digit",
  });

  return `${dateString} at ${timeString}`;
};

/**
 * Formats a date object into a short date and time string.
 * @param datetime - The date object to format.
 * @returns A string in the format of "Weekday, Month Day Year at Hour:Minute".
 */
const formatShortDateTime = (datetime: Date) => {
  const dateString = datetime.toLocaleString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  const timeString = datetime.toLocaleString("en-US", {
    hour: "numeric",
    minute: "2-digit",
  });

  return `${dateString} at ${timeString}`;
};

/**
 * Formats a given date into a short date string with the format "weekday, month day, year".
 * @param date - The date to format.
 * @returns A string representing the formatted date.
 */
const formatShortDate = (date: Date) => {
  const formattedDate = date.toLocaleString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  return formattedDate;
};

/**
 * Formats a Date object into a string with the format "MM/DD/YY".
 * @param {Date} date - The Date object to format.
 * @returns {string} The formatted date string.
 */
const formatNumericDate = (date: Date) => {
  return date.toLocaleString("en-US", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  });
};

/**
 * Formats a given number of milliseconds into a string representation of minutes and seconds.
 * @param milliseconds - The number of milliseconds to format.
 * @returns A string representation of the given milliseconds in the format "MM:SS".
 */
const formatMillisecondsToMinutes = (milliseconds: number) => {
  const minutes = Math.floor(milliseconds / 60000);
  const seconds = Math.round((milliseconds % 60000) / 1000);
  return seconds == 60
    ? minutes + 1 + ":00"
    : minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
};

/**
 * Formats a given amount as a currency string in USD.
 * If the amount is not numeric or not a valid currency, returns "$ --".
 * @param amount - The amount to format as currency.
 * @param style - The style to format the currency.
 * @returns The formatted currency string.
 */
const formatCurrency = (
  amount: string | number,
  style: "currency" | "decimal" | "percent" = "currency"
) => {
  if (!isNumeric(amount)) {
    if (!validateCurrency(String(amount))) {
      return "$ --";
    }

    return amount;
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style,
    currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  });

  return formatter.format(Number(amount));
};

function isDateNotPast(value: string) {
  // Extract year, month, and day from the input value
  const [year, month, day] = value.split("-").map((num) => parseInt(num, 10));

  // Create a date object using the local timezone
  // Note: Month is 0-indexed, so subtract 1
  const inputDate = new Date(year, month - 1, day);

  // Get the current date and time, then adjust to the start of the day in the local timezone
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Compare the two dates
  return inputDate >= today;
}

/**
 * Checks if a value is numeric.
 * @param value - The value to check.
 * @returns True if the value is numeric, false otherwise.
 */
function isNumeric(value: string | number): boolean {
  return !isNaN(value as number) && !isNaN(parseFloat(value as string));
}

/**
 * Validates a currency value and returns it formatted as a string with two decimal places.
 * @param value - The currency value to validate and format.
 * @returns The formatted currency value as a string with two decimal places, or null if the input is invalid.
 */
const validateCurrency = (value: string) => {
  if (!value) {
    return null;
  }

  const valueFormatted = value.replace(/[$,]+/g, "");
  // values less than $1,000,000
  // var regex = /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;
  // values including greater than $1,000,000
  const regex = /^[0-9]\d*(((,\d{3})*)?(\.\d{0,2})?)$/;
  if (regex.test(valueFormatted)) {
    //Input is valid, check the number of decimal places
    const twoDecimalPlaces = /\.\d{2}$/g;
    const oneDecimalPlace = /\.\d{1}$/g;
    const noDecimalPlacesWithDecimal = /\.\d{0}$/g;

    if (valueFormatted.match(twoDecimalPlaces)) {
      //all good, return as is
      return valueFormatted;
    }
    if (valueFormatted.match(noDecimalPlacesWithDecimal)) {
      //add two decimal places
      return valueFormatted + "00";
    }
    if (valueFormatted.match(oneDecimalPlace)) {
      //ad one decimal place
      return valueFormatted + "0";
    }
    //else there is no decimal places and no decimal
    return valueFormatted + ".00";
  }
  return null;
};

/**
 * Checks if the given value is a valid currency.
 * @param value - The value to be validated.
 * @returns If the value is not a valid currency, returns a string "Please enter a valid dollar amount".
 */
const isValidCurrency = (value: string) => {
  if (!validateCurrency(value)) {
    return "Please enter a valid dollar amount";
  }
};

/**
 * Converts a string to sentence case.
 * @param value - The string to convert.
 * @returns The converted string.
 */
const toSentenceCase = (value: string) => {
  function firstLetterUpper(value: string) {
    return value.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) {
      return c.toUpperCase();
    });
  }

  return firstLetterUpper(value);
};

/**
 * Converts a string to title case.
 * @param value - The string to convert.
 * @returns The title case version of the input string.
 */
const toTitleCase = (value: string): string => {
  let result = "";
  let capitalizeNext = true;
  for (let i = 0; i < value.length; i++) {
    const char = value.charAt(i);
    if (capitalizeNext) {
      result += char.toUpperCase();
      capitalizeNext = false;
    } else {
      result += char.toLowerCase();
    }
    if (char === " ") {
      capitalizeNext = true;
    }
  }
  return result;
};

/**
 * Generates a random UUID v4 string.
 * @returns {string} A UUID v4 string.
 */
const uuidv4 = (): string => {
  const baseString = "10000000-1000-4000-8000-100000000000";
  return baseString.replace(/[018]/g, (c: string): string => {
    const charCode = parseInt(c, 10);
    return (
      charCode ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (charCode / 4)))
    ).toString(16);
  });
};

enum ConversionType {
  TO_NAME = 1,
  TO_ABBREVIATED = 2,
}
/**
 * Converts a state or province name to its abbreviated form or vice versa.
 * @param input - The name of the state or province to convert.
 * @param to - The type of conversion to perform. Defaults to `ConversionType.TO_NAME`.
 * @returns The converted state or province name in its abbreviated or full form, or `undefined` if the input is invalid.
 */
function convertStateName(
  input: string,
  to: ConversionType = ConversionType.TO_NAME
): string | undefined {
  const states: Record<string, string> = {
    Alabama: "AL",
    Alaska: "AK",
    "American Samoa": "AS",
    Arizona: "AZ",
    Arkansas: "AR",
    "Armed Forces Americas": "AA",
    "Armed Forces Europe": "AE",
    "Armed Forces Pacific": "AP",
    California: "CA",
    Colorado: "CO",
    Connecticut: "CT",
    Delaware: "DE",
    "District Of Columbia": "DC",
    Florida: "FL",
    Georgia: "GA",
    Guam: "GU",
    Hawaii: "HI",
    Idaho: "ID",
    Illinois: "IL",
    Indiana: "IN",
    Iowa: "IA",
    Kansas: "KS",
    Kentucky: "KY",
    Louisiana: "LA",
    Maine: "ME",
    "Marshall Islands": "MH",
    Maryland: "MD",
    Massachusetts: "MA",
    Michigan: "MI",
    Minnesota: "MN",
    Mississippi: "MS",
    Missouri: "MO",
    Montana: "MT",
    Nebraska: "NE",
    Nevada: "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    "Northern Mariana Islands": "NP",
    Ohio: "OH",
    Oklahoma: "OK",
    Oregon: "OR",
    Pennsylvania: "PA",
    "Puerto Rico": "PR",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    Tennessee: "TN",
    Texas: "TX",
    "US Virgin Islands": "VI",
    Utah: "UT",
    Vermont: "VT",
    Virginia: "VA",
    Washington: "WA",
    "West Virginia": "WV",
    Wisconsin: "WI",
    Wyoming: "WY",
  };

  const provinces: Record<string, string> = {
    Alberta: "AB",
    "British Columbia": "BC",
    Manitoba: "MB",
    "New Brunswick": "NB",
    Newfoundland: "NF",
    "Northwest Territory": "NT",
    "Nova Scotia": "NS",
    Nunavut: "NU",
    Ontario: "ON",
    "Prince Edward Island": "PE",
    Quebec: "QC",
    Saskatchewan: "SK",
    Yukon: "YT",
  };

  const regions = { ...states, ...provinces };

  if (to === ConversionType.TO_ABBREVIATED) {
    const formattedInput = input.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
    return regions[formattedInput];
  } else if (to === ConversionType.TO_NAME) {
    const upperInput = input.toUpperCase();
    return Object.keys(regions).find((key) => regions[key] === upperInput);
  }
}

/**
 * Validates the given email address based on a regex pattern.
 *
 * @function
 * @param {string} email - The email address to be validated.
 * @returns {boolean} Returns `true` if the email is valid, otherwise `false`.
 * @example
 *
 * validateEmail("test@example.com"); // returns true
 * validateEmail("invalid-email");    // returns false
 */
const validateEmail = (email: string): boolean => {
  const regexPattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexPattern.test(String(email).toLowerCase());
};

const validatePhone = function (phone: string) {
  const phoneRegex =
    /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d*)\)?)[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?)+)(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;
  return phoneRegex.test(phone);
};

/**
 * Capitalizes the first letter of a string.
 * @param string - The input string to capitalize.
 * @returns The input string with the first letter capitalized.
 */
const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const toHumanReadableFinanceType = {
  standard: "Standard financing",
  fair_market_value: "Fair market value",
  net_terms: "Net terms",
  subscription: "Subscription license agreement",
  dollar_out_lease: "Dollar out lease",
  saas: "Software as a service",
  rental: "Rental",
};

const toFinanceTypeSlug = {
  "Standard financing": "standard",
  "Fair market value": "fair_market_value",
  "Net terms": "net_terms",
  "Subscription license agreement": "subscription",
  "Dollar out lease": "dollar_out_lease",
  "Software as a service": "saas",
  Rental: "rental",
};

const formatDateOptions = () => {
  const setToMidnight = (date: Date) => {
    date.setHours(0, 0, 0, 0);
    return date.toISOString();
  };

  const today = setToMidnight(new Date());
  const sevenDaysAgo = setToMidnight(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  );
  const thirtyDaysAgo = setToMidnight(
    new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
  );
  const ninetyDaysAgo = setToMidnight(
    new Date(Date.now() - 90 * 24 * 60 * 60 * 1000)
  );
  const oneYearAgo = setToMidnight(
    new Date(Date.now() - 365 * 24 * 60 * 60 * 1000)
  );

  return [
    {
      label: "Today",
      value: today,
    },
    {
      label: "Last 7 days",
      value: sevenDaysAgo,
    },
    {
      label: "Last 30 days",
      value: thirtyDaysAgo,
    },
    {
      label: "Last 90 days",
      value: ninetyDaysAgo,
    },
    {
      label: "Last 12 months",
      value: oneYearAgo,
    },
  ];
};
const dateOptions = formatDateOptions();

const taskLabels = [
  // "Quote",
  // "Purchase Option",
  // "Pre-approved",
  // "Credit review",
  // "Signer",
  // "File upload",
  // "Envelope",
  // "Payment auth",
  // "Invoice",
  // "Transactions"
];

const taskNameToLabel = {
  quote: "Quote",
  purchase_option: "Purchase Option",
  purchase_option_approved: "Pre-approved",
  credit_review: "Credit review",
  signer: "Signer",
  file_upload: "File upload",
  envelope: "Envelope",
  payment_auth: "Payment auth",
  invoice: "Invoice",
  transaction: "Transactions",
};

const taskLabelToName = {
  Quote: "quote",
  "Purchase Option": "purchase_option",
  "Pre-approved": "purchase_option_approved",
  "Credit review": "credit_review",
  Signer: "signer",
  "File upload": "file_upload",
  Envelope: "envelope",
  "Payment auth": "payment_auth",
  Invoice: "invoice",
  Transactions: "transaction",
};

const taskNameToChoices = {
  quote: [
    { label: "Present", value: "Complete" },
    { label: "Not present", value: "None" },
  ],
  purchase_option: [
    { label: "Selected", value: "Complete" },
    { label: "Not selected", value: "None" },
  ],
  purchase_option_approved: [
    { label: "Approved", value: "Complete" },
    { label: "Not approved", value: "None" },
  ],
  credit_review: [
    { label: "Approved", value: "Complete" },
    { label: "Not approved", value: "None" },
  ],
  signer: [
    { label: "Set", value: "Complete" },
    { label: "Not set", value: "None" },
  ],
  file_upload: [
    { label: "Complete", value: "Complete" },
    { label: "Not complete", value: "Incomplete" },
    { label: "None", value: "None" },
  ],
  envelope: [
    { label: "Complete", value: "Complete" },
    { label: "Not complete", value: "Incomplete" },
    { label: "None", value: "None" },
  ],
  payment_auth: [
    { label: "Complete", value: "Complete" },
    { label: "Not complete", value: "None" },
  ],
  invoice: [
    { label: "Present", value: "Complete" },
    { label: "Not present", value: "None" },
  ],
  transaction: [
    { label: "Complete", value: "Complete" },
    { label: "Not complete", value: "Incomplete" },
    { label: "None", value: "None" },
  ],
};

const showPaymentScheduleTypes = ["Net terms", "Standard financing", "Rental", "Dollar out lease"];

export {
  capitalizeFirstLetter,
  convertStateName,
  convertServerDate,
  copyTextToClipboard,
  formatCurrency,
  formatHumanFriendlyTimeDifference,
  formatLongDateTime,
  formatShortDate,
  formatShortDateTime,
  formatNumericDate,
  formatMillisecondsToMinutes,
  isDateNotPast,
  isValidCurrency,
  isNumeric,
  toSentenceCase,
  toTitleCase,
  validateCurrency,
  validateEmail,
  validatePhone,
  uuidv4,
  countryOptions,
  dateOptions,
  equipmentProductTypeOptions,
  stateOptions,
  stateOptionsShort,
  toHumanReadableFinanceType,
  toFinanceTypeSlug,
  taskNameToLabel,
  taskLabels,
  taskLabelToName,
  taskNameToChoices,
  showPaymentScheduleTypes,
};
