import React, { useState, useEffect } from "react";

import {
  Box,
  TextField,
  Button,
  BlockStack,
  Form,
  FormLayout,
  Badge,
  Text,
  InlineStack,
  Card,
  Spinner,
  Scrollable,
  ButtonGroup,
  Icon,
  List,
  Bleed,
} from "@shopify/polaris";
import {
  CalendarIcon,
  ChatIcon,
  CheckSmallIcon,
  GaugeIcon,
  ListBulletedIcon,
} from "@shopify/polaris-icons";
import { useField, useForm } from "@shopify/react-form";
import PropTypes from "prop-types";
import {
  useCreateCreditPreviewMutation,
  useGetCreditPreviewQuery,
} from "../../services/api";
import { formatLongDateTime } from "../../utilities";

const CreditPreview = (props) => {
  const {
    opportunityId = null,
    viewOnly = false,
    creditPreviewId = null,
  } = props;

  const [createCreditPreview, { isLoading: isCreatingCreditPreview }] =
    useCreateCreditPreviewMutation();

  const {
    data: creditPreview = { attributes: {} },
    isLoading: isLoadingCreditPreview,
  } = useGetCreditPreviewQuery({ opportunityId, creditPreviewId });

  const [isRequestingCreditPreview, setIsRequestingCreditPreview] =
    useState(true);

  const { fields, submit, submitting, makeClean, reset } = useForm({
    fields: {
      input: useField({
        value: "",
        validates: [],
      }),
    },
    async onSubmit(form) {
      return createCreditPreview({
        opportunityId: opportunityId,
        input: form.input,
      })
        .unwrap()
        .then((response) => {
          reset();
          return { status: "success" };
        })
        .catch((error) => {
          console.log(error);
          return {
            status: "fail",
            errors: [{ message: "Failed to create credit preview" }],
          };
        });
    },
  });

  useEffect(() => {
    if (creditPreview?.id) {
      setIsRequestingCreditPreview(false);
    }
  }, [creditPreview]);

  const handleRequestCreditPreview = () => {
    reset();
    setIsRequestingCreditPreview(true);
  };

  const badgeTone =
    creditPreview?.attributes?.grade === "green"
      ? "success"
      : creditPreview?.attributes?.grade === "yellow"
      ? "warning"
      : creditPreview?.attributes?.grade === "red"
      ? "critical"
      : "info";

  const badgeEmoji =
    creditPreview?.attributes?.grade === "green"
      ? "🟢 "
      : creditPreview?.attributes?.grade === "yellow"
      ? "🟡 "
      : creditPreview?.attributes?.grade === "red"
      ? "🔴 "
      : "⚪️ ";

  const badgeContent = (
    <>
      {badgeEmoji}
      {creditPreview?.attributes?.grade?.charAt(0).toUpperCase() +
        creditPreview?.attributes?.grade?.slice(1)}
    </>
  );

  const cardIsLoading = isLoadingCreditPreview || isCreatingCreditPreview;

  return (
    <Card padding="0">
      <Box as="section" padding="400">
        <BlockStack gap="400">
          <Text variant="headingMd" as="h6">
            Credit preview
          </Text>

          {cardIsLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <InlineStack gap="200" align="center">
                <Spinner size="small" />
              </InlineStack>
            </Box>
          ) : creditPreview?.id && !isRequestingCreditPreview ? (
            <BlockStack gap="400">
              <InlineStack blockAlign="center" gap="200">
                <Text>
                  <Icon source={GaugeIcon} />
                </Text>
                <Badge tone={badgeTone} size="large">
                  {badgeContent}
                </Badge>
              </InlineStack>

              <InlineStack
                gap="200"
                align="start"
                blockAlign="center"
                wrap={false}
              >
                <Text>
                  <Icon source={CalendarIcon} />
                </Text>

                <Text variant="bodySm" as="p">
                  Requested{" "}
                  {formatLongDateTime(
                    new Date(creditPreview?.attributes?.created_at)
                  )}
                </Text>
              </InlineStack>

              {creditPreview?.attributes?.suggested_action_items?.length >
                0 && (
                <Scrollable
                  shadow
                  style={{ maxHeight: "300px" }}
                  horizontal={false}
                >
                  <BlockStack gap={"200"}>
                    <Box paddingInlineStart={"800"}>
                      <Bleed marginInline="100">
                        <Text variant="headingXs" as="h6">
                          SUGGESTED ACTION ITEMS
                        </Text>
                      </Bleed>
                    </Box>

                    {creditPreview.attributes.suggested_action_items.map(
                      (item) => (
                        <InlineStack
                          key={item}
                          gap="200"
                          align="start"
                          wrap={false}
                        >
                          <Text>
                            <Icon source={CheckSmallIcon} />
                          </Text>
                          <Text variant="bodyMd" as="p">
                            {item}
                          </Text>
                        </InlineStack>
                      )
                    )}
                  </BlockStack>
                </Scrollable>
              )}

              <InlineStack gap="200" align="start" wrap={false}>
                <Text>
                  <Icon source={ChatIcon} />
                </Text>
                <Scrollable shadow style={{ maxHeight: "300px" }}>
                  <Text variant="bodyMd" as="p">
                    {creditPreview.attributes.rationale ||
                      "No rationale given."}
                  </Text>
                </Scrollable>
              </InlineStack>
            </BlockStack>
          ) : viewOnly ? (
            <Text>
              No credit previews for this client. Request a credit preview from
              an opportunity detail page.
            </Text>
          ) : (
            <Form onSubmit={submit}>
              <FormLayout>
                <TextField
                  multiline={8}
                  maxHeight="300px"
                  helpText="Copy & paste a credit report or any helpful information about the opportunity."
                  {...fields.input}
                />
                <ButtonGroup>
                  <Button
                    submit
                    variant="primary"
                    loading={submitting}
                    disabled={!fields.input.value.trim()}
                  >
                    Request preview
                  </Button>
                  {creditPreview?.id && (
                    <Button
                      onClick={() => {
                        reset();
                        setIsRequestingCreditPreview(false);
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                </ButtonGroup>
              </FormLayout>
            </Form>
          )}

          {!isRequestingCreditPreview && !viewOnly && (
            <Button variant="plain" onClick={handleRequestCreditPreview}>
              Request new preview
            </Button>
          )}
        </BlockStack>
      </Box>
    </Card>
  );
};

export default CreditPreview;

CreditPreview.propTypes = {
  opportunityId: PropTypes.string.isRequired,
  viewOnly: PropTypes.bool,
  creditPreviewId: PropTypes.string,
};
