import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";

import { useParams, useHistory, useLocation } from "react-router-dom";

import {
  useField,
  useForm,
  notEmpty,
  asChoiceField,
} from "@shopify/react-form";

import {
  Modal,
  FormLayout,
  RadioButton,
  Select,
  TextField,
  Banner,
  Icon,
  InlineStack,
  Button
} from "@shopify/polaris";

import {
  CheckIcon,
  CalendarIcon,
  EditIcon,
  XIcon,
} from "@shopify/polaris-icons";

import { useCreateOpportunityTransactionMutation } from "../../../services/api";

import {
  formatNumericDate,
  convertServerDate,
  isDateNotPast,
} from "../../../utilities";

import DatePickerPopUp from "../../../components/DatePickerPopUp";

const NewTransactionModal = (props) => {
  const {
    opportunityId,
    showNewTransactionModal,
    toggleNewTransactionModal,
    setToastMessage,
    setShowToast,
    isVendorAdmin,
    isOrganizationAdmin,
  } = props;

  const [createTransaction, { isLoading: isCreatingTransaction }] =
    useCreateOpportunityTransactionMutation();

  const payableDetailOptions = [
    {
      label: "Vendor Payout - Net terms installment payment",
      value: "Vendor Payout - Net terms installment payment",
    },
    {
      label: "Vendor Payout - Down Payment",
      value: "Vendor Payout - Down Payment",
    },
    {
      label: "Vendor Payout - Early Commencement",
      value: "Vendor Payout - Early Commencement",
    },
    {
      label: "Vendor Payout - Full",
      value: "Vendor Payout - Full",
    },
    {
      label: "Vendor Payout - Progress Payment",
      value: "Vendor Payout - Progress Payment",
    },
    {
      label: "Expense - Progress Payment Interest Cost",
      value: "Expense - Progress Payment Interest Cost",
    },
    {
      label: "Expense - UCC filing",
      value: "Expense - UCC filing",
    },
    {
      label: "Expense - On-site inspection",
      value: "Expense - On-site inspection",
    },
    {
      label: "Expense - Lien search",
      value: "Expense - Lien search",
    },
    {
      label: "Expense - Lender doc fee",
      value: "Expense - Lender doc fee",
    },
    {
      label: "Expense - Transaction expense",
      value: "Expense - Transaction expense",
    },
    {
      label: "Fee - Vendor Referral",
      value: "Fee - Vendor Referral",
    },
    {
      label: "Fee - Associate Referral",
      value: "Fee - Associate Referral",
    },
    {
      label: "Fee - Commission or Referral",
      value: "Fee - Commission or Referral",
    },
    {
      label: "Fee - LoC interest",
      value: "Fee - LoC interest",
    },
    {
      label: "Fee - Other",
      value: "Fee - Other",
    },
    {
      label: "Fee - Other fee to Lender",
      value: "Fee - Other fee to Lender",
    },
  ];

  const vendorAdminPayableDetailOptions = [
    {
      label: "Vendor Payout - Net terms installment payment",
      value: "Vendor Payout - Net terms installment payment",
    },
    {
      label: "Vendor Payout - Down Payment",
      value: "Vendor Payout - Down Payment",
    },
  ];

  const receivableDetailOptions = [
    {
      label: "Customer Charge - Net terms installment payment",
      value: "Customer Charge - Net terms installment payment",
    },
    {
      label: "Customer Charge - Base Payment",
      value: "Customer Charge - Base Payment",
    },
    {
      label: "Customer Charge - Advance Payment",
      value: "Customer Charge - Advance Payment",
    },
    {
      label: "Customer Charge - Pro Rata",
      value: "Customer Charge - Pro Rata",
    },
    {
      label: "Lender Charge - Bank Fund Out",
      value: "Lender Charge - Bank Fund Out",
    },
    {
      label: "Customer Charge - Customer Charge",
      value: "Customer Charge - Customer Charge",
    },
    {
      label: "Customer Charge - Commitment Deposit",
      value: "Customer Charge - Commitment Deposit",
    },
    {
      label: "Customer Charge - Progress Payment",
      value: "Customer Charge - Progress Payment",
    },
    {
      label: "Vendor Charge - Subsidy",
      value: "Vendor Charge - Subsidy",
    },
    {
      label: "Fee - Origination Fee from Customer",
      value: "Fee - Origination Fee from Customer",
    },
    {
      label: "Fee - Origination Fee from Lender",
      value: "Fee - Origination Fee from Lender",
    },
    {
      label: "Fee - Doc fee",
      value: "Fee - Doc fee",
    },
    {
      label: "Fee - Late fee",
      value: "Fee - Late fee",
    },
    {
      label: "Fee - NSF",
      value: "Fee - NSF",
    },
    {
      label: "Fee - Other",
      value: "Fee - Other",
    },
  ];

  const {
    fields,
    submit: submitNewTransaction,
    submitting,
    reset: resetNewTransactionForm,
    submitErrors,
    makeClean,
    dirty: newTransactionFormDirty,
  } = useForm({
    fields: {
      amount: useField({
        value: "",
        validates: [notEmpty("Transaction amount is required")],
      }),
      detail: useField({
        value: "",
        validates: [notEmpty("Transaction detail is required")],
      }),
      transaction_type: useField({
        value: "payable",
        validates: [notEmpty("Transaction type is required")],
      }),
      scheduled_date: useField({
        value: "",
        validates: (value) => {
          if (!isOrganizationAdmin && value && !isDateNotPast(value)) {
            return "Scheduled date cannot be in the past";
          }
        },
      }),
      status: useField({
        value: "scheduled",
        validates: [],
      }),
    },
    async onSubmit(form) {
      return createTransaction({
        opportunityId: opportunityId,
        ...form,
      })
        .unwrap()
        .then(() => {
          if (setToastMessage) setToastMessage("Transaction added.");
          if (setShowToast) setShowToast(true);

          toggleNewTransactionModal();
          resetNewTransactionForm();

          return { status: "success" };
        })
        .catch((error) => {
          console.log(error);
          return { status: "fail", errors: [error] };
        });
    },
  });

  const errorBanner = submitErrors.length > 0 && (
    <Modal.Section>
      <Banner tone="critical">
        <p>There were some issues with this transaction:</p>
        <ul>
          {submitErrors.map(({ data }, index) => {
            if (data?.financing_option == "must exist") {
              return (
                <li key={`transactionError${index}`}>
                  Accepted purchase option is required.
                </li>
              );
            } else {
              return (
                <li key={`transactionError${index}`}>
                  A system error prevented the transaction from saving.
                </li>
              );
            }
          })}
        </ul>
      </Banner>
    </Modal.Section>
  );

  const editTransactionModal = (
    <Modal
      open={showNewTransactionModal}
      onClose={toggleNewTransactionModal}
      title={"Add transaction"}
      primaryAction={{
        content: "Save",
        onAction: submitNewTransaction,
        disabled: !newTransactionFormDirty,
        loading: submitting,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: toggleNewTransactionModal,
        },
      ]}
    >
      {errorBanner}

      {isOrganizationAdmin && (
        <Modal.Section>
          <FormLayout>
            <FormLayout.Group>
              <RadioButton
                label="Payable"
                id="payable"
                name="payable"
                helpText="FORT Capital is making a payment."
                {...asChoiceField(fields.transaction_type, "payable")}
              />

              <RadioButton
                label="Receivable"
                id="receivable"
                name="receivable"
                helpText="FORT Capital is receiving a payment."
                {...asChoiceField(fields.transaction_type, "receivable")}
              />
            </FormLayout.Group>
          </FormLayout>
        </Modal.Section>
      )}

      <Modal.Section>
        <FormLayout>
          {fields.transaction_type.value == "payable" && (
            <Select
              label="Transaction type"
              options={
                isOrganizationAdmin
                  ? payableDetailOptions
                  : isVendorAdmin
                  ? vendorAdminPayableDetailOptions
                  : []
              }
              placeholder="Select"
              {...fields.detail}
            />
          )}

          {fields.transaction_type.value == "receivable" && (
            <Select
              label="Transaction type"
              options={receivableDetailOptions}
              placeholder="Select"
              {...fields.detail}
            />
          )}

          <div className="FortForm__NumericTextField">
            <TextField
              autoComplete="off"
              label="Amount"
              prefix={"$"}
              type="number"
              placeholder="0.00"
              {...fields.amount}
            />
          </div>

          <div>
            <DatePickerPopUp
              datePickerLabel={"Scheduled Date"}
              placeholder="mm/dd/yyyy"
              setEditingDate={(rubyStringDate) => {
                fields.scheduled_date.value = rubyStringDate
              }}
            />
          </div>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  return editTransactionModal;
};

export default NewTransactionModal;
