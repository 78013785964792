const attributes = [
  // 'stagename', - Remove stage
  'created_at',
  'originator_name',
  'team_name',
  'vendor_name',
  'tags',
  'booked_at',
  'funded_at',
  'approved_at',
  'quote',
  'task',
  'credit_review',
  'status',
  'financing_type'
]

const filters = {

  // Old Stage Name filter
  // 'stagename': {
  //   label: 'Stage',
  //   type: 'choice_string',
  //   query: 'stagename = ?',
  //   dynamic: false,
  //   choices: [
  //     {
  //       value: 'lead',
  //       label: 'Lead'
  //     },
  //     {
  //       value: 'estimated_terms',
  //       label: 'Estimated Terms'
  //     },
  //     {
  //       value: 'credit_review',
  //       label: 'Credit Review'
  //     },
  //     {
  //       value: 'approved',
  //       label: 'Approved'
  //     },
  //     {
  //       value: 'booked',
  //       label: 'Booked'
  //     },
  //     {
  //       value: 'funded',
  //       label: 'Funded'
  //     },
  //     {
  //       value: 'closed_lost',
  //       label: 'Closed'
  //     },
  //   ],
  //   multiple: false // If true, FE should handle all choices selected and encapsulate within parenthesis
  // },

  // Remove stage again
  // 'stagename': {
  //   label: 'Stage',
  //   type: 'choice_string',
  //   query: 'stagename in ?',
  //   dynamic: false,
  //   choices: [
  //     {
  //       value: "('lead', 'estimated_terms', 'credit_review', 'approved', 'booked', 'funding')",
  //       label: 'Open'
  //     },
  //     {
  //       value: "('closed_won')",
  //       label: 'Closed - Won'
  //     },
  //     {
  //       value: "('closed_lost')",
  //       label: 'Closed - Lost'
  //     },
  //   ],
  //   multiple: false
  // },

  'created_at': {
    label: 'Date Created',
    type: 'date',
    query: "created_at >= ?::date"
  },

  'originator_name': {
    label: 'Originator',
    type: 'search_string',
    query: 'LOWER(firstname) like LOWER(?) OR LOWER(lastname) like LOWER(?) OR LOWER(name) like LOWER(?) OR LOWER(email) like LOWER(?)',
    handleSearchChange: function (value, externalHandler) { externalHandler(value) }
  },

  'team_name': {
    label: 'Team',
    type: 'search_string',
    query: 'LOWER(name) like LOWER(?)',
    handleSearchChange: function (value, externalHandler) { externalHandler() }
  },

  'vendor_name': {
    label: 'Vendor',
    type: 'search_string',
    query: 'LOWER(name) like LOWER(?)',
    handleSearchChange: function (value, externalHandler) { externalHandler() }
  },

  'tags': {
    label: 'Tags',
    type: 'choice_string',
    dynamic: true,
    multiple: true,
    query: ''
  },

  'booked_at': {
    label: 'Booked date',
    type: 'date',
    query: "booked_at >= ?::date"
  },

  'funded_at': {
    label: 'Funded date',
    type: 'date',
    query: "funded_at >= ?::date"
  },

  'approved_at': {
    label: 'Date Approved',
    type: 'date',
    query: "approved_at >= ?::date"
  },

  'credit_review': {
    label: 'Credit Review',
    type: 'choice_string',
    dynamic: true,
    query: ''
  },

  // Custom Task Filter

  'task': {
    label: 'Requirement',
    type: 'task',
  },

  'status': {
    label: 'Status',
    type: 'choice_string',
    dynamic: true,
    multiple: true,
    query: ''
  },

  'financing_type': {
    label: 'Financing Type',
    type: 'choice_string',
    dynamic: true,
    multiple: true,
    query: ''
  },

  // Keep as comment - Number example
  // 'total_finance_amount': {
  //   label: 'Total finance amount',
  //   type: 'number',
  //   min: 0,
  //   max: 100,
  //   step: 1,
  //   query: 'amount COMPARISON ?' // Value of COMPARISON to be replaced in FE
  // },
}

const initialValues = {
  'stagename': [],
  'created_at': [],
  'originator_name': "",
  'team_name': "",
  'vendor_name': "",
  'tags': [],
  'booked_at': [],
  'funded_at': [],
  'approved_at': [],
  'task': {},
  'credit_review': [],
  'status': [],
  'financing_type': []

  // Keep as comment - Number example
  // 'total_finance_amount': [0, 50],
}

const allowedTeamAdminAttributes = [
  'created_at',
  'originator_name',
  'team_name',
  'vendor_name',
  'status'
]

export {
  filters,
  attributes,
  initialValues,
  allowedTeamAdminAttributes
};

