import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";

import {
  Card,
  Page,
  LegacyStack,
  Thumbnail,
  Layout,
  Labelled,
  Badge,
  Toast,
  Banner,
  Modal,
  IndexTable,
  Text,
  Select,
  TextField,
} from "@shopify/polaris";

import {
  useGetTeamQuery,
  useGetMembershipsByTeamQuery,
  useInviteMembershipsByTeamMutation,
  useUpdateVendorAdminMembershipRoleMutation,
  useUpdateTeamMutation,
} from "../../services/api";

import { formatShortDateTime } from "../../utilities";
import FadeIn from "../../components/FadeIn";
import LoadingScreen from "../../components/Auth/Loading";

import TeamContactForm from "../../components/Team/Contact/Form";
import VendorLockCard from "../../components/Team/VendorLockCard";

import { CurrentContactContext } from "../../contexts/Contact";
import AdminTeamForm from "../../components/Admin/Team/Form";

const TeamShow = (props) => {
  const history = useHistory();
  const location = useLocation();

  const { id: teamId } = useParams();

  const { currentContact, isOrganizationAdmin, currentContactHasPermission } =
    useContext(CurrentContactContext);

  const [showMembersInviteModal, setShowMembersInviteModal] = useState(false);
  const [showNewContactForm, setShowNewContactForm] = useState(false);
  const [addNewContact, setAddNewContact] = useState(false);
  const [inviteParams, setInviteParams] = useState({
    contactIds: [],
    notifyContacts: false,
    notifMsg: "",
    isManager: false,
  });
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedMemberUpdate, setSelectedMemberUpdate] = useState("");

  const navigateToAdminIndex = useCallback(() => {
    history.push("/admin");
  }, [history]);

  useEffect(() => {
    if (!teamId) {
      navigateToAdminIndex();
    }
  }, [teamId, navigateToAdminIndex]);

  const [inviteMember, { isLoading: isInviting }] =
    useInviteMembershipsByTeamMutation();
  const [updateMemberRole, { isLoading: isUpdatingRole }] =
    useUpdateVendorAdminMembershipRoleMutation();
  const { data: team = { attributes: {} }, isLoading: isLoadingTeam } =
    useGetTeamQuery({ id: teamId });
  const { data: memberships = [], isLoading: isLoadingMemberships } =
    useGetMembershipsByTeamQuery(teamId);
  const [updateTeam, { isLoading: isUpdatingTeam }] = useUpdateTeamMutation();

  const [teamName, setTeamName] = useState("");

  useEffect(() => {
    setTeamName(team.attributes.name);
  }, [team]);

  const isAuthorized =
    isOrganizationAdmin ||
    currentContactHasPermission("vendor_admin", team.attributes.account_id) ||
    currentContactHasPermission("team_admin", teamId);

  const handleInviteToTeam = () => {
    inviteMember({ teamId, ...inviteParams })
      .then(() => {
        setToastMessage(`Contacts invited to ${team.attributes.name}`);
        setShowToast(true);
        setShowMembersInviteModal(false);

        return { status: "success" };
      })
      .catch((error) => console.log(error));
  };

  const handleSelectFocus = useCallback(
    (value) => setSelectedMemberUpdate(value),
    [selectedMemberUpdate]
  );

  const handleUpdateRole = useCallback(
    (value) => {
      updateMemberRole({
        memberId: selectedMemberUpdate,
        team_id: teamId,
        isManager: value == "Admin",
      })
        .then(() => {
          setToastMessage(`Role updated to ${value}`);
          setShowToast(true);

          return { status: "success" };
        })
        .catch((error) => console.log(error));
    },
    [selectedMemberUpdate]
  );

  const updateTeamName = useCallback(() => {
    return updateTeam({ team_id: teamId, team_name: teamName })
      .unwrap()
      .then((response) => {
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
        return {
          status: "fail",
          errors: [{ message: "Could not update team name." }],
        };
      });
  }, [teamName]);

  const toggleVendorLock = () => {
    return updateTeam({ team_id: teamId })
      .unwrap()
      .then((response) => {
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
        return {
          status: "fail",
          errors: [{ message: "Could not toggle vendor lock." }],
        };
      });
  };

  const [showToast, setShowToast] = useState(location?.state?.showToast);
  const [toastMessage, setToastMessage] = useState(
    location?.state?.toastMessage
  );
  const toggleShowToast = useCallback(
    () => setShowToast((active) => !active),
    []
  );

  const handleDismissToast = () => {
    const { state: currentState } = location;
    if (currentState?.toastMessage) {
      delete currentState.toastMessage;
    }
    if (currentState?.showToast) {
      delete currentState.showToast;
    }
    window.history.replaceState({ ...currentState }, "");
    toggleShowToast();
    setToastMessage();
  };
  const toastMarkup = showToast ? (
    <Toast content={toastMessage} onDismiss={handleDismissToast} />
  ) : undefined;

  const rowMarkup =
    memberships.length > 0
      ? memberships.map((item, index) => (
          <IndexTable.Row id={index} key={index} position={index}>
            <IndexTable.Cell>
              <Text variant="strong" fontWeight="bold" truncate>
                {item.attributes.contact_full_name}
              </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>{item.attributes.contact_email}</IndexTable.Cell>
            <IndexTable.Cell>
              {item.attributes.last_logged_in_at
                ? formatShortDateTime(
                    new Date(item.attributes.last_logged_in_at)
                  )
                : ""}
            </IndexTable.Cell>
            <IndexTable.Cell>
              {item.attributes.is_team_admin ? <Badge> Admin </Badge> : <></>}
            </IndexTable.Cell>
          </IndexTable.Row>
        ))
      : [];

  const labelStyles = {
    minHeight: "36px",
    paddingTop: "4px",
  };

  const teamContactFormRef = useRef();

  const inviteMembersModal = (
    <Modal
      open={showMembersInviteModal}
      onClose={() => setShowMembersInviteModal(false)}
      title={`Invite new members to ${team.attributes.name}`}
      large={true}
      primaryAction={{
        content: "Send",
        loading: addNewContact,
        onAction: () => {
          if (showNewContactForm) {
            setAddNewContact(true);
          } else {
            handleInviteToTeam();
            setShowMembersInviteModal(false);
          }
        },
      }}
    >
      <Modal.Section>
        <TeamContactForm
          onAfterShareWithContacts={() => {}}
          teamId={teamId}
          memberships={memberships}
          ref={teamContactFormRef}
          setInviteParams={setInviteParams}
          isShowNewContactForm={setShowNewContactForm}
          addNewContact={addNewContact}
          setAddNewContact={setAddNewContact}
        />
      </Modal.Section>
    </Modal>
  );

  const handleTeamUpdate = () => {
    setToastMessage("Team updated");
    setShowToast(true);
  };

  const pageMarkup = (
    <FadeIn fadeIn>
      <Page fullWidth>
        <Layout>
          {/* Team details */}
          <Layout.Section>
            {!isAuthorized ? (
              <>
                <Banner title="Only admins can edit this page" tone="info">
                  As a member, this page is view only. Contact an admin to gain
                  access to invite or manage users on this team
                </Banner>
                <br />
              </>
            ) : (
              <></>
            )}

            <Card title={"Basic information"}>
              <Card>
                {currentContactHasPermission("team_admin", team.id) ? (
                  <AdminTeamForm
                    team={team}
                    teamId={team.id}
                    onCreateOrUpdate={handleTeamUpdate}
                    onEdit={() => {}}
                  />
                ) : (
                  <TextField
                    label="Name"
                    value={team.attributes.name}
                    disabled
                  />
                )}
              </Card>
            </Card>
            <br />
            {isAuthorized ? (
              <>
                <VendorLockCard
                  team={team}
                  toggleVendorLock={toggleVendorLock}
                />
                <br />
              </>
            ) : (
              <></>
            )}
            <Card
              title={"Members"}
              actions={
                isAuthorized
                  ? [
                      {
                        content: "Add members",
                        onAction: () => {
                          setShowMembersInviteModal(true);
                        },
                      },
                    ]
                  : []
              }
            >
              <div style={{ paddingTop: "1rem" }}>
                <IndexTable
                  resourceName={{
                    singular: "member",
                    plural: "members",
                  }}
                  itemCount={memberships.length}
                  selectable={false}
                  loading={isLoadingMemberships}
                  headings={[
                    { title: "User" },
                    { title: "Email" },
                    { title: "Last login" },
                    { title: "" },
                  ]}
                  sortable={[true, false, false, false]}
                  defaultSortDirection={"descending"}
                >
                  {rowMarkup}
                </IndexTable>
              </div>
            </Card>
          </Layout.Section>
          {inviteMembersModal}
          {toastMarkup}
        </Layout>
      </Page>
    </FadeIn>
  );

  return currentContact ? pageMarkup : <LoadingScreen />;
};

export default TeamShow;
