import React, { useState, useCallback, useEffect, useContext } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";

import {
  useGetFinancingOptionQuery,
  useGetFinancingOptionSegmentsQuery,
  useGetOpportunityQuery,
  useUpdateFinancingOptionMutation,
} from "../../services/api";

import FadeIn from "../../components/FadeIn";
import LoadingScreen from "../../components/Auth/Loading";

import { CurrentContactContext } from "../../contexts/Contact";
import PurchaseOptionCalculator from "../../components/PurchaseOption/Calculator";
import LoadingSkeleton from "../../components/FinancingOption/FinanceCalculator/components/LoadingSkeleton";

const FinancingOptionsShow = () => {
  const location = useLocation();
  const history = useHistory();

  const { currentContact } = useContext(CurrentContactContext);

  const { opportunityId, financingOptionId } = useParams();

  const { financingOption: financingOptionObject } = location?.state
    ?.financingOption
    ? location.state
    : {
        financingOption: { attributes: null },
      };
  const { opportunity: opportunityObject } = location?.state?.opportunity
    ? location.state
    : {
        opportunity: { attributes: null },
      };

  const {
    data: opportunity = opportunityObject,
    isLoading: isLoadingOpportunity,
  } = useGetOpportunityQuery(opportunityId);

  const {
    data: financingOption = financingOptionObject,
    isLoading: isLoadingFinancingOption,
  } = useGetFinancingOptionQuery({
    id: financingOptionId,
    opportunityId: opportunityId,
  });

  const {
    data: financingOptionSegments,
    isLoading: isLoadingFinancingOptionSegments,
  } = useGetFinancingOptionSegmentsQuery(financingOptionId, {
    skip: !financingOptionId,
  });

  const [updateFinancingOption, { isLoading: isUpdatingFinancingOption }] =
    useUpdateFinancingOptionMutation();

  const [clientId, setClientId] = useState();
  useEffect(() => {
    if (opportunity.attributes) {
      setClientId(opportunity.attributes.client_id);
    }
  }, [opportunity.attributes]);

  const navigateToOpportunitiesShow = useCallback(
    (state = {}) => {
      history.push({
        pathname: `/clients/${clientId}/opportunities/${opportunityId}`,
        state: state,
      });
    },
    [clientId, history, opportunityId]
  );

  const handleSavePurchaseOption = useCallback(
    (form) => {
      return updateFinancingOption({
        id: financingOptionId,
        opportunityId: opportunityId,
        ...form,
      })
        .unwrap()
        .then(() => {
          navigateToOpportunitiesShow({
            showToast: true,
            toastMessage: "Purchase option updated.",
          });

          return { status: "success" };
        })
        .catch((error) => {
          console.log(error);

          return { status: "fail", errors: [error] };
        });
    },
    [
      financingOptionId,
      navigateToOpportunitiesShow,
      opportunityId,
      updateFinancingOption,
    ]
  );

  const pageMarkup = (
    <FadeIn fadeIn>
      {isLoadingFinancingOptionSegments ||
      isLoadingFinancingOption ||
      isLoadingOpportunity ? (
        <LoadingSkeleton purchaseOptionType={financingOption?.attributes?.type} />
      ) : (
        <PurchaseOptionCalculator
          onBackAction={() => navigateToOpportunitiesShow()}
          onSaveAction={handleSavePurchaseOption}
          opportunity={opportunity}
          purchaseOption={financingOption}
          purchaseOptionType={financingOption.attributes.type}
          purchaseOptionSegments={financingOptionSegments}
        />
      )}
    </FadeIn>
  );

  return currentContact ? pageMarkup : <LoadingScreen />;
};

FinancingOptionsShow.propTypes = {};

export default FinancingOptionsShow;
