import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Card,
  Text,
  Box,
  Page,
  PageActions,
  Layout,
  Modal,
  FormLayout,
  BlockStack,
} from "@shopify/polaris";

import {
  useGetOpportunityQuery,
  useCreateOpportunityEventMutation,
} from "../../services/api";
import VendorPaymentForm from "../../components/VendorPayment/Form";
import AccountForm from "../../components/Account/Form";
import CardBoxTitle from "../../components/Card/BoxTitle";

import AttachmentUpload from "../../components/VendorPayment/AttachmentUpload";

import { CurrentContactContext } from "../../contexts/Contact";
import LoadingScreen from "../../components/Auth/Loading";
import { useAuth0 } from "@auth0/auth0-react";

const VendorPaymentsNew = () => {
  const history = useHistory();
  const location = useLocation();

  const { opportunityId } = useParams();
  const paramSet = useParams();

  const { user } = useAuth0();
  const { currentContactId, currentContact } = useContext(
    CurrentContactContext
  );
  const [selectedVendorId, setSelectedVendorId] = useState();
  const [finalInvoice, setFinalInvoice] = useState(
    window.location.pathname.includes("final_invoice")
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      currentContact &&
      currentContact.attributes.vendor_id &&
      !selectedVendorId
    ) {
      setSelectedVendorId(currentContact.attributes.vendor_id);
    }
  });

  const { opportunity: opportunityObject } = location.state?.opportunity
    ? location?.state
    : {
        opportunity: { attributes: {} },
      };

  const {
    data: opportunity = opportunityObject,
    isLoading: isLoadingOpportunity,
  } = useGetOpportunityQuery(opportunityId);

  const navigateToOpportunitiesShow = useCallback(
    (state = {}) => {
      history.push({
        pathname: `/clients/${opportunity.attributes.client_id}/opportunities/${opportunityId}`,
        search: finalInvoice ? "?tab=payments-n-review" : "",
        state: state,
      });
    },
    [history, opportunity.attributes.client_id, opportunityId, finalInvoice]
  );

  const navigateToVendorPaymentEdit = useCallback(
    (vendorPaymentId) => {
      if (finalInvoice) {
        // Send invoice uploaded event
        handleCreateOpportunityEvent("OpportunityInvoiceUploaded");

        history.push({
          pathname: `/opportunities/${opportunityId}/final_invoice/${vendorPaymentId}`,
        });
      } else {
        // Send quote uploaded event
        handleCreateOpportunityEvent("OpportunityQuoteUploaded");

        history.push({
          pathname: `/opportunities/${opportunityId}/vendor_payments/${vendorPaymentId}`,
        });
      }
    },
    [history, opportunityId]
  );

  const vendorPaymentFormRef = useRef();

  const [createOpportunityEvent, { isLoading: isCreatingOpportunityEvent }] =
    useCreateOpportunityEventMutation();

  const handleCreateOpportunityEvent = (eventName) => {
    return createOpportunityEvent({
      opportunityId: opportunityId,
      type: eventName,
      contact_id: currentContact?.id,
    })
      .unwrap()
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const callOpportunityVendorQuoteEvent = () => {
    handleCreateOpportunityEvent("VendorPaymentUpdated");
  };

  const handleCreateVendorPayment = () => {
    // Add event call for Vendor Quote Created/Updated
    callOpportunityVendorQuoteEvent();

    navigateToOpportunitiesShow({
      showToast: true,
      toastMessage: `Vendor ${finalInvoice ? "invoice" : "quote"} created.`,
    });
  };

  const vendorFormRef = useRef();
  const [showAddVendorModal, setShowAddVendorModal] = useState(false);
  const handleCloseModal = () => {
    setShowAddVendorModal(false);
  };

  const [vendorFormDirty, setVendorFormDirty] = useState(false);
  const handleVendorEdit = (isDirty) => {
    setVendorFormDirty(isDirty);
  };

  const handleCreateVendor = (vendor) => {
    setSelectedVendorId(vendor.id);
    handleCloseModal();
  };

  const [vendorFormQuery, setVenorFormQuery] = useState();
  const handleAddVendor = (searchQuery) => {
    setVenorFormQuery(searchQuery);
    setShowAddVendorModal(true);
  };

  const addVendorModal = (
    <Modal
      open={showAddVendorModal}
      onClose={handleCloseModal}
      title={"Add vendor"}
      primaryAction={{
        content: "Save",
        onAction: () => vendorFormRef.current.handleSubmitForm(),
        disabled: !vendorFormDirty,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleCloseModal,
        },
      ]}
    >
      <AccountForm
        ref={vendorFormRef}
        onEdit={handleVendorEdit}
        onCreateOrUpdate={handleCreateVendor}
        resourceName={"vendor"}
        searchQuery={vendorFormQuery}
        isVendor={true}
      />
    </Modal>
  );

  const pagePrimaryAction = {
    content: "Save",
    onAction: () => vendorPaymentFormRef.current.handleSubmitForm(),
  };

  const pageMarkup = (
    <Page
      title={opportunity.attributes.name}
      subtitle={`Vendor ${finalInvoice ? "invoice" : "quote"}`}
      primaryAction={pagePrimaryAction}
      fullWidth
    >
      <Layout>
        <Layout.Section variant="oneHalf">
          <Card>
            <BlockStack gap="400">
              <Text variant="headingMd" as="h6">
                {`${finalInvoice ? "Invoice" : "Quote"} upload`}
              </Text>

              <FormLayout>
                <AttachmentUpload
                  opportunityId={opportunityId}
                  isWithinCard={true}
                  finalInvoice={finalInvoice}
                  onUploadSuccess={(vendorPayment) => {
                    navigateToVendorPaymentEdit(vendorPayment.id);
                  }}
                />
              </FormLayout>
            </BlockStack>
          </Card>
        </Layout.Section>

        <Layout.Section variant="oneHalf">
          <Card>
            <VendorPaymentForm
              ref={vendorPaymentFormRef}
              opportunityId={opportunityId}
              opportunity={opportunity}
              clientAccountId={opportunity.attributes.client_id}
              vendorId={selectedVendorId}
              onCreateOrUpdate={handleCreateVendorPayment}
              onAddVendor={handleAddVendor}
              finalInvoice={finalInvoice}
            />
          </Card>
        </Layout.Section>

        <Layout.Section>
          <PageActions primaryAction={pagePrimaryAction} />
        </Layout.Section>
      </Layout>
      {addVendorModal}
    </Page>
  );

  return currentContact ? pageMarkup : <LoadingScreen />;
};

VendorPaymentsNew.propTypes = {};

export default VendorPaymentsNew;
