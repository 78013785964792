import React, { Component } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none" as const,
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  zIndex: 9999999,
};

type ConfettiProps = {
  active: boolean;
  foo?: string;
};

class Confetti extends Component<ConfettiProps> {
  animationInstance: any = null;

  constructor(props: ConfettiProps) {
    super(props);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPress, false);

    if (this.props.active) {
      this.fire();
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress, false);
  }

  componentDidUpdate(prevProps: ConfettiProps) {
    if (this.props.active && !prevProps.active) {
      this.fire();
    }
  }

  handleKeyPress(e: KeyboardEvent) {
    if (e.key === "ç") {
      this.fire();
    }
  }

  makeShot = (particleRatio: number, opts: any) => {
    this.animationInstance &&
      this.animationInstance({
        ...opts,
        origin: { y: -0.3 },
        angle: 270,
        particleCount: Math.floor(200 * particleRatio),
      });
  };

  fire = () => {
    this.makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    this.makeShot(0.2, {
      spread: 60,
    });

    this.makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    this.makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    this.makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  };

  handlerFire = () => {
    this.fire();
  };

  getInstance = (instance: any) => {
    this.animationInstance = instance;
  };

  render() {
    return (
      <ReactCanvasConfetti
        refConfetti={this.getInstance}
        style={canvasStyles}
      />
    );
  }
}

export default Confetti;
