import React, { useState, useEffect, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
// import PropTypes from "prop-types";
// import { useCookies } from "react-cookie";

import {
  Button,
  BlockStack,
  InlineStack,
  Text,
  Image,
  FormLayout,
} from "@shopify/polaris";
import { ChevronLeftIcon } from "@shopify/polaris-icons";

import FadeIn from "../../../../../components/FadeIn";
import Confetti from "../../../../../components/Confetti";
import successSVG from "../../../../../assets/shareFinancingOptionsSuccess.svg";

import { CurrentContactContext } from "../../../../../contexts/Contact";

const Finish = () => {
  const history = useHistory();

  const { currentContact } = useContext(CurrentContactContext);

  const navigateHome = useCallback(() => {
    history.push("/");
  }, [history]);

  const [iCanHasConfetti, setICanHasConfetti] = useState(false);

  useEffect(() => {
    if (document.readyState === "complete") {
      setTimeout(() => handleConfetti(), 300);
    }
  }, []);

  const handleConfetti = () => {
    setICanHasConfetti(true);
    setTimeout(() => {
      setICanHasConfetti(false);
    }, 100);
  };

  return (
    <FadeIn fadeIn>
      <div
        style={{
          display: isMobile ? "block" : "flex",
          height: "50vh",
          alignItems: "center",
          marginTop: isMobile ? "32px" : 0,
        }}
      >
        <InlineStack
          alignment="center"
          distribution="equalSpacing"
          wrap={isMobile}
        >
          <BlockStack>
            <BlockStack gap={"600"} align={"start"}>
              <Text variant="heading2xl" as="p">
                Great! Looks like we've got everything we need for now.
              </Text>

              <Text variant="headingLg" as="p">
                <Text as="span" tone="subdued">
                  Our team has been notified and will be in touch with next
                  steps shortly.
                </Text>
              </Text>

              <FormLayout>
                <Button
                  onClick={navigateHome}
                  icon={ChevronLeftIcon}
                  variant="plain"
                >
                  {currentContact
                    ? "Go back home"
                    : "Log in to your Fortify account"}
                </Button>
              </FormLayout>
            </BlockStack>
          </BlockStack>

          <Image source={successSVG} style={{ width: "350px" }} />
        </InlineStack>
      </div>

      <Confetti active={iCanHasConfetti} />
    </FadeIn>
  );
};

export default Finish;
