import React, { useState, useCallback, useContext } from "react";
import { TopBar, Icon, Text } from "@shopify/polaris";
import { ArrowLeftIcon, QuestionCircleIcon } from "@shopify/polaris-icons";
import PropTypes from "prop-types";
import { CurrentContactContext } from "../../contexts/Contact";
import { useAuth0 } from "@auth0/auth0-react";

const Navbar = (props) => {
  const { handleAuth0Logout, handleNavigationToggle } = props;

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isSecondaryMenuOpen, setIsSecondaryMenuOpen] = useState(false);

  const { currentContact } = useContext(CurrentContactContext);

  const { user } = useAuth0();

  const toggleIsUserMenuOpen = useCallback(
    () => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen),
    []
  );

  const toggleIsSecondaryMenuOpen = useCallback(
    () => setIsSecondaryMenuOpen((isSecondaryMenuOpen) => !isSecondaryMenuOpen),
    []
  );

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={[
        {
          items: [
            {
              content: "Logout",
              icon: ArrowLeftIcon,
              onAction: handleAuth0Logout,
            },
          ],
        },
      ]}
      avatar={user?.picture}
      name={
        currentContact?.attributes.name ? currentContact.attributes.name : ""
      }
      detail={
        user?.email
          ? user.email
          : currentContact?.attributes.email
          ? currentContact.attributes.email
          : ""
      }
      initials={
        currentContact?.attributes.initials
          ? currentContact.attributes.initials
          : ""
      }
      open={isUserMenuOpen}
      onToggle={toggleIsUserMenuOpen}
    />
  );

  const handleContactFort = () =>
    window.open(`mailto:${process.env.SUPPORT_EMAIL}?subject=General Support`);

  const secondaryMenuMarkup = (
    <TopBar.Menu
      activatorContent={
        <span>
          <Icon source={QuestionCircleIcon} />
          <Text visuallyHidden as="h2">
            Support menu
          </Text>
        </span>
      }
      open={isSecondaryMenuOpen}
      onOpen={toggleIsSecondaryMenuOpen}
      onClose={toggleIsSecondaryMenuOpen}
      actions={[
        {
          items: [
            { content: "Contact FORT", onAction: handleContactFort },
            {
              content: "Help Center",
              external: true,
              url: "https://help.fortifypay.com",
            },
          ],
        },
      ]}
    />
  );

  const navbarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      secondaryMenu={secondaryMenuMarkup}
      onNavigationToggle={handleNavigationToggle}
    />
  );

  return navbarMarkup;
};
Navbar.propTypes = {
  handleAuth0Logout: PropTypes.func,
  handleNavigationToggle: PropTypes.func,
};

export default Navbar;
