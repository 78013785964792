import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useRef,
  useContext,
} from "react";
import { useDebounce } from "use-debounce";
import {
  useGetContactQuery,
  useSearchContactsQuery,
  useCreateOpportunityContactMutation,
} from "../../services/api";
import { useField, useForm, notEmpty } from "@shopify/react-form";
import {
  Autocomplete,
  Button,
  FormLayout,
  Icon,
  LegacyStack,
  Tag,
  TextField,
  Checkbox,
  Text,
} from "@shopify/polaris";
import { SearchIcon, PlusCircleIcon } from "@shopify/polaris-icons";
import PropTypes from "prop-types";
import { validateEmail } from "../../utilities";

import { CurrentContactContext } from "../../contexts/Contact";

const OpportunityContactForm = forwardRef((props, ref) => {
  const {
    contactId,
    opportunityId,
    onAfterShareWithContacts,
    onSelectContact,
    onNewContact,
    onDeselectContacts,
    onShowNewContactForm,
    setSelectedContactEmails,
  } = props;

  const { currentContact } = useContext(CurrentContactContext);

  const [notifMsg, setNotifMsg] = useState("");
  const [notifSubject, setNotifSubject] = useState(
    `${currentContact?.attributes?.full_name} sent you a proposal on Fortify`
  );
  const [notifyContacts, setNotifyContacts] = useState(true);
  const [showCCTextBox, setShowCCTextBox] = useState(false);
  const handleCheckboxChange = useCallback((newChecked) => {
    setNotifyContacts(newChecked);
    setNotifSubject(
      `${currentContact?.attributes?.full_name} sent you a proposal on Fortify`
    );
  }, []);

  const [
    createOpportunityContact,
    { isLoading: isCreatingOpportunityContact },
  ] = useCreateOpportunityContactMutation();

  const [contactSearchValue, setContactSearchValue] = useState("");
  const [contactSearchQuery] = useDebounce(contactSearchValue, 500);

  const [showNewContactForm, setShowNewContactForm] = useState(false);

  const { data: searchContacts = [], isLoading: isLoadingSearchContacts } =
    useSearchContactsQuery({ search: contactSearchQuery });

  const { data: requestedContact, isLoading: isLoadingContact } =
    useGetContactQuery(contactId, { skip: !contactId });

  const [selectedContactOptions, setSelectedContactOptions] = useState([]);
  const [selectedContactOptionIds, setSelectedContactOptionIds] = useState(
    contactId != undefined ? [contactId] : []
  );
  const [selectedCCContactOptions, setSelectedCCContactOptions] = useState([]);
  const [selectedCCContactOptionIds, setSelectedCCContactOptionIds] = useState(
    contactId != undefined ? [contactId] : []
  );
  const [contactOptions, setContactOptions] = useState([]);

  useEffect(() => {
    if (requestedContact) {
      setSelectedContactOptions([requestedContact]);
    }
  }, [requestedContact]);

  useEffect(() => {
    if (selectedContactOptions.length > 0) {
      setSelectedContactEmails(
        selectedContactOptions.map((contact) => {
          return contact.attributes.email;
        })
      );
    } else {
      setSelectedContactEmails([]);
    }
  }, [selectedContactOptions]);

  const handleSelectContact = useCallback(
    (selectedContactIds) => {
      onSelectContact();

      if (selectedContactIds.includes(selectedContactIds)) {
        return;
      } else {
        setSelectedContactOptionIds((previousSelectedOptionIds) => {
          return [
            ...new Set([...previousSelectedOptionIds, ...selectedContactIds]),
          ];
        });

        const newSelectedContacts = searchContacts.filter((contact) => {
          return selectedContactIds.includes(contact.id);
        });

        setNotifMsg(
          `Hi ${newSelectedContacts[0].attributes.first_name}, here's the latest proposal for your review. To access these options, create an account on Fortify by following the link below.`
        );
        setSelectedContactOptions([
          ...new Set([...selectedContactOptions, ...newSelectedContacts]),
        ]);
      }
    },
    [searchContacts, selectedContactOptions]
  );

  const handleSelectCCContact = useCallback(
    (selectedCCContactIds) => {
      if (selectedCCContactIds.includes(selectedCCContactIds)) {
        return;
      } else {
        setSelectedCCContactOptionIds(selectedCCContactIds);

        const newSelectedContacts = searchContacts.filter((contact) => {
          return selectedCCContactIds.includes(contact.id);
        });

        setSelectedCCContactOptions([
          ...new Set([...selectedCCContactOptions, ...newSelectedContacts]),
        ]);
      }
    },
    [searchContacts, selectedCCContactOptions]
  );

  const handleRemoveContact = useCallback(
    (contact) => {
      const options = [...selectedContactOptions];
      options.splice(options.indexOf(contact), 1);
      setSelectedContactOptions(options);

      const optionIds = [...selectedContactOptionIds];
      optionIds.splice(optionIds.indexOf(contact.id), 1);
      setSelectedContactOptionIds(optionIds);
      if (optionIds.length < 1) {
        setNotifyContacts(true);
        onDeselectContacts();
      }
    },
    [onDeselectContacts, selectedContactOptionIds, selectedContactOptions]
  );

  const handleRemoveCCContact = useCallback(
    (contact) => {
      const options = [...selectedCCContactOptions];
      options.splice(options.indexOf(contact), 1);
      setSelectedCCContactOptions(options);

      const optionIds = [...selectedCCContactOptionIds];
      optionIds.splice(optionIds.indexOf(contact.id), 1);
      setSelectedCCContactOptionIds(optionIds);
    },
    [selectedCCContactOptionIds, selectedCCContactOptions]
  );

  const shareButton = useRef(null);

  const handleShareWithContacts = () => {
    if (!selectedContactOptionIds.length) return;
    setNotifyContacts(true);

    const form = {
      contact_ids: selectedContactOptionIds,
      cc_contact_ids: selectedCCContactOptionIds,
      notifSubject: notifSubject,
      notifMsg: notifMsg,
      notifyContacts: notifyContacts,
    };

    // Reset message and subject
    setNotifMsg("");
    setNotifSubject("");
    return createOpportunityContact({ opportunityId: opportunityId, ...form })
      .unwrap()
      .then(() => {
        // Reset contacts
        setSelectedContactOptionIds([]);
        setSelectedContactOptions([]);
        setSelectedCCContactOptionIds([]);
        setSelectedCCContactOptions([]);
        setContactSearchValue("");

        // pull focus from autocomplete
        shareButton.current.click();

        onAfterShareWithContacts();

        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleShareWithOrCreateContacts = () => {
    if (showNewContactForm) {
      submit();
    } else {
      handleShareWithContacts();
    }
  };

  const handleShowNewContactForm = () => {
    onNewContact();

    setShowNewContactForm(true);
    onShowNewContactForm(true);
    resetNewContactForm();
  };

  const handleHideNewContactForm = () => {
    setShowNewContactForm(false);
    onShowNewContactForm(false);
    resetNewContactForm();
  };

  const resetNewContactForm = () => {
    setShowCCTextBox(false);
    setNotifyContacts(true);
    setNotifMsg("");
    setNotifSubject(
      `${currentContact?.attributes?.full_name} sent you a proposal on Fortify`
    );
  };

  useEffect(() => {
    if (searchContacts.length > 0) {
      const contactList = searchContacts.map((contact, index) => {
        return {
          value: contact.id,
          label: `${contact.attributes.full_name} - ${contact.attributes.email}`,
        };
      });

      setContactOptions(contactList);
    }
  }, [searchContacts]);

  const resetContactSelection = () => {
    setSelectedContactOptionIds([]);
    setSelectedContactOptions([]);
    setSelectedCCContactOptionIds([]);
    setSelectedCCContactOptions([]);
    setContactSearchValue("");
    onDeselectContacts();
  };

  useImperativeHandle(ref, () => ({
    handleShareWithContacts: handleShareWithContacts,
    handleShareWithOrCreateContacts: handleShareWithOrCreateContacts,
    handleHideNewContactForm: handleHideNewContactForm,
    resetContactSelection: resetContactSelection,
  }));

  const verticalContentMarkup =
    selectedContactOptions.length > 0 ? (
      <LegacyStack spacing="extraTight" alignment="center">
        {selectedContactOptions.map((contact, index) => {
          return (
            <Tag
              key={`option${index}`}
              onRemove={() => handleRemoveContact(contact)}
            >
              {contact.attributes.full_name}
            </Tag>
          );
        })}
      </LegacyStack>
    ) : null;

  const verticalCCContentMarkup =
    selectedCCContactOptions.length > 0 ? (
      <LegacyStack spacing="extraTight" alignment="center">
        {selectedCCContactOptions.map((contact, index) => {
          return (
            <Tag
              key={`option${index}`}
              onRemove={() => handleRemoveCCContact(contact)}
            >
              {contact.attributes.full_name}
            </Tag>
          );
        })}
      </LegacyStack>
    ) : null;

  const contactShareTextField = (
    <>
      <Autocomplete.TextField
        onChange={setContactSearchValue}
        prefix={<Icon source={SearchIcon} tone="base" />}
        placeholder="Add people"
        value={contactSearchValue}
        verticalContent={verticalContentMarkup}
        disabled={isLoadingContact}
      />
    </>
  );

  const ccTextField = (
    <>
      <Autocomplete.TextField
        label={"CC"}
        onChange={setContactSearchValue}
        placeholder="Search"
        value={contactSearchValue}
        verticalContent={verticalCCContentMarkup}
        disabled={isLoadingContact}
      />
    </>
  );

  const {
    fields,
    submit,
    submitting,
    reset,
    submitErrors,
    makeClean,
    dirty: newContactFormDirty,
  } = useForm({
    fields: {
      firstname: useField({
        value: "",
        validates: [notEmpty("First name is required")],
      }),
      lastname: useField({
        value: "",
        validates: [notEmpty("Last name is required")],
      }),
      phone: useField({
        value: "",
        validates: [],
      }),
      email: useField({
        value: "",
        validates: [
          notEmpty("Email is required"),
          (email) => {
            if (email.length <= 3 || !validateEmail(email)) {
              return "Email must be valid";
            }
          },
        ],
      }),
    },
    async onSubmit(form) {
      return createOpportunityContact({
        opportunityId: opportunityId,
        notifyContacts: true,
        inviteUser: true,
        notifMsg: notifMsg,
        cc_contact_ids: selectedCCContactOptionIds || null,
        contact: { ...form },
      })
        .unwrap()
        .then(() => {
          reset();
          setSelectedContactOptionIds([]);
          setSelectedContactOptions([]);
          setContactSearchValue("");

          setShowNewContactForm(false);
          onShowNewContactForm(false);
          setNotifyContacts(true);
          setNotifMsg("");
          setNotifSubject(
            `${currentContact?.attributes?.full_name} sent you a proposal on Fortify`
          );
          onAfterShareWithContacts();
          return { status: "success" };
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  useEffect(() => {
    if (fields.firstname.value.length > 0) {
      setNotifMsg(
        `Hi ${fields.firstname.value}, here's the latest proposal for your review. To access these options, create an account on Fortify by following the link below.`
      );
    }
  }, [fields.firstname.value]);

  return <>
    {showNewContactForm ? (
      <FormLayout>
        <FormLayout.Group>
          <TextField label="First name" type="text" {...fields.firstname} />

          <TextField label="Last name" type="text" {...fields.lastname} />
        </FormLayout.Group>

        <TextField label="Email" type="email" {...fields.email} />
        <TextField label="Phone number" type="text" {...fields.phone} />

        {!showCCTextBox && (
          <Button
            plain
            onClick={() => {
              setShowCCTextBox(true);
            }}
          >
            CC
          </Button>
        )}

        {showCCTextBox && (
          <>
            <Autocomplete
              allowMultiple
              id={"cc-autocomplete"}
              options={contactOptions}
              selected={selectedCCContactOptionIds}
              onSelect={handleSelectCCContact}
              textField={ccTextField}
              loading={isLoadingSearchContacts}
              emptyState={"No contacts found"}
              preferredPosition={"below"}
            />
            <br />
          </>
        )}

        <TextField
          label="Subject"
          disabled={!notifyContacts}
          value={notifSubject}
          onChange={setNotifSubject}
          autoComplete="off"
        />
        <TextField
          label="Message"
          type="text"
          multiline={2}
          value={notifMsg}
          onChange={setNotifMsg}
        />
      </FormLayout>
    ) : (
      <>
        <Autocomplete
          id={"contact-share-autocomplete"}
          options={contactOptions}
          selected={selectedContactOptionIds}
          onSelect={handleSelectContact}
          textField={contactShareTextField}
          loading={isLoadingSearchContacts}
          emptyState={"No contacts found"}
          preferredPosition={"below"}
          actionBefore={{
            accessibilityLabel: "Invite a new contact to this opportunity",
            content: "Invite a new contact to this opportunity",
            ellipsis: true,
            icon: PlusCircleIcon,
            onAction: handleShowNewContactForm,
          }}
        />

        <div
          style={{
            margin: "0.4rem",
            display: !selectedContactOptionIds.length ? "none" : "",
          }}
        >
          <Checkbox
            disabled={!selectedContactOptionIds.length}
            label="Notify contacts"
            checked={notifyContacts}
            onChange={handleCheckboxChange}
          />
        </div>

        {notifyContacts && selectedContactOptions.length > 0 && (
          <div style={{ marginTop: "1rem" }}>
            {!showCCTextBox && (
              <Button
                onClick={() => {
                  setShowCCTextBox(true);
                }}
                variant="plain"
              >
                CC
              </Button>
            )}

            {showCCTextBox && (
              <>
                <Autocomplete
                  allowMultiple
                  id={"cc-autocomplete"}
                  options={contactOptions}
                  selected={selectedCCContactOptionIds}
                  onSelect={handleSelectCCContact}
                  textField={ccTextField}
                  loading={isLoadingSearchContacts}
                  emptyState={"No contacts found"}
                  preferredPosition={"below"}
                />
                <br />
              </>
            )}

            <Text variant="headingSm" as="h3">
              Subject
            </Text>
            <br />
            <TextField
              label=""
              disabled={!notifyContacts}
              value={notifSubject}
              onChange={setNotifSubject}
              autoComplete="off"
            />

            <br />

            <Text variant="headingSm" as="h3">
              Message
            </Text>
            <br />
            <TextField
              label=""
              disabled={!notifyContacts}
              value={notifMsg}
              onChange={setNotifMsg}
              multiline={2}
              autoComplete="off"
            />
          </div>
        )}
        <Text visuallyHidden as="h2">
          <input ref={shareButton} alt="share button input reference" />
        </Text>
      </>
    )}
  </>;
});

OpportunityContactForm.displayName = "OpportunityContactForm";
OpportunityContactForm.propTypes = {
  contactId: PropTypes.string,
  onNewContact: PropTypes.func,
  onShowNewContactForm: PropTypes.func,
  onAfterShareWithContacts: PropTypes.func,
  onSelectContact: PropTypes.func,
  onDeselectContacts: PropTypes.func,
  opportunityId: PropTypes.string,
  setSelectedContactEmails: PropTypes.func,
};

export default OpportunityContactForm;
