import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import { useLocation } from "react-router-dom";
import {
  Layout,
  Page,
  PageActions,
} from "@shopify/polaris";

import {
  useGetClientQuery,
 } from "../../services/api";
import FadeIn from "../../components/FadeIn";
import CreateOpportunityForm from "../../components/Opportunity/CreateForm";

import { CurrentContactContext } from "../../contexts/Contact";

// import LoadingScreen from "../../components/Auth/Loading";
// import InvoiceConfirmForm from "../../components/Invoice/Confirm/Form";

const CreateOpportunity = () => {

  const { currentContact } = useContext(CurrentContactContext)

  const confirmFormRef = useRef();

  const handleConfirmInvoiceUpload = (args) => {
    confirmFormRef.current.handleSubmitForm();
  };

  const location = useLocation();

  const locationState = location?.state?.clientId ? location.state : null;

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState("");
  const [clientId, setClientId] = useState(null);

  useEffect(() => {
    if(locationState?.clientId){
      setClientId(locationState?.clientId);
    }
  }, [locationState]);

  const {
    data: existingClientData = { attributes: {} },
    isLoading: existingClientIsLoading,
  } = useGetClientQuery(
    clientId,
    { skip: !clientId }
  );

  const handleErrorMessage = useCallback((error) => {
    console.log(error);
    setShowErrorBanner(true);
    let message;

    if (error.data?.legal_business_name == "has already been taken") {
      message =
        "Could not create opportunity.  Account name has already been taken.";
    } else {
      message = "Could not create opportunity.";
    }
    setSubmitErrorMessage(message);
  }, []);

  const [isLoadingConfirmation, setIsLoadingConfirmation] = useState(false);
  const handleConfirmationLoadingState = (isLoading) => {
    setIsLoadingConfirmation(isLoading);
  };

  const pageMarkup = (
    <FadeIn fadeIn={true}>
      <Page
        title="New Opportunity"
        primaryAction={{
          content: "Confirm",
          onAction: handleConfirmInvoiceUpload,
          loading: isLoadingConfirmation
        }}
      >
        <Layout>
          <Layout.Section>
            {!existingClientIsLoading && (
              <CreateOpportunityForm
                handleErrorMessage={handleErrorMessage}
                onConfirmationLoading={handleConfirmationLoadingState}
                stateClientId={clientId}
                existingClientData={existingClientData}
                ref={confirmFormRef}
              />
            )}
          </Layout.Section>

          <Layout.Section>
            <PageActions
              primaryAction={{
                content: "Confirm",
                onAction: handleConfirmInvoiceUpload,
                loading: isLoadingConfirmation
              }}
            />
          </Layout.Section>
        </Layout>
      </Page>
    </FadeIn>
  );

  // return currentContact ? pageMarkup : <LoadingScreen />
  return pageMarkup;
};

export default CreateOpportunity;
