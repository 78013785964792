import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Button,
  Text,
  LegacyStack,
  ResourceItem,
  ResourceList,
  EmptyState,
  Modal,
  Thumbnail,
  BlockStack,
  Toast,
  Truncate,
  Icon,
} from "@shopify/polaris";
import { NoteIcon, AlertTriangleIcon } from "@shopify/polaris-icons";
import {
  useGetVendorPaymentsQuery,
  useDeleteVendorPaymentMutation,
} from "../../services/api";
import { formatCurrency } from "../../utilities";
import emptySearchCompanies from "../../assets/emptySearchCompanies.svg";

const VendorPaymentList = (props) => {
  const history = useHistory();

  const { opportunity, opportunityId, accountId } = props;
  const resourceName = {
    singular: "vendor quote",
    plural: "vendor quotes",
  };

  const { data: vendorPayments = [], isLoading: isLoadingVendorPayments } =
    useGetVendorPaymentsQuery({opportunityId});

  const [deleteVendorPayment, { isLoading: isDeletingVendorPayment }] =
    useDeleteVendorPaymentMutation();

  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);

  const [randomNumber, setRandomNumber] = useState(12);

  useEffect(() => {
    if (vendorPayments.length) {
      setFilteredItems(vendorPayments);
    }
  }, [vendorPayments, isLoadingVendorPayments]);

  const handleNewVendorPayment = () => {
    history.push({
      pathname: `/opportunities/${opportunityId}/vendor_payments/new`,
      state: { opportunity: opportunity },
    });
  };

  const handleEditVendorPayment = (vendorPaymentId) => {
    history.push({
      pathname: `/opportunities/${opportunityId}/vendor_payments/${vendorPaymentId}`,
      state: { opportunity: opportunity },
    });
  };

  const handleDeleteVendorPayments = () => {
    selectedItems.forEach((vendorPaymentId, index) => {
      return deleteVendorPayment({ id: vendorPaymentId, opportunityId })
        .unwrap()
        .then((response) => {
          handleCloseModal();
          setShowToast(true);
          setToastMessage("Vendor quote deleted.");
          setFilteredItems([]);
          setSelectedItems([]);
          return { status: "success" };
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const [
    showDeleteVendorPaymentConfirmationModal,
    setShowDeleteVendorPaymentConfirmationModal,
  ] = useState(false);
  const handleShowDeleteVendorPaymentConfirmationModal = () => {
    setShowDeleteVendorPaymentConfirmationModal(true);
  };
  const handleCloseModal = () => {
    setShowDeleteVendorPaymentConfirmationModal(false);
  };
  const deleteVendorPaymentConfirmationModal = (
    <Modal
      open={showDeleteVendorPaymentConfirmationModal}
      onClose={handleCloseModal}
      title={`Delete ${
        selectedItems.length > 1
          ? "vendor quotes"
          : "vendor quote"
      }`}
      primaryAction={{
        content: "Delete",
        onAction: handleDeleteVendorPayments,
        destructive: true,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleCloseModal,
        },
      ]}
    >
      <Modal.Section title={"Are you sure?"}>
        <BlockStack>
          <Text variant="headingMd" as="h2">Are you sure?</Text>
          <p>
            Deleting the vendor quote will delete the existing finance
            options and rebuild new finance options from the new finance amount.
          </p>
        </BlockStack>
      </Modal.Section>
    </Modal>
  );

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const toggleShowToast = useCallback(
    () => setShowToast((active) => !active),
    []
  );
  const handleDismissToast = () => {
    toggleShowToast();
    setToastMessage("");
  };
  const toastMarkup = showToast ? (
    <Toast content={toastMessage} onDismiss={handleDismissToast} />
  ) : undefined;

  const promotedBulkActions = [
    {
      content: `Delete ${
        selectedItems.length == 1
          ? resourceName["singular"]
          : resourceName["plural"]
      }`,
      onAction: handleShowDeleteVendorPaymentConfirmationModal,
    },
  ];

  const renderItem = (item, index) => {
    const {
      id,
      attributes: {
        name,
        product_description,
        total_amount,
        invoice_number,
        vendor_name,
        confirmed_at,
      },
    } = item;
    const thumbnail = (
      <Thumbnail source={NoteIcon} size="small" alt="Vendor quote" />
    );

    const handleVendorPaymentClick = (vendorPaymentId) => {
      handleEditVendorPayment(vendorPaymentId);
    };

    return (
      <ResourceItem
        id={id}
        key={[randomNumber, index]}
        media={thumbnail}
        onClick={handleVendorPaymentClick}
        accessibilityLabel={`View details for quote ${name}`}
        verticalAlignment="center"
      >
        <LegacyStack spacing="baseTight" alignment="center">
          <LegacyStack.Item fill>
            <LegacyStack vertical spacing="extraTight">
              <LegacyStack>
                <h3 style={{ maxWidth: "320px" }}>
                  <Truncate>
                    <Text as="span" fontWeight="semibold" >
                      {vendor_name} - {invoice_number}
                    </Text>
                  </Truncate>
                </h3>

                { !confirmed_at && 
                  <LegacyStack.Item>
                    <LegacyStack spacing="extraTight">
                      <Icon source={AlertTriangleIcon} tone={"warning"} />
                      <Text as="span" tone="caution">
                        <span data-test="accepted-indicator">Unconfirmed</span>
                    </Text>
                    </LegacyStack>
                  </LegacyStack.Item> 
                }
              </LegacyStack>

              <LegacyStack.Item>
                <Text variant="bodySm" as="p">
                  <Truncate>{product_description}</Truncate>
                </Text>
              </LegacyStack.Item>
            </LegacyStack>
          </LegacyStack.Item>

          

          <p>{formatCurrency(total_amount)}</p>
        </LegacyStack>
      </ResourceItem>
    );
  };

  const emptyStateMarkup = !filteredItems.length ? (
    <EmptyState
      heading="You don't have any vendor quotes"
      action={{
        content: "Add Vendor Quote",
        onAction: handleNewVendorPayment,
      }}
      image={emptySearchCompanies}
    >
      <p>Add your first vendor quote to see them here.</p>
    </EmptyState>
  ) : undefined;

  const vendorPaymentsResourceList = (
    <ResourceList
      resourceName={resourceName}
      items={filteredItems}
      renderItem={(item, id, index) => {
        return renderItem(item, id, index);
      }}
      selectedItems={selectedItems}
      onSelectionChange={setSelectedItems}
      selectable={true}
      promotedBulkActions={promotedBulkActions}
      alternateTool={
        <Button onClick={handleNewVendorPayment}>
          Add {resourceName["singular"]}
        </Button>
      }
      emptyState={emptyStateMarkup}
    />
  );

  return (
    <>
      {vendorPaymentsResourceList}
      {deleteVendorPaymentConfirmationModal}
      {toastMarkup}
    </>
  );
};

VendorPaymentList.propTypes = {
  accountId: PropTypes.string,
  opportunity: PropTypes.object,
  opportunityId: PropTypes.string,
}

export default VendorPaymentList;
