import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useCookies } from "react-cookie";

import {
  Icon,
  Button,
  Text,
  Card,
  InlineStack,
  BlockStack,
  EmptyState,
  DropZone,
  Thumbnail,
  Spinner,
} from "@shopify/polaris";
import {
  NoteIcon,
  CheckCircleIcon,
  AttachmentIcon,
  DeleteIcon,
} from "@shopify/polaris-icons";

import { DirectUploadProvider } from "react-activestorage-provider";

import {
  useGetOpportunityAttachmentsQuery,
  useUpdateOpportunityAttachmentMutation,
  usePurgeOpportunityAttachmentMutation,
} from "../../../../../services/api";

import FadeIn from "../../../../../components/FadeIn";

const UploadAttachments = (props) => {
  const { opportunityId, setCanClickNext } = props;

  const [cookies, setCookie, removeCookie] = useCookies([
    "fortify-app-contact-captured-at",
    "fortify-app-contact-id",
  ]);

  const {
    data: opportunityAttachments,
    isLoading: isLoadingOpportunityAttachments,
    refetch: refetchOpportunityAttachments,
  } = useGetOpportunityAttachmentsQuery(
    { opportunityId: opportunityId, isPublic: true },
    {
      skip: !opportunityId,
    }
  );


  const [updateOpportunityAttachment, { isLoading: isUpdating }] =
    useUpdateOpportunityAttachmentMutation();

  const [purgeOpportunityAttachment, { isLoading: isPurging }] =
    usePurgeOpportunityAttachmentMutation();

  useEffect(() => {
    let attachmentCount = 0;
    for (let i = 0; i < opportunityAttachments.length; i++) {
      if (opportunityAttachments[i].attributes.document) {
        attachmentCount = attachmentCount + 1;
      }
    }

    if (attachmentCount == opportunityAttachments.length) setCanClickNext(true);
    else setCanClickNext(false);
  }, [opportunityAttachments]);

  const [loadingDocuments, setLoadingDocuments] = useState({});
  const [uploadingFilenames, setUploadingFilenames] = useState("");

  const setDocumentToLoadingState = (documentId, state) => {
    const loading = JSON.parse(JSON.stringify(loadingDocuments));
    loading[documentId] = state;
    setLoadingDocuments(loading);
  };

  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles, handleUpload, documentId) => {
      let acceptedFilenames = [];
      for (let i = 0; i < acceptedFiles.length; i++) {
        acceptedFilenames.push(acceptedFiles[i].name);
      }

      // Set loading to true
      setDocumentToLoadingState(documentId, true);

      // Save filename
      setUploadingFilenames(acceptedFilenames);

      handleUpload(acceptedFiles);
    },
    []
  );

  const opportunityDocumentCardTitle = (document) => {
    return document.attributes.filenames.length == 0 ? (
      <InlineStack gap="400">
        <div>
          <Icon tone="subdued" source={AttachmentIcon} />
        </div>
        <Text as="span" fontWeight="semibold">
          {document.attributes.name}
        </Text>
      </InlineStack>
    ) : (
      <InlineStack gap="400">
        <div>
          <Icon tone="success" source={CheckCircleIcon} />
        </div>
        <Text as="span" tone="success">
          <Text as="span" fontWeight="semibold">
            {document.attributes.name}
          </Text>
        </Text>
      </InlineStack>
    );
  };

  const handleAttachment = (uploads, document) => {
    let documentId = document.id;
    let files = uploads;

    return updateOpportunityAttachment({
      opportunityId: opportunityId,
      id: documentId,
      patch: {
        documents: files,
        uploaded_by_id: cookies["fortify-app-contact-id"],
      },
    })
      .unwrap()
      .then(() => {
        refetchOpportunityAttachments();
        setDocumentToLoadingState(documentId, false);
        return { status: "success" };
      })
      .catch((error) => {
        setDocumentToLoadingState(documentId, false);
        console.log(error);
      });
  };

  const purgeAttachment = (document, dIndex) => {
    let documentId = document.id;
    let attachmentId = document.attributes.attachment_id[dIndex];

    setDocumentToLoadingState(documentId, true);

    return purgeOpportunityAttachment({
      opportunityId: opportunityId,
      id: documentId,
      attachmentId: attachmentId,
      dIndex: dIndex,
    })
      .unwrap()
      .then(() => {
        refetchOpportunityAttachments();
        setDocumentToLoadingState(documentId, false);

        console.log("file accepted...");
        return { status: "success" };
      })
      .catch((error) => {
        setDocumentToLoadingState(documentId, false);
        console.log(error);
      });
  };

  return (
    <FadeIn fadeIn>
      <BlockStack>
        <Text variant="headingXl" as="h4">
          Please upload the following attachments
        </Text>
        <br />
      </BlockStack>

      {isLoadingOpportunityAttachments && (
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "304px",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: "calc(50% - 2.5rem)",
              top: "7.5rem",
            }}
          >
            <Spinner size="large" />
          </div>
        </div>
      )}

      {opportunityAttachments &&
        opportunityAttachments.map((document, index) => (
          <>
            <Card key={index}>
              <div>{opportunityDocumentCardTitle(document)}</div>

              <br />

              <div>
                <DirectUploadProvider
                  multiple={false}
                  onSuccess={(uploads) => {
                    handleAttachment(uploads, document);
                  }}
                  render={({ handleUpload, uploads, ready }) => (
                    <>
                      {/* Spinner when loading */}
                      {loadingDocuments[document.id] && (
                        <div
                          style={{
                            position: "relative",
                            minHeight: "118px",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "32px",
                              left: "calc(50% - 22px)",
                            }}
                          >
                            <Spinner
                              accessibilityLabel="Loading documents"
                              size="large"
                            />
                          </div>
                        </div>
                      )}

                      {/* Dropzone and uploaded content */}
                      {!loadingDocuments[document.id] &&
                        (document.attributes.filenames.length > 0 ? (
                          <>
                            <DropZone
                              allowMultiple={true}
                              onDrop={(
                                _dropFiles,
                                acceptedFiles,
                                _rejectedFiles
                              ) => {
                                handleDropZoneDrop(
                                  _dropFiles,
                                  acceptedFiles,
                                  _rejectedFiles,
                                  handleUpload,
                                  document.id
                                );
                              }}
                            >
                              <DropZone.FileUpload actionHint="or drop files to upload" />
                            </DropZone>

                            <br />

                            {document.attributes.filenames.map(
                              (filename, dIndex) => (
                                <>
                                  <div
                                    style={{
                                      backgroundColor: "#F6F6F7",
                                      borderBottom: "1px solid #EBEBEB",
                                      padding: "1em",
                                    }}
                                  >
                                    <InlineStack
                                      gap="400"
                                      align="space-between"
                                      blockAlign="center"
                                      wrap={false}
                                    >
                                      <Text as="p" truncate>
                                        {filename}
                                      </Text>

                                      <InlineStack gap="400" wrap={false}>
                                        {document.attributes.document[dIndex] !=
                                          "-" && (
                                          <Button
                                            url={
                                              document.attributes.document[
                                                dIndex
                                              ]
                                            }
                                            variant="plain"
                                          >
                                            View file
                                          </Button>
                                        )}

                                        {document.attributes.document[dIndex] !=
                                          "-" && (
                                          <Button
                                            icon={DeleteIcon}
                                            onClick={() =>
                                              purgeAttachment(document, dIndex)
                                            }
                                            variant="plain"
                                            tone="critical"
                                          ></Button>
                                        )}
                                      </InlineStack>
                                    </InlineStack>
                                  </div>
                                </>
                              )
                            )}
                          </>
                        ) : (
                          <DropZone
                            allowMultiple={true}
                            onDrop={(
                              _dropFiles,
                              acceptedFiles,
                              _rejectedFiles
                            ) => {
                              handleDropZoneDrop(
                                _dropFiles,
                                acceptedFiles,
                                _rejectedFiles,
                                handleUpload,
                                document.id
                              );
                            }}
                          >
                            <DropZone.FileUpload actionHint="or drop files to upload" />
                          </DropZone>
                        ))}
                    </>
                  )}
                />
              </div>
            </Card>

            <br />
          </>
        ))}

      {(!opportunityAttachments || !opportunityAttachments.length) &&
        !isLoadingOpportunityAttachments && (
          <Card sectioned>
            <EmptyState heading="No documents required">
              <p>
                Looks like we have everything we need. Please continue to the
                next step.
              </p>
            </EmptyState>
          </Card>
        )}
    </FadeIn>
  );
};

UploadAttachments.propTypes = {
  opportunityId: PropTypes.string,
  setCanClickNext: PropTypes.func,
};

export default UploadAttachments;
