import React from "react";
import { useGetOpportunityContactsQuery } from "../../services/api";

import {
  Avatar,
  Spinner,
  LegacyStack,
  Tooltip,
} from "@shopify/polaris";
import PropTypes from "prop-types";

const OpportunityContactAvatarList = (props) => {
  const { opportunityId, onAvatarClick } = props;

  const DESIGNATED_SIGNER_EMOJI = "✍️"

  const {
    data: opportunityContacts = [],
    isLoading,
    isFetching,
  } = useGetOpportunityContactsQuery(opportunityId, { skip: !opportunityId });

  return (
    <LegacyStack spacing={"extraTight"}>
      {isLoading || isFetching ? (
        <Spinner
          size={"small"}
          accessibilityLabel={"Loading opportunity contacts"}
        />
      ) : (
        opportunityContacts.map((contact, index) => {
          const { email, full_name, initials, signer } = contact.attributes;

          return (
            <Tooltip
              content={
                <>
                  {full_name} {email}
                  {signer && (
                    <>
                      {" "}
                      <div>{DESIGNATED_SIGNER_EMOJI} Designated signer</div>
                    </>
                  )}
                </>
              }
              key={`avatar-${index}`}
            >
              <div style={{ position: "relative", cursor: "pointer" }} onClick={onAvatarClick} >
                {signer && (
                  <span
                    style={{
                      position: "absolute",
                      top: "16px",
                      left: "16px",
                      zIndex: 99,
                    }}
                  >
                    {DESIGNATED_SIGNER_EMOJI}
                  </span>
                )}
                <Avatar size="sm" name={full_name} initials={initials} />
              </div>
            </Tooltip>
          );
        })
      )}
    </LegacyStack>
  );
};

OpportunityContactAvatarList.propTypes = {
  onAvatarClick: PropTypes.func,
  opportunityId: PropTypes.string,
};

export default OpportunityContactAvatarList;
