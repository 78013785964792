import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Form,
  FormLayout,
  TextField,
  Text,
  LegacyStack,
  Labelled,
  Modal,
  Box,
  Button,
  InlineStack,
  BlockStack,
} from "@shopify/polaris";

import { useUpdateVendorMutation } from "../../../../services/api";

import { useField, useForm, notEmpty } from "@shopify/react-form";

import CardBoxTitle from "../../../../components/Card/BoxTitle";

const VendorAdminSettingsAccountDetailsTab = (props) => {
  const { vendor } = props;

  const [updateVendor, { isLoading: isUpdating }] = useUpdateVendorMutation();
  const [showEditBasicInfoModal, setShowEditBasicInfoModal] = useState(false);
  const [showEditBillAddressModal, setShowEditBillAddressModal] = useState(false);

  const labelStyles = {
    minHeight: "36px",
    paddingTop: "4px",
  };

  const {
    fields,
    submit,
    submitting,
    reset,
    submitErrors,
    makeClean,
    dirty: vendorFormDirty,
  } = useForm({
    fields: {
      name: useField({
        value: vendor ? vendor.attributes.name : "",
        validates: [notEmpty("Account name is required")],
      }),
      dba: useField({
        value: vendor ? vendor.attributes.dba : "",
        validates: [],
      }),
      ein: useField({
        value: vendor ? vendor.attributes.ein : "",
        validates: [],
      }),
      billing_street: useField({
        value: vendor ? vendor.attributes.billing_street: "",
        validates: [],
      }),
      billing_city: useField({
        value: vendor ? vendor.attributes.billing_city: "",
        validates: [],
      }),
      billing_state: useField({
        value: vendor ? vendor.attributes.billing_state: "",
        validates: [],
      }),
      billing_postal_code: useField({
        value: vendor ? vendor.attributes.billing_postal_code: "",
        validates: [],
      }),
    },
    async onSubmit(form) {
      const updateVendorFormPayload = {
        id: vendor.id,
        ...form
      };

      return updateVendor({ ...updateVendorFormPayload })
        .unwrap()
        .then((response) => {
          makeClean();
          setShowEditBasicInfoModal(false);
          setShowEditBillAddressModal(false);
          return { status: "success" };
        })
        .catch((error) => {
          console.log(error);
          return {
            status: "fail",
            errors: [{ message: "Could not create vendor." }],
          };
        });
    },
  });

  const editBasicInfoModal = (
    <Modal
      open={showEditBasicInfoModal}
      onClose={() => setShowEditBasicInfoModal(false)}
      title={"Edit basic information"}
      large={true}
      loading={submitting || isUpdating}
      primaryAction={{
        content: "Save",
        onAction: submit
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => {
            setShowEditBasicInfoModal(false);
          }
        },
      ]}
    >
      <Modal.Section>
        <Form>
          <FormLayout>
            <FormLayout.Group>
              <TextField label="Legal name" {...fields.name} />
              <TextField label="DBA" {...fields.dba} />
            </FormLayout.Group>

            <FormLayout.Group>
              <TextField label="EIN" {...fields.ein} />
            </FormLayout.Group>
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );

  const editBillAddressModal = (
    <Modal
      open={showEditBillAddressModal}
      onClose={() => setShowEditBillAddressModal(false)}
      title={"Edit billing address"}
      large={true}
      loading={submitting || isUpdating}
      primaryAction={{
        content: "Save",
        onAction: submit
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => {
            setShowEditBillAddressModal(false);
          }
        },
      ]}
    >
      <Modal.Section>
        <Form>
          <FormLayout>
            <FormLayout.Group>
              <TextField label="Street address" {...fields.billing_street} />
            </FormLayout.Group>

            <FormLayout.Group>
              <TextField label="City" {...fields.billing_city} />
              <TextField label="State" {...fields.billing_state} />
              <TextField label="Zip code" {...fields.billing_postal_code} />
            </FormLayout.Group>
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );

  const tab = (
    <Box paddingBlockStart="400">
      <BlockStack gap="400">
        <Card padding="0">
          <CardBoxTitle>
            <InlineStack align="space-between">
              <Text variant="headingMd" as="h6">Basic information</Text>
              <Button
                variant="primary"
                onClick={() => { setShowEditBasicInfoModal(true); }}
              >
                Edit
              </Button>
            </InlineStack>
          </CardBoxTitle>
          <Box padding="400">
            <LegacyStack
              alignment={"trailing"}
              distribution={"fillEvenly"}
              spacing={"loose"}
            >
              <Labelled label="Legal name">
                <div style={labelStyles}>
                  <LegacyStack alignment="trailing" spacing="tight">
                    <LegacyStack alignment="center" spacing="tight">
                      <p>{vendor.attributes.name}</p>
                    </LegacyStack>
                  </LegacyStack>
                </div>
              </Labelled>

              <Labelled label="DBA">
                <div style={labelStyles}>
                  <LegacyStack alignment="trailing" spacing="tight">
                    <LegacyStack alignment="center" spacing="tight">
                      <p>{vendor.attributes.dba || "-"}</p>
                    </LegacyStack>
                  </LegacyStack>
                </div>
              </Labelled>
            </LegacyStack>

            <LegacyStack
              alignment={"trailing"}
              distribution={"fillEvenly"}
              spacing={"loose"}
            >
              <Labelled label="EIN">
                <div style={labelStyles}>
                  <LegacyStack alignment="trailing" spacing="tight">
                    <LegacyStack alignment="center" spacing="tight">
                      <p>{vendor.attributes.ein || "-"}</p>
                    </LegacyStack>
                  </LegacyStack>
                </div>
              </Labelled>
            </LegacyStack>
          </Box>
        </Card>

        <Card padding="0">
          <CardBoxTitle>
            <InlineStack align="space-between">
              <Text variant="headingMd" as="h6">Billing address</Text>
              <Button
                variant="primary"
                onClick={() => { setShowEditBillAddressModal(true); }}
              >
                Edit
              </Button>
            </InlineStack>
          </CardBoxTitle>
          <Box padding="400">
            <div style={{marginTop: "-1.2rem"}}>
              <Text as="span" tone="subdued">Used on finance documents and customer order confirmations.</Text>
            </div>

            <br /><br />

            <Labelled label="Street address">
              <div style={labelStyles}>
                <LegacyStack alignment="trailing" spacing="tight">
                  <LegacyStack alignment="center" spacing="tight">
                    <p>{vendor.attributes.billing_street || "-"}</p>
                  </LegacyStack>
                </LegacyStack>
              </div>
            </Labelled>

            <LegacyStack
              alignment={"trailing"}
              distribution={"fillEvenly"}
              spacing={"loose"}
            >
              <Labelled label="City">
                <div style={labelStyles}>
                  <LegacyStack alignment="trailing" spacing="tight">
                    <LegacyStack alignment="center" spacing="tight">
                      <p>{vendor.attributes.billing_city || "-"}</p>
                    </LegacyStack>
                  </LegacyStack>
                </div>
              </Labelled>

              <Labelled label="State">
                <div style={labelStyles}>
                  <LegacyStack alignment="trailing" spacing="tight">
                    <LegacyStack alignment="center" spacing="tight">
                      <p>{vendor.attributes.billing_state || "-"}</p>
                    </LegacyStack>
                  </LegacyStack>
                </div>
              </Labelled>

              <Labelled label="Zip code">
                <div style={labelStyles}>
                  <LegacyStack alignment="trailing" spacing="tight">
                    <LegacyStack alignment="center" spacing="tight">
                      <p>{vendor.attributes.billing_postal_code || "-"}</p>
                    </LegacyStack>
                  </LegacyStack>
                </div>
              </Labelled>
            </LegacyStack>
          </Box>
        </Card>

        <br /><br />
      </BlockStack>
      {editBasicInfoModal}
      {editBillAddressModal}
    </Box>
  );

  return tab;
};
VendorAdminSettingsAccountDetailsTab.propTypes = {
  vendor: PropTypes.any,
};

export default VendorAdminSettingsAccountDetailsTab;
