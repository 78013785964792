import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  Card,
  Filters,
  FooterHelp,
  Link,
  Page,
  ResourceList,
  ResourceItem,
  Text,
  Box,
  LegacyStack,
  Thumbnail,
  Layout,
  Pagination,
  Truncate,
  Modal,
} from "@shopify/polaris";
import { StoreIcon } from "@shopify/polaris-icons";

import { useGetClientsQuery } from "../../services/api";

import AccountForm from "../../components/Account/Form";
import FadeIn from "../../components/FadeIn";

import { CurrentContactContext } from "../../contexts/Contact";
// import { CurrentTeamContext } from "../../contexts/Team";
import LoadingScreen from "../../components/Auth/Loading";

const AccountsIndex = (props) => {
  const resourceName = {
    singular: "account",
    plural: "accounts",
  };

  // const { currentTeamId } = useContext(CurrentTeamContext);
  const { currentContact, isOrganizationAdmin } = useContext(CurrentContactContext)

  const history = useHistory();

  // const { data: accounts = [], isLoading } = useGetTeamClientsQuery(
  //   currentTeamId,
  //   { skip: !currentTeamId }
  // );

  // const { data: accounts = [], isLoading } = useGetAccountsQuery();

  const { data: accounts = [], isLoading } = useGetClientsQuery();

  const [selectedItems, setSelectedItems] = useState([]);
  const [queryValue, setQueryValue] = useState(undefined);
  const [filteredItems, setFilteredItems] = useState([]);

  const handleQueryValueRemove = useCallback(
    () => setQueryValue(undefined),
    []
  );
  const handleQueryChange = (value) => setQueryValue(value);

  const filterControl = (
    <Filters
      filters={[]}
      queryValue={queryValue}
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryValueRemove}
    />
  );

  useEffect(() => {
    if (queryValue && accounts.length) {
      const results = accounts.filter((item) => {
        return item.attributes.name
          .toLowerCase()
          .includes(queryValue.toLowerCase());
      });

      setFilteredItems(results);
    } else {
      if (!accounts.length) {
        setFilteredItems([]);
      } else {
        setFilteredItems(accounts);
      }
    }

    return () => setFilteredItems([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue, accounts.length]);

  const [currentPage, setCurrentPage] = useState(1);
  const handleNextPage = () => {
    setCurrentPage((state) => {
      if (state == paginatedFilteredItems.length) {
        return paginatedFilteredItems.length;
      }
      return state + 1;
    });
  };
  const handlePreviousPage = () => {
    setCurrentPage((state) => {
      if (state == 1) {
        return 1;
      }
      return state - 1;
    });
  };

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [paginatedFilteredItems, setPaginatedFilteredItems] = useState([]);
  const [currentPageItems, setCurrentPageItems] = useState([]);
  useEffect(() => {
    const perPage = itemsPerPage;

    if (filteredItems.length) {
      const paginatedFilteredItems = filteredItems.reduce(
        (resultArray, item, index) => {
          const pageIndex = Math.floor(index / perPage);
          if (!resultArray[pageIndex]) {
            resultArray[pageIndex] = [];
          }
          resultArray[pageIndex].push(item);
          return resultArray;
        },
        []
      );

      setPaginatedFilteredItems(paginatedFilteredItems);
    }

    return () => setPaginatedFilteredItems([]);
  }, [filteredItems, itemsPerPage]);

  useEffect(() => {
    const pageIndex = currentPage - 1;
    if (paginatedFilteredItems.length) {
      const currentPageItems = paginatedFilteredItems[pageIndex] || [];
      setCurrentPageItems(currentPageItems);
    }

    return () => setCurrentPageItems([]);
  }, [paginatedFilteredItems, currentPage]);

  const accountsResourceList = (
    <ResourceList
      resourceName={resourceName}
      items={currentPageItems}
      renderItem={(item) => renderItem(item, history)}
      selectedItems={selectedItems}
      onSelectionChange={setSelectedItems}
      filterControl={filterControl}
      loading={isLoading}
    />
  );

  const paginationMarkup = (
    <Pagination
      hasPrevious={currentPage != 1}
      onPrevious={handlePreviousPage}
      label={`Page ${currentPage} of ${paginatedFilteredItems.length}`}
      hasNext={currentPage < paginatedFilteredItems.length}
      onNext={handleNextPage}
    />
  );

  const footerHelp = (
    <FooterHelp>
      Learn more about{" "}
      <Link
        external
        url={"https://help.fortifypay.com/how-to-create-an-opportunity"}
      >
        managing accounts
      </Link>
    </FooterHelp>
  );

  const navigateToNewOpportunityShow = (clientId, opportunityId) => {
    history.push({
      pathname: `/clients/${clientId}/opportunities/${opportunityId}`,
      state: {
        showToast: true,
        toastMessage: "Client created.",
      },
    });
  };

  const accountFormRef = useRef();
  const [showAddAccountModal, setShowAddAccountModal] = useState(false);
  const handleCloseModal = () => {
    setShowAddAccountModal(false);
  };

  const [accountFormDirty, setAccountFormDirty] = useState(false);
  const handleAccountEdit = (isDirty) => {
    setAccountFormDirty(isDirty);
  };

  const handleCreateAccount = (account) => {
    handleCloseModal();

    if (account.meta?.redirect_to_opportunity_id && account.id) {
      navigateToNewOpportunityShow(
        account.id,
        account.meta?.redirect_to_opportunity_id
      );
    }
  };

  const addAccountModal = (
    <Modal
      open={showAddAccountModal}
      onClose={handleCloseModal}
      title={"Add client"}
      primaryAction={{
        content: "Save",
        onAction: () => accountFormRef.current.handleSubmitForm(),
        disabled: !accountFormDirty,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleCloseModal,
        },
      ]}
    >
      <AccountForm
        ref={accountFormRef}
        onEdit={handleAccountEdit}
        onCreateOrUpdate={handleCreateAccount}
        isAdminUser={isOrganizationAdmin}
        resourceName={"client"}
        isClient={true}
      />
    </Modal>
  );

  const accountIndexPageMarkup = (
    <>
      <FadeIn fadeIn>
        <Page
          title="Clients"
          secondaryActions={[
            {
              content: "Add client",
              accessibilityLabel: "Add client",
              onAction: () => setShowAddAccountModal(true),
            },
          ]}
        >
          <Layout>
            <Layout.Section>
              <Card padding="0">
                <Box as="section">{accountsResourceList}</Box>
                {paginatedFilteredItems.length > 1 && (
                  <Box as="section" padding="400">
                    <LegacyStack distribution={"center"}>{paginationMarkup}</LegacyStack>
                  </Box>
                )}
              </Card>
            </Layout.Section>
            <Layout.Section>{footerHelp}</Layout.Section>
          </Layout>
          {addAccountModal}
        </Page>
      </FadeIn>
    </>
  );

  return currentContact ? accountIndexPageMarkup : <LoadingScreen />;
};

const renderItem = (item, history) => {
  const {
    id,
    attributes: { name, billing_city, billing_state },
  } = item;
  const media = (
    <Avatar size="sm" name={name} initials={name?.slice(0, 1)} />
  );
  const thumbnail = (
    <Thumbnail source={StoreIcon} size="extraSmall" alt={`Icon of ${name}`} />
  );

  const handleAccountClick = () => {
    navigateToAccountShow();
  };

  const navigateToAccountShow = () => {
    history.push({
      pathname: `/clients/${id}`,
      state: { client: { ...item } },
    });
  };

  const billingCityState =
    billing_city && billing_state
      ? `${billing_city}, ${billing_state}`
      : billing_city
      ? billing_city
      : billing_state
      ? billing_state
      : null;

  return (
    <ResourceItem
      id={id}
      media={thumbnail}
      onClick={handleAccountClick}
      accessibilityLabel={`View details for ${name}`}
      verticalAlignment="center"
    >
      <LegacyStack spacing="extraLoose" alignment="center">
        <LegacyStack.Item fill>
          <Text as="span" fontWeight="semibold" >
            <Truncate>{name}</Truncate>
          </Text>
          <Text variant="bodySm" as="p">
            <Truncate>{billingCityState || "-"}</Truncate>
          </Text>
        </LegacyStack.Item>
      </LegacyStack>
    </ResourceItem>
  );
};

export default AccountsIndex;
