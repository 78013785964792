import React, { useState, useEffect } from "react";
import {
  Tooltip,
} from "@shopify/polaris";
import { formatCurrency } from "../../utilities";

interface PurchaseOptionSegment {
  count: number;
  value?: number;
}

interface ScheduleBarChartProps {
  termLength: number;
  customSegments?: PurchaseOptionSegment[];
  payment: number;
  downPayment: number;
  colorClassIndex?: number;
  wrapWidth?: boolean;
}

const ScheduleBarChart: React.FC<ScheduleBarChartProps> = (props) => {
  const { termLength, customSegments = [], payment, downPayment, colorClassIndex = 5, wrapWidth = false} = props;

  const [chartBlocks, setChartBlocks] = useState([]);

  const colors = [
    { background: "#f6f0fd", hover: "#e3d0ff" },
    { background: "#e0f5f5", hover: "#b7ecec" },
    { background: "#e3f1df", hover: "#bbe5b3" },
    { background: "#fcf1cd", hover: "#ffea8a" },
    { background: "#fcebdb", hover: "#ffc58b" },
    { background: "#fbeae5", hover: "#feaf9a" },
  ];

  const colorClasses = [
    "FortFinancingOption__ScheduleBarChartBlock FortFinancingOption__ScheduleBarChartBlock--red",
    "FortFinancingOption__ScheduleBarChartBlock FortFinancingOption__ScheduleBarChartBlock--yellow",
    "FortFinancingOption__ScheduleBarChartBlock FortFinancingOption__ScheduleBarChartBlock--orange",
    "FortFinancingOption__ScheduleBarChartBlock FortFinancingOption__ScheduleBarChartBlock--purple",
    "FortFinancingOption__ScheduleBarChartBlock FortFinancingOption__ScheduleBarChartBlock--teal",
    "FortFinancingOption__ScheduleBarChartBlock FortFinancingOption__ScheduleBarChartBlock--green",
  ];

  useEffect(() => {
    const customSegmentsTotalCount = customSegments.reduce((sum, segment) => {
      return sum + segment.count;
    }, 0);

    let runningSegmentTotal = 0;
    let colorChoice = 4;
    let blocks = [];

    if (downPayment > 0) {
      blocks.push(
        <div
          key={runningSegmentTotal}
          className={colorClasses[colorChoice]}
        >
          <Tooltip
            content={`Downpayment of ${downPayment}`}
            preferredPosition={"above"}
            dismissOnMouseOut
          >
            <div className="FortFinancingOption__ScheduleBarChartTooltipActivator"></div>
          </Tooltip>
        </div>
      );
    }

    customSegments.forEach((segment, index) => {
      // const blockColor = colors[index] || colors.pop();

      for (let i = 0; i < segment.count; i++) {
        runningSegmentTotal++;
        blocks.push(
          <div
            key={runningSegmentTotal}
            className={colorClasses[colorChoice]}
          >
            <Tooltip
              content={`Segment ${
                index + 1
              } - Month ${runningSegmentTotal} - ${formatCurrency(
                segment.value
              )}`}
              preferredPosition={"above"}
              dismissOnMouseOut
            >
              <div className="FortFinancingOption__ScheduleBarChartTooltipActivator"></div>
            </Tooltip>
          </div>
        );
      }

      // Disable custom segments changing colors
      // colorChoice++;
      // if (colorChoice >= colorClasses.length) {
      //   colorChoice = 0;
      // }
    });

    for (let i = 0; i < termLength - customSegmentsTotalCount; i++) {
      if (termLength > 84) {
        return;
      }

      blocks.push(
        <div
          key={i + 1 + customSegmentsTotalCount}
          className={`FortFinancingOption__ScheduleBarChartBlock ${colorClasses[colorClassIndex]}`}
        >
          <Tooltip
            content={`Month ${
              i + 1 + customSegmentsTotalCount
            } - ${formatCurrency(payment)}`}
            preferredPosition={"above"}
            dismissOnMouseOut
          >
            <div className="FortFinancingOption__ScheduleBarChartTooltipActivator"></div>
          </Tooltip>
        </div>
      );
    }

    setChartBlocks(blocks);
  }, [termLength, customSegments, payment]);

  return (
    <div className={(wrapWidth ? "FortFinancingOption__ScheduleBarChart--wrapWidth " : "") + "FortFinancingOption__ScheduleBarChart"}>{chartBlocks}</div>
  );
};

export default ScheduleBarChart;
