import React from "react";
import PropTypes from "prop-types";

import { Text } from "@shopify/polaris";

const ActivityChunk = (props) => {
  const { index, item } = props;

  const timeString = new Date(item.attributes.created_at).toLocaleString(
    "en-US",
    {
      hour: "numeric",
      minute: "2-digit",
    }
  );

  const getAuthorName = (item) => {
    const { event_type: eventType, author_name: authorName } = item.attributes;

    if (!authorName || authorName === "-" || authorName === " ") {
      return "A guest";
    }

    if (eventType === "OpportunityEnvelopeCompleted") {
      return "Designated signer";
    } else if (eventType === "StripePaymentFailed") {
      return ""
    }

    return authorName;
  };

  const sharedActivityEmails = (item) => {
    const emails = item.attributes.event_type.split(" - ")[1];
    const separateEmails = emails.split(",");
    if (separateEmails.length > 1) {
      return "multiple contacts";
    } else {
      return emails;
    }
  };

  const RenderEventStatus = ({ eventType }) => {
    const statusMap = {
      OpportunityEnvelopeCompleted: "completed",
      FinancingOptionApproved: "approved",
      FinancingOptionUnapproved: "unapproved",
      FinancingOptionViewed: "viewed",
      VendorPaymentAttachmentViewed: "viewed",
      VendorPaymentUpdated: "updated",
      OpportunityNotificationDelivered: "delivered",
      OpportunityNotificationOpen: "opened",
      OpportunityViewed: "viewed",
      OpportunityShared: "shared",
      OpportunityInvoiceUploaded: "uploaded an invoice to",
      OpportunityQuoteUploaded: "uploaded a quote to",
      OpportunityFileUploaded: "uploaded a file to",
      PaymentAuthorizationSaved: "setup a payment method for",
      TransactionCompleted: "completed transaction",
      TransactionCreated: "created new transaction",
      TransactionScheduled: "scheduled transaction",
      TransactionsScheduled: "scheduled transactions",
      TransactionFailed: "has failed transaction",
      StripePaymentReminderSent: "was sent",
      StripePaymentFailed: "failed"
      // Add other event types here as needed
    };

    const selectedEvents = [
      "FinancingOptionSelected",
      "OpportunityNetTermsSelectedSelected",
      "OpportunityStandardFinancingSelected",
      "OpportunityFairMarketValueSelected",
      "OpportunityDollarOutLeaseSelected",
      "OpportunityRentalSelected",
      "OpportunitySoftwareAsAServiceSelected",
    ];

    const createdEvents = [
      "FinancingOptionCreated",
      "OpportunityCreated",
    ];

    if (selectedEvents.includes(eventType)) {
      return "selected";
    }

    if (createdEvents.includes(eventType)) {
      return "created";
    }

    return statusMap[eventType] || eventType;
  };

  const RenderEventType = ({ eventType }) => {
    const typeMap = {
      OpportunityEnvelopeCompleted: "Docusign envelope",
      FinancingOptionApproved: "financing option",
      FinancingOptionUnapproved: "financing option",
      FinancingOptionViewed: "financing option",
      FinancingOptionCreated: "financing option",
      FinancingOptionSelected: "financing option",
      VendorPaymentAttachmentViewed: "quote/invoice",
      VendorPaymentUpdated: "quote/invoice",
      OpportunityNetTermsSelectedSelected: "net terms option",
      OpportunityStandardFinancingSelected: "standard financing option",
      OpportunityFairMarketValueSelected: "fair market value option",
      OpportunityDollarOutLeaseSelected: "dollar out lease option",
      OpportunityRentalSelected: "rental option",
      OpportunitySoftwareAsAServiceSelected: "software as a service option",
      OpportunityViewed: "client portal",
      StripePaymentReminderSent: "a payment reminder"
      // Add other event types here as needed
    };

    return typeMap[eventType] || "";
  };

  const isOpportunityNotification = item.attributes.event_type.includes(
    "OpportunityNotification"
  );

  const RenderEventSubjectName = ({ eventType }) => {
    const noSubjectTypes = [
      "StripePaymentReminderSent",
      "TransactionsScheduled"
    ]

    if (noSubjectTypes.includes(eventType)) {
      return ""
    } else {
      return item.attributes.subject_name
    }
  }

  const isStripePaymentFailed = item.attributes.event_type === "StripePaymentFailed"

  return (
    <li className="FortComments__ListItem" key={`comment-${index}`}>
      <div className="FortComments__Comment">
        <div className="FortComments__ActivityComment">
          <div className="FortComments__Dot"></div>
          <div className="FortComments__ActivityContent">
            <p>
              {isOpportunityNotification && "Email for "}
              {isStripePaymentFailed && "A payment "}
              {getAuthorName(item)} {isOpportunityNotification && " was "}
              <Text as="span" fontWeight="semibold">
                <RenderEventStatus eventType={item.attributes.event_type} />
              </Text>{" "}
              {isOpportunityNotification && "for sharing "}
              {isStripePaymentFailed && "for "}
              <RenderEventSubjectName eventType={item.attributes.event_type} /> {" "}
              <RenderEventType eventType={item.attributes.event_type} />
            </p>
          </div>
          <div className="FortComments__ActivityTime">
            <Text tone="subdued">{timeString}</Text>
          </div>
        </div>
      </div>
    </li>
  );
};

ActivityChunk.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
};

export default ActivityChunk;
