import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Text,
  Box,
  Card,
  Button,
  Badge,
  BlockStack,
  InlineStack,
  useBreakpoints,
} from '@shopify/polaris';

const VendorLockCard = (props) => {
  const {
    team,
    toggleVendorLock
  } = props;

  const [vendorLocked, setVendorLocked] = useState(team?.attributes?.vendor_locked);
  const [isLoadingVendorLock, setIsLoadingVendorLock] = useState(false);
  const handleToggleVendorLock = useCallback(() => {
    setIsLoadingVendorLock(true);
    setVendorLocked((vendorLocked) => !vendorLocked);
    toggleVendorLock();
  }, []);
  const contentStatus = team?.attributes?.vendor_locked ? 'Turn off' : 'Turn on';
  const toggleId = 'setting-toggle-uuid';
  const descriptionId = 'setting-toggle-description-uuid';
  const {mdDown} = useBreakpoints();
  const badgeStatus = team?.attributes?.vendor_locked ? 'success' : undefined;
  const badgeContent = team?.attributes?.vendor_locked ? 'On' : 'Off';

  useEffect(() => {
    if(isLoadingVendorLock){
      setIsLoadingVendorLock(false);
    }
  }, [team]);

  const settingStatusMarkup = (
    <Badge
      tone={badgeStatus}
      toneAndProgressLabelOverride={`Setting is ${badgeContent}`}
    >
      {badgeContent}
    </Badge>
  );

  const settingTitle = (
    <InlineStack gap="200">
      <label htmlFor={toggleId}>
        <div style={{ marginTop: "2.5px" }}>
          <Text variant="headingMd" as="h6">
            Vendor lock
          </Text>
        </div>
      </label>
      {settingStatusMarkup}
    </InlineStack>
  );

  const actionMarkup = (
    <Button
      role="switch"
      id={toggleId}
      ariaChecked={team?.attributes?.vendor_locked ? 'true' : 'false'}
      onClick={handleToggleVendorLock}
      size="slim"
      loading={isLoadingVendorLock}
    >
      {contentStatus}
    </Button>
  );

  const headerMarkup = (
    <Box width="100%">
      <InlineStack align="space-between">
        {settingTitle}
        {!mdDown ? (
          <Box minWidth="fit-content">
            {actionMarkup}
          </Box>
        ) : null}
      </InlineStack>
    </Box>
  );

  const descriptionMarkup = (
    <BlockStack gap="400">
      <Text id={descriptionId} variant="bodyMd" as="p" tone="subdued">
        When vendor lock is on, members can only build opportunities for this vendor.
        When vendor lock is off, members can set the vendor from the quote page.
      </Text>
      {mdDown ? (
        <Box width="100%">
          {actionMarkup}
        </Box>
      ) : null}
    </BlockStack>
  );

  return (
    <Card>
      <BlockStack gap={{xs: '4', sm: '5'}}>
        <Box width="100%">
          <BlockStack gap={{xs: '100', sm: '200'}}>
            {headerMarkup}
            {descriptionMarkup}
          </BlockStack>
        </Box>
        { team?.attributes?.vendor_locked ?
        (<Text variant="bodyMd" as="p">
           This team is locked to {team?.attributes?.vendor_origin_name}
         </Text>)
        : (<></>)}
      </BlockStack>
    </Card>
  );

};

VendorLockCard.propTypes = {
  team: PropTypes.any,
  toggleVendorLock: PropTypes.func,
};

export default VendorLockCard;

