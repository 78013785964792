import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  Page,
  Layout,
  FormLayout,
  InlineStack,
  Button,
  TextField,
  Select,
  BlockStack,
  Labelled,
  Tabs,
  List,
  Link,
  Text,
  Box,
  RangeSlider,
  Divider,
  Bleed,
  RadioButton,
  Tooltip,
} from "@shopify/polaris";
import {
  useField,
  useForm,
  useReset,
  useChoiceField,
  asChoiceField,
  getValues,
} from "@shopify/react-form";
import { ResetMinor } from "@shopify/polaris-icons";
import PropTypes from "prop-types";
import { ResponsivePie } from "@nivo/pie";

import { formatCurrency } from "../../utilities";

import { calculatePaymentAndDiscount } from "../../finance";

const PaymentCalculator = (props) => {

  const {
    solvingMode = "payment",
    totalFinanceAmount = 100000.0,
    baseTermLength = 36,
    customerInterestRate = 5.0,
    basePayment = 0,
    period = "monthly",
    paymentType = "arrears",
    downPayment = 0,
    onCalculatorChange = () => {},
  } = props;

  const paymentTypeOptions = [
    { label: "Advance", value: "advance" },
    { label: "Arrears", value: "arrears" },
  ];

  const { fields } = useForm({
    fields: {
      solving_mode: useField(solvingMode),
      total_finance_amount: useField({
        value: totalFinanceAmount,
        validates: [],
      }),
      base_term_length: useField({
        value: baseTermLength,
        validates: [],
      }),
      customer_interest_rate: useField({
        value: customerInterestRate,
        validates: [],
      }),
      base_payment: useField({
        value: basePayment,
        validates: [],
      }),
      period: useField({
        value: period,
        validates: [],
      }),
      payment_type: useField({
        value: paymentType,
        validates: [],
      }),
      down_payment: useField({
        value: downPayment,
        validates: [],
      }),
    },
    async onSubmit(form) {
      console.log(form);

      return { status: "success" };
    },
  });

  const [netPayment, setNetPayment] = useState(0);
  const [principal, setPrincipal] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);

  const handleChangeCustomerInterestRate = useCallback(
    (newValue) => {
      fields.customer_interest_rate.onChange(newValue / 100);
    },
    [fields.customer_interest_rate]
  );

  const handleChangeCustomerInterestRateTextField = useCallback(
    (newValue) => {
      if (newValue == "-") return;
      fields.customer_interest_rate.onChange(newValue);
    },
    [fields.customer_interest_rate]
  );

  const handleBasePaymentChange = useCallback(
    (newValue) => {
      if (newValue !== fields.base_payment.value) {
        fields.base_payment.onChange(newValue);
      }
    },
    [fields.base_payment]
  );

  const handleChangeBaseTermLength = useCallback(
    (newValue) => {
      fields.base_term_length.onChange(newValue);
    },
    [fields.base_term_length]
  );

  const handleChangeBaseTermLengthTextField = useCallback(
    (newValue) => {
      if (newValue == "-") return;
      fields.base_term_length.onChange(newValue);
    },
    [fields.base_term_length]
  );

  useEffect(() => {
    const calculatedTotalFinanceAmount =
      fields.total_finance_amount.value - fields.down_payment.value;

    if (fields.solving_mode.value == "payment") {
      console.log("solving for payment");

      const customerInterestRateSpreadUpdate = calculatePaymentAndDiscount(
        fields.total_finance_amount.value,
        fields.customer_interest_rate.value,
        fields.customer_interest_rate.value,
        fields.base_term_length.value,
        fields.payment_type.value,
        []
      );

      const { payment: customerPayment } = customerInterestRateSpreadUpdate;

      const netPayment = customerPayment * fields.base_term_length.value;

      handleBasePaymentChange(customerPayment);

      setNetPayment(netPayment);

      const totalInterest = netPayment - calculatedTotalFinanceAmount;
      const principal = calculatedTotalFinanceAmount;

      setTotalInterest(totalInterest);
      setPrincipal(principal);
    } else if (fields.solving_mode.value == "rate") {
      console.log("solving for rate");
    }
  }, [
    fields.base_term_length.value,
    fields.customer_interest_rate.value,
    fields.down_payment.value,
    fields.payment_type.value,
    fields.solving_mode.value,
    fields.total_finance_amount.value,
    handleBasePaymentChange,
  ]);

  // on every change, call a function that calls a prop function with
  // the calculators updated values

  useEffect(() => {
    onCalculatorChange(getValues(fields))
  });

  const nivoChartData = [
    {
      id: "principal",
      label: "Principal",
      value: principal,
      color: "tomato",
    },
    {
      id: "interest",
      label: "Interest",
      value: Math.round(totalInterest * 100) / 100,
      color: "blue",
    },
    Number(fields.down_payment.value) > 0
      ? {
          id: "down",
          label: "Down",
          value: Number(fields.down_payment.value),
          color: "green",
        }
      : null,
  ].filter((data) => data !== null);

  const DataDisplayField = ({ label, value }) => {
    return (
      <Box>
        <InlineStack>
          <Tooltip hasUnderline={true} content={"Base payment description"}>
            <Text as={"h2"} variant={"bodyMd"} fontWeight="medium">
              {label}
            </Text>
          </Tooltip>
        </InlineStack>

        <InlineStack blockAlign={"center"}>
          <Text variant={"headingLg"}>
            {value || "-"}
          </Text>
          <div style={{ minHeight: "2rem" }}></div>
        </InlineStack>
      </Box>
    );
  };
  DataDisplayField.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
  };

  const pageMarkup = (
    <Page>
      <Layout>
        <Layout.Section>
          <Card padding={400}>
            <BlockStack gap="400">
              <Text variant="headingMd" as="h6">
                Calculator
              </Text>

              <BlockStack gap="400">
                <FormLayout>
                  <FormLayout.Group>
                    <RadioButton
                      label="Solve for payment"
                      {...asChoiceField(fields.solving_mode, "payment")}
                    />
                    <RadioButton
                      label="Solve for interest rate"
                      {...asChoiceField(fields.solving_mode, "rate")}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </BlockStack>

              <Bleed marginInline="400">
                <Divider />
              </Bleed>

              <BlockStack gap="400">
                <FormLayout>
                  <TextField
                    autoComplete="off"
                    label="Price"
                    type="number"
                    prefix="$"
                    {...fields.total_finance_amount}
                  />

                  <TextField
                    autoComplete="off"
                    label="Down payment"
                    type="number"
                    prefix="$"
                    {...fields.down_payment}
                  />

                  <RangeSlider
                    label={"Term length"}
                    min={1}
                    max={120}
                    step={1}
                    value={fields.base_term_length.value}
                    onChange={handleChangeBaseTermLength}
                    error={fields.base_term_length.error}
                    prefix={
                      <div className="FortCalculator__SellRateTextField">
                        <TextField
                          label=""
                          type="number"
                          autoComplete="off"
                          onChange={handleChangeBaseTermLengthTextField}
                          onBlur={fields.base_term_length.onBlur}
                          value={fields.base_term_length.value}
                        />
                      </div>
                    }
                  />

                  {fields.solving_mode.value == "rate" ? (
                    <TextField
                      autoComplete="off"
                      label="Payment"
                      type="number"
                      prefix="$"
                      disabled={fields.solving_mode.value == "payment"}
                      {...fields.base_payment}
                    />
                  ) : (
                    <DataDisplayField
                      label={"Payment"}
                      value={formatCurrency(fields.base_payment.value)}
                    />
                  )}
                </FormLayout>
              </BlockStack>

              <Bleed marginInline="400">
                <Divider />
              </Bleed>

              <BlockStack gap="400">
                <FormLayout>
                  <Select
                    label="Payment Type"
                    options={paymentTypeOptions}
                    {...fields.payment_type}
                  />

                  <RangeSlider
                    label="Customer interest rate"
                    min={0}
                    max={30 * 100}
                    step={1}
                    value={fields.customer_interest_rate.value * 100}
                    onChange={handleChangeCustomerInterestRate}
                    error={fields.customer_interest_rate.error}
                    prefix={
                      <div className="FortCalculator__SellRateTextField">
                        <TextField
                          label=""
                          value={fields.customer_interest_rate.value.toString()}
                          onChange={handleChangeCustomerInterestRateTextField}
                          step={0.01}
                          suffix="%"
                          autoComplete="off"
                          type="number"
                        />
                      </div>
                    }
                    suffix={
                      <Button
                        accessibilityLabel="Reset sell rate to default"
                        icon={ResetIcon}
                        disabled={!fields.customer_interest_rate.dirty}
                        onClick={useReset(fields.customer_interest_rate)}
                      />
                    }
                  />
                </FormLayout>
              </BlockStack>
            </BlockStack>
          </Card>
        </Layout.Section>

        <Layout.Section variant={"oneThird"}>
          <BlockStack gap="400">
            <Card padding={400}>
              <BlockStack gap="400">
                <Text variant="headingMd" as="h6">
                  Chart - nivo
                </Text>
                <div style={{ width: "100%", height: 300 }}>
                  <ResponsivePie
                    data={nivoChartData}
                    colors={{ scheme: "accent" }}
                    margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                    innerRadius={0.5}
                    padAngle={0.7}
                    cornerRadius={3}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 0.2]],
                    }}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2}
                    arcLinkLabelsColor={{ from: "color" }}
                    arcLabelsSkipAngle={10}
                    arcLabelsTextColor={{
                      from: "color",
                      modifiers: [["darker", 2]],
                    }}
                    valueFormat={(value) => formatCurrency(value)}
                    defs={[
                      {
                        id: "dots",
                        type: "patternDots",
                        background: "inherit",
                        color: "rgba(255, 255, 255, 0.3)",
                        size: 4,
                        padding: 1,
                        stagger: true,
                      },
                      {
                        id: "lines",
                        type: "patternLines",
                        background: "inherit",
                        color: "rgba(255, 255, 255, 0.3)",
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10,
                      },
                    ]}
                  />
                </div>
              </BlockStack>
            </Card>

            <Card padding={400}>
              <BlockStack gap="400">
                <Text variant="headingMd" as="h6">
                  Summary
                </Text>

                <BlockStack gap="400">
                  <InlineStack align="space-between">
                    <Text as="p">Total of payments</Text>

                    <Text as="p">{formatCurrency(netPayment)}</Text>
                  </InlineStack>

                  <InlineStack align="space-between">
                    <Text as="p">Down payment</Text>

                    <Text as="p">
                      {formatCurrency(fields.down_payment.value)}
                    </Text>
                  </InlineStack>

                  <InlineStack align="space-between">
                    <Text as="p">Principal</Text>

                    <Text as="p">{formatCurrency(principal)}</Text>
                  </InlineStack>

                  <InlineStack align="space-between">
                    <Text as="p">Interest</Text>

                    <Text as="p">{formatCurrency(totalInterest)}</Text>
                  </InlineStack>
                </BlockStack>
              </BlockStack>
            </Card>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </Page>
  );

  return pageMarkup;
};

export default PaymentCalculator;
