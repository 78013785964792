import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  IndexTable,
  Text,
  Link,
  Badge,
  Icon,
  Tooltip,
  List,
} from "@shopify/polaris";
import { NoteIcon } from "@shopify/polaris-icons";

const EnvelopeIndexTable = (props) => {
  const {
    templates,
    handleSelectionChange,
    allSelected,
    selectedResources,
    isFetchingTemplates,
    isCreatingEnvelopeTemplate,
    handleOpenConfirmationModal,
  } = props;

  const resourceName = {
    singular: 'envelope template',
    plural: 'envelope template',
  };

  const promotedBulkActions = [
    {
      content: "Delete envelope templates",
      onAction: () => handleOpenConfirmationModal("envelope"),
    },
  ];

  const rowMarkup = (templates.length > 0 ? templates.map((item, index) => (
    <IndexTable.Row
      id={`envelope-${index}`}
      key={index}
      position={index}
      selected={selectedResources.includes(`envelope-${index}`)}
    >
      <IndexTable.Cell>
        <Text variant="strong" fontWeight="bold" truncate>
          <Link
            removeUnderline
            monochrome
            // onClick={()=>handleVendorProgramClick(item)}
          >
            {item.attributes.financing_type}
          </Link>
        </Text>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Text as="p">
          {item.attributes.countersignature_required ? (
            <Badge tone="success">Required</Badge>
          ) : (
            <Badge>Not Required</Badge>
          )}
        </Text>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Tooltip content={
            <List type="bullet">
              {(item.attributes.document_names.length > 0 ? item.attributes.document_names.map((docu, iindex) => (
                <List.Item
                  key={iindex}
                >{docu}</List.Item>
              ))
              : [] )}
            </List>
          }>
          <div style={{width: "1.4rem"}}>
            <Icon
              source={NoteIcon}
              tone="base"
            />
         </div>
        </Tooltip>
      </IndexTable.Cell>
    </IndexTable.Row>
    ),
  ) : []);

  return (
    <div>
      <IndexTable
        resourceName={resourceName}
        itemCount={templates.length}
        selectable={true}
        onSelectionChange={(selectType, toggleType, selection) => handleSelectionChange(selectType, toggleType, selection, "envelope")}
        selectedItemsCount={allSelected ? "All" : selectedResources.length}
        promotedBulkActions={promotedBulkActions}
        loading={isFetchingTemplates || isCreatingEnvelopeTemplate}
        headings={[
          {title: 'Type'},
          {title: 'Countersignature'},
          {title: 'Documents'},
        ]}
        sortable={[false, false, false]}
        defaultSortDirection={"descending"}
      >
        {rowMarkup}
      </IndexTable>
    </div>
  );
};

EnvelopeIndexTable.propTypes = {
  templates: PropTypes.array,
  handleOpenConfirmationModal: PropTypes.func,
  handleSelectionChange: PropTypes.func,
  allSelected: PropTypes.bool,
  selectedResources: PropTypes.array,
  isFetchingTemplates: PropTypes.bool,
  isCreatingEnvelopeTemplate: PropTypes.bool,
}

export default EnvelopeIndexTable;
