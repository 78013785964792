import React, {
  useImperativeHandle,
  forwardRef,
  useEffect,
  useState,
  useCallback,
} from "react";
import {
  FormLayout,
  TextField,
  Checkbox,
  Select,
  Spinner,
  Form,
  Button,
  Banner,
  Modal,
  Text,
  BlockStack,
} from "@shopify/polaris";
import {
  useField,
  useForm,
  notEmpty,
  asChoiceField,
  useDynamicList,
} from "@shopify/react-form";
import PropTypes from "prop-types";
import { PlusCircleIcon } from "@shopify/polaris-icons";

import {
  useCreateRateCardMutation,
  useUpdateRateCardMutation,
} from "../../services/api";
import DatePickerPopUp from "../DatePickerPopUp";
import LenderRateForm from "../LenderRate/Form";

const RateCardForm = forwardRef((props, ref) => {
  const { lenderId, rateCard, lenderRates, lastRateCard, setDisableRateCardSave } = props;

  const [createRateCard, { isLoading: isCreatingRateCard }] =
    useCreateRateCardMutation();

  const [updateRateCard, { isLoading: isUpdatingRateCard }] =
    useUpdateRateCardMutation();

  const [ownedRates, setOwnedRates] = useState([]);

  const emptyOwnedRateFactory = () => ({
    minimum_lending_amount: undefined,
    maximum_lending_amount: undefined,
    grade: "",
    term_length_minimum: undefined,
    term_length_maximum: undefined,
    interest_rate: undefined,
    financing_type: "Standard financing",
    id: "",
  });

  const {
    fields: ownedRatesFields,
    addItem: addOwnedRateItem,
    removeItem: removeOwnedRateItem,
  } = useDynamicList(
    {
      list: ownedRates,
      validates: {
        minimum_lending_amount: (minimum_lending_amount) => {
          if (!minimum_lending_amount || minimum_lending_amount < 0) {
            return "Min amount is required";
          }
        },
        maximum_lending_amount: (maximum_lending_amount) => {
          if (maximum_lending_amount < 1) {
            return "Max amount is required";
          }
        },
        grade: [notEmpty("Amount is required.")],
        term_length_minimum: (term_length_minimum) => {
          if (term_length_minimum < 1) {
            return "Min term is required";
          }
        },
        term_length_maximum: (term_length_maximum) => {
          if (term_length_maximum < 1) {
            return "Max term is required";
          }
        },
        interest_rate: (interest_rate) => {
          if (!interest_rate || interest_rate < 0) {
            return "Interest rate is required";
          }
        },
        financing_type: [notEmpty("Financing type is required.")],
      },
    },
    emptyOwnedRateFactory
  );

  useEffect(() => {
    if (rateCard) {
      // Build owned rates list
      let oRates = [];
      let includedRates = rateCard?.relationships?.rates?.data;
      for (let i = 0; i < includedRates.length; i++) {
        let newRate = {
          ...emptyOwnedRateFactory(),
          ...lenderRates[includedRates[i].id].attributes,
          id: includedRates[i].id,
        };
        oRates.push(newRate);
      }
      setOwnedRates(oRates);
    }
  }, [rateCard, lenderRates]);

  const [deletedRates, setDeletedRates] = useState([]);
  const addDeletedRate = (id) => {
    if (id.length > 0) {
      let dRates = JSON.parse(JSON.stringify(deletedRates));
      dRates.push({ id: id, _destroy: "1" });
      setDeletedRates(dRates);
    }
  };

  const {
    fields,
    submit,
    submitting,
    reset,
    submitErrors,
    makeClean,
    dirty: accountFormDirty,
  } = useForm({
    fields: {
      version_number: useField({
        value: rateCard ? rateCard.attributes?.version_number : "",
        validates: [],
      }),
      version_name: useField({
        value: rateCard ? rateCard.attributes?.version_name : "",
        validates: [notEmpty("Version name is required")],
      }),
      effective_date: useField({
        value: rateCard ? rateCard.attributes?.effective_date : "",
        validates: [],
      }),
      is_current: useField({
        value: rateCard ? rateCard.attributes?.is_current : true,
        validates: [],
      }),
      rates_attributes: ownedRatesFields,
    },
    async onSubmit(form) {
      // Add check for submitErrors
      if(submitErrors.length > 0) return;

      let submitForm = { ...form };

      // Not updatable
      delete submitForm.version_number;

      // Build rates
      let uRates = toUpdateableRates().concat(deletedRates);
      // let uRates = []
      submitForm.rates_attributes = uRates;

      if (rateCard?.id?.length > 0) {
        return updateRateCard({
          lenderId: lenderId,
          id: rateCard.id,
          ...submitForm,
        })
          .unwrap()
          .then(() => {
            return { status: "success" };
          })
          .catch((error) => {
            return { status: "fail", errors: [error] };
          });
      } else {
        return createRateCard({
          lenderId: lenderId,
          ...submitForm,
        })
          .unwrap()
          .then(() => {
            return { status: "success" };
          })
          .catch((error) => {
            return { status: "fail", errors: [error] };
          });
      }
    },
  });

  useImperativeHandle(ref, () => ({
    handleSubmitForm() {
      if(submitErrors.length > 0) {

      } else {
        submit();
      }
    },
  }));

  const toUpdateableRates = useCallback(() => {
    let uRates = [];
    for (let i = 0; i < ownedRatesFields.length; i++) {
      let r = {
        minimum_lending_amount:
          ownedRatesFields[i].minimum_lending_amount.value,
        maximum_lending_amount:
          ownedRatesFields[i].maximum_lending_amount.value,
        grade: ownedRatesFields[i].grade.value,
        term_length_minimum: ownedRatesFields[i].term_length_minimum.value,
        term_length_maximum: ownedRatesFields[i].term_length_maximum.value,
        interest_rate: ownedRatesFields[i].interest_rate.value,
        financing_type: ownedRatesFields[i].financing_type.value,
      };
      if (ownedRatesFields[i].id.value.length > 0)
        r.id = ownedRatesFields[i].id.value;
      uRates.push(r);
    }
    return uRates;
  }, [ownedRatesFields]);

  // Disable save button if errors exist
  // Submit Errors
  useEffect(() => {
    if (submitErrors.length > 0) setDisableRateCardSave(true)
    else setDisableRateCardSave(false)
  }, [submitErrors])

  // Disable save button if errors exist
  // On fields
  useEffect(() => {
    let errorPresent = false
    for(let i = 0; i < Object.keys(fields).length; i++) {
      let cKey = Object.keys(fields)[i]
      if (cKey === 'rates_attributes') {
        for(let currentRateIndex = 0; currentRateIndex < fields.rates_attributes.length; currentRateIndex++) {
          let currentRate = fields.rates_attributes[currentRateIndex]
          for(let j = 0; j < Object.keys(currentRate).length; j++) {
            let cjKey = Object.keys(currentRate)[j]
            if (currentRate[cjKey].error != undefined && currentRate[cjKey].error?.length > 0) {
              errorPresent = true
              break
            }
          }
        }
      } else {
        if (fields[cKey].error != undefined) {
          errorPresent = true
          break
        }
      }
    }

    if(errorPresent) setDisableRateCardSave(true)
    else setDisableRateCardSave(false)
  }, [fields])

  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const errorBanner = submitErrors.length > 0 && showErrorBanner && (
    <Banner tone="critical" title="Something went wrong">
      {submitErrors.map((error, index) => {
        return <p key={index}>{error.message}</p>;
      })}
    </Banner>
  );

  return (
    <>
      <Modal.Section>
        <Form onSubmit={submit}>
          <FormLayout>
            {errorBanner}
            <FormLayout.Group condensed>
              <TextField
                autoComplete="false"
                label="Version"
                type="number"
                placeholder={
                  lastRateCard
                    ? lastRateCard.attributes?.version_number + 1
                    : "1"
                }
                {...fields.version_number}
                disabled
              />

              <TextField
                autoComplete="false"
                label="Name"
                {...fields.version_name}
                requiredIndicator
              />

              <DatePickerPopUp
                datePickerLabel={"Effective date"}
                placeholder="mm/dd/yyyy"
                setEditingDate={(rubyStringDate) => {
                  fields.effective_date.onChange(rubyStringDate);
                }}
                initialDate={fields.effective_date}
              />
            </FormLayout.Group>

            <Checkbox
              label="Currently active"
              {...asChoiceField(fields.is_current)}
            />
          </FormLayout>
        </Form>
      </Modal.Section>

      <Modal.Section>
        <BlockStack align="start" gap="400">
          {ownedRatesFields.map((lenderRate, index) => {
            return (
              <LenderRateForm
                key={index}
                index={index}
                lenderRate={lenderRate}
                addDeletedRate={addDeletedRate}
                removeOwnedRateItem={removeOwnedRateItem}
              />
            );
          })}

          <FormLayout>
            <Button
              variant="plain"
              icon={PlusCircleIcon}
              onClick={() => addOwnedRateItem()}
            >
              Add rate
            </Button>
          </FormLayout>
        </BlockStack>
      </Modal.Section>
    </>
  );
});
RateCardForm.displayName = "RateCardForm";

RateCardForm.propTypes = {
  lenderId: PropTypes.string,
  rateCard: PropTypes.object,
  lenderRates: PropTypes.object,
  lastRateCard: PropTypes.any,
  setDisableRateCardSave: PropTypes.func,
};

export default RateCardForm;
