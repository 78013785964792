import React from "react";

import {
  SkeletonPage,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  Layout,
  BlockStack,
  Card,
} from "@shopify/polaris";
import PropTypes from "prop-types";

const OpportunityShowSkeletonPage = (props) => {
  const { opportunity, withPage } = props;

  const layoutMarkup = (
    <Layout>
      <Layout.Section>
        <Card>

          <div>
            <BlockStack>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </BlockStack>
          </div>
          <div>
            <SkeletonBodyText />
            <SkeletonBodyText />
          </div>
            <SkeletonBodyText />
            <SkeletonBodyText />
        </Card>
      </Layout.Section>

      <Layout.Section>
        <Card >
          <BlockStack>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText />
          </BlockStack>
        </Card>
        <Card >
          <BlockStack>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText />
          </BlockStack>
        </Card>
      </Layout.Section>

      <Layout.Section variant="oneThird">
        <Card>
            <BlockStack>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={2} />
            </BlockStack>
        </Card>
        <Card  background="bg-surface-secondary">
        </Card>
        <Card  background="bg-surface-secondary">
            <BlockStack>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={2} />
            </BlockStack>
        </Card>
      </Layout.Section>
    </Layout>
  );

  if (withPage) {
    return opportunity?.attributes?.name ? (
      <Page title={opportunity.attributes.name}>{layoutMarkup}</Page>
    ) : (
      <SkeletonPage primaryAction>{layoutMarkup}</SkeletonPage>
    );
  } else {
    return layoutMarkup;
  }
};

OpportunityShowSkeletonPage.propTypes = {
  opportunity: PropTypes.object,
  withPage: PropTypes.bool,
};

export default OpportunityShowSkeletonPage;
