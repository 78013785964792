import React from "react";
import { Transition } from "react-transition-group";
import PropTypes from "prop-types";
import { Page } from "@shopify/polaris";

const FadeIn = ({ fadeIn = false, children, duration = 300 }) => {
  const defaultStyle = {
    opacity: 0,
    transition: `opacity ${duration}ms ease-in-out`,
  };

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  return (
    <Transition
      in={fadeIn}
      timeout={{
        appear: duration / 2,
        enter: duration,
        exit: duration / 2,
      }}
      mountOnEnter
      unmountOnExit
      appear={true}
      exit={true}
    >
      {(state) => {
        return (
          <>
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[state],
              }}
            >
              {children}
            </div>

            {/* I have no clue why this needs to be here... 
            fade in only seems to work if there is a secondary
            page action. 🤷‍♂️ */}
            <div style={{ display: "none" }}>
              <Page secondaryActions={[{ content: "" }]}></Page>
            </div>
          </>
        );
      }}
    </Transition>
  );
};
FadeIn.propTypes = {
  duration: PropTypes.number,
  fadeIn: PropTypes.bool,
  children: PropTypes.any,
};

export default FadeIn;
