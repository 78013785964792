import React, {
  useImperativeHandle,
  forwardRef,
  useEffect,
  useState,
  useCallback,
} from "react";

import {
  FormLayout,
  TextField,
  Select,
  Spinner,
  Form,
  Button,
  Banner,
  Checkbox,
  Link,
  BlockStack,
} from "@shopify/polaris";

import {
  useField,
  useChoiceField,
  asChoiceField,
  useForm,
  useSubmit,
  useDirty,
  notEmpty,
  makeCleanFields,
} from "@shopify/react-form";

import PropTypes from "prop-types";

import {
  useGetOpportunityCreditSubmissionQuery,
  useUpdateOpportunityCreditSubmissionMutation,
} from "../../services/api";

const DeclinedForm = forwardRef((props, ref) => {
  const {
    creditSubmissionId,
    creditSubmission: creditSubmissionObject = { attributes: {} },
    opportunityId,
    onUpdate = () => {},
    onEdit = () => {},
  } = props;

  const { data: creditSubmission = creditSubmissionObject, isLoading } =
    useGetOpportunityCreditSubmissionQuery(
      { id: creditSubmissionId, opportunityId: opportunityId },
      {
        skip: !creditSubmissionId,
      }
    );

  const [updateCreditSubmission, { isLoading: isUpdatingCreditSubmision }] =
    useUpdateOpportunityCreditSubmissionMutation();

  const endOptionOptions = ["EFA", "FMV", "$1 Out"];

  const declineOptions = [
    {
      label: "Insufficient credit history",
      value: "insufficient_credit_history",
    },
  ];

  const fields = {
    decline_reason: useField({
      value: creditSubmission.attributes.decline_reason || undefined,
      validates: [],
    }),
    notes: useField({
      value: creditSubmission.attributes.notes,
      validates: [],
    }),
  };

  const approvedFormDirty = useDirty(fields);

  const { submit, submitting, errors, setErrors } = useSubmit(async (form) => {
    console.log(form);

    return updateCreditSubmission({
      id: creditSubmissionId,
      opportunityId: opportunityId,
      ...form,
    })
      .then(({ data }) => {
        makeCleanFields(fields);
        onUpdate();
        return { status: "success" };
      })
      .catch((error) => {
        console.log(error);
      });
  }, fields);

  useEffect(() => {
    onEdit(approvedFormDirty);
  }, [onEdit, approvedFormDirty]);

  useImperativeHandle(ref, () => ({
    handleSubmitForm() {
      submit();
    },
  }));

  const errorBanner = errors.length > 0 && (
    <BlockStack>
      <Banner tone="critical" title="Something went wrong">
        {errors.map((error, index) => {
          return (
            <p key={index}>
              {error.message}{" "}
              {error.account_id && (
                  <Link url={`/admin/vendors/${error.account_id}`}>
                    {error.account_name}
                  </Link>
                ) &&
                " vendor account."}
            </p>
          );
        })}
      </Banner>
    </BlockStack>
  );

  return isLoading ? (
    <Spinner />
  ) : (
    <Form onSubmit={submit}>
      <FormLayout>
        {errorBanner}
        <TextField
          label="Decline reason"
          multiline={5}
          autoComplete="off"
          {...fields.decline_reason}
        />

        {/* polaris-migrator: Unable to migrate the following expression. Please upgrade manually. */}
        <TextField
          label="Notes"
          multiline={5}
          autoComplete="off"
          {...fields.notes}
        />
        {approvedFormDirty && (
          <Button  submit variant="primary">
            Save changes
          </Button>
        )}
      </FormLayout>
    </Form>
  );
});
DeclinedForm.displayName = "DeclinedForm";

DeclinedForm.propTypes = {
  creditSubmissionId: PropTypes.string,
  creditSubmission: PropTypes.object,
  opportunityId: PropTypes.string,
  onEdit: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default DeclinedForm;
