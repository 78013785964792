import React from "react";
import { Button, Spinner } from "@shopify/polaris";
import { isMobile } from "react-device-detect";
import PropTypes from "prop-types";

import FatalError from "../../components/FatalError";

const InvoiceEmbed = (props) => {
  const {
    invoiceContentType = "application/pdf",
    invoiceUrl,
    fullHeight = false,
  } = props;

  if (!invoiceUrl) {
    return <FatalError hideRefreshAction />;
  }

  const pdfEmbed = isMobile ? (
    <Button url={invoiceUrl} external>
      Open quote/invoice
    </Button>
  ) : (
    <div
      style={{
        position: "relative",
        overflow: "hidden",
        height: 0,
        // this is the ratio of height to width of a
        // Letter sized piece of paper with an addt'l 56px
        // of height for toolbar
        paddingBottom: fullHeight
          ? "max(calc(129% + 56px), 1293px)"
          : "max(calc(120% + 56px), 800px)",
        border: "2px #525659 solid",
        borderTop: "none",
        maxHeight: fullHeight ? "1200px" : "800px",
      }}
    >
      {window.chrome && <div className="FortPdfOverlay"></div>}
      <embed
        src={`${invoiceUrl}#pagemode=none&navpanes=0&scrollbar=0&statusbar=0&messages=0&toolbar=1&scrollbar=0`}
        type="application/pdf"
        width="100%"
        height="100%"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          maxWidth: "100%",
        }}
      />
    </div>
  );

  const imageEmbed = (
    <img
      alt=""
      width="100%"
      height="fit-content"
      style={{
        objectFit: "cover",
        objectPosition: "center",
        width:"100%",
        height:"fit-content"
      }}
      src={`${invoiceUrl}?`}
    />
  );

  switch (invoiceContentType) {
    case "application/pdf":
      return pdfEmbed;
    case "image/png":
    case "image/jpeg":
      return imageEmbed;
    default:
      return pdfEmbed;
  }
};

InvoiceEmbed.propTypes = {
  fullHeight: PropTypes.bool,
  invoiceContentType: PropTypes.string,
  invoiceUrl: PropTypes.string,
};

export default InvoiceEmbed;
