import React, { useState, useEffect, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";

import {
  Badge,
  Box,
  Card,
  Filters,
  Page,
  ResourceList,
  ResourceItem,
  Text,
  InlineStack,
  Layout,
  Pagination,
  Truncate,
} from "@shopify/polaris";

import { useGetAdminTeamsQuery } from "../../../services/api";
import FadeIn from "../../../components/FadeIn";
import LoadingScreen from "../../../components/Auth/Loading";

import { CurrentContactContext } from "../../../contexts/Contact";

const AdminTeams = () => {
  const resourceName = {
    singular: "team",
    plural: "teams",
  };

  const { currentContact } = useContext(CurrentContactContext)

  const history = useHistory();

  const { data: teams = [], isLoading: isLoadingTeam } =
    useGetAdminTeamsQuery();

  const [selectedItems, setSelectedItems] = useState([]);
  const [queryValue, setQueryValue] = useState(undefined);
  const [filteredItems, setFilteredItems] = useState([]);

  const handleQueryValueRemove = useCallback(
    () => setQueryValue(undefined),
    []
  );
  const handleQueryChange = (value) => setQueryValue(value);

  // const [selectedTab, setSelectedTab] = useState(0);
  // const handleTabChange = useCallback(
  //   (selectedTabIndex) => setSelectedTab(selectedTabIndex),
  //   []
  // );

  const filterControl = (
    <Filters
      filters={[]}
      queryValue={queryValue}
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryValueRemove}
    />
  );

  useEffect(() => {
    let filtered = [];

    if (queryValue && teams.length) {
      const results = teams.filter((item) => {
        return item.attributes.name
          .toLowerCase()
          .includes(queryValue.toLowerCase());
      });

      filtered = results;
    } else {
      if (!teams.length) {
        filtered = [];
      } else {
        filtered = teams;
      }
    }

    setFilteredItems(filtered);

    return () => setFilteredItems([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue, teams.length]);

  const [currentPage, setCurrentPage] = useState(1);
  const handleNextPage = () => {
    setCurrentPage((state) => {
      if (state == paginatedFilteredItems.length) {
        return paginatedFilteredItems.length;
      }
      return state + 1;
    });
  };
  const handlePreviousPage = () => {
    setCurrentPage((state) => {
      if (state == 1) {
        return 1;
      }
      return state - 1;
    });
  };

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [paginatedFilteredItems, setPaginatedFilteredItems] = useState([]);
  const [currentPageItems, setCurrentPageItems] = useState([]);
  useEffect(() => {
    const perPage = itemsPerPage;

    const paginatedFilteredItems = filteredItems.reduce(
      (resultArray, item, index) => {
        const pageIndex = Math.floor(index / perPage);
        if (!resultArray[pageIndex]) {
          resultArray[pageIndex] = [];
        }
        resultArray[pageIndex].push(item);
        return resultArray;
      },
      []
    );

    setPaginatedFilteredItems(paginatedFilteredItems);
  }, [filteredItems, itemsPerPage]);

  useEffect(() => {
    const pageIndex = currentPage - 1;
    const currentPageItems = paginatedFilteredItems[pageIndex] || [];

    setCurrentPageItems(currentPageItems);
  }, [paginatedFilteredItems, currentPage]);

  const teamsResourceList = (
    <ResourceList
      resourceName={resourceName}
      items={currentPageItems}
      renderItem={(item) => renderItem(item, history)}
      selectedItems={selectedItems}
      onSelectionChange={setSelectedItems}
      filterControl={filterControl}
      loading={isLoadingTeam}
      flushFilters
    />
  );

  const paginationMarkup = (
    <Pagination
      hasPrevious={currentPage != 1}
      onPrevious={handlePreviousPage}
      label={`Page ${currentPage} of ${paginatedFilteredItems.length}`}
      hasNext={currentPage < paginatedFilteredItems.length}
      onNext={handleNextPage}
    />
  );

  const accountIndexPageMarkup = (
    <FadeIn fadeIn>
      <Page title="Teams">
        <Layout>
          <Layout.Section>
            <Card padding="0">
              {teamsResourceList}
              <Box padding="400">
              {paginatedFilteredItems.length > 1 && (
                <InlineStack align="center">{paginationMarkup}</InlineStack>
              )}
              </Box>
            </Card>
          </Layout.Section>
        </Layout>
        {/* {addAccountModal} */}
      </Page>
    </FadeIn>
  );

  return currentContact ? accountIndexPageMarkup : <LoadingScreen />;
};

const renderItem = (item, history) => {
  const {
    id,
    attributes: { name, vendor_locked },
  } = item;

  const handleTeamClick = () => {
    navigateToAdminTeamShow();
  };

  const navigateToAdminTeamShow = () => {
    history.push({
      pathname: `/admin/teams/${id}`,
      state: { team: { ...item } },
    });
  };

  return (
    <ResourceItem
      id={id}
      onClick={handleTeamClick}
      accessibilityLabel={`View details for ${name}`}
      verticalAlignment="center"
    >
      <InlineStack gap="200">
        <Box paddingInlineStart="300">
          <Text as="span" fontWeight="semibold" >
            <Truncate>{name}</Truncate>
          </Text>
        </Box>
        {vendor_locked && <Badge>🔒 Locked vendor</Badge>}
      </InlineStack>
    </ResourceItem>
  );
};

export default AdminTeams;
